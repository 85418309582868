/*!
 * Bracket Plus Responsive Dashboard Template
 * Version: 1.4.0
 * Author: ThemePixels (@themepixels)
 * URL: http://themepixels.me/bracketplus
 *
 * TABLE OF CONTENTS
 *
 * 1.   GOOGLE FONTS IMPORT
 *
 * 2.   BOOTSTRAP FRAMEWORK
 *
 * 3.   BOOTSTRAP OVERRIDES
 *        3.1   Alerts
 *        3.2   Buttons
 *        3.3   Cards
 *        3.4   Carousel
 *        3.5   Custom
 *        3.6   Custom Forms
 *        3.7   Forms
 *        3.8   Form Layouts
 *        3.9   Modal
 *        3.10  Navigation
 *        3.11  Pagination
 *        3.12  Popover
 *        3.13  Progress
 *        3.14  Tooltip
 *        3.15  Tables
 *        3.16  Widgets
 *
 * 4.   CUSTOM VENDOR STYLES
 *        4.1   Chartist
 *        4.2   Codemirror
 *        4.3   Datatables
 *        4.4   Flot
 *        4.5   IonRangeSlider
 *        4.6   jQuery Steps
 *        4.7   jQuery UI
 *        4.8   JQVMap
 *        4.9   Leaflet
 *        4.10  Medium Editor
 *        4.11  Morris
 *        4.12  Parsley
 *        4.13  Perfect Scrollbar
 *        4.14  Rickshaw
 *        4.15  Select2
 *        4.16  Sparkline
 *        4.17  Spectrum
 *        4.18  Summernote
 *        4.19  TagsInput
 *        4.20  Toggles
 *        4.21  DataTable
 *
 * 5.   BRACKET PLUS CORE STYLES
 *        5.1   Email Page
 *        5.2   Contacts Page
 *        5.3   Profile Page
 *        5.4   Chat Page
 *        5.5   Sitemap
 *
 *
 * 6.   FULL RTL SUPPORT
 *
 * 7.   UTILITIES / HELPER CLASSES
 *        7.1   Background
 *        7.2   Border
 *        7.3   Height
 *        7.4   Hover
 *        7.5   Margin
 *        7.6   Misc
 *        7.7   Padding
 *        7.8   Position
 *        7.9   Typography
 *        7.10  Width
 *
**/


/* ------------------------------------------------------ */


/* ############### 1. GOOGLE FONTS IMPORT ############### */


/* ------------------------------------------------------ */

@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");
@import url("https://fonts.googleapis.com/css?family=Fira+Sans:100,200,300,400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Hind:300,400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700");
@import url("https://fonts.googleapis.com/css?family=Libre+Barcode+39+Extended+Text");

/* ------------------------------------------------------ */


/* ############### 2. BOOTSTRAP FRAMEWORK ############### */


/* ------------------------------------------------------ */


/*!
 * Bootstrap v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

:root {
    --blue: #0866C6;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #DC3545;
    --orange: #f27510;
    --yellow: #F49917;
    --green: #23BF08;
    --teal: #1CAF9A;
    --cyan: #17A2B8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #0866C6;
    --secondary: #6c757d;
    --success: #23BF08;
    --info: #17A2B8;
    --warning: #F49917;
    --danger: #DC3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent;
}

@-ms-viewport {
    width: device-width;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

body {
    margin: 0;
    font-family: "Fira Sans", "Helvetica Neue", Arial, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #868ba1;
    text-align: left;
    background-color: #E9ECEF;
}

[tabindex="-1"]:focus {
    outline: 0 !important;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
    text-decoration: underline;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0;
}

dt {
    font-weight: 700;
}

dd {
    margin-bottom: .5rem;
    margin-left: 0;
}

blockquote {
    margin: 0 0 1rem;
}

dfn {
    font-style: italic;
}

b,
strong {
    font-weight: bolder;
}

small {
    font-size: 80%;
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -.25em;
}

sup {
    top: -.5em;
}

a {
    color: #0866C6;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}

a:hover {
    color: #05407c;
    text-decoration: none;
}

a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
    outline: 0;
}

pre,
code,
kbd,
samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 1em;
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar;
}

figure {
    margin: 0 0 1rem;
    width: 37px;
    height: 37px;
    border-radius: 100%;
    overflow: hidden;
    background-color: #ededf2;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin: 0 20px 0 0;
    transition: all .3s ease-in-out;
}

figure {
}

img {
    vertical-align: middle;
    border-style: none;
}

svg {
    overflow: hidden;
    vertical-align: middle;
}

table {
    border-collapse: collapse;
}

caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #6c757d;
    text-align: left;
    caption-side: bottom;
}

th {
    text-align: inherit;
}


label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

button {
    border-radius: 0;
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
    -webkit-appearance: listbox;
}

textarea {
    overflow: auto;
    resize: vertical;
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}

progress {
    vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

[type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}

output {
    display: inline-block;
}

summary {
    display: list-item;
    cursor: pointer;
}

template {
    display: none;
}

[hidden] {
    display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
}

h1,
.h1 {
    font-size: 2.1875rem;
}

h2,
.h2 {
    font-size: 1.75rem;
}

h3,
.h3 {
    font-size: 1rem;
    
}

h4,
.h4 {
    font-size: 1.3125rem;
}

h5,
.h5 {
    font-size: 1.09375rem;
}

h6,
.h6 {
    font-size: 0.875rem;
}

.lead {
    font-size: 1.09375rem;
    font-weight: 300;
}

.display-1 {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.2;
}

.display-2 {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.2;
}

.display-3 {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.2;
}

.display-4 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2;
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
    font-size: 80%;
    font-weight: 400;
}

mark,
.mark {
    padding: 0.2em;
    background-color: #fcf8e3;
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.list-inline {
    padding-left: 0;
    list-style: none;
}

.list-inline-item {
    display: inline-block;
}

.list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
}

.initialism {
    font-size: 90%;
    text-transform: uppercase;
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.09375rem;
}

.blockquote-footer {
    display: block;
    font-size: 80%;
    color: #6c757d;
}

.blockquote-footer::before {
    content: "\2014 \00A0";
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.img-thumbnail {
    padding: 0.25rem;
    background-color: #E9ECEF;
    border: 1px solid #dee2e6;
    border-radius: 3px;
    max-width: 100%;
    height: auto;
}

.figure {
    display: inline-block;
}

.figure-img {
    margin-bottom: 0.5rem;
    line-height: 1;
}

.figure-caption {
    font-size: 90%;
    color: #6c757d;
}

code {
    font-size: 87.5%;
    color: #e83e8c;
    word-break: break-word;
}

a>code {
    color: inherit;
}

kbd {
    padding: 0.2rem 0.4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #212529;
    border-radius: 0.2rem;
}

kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
}

pre {
    display: block;
    font-size: 87.5%;
    color: #212529;
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
}

.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

.container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    margin-bottom: 15px;
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.no-gutters>.col,
.no-gutters>[class*="col-"] {
    padding-right: 0;
    padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}

.col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
}

.col-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
}

.col-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}

.col-3 {
    flex: 0 0 25%;
    max-width: 25%;
}

.col-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}

.col-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
}

.col-6 {
    flex: 0 0 50%;
    max-width: 50%;
}

.col-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
}

.col-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
}

.col-9 {
    flex: 0 0 75%;
    max-width: 75%;
}

.col-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
}

.col-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
}

.col-12 {
    flex: 0 0 100%;
    max-width: 100%;
}

.order-first {
    order: -1;
}

.order-last {
    order: 13;
}

.order-0 {
    order: 0;
}

.order-1 {
    order: 1;
}

.order-2 {
    order: 2;
}

.order-3 {
    order: 3;
}

.order-4 {
    order: 4;
}

.order-5 {
    order: 5;
}

.order-6 {
    order: 6;
}

.order-7 {
    order: 7;
}

.order-8 {
    order: 8;
}

.order-9 {
    order: 9;
}

.order-10 {
    order: 10;
}

.order-11 {
    order: 11;
}

.order-12 {
    order: 12;
}

.offset-1 {
    margin-left: 8.33333%;
}

.offset-2 {
    margin-left: 16.66667%;
}

.offset-3 {
    margin-left: 25%;
}

.offset-4 {
    margin-left: 33.33333%;
}

.offset-5 {
    margin-left: 41.66667%;
}

.offset-6 {
    margin-left: 50%;
}

.offset-7 {
    margin-left: 58.33333%;
}

.offset-8 {
    margin-left: 66.66667%;
}

.offset-9 {
    margin-left: 75%;
}

.offset-10 {
    margin-left: 83.33333%;
}

.offset-11 {
    margin-left: 91.66667%;
}

@media (min-width: 576px) {
    .col-sm {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .col-sm-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }

    .col-sm-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .col-sm-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-sm-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-sm-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .col-sm-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-sm-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .col-sm-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .col-sm-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-sm-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .col-sm-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-sm-first {
        order: -1;
    }

    .order-sm-last {
        order: 13;
    }

    .order-sm-0 {
        order: 0;
    }

    .order-sm-1 {
        order: 1;
    }

    .order-sm-2 {
        order: 2;
    }

    .order-sm-3 {
        order: 3;
    }

    .order-sm-4 {
        order: 4;
    }

    .order-sm-5 {
        order: 5;
    }

    .order-sm-6 {
        order: 6;
    }

    .order-sm-7 {
        order: 7;
    }

    .order-sm-8 {
        order: 8;
    }

    .order-sm-9 {
        order: 9;
    }

    .order-sm-10 {
        order: 10;
    }

    .order-sm-11 {
        order: 11;
    }

    .order-sm-12 {
        order: 12;
    }

    .offset-sm-0 {
        margin-left: 0;
    }

    .offset-sm-1 {
        margin-left: 8.33333%;
    }

    .offset-sm-2 {
        margin-left: 16.66667%;
    }

    .offset-sm-3 {
        margin-left: 25%;
    }

    .offset-sm-4 {
        margin-left: 33.33333%;
    }

    .offset-sm-5 {
        margin-left: 41.66667%;
    }

    .offset-sm-6 {
        margin-left: 50%;
    }

    .offset-sm-7 {
        margin-left: 58.33333%;
    }

    .offset-sm-8 {
        margin-left: 66.66667%;
    }

    .offset-sm-9 {
        margin-left: 75%;
    }

    .offset-sm-10 {
        margin-left: 83.33333%;
    }

    .offset-sm-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 768px) {
    .col-md {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .col-md-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }

    .col-md-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .col-md-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-md-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .col-md-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-md-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .col-md-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .col-md-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-md-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .col-md-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-md-first {
        order: -1;
    }

    .order-md-last {
        order: 13;
    }

    .order-md-0 {
        order: 0;
    }

    .order-md-1 {
        order: 1;
    }

    .order-md-2 {
        order: 2;
    }

    .order-md-3 {
        order: 3;
    }

    .order-md-4 {
        order: 4;
    }

    .order-md-5 {
        order: 5;
    }

    .order-md-6 {
        order: 6;
    }

    .order-md-7 {
        order: 7;
    }

    .order-md-8 {
        order: 8;
    }

    .order-md-9 {
        order: 9;
    }

    .order-md-10 {
        order: 10;
    }

    .order-md-11 {
        order: 11;
    }

    .order-md-12 {
        order: 12;
    }

    .offset-md-0 {
        margin-left: 0;
    }

    .offset-md-1 {
        margin-left: 8.33333%;
    }

    .offset-md-2 {
        margin-left: 16.66667%;
    }

    .offset-md-3 {
        margin-left: 25%;
    }

    .offset-md-4 {
        margin-left: 33.33333%;
    }

    .offset-md-5 {
        margin-left: 41.66667%;
    }

    .offset-md-6 {
        margin-left: 50%;
    }

    .offset-md-7 {
        margin-left: 58.33333%;
    }

    .offset-md-8 {
        margin-left: 66.66667%;
    }

    .offset-md-9 {
        margin-left: 75%;
    }

    .offset-md-10 {
        margin-left: 83.33333%;
    }

    .offset-md-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 992px) {
    .col-lg {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .col-lg-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }

    .col-lg-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .col-lg-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-lg-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .col-lg-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-lg-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .col-lg-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-lg-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .col-lg-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-lg-first {
        order: -1;
    }

    .order-lg-last {
        order: 13;
    }

    .order-lg-0 {
        order: 0;
    }

    .order-lg-1 {
        order: 1;
    }

    .order-lg-2 {
        order: 2;
    }

    .order-lg-3 {
        order: 3;
    }

    .order-lg-4 {
        order: 4;
    }

    .order-lg-5 {
        order: 5;
    }

    .order-lg-6 {
        order: 6;
    }

    .order-lg-7 {
        order: 7;
    }

    .order-lg-8 {
        order: 8;
    }

    .order-lg-9 {
        order: 9;
    }

    .order-lg-10 {
        order: 10;
    }

    .order-lg-11 {
        order: 11;
    }

    .order-lg-12 {
        order: 12;
    }

    .offset-lg-0 {
        margin-left: 0;
    }

    .offset-lg-1 {
        margin-left: 8.33333%;
    }

    .offset-lg-2 {
        margin-left: 16.66667%;
    }

    .offset-lg-3 {
        margin-left: 25%;
    }

    .offset-lg-4 {
        margin-left: 33.33333%;
    }

    .offset-lg-5 {
        margin-left: 41.66667%;
    }

    .offset-lg-6 {
        margin-left: 50%;
    }

    .offset-lg-7 {
        margin-left: 58.33333%;
    }

    .offset-lg-8 {
        margin-left: 66.66667%;
    }

    .offset-lg-9 {
        margin-left: 75%;
    }

    .offset-lg-10 {
        margin-left: 83.33333%;
    }

    .offset-lg-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 1200px) {
    .col-xl {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .col-xl-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }

    .col-xl-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .col-xl-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-xl-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-xl-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .col-xl-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .col-xl-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-xl-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .col-xl-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .col-xl-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-xl-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .col-xl-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .col-xl-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-xl-first {
        order: -1;
    }

    .order-xl-last {
        order: 13;
    }

    .order-xl-0 {
        order: 0;
    }

    .order-xl-1 {
        order: 1;
    }

    .order-xl-2 {
        order: 2;
    }

    .order-xl-3 {
        order: 3;
    }

    .order-xl-4 {
        order: 4;
    }

    .order-xl-5 {
        order: 5;
    }

    .order-xl-6 {
        order: 6;
    }

    .order-xl-7 {
        order: 7;
    }

    .order-xl-8 {
        order: 8;
    }

    .order-xl-9 {
        order: 9;
    }

    .order-xl-10 {
        order: 10;
    }

    .order-xl-11 {
        order: 11;
    }

    .order-xl-12 {
        order: 12;
    }

    .offset-xl-0 {
        margin-left: 0;
    }

    .offset-xl-1 {
        margin-left: 8.33333%;
    }

    .offset-xl-2 {
        margin-left: 16.66667%;
    }

    .offset-xl-3 {
        margin-left: 25%;
    }

    .offset-xl-4 {
        margin-left: 33.33333%;
    }

    .offset-xl-5 {
        margin-left: 41.66667%;
    }

    .offset-xl-6 {
        margin-left: 50%;
    }

    .offset-xl-7 {
        margin-left: 58.33333%;
    }

    .offset-xl-8 {
        margin-left: 66.66667%;
    }

    .offset-xl-9 {
        margin-left: 75%;
    }

    .offset-xl-10 {
        margin-left: 83.33333%;
    }

    .offset-xl-11 {
        margin-left: 91.66667%;
    }
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
}

.table th,
.table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
}

.table tbody+tbody {
    border-top: 2px solid #dee2e6;
}

.table .table {
    background-color: #E9ECEF;
}

.table-sm th,
.table-sm td {
    padding: 0.3rem;
}

.table-bordered {
    border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
    border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody+tbody {
    border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary>th,
.table-primary>td {
    background-color: #bad4ef;
}

.table-hover .table-primary:hover {
    background-color: #a5c7ea;
}

.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
    background-color: #a5c7ea;
}

.table-secondary,
.table-secondary>th,
.table-secondary>td {
    background-color: #d6d8db;
}

.table-hover .table-secondary:hover {
    background-color: #c8cbcf;
}

.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
    background-color: #c8cbcf;
}

.table-success,
.table-success>th,
.table-success>td {
    background-color: #c1edba;
}

.table-hover .table-success:hover {
    background-color: #afe8a6;
}

.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
    background-color: #afe8a6;
}

.table-info,
.table-info>th,
.table-info>td {
    background-color: #bee5eb;
}

.table-hover .table-info:hover {
    background-color: #abdde5;
}

.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
    background-color: #abdde5;
}

.table-warning,
.table-warning>th,
.table-warning>td {
    background-color: #fce2be;
}

.table-hover .table-warning:hover {
    background-color: #fbd7a6;
}

.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
    background-color: #fbd7a6;
}

.table-danger,
.table-danger>th,
.table-danger>td {
    background-color: #f5c6cb;
}

.table-hover .table-danger:hover {
    background-color: #f1b0b7;
}

.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
    background-color: #f1b0b7;
}

.table-light,
.table-light>th,
.table-light>td {
    background-color: #fdfdfe;
}

.table-hover .table-light:hover {
    background-color: #ececf6;
}

.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
    background-color: #ececf6;
}

.table-dark,
.table-dark>th,
.table-dark>td {
    background-color: #c6c8ca;
}

.table-hover .table-dark:hover {
    background-color: #b9bbbe;
}

.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
    background-color: #b9bbbe;
}

.table-active,
.table-active>th,
.table-active>td {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
    background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
    color: #E9ECEF;
    background-color: #212529;
    border-color: #32383e;
}

.table .thead-light th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6;
}

.table-dark {
    color: #E9ECEF;
    background-color: #212529;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
    border-color: #32383e;
}

.table-dark.table-bordered {
    border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
    .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    .table-responsive-sm>.table-bordered {
        border: 0;
    }
}

@media (max-width: 767.98px) {
    .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    .table-responsive-md>.table-bordered {
        border: 0;
    }
}

@media (max-width: 991.98px) {
    .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    .table-responsive-lg>.table-bordered {
        border: 0;
    }
}

@media (max-width: 1199.98px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    .table-responsive-xl>.table-bordered {
        border: 0;
    }
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive>.table-bordered {
    border: 0;
}

.form-control,
.dataTables_filter input {
    display: block;
    width: 100%;
    height: calc(2.6125rem + 2px);
    padding: 0.65rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 3px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {

    .form-control,
    .dataTables_filter input {
        transition: none;
    }
}

.form-control::-ms-expand,
.dataTables_filter input::-ms-expand {
    background-color: transparent;
    border: 0;
}

.form-control:focus,
.dataTables_filter input:focus {
    color: #495057;
    background-color: #fff;
    border-color: #55a6f8;
    outline: 0;
    box-shadow: none;
}

.form-control::placeholder,
.dataTables_filter input::placeholder {
    color: #6c757d;
    opacity: 1;
}

.form-control:disabled,
.dataTables_filter input:disabled,
.form-control[readonly],
.dataTables_filter input[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}

select.form-control:focus::-ms-value {
    color: #495057;
    background-color: #fff;
}

.form-control-file,
.form-control-range {
    display: block;
    width: 100%;
}

.col-form-label {
    padding-top: calc(0.65rem + 1px);
    padding-bottom: calc(0.65rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
}

.col-form-label-lg {
    padding-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
    font-size: 1.09375rem;
    line-height: 1.5;
}

.col-form-label-sm {
    padding-top: calc(0.25rem + 1px);
    padding-bottom: calc(0.25rem + 1px);
    font-size: 0.76563rem;
    line-height: 1.5;
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding-top: 0.65rem;
    padding-bottom: 0.65rem;
    margin-bottom: 0;
    line-height: 1.5;
    color: #868ba1;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0;
}

.form-control-sm {
    height: calc(1.64844rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.76563rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.form-control-lg {
    height: calc(2.64063rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

select.form-control[size],
select.form-control[multiple] {
    height: auto;
}

textarea.form-control {
    height: auto;
}

.form-group {
    margin-bottom: 1rem;
}

.form-text {
    display: block;
    margin-top: 0.25rem;
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}

.form-row>.col,
.form-row>[class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
}

.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
}

.form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem;
}

.form-check-input:disabled~.form-check-label {
    color: #6c757d;
}

.form-check-label {
    margin-bottom: 0;
}

.form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0;
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #23BF08;
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: .1rem;
    font-size: 0.76563rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(35, 191, 8, 0.9);
    border-radius: 3px;
}

.was-validated .form-control:valid,
.was-validated .dataTables_filter input:valid,
.dataTables_filter .was-validated input:valid,
.form-control.is-valid,
.dataTables_filter input.is-valid,
.dataTables_filter input.parsley-success,
input.form-control.parsley-success,
textarea.form-control.parsley-success,
.was-validated .custom-select:valid,
.custom-select.is-valid,
input.custom-select.parsley-success,
textarea.custom-select.parsley-success {
    border-color: #23BF08;
}

.was-validated .form-control:valid:focus,
.was-validated .dataTables_filter input:valid:focus,
.dataTables_filter .was-validated input:valid:focus,
.form-control.is-valid:focus,
.dataTables_filter input.is-valid:focus,
.dataTables_filter input.parsley-success:focus,
input.form-control.parsley-success:focus,
textarea.form-control.parsley-success:focus,
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus,
input.custom-select.parsley-success:focus,
textarea.custom-select.parsley-success:focus {
    border-color: #23BF08;
    box-shadow: 0 0 0 0.2rem rgba(35, 191, 8, 0.25);
}

.was-validated .form-control:valid~.valid-feedback,
.was-validated .dataTables_filter input:valid~.valid-feedback,
.dataTables_filter .was-validated input:valid~.valid-feedback,
.was-validated .form-control:valid~.valid-tooltip,
.was-validated .dataTables_filter input:valid~.valid-tooltip,
.dataTables_filter .was-validated input:valid~.valid-tooltip,
.form-control.is-valid~.valid-feedback,
.dataTables_filter input.is-valid~.valid-feedback,
.dataTables_filter input.parsley-success~.valid-feedback,
input.form-control.parsley-success~.valid-feedback,
textarea.form-control.parsley-success~.valid-feedback,
.form-control.is-valid~.valid-tooltip,
.dataTables_filter input.is-valid~.valid-tooltip,
.dataTables_filter input.parsley-success~.valid-tooltip,
input.form-control.parsley-success~.valid-tooltip,
textarea.form-control.parsley-success~.valid-tooltip,
.was-validated .custom-select:valid~.valid-feedback,
.was-validated .custom-select:valid~.valid-tooltip,
.custom-select.is-valid~.valid-feedback,
input.custom-select.parsley-success~.valid-feedback,
textarea.custom-select.parsley-success~.valid-feedback,
.custom-select.is-valid~.valid-tooltip,
input.custom-select.parsley-success~.valid-tooltip,
textarea.custom-select.parsley-success~.valid-tooltip {
    display: block;
}

.was-validated .form-control-file:valid~.valid-feedback,
.was-validated .form-control-file:valid~.valid-tooltip,
.form-control-file.is-valid~.valid-feedback,
input.form-control-file.parsley-success~.valid-feedback,
textarea.form-control-file.parsley-success~.valid-feedback,
.form-control-file.is-valid~.valid-tooltip,
input.form-control-file.parsley-success~.valid-tooltip,
textarea.form-control-file.parsley-success~.valid-tooltip {
    display: block;
}

.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label,
input.form-check-input.parsley-success~.form-check-label,
textarea.form-check-input.parsley-success~.form-check-label {
    color: #23BF08;
}

.was-validated .form-check-input:valid~.valid-feedback,
.was-validated .form-check-input:valid~.valid-tooltip,
.form-check-input.is-valid~.valid-feedback,
input.form-check-input.parsley-success~.valid-feedback,
textarea.form-check-input.parsley-success~.valid-feedback,
.form-check-input.is-valid~.valid-tooltip,
input.form-check-input.parsley-success~.valid-tooltip,
textarea.form-check-input.parsley-success~.valid-tooltip {
    display: block;
}

.was-validated .custom-control-input:valid~.custom-control-label,
.custom-control-input.is-valid~.custom-control-label,
input.custom-control-input.parsley-success~.custom-control-label,
textarea.custom-control-input.parsley-success~.custom-control-label {
    color: #23BF08;
}

.was-validated .custom-control-input:valid~.custom-control-label::before,
.custom-control-input.is-valid~.custom-control-label::before,
input.custom-control-input.parsley-success~.custom-control-label::before,
textarea.custom-control-input.parsley-success~.custom-control-label::before {
    background-color: #68f84f;
}

.was-validated .custom-control-input:valid~.valid-feedback,
.was-validated .custom-control-input:valid~.valid-tooltip,
.custom-control-input.is-valid~.valid-feedback,
input.custom-control-input.parsley-success~.valid-feedback,
textarea.custom-control-input.parsley-success~.valid-feedback,
.custom-control-input.is-valid~.valid-tooltip,
input.custom-control-input.parsley-success~.valid-tooltip,
textarea.custom-control-input.parsley-success~.valid-tooltip {
    display: block;
}

.was-validated .custom-control-input:valid:checked~.custom-control-label::before,
.custom-control-input.is-valid:checked~.custom-control-label::before,
input.custom-control-input.parsley-success:checked~.custom-control-label::before,
textarea.custom-control-input.parsley-success:checked~.custom-control-label::before {
    background-color: #2cf00a;
}

.was-validated .custom-control-input:valid:focus~.custom-control-label::before,
.custom-control-input.is-valid:focus~.custom-control-label::before,
input.custom-control-input.parsley-success:focus~.custom-control-label::before,
textarea.custom-control-input.parsley-success:focus~.custom-control-label::before {
    box-shadow: 0 0 0 1px #E9ECEF, 0 0 0 0.2rem rgba(35, 191, 8, 0.25);
}

.was-validated .custom-file-input:valid~.custom-file-label,
.custom-file-input.is-valid~.custom-file-label,
input.custom-file-input.parsley-success~.custom-file-label,
textarea.custom-file-input.parsley-success~.custom-file-label {
    border-color: #23BF08;
}

.was-validated .custom-file-input:valid~.custom-file-label::after,
.custom-file-input.is-valid~.custom-file-label::after,
input.custom-file-input.parsley-success~.custom-file-label::after,
textarea.custom-file-input.parsley-success~.custom-file-label::after {
    border-color: inherit;
}

.was-validated .custom-file-input:valid~.valid-feedback,
.was-validated .custom-file-input:valid~.valid-tooltip,
.custom-file-input.is-valid~.valid-feedback,
input.custom-file-input.parsley-success~.valid-feedback,
textarea.custom-file-input.parsley-success~.valid-feedback,
.custom-file-input.is-valid~.valid-tooltip,
input.custom-file-input.parsley-success~.valid-tooltip,
textarea.custom-file-input.parsley-success~.valid-tooltip {
    display: block;
}

.was-validated .custom-file-input:valid:focus~.custom-file-label,
.custom-file-input.is-valid:focus~.custom-file-label,
input.custom-file-input.parsley-success:focus~.custom-file-label,
textarea.custom-file-input.parsley-success:focus~.custom-file-label {
    box-shadow: 0 0 0 0.2rem rgba(35, 191, 8, 0.25);
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #DC3545;
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: .1rem;
    font-size: 0.76563rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(220, 53, 69, 0.9);
    border-radius: 3px;
}

.was-validated .form-control:invalid,
.was-validated .dataTables_filter input:invalid,
.dataTables_filter .was-validated input:invalid,
.form-control.is-invalid,
.dataTables_filter input.is-invalid,
.dataTables_filter input.parsley-error,
input.form-control.parsley-error,
textarea.form-control.parsley-error,
.was-validated .custom-select:invalid,
.custom-select.is-invalid,
input.custom-select.parsley-error,
textarea.custom-select.parsley-error {
    border-color: #DC3545;
}

.was-validated .form-control:invalid:focus,
.was-validated .dataTables_filter input:invalid:focus,
.dataTables_filter .was-validated input:invalid:focus,
.form-control.is-invalid:focus,
.dataTables_filter input.is-invalid:focus,
.dataTables_filter input.parsley-error:focus,
input.form-control.parsley-error:focus,
textarea.form-control.parsley-error:focus,
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus,
input.custom-select.parsley-error:focus,
textarea.custom-select.parsley-error:focus {
    border-color: #DC3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control:invalid~.invalid-feedback,
.was-validated .dataTables_filter input:invalid~.invalid-feedback,
.dataTables_filter .was-validated input:invalid~.invalid-feedback,
.was-validated .form-control:invalid~.invalid-tooltip,
.was-validated .dataTables_filter input:invalid~.invalid-tooltip,
.dataTables_filter .was-validated input:invalid~.invalid-tooltip,
.form-control.is-invalid~.invalid-feedback,
.dataTables_filter input.is-invalid~.invalid-feedback,
.dataTables_filter input.parsley-error~.invalid-feedback,
input.form-control.parsley-error~.invalid-feedback,
textarea.form-control.parsley-error~.invalid-feedback,
.form-control.is-invalid~.invalid-tooltip,
.dataTables_filter input.is-invalid~.invalid-tooltip,
.dataTables_filter input.parsley-error~.invalid-tooltip,
input.form-control.parsley-error~.invalid-tooltip,
textarea.form-control.parsley-error~.invalid-tooltip,
.was-validated .custom-select:invalid~.invalid-feedback,
.was-validated .custom-select:invalid~.invalid-tooltip,
.custom-select.is-invalid~.invalid-feedback,
input.custom-select.parsley-error~.invalid-feedback,
textarea.custom-select.parsley-error~.invalid-feedback,
.custom-select.is-invalid~.invalid-tooltip,
input.custom-select.parsley-error~.invalid-tooltip,
textarea.custom-select.parsley-error~.invalid-tooltip {
    display: block;
}

.was-validated .form-control-file:invalid~.invalid-feedback,
.was-validated .form-control-file:invalid~.invalid-tooltip,
.form-control-file.is-invalid~.invalid-feedback,
input.form-control-file.parsley-error~.invalid-feedback,
textarea.form-control-file.parsley-error~.invalid-feedback,
.form-control-file.is-invalid~.invalid-tooltip,
input.form-control-file.parsley-error~.invalid-tooltip,
textarea.form-control-file.parsley-error~.invalid-tooltip {
    display: block;
}

.was-validated .form-check-input:invalid~.form-check-label,
.form-check-input.is-invalid~.form-check-label,
input.form-check-input.parsley-error~.form-check-label,
textarea.form-check-input.parsley-error~.form-check-label {
    color: #DC3545;
}

.was-validated .form-check-input:invalid~.invalid-feedback,
.was-validated .form-check-input:invalid~.invalid-tooltip,
.form-check-input.is-invalid~.invalid-feedback,
input.form-check-input.parsley-error~.invalid-feedback,
textarea.form-check-input.parsley-error~.invalid-feedback,
.form-check-input.is-invalid~.invalid-tooltip,
input.form-check-input.parsley-error~.invalid-tooltip,
textarea.form-check-input.parsley-error~.invalid-tooltip {
    display: block;
}

.was-validated .custom-control-input:invalid~.custom-control-label,
.custom-control-input.is-invalid~.custom-control-label,
input.custom-control-input.parsley-error~.custom-control-label,
textarea.custom-control-input.parsley-error~.custom-control-label {
    color: #DC3545;
}

.was-validated .custom-control-input:invalid~.custom-control-label::before,
.custom-control-input.is-invalid~.custom-control-label::before,
input.custom-control-input.parsley-error~.custom-control-label::before,
textarea.custom-control-input.parsley-error~.custom-control-label::before {
    background-color: #efa2a9;
}

.was-validated .custom-control-input:invalid~.invalid-feedback,
.was-validated .custom-control-input:invalid~.invalid-tooltip,
.custom-control-input.is-invalid~.invalid-feedback,
input.custom-control-input.parsley-error~.invalid-feedback,
textarea.custom-control-input.parsley-error~.invalid-feedback,
.custom-control-input.is-invalid~.invalid-tooltip,
input.custom-control-input.parsley-error~.invalid-tooltip,
textarea.custom-control-input.parsley-error~.invalid-tooltip {
    display: block;
}

.was-validated .custom-control-input:invalid:checked~.custom-control-label::before,
.custom-control-input.is-invalid:checked~.custom-control-label::before,
input.custom-control-input.parsley-error:checked~.custom-control-label::before,
textarea.custom-control-input.parsley-error:checked~.custom-control-label::before {
    background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus~.custom-control-label::before,
.custom-control-input.is-invalid:focus~.custom-control-label::before,
input.custom-control-input.parsley-error:focus~.custom-control-label::before,
textarea.custom-control-input.parsley-error:focus~.custom-control-label::before {
    box-shadow: 0 0 0 1px #E9ECEF, 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-file-input:invalid~.custom-file-label,
.custom-file-input.is-invalid~.custom-file-label,
input.custom-file-input.parsley-error~.custom-file-label,
textarea.custom-file-input.parsley-error~.custom-file-label {
    border-color: #DC3545;
}

.was-validated .custom-file-input:invalid~.custom-file-label::after,
.custom-file-input.is-invalid~.custom-file-label::after,
input.custom-file-input.parsley-error~.custom-file-label::after,
textarea.custom-file-input.parsley-error~.custom-file-label::after {
    border-color: inherit;
}

.was-validated .custom-file-input:invalid~.invalid-feedback,
.was-validated .custom-file-input:invalid~.invalid-tooltip,
.custom-file-input.is-invalid~.invalid-feedback,
input.custom-file-input.parsley-error~.invalid-feedback,
textarea.custom-file-input.parsley-error~.invalid-feedback,
.custom-file-input.is-invalid~.invalid-tooltip,
input.custom-file-input.parsley-error~.invalid-tooltip,
textarea.custom-file-input.parsley-error~.invalid-tooltip {
    display: block;
}

.was-validated .custom-file-input:invalid:focus~.custom-file-label,
.custom-file-input.is-invalid:focus~.custom-file-label,
input.custom-file-input.parsley-error:focus~.custom-file-label,
textarea.custom-file-input.parsley-error:focus~.custom-file-label {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.form-inline .form-check {
    width: 100%;
}

@media (min-width: 576px) {
    .form-inline label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
    }

    .form-inline .form-group {
        display: flex;
        flex: 0 0 auto;
        flex-flow: row wrap;
        align-items: center;
        margin-bottom: 0;
    }

    .form-inline .form-control,
    .form-inline .dataTables_filter input,
    .dataTables_filter .form-inline input {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }

    .form-inline .form-control-plaintext {
        display: inline-block;
    }

    .form-inline .input-group,
    .form-inline .custom-select {
        width: auto;
    }

    .form-inline .form-check {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        padding-left: 0;
    }

    .form-inline .form-check-input {
        position: relative;
        margin-top: 0;
        margin-right: 0.25rem;
        margin-left: 0;
    }

    .form-inline .custom-control {
        align-items: center;
        justify-content: center;
    }

    .form-inline .custom-control-label {
        margin-bottom: 0;
    }
}

.btn,
.sp-container button {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.65rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 3px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {

    .btn,
    .sp-container button {
        transition: none;
    }
}

.btn:hover,
.sp-container button:hover,
.btn:focus,
.sp-container button:focus {
    text-decoration: none;
}

.btn:focus,
.sp-container button:focus,
.btn.focus,
.sp-container button.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(8, 102, 198, 0.25);
}

.btn.disabled,
.sp-container button.disabled,
.btn:disabled,
.sp-container button:disabled {
    opacity: 0.65;
}

.btn:not(:disabled):not(.disabled),
.sp-container button:not(:disabled):not(.disabled) {
    cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none;
}

.btn-primary {
    color: #fff;
    background-color: #0866C6;
    border-color: #0866C6;
}

.btn-primary:hover {
    color: #fff;
    background-color: #0753a1;
    border-color: #064d95;
}

.btn-primary:focus,
.btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(8, 102, 198, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: #0866C6;
    border-color: #0866C6;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #064d95;
    border-color: #064689;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(8, 102, 198, 0.5);
}

.btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
}

.btn-secondary:focus,
.btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-success {
    color: #fff;
    background-color: #23BF08;
    border-color: #23BF08;
}

.btn-success:hover {
    color: #fff;
    background-color: #1c9a06;
    border-color: #1a8e06;
}

.btn-success:focus,
.btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(35, 191, 8, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
    color: #fff;
    background-color: #23BF08;
    border-color: #23BF08;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1a8e06;
    border-color: #188205;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(35, 191, 8, 0.5);
}

.btn-info {
    color: #fff;
    background-color: #17A2B8;
    border-color: #17A2B8;
}

.btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
}

.btn-info:focus,
.btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
    color: #fff;
    background-color: #17A2B8;
    border-color: #17A2B8;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show>.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-warning {
    color: #212529;
    background-color: #F49917;
    border-color: #F49917;
}

.btn-warning:hover {
    color: #fff;
    background-color: #da850a;
    border-color: #ce7d0a;
}

.btn-warning:focus,
.btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 153, 23, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
    color: #212529;
    background-color: #F49917;
    border-color: #F49917;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show>.btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #ce7d0a;
    border-color: #c27609;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 153, 23, 0.5);
}

.btn-danger {
    color: #fff;
    background-color: #DC3545;
    border-color: #DC3545;
}

.btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
}

.btn-danger:focus,
.btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
    color: #fff;
    background-color: #DC3545;
    border-color: #DC3545;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show>.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
}

.btn-light:focus,
.btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show>.btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-dark {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

.btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
}

.btn-dark:focus,
.btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show>.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-primary {
    color: #0866C6;
    background-color: transparent;
    background-image: none;
    border-color: #0866C6;
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #0866C6;
    border-color: #0866C6;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(8, 102, 198, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: #0866C6;
    background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #0866C6;
    border-color: #0866C6;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(8, 102, 198, 0.5);
}

.btn-outline-secondary {
    color: #6c757d;
    background-color: transparent;
    background-image: none;
    border-color: #6c757d;
}

.btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show>.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
    color: #23BF08;
    background-color: transparent;
    background-image: none;
    border-color: #23BF08;
}

.btn-outline-success:hover {
    color: #fff;
    background-color: #23BF08;
    border-color: #23BF08;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(35, 191, 8, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
    color: #23BF08;
    background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show>.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #23BF08;
    border-color: #23BF08;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(35, 191, 8, 0.5);
}

.btn-outline-info {
    color: #17A2B8;
    background-color: transparent;
    background-image: none;
    border-color: #17A2B8;
}

.btn-outline-info:hover {
    color: #fff;
    background-color: #17A2B8;
    border-color: #17A2B8;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
    color: #17A2B8;
    background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show>.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17A2B8;
    border-color: #17A2B8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
    color: #F49917;
    background-color: transparent;
    background-image: none;
    border-color: #F49917;
}

.btn-outline-warning:hover {
    color: #212529;
    background-color: #F49917;
    border-color: #F49917;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 153, 23, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
    color: #F49917;
    background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show>.btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #F49917;
    border-color: #F49917;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 153, 23, 0.5);
}

.btn-outline-danger {
    color: #DC3545;
    background-color: transparent;
    background-image: none;
    border-color: #DC3545;
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: #DC3545;
    border-color: #DC3545;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
    color: #DC3545;
    background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show>.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #DC3545;
    border-color: #DC3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
    color: #f8f9fa;
    background-color: transparent;
    background-image: none;
    border-color: #f8f9fa;
}

.btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show>.btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
    color: #343a40;
    background-color: transparent;
    background-image: none;
    border-color: #343a40;
}

.btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show>.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
    font-weight: 400;
    color: #0866C6;
    background-color: transparent;
}

.btn-link:hover {
    color: #05407c;
    text-decoration: none;
    background-color: transparent;
    border-color: transparent;
}

.btn-link:focus,
.btn-link.focus {
    text-decoration: none;
    border-color: transparent;
    box-shadow: none;
}

.btn-link:disabled,
.btn-link.disabled {
    color: #6c757d;
    pointer-events: none;
}

.btn-lg,
.btn-group-lg>.btn,
.sp-container .btn-group-lg>button {
    padding: 0.5rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

.btn-sm,
.btn-group-sm>.btn,
.sp-container .btn-group-sm>button {
    padding: 0.25rem 0.5rem;
    font-size: 0.76563rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-block+.btn-block {
    margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
    width: 100%;
}

.fade {
    transition: opacity 0.15s linear;
}

@media screen and (prefers-reduced-motion: reduce) {
    .fade {
        transition: none;
    }
}

.fade:not(.show) {
    opacity: 0;
}

.collapse:not(.show) {
    display: none;
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
    .collapsing {
        transition: none;
    }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
    position: relative;
}

.dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 0.875rem;
    color: #868ba1;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 3px;
}

.dropdown-menu-right {
    right: 0;
    left: auto;
}

.dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropright .dropdown-toggle::after {
    vertical-align: 0;
}

.dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
}

.dropleft .dropdown-toggle::after {
    display: none;
}

.dropleft .dropdown-toggle::before {
    display: inline-block;
    width: 0;
    height: 0;
    margin-right: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropleft .dropdown-toggle::before {
    vertical-align: 0;
}

.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
    right: auto;
    bottom: auto;
}

.dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #e9ecef;
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa;
}

.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #0866C6;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: #6c757d;
    background-color: transparent;
}

.dropdown-menu.show {
    display: block;
}

.dropdown-header {
    display: block;
    padding: 0.5rem 1.5rem;
    margin-bottom: 0;
    font-size: 0.76563rem;
    color: #6c757d;
    white-space: nowrap;
}

.dropdown-item-text {
    display: block;
    padding: 0.25rem 1.5rem;
    color: #212529;
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
}

.btn-group>.btn,
.sp-container .btn-group>button,
.btn-group-vertical>.btn,
.sp-container .btn-group-vertical>button {
    position: relative;
    flex: 0 1 auto;
}

.btn-group>.btn:hover,
.sp-container .btn-group>button:hover,
.btn-group-vertical>.btn:hover,
.sp-container .btn-group-vertical>button:hover {
    z-index: 1;
}

.btn-group>.btn:focus,
.sp-container .btn-group>button:focus,
.btn-group>.btn:active,
.sp-container .btn-group>button:active,
.btn-group>.btn.active,
.sp-container .btn-group>button.active,
.btn-group-vertical>.btn:focus,
.sp-container .btn-group-vertical>button:focus,
.btn-group-vertical>.btn:active,
.sp-container .btn-group-vertical>button:active,
.btn-group-vertical>.btn.active,
.sp-container .btn-group-vertical>button.active {
    z-index: 1;
}

.btn-group .btn+.btn,
.btn-group .sp-container button+.btn,
.sp-container .btn-group button+.btn,
.btn-group .sp-container .btn+button,
.sp-container .btn-group .btn+button,
.btn-group .sp-container button+button,
.sp-container .btn-group button+button,
.btn-group .btn+.btn-group,
.btn-group .sp-container button+.btn-group,
.sp-container .btn-group button+.btn-group,
.btn-group .btn-group+.btn,
.btn-group .sp-container .btn-group+button,
.sp-container .btn-group .btn-group+button,
.btn-group .btn-group+.btn-group,
.btn-group-vertical .btn+.btn,
.btn-group-vertical .sp-container button+.btn,
.sp-container .btn-group-vertical button+.btn,
.btn-group-vertical .sp-container .btn+button,
.sp-container .btn-group-vertical .btn+button,
.btn-group-vertical .sp-container button+button,
.sp-container .btn-group-vertical button+button,
.btn-group-vertical .btn+.btn-group,
.btn-group-vertical .sp-container button+.btn-group,
.sp-container .btn-group-vertical button+.btn-group,
.btn-group-vertical .btn-group+.btn,
.btn-group-vertical .sp-container .btn-group+button,
.sp-container .btn-group-vertical .btn-group+button,
.btn-group-vertical .btn-group+.btn-group {
    margin-left: -1px;
}

.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.btn-toolbar .input-group {
    width: auto;
}

.btn-group>.btn:first-child,
.sp-container .btn-group>button:first-child {
    margin-left: 0;
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.sp-container .btn-group>button:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn-group:not(:last-child)>.btn,
.sp-container .btn-group>.btn-group:not(:last-child)>button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.btn-group>.btn:not(:first-child),
.sp-container .btn-group>button:not(:first-child),
.btn-group>.btn-group:not(:first-child)>.btn,
.sp-container .btn-group>.btn-group:not(:first-child)>button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
    padding-right: 0.5625rem;
    padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
    margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
    margin-right: 0;
}

.btn-sm+.dropdown-toggle-split,
.btn-group-sm>.btn+.dropdown-toggle-split,
.sp-container .btn-group-sm>button+.dropdown-toggle-split {
    padding-right: 0.375rem;
    padding-left: 0.375rem;
}

.btn-lg+.dropdown-toggle-split,
.btn-group-lg>.btn+.dropdown-toggle-split,
.sp-container .btn-group-lg>button+.dropdown-toggle-split {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
}

.btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.btn-group-vertical .btn,
.btn-group-vertical .sp-container button,
.sp-container .btn-group-vertical button,
.btn-group-vertical .btn-group {
    width: 100%;
}

.btn-group-vertical>.btn+.btn,
.sp-container .btn-group-vertical>button+.btn,
.sp-container .btn-group-vertical>.btn+button,
.sp-container .btn-group-vertical>button+button,
.btn-group-vertical>.btn+.btn-group,
.sp-container .btn-group-vertical>button+.btn-group,
.btn-group-vertical>.btn-group+.btn,
.sp-container .btn-group-vertical>.btn-group+button,
.btn-group-vertical>.btn-group+.btn-group {
    margin-top: -1px;
    margin-left: 0;
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
.sp-container .btn-group-vertical>button:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.btn-group:not(:last-child)>.btn,
.sp-container .btn-group-vertical>.btn-group:not(:last-child)>button {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn:not(:first-child),
.sp-container .btn-group-vertical>button:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child)>.btn,
.sp-container .btn-group-vertical>.btn-group:not(:first-child)>button {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.btn-group-toggle>.btn,
.sp-container .btn-group-toggle>button,
.btn-group-toggle>.btn-group>.btn,
.sp-container .btn-group-toggle>.btn-group>button {
    margin-bottom: 0;
}

.btn-group-toggle>.btn input[type="radio"],
.sp-container .btn-group-toggle>button input[type="radio"],
.btn-group-toggle>.btn input[type="checkbox"],
.sp-container .btn-group-toggle>button input[type="checkbox"],
.btn-group-toggle>.btn-group>.btn input[type="radio"],
.sp-container .btn-group-toggle>.btn-group>button input[type="radio"],
.btn-group-toggle>.btn-group>.btn input[type="checkbox"],
.sp-container .btn-group-toggle>.btn-group>button input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

.input-group>.form-control,
.dataTables_filter .input-group>input,
.input-group>.custom-select,
.input-group>.custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
}

.input-group>.form-control+.form-control,
.dataTables_filter .input-group>input+.form-control,
.dataTables_filter .input-group>.form-control+input,
.dataTables_filter .input-group>input+input,
.input-group>.form-control+.custom-select,
.dataTables_filter .input-group>input+.custom-select,
.input-group>.form-control+.custom-file,
.dataTables_filter .input-group>input+.custom-file,
.input-group>.custom-select+.form-control,
.dataTables_filter .input-group>.custom-select+input,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.custom-file,
.input-group>.custom-file+.form-control,
.dataTables_filter .input-group>.custom-file+input,
.input-group>.custom-file+.custom-select,
.input-group>.custom-file+.custom-file {
    margin-left: -1px;
}

.input-group>.form-control:focus,
.dataTables_filter .input-group>input:focus,
.input-group>.custom-select:focus,
.input-group>.custom-file .custom-file-input:focus~.custom-file-label {
    z-index: 3;
}

.input-group>.custom-file .custom-file-input:focus {
    z-index: 4;
}

.input-group>.form-control:not(:last-child),
.dataTables_filter .input-group>input:not(:last-child),
.input-group>.custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.form-control:not(:first-child),
.dataTables_filter .input-group>input:not(:first-child),
.input-group>.custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group>.custom-file {
    display: flex;
    align-items: center;
}

.input-group>.custom-file:not(:last-child) .custom-file-label,
.input-group>.custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.custom-file:not(:first-child) .custom-file-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
    display: flex;
}

.input-group-prepend .btn,
.input-group-prepend .sp-container button,
.sp-container .input-group-prepend button,
.input-group-append .btn,
.input-group-append .sp-container button,
.sp-container .input-group-append button {
    position: relative;
    z-index: 2;
}

.input-group-prepend .btn+.btn,
.input-group-prepend .sp-container button+.btn,
.sp-container .input-group-prepend button+.btn,
.input-group-prepend .sp-container .btn+button,
.sp-container .input-group-prepend .btn+button,
.input-group-prepend .sp-container button+button,
.sp-container .input-group-prepend button+button,
.input-group-prepend .btn+.input-group-text,
.input-group-prepend .sp-container button+.input-group-text,
.sp-container .input-group-prepend button+.input-group-text,
.input-group-prepend .input-group-text+.input-group-text,
.input-group-prepend .input-group-text+.btn,
.input-group-prepend .sp-container .input-group-text+button,
.sp-container .input-group-prepend .input-group-text+button,
.input-group-append .btn+.btn,
.input-group-append .sp-container button+.btn,
.sp-container .input-group-append button+.btn,
.input-group-append .sp-container .btn+button,
.sp-container .input-group-append .btn+button,
.input-group-append .sp-container button+button,
.sp-container .input-group-append button+button,
.input-group-append .btn+.input-group-text,
.input-group-append .sp-container button+.input-group-text,
.sp-container .input-group-append button+.input-group-text,
.input-group-append .input-group-text+.input-group-text,
.input-group-append .input-group-text+.btn,
.input-group-append .sp-container .input-group-text+button,
.sp-container .input-group-append .input-group-text+button {
    margin-left: -1px;
}

.input-group-prepend {
    margin-right: -1px;
}

.input-group-append {
    margin-left: -1px;
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: 0.65rem 0.75rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 3px;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
    margin-top: 0;
}

.input-group-lg>.form-control,
.dataTables_filter .input-group-lg>input,
.input-group-lg>.input-group-prepend>.input-group-text,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.sp-container .input-group-lg>.input-group-prepend>button,
.input-group-lg>.input-group-append>.btn,
.sp-container .input-group-lg>.input-group-append>button {
    height: calc(2.64063rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

.input-group-sm>.form-control,
.dataTables_filter .input-group-sm>input,
.input-group-sm>.input-group-prepend>.input-group-text,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.sp-container .input-group-sm>.input-group-prepend>button,
.input-group-sm>.input-group-append>.btn,
.sp-container .input-group-sm>.input-group-append>button {
    height: calc(1.64844rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.76563rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.input-group>.input-group-prepend>.btn,
.sp-container .input-group>.input-group-prepend>button,
.input-group>.input-group-prepend>.input-group-text,
.input-group>.input-group-append:not(:last-child)>.btn,
.sp-container .input-group>.input-group-append:not(:last-child)>button,
.input-group>.input-group-append:not(:last-child)>.input-group-text,
.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.sp-container .input-group>.input-group-append:last-child>button:not(:last-child):not(.dropdown-toggle),
.input-group>.input-group-append:last-child>.input-group-text:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.input-group-append>.btn,
.sp-container .input-group>.input-group-append>button,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:not(:first-child)>.btn,
.sp-container .input-group>.input-group-prepend:not(:first-child)>button,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.sp-container .input-group>.input-group-prepend:first-child>button:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.custom-control {
    position: relative;
    display: block;
    min-height: 1.3125rem;
    padding-left: 1.5rem;
}

.custom-control-inline {
    display: inline-flex;
    margin-right: 1rem;
}

.custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    background-color: #0866C6;
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: 0 0 0 1px #E9ECEF, 0 0 0 0.2rem rgba(8, 102, 198, 0.25);
}

.custom-control-input:active~.custom-control-label::before {
    color: #fff;
    background-color: #86c0fa;
}

.custom-control-input:disabled~.custom-control-label {
    color: #6c757d;
}

.custom-control-input:disabled~.custom-control-label::before {
    background-color: #e9ecef;
}

.custom-control-label {
    position: relative;
    margin-bottom: 0;
}

.custom-control-label::before {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    user-select: none;
    background-color: #dee2e6;
}

.custom-control-label::after {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
}

.custom-checkbox .custom-control-label::before {
    border-radius: 3px;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: #0866C6;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
    background-color: #0866C6;
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(8, 102, 198, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
    background-color: rgba(8, 102, 198, 0.5);
}

.custom-radio .custom-control-label::before {
    border-radius: 50%;
}

.custom-radio .custom-control-input:checked~.custom-control-label::before {
    background-color: #0866C6;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(8, 102, 198, 0.5);
}

.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(2.6125rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
    background-size: 8px 10px;
    border: 1px solid #ced4da;
    border-radius: 3px;
    appearance: none;
}

.custom-select:focus {
    border-color: #55a6f8;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(85, 166, 248, 0.5);
}

.custom-select:focus::-ms-value {
    color: #495057;
    background-color: #fff;
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none;
}

.custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef;
}

.custom-select::-ms-expand {
    opacity: 0;
}

.custom-select-sm {
    height: calc(1.64844rem + 2px);
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    font-size: 75%;
}

.custom-select-lg {
    height: calc(2.64063rem + 2px);
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    font-size: 125%;
}

.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(2.6125rem + 2px);
    margin-bottom: 0;
}

.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(2.6125rem + 2px);
    margin: 0;
    opacity: 0;
}

.custom-file-input:focus~.custom-file-label {
    border-color: #55a6f8;
    box-shadow: 0 0 0 0.2rem rgba(8, 102, 198, 0.25);
}

.custom-file-input:focus~.custom-file-label::after {
    border-color: #55a6f8;
}

.custom-file-input:disabled~.custom-file-label {
    background-color: #e9ecef;
}

.custom-file-input:lang(en)~.custom-file-label::after {
    content: "Browse";
}

.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(2.6125rem + 2px);
    padding: 0.65rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 3px;
}

.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 2.6125rem;
    padding: 0.65rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: 1px solid #ced4da;
    border-radius: 0 3px 3px 0;
}

.custom-range {
    width: 100%;
    padding-left: 0;
    background-color: transparent;
    appearance: none;
}

.custom-range:focus {
    outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #E9ECEF, 0 0 0 0.2rem rgba(8, 102, 198, 0.25);
}

.custom-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #E9ECEF, 0 0 0 0.2rem rgba(8, 102, 198, 0.25);
}

.custom-range:focus::-ms-thumb {
    box-shadow: 0 0 0 1px #E9ECEF, 0 0 0 0.2rem rgba(8, 102, 198, 0.25);
}

.custom-range::-moz-focus-outer {
    border: 0;
}

.custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #0866C6;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
    .custom-range::-webkit-slider-thumb {
        transition: none;
    }
}

.custom-range::-webkit-slider-thumb:active {
    background-color: #86c0fa;
}

.custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #0866C6;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
    .custom-range::-moz-range-thumb {
        transition: none;
    }
}

.custom-range::-moz-range-thumb:active {
    background-color: #86c0fa;
}

.custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
}

.custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #0866C6;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
    .custom-range::-ms-thumb {
        transition: none;
    }
}

.custom-range::-ms-thumb:active {
    background-color: #86c0fa;
}

.custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {

    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
        transition: none;
    }
}

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
    text-decoration: none;
}

.nav-link.disabled {
    color: #6c757d;
}

.nav-tabs {
    border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
    margin-bottom: -1px;
}

.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #E9ECEF;
    border-color: #dee2e6 #dee2e6 #E9ECEF;
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.nav-pills .nav-link {
    border-radius: 3px;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #0866C6;
}

.nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center;
}

.nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
}

.tab-content>.tab-pane {
    display: none;
}

.tab-content>.active {
    display: block;
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
}

.navbar>.container,
.navbar>.container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.navbar-brand {
    display: inline-block;
    padding-top: 0.33594rem;
    padding-bottom: 0.33594rem;
    margin-right: 1rem;
    font-size: 1.09375rem;
    line-height: inherit;
    white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
    text-decoration: none;
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}

.navbar-nav .dropdown-menu {
    position: static;
    float: none;
}

.navbar-text {
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
}

.navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.09375rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 3px;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
    text-decoration: none;
}

.navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
}

@media (max-width: 575.98px) {

    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 576px) {
    .navbar-expand-sm {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    .navbar-expand-sm .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid {
        flex-wrap: nowrap;
    }

    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-expand-sm .navbar-toggler {
        display: none;
    }
}

@media (max-width: 767.98px) {

    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 768px) {
    .navbar-expand-md {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    .navbar-expand-md .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid {
        flex-wrap: nowrap;
    }

    .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-expand-md .navbar-toggler {
        display: none;
    }
}

@media (max-width: 991.98px) {

    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    .navbar-expand-lg .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid {
        flex-wrap: nowrap;
    }

    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-expand-lg .navbar-toggler {
        display: none;
    }
}

@media (max-width: 1199.98px) {

    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 1200px) {
    .navbar-expand-xl {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    .navbar-expand-xl .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid {
        flex-wrap: nowrap;
    }

    .navbar-expand-xl .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    .navbar-expand-xl .navbar-toggler {
        display: none;
    }
}

.navbar-expand {
    flex-flow: row nowrap;
    justify-content: flex-start;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid {
    padding-right: 0;
    padding-left: 0;
}

.navbar-expand .navbar-nav {
    flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid {
    flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
}

.navbar-expand .navbar-toggler {
    display: none;
}

.navbar-light .navbar-brand {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
    color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
    color: #fff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
    color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
    color: #fff;
}

.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
    color: #fff;
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
    color: #fff;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 3px;
}

.card>hr {
    margin-right: 0;
    margin-left: 0;
}

.card>.list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.card>.list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.card-body {
    flex: 1 1 auto;
    padding: 1.25rem;
}

.card-title {
    margin-bottom: 0.75rem;
}

.card-subtitle {
    margin-top: -0.375rem;
    margin-bottom: 0;
}

.card-text:last-child {
    margin-bottom: 0;
}

.card-link:hover {
    text-decoration: none;
}

.card-link+.card-link {
    margin-left: 1.25rem;
}

.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
    border-radius: calc(3px - 1px) calc(3px - 1px) 0 0;
}

.card-header+.list-group .list-group-item:first-child {
    border-top: 0;
}

.card-footer {
    padding: 0.75rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
    border-radius: 0 0 calc(3px - 1px) calc(3px - 1px);
}

.card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -0.75rem;
    margin-left: -0.625rem;
    border-bottom: 0;
}

.card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
}

.card-img {
    width: 100%;
    border-radius: calc(3px - 1px);
}

.card-img-top {
    width: 100%;
    border-top-left-radius: calc(3px - 1px);
    border-top-right-radius: calc(3px - 1px);
}

.card-img-bottom {
    width: 100%;
    border-bottom-right-radius: calc(3px - 1px);
    border-bottom-left-radius: calc(3px - 1px);
}

.card-deck {
    display: flex;
    flex-direction: column;
}

.card-deck .card {
    margin-bottom: 15px;
}

@media (min-width: 576px) {
    .card-deck {
        flex-flow: row wrap;
        margin-right: -15px;
        margin-left: -15px;
    }

    .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px;
    }
}

.card-group {
    display: flex;
    flex-direction: column;
}

.card-group>.card {
    margin-bottom: 15px;
}

@media (min-width: 576px) {
    .card-group {
        flex-flow: row wrap;
    }

    .card-group>.card {
        flex: 1 0 0%;
        margin-bottom: 0;
    }

    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0;
    }

    .card-group>.card:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .card-group>.card:first-child .card-img-top,
    .card-group>.card:first-child .card-header {
        border-top-right-radius: 0;
    }

    .card-group>.card:first-child .card-img-bottom,
    .card-group>.card:first-child .card-footer {
        border-bottom-right-radius: 0;
    }

    .card-group>.card:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .card-group>.card:last-child .card-img-top,
    .card-group>.card:last-child .card-header {
        border-top-left-radius: 0;
    }

    .card-group>.card:last-child .card-img-bottom,
    .card-group>.card:last-child .card-footer {
        border-bottom-left-radius: 0;
    }

    .card-group>.card:only-child {
        border-radius: 3px;
    }

    .card-group>.card:only-child .card-img-top,
    .card-group>.card:only-child .card-header {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
    }

    .card-group>.card:only-child .card-img-bottom,
    .card-group>.card:only-child .card-footer {
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
    }

    .card-group>.card:not(:first-child):not(:last-child):not(:only-child) {
        border-radius: 0;
    }

    .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
    .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
    .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-header,
    .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
        border-radius: 0;
    }
}

.card-columns .card {
    margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
    .card-columns {
        column-count: 3;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1;
    }

    .card-columns .card {
        display: inline-block;
        width: 100%;
    }
}

.accordion .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0;
}

.accordion .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0;
}

.accordion .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.accordion .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #e9ecef;
    border-radius: 3px;
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: 0.5rem;
}

.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/";
}

.breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: underline;
}

.breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: none;
}

.breadcrumb-item.active {
    color: #6c757d;
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 3px;
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #0866C6;
    background-color: #fff;
    border: 1px solid #dee2e6;
}

.page-link:hover {
    z-index: 2;
    color: #05407c;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6;
}

.page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(8, 102, 198, 0.25);
}

.page-link:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.page-item:last-child .page-link {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #0866C6;
    border-color: #0866C6;
}

.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
}

.pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.09375rem;
    line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.76563rem;
    line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
}

.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 3px;
}

.badge:empty {
    display: none;
}

.btn .badge,
.sp-container button .badge {
    position: relative;
    top: -1px;
}

.badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
}

.badge-primary {
    color: #fff;
    background-color: #0866C6;
}

.badge-primary[href]:hover,
.badge-primary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #064d95;
}

.badge-secondary {
    color: #fff;
    background-color: #6c757d;
}

.badge-secondary[href]:hover,
.badge-secondary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #545b62;
}

.badge-success {
    color: #fff;
    background-color: #23BF08;
}

.badge-success[href]:hover,
.badge-success[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1a8e06;
}

.badge-info {
    color: #fff;
    background-color: #17A2B8;
}

.badge-info[href]:hover,
.badge-info[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #117a8b;
}

.badge-warning {
    color: #212529;
    background-color: #F49917;
}

.badge-warning[href]:hover,
.badge-warning[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #ce7d0a;
}

.badge-danger {
    color: #fff;
    background-color: #DC3545;
}

.badge-danger[href]:hover,
.badge-danger[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #bd2130;
}

.badge-light {
    color: #212529;
    background-color: #f8f9fa;
}

.badge-light[href]:hover,
.badge-light[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #dae0e5;
}

.badge-dark {
    color: #fff;
    background-color: #343a40;
}

.badge-dark[href]:hover,
.badge-dark[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1d2124;
}

.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #e9ecef;
    border-radius: 0.3rem;
}

@media (min-width: 576px) {
    .jumbotron {
        padding: 4rem 2rem;
    }
}

.jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0;
}

.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 3px;
}

.alert-heading {
    color: inherit;
}

.alert-link {
    font-weight: 700;
}

.alert-dismissible {
    padding-right: 3.8125rem;
}

.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit;
}

.alert-primary {
    color: #043567;
    background-color: #cee0f4;
    border-color: #bad4ef;
}

.alert-primary hr {
    border-top-color: #a5c7ea;
}

.alert-primary .alert-link {
    color: #021c36;
}

.alert-secondary {
    color: #383d41;
    background-color: #e2e3e5;
    border-color: #d6d8db;
}

.alert-secondary hr {
    border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
    color: #202326;
}

.alert-success {
    color: #126304;
    background-color: #d3f2ce;
    border-color: #c1edba;
}

.alert-success hr {
    border-top-color: #afe8a6;
}

.alert-success .alert-link {
    color: #093202;
}

.alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
}

.alert-info hr {
    border-top-color: #abdde5;
}

.alert-info .alert-link {
    color: #062c33;
}

.alert-warning {
    color: #7f500c;
    background-color: #fdebd1;
    border-color: #fce2be;
}

.alert-warning hr {
    border-top-color: #fbd7a6;
}

.alert-warning .alert-link {
    color: #503308;
}

.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.alert-danger hr {
    border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
    color: #491217;
}

.alert-light {
    color: #818182;
    background-color: #fefefe;
    border-color: #fdfdfe;
}

.alert-light hr {
    border-top-color: #ececf6;
}

.alert-light .alert-link {
    color: #686868;
}

.alert-dark {
    color: #1b1e21;
    background-color: #d6d8d9;
    border-color: #c6c8ca;
}

.alert-dark hr {
    border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
    color: #040505;
}

@keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0;
    }

    to {
        background-position: 0 0;
    }
}

.progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: 0.65625rem;
    background-color: #e9ecef;
    border-radius: 3px;
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #0866C6;
    transition: width 0.6s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
    .progress-bar {
        transition: none;
    }
}

.progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem;
}

.progress-bar-animated {
    animation: progress-bar-stripes 1s linear infinite;
}

.media {
    display: flex;
    align-items: flex-start;
}

.media-body {
    flex: 1;
}

.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
}

.list-group-item-action {
    width: 100%;
    color: #495057;
    text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa;
}

.list-group-item-action:active {
    color: #868ba1;
    background-color: #e9ecef;
}

.list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.list-group-item:hover,
.list-group-item:focus {
    z-index: 1;
    text-decoration: none;
}

.list-group-item.disabled,
.list-group-item:disabled {
    color: #6c757d;
    background-color: #fff;
}

.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #0866C6;
    border-color: #0866C6;
}

.list-group-flush .list-group-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
}

.list-group-flush:first-child .list-group-item:first-child {
    border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
    border-bottom: 0;
}

.list-group-item-primary {
    color: #043567;
    background-color: #bad4ef;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
    color: #043567;
    background-color: #a5c7ea;
}

.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #043567;
    border-color: #043567;
}

.list-group-item-secondary {
    color: #383d41;
    background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41;
}

.list-group-item-success {
    color: #126304;
    background-color: #c1edba;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
    color: #126304;
    background-color: #afe8a6;
}

.list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #126304;
    border-color: #126304;
}

.list-group-item-info {
    color: #0c5460;
    background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460;
}

.list-group-item-warning {
    color: #7f500c;
    background-color: #fce2be;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
    color: #7f500c;
    background-color: #fbd7a6;
}

.list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #7f500c;
    border-color: #7f500c;
}

.list-group-item-danger {
    color: #721c24;
    background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24;
}

.list-group-item-light {
    color: #818182;
    background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182;
}

.list-group-item-dark {
    color: #1b1e21;
    background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21;
}

.close {
    float: right;
    font-size: 1.3125rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

.close:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
    color: #000;
    text-decoration: none;
    opacity: .75;
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
}

.modal-open {
    overflow: hidden;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    outline: 0;
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
}

.modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%);
}

@media screen and (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
        transition: none;
    }
}

.modal.show .modal-dialog {
    transform: translate(0, 0);
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - (0.5rem * 2));
}

.modal-dialog-centered::before {
    display: block;
    height: calc(100vh - (0.5rem * 2));
    content: "";
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000;
}

.modal-backdrop.fade {
    opacity: 0;
}

.modal-backdrop.show {
    opacity: 0.5;
}

.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #e9ecef;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}

.modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
}

.modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #e9ecef;
}

.modal-footer> :not(:first-child) {
    margin-left: .25rem;
}

.modal-footer> :not(:last-child) {
    margin-right: .25rem;
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }

    .modal-dialog-centered {
        min-height: calc(100% - (1.75rem * 2));
    }

    .modal-dialog-centered::before {
        height: calc(100vh - (1.75rem * 2));
    }

    .modal-sm {
        max-width: 300px;
    }
}

@media (min-width: 992px) {
    .modal-lg {
        max-width: 800px;
    }
}

.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: "Fira Sans", "Helvetica Neue", Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.76563rem;
    word-wrap: break-word;
    opacity: 0;
}

.tooltip.show {
    opacity: 0.9;
}

.tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;
}

.tooltip .arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
    padding: 0.4rem 0;
}

.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    top: 0;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #000;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
    padding: 0 0.4rem;
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    right: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
    padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    bottom: 0;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #000;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
    padding: 0 0.4rem;
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    left: 0;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #000;
}

.tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 3px;
}

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: "Fira Sans", "Helvetica Neue", Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.76563rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
}

.popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;
}

.popover .arrow::before,
.popover .arrow::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
}

.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
    margin-bottom: 0.5rem;
}

.bs-popover-top .arrow,
.bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0;
}

.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #fff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
    margin-left: 0.5rem;
}

.bs-popover-right .arrow,
.bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
}

.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0;
}

.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
    margin-top: 0.5rem;
}

.bs-popover-bottom .arrow,
.bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1);
}

.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem;
}

.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
    margin-right: 0.5rem;
}

.bs-popover-left .arrow,
.bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
}

.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem;
}

.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #fff;
}

.popover-header {
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: inherit;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
    display: none;
}

.popover-body {
    padding: 0.5rem 0.75rem;
    color: #868ba1;
}

.carousel {
    position: relative;
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.carousel-item {
    position: relative;
    display: none;
    align-items: center;
    width: 100%;
    backface-visibility: hidden;
    perspective: 1000px;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
    display: block;
    transition: transform 0.6s ease;
}

@media screen and (prefers-reduced-motion: reduce) {

    .carousel-item.active,
    .carousel-item-next,
    .carousel-item-prev {
        transition: none;
    }
}

.carousel-item-next,
.carousel-item-prev {
    position: absolute;
    top: 0;
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
    transform: translateX(0);
}

@supports (transform-style: preserve-3d) {

    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
        transform: translate3d(0, 0, 0);
    }
}

.carousel-item-next,
.active.carousel-item-right {
    transform: translateX(100%);
}

@supports (transform-style: preserve-3d) {

    .carousel-item-next,
    .active.carousel-item-right {
        transform: translate3d(100%, 0, 0);
    }
}

.carousel-item-prev,
.active.carousel-item-left {
    transform: translateX(-100%);
}

@supports (transform-style: preserve-3d) {

    .carousel-item-prev,
    .active.carousel-item-left {
        transform: translate3d(-100%, 0, 0);
    }
}

.carousel-fade .carousel-item {
    opacity: 0;
    transition-duration: .6s;
    transition-property: opacity;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
    opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    opacity: 0;
}

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
    transform: translateX(0);
}

@supports (transform-style: preserve-3d) {

    .carousel-fade .carousel-item-next,
    .carousel-fade .carousel-item-prev,
    .carousel-fade .carousel-item.active,
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-prev {
        transform: translate3d(0, 0, 0);
    }
}

.carousel-control-prev,
.carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: 0.5;
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9;
}

.carousel-control-prev {
    left: 0;
}

.carousel-control-next {
    right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: transparent no-repeat center center;
    background-size: 100% 100%;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 10px;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
}

.carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5);
}

.carousel-indicators li::before {
    position: absolute;
    top: -10px;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 10px;
    content: "";
}

.carousel-indicators li::after {
    position: absolute;
    bottom: -10px;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 10px;
    content: "";
}

.carousel-indicators .active {
    background-color: #fff;
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
}

.align-baseline {
    vertical-align: baseline !important;
}

.align-top {
    vertical-align: top !important;
}

.align-middle {
    vertical-align: middle !important;
}

.align-bottom {
    vertical-align: bottom !important;
}

.align-text-bottom {
    vertical-align: text-bottom !important;
}

.align-text-top {
    vertical-align: text-top !important;
}

.bg-primary {
    background-color: #0866C6 !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
    background-color: #064d95 !important;
}

.bg-secondary {
    background-color: #6c757d !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
    background-color: #545b62 !important;
}

.bg-success {
    background-color: #23BF08 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
    background-color: #1a8e06 !important;
}

.bg-info {
    background-color: #17A2B8 !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
    background-color: #117a8b !important;
}

.bg-warning {
    background-color: #F49917 !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
    background-color: #ce7d0a !important;
}

.bg-danger {
    background-color: #DC3545 !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
    background-color: #bd2130 !important;
}

.bg-light {
    background-color: #f8f9fa !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
    background-color: #dae0e5 !important;
}

.bg-dark {
    background-color: #343a40 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
    background-color: #1d2124 !important;
}

.bg-white {
    background-color: #fff !important;
}

.bg-transparent {
    background-color: transparent !important;
}

.border {
    border: 1px solid #dee2e6 !important;
}

.border-top {
    border-top: 1px solid #dee2e6 !important;
}

.border-right {
    border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
    border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
    border-left: 1px solid #dee2e6 !important;
}

.border-0 {
    border: 0 !important;
}

.border-top-0 {
    border-top: 0 !important;
}

.border-right-0 {
    border-right: 0 !important;
}

.border-bottom-0 {
    border-bottom: 0 !important;
}

.border-left-0 {
    border-left: 0 !important;
}

.border-primary {
    border-color: #0866C6 !important;
}

.border-secondary {
    border-color: #6c757d !important;
}

.border-success {
    border-color: #23BF08 !important;
}

.border-info {
    border-color: #17A2B8 !important;
}

.border-warning {
    border-color: #F49917 !important;
}

.border-danger {
    border-color: #DC3545 !important;
}

.border-light {
    border-color: #f8f9fa !important;
}

.border-dark {
    border-color: #343a40 !important;
}

.border-white {
    border-color: #fff !important;
}

.rounded {
    border-radius: 3px !important;
}

.rounded-top {
    border-top-left-radius: 3px !important;
    border-top-right-radius: 3px !important;
}

.rounded-right {
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
}

.rounded-bottom {
    border-bottom-right-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
}

.rounded-left {
    border-top-left-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
}

.rounded-circle {
    border-radius: 50% !important;
}

.rounded-0 {
    border-radius: 0 !important;
}

.clearfix::after {
    display: block;
    clear: both;
    content: "";
}

.d-none {
    display: none !important;
}

.d-inline {
    display: inline !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-block {
    display: block !important;
}

.d-table {
    display: table !important;
}

.d-table-row {
    display: table-row !important;
}

.d-table-cell {
    display: table-cell !important;
}

.d-flex {
    display: flex !important;
}

.d-inline-flex {
    display: inline-flex !important;
}

@media (min-width: 576px) {
    .d-sm-none {
        display: none !important;
    }

    .d-sm-inline {
        display: inline !important;
    }

    .d-sm-inline-block {
        display: inline-block !important;
    }

    .d-sm-block {
        display: block !important;
    }

    .d-sm-table {
        display: table !important;
    }

    .d-sm-table-row {
        display: table-row !important;
    }

    .d-sm-table-cell {
        display: table-cell !important;
    }

    .d-sm-flex {
        display: flex !important;
    }

    .d-sm-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: 768px) {
    .d-md-none {
        display: none !important;
    }

    .d-md-inline {
        display: inline !important;
    }

    .d-md-inline-block {
        display: inline-block !important;
    }

    .d-md-block {
        display: block !important;
    }

    .d-md-table {
        display: table !important;
    }

    .d-md-table-row {
        display: table-row !important;
    }

    .d-md-table-cell {
        display: table-cell !important;
    }

    .d-md-flex {
        display: flex !important;
    }

    .d-md-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: 992px) {
    .d-lg-none {
        display: none !important;
    }

    .d-lg-inline {
        display: inline !important;
    }

    .d-lg-inline-block {
        display: inline-block !important;
    }

    .d-lg-block {
        display: block !important;
    }

    .d-lg-table {
        display: table !important;
    }

    .d-lg-table-row {
        display: table-row !important;
    }

    .d-lg-table-cell {
        display: table-cell !important;
    }

    .d-lg-flex {
        display: flex !important;
    }

    .d-lg-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: 1200px) {
    .d-xl-none {
        display: none !important;
    }

    .d-xl-inline {
        display: inline !important;
    }

    .d-xl-inline-block {
        display: inline-block !important;
    }

    .d-xl-block {
        display: block !important;
    }

    .d-xl-table {
        display: table !important;
    }

    .d-xl-table-row {
        display: table-row !important;
    }

    .d-xl-table-cell {
        display: table-cell !important;
    }

    .d-xl-flex {
        display: flex !important;
    }

    .d-xl-inline-flex {
        display: inline-flex !important;
    }
}

@media print {
    .d-print-none {
        display: none !important;
    }

    .d-print-inline {
        display: inline !important;
    }

    .d-print-inline-block {
        display: inline-block !important;
    }

    .d-print-block {
        display: block !important;
    }

    .d-print-table {
        display: table !important;
    }

    .d-print-table-row {
        display: table-row !important;
    }

    .d-print-table-cell {
        display: table-cell !important;
    }

    .d-print-flex {
        display: flex !important;
    }

    .d-print-inline-flex {
        display: inline-flex !important;
    }
}

.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
}

.embed-responsive::before {
    display: block;
    content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.embed-responsive-21by9::before {
    padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
    padding-top: 56.25%;
}

.embed-responsive-4by3::before {
    padding-top: 75%;
}

.embed-responsive-1by1::before {
    padding-top: 100%;
}

.flex-row {
    flex-direction: row !important;
}

.flex-column {
    flex-direction: column !important;
}

.flex-row-reverse {
    flex-direction: row-reverse !important;
}

.flex-column-reverse {
    flex-direction: column-reverse !important;
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.flex-nowrap {
    flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
}

.flex-fill {
    flex: 1 1 auto !important;
}

.flex-grow-0 {
    flex-grow: 0 !important;
}

.flex-grow-1 {
    flex-grow: 1 !important;
}

.flex-shrink-0 {
    flex-shrink: 0 !important;
}

.flex-shrink-1 {
    flex-shrink: 1 !important;
}

.justify-content-start {
    justify-content: flex-start !important;
}

.justify-content-end {
    justify-content: flex-end !important;
}

.justify-content-center {
    justify-content: center !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.justify-content-around {
    justify-content: space-around !important;
}

.align-items-start {
    align-items: flex-start !important;
}

.align-items-end {
    align-items: flex-end !important;
}

.align-items-center {
    align-items: center !important;
}

.align-items-baseline {
    align-items: baseline !important;
}

.align-items-stretch {
    align-items: stretch !important;
}

.align-content-start {
    align-content: flex-start !important;
}

.align-content-end {
    align-content: flex-end !important;
}

.align-content-center {
    align-content: center !important;
}

.align-content-between {
    align-content: space-between !important;
}

.align-content-around {
    align-content: space-around !important;
}

.align-content-stretch {
    align-content: stretch !important;
}

.align-self-auto {
    align-self: auto !important;
}

.align-self-start {
    align-self: flex-start !important;
}

.align-self-end {
    align-self: flex-end !important;
}

.align-self-center {
    align-self: center !important;
}

.align-self-baseline {
    align-self: baseline !important;
}

.align-self-stretch {
    align-self: stretch !important;
}

@media (min-width: 576px) {
    .flex-sm-row {
        flex-direction: row !important;
    }

    .flex-sm-column {
        flex-direction: column !important;
    }

    .flex-sm-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-sm-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-sm-wrap {
        flex-wrap: wrap !important;
    }

    .flex-sm-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .flex-sm-fill {
        flex: 1 1 auto !important;
    }

    .flex-sm-grow-0 {
        flex-grow: 0 !important;
    }

    .flex-sm-grow-1 {
        flex-grow: 1 !important;
    }

    .flex-sm-shrink-0 {
        flex-shrink: 0 !important;
    }

    .flex-sm-shrink-1 {
        flex-shrink: 1 !important;
    }

    .justify-content-sm-start {
        justify-content: flex-start !important;
    }

    .justify-content-sm-end {
        justify-content: flex-end !important;
    }

    .justify-content-sm-center {
        justify-content: center !important;
    }

    .justify-content-sm-between {
        justify-content: space-between !important;
    }

    .justify-content-sm-around {
        justify-content: space-around !important;
    }

    .align-items-sm-start {
        align-items: flex-start !important;
    }

    .align-items-sm-end {
        align-items: flex-end !important;
    }

    .align-items-sm-center {
        align-items: center !important;
    }

    .align-items-sm-baseline {
        align-items: baseline !important;
    }

    .align-items-sm-stretch {
        align-items: stretch !important;
    }

    .align-content-sm-start {
        align-content: flex-start !important;
    }

    .align-content-sm-end {
        align-content: flex-end !important;
    }

    .align-content-sm-center {
        align-content: center !important;
    }

    .align-content-sm-between {
        align-content: space-between !important;
    }

    .align-content-sm-around {
        align-content: space-around !important;
    }

    .align-content-sm-stretch {
        align-content: stretch !important;
    }

    .align-self-sm-auto {
        align-self: auto !important;
    }

    .align-self-sm-start {
        align-self: flex-start !important;
    }

    .align-self-sm-end {
        align-self: flex-end !important;
    }

    .align-self-sm-center {
        align-self: center !important;
    }

    .align-self-sm-baseline {
        align-self: baseline !important;
    }

    .align-self-sm-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 768px) {
    .flex-md-row {
        flex-direction: row !important;
    }

    .flex-md-column {
        flex-direction: column !important;
    }

    .flex-md-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-md-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-md-wrap {
        flex-wrap: wrap !important;
    }

    .flex-md-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .flex-md-fill {
        flex: 1 1 auto !important;
    }

    .flex-md-grow-0 {
        flex-grow: 0 !important;
    }

    .flex-md-grow-1 {
        flex-grow: 1 !important;
    }

    .flex-md-shrink-0 {
        flex-shrink: 0 !important;
    }

    .flex-md-shrink-1 {
        flex-shrink: 1 !important;
    }

    .justify-content-md-start {
        justify-content: flex-start !important;
    }

    .justify-content-md-end {
        justify-content: flex-end !important;
    }

    .justify-content-md-center {
        justify-content: center !important;
    }

    .justify-content-md-between {
        justify-content: space-between !important;
    }

    .justify-content-md-around {
        justify-content: space-around !important;
    }

    .align-items-md-start {
        align-items: flex-start !important;
    }

    .align-items-md-end {
        align-items: flex-end !important;
    }

    .align-items-md-center {
        align-items: center !important;
    }

    .align-items-md-baseline {
        align-items: baseline !important;
    }

    .align-items-md-stretch {
        align-items: stretch !important;
    }

    .align-content-md-start {
        align-content: flex-start !important;
    }

    .align-content-md-end {
        align-content: flex-end !important;
    }

    .align-content-md-center {
        align-content: center !important;
    }

    .align-content-md-between {
        align-content: space-between !important;
    }

    .align-content-md-around {
        align-content: space-around !important;
    }

    .align-content-md-stretch {
        align-content: stretch !important;
    }

    .align-self-md-auto {
        align-self: auto !important;
    }

    .align-self-md-start {
        align-self: flex-start !important;
    }

    .align-self-md-end {
        align-self: flex-end !important;
    }

    .align-self-md-center {
        align-self: center !important;
    }

    .align-self-md-baseline {
        align-self: baseline !important;
    }

    .align-self-md-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 992px) {
    .flex-lg-row {
        flex-direction: row !important;
    }

    .flex-lg-column {
        flex-direction: column !important;
    }

    .flex-lg-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-lg-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-lg-wrap {
        flex-wrap: wrap !important;
    }

    .flex-lg-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .flex-lg-fill {
        flex: 1 1 auto !important;
    }

    .flex-lg-grow-0 {
        flex-grow: 0 !important;
    }

    .flex-lg-grow-1 {
        flex-grow: 1 !important;
    }

    .flex-lg-shrink-0 {
        flex-shrink: 0 !important;
    }

    .flex-lg-shrink-1 {
        flex-shrink: 1 !important;
    }

    .justify-content-lg-start {
        justify-content: flex-start !important;
    }

    .justify-content-lg-end {
        justify-content: flex-end !important;
    }

    .justify-content-lg-center {
        justify-content: center !important;
    }

    .justify-content-lg-between {
        justify-content: space-between !important;
    }

    .justify-content-lg-around {
        justify-content: space-around !important;
    }

    .align-items-lg-start {
        align-items: flex-start !important;
    }

    .align-items-lg-end {
        align-items: flex-end !important;
    }

    .align-items-lg-center {
        align-items: center !important;
    }

    .align-items-lg-baseline {
        align-items: baseline !important;
    }

    .align-items-lg-stretch {
        align-items: stretch !important;
    }

    .align-content-lg-start {
        align-content: flex-start !important;
    }

    .align-content-lg-end {
        align-content: flex-end !important;
    }

    .align-content-lg-center {
        align-content: center !important;
    }

    .align-content-lg-between {
        align-content: space-between !important;
    }

    .align-content-lg-around {
        align-content: space-around !important;
    }

    .align-content-lg-stretch {
        align-content: stretch !important;
    }

    .align-self-lg-auto {
        align-self: auto !important;
    }

    .align-self-lg-start {
        align-self: flex-start !important;
    }

    .align-self-lg-end {
        align-self: flex-end !important;
    }

    .align-self-lg-center {
        align-self: center !important;
    }

    .align-self-lg-baseline {
        align-self: baseline !important;
    }

    .align-self-lg-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 1200px) {
    .flex-xl-row {
        flex-direction: row !important;
    }

    .flex-xl-column {
        flex-direction: column !important;
    }

    .flex-xl-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-xl-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-xl-wrap {
        flex-wrap: wrap !important;
    }

    .flex-xl-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .flex-xl-fill {
        flex: 1 1 auto !important;
    }

    .flex-xl-grow-0 {
        flex-grow: 0 !important;
    }

    .flex-xl-grow-1 {
        flex-grow: 1 !important;
    }

    .flex-xl-shrink-0 {
        flex-shrink: 0 !important;
    }

    .flex-xl-shrink-1 {
        flex-shrink: 1 !important;
    }

    .justify-content-xl-start {
        justify-content: flex-start !important;
    }

    .justify-content-xl-end {
        justify-content: flex-end !important;
    }

    .justify-content-xl-center {
        justify-content: center !important;
    }

    .justify-content-xl-between {
        justify-content: space-between !important;
    }

    .justify-content-xl-around {
        justify-content: space-around !important;
    }

    .align-items-xl-start {
        align-items: flex-start !important;
    }

    .align-items-xl-end {
        align-items: flex-end !important;
    }

    .align-items-xl-center {
        align-items: center !important;
    }

    .align-items-xl-baseline {
        align-items: baseline !important;
    }

    .align-items-xl-stretch {
        align-items: stretch !important;
    }

    .align-content-xl-start {
        align-content: flex-start !important;
    }

    .align-content-xl-end {
        align-content: flex-end !important;
    }

    .align-content-xl-center {
        align-content: center !important;
    }

    .align-content-xl-between {
        align-content: space-between !important;
    }

    .align-content-xl-around {
        align-content: space-around !important;
    }

    .align-content-xl-stretch {
        align-content: stretch !important;
    }

    .align-self-xl-auto {
        align-self: auto !important;
    }

    .align-self-xl-start {
        align-self: flex-start !important;
    }

    .align-self-xl-end {
        align-self: flex-end !important;
    }

    .align-self-xl-center {
        align-self: center !important;
    }

    .align-self-xl-baseline {
        align-self: baseline !important;
    }

    .align-self-xl-stretch {
        align-self: stretch !important;
    }
}

.float-left {
    float: left !important;
}

.float-right {
    float: right !important;
}

.float-none {
    float: none !important;
}

@media (min-width: 576px) {
    .float-sm-left {
        float: left !important;
    }

    .float-sm-right {
        float: right !important;
    }

    .float-sm-none {
        float: none !important;
    }
}

@media (min-width: 768px) {
    .float-md-left {
        float: left !important;
    }

    .float-md-right {
        float: right !important;
    }

    .float-md-none {
        float: none !important;
    }
}

@media (min-width: 992px) {
    .float-lg-left {
        float: left !important;
    }

    .float-lg-right {
        float: right !important;
    }

    .float-lg-none {
        float: none !important;
    }
}

@media (min-width: 1200px) {
    .float-xl-left {
        float: left !important;
    }

    .float-xl-right {
        float: right !important;
    }

    .float-xl-none {
        float: none !important;
    }
}

.position-static {
    position: static !important;
}

.position-relative {
    position: relative !important;
}

.position-absolute {
    position: absolute !important;
}

.position-fixed {
    position: fixed !important;
}

.position-sticky {
    position: sticky !important;
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
}

@supports (position: sticky) {
    .sticky-top {
        position: sticky;
        top: 0;
        z-index: 1020;
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
}

.shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
    box-shadow: none !important;
}

.w-25 {
    width: 25% !important;
}

.w-50 {
    width: 50% !important;
}

.w-75 {
    width: 75% !important;
}

.w-100 {
    width: 100% !important;
}

.w-auto {
    width: auto !important;
}

.h-25 {
    height: 25% !important;
}

.h-50 {
    height: 50% !important;
}

.h-75 {
    height: 75% !important;
}

.h-100 {
    height: 100% !important;
}

.h-auto {
    height: auto !important;
}

.mw-100 {
    max-width: 100% !important;
}

.mh-100 {
    max-height: 100% !important;
}

.m-0 {
    margin: 0 !important;
}

.mt-0,
.my-0 {
    margin-top: 0 !important;
}

.mr-0,
.mx-0 {
    margin-right: 0 !important;
}

.mb-0,
.my-0 {
    margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
    margin-left: 0 !important;
}

.m-1 {
    margin: 0.25rem !important;
}

.mt-1,
.my-1 {
    margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
    margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
    margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
    margin-left: 0.25rem !important;
}

.m-2 {
    margin: 0.5rem !important;
}

.mt-2,
.my-2 {
    margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
    margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
    margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
    margin-left: 0.5rem !important;
}

.m-3 {
    margin: 1rem !important;
}

.mt-3,
.my-3 {
    margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
    margin-right: 1rem !important;
}

.mb-3,
.my-3 {
    margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
    margin-left: 1rem !important;
}

.m-4 {
    margin: 1.5rem !important;
}

.mt-4,
.my-4 {
    margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
    margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
    margin-left: 1.5rem !important;
}

.m-5 {
    margin: 3rem !important;
}

.mt-5,
.my-5 {
    margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
    margin-right: 3rem !important;
}

.mb-5,
.my-5 {
    margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
    margin-left: 3rem !important;
}

.p-0 {
    padding: 0 !important;
}

.pt-0,
.py-0 {
    padding-top: 0 !important;
}

.pr-0,
.px-0 {
    padding-right: 0 !important;
}

.pb-0,
.py-0 {
    padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
    padding-left: 0 !important;
}

.p-1 {
    padding: 0.25rem !important;
}

.pt-1,
.py-1 {
    padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
    padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
    padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
    padding-left: 0.25rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.pt-2,
.py-2 {
    padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
    padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
    padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
    padding-left: 0.5rem !important;
}

.p-3 {
    padding: 1rem !important;
}

.pt-3,
.py-3 {
    padding-top: 1rem !important;
}

.pr-3,
.px-3 {
    padding-right: 1rem !important;
}

.pb-3,
.py-3 {
    padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
    padding-left: 1rem !important;
}

.p-4 {
    padding: 1.5rem !important;
}

.pt-4,
.py-4 {
    padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
    padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
    padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
    padding-left: 1.5rem !important;
}

.p-5 {
    padding: 3rem !important;
}

.pt-5,
.py-5 {
    padding-top: 3rem !important;
}

.pr-5,
.px-5 {
    padding-right: 3rem !important;
}

.pb-5,
.py-5 {
    padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
    padding-left: 3rem !important;
}

.m-auto {
    margin: auto !important;
}

.mt-auto,
.my-auto {
    margin-top: auto !important;
}

.mr-auto,
.mx-auto {
    margin-right: auto !important;
}

.mb-auto,
.my-auto {
    margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
    margin-left: auto !important;
}

@media (min-width: 576px) {
    .m-sm-0 {
        margin: 0 !important;
    }

    .mt-sm-0,
    .my-sm-0 {
        margin-top: 0 !important;
    }

    .mr-sm-0,
    .mx-sm-0 {
        margin-right: 0 !important;
    }

    .mb-sm-0,
    .my-sm-0 {
        margin-bottom: 0 !important;
    }

    .ml-sm-0,
    .mx-sm-0 {
        margin-left: 0 !important;
    }

    .m-sm-1 {
        margin: 0.25rem !important;
    }

    .mt-sm-1,
    .my-sm-1 {
        margin-top: 0.25rem !important;
    }

    .mr-sm-1,
    .mx-sm-1 {
        margin-right: 0.25rem !important;
    }

    .mb-sm-1,
    .my-sm-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-sm-1,
    .mx-sm-1 {
        margin-left: 0.25rem !important;
    }

    .m-sm-2 {
        margin: 0.5rem !important;
    }

    .mt-sm-2,
    .my-sm-2 {
        margin-top: 0.5rem !important;
    }

    .mr-sm-2,
    .mx-sm-2 {
        margin-right: 0.5rem !important;
    }

    .mb-sm-2,
    .my-sm-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-sm-2,
    .mx-sm-2 {
        margin-left: 0.5rem !important;
    }

    .m-sm-3 {
        margin: 1rem !important;
    }

    .mt-sm-3,
    .my-sm-3 {
        margin-top: 1rem !important;
    }

    .mr-sm-3,
    .mx-sm-3 {
        margin-right: 1rem !important;
    }

    .mb-sm-3,
    .my-sm-3 {
        margin-bottom: 1rem !important;
    }

    .ml-sm-3,
    .mx-sm-3 {
        margin-left: 1rem !important;
    }

    .m-sm-4 {
        margin: 1.5rem !important;
    }

    .mt-sm-4,
    .my-sm-4 {
        margin-top: 1.5rem !important;
    }

    .mr-sm-4,
    .mx-sm-4 {
        margin-right: 1.5rem !important;
    }

    .mb-sm-4,
    .my-sm-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-sm-4,
    .mx-sm-4 {
        margin-left: 1.5rem !important;
    }

    .m-sm-5 {
        margin: 3rem !important;
    }

    .mt-sm-5,
    .my-sm-5 {
        margin-top: 3rem !important;
    }

    .mr-sm-5,
    .mx-sm-5 {
        margin-right: 3rem !important;
    }

    .mb-sm-5,
    .my-sm-5 {
        margin-bottom: 3rem !important;
    }

    .ml-sm-5,
    .mx-sm-5 {
        margin-left: 3rem !important;
    }

    .p-sm-0 {
        padding: 0 !important;
    }

    .pt-sm-0,
    .py-sm-0 {
        padding-top: 0 !important;
    }

    .pr-sm-0,
    .px-sm-0 {
        padding-right: 0 !important;
    }

    .pb-sm-0,
    .py-sm-0 {
        padding-bottom: 0 !important;
    }

    .pl-sm-0,
    .px-sm-0 {
        padding-left: 0 !important;
    }

    .p-sm-1 {
        padding: 0.25rem !important;
    }

    .pt-sm-1,
    .py-sm-1 {
        padding-top: 0.25rem !important;
    }

    .pr-sm-1,
    .px-sm-1 {
        padding-right: 0.25rem !important;
    }

    .pb-sm-1,
    .py-sm-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-sm-1,
    .px-sm-1 {
        padding-left: 0.25rem !important;
    }

    .p-sm-2 {
        padding: 0.5rem !important;
    }

    .pt-sm-2,
    .py-sm-2 {
        padding-top: 0.5rem !important;
    }

    .pr-sm-2,
    .px-sm-2 {
        padding-right: 0.5rem !important;
    }

    .pb-sm-2,
    .py-sm-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-sm-2,
    .px-sm-2 {
        padding-left: 0.5rem !important;
    }

    .p-sm-3 {
        padding: 1rem !important;
    }

    .pt-sm-3,
    .py-sm-3 {
        padding-top: 1rem !important;
    }

    .pr-sm-3,
    .px-sm-3 {
        padding-right: 1rem !important;
    }

    .pb-sm-3,
    .py-sm-3 {
        padding-bottom: 1rem !important;
    }

    .pl-sm-3,
    .px-sm-3 {
        padding-left: 1rem !important;
    }

    .p-sm-4 {
        padding: 1.5rem !important;
    }

    .pt-sm-4,
    .py-sm-4 {
        padding-top: 1.5rem !important;
    }

    .pr-sm-4,
    .px-sm-4 {
        padding-right: 1.5rem !important;
    }

    .pb-sm-4,
    .py-sm-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-sm-4,
    .px-sm-4 {
        padding-left: 1.5rem !important;
    }

    .p-sm-5 {
        padding: 3rem !important;
    }

    .pt-sm-5,
    .py-sm-5 {
        padding-top: 3rem !important;
    }

    .pr-sm-5,
    .px-sm-5 {
        padding-right: 3rem !important;
    }

    .pb-sm-5,
    .py-sm-5 {
        padding-bottom: 3rem !important;
    }

    .pl-sm-5,
    .px-sm-5 {
        padding-left: 3rem !important;
    }

    .m-sm-auto {
        margin: auto !important;
    }

    .mt-sm-auto,
    .my-sm-auto {
        margin-top: auto !important;
    }

    .mr-sm-auto,
    .mx-sm-auto {
        margin-right: auto !important;
    }

    .mb-sm-auto,
    .my-sm-auto {
        margin-bottom: auto !important;
    }

    .ml-sm-auto,
    .mx-sm-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 768px) {
    .m-md-0 {
        margin: 0 !important;
    }

    .mt-md-0,
    .my-md-0 {
        margin-top: 0 !important;
    }

    .mr-md-0,
    .mx-md-0 {
        margin-right: 0 !important;
    }

    .mb-md-0,
    .my-md-0 {
        margin-bottom: 0 !important;
    }

    .ml-md-0,
    .mx-md-0 {
        margin-left: 0 !important;
    }

    .m-md-1 {
        margin: 0.25rem !important;
    }

    .mt-md-1,
    .my-md-1 {
        margin-top: 0.25rem !important;
    }

    .mr-md-1,
    .mx-md-1 {
        margin-right: 0.25rem !important;
    }

    .mb-md-1,
    .my-md-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-md-1,
    .mx-md-1 {
        margin-left: 0.25rem !important;
    }

    .m-md-2 {
        margin: 0.5rem !important;
    }

    .mt-md-2,
    .my-md-2 {
        margin-top: 0.5rem !important;
    }

    .mr-md-2,
    .mx-md-2 {
        margin-right: 0.5rem !important;
    }

    .mb-md-2,
    .my-md-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-md-2,
    .mx-md-2 {
        margin-left: 0.5rem !important;
    }

    .m-md-3 {
        margin: 1rem !important;
    }

    .mt-md-3,
    .my-md-3 {
        margin-top: 1rem !important;
    }

    .mr-md-3,
    .mx-md-3 {
        margin-right: 1rem !important;
    }

    .mb-md-3,
    .my-md-3 {
        margin-bottom: 1rem !important;
    }

    .ml-md-3,
    .mx-md-3 {
        margin-left: 1rem !important;
    }

    .m-md-4 {
        margin: 1.5rem !important;
    }

    .mt-md-4,
    .my-md-4 {
        margin-top: 1.5rem !important;
    }

    .mr-md-4,
    .mx-md-4 {
        margin-right: 1.5rem !important;
    }

    .mb-md-4,
    .my-md-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-md-4,
    .mx-md-4 {
        margin-left: 1.5rem !important;
    }

    .m-md-5 {
        margin: 3rem !important;
    }

    .mt-md-5,
    .my-md-5 {
        margin-top: 3rem !important;
    }

    .mr-md-5,
    .mx-md-5 {
        margin-right: 3rem !important;
    }

    .mb-md-5,
    .my-md-5 {
        margin-bottom: 3rem !important;
    }

    .ml-md-5,
    .mx-md-5 {
        margin-left: 3rem !important;
    }

    .p-md-0 {
        padding: 0 !important;
    }

    .pt-md-0,
    .py-md-0 {
        padding-top: 0 !important;
    }

    .pr-md-0,
    .px-md-0 {
        padding-right: 0 !important;
    }

    .pb-md-0,
    .py-md-0 {
        padding-bottom: 0 !important;
    }

    .pl-md-0,
    .px-md-0 {
        padding-left: 0 !important;
    }

    .p-md-1 {
        padding: 0.25rem !important;
    }

    .pt-md-1,
    .py-md-1 {
        padding-top: 0.25rem !important;
    }

    .pr-md-1,
    .px-md-1 {
        padding-right: 0.25rem !important;
    }

    .pb-md-1,
    .py-md-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-md-1,
    .px-md-1 {
        padding-left: 0.25rem !important;
    }

    .p-md-2 {
        padding: 0.5rem !important;
    }

    .pt-md-2,
    .py-md-2 {
        padding-top: 0.5rem !important;
    }

    .pr-md-2,
    .px-md-2 {
        padding-right: 0.5rem !important;
    }

    .pb-md-2,
    .py-md-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-md-2,
    .px-md-2 {
        padding-left: 0.5rem !important;
    }

    .p-md-3 {
        padding: 1rem !important;
    }

    .pt-md-3,
    .py-md-3 {
        padding-top: 1rem !important;
    }

    .pr-md-3,
    .px-md-3 {
        padding-right: 1rem !important;
    }

    .pb-md-3,
    .py-md-3 {
        padding-bottom: 1rem !important;
    }

    .pl-md-3,
    .px-md-3 {
        padding-left: 1rem !important;
    }

    .p-md-4 {
        padding: 1.5rem !important;
    }

    .pt-md-4,
    .py-md-4 {
        padding-top: 1.5rem !important;
    }

    .pr-md-4,
    .px-md-4 {
        padding-right: 1.5rem !important;
    }

    .pb-md-4,
    .py-md-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-md-4,
    .px-md-4 {
        padding-left: 1.5rem !important;
    }

    .p-md-5 {
        padding: 3rem !important;
    }

    .pt-md-5,
    .py-md-5 {
        padding-top: 3rem !important;
    }

    .pr-md-5,
    .px-md-5 {
        padding-right: 3rem !important;
    }

    .pb-md-5,
    .py-md-5 {
        padding-bottom: 3rem !important;
    }

    .pl-md-5,
    .px-md-5 {
        padding-left: 3rem !important;
    }

    .m-md-auto {
        margin: auto !important;
    }

    .mt-md-auto,
    .my-md-auto {
        margin-top: auto !important;
    }

    .mr-md-auto,
    .mx-md-auto {
        margin-right: auto !important;
    }

    .mb-md-auto,
    .my-md-auto {
        margin-bottom: auto !important;
    }

    .ml-md-auto,
    .mx-md-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 992px) {
    .m-lg-0 {
        margin: 0 !important;
    }

    .mt-lg-0,
    .my-lg-0 {
        margin-top: 0 !important;
    }

    .mr-lg-0,
    .mx-lg-0 {
        margin-right: 0 !important;
    }

    .mb-lg-0,
    .my-lg-0 {
        margin-bottom: 0 !important;
    }

    .ml-lg-0,
    .mx-lg-0 {
        margin-left: 0 !important;
    }

    .m-lg-1 {
        margin: 0.25rem !important;
    }

    .mt-lg-1,
    .my-lg-1 {
        margin-top: 0.25rem !important;
    }

    .mr-lg-1,
    .mx-lg-1 {
        margin-right: 0.25rem !important;
    }

    .mb-lg-1,
    .my-lg-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-lg-1,
    .mx-lg-1 {
        margin-left: 0.25rem !important;
    }

    .m-lg-2 {
        margin: 0.5rem !important;
    }

    .mt-lg-2,
    .my-lg-2 {
        margin-top: 0.5rem !important;
    }

    .mr-lg-2,
    .mx-lg-2 {
        margin-right: 0.5rem !important;
    }

    .mb-lg-2,
    .my-lg-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-lg-2,
    .mx-lg-2 {
        margin-left: 0.5rem !important;
    }

    .m-lg-3 {
        margin: 1rem !important;
    }

    .mt-lg-3,
    .my-lg-3 {
        margin-top: 1rem !important;
    }

    .mr-lg-3,
    .mx-lg-3 {
        margin-right: 1rem !important;
    }

    .mb-lg-3,
    .my-lg-3 {
        margin-bottom: 1rem !important;
    }

    .ml-lg-3,
    .mx-lg-3 {
        margin-left: 1rem !important;
    }

    .m-lg-4 {
        margin: 1.5rem !important;
    }

    .mt-lg-4,
    .my-lg-4 {
        margin-top: 1.5rem !important;
    }

    .mr-lg-4,
    .mx-lg-4 {
        margin-right: 1.5rem !important;
    }

    .mb-lg-4,
    .my-lg-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-lg-4,
    .mx-lg-4 {
        margin-left: 1.5rem !important;
    }

    .m-lg-5 {
        margin: 3rem !important;
    }

    .mt-lg-5,
    .my-lg-5 {
        margin-top: 3rem !important;
    }

    .mr-lg-5,
    .mx-lg-5 {
        margin-right: 3rem !important;
    }

    .mb-lg-5,
    .my-lg-5 {
        margin-bottom: 3rem !important;
    }

    .ml-lg-5,
    .mx-lg-5 {
        margin-left: 3rem !important;
    }

    .p-lg-0 {
        padding: 0 !important;
    }

    .pt-lg-0,
    .py-lg-0 {
        padding-top: 0 !important;
    }

    .pr-lg-0,
    .px-lg-0 {
        padding-right: 0 !important;
    }

    .pb-lg-0,
    .py-lg-0 {
        padding-bottom: 0 !important;
    }

    .pl-lg-0,
    .px-lg-0 {
        padding-left: 0 !important;
    }

    .p-lg-1 {
        padding: 0.25rem !important;
    }

    .pt-lg-1,
    .py-lg-1 {
        padding-top: 0.25rem !important;
    }

    .pr-lg-1,
    .px-lg-1 {
        padding-right: 0.25rem !important;
    }

    .pb-lg-1,
    .py-lg-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-lg-1,
    .px-lg-1 {
        padding-left: 0.25rem !important;
    }

    .p-lg-2 {
        padding: 0.5rem !important;
    }

    .pt-lg-2,
    .py-lg-2 {
        padding-top: 0.5rem !important;
    }

    .pr-lg-2,
    .px-lg-2 {
        padding-right: 0.5rem !important;
    }

    .pb-lg-2,
    .py-lg-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-lg-2,
    .px-lg-2 {
        padding-left: 0.5rem !important;
    }

    .p-lg-3 {
        padding: 1rem !important;
    }

    .pt-lg-3,
    .py-lg-3 {
        padding-top: 1rem !important;
    }

    .pr-lg-3,
    .px-lg-3 {
        padding-right: 1rem !important;
    }

    .pb-lg-3,
    .py-lg-3 {
        padding-bottom: 1rem !important;
    }

    .pl-lg-3,
    .px-lg-3 {
        padding-left: 1rem !important;
    }

    .p-lg-4 {
        padding: 1.5rem !important;
    }

    .pt-lg-4,
    .py-lg-4 {
        padding-top: 1.5rem !important;
    }

    .pr-lg-4,
    .px-lg-4 {
        padding-right: 1.5rem !important;
    }

    .pb-lg-4,
    .py-lg-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-lg-4,
    .px-lg-4 {
        padding-left: 1.5rem !important;
    }

    .p-lg-5 {
        padding: 3rem !important;
    }

    .pt-lg-5,
    .py-lg-5 {
        padding-top: 3rem !important;
    }

    .pr-lg-5,
    .px-lg-5 {
        padding-right: 3rem !important;
    }

    .pb-lg-5,
    .py-lg-5 {
        padding-bottom: 3rem !important;
    }

    .pl-lg-5,
    .px-lg-5 {
        padding-left: 3rem !important;
    }

    .m-lg-auto {
        margin: auto !important;
    }

    .mt-lg-auto,
    .my-lg-auto {
        margin-top: auto !important;
    }

    .mr-lg-auto,
    .mx-lg-auto {
        margin-right: auto !important;
    }

    .mb-lg-auto,
    .my-lg-auto {
        margin-bottom: auto !important;
    }

    .ml-lg-auto,
    .mx-lg-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 1200px) {
    .m-xl-0 {
        margin: 0 !important;
    }

    .mt-xl-0,
    .my-xl-0 {
        margin-top: 0 !important;
    }

    .mr-xl-0,
    .mx-xl-0 {
        margin-right: 0 !important;
    }

    .mb-xl-0,
    .my-xl-0 {
        margin-bottom: 0 !important;
    }

    .ml-xl-0,
    .mx-xl-0 {
        margin-left: 0 !important;
    }

    .m-xl-1 {
        margin: 0.25rem !important;
    }

    .mt-xl-1,
    .my-xl-1 {
        margin-top: 0.25rem !important;
    }

    .mr-xl-1,
    .mx-xl-1 {
        margin-right: 0.25rem !important;
    }

    .mb-xl-1,
    .my-xl-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-xl-1,
    .mx-xl-1 {
        margin-left: 0.25rem !important;
    }

    .m-xl-2 {
        margin: 0.5rem !important;
    }

    .mt-xl-2,
    .my-xl-2 {
        margin-top: 0.5rem !important;
    }

    .mr-xl-2,
    .mx-xl-2 {
        margin-right: 0.5rem !important;
    }

    .mb-xl-2,
    .my-xl-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-xl-2,
    .mx-xl-2 {
        margin-left: 0.5rem !important;
    }

    .m-xl-3 {
        margin: 1rem !important;
    }

    .mt-xl-3,
    .my-xl-3 {
        margin-top: 1rem !important;
    }

    .mr-xl-3,
    .mx-xl-3 {
        margin-right: 1rem !important;
    }

    .mb-xl-3,
    .my-xl-3 {
        margin-bottom: 1rem !important;
    }

    .ml-xl-3,
    .mx-xl-3 {
        margin-left: 1rem !important;
    }

    .m-xl-4 {
        margin: 1.5rem !important;
    }

    .mt-xl-4,
    .my-xl-4 {
        margin-top: 1.5rem !important;
    }

    .mr-xl-4,
    .mx-xl-4 {
        margin-right: 1.5rem !important;
    }

    .mb-xl-4,
    .my-xl-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-xl-4,
    .mx-xl-4 {
        margin-left: 1.5rem !important;
    }

    .m-xl-5 {
        margin: 3rem !important;
    }

    .mt-xl-5,
    .my-xl-5 {
        margin-top: 3rem !important;
    }

    .mr-xl-5,
    .mx-xl-5 {
        margin-right: 3rem !important;
    }

    .mb-xl-5,
    .my-xl-5 {
        margin-bottom: 3rem !important;
    }

    .ml-xl-5,
    .mx-xl-5 {
        margin-left: 3rem !important;
    }

    .p-xl-0 {
        padding: 0 !important;
    }

    .pt-xl-0,
    .py-xl-0 {
        padding-top: 0 !important;
    }

    .pr-xl-0,
    .px-xl-0 {
        padding-right: 0 !important;
    }

    .pb-xl-0,
    .py-xl-0 {
        padding-bottom: 0 !important;
    }

    .pl-xl-0,
    .px-xl-0 {
        padding-left: 0 !important;
    }

    .p-xl-1 {
        padding: 0.25rem !important;
    }

    .pt-xl-1,
    .py-xl-1 {
        padding-top: 0.25rem !important;
    }

    .pr-xl-1,
    .px-xl-1 {
        padding-right: 0.25rem !important;
    }

    .pb-xl-1,
    .py-xl-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-xl-1,
    .px-xl-1 {
        padding-left: 0.25rem !important;
    }

    .p-xl-2 {
        padding: 0.5rem !important;
    }

    .pt-xl-2,
    .py-xl-2 {
        padding-top: 0.5rem !important;
    }

    .pr-xl-2,
    .px-xl-2 {
        padding-right: 0.5rem !important;
    }

    .pb-xl-2,
    .py-xl-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-xl-2,
    .px-xl-2 {
        padding-left: 0.5rem !important;
    }

    .p-xl-3 {
        padding: 1rem !important;
    }

    .pt-xl-3,
    .py-xl-3 {
        padding-top: 1rem !important;
    }

    .pr-xl-3,
    .px-xl-3 {
        padding-right: 1rem !important;
    }

    .pb-xl-3,
    .py-xl-3 {
        padding-bottom: 1rem !important;
    }

    .pl-xl-3,
    .px-xl-3 {
        padding-left: 1rem !important;
    }

    .p-xl-4 {
        padding: 1.5rem !important;
    }

    .pt-xl-4,
    .py-xl-4 {
        padding-top: 1.5rem !important;
    }

    .pr-xl-4,
    .px-xl-4 {
        padding-right: 1.5rem !important;
    }

    .pb-xl-4,
    .py-xl-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-xl-4,
    .px-xl-4 {
        padding-left: 1.5rem !important;
    }

    .p-xl-5 {
        padding: 3rem !important;
    }

    .pt-xl-5,
    .py-xl-5 {
        padding-top: 3rem !important;
    }

    .pr-xl-5,
    .px-xl-5 {
        padding-right: 3rem !important;
    }

    .pb-xl-5,
    .py-xl-5 {
        padding-bottom: 3rem !important;
    }

    .pl-xl-5,
    .px-xl-5 {
        padding-left: 3rem !important;
    }

    .m-xl-auto {
        margin: auto !important;
    }

    .mt-xl-auto,
    .my-xl-auto {
        margin-top: auto !important;
    }

    .mr-xl-auto,
    .mx-xl-auto {
        margin-right: auto !important;
    }

    .mb-xl-auto,
    .my-xl-auto {
        margin-bottom: auto !important;
    }

    .ml-xl-auto,
    .mx-xl-auto {
        margin-left: auto !important;
    }
}

.text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
    text-align: justify !important;
}

.text-nowrap {
    white-space: nowrap !important;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

@media (min-width: 576px) {
    .text-sm-left {
        text-align: left !important;
    }

    .text-sm-right {
        text-align: right !important;
    }

    .text-sm-center {
        text-align: center !important;
    }
}

@media (min-width: 768px) {
    .text-md-left {
        text-align: left !important;
    }

    .text-md-right {
        text-align: right !important;
    }

    .text-md-center {
        text-align: center !important;
    }
}

@media (min-width: 992px) {
    .text-lg-left {
        text-align: left !important;
    }

    .text-lg-right {
        text-align: right !important;
    }

    .text-lg-center {
        text-align: center !important;
    }
}

@media (min-width: 1200px) {
    .text-xl-left {
        text-align: left !important;
    }

    .text-xl-right {
        text-align: right !important;
    }

    .text-xl-center {
        text-align: center !important;
    }
}

.text-lowercase {
    text-transform: lowercase !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.font-weight-light {
    font-weight: 300 !important;
}

.font-weight-normal {
    font-weight: 400 !important;
}

.font-weight-bold {
    font-weight: 700 !important;
}

.font-italic {
    font-style: italic !important;
}

.text-white {
    color: #fff !important;
}

.text-primary {
    color: #0866C6 !important;
}

a.text-primary:hover,
a.text-primary:focus {
    color: #064d95 !important;
}

.text-secondary {
    color: #6c757d !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
    color: #545b62 !important;
}

.text-success {
    color: #23BF08 !important;
}

a.text-success:hover,
a.text-success:focus {
    color: #1a8e06 !important;
}

.text-info {
    color: #17A2B8 !important;
}

a.text-info:hover,
a.text-info:focus {
    color: #117a8b !important;
}

.text-warning {
    color: #F49917 !important;
}

a.text-warning:hover,
a.text-warning:focus {
    color: #ce7d0a !important;
}

.text-danger {
    color: #DC3545 !important;
}

a.text-danger:hover,
a.text-danger:focus {
    color: #bd2130 !important;
}

.text-light {
    color: #f8f9fa !important;
}

a.text-light:hover,
a.text-light:focus {
    color: #dae0e5 !important;
}

.text-dark {
    color: #343a40 !important;
}

a.text-dark:hover,
a.text-dark:focus {
    color: #1d2124 !important;
}

.text-body {
    color: #868ba1 !important;
}

.text-muted {
    color: #6c757d !important;
}

.text-black-50 {
    color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.visible {
    visibility: visible !important;
}

.invisible {
    visibility: hidden !important;
}

@media print {

    *,
    *::before,
    *::after {
        text-shadow: none !important;
        box-shadow: none !important;
    }

    a:not(.btn):not(.sp-container button) {
        text-decoration: underline;
    }

    abbr[title]::after {
        content: " (" attr(title) ")";
    }

    pre {
        white-space: pre-wrap !important;
    }

    pre,
    blockquote {
        border: 1px solid #adb5bd;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }

    @page {
        size: a3;
    }

    body {
        min-width: 992px !important;
    }

    .container {
        min-width: 992px !important;
    }

    .navbar {
        display: none;
    }

    .badge {
        border: 1px solid #000;
    }

    .table {
        border-collapse: collapse !important;
    }

    .table td,
    .table th {
        background-color: #fff !important;
    }

    .table-bordered th,
    .table-bordered td {
        border: 1px solid #dee2e6 !important;
    }

    .table-dark {
        color: inherit;
    }

    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody+tbody {
        border-color: #dee2e6;
    }

    .table .thead-dark th {
        color: inherit;
        border-color: #dee2e6;
    }
}

.hidden-xs-up {
    display: none !important;
}

@media (max-width: 575.98px) {
    .hidden-xs-down {
        display: none !important;
    }
}

@media (min-width: 576px) {
    .hidden-sm-up {
        display: none !important;
    }
}

@media (max-width: 767.98px) {
    .hidden-sm-down {
        display: none !important;
    }
}

@media (min-width: 768px) {
    .hidden-md-up {
        display: none !important;
    }
}

@media (max-width: 991.98px) {
    .hidden-md-down {
        display: none !important;
    }
}

@media (min-width: 992px) {
    .hidden-lg-up {
        display: none !important;
    }
}

@media (max-width: 1199.98px) {
    .hidden-lg-down {
        display: none !important;
    }
}

@media (min-width: 1200px) {
    .hidden-xl-up {
        display: none !important;
    }
}

.hidden-xl-down {
    display: none !important;
}


/* ------------------------------------------------------ */


/* ############### 3. BOOTSTRAP OVERRIDES ############### */


/* ------------------------------------------------------ */


/* ------------------------------------------ */


/* ############### 3.1 Alerts ############### */


/* ------------------------------------------ */

.alert-success {
    color: #1c9806;
    background-color: #bcfcb1;
    border-color: #23BF08;
}

.alert-success hr {
    border-top-color: #1fa707;
}

.alert-success .alert-link {
    color: #136704;
}

.alert-info {
    color: #128294;
    background-color: #beeff7;
    border-color: #17A2B8;
}

.alert-info hr {
    border-top-color: #148ea1;
}

.alert-info .alert-link {
    color: #0d5a66;
}

.alert-warning {
    color: #c47709;
    background-color: #fce6c6;
    border-color: #F49917;
}

.alert-warning hr {
    border-top-color: #e78c0b;
}

.alert-warning .alert-link {
    color: #945a07;
}

.alert-danger {
    color: #b51f2e;
    background-color: #f7d1d5;
    border-color: #DC3545;
}

.alert-danger hr {
    border-top-color: #d32535;
}

.alert-danger .alert-link {
    color: #891823;
}

.alert {
    border-width: 0;
    padding: 15px 20px;
    position: relative;
}

.alert .close {
    opacity: .25;
}

.alert-bordered {
    border-width: 1px;
}

.alert-bordered.alert-success {
    background-color: #f2fef0;
}

.alert-bordered.alert-info {
    background-color: #f7fbff;
}

.alert-bordered.alert-warning {
    background-color: #fef7ed;
}

.alert-bordered.alert-danger {
    background-color: #fceced;
}

.alert-solid {
    border-color: transparent;
    color: #fff;
}

.alert-solid.alert-success {
    background-color: #23BF08;
}

.alert-solid.alert-info {
    background-color: #17A2B8;
}

.alert-solid.alert-warning {
    background-color: #F49917;
}

.alert-solid.alert-danger {
    background-color: #DC3545;
}

.alert-solid.alert-inverse {
    background-color: #343a40;
}

.alert-icon {
    opacity: .95;
    line-height: 0;
    margin-right: 10px;
    position: relative;
}

.alert-outline {
    background-color: transparent;
}

.alert-outline .alert-icon {
    float: left;
    line-height: 0;
    margin-right: 15px;
}

.alert-outline.alert-success {
    border: 1px solid #23BF08;
}


/* ########## RTL SUPPORT ########## */

.rtl .alert-icon {
    margin-right: 0;
    margin-left: 10px;
}


/* ------------------------------------------- */


/* ############### 3.2 Buttons ############### */


/* ------------------------------------------- */

.btn:active,
.sp-container button:active {
    box-shadow: none;
}

.btn-indigo {
    color: #fff;
    background-color: #6610f2;
    border-color: #5b0cdd;
}

.btn-indigo:hover {
    color: #fff;
    background-color: #560bd0;
    border-color: #4709ac;
}

.btn-indigo:focus,
.btn-indigo.focus {
    box-shadow: 0 0 0 0.2rem rgba(91, 12, 221, 0.5);
}

.btn-indigo.disabled,
.btn-indigo:disabled {
    color: #fff;
    background-color: #6610f2;
    border-color: #5b0cdd;
}

.btn-indigo:not(:disabled):not(.disabled):active,
.btn-indigo:not(:disabled):not(.disabled).active,
.show>.btn-indigo.dropdown-toggle {
    color: #fff;
    background-color: #510bc4;
    border-color: #4209a0;
}

.btn-indigo:not(:disabled):not(.disabled):active:focus,
.btn-indigo:not(:disabled):not(.disabled).active:focus,
.show>.btn-indigo.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(91, 12, 221, 0.5);
}

.btn-purple {
    color: #fff;
    background-color: #6f42c1;
    border-color: #643ab0;
}

.btn-purple:hover {
    color: #fff;
    background-color: #5e37a6;
    border-color: #4e2d89;
}

.btn-purple:focus,
.btn-purple.focus {
    box-shadow: 0 0 0 0.2rem rgba(100, 58, 176, 0.5);
}

.btn-purple.disabled,
.btn-purple:disabled {
    color: #fff;
    background-color: #6f42c1;
    border-color: #643ab0;
}

.btn-purple:not(:disabled):not(.disabled):active,
.btn-purple:not(:disabled):not(.disabled).active,
.show>.btn-purple.dropdown-toggle {
    color: #fff;
    background-color: #59339d;
    border-color: #482a80;
}

.btn-purple:not(:disabled):not(.disabled):active:focus,
.btn-purple:not(:disabled):not(.disabled).active:focus,
.show>.btn-purple.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(100, 58, 176, 0.5);
}

.btn-pink {
    color: #fff;
    background-color: #e83e8c;
    border-color: #e5277e;
}

.btn-pink:hover {
    color: #fff;
    background-color: #e41c78;
    border-color: #c21766;
}

.btn-pink:focus,
.btn-pink.focus {
    box-shadow: 0 0 0 0.2rem rgba(229, 39, 126, 0.5);
}

.btn-pink.disabled,
.btn-pink:disabled {
    color: #fff;
    background-color: #e83e8c;
    border-color: #e5277e;
}

.btn-pink:not(:disabled):not(.disabled):active,
.btn-pink:not(:disabled):not(.disabled).active,
.show>.btn-pink.dropdown-toggle {
    color: #fff;
    background-color: #d91a72;
    border-color: #b71660;
}

.btn-pink:not(:disabled):not(.disabled):active:focus,
.btn-pink:not(:disabled):not(.disabled).active:focus,
.show>.btn-pink.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(229, 39, 126, 0.5);
}

.btn-teal {
    color: #fff;
    background-color: #1CAF9A;
    border-color: #189987;
}

.btn-teal:hover {
    color: #fff;
    background-color: #178e7d;
    border-color: #116d60;
}

.btn-teal:focus,
.btn-teal.focus {
    box-shadow: 0 0 0 0.2rem rgba(24, 153, 135, 0.5);
}

.btn-teal.disabled,
.btn-teal:disabled {
    color: #fff;
    background-color: #1CAF9A;
    border-color: #189987;
}

.btn-teal:not(:disabled):not(.disabled):active,
.btn-teal:not(:disabled):not(.disabled).active,
.show>.btn-teal.dropdown-toggle {
    color: #fff;
    background-color: #158373;
    border-color: #106256;
}

.btn-teal:not(:disabled):not(.disabled):active:focus,
.btn-teal:not(:disabled):not(.disabled).active:focus,
.show>.btn-teal.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(24, 153, 135, 0.5);
}

.btn-outline-light {
    color: #adb5bd;
    background-color: transparent;
    background-image: none;
    border-color: #adb5bd;
}

.btn-outline-light:hover {
    color: #fff;
    background-color: #adb5bd;
    border-color: #adb5bd;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(173, 181, 189, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
    color: #adb5bd;
    background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show>.btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #adb5bd;
    border-color: #adb5bd;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(173, 181, 189, 0.5);
}

.btn-outline-indigo {
    color: #6610f2;
    background-color: transparent;
    background-image: none;
    border-color: #6610f2;
}

.btn-outline-indigo:hover {
    color: #fff;
    background-color: #6610f2;
    border-color: #6610f2;
}

.btn-outline-indigo:focus,
.btn-outline-indigo.focus {
    box-shadow: 0 0 0 0.2rem rgba(102, 16, 242, 0.5);
}

.btn-outline-indigo.disabled,
.btn-outline-indigo:disabled {
    color: #6610f2;
    background-color: transparent;
}

.btn-outline-indigo:not(:disabled):not(.disabled):active,
.btn-outline-indigo:not(:disabled):not(.disabled).active,
.show>.btn-outline-indigo.dropdown-toggle {
    color: #fff;
    background-color: #6610f2;
    border-color: #6610f2;
}

.btn-outline-indigo:not(:disabled):not(.disabled):active:focus,
.btn-outline-indigo:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-indigo.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(102, 16, 242, 0.5);
}

.btn-outline-purple {
    color: #6f42c1;
    background-color: transparent;
    background-image: none;
    border-color: #6f42c1;
}

.btn-outline-purple:hover {
    color: #fff;
    background-color: #6f42c1;
    border-color: #6f42c1;
}

.btn-outline-purple:focus,
.btn-outline-purple.focus {
    box-shadow: 0 0 0 0.2rem rgba(111, 66, 193, 0.5);
}

.btn-outline-purple.disabled,
.btn-outline-purple:disabled {
    color: #6f42c1;
    background-color: transparent;
}

.btn-outline-purple:not(:disabled):not(.disabled):active,
.btn-outline-purple:not(:disabled):not(.disabled).active,
.show>.btn-outline-purple.dropdown-toggle {
    color: #fff;
    background-color: #6f42c1;
    border-color: #6f42c1;
}

.btn-outline-purple:not(:disabled):not(.disabled):active:focus,
.btn-outline-purple:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-purple.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(111, 66, 193, 0.5);
}

.btn-outline-pink {
    color: #e83e8c;
    background-color: transparent;
    background-image: none;
    border-color: #e83e8c;
}

.btn-outline-pink:hover {
    color: #fff;
    background-color: #e83e8c;
    border-color: #e83e8c;
}

.btn-outline-pink:focus,
.btn-outline-pink.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 62, 140, 0.5);
}

.btn-outline-pink.disabled,
.btn-outline-pink:disabled {
    color: #e83e8c;
    background-color: transparent;
}

.btn-outline-pink:not(:disabled):not(.disabled):active,
.btn-outline-pink:not(:disabled):not(.disabled).active,
.show>.btn-outline-pink.dropdown-toggle {
    color: #fff;
    background-color: #e83e8c;
    border-color: #e83e8c;
}

.btn-outline-pink:not(:disabled):not(.disabled):active:focus,
.btn-outline-pink:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-pink.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 62, 140, 0.5);
}

.btn-outline-teal {
    color: #1CAF9A;
    background-color: transparent;
    background-image: none;
    border-color: #1CAF9A;
}

.btn-outline-teal:hover {
    color: #fff;
    background-color: #1CAF9A;
    border-color: #1CAF9A;
}

.btn-outline-teal:focus,
.btn-outline-teal.focus {
    box-shadow: 0 0 0 0.2rem rgba(28, 175, 154, 0.5);
}

.btn-outline-teal.disabled,
.btn-outline-teal:disabled {
    color: #1CAF9A;
    background-color: transparent;
}

.btn-outline-teal:not(:disabled):not(.disabled):active,
.btn-outline-teal:not(:disabled):not(.disabled).active,
.show>.btn-outline-teal.dropdown-toggle {
    color: #fff;
    background-color: #1CAF9A;
    border-color: #1CAF9A;
}

.btn-outline-teal:not(:disabled):not(.disabled):active:focus,
.btn-outline-teal:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-teal.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(28, 175, 154, 0.5);
}

.btn-warning {
    color: #fff !important;
}

.btn-oblong {
    border-radius: 50px;
}

.btn-with-icon {
    padding: 0;
    display: inline-block;
    border-width: 0;
}

.btn-with-icon>div {
    position: relative;
    display: flex;
}

.btn-with-icon>div>span {
    display: flex;
    height: 100%;
    align-items: center;
}

.btn-with-icon>div>span.icon {
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
}

.btn-icon {
    display: inline-block;
    padding: 0;
}

.btn-icon>div {
    display: flex;
    width: 35px;
    height: 35px;
    align-items: center;
    justify-content: center;
}

.btn-group {
    direction: ltr;
}


/* ----------------------------------------- */


/* ############### 3.3 Cards ############### */


/* ----------------------------------------- */

.card-header,
.card-footer {
    border-color: #dee2e6;
}

.card-title {
    color: #212529;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin-bottom: 20px;
}

.accordion .card {
    border-radius: 0;
}

.accordion .card+.card {
    border-top-width: 0;
}

.accordion .card:first-child,
.accordion .card:first-child .card-header a {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.accordion .card:last-child,
.accordion .card:last-child .card-header a.collapsed {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.accordion .card-header {
    padding: 0;
    background-color: transparent;
    border-bottom-width: 0;
}

.accordion .card-header a {
    display: block;
    padding: 15px 20px;
}

.accordion .card-header a.collapsed:hover,
.accordion .card-header a.collapsed:focus {
    background-color: #e9ecef;
}

.accordion .card-body {
    padding-top: 0;
}

.accordion-head-colored .card-header a {
    position: relative;
    margin: -1px -1px 16px;
    color: #fff;
    font-weight: 500;
    letter-spacing: -.25px;
}

.accordion-head-colored .card-header a.collapsed {
    background-color: transparent;
    margin: 0;
    font-weight: 600;
}

.accordion-primary .card-header a {
    background-color: #0866C6;
}

.accordion-primary .card-header a.collapsed {
    color: #0866C6;
}

.accordion-success .card-header a {
    background-color: #23BF08;
}

.accordion-success .card-header a.collapsed {
    color: #23BF08;
}

.accordion-warning .card-header a {
    background-color: #F49917;
}

.accordion-warning .card-header a.collapsed {
    color: #F49917;
}

.accordion-danger .card-header a {
    background-color: #DC3545;
}

.accordion-danger .card-header a.collapsed {
    color: #DC3545;
}

.accordion-info .card-header a {
    background-color: #17A2B8;
}

.accordion-info .card-header a.collapsed {
    color: #17A2B8;
}

.accordion-inverse .card-header a {
    background-color: #212529;
}

.accordion-inverse .card-header a.collapsed {
    color: #212529;
}

.card-deck-xs .card:not(:last-child),
.card-deck-xs .card:not(:first-child) {
    margin-left: 5px;
    margin-right: 5px;
}

.card-deck-xs .card:first-child {
    margin-left: 0;
}

.card-deck-xs .card:last-child {
    margin-right: 0;
}

.card-deck-sm .card:not(:last-child),
.card-deck-sm .card:not(:first-child) {
    margin-left: 10px;
    margin-right: 10px;
}

.card-deck-sm .card:first-child {
    margin-left: 0;
}

.card-deck-sm .card:last-child {
    margin-right: 0;
}

.card-deck-lg .card:not(:last-child),
.card-deck-lg .card:not(:first-child) {
    margin-left: 20px;
    margin-right: 20px;
}

.card-deck-lg .card:first-child {
    margin-left: 0;
}

.card-deck-lg .card:last-child {
    margin-right: 0;
}

@media (max-width: 799px) {
    .card-deck {
        display: block;
    }

    .card-deck .card:not(:last-child),
    .card-deck .card:not(:first-child) {
        margin-left: 0;
        margin-right: 0;
    }

    .card-deck .card+.card {
        margin-top: 20px;
    }
}

.card-columns .card {
    margin-bottom: 20px;
}

@media (min-width: 576px) {
    .card-columns {
        column-count: 2;
    }
}

@media (min-width: 992px) {
    .card-columns {
        column-count: 3;
    }
}

@media (min-width: 576px) {
    .card-columns.column-count-2 {
        column-count: 2;
    }
}

@media (min-width: 480px) {
    .card-columns.column-count-4 {
        column-count: 2;
    }
}

@media (min-width: 768px) {
    .card-columns.column-count-4 {
        column-count: 3;
    }
}

@media (min-width: 992px) {
    .card-columns.column-count-4 {
        column-count: 4;
    }
}

@media (min-width: 480px) {
    .card-columns.column-count-5 {
        column-count: 2;
    }
}

@media (min-width: 768px) {
    .card-columns.column-count-5 {
        column-count: 3;
    }
}

@media (min-width: 992px) {
    .card-columns.column-count-5 {
        column-count: 4;
    }
}

@media (min-width: 1200px) {
    .card-columns.column-count-5 {
        column-count: 5;
    }
}

@media (min-width: 480px) {
    .card-columns.column-count-6 {
        column-count: 2;
    }
}

@media (min-width: 768px) {
    .card-columns.column-count-6 {
        column-count: 3;
    }
}

@media (min-width: 992px) {
    .card-columns.column-count-6 {
        column-count: 4;
    }
}

@media (min-width: 1200px) {
    .card-columns.column-count-6 {
        column-count: 6;
    }
}

.card-columns-sm {
    margin-top: -10px;
    -webkit-column-gap: 10px;
    column-gap: 10px;
}

.card-columns-sm .card {
    margin-top: 10px;
    margin-bottom: 0;
}

.card-columns-md {
    margin-top: -20px;
    -webkit-column-gap: 30px;
    column-gap: 30px;
}

.card-columns-md .card {
    margin-top: 20px;
}

.card-columns-lg {
    margin-top: -30px;
    -webkit-column-gap: 40px;
    column-gap: 40px;
}

.card-columns-lg .card {
    margin-top: 30px;
}

.card-item-img {
    margin-bottom: 0;
}

.card-item-img img {
    opacity: 0.2;
    transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .card-item-img img {
        transition: none;
    }
}

.card-item-img:hover img,
.card-item-img:focus img {
    opacity: 0.5;
}

.card-header-tabs {
    flex-direction: column;
}

.card-header-tabs .nav-link.active,
.card-header-tabs .nav-link.active:hover,
.card-header-tabs .nav-link.active:focus {
    background-color: #fff;
    border: 1px solid #dee2e6;
    color: #343a40;
    border-radius: 3px;
}

.card-header-tabs .nav-link:hover,
.card-header-tabs .nav-link:focus {
    border-color: transparent;
}

@media (min-width: 480px) {
    .card-header-tabs {
        flex-direction: row;
    }

    .card-header-tabs .nav-link.active,
    .card-header-tabs .nav-link.active:hover,
    .card-header-tabs .nav-link.active:focus {
        background-color: #fff;
        border-bottom-color: #fff;
        color: #343a40;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
}


/***** RTL SUPPORT *****/

@media (min-width: 992px) {
    .rtl .card-deck-sm .card:first-child {
        margin-left: 10px;
        margin-right: 0;
    }

    .rtl .card-deck-sm .card:last-child {
        margin-right: 10px;
        margin-left: 0;
    }
}


/* -------------------------------------------- */


/* ############### 3.4 Carousel ############### */


/* -------------------------------------------- */

.carousel-control-prev,
.carousel-control-next {
    z-index: 100;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-image: none;
}

.carousel-control-prev-icon::before,
.carousel-control-next-icon::before {
    font-family: 'Ionicons';
    font-size: 24px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.carousel-control-prev-icon::before {
    content: '\f3d2';
    left: 20px;
}

.carousel-control-next-icon::before {
    content: '\f3d3';
    right: 20px;
}

.control-top-left .carousel-control-prev,
.control-top-left .carousel-control-next,
.control-top-right .carousel-control-prev,
.control-top-right .carousel-control-next,
.control-bottom-left .carousel-control-prev,
.control-bottom-left .carousel-control-next,
.control-bottom-right .carousel-control-prev,
.control-bottom-right .carousel-control-next {
    width: 40px;
    height: 55px;
}

.control-top-left .carousel-control-next,
.control-bottom-left .carousel-control-next {
    left: 40px;
    right: auto;
}

.control-top-left .carousel-control-prev-icon::before,
.control-bottom-left .carousel-control-prev-icon::before {
    left: 20px;
}

.control-top-left .carousel-control-next-icon::before,
.control-bottom-left .carousel-control-next-icon::before {
    right: 20px;
}

.control-top-right .carousel-control-prev,
.control-bottom-right .carousel-control-prev {
    right: 40px;
    left: auto;
}

.control-bottom-left .carousel-control-prev,
.control-bottom-left .carousel-control-next,
.control-bottom-right .carousel-control-prev,
.control-bottom-right .carousel-control-next {
    top: auto;
    bottom: 0;
}

.carousel-indicators {
    bottom: 5px;
}

.carousel-indicators li {
    cursor: pointer;
    width: 8px;
    height: 8px;
    border-radius: 50px;
}

.indicators-top-center,
.indicators-top-left,
.indicators-top-right {
    top: 5px;
    bottom: auto;
    margin: 1rem 1rem 0;
}

.indicators-top-left,
.indicators-bottom-left {
    justify-content: flex-start;
}

.indicators-top-right,
.indicators-bottom-right {
    justify-content: flex-end;
}

.indicators-bottom-left,
.indicators-bottom-right {
    margin-left: 1rem;
    margin-right: 1rem;
}


/***** RTL SUPPORT *****/

.rtl .carousel-indicators {
    padding: 0;
}


/* ------------------------------------------ */


/* ############### 3.6 Custom ############### */


/* ------------------------------------------ */

a {
    transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    a {
        transition: none;
    }
}

a:focus,
a:active {
    outline: none !important;
}

.square-6 {
    display: inline-block;
    width: 6px;
    height: 6px;
}

.square-8 {
    display: inline-block;
    width: 8px;
    height: 8px;
}

.square-10 {
    display: inline-block;
    width: 10px;
    height: 10px;
}

.more {
    color: #fff;
    opacity: .2;
    display: inline-block;
    padding: 2px 5px;
}

.more i {
    line-height: 0;
}

.more:hover,
.more:focus {
    color: #fff;
    opacity: .5;
}

.dropdown-menu {
    box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.16);
}

.form-control-inverse {
    border: 0;
    background-color: #18222f;
    color: rgba(255, 255, 255, 0.6);
}

.form-control-inverse:focus {
    background-color: #141d28;
    color: rgba(255, 255, 255, 0.6);
}

.overlay {
    position: relative;
}

.overlay-body {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.row-sm {
    margin-left: -10px;
    margin-right: -10px;
}

.row-sm>div {
    padding-left: 10px;
    padding-right: 10px;
}

.row-xs {
    margin-left: -5px;
    margin-right: -5px;
}

.row-xs>div {
    padding-left: 5px;
    padding-right: 5px;
}

.img-option {
    display: flex;
    margin-bottom: 15px;
    position: relative;
    flex-direction: row;
}

.img-option .img-option-link {
    display: block;
    color: #fff;
    background-color: #000;
    opacity: .65;
}

.img-option .img-option-link div {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (min-width: 576px) {
    .img-option .img-option-link div {
        width: 60px;
        height: 60px;
    }
}

.img-option .img-option-link i {
    font-size: 20px;
    line-height: 0;
}

@media (min-width: 576px) {
    .img-option .img-option-link i {
        font-size: 24px;
    }
}

.img-option .img-option-link:hover,
.img-option .img-option-link:focus {
    opacity: 1;
}

.img-option-sm .img-option-link div {
    width: 40px;
    height: 40px;
}

.img-option-sm .img-option-link i {
    font-size: 18px;
}

.list-group-striped .list-group-item:nth-child(even) {
    background-color: #f8f9fa;
}

.iconwrap {
    display: inline-block;
    line-height: 0;
    border: 1px solid transparent;
    text-align: center;
}

.iconwrap .fa,
.iconwrap .icon {
    display: inline-block;
    position: relative;
    top: -.5px;
}

.iconwrap.icon-16 {
    padding: 6px;
}

.iconwrap.icon-16 .fa,
.iconwrap.icon-16 .icon {
    font-size: 16px;
    width: 16px;
    height: 16px;
}

.iconwrap.icon-18 {
    padding: 8px;
}

.iconwrap.icon-18 .fa,
.iconwrap.icon-18 .icon {
    font-size: 18px;
    width: 18px;
    height: 18px;
}

.iconwrap.icon-20 {
    padding: 8px;
}

.iconwrap.icon-20 .fa,
.iconwrap.icon-20 .icon {
    font-size: 20px;
    width: 20px;
    height: 20px;
}

.iconwrap.icon-24 {
    padding: 10px;
}

.iconwrap.icon-24 .fa,
.iconwrap.icon-24 .icon {
    font-size: 24px;
    width: 24px;
    height: 24px;
}

.iconwrap.icon-28 {
    padding: 10px;
}

.iconwrap.icon-28 .fa,
.iconwrap.icon-28 .icon {
    font-size: 28px;
    width: 28px;
    height: 28px;
}

.iconwrap.icon-32 {
    padding: 12px;
}

.iconwrap.icon-32 .fa,
.iconwrap.icon-32 .icon {
    font-size: 32px;
    width: 32px;
    height: 32px;
}

.iconwrap.icon-36 {
    padding: 12px;
}

.iconwrap.icon-36 .fa,
.iconwrap.icon-36 .icon {
    font-size: 36px;
    width: 36px;
    height: 36px;
}

.iconwrap.icon-40 {
    padding: 14px;
}

.iconwrap.icon-40 .fa,
.iconwrap.icon-40 .icon {
    font-size: 40px;
    width: 40px;
    height: 40px;
}

.iconwrap.icon-44 {
    padding: 14px;
}

.iconwrap.icon-44 .fa,
.iconwrap.icon-44 .icon {
    font-size: 44px;
    width: 44px;
    height: 44px;
}

.iconwrap.icon-48 {
    padding: 16px;
}

.iconwrap.icon-48 .fa,
.iconwrap.icon-48 .icon {
    font-size: 48px;
    width: 48px;
    height: 48px;
}

.icon.tx-size-64 {
    line-height: 0;
}

.icon.tx-size-64::before {
    line-height: 45px;
}


/* ########## RTL SUPPORT ########## */

.rtl .close {
    float: left;
}


/* ------------------------------------------------ */


/* ############### 3.5 Custom Forms ############### */


/* ------------------------------------------------ */


/* Checkbox */

.ckbox {
    font-weight: normal;
    position: relative;
    display: block;
    line-height: 18px;
}

.ckbox span {
    padding-left: 6px;
}

.ckbox span:empty {
    float: left;
}

.ckbox span:before,
.ckbox span:after {
    line-height: 18px;
    position: absolute;
}

.ckbox span:before {
    content: '';
    width: 16px;
    height: 16px;
    background-color: #fff;
    border: 1px solid #adb5bd;
    border-radius: 2px;
    top: 1px;
    left: 0;
}

.ckbox span:after {
    top: 1px;
    left: 0;
    width: 16px;
    height: 16px;
    content: '\f122';
    font-family: 'Ionicons';
    font-size: 9px;
    text-align: center;
    color: #fff;
    background-color: #0866C6;
    line-height: 1.65;
    border-radius: 2px;
    display: none;
}

.ckbox input[type='checkbox'] {
    opacity: 0;
    margin: 0 5px 0 0;
}

.ckbox input[type='checkbox']:checked+span:after {
    display: block;
}

.ckbox input[type='checkbox'][disabled]+span,
.ckbox input[type='checkbox'][disabled]+span:before,
.ckbox input[type='checkbox'][disabled]+span:after {
    opacity: .75;
}

.ckbox.full-opacity input[type='checkbox'][disabled]+span,
.ckbox.full-opacity input[type='checkbox'][disabled]+span:before,
.ckbox.full-opacity input[type='checkbox'][disabled]+span:after {
    opacity: 1 !important;
}

.ckbox-inline {
    display: inline-block;
}


/* Checkbox color variant */

.ckbox-success span::after {
    background-color: #23BF08;
}

.ckbox-warning span::after {
    background-color: #F49917;
}

.ckbox-danger span::after {
    background-color: #DC3545;
}

.ckbox-info span::after {
    background-color: #17A2B8;
}

.ckbox-indigo span::after {
    background-color: #6610f2;
}

.ckbox-purple span::after {
    background-color: #6f42c1;
}

.ckbox-pink span::after {
    background-color: #e83e8c;
}

.ckbox-orange span::after {
    background-color: #f27510;
}

.ckbox-teal span::after {
    background-color: #1CAF9A;
}

.ckbox-dark span::after {
    background-color: #343a40;
}


/* Radio Box */

.rdiobox {
    font-weight: normal;
    position: relative;
    display: block;
    line-height: 18px;
}

.rdiobox span {
    padding-left: 6px;
}

.rdiobox span:before,
.rdiobox span:after {
    line-height: 18px;
    position: absolute;
}

.rdiobox span:before {
    content: '';
    width: 16px;
    height: 16px;
    background-color: #fff;
    border: 1px solid #adb5bd;
    border-radius: 50px;
    top: 2px;
    left: 0;
}

.rdiobox span:after {
    content: '';
    width: 6px;
    height: 6px;
    background-color: #fff;
    border-radius: 50px;
    top: 7px;
    left: 5px;
    display: none;
}

.rdiobox input[type='radio'] {
    opacity: 0 !important;
    margin: 0 5px 0 0;
}

.rdiobox input[type='radio']:checked+span:before {
    border-color: transparent;
    background-color: #0866C6;
}

.rdiobox input[type='radio']:checked+span:after {
    display: block;
}

.rdiobox input[type='radio'][disabled]+span,
.rdiobox input[type='radio'][disabled]+span:before,
.rdiobox input[type='radio'][disabled]+span:after {
    opacity: .75;
}

.rdiobox-inline {
    display: inline-block;
}


/* Radiobox Color Variant */

.rdiobox-success input[type='radio']:checked+span:before {
    background-color: #23BF08;
}

.rdiobox-warning input[type='radio']:checked+span:before {
    background-color: #F49917;
}

.rdiobox-danger input[type='radio']:checked+span:before {
    background-color: #DC3545;
}

.rdiobox-info input[type='radio']:checked+span:before {
    background-color: #17A2B8;
}

.rdiobox-indigo input[type='radio']:checked+span:before {
    background-color: #6610f2;
}

.rdiobox-purple input[type='radio']:checked+span:before {
    background-color: #6f42c1;
}

.rdiobox-orange input[type='radio']:checked+span:before {
    background-color: #f27510;
}

.rdiobox-pink input[type='radio']:checked+span:before {
    background-color: #e83e8c;
}

.rdiobox-teal input[type='radio']:checked+span:before {
    background-color: #1CAF9A;
}

.rdiobox-dark input[type='radio']:checked+span:before {
    background-color: #343a40;
}


/* Inside input group */

.input-group-text .ckbox,
.input-group-text .ckbox input,
.input-group-text .rdiobox,
.input-group-text .rdiobox input {
    margin: 0;
}


/* File Browser */

.custom-file {
    direction: ltr;
}

.custom-file-label-primary::after {
    background-color: #0866C6;
    border-color: transparent;
    color: #fff;
}

.custom-file-label-inverse::after {
    background-color: #343a40;
    border-color: transparent;
    color: #fff;
}

.custom-file-label-primary::after,
.custom-file-label-inverse::after {
    top: -1px;
    bottom: -1px;
    right: -1px;
    height: auto;
}


/* Switch Button */

.br-switchbutton {
    margin: 0 25px;
    position: relative;
    background-color: rgba(255, 255, 255, 0.08);
    width: 30px;
    height: 15px;
    border-radius: 2px;
    cursor: pointer;
}

.br-switchbutton::before,
.br-switchbutton::after {
    text-transform: uppercase;
    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
    position: absolute;
    top: 2px;
    font-size: 11px;
    display: block;
    line-height: 1.3;
}

.br-switchbutton::before {
    content: 'off';
    left: -25px;
    color: #1CAF9A;
}

.br-switchbutton::after {
    content: 'on';
    right: -20px;
    color: #868ba1;
}

.br-switchbutton span {
    position: absolute;
    background-color: #fff;
    border-radius: 2px;
    top: 2px;
    bottom: 2px;
    left: 2px;
    width: 11px;
    box-shadow: 0 0 2px rgba(33, 37, 41, 0.3);
    transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .br-switchbutton span {
        transition: none;
    }
}

.br-switchbutton.checked {
    background-color: #1CAF9A;
}

.br-switchbutton.checked::before {
    color: #868ba1;
}

.br-switchbutton.checked::after {
    color: #1CAF9A;
}

.br-switchbutton.checked span {
    left: 17px;
}


/* ########## RTL SUPPORT ########## */

.rtl .ckbox span {
    padding-left: 0;
    padding-right: 6px;
}

.rtl .ckbox span:empty {
    float: right;
}

.rtl .ckbox span:before,
.rtl .ckbox span:after {
    left: auto;
    right: 0;
}

.rtl .ckbox input[type='checkbox'] {
    margin: 0 0 0 5px;
}

.rtl .rdiobox span {
    padding-left: 0;
    padding-right: 6px;
}

.rtl .rdiobox span:before {
    left: auto;
    right: 0;
}

.rtl .rdiobox span:after {
    left: auto;
    right: 5px;
}

.rtl .rdiobox input[type='radio'] {
    margin: 0 0 0 5px;
}


/* ----------------------------------------- */


/* ############### 3.8 Forms ############### */


/* ----------------------------------------- */


/* #################### VALIDATION #################### */

.is-valid:focus,
input.parsley-success:focus,
textarea.parsley-success:focus,
.is-valid:active,
input.parsley-success:active,
textarea.parsley-success:active,
.is-invalid:focus,
input.parsley-error:focus,
textarea.parsley-error:focus,
.is-invalid:active,
input.parsley-error:active,
textarea.parsley-error:active {
    box-shadow: none !important;
}

.is-warning,
.is-warning:focus,
.is-warning:active {
    border-color: #F49917;
}


/* #################### INPUT FORM STYLES #################### */

.fc-outline,
.fc-outline:focus,
.fc-outline:active,
.fc-outline-dark,
.fc-outline-dark:focus,
.fc-outline-dark:active {
    background-color: transparent;
}

.fc-outline:focus,
.fc-outline:active {
    border-color: #adb5bd;
}

.fc-outline-dark {
    border-color: rgba(255, 255, 255, 0.3);
    color: #fff;
}

.fc-outline-dark:focus,
.fc-outline-dark:active {
    border-color: rgba(255, 255, 255, 0.75);
    color: rgba(255, 255, 255, 0.75);
}

.fc-focus-primary:focus,
.fc-focus-primary:active {
    border-color: #0866C6;
}

.fc-focus-success:focus,
.fc-focus-success:active {
    border-color: #23BF08;
}

.fc-focus-warning:focus,
.fc-focus-warning:active {
    border-color: #F49917;
}

.fc-focus-danger:focus,
.fc-focus-danger:active {
    border-color: #DC3545;
}

.fc-focus-info:focus,
.fc-focus-info:active {
    border-color: #17A2B8;
}

.fc-focus-indigo:focus,
.fc-focus-indigo:active {
    border-color: #6610f2;
}

.fc-focus-purple:focus,
.fc-focus-purple:active {
    border-color: #6f42c1;
}

.fc-focus-teal:focus,
.fc-focus-teal:active {
    border-color: #1CAF9A;
}

.fc-focus-pink:focus,
.fc-focus-pink:active {
    border-color: #e83e8c;
}

.fc-focus-orange:focus,
.fc-focus-orange:active {
    border-color: #f27510;
}


/* #################### INPUT FILE BROWSER #################### */

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile+label {
    margin-bottom: 0;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 8px 20px;
    border-radius: 3px;
    transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .inputfile+label {
        transition: none;
    }
}

.inputfile+label i {
    vertical-align: middle;
    margin-top: -0.25em;
    margin-right: 10px;
    line-height: 0;
}

.inputfile+label.bg-primary:hover {
    background-color: #0759ad !important;
}

.inputfile+label.bg-success:hover {
    background-color: #1fa707 !important;
}

.inputfile+label.bg-warning:hover {
    background-color: #e78c0b !important;
}

.inputfile+label.bg-danger:hover {
    background-color: #d32535 !important;
}

.inputfile+label.bg-info:hover {
    background-color: #148ea1 !important;
}

.inputfile+label.bg-indigo:hover {
    background-color: #5b0cdd;
}

.inputfile+label.bg-purple:hover {
    background-color: #643ab0;
}

.inputfile+label.bg-pink:hover {
    background-color: #e5277e;
}

.inputfile+label.bg-orange:hover {
    background-color: #dd690c;
}

.inputfile+label.bg-teal:hover {
    background-color: #189987;
}

.inputfile+label.bg-dark:hover {
    background-color: #292d32;
}

.inputfile+label.if-outline {
    padding: 6px 18px;
}

.inputfile+label.if-outline-primary {
    border: 2px solid #0866C6;
    color: #0866C6;
}

.inputfile+label.if-outline-primary:hover {
    color: #0759ad;
    border-color: #0759ad;
}

.inputfile+label.if-outline-success {
    border: 2px solid #23BF08;
    color: #23BF08;
}

.inputfile+label.if-outline-success:hover {
    color: #1fa707;
    border-color: #1fa707;
}

.inputfile+label.if-outline-warning {
    border: 2px solid #F49917;
    color: #F49917;
}

.inputfile+label.if-outline-warning:hover {
    color: #e78c0b;
    border-color: #e78c0b;
}

.inputfile+label.if-outline-danger {
    border: 2px solid #DC3545;
    color: #DC3545;
}

.inputfile+label.if-outline-danger:hover {
    color: #d32535;
    border-color: #d32535;
}

.inputfile+label.if-outline-info {
    border: 2px solid #17A2B8;
    color: #17A2B8;
}

.inputfile+label.if-outline-info:hover {
    color: #148ea1;
    border-color: #148ea1;
}

.inputfile+label.if-outline-indigo {
    border: 2px solid #6610f2;
    color: #6610f2;
}

.inputfile+label.if-outline-indigo:hover {
    color: #5b0cdd;
    border-color: #5b0cdd;
}

.inputfile+label.if-outline-purple {
    border: 2px solid #6f42c1;
    color: #6f42c1;
}

.inputfile+label.if-outline-purple:hover {
    color: #643ab0;
    border-color: #643ab0;
}

.inputfile+label.if-outline-pink {
    border: 2px solid #e83e8c;
    color: #e83e8c;
}

.inputfile+label.if-outline-pink:hover {
    color: #e5277e;
    border-color: #e5277e;
}

.inputfile+label.if-outline-orange {
    border: 2px solid #f27510;
    color: #f27510;
}

.inputfile+label.if-outline-orange:hover {
    color: #dd690c;
    border-color: #dd690c;
}

.inputfile+label.if-outline-teal {
    border: 2px solid #1CAF9A;
    color: #1CAF9A;
}

.inputfile+label.if-outline-teal:hover {
    color: #189987;
    border-color: #189987;
}

.inputfile+label.if-outline-dark {
    border: 2px solid #343a40;
    color: #343a40;
}

.inputfile+label.if-outline-dark:hover {
    color: #292d32;
    border-color: #292d32;
}

.inputfile+.if-style-1 {
    text-align: center;
    color: #17A2B8;
}

.inputfile+.if-style-1 .icon-wrapper {
    width: 100px;
    height: 100px;
    background-color: #17A2B8;
    color: #fff;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 64px;
    margin: 0 auto 10px;
}

.inputfile+.if-style-1 .icon-wrapper>i {
    height: 50px;
    margin-right: 0;
    line-height: 0;
}

.inputfile+.if-style-1.if-primary {
    color: #0866C6;
}

.inputfile+.if-style-1.if-primary .icon-wrapper {
    background-color: #0866C6;
}

.inputfile+.if-style-1.if-success {
    color: #23BF08;
}

.inputfile+.if-style-1.if-success .icon-wrapper {
    background-color: #23BF08;
}

.inputfile+.if-style-1.if-warning {
    color: #F49917;
}

.inputfile+.if-style-1.if-warning .icon-wrapper {
    background-color: #F49917;
}

.inputfile+.if-style-1.if-danger {
    color: #DC3545;
}

.inputfile+.if-style-1.if-danger .icon-wrapper {
    background-color: #DC3545;
}

.inputfile+.if-style-1.if-info {
    color: #17A2B8;
}

.inputfile+.if-style-1.if-info .icon-wrapper {
    background-color: #17A2B8;
}

.inputfile+.if-style-1.if-indigo {
    color: #6610f2;
}

.inputfile+.if-style-1.if-indigo .icon-wrapper {
    background-color: #6610f2;
}

.inputfile+.if-style-1.if-purple {
    color: #6f42c1;
}

.inputfile+.if-style-1.if-purple .icon-wrapper {
    background-color: #6f42c1;
}

.inputfile+.if-style-1.if-teal {
    color: #1CAF9A;
}

.inputfile+.if-style-1.if-teal .icon-wrapper {
    background-color: #1CAF9A;
}

.inputfile+.if-style-1.if-pink {
    color: #e83e8c;
}

.inputfile+.if-style-1.if-pink .icon-wrapper {
    background-color: #e83e8c;
}

.inputfile+.if-style-1.if-orange {
    color: #f27510;
}

.inputfile+.if-style-1.if-orange .icon-wrapper {
    background-color: #f27510;
}

.inputfile+.if-style-1.if-dark {
    color: #343a40;
}

.inputfile+.if-style-1.if-dark .icon-wrapper {
    background-color: #343a40;
}

.inputfile+.if-style-1.if-light {
    color: rgba(255, 255, 255, 0.6);
}

.inputfile+.if-style-1.if-light .icon-wrapper {
    background-color: rgba(255, 255, 255, 0.6);
}


/* #################### RTL SUPPORT #################### */

.rtl .input-group .input-group-addon:not(:last-child),
.rtl .input-group .form-control:not(:last-child),
.rtl .input-group .dataTables_filter input:not(:last-child),
.dataTables_filter .rtl .input-group input:not(:last-child) {
    border-left-width: 0;
    border-right: 1px solid #ced4da;
}

.rtl .input-group .input-group-addon:first-child,
.rtl .input-group .form-control:first-child,
.rtl .input-group .dataTables_filter input:first-child,
.dataTables_filter .rtl .input-group input:first-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rtl .input-group .input-group-addon:last-child,
.rtl .input-group .form-control:last-child,
.rtl .input-group .dataTables_filter input:last-child,
.dataTables_filter .rtl .input-group input:last-child {
    border-left: 1px solid #ced4da;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rtl .input-group .input-group-btn:not(:last-child) .btn,
.rtl .input-group .input-group-btn:not(:last-child) .sp-container button,
.sp-container .rtl .input-group .input-group-btn:not(:last-child) button {
    border-left: 0;
    border-right: 1px solid #ced4da;
}

.rtl .input-group .input-group-btn:first-child .btn,
.rtl .input-group .input-group-btn:first-child .sp-container button,
.sp-container .rtl .input-group .input-group-btn:first-child button {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rtl .input-group .input-group-btn:last-child .btn,
.rtl .input-group .input-group-btn:last-child .sp-container button,
.sp-container .rtl .input-group .input-group-btn:last-child button {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rtl .input-group .form-control:focus,
.rtl .input-group .dataTables_filter input:focus,
.dataTables_filter .rtl .input-group input:focus {
    border-color: #ced4da;
}

.rtl .inputfile+label i {
    margin-left: 10px;
    margin-right: 0;
}

.rtl .inputfile+.if-style-1 .icon-wrapper>i {
    margin-left: 0;
}


/* ------------------------------------------------- */


/* ############### 3.7 Form Layoyuts ############### */


/* ------------------------------------------------- */

.form-layout-footer .btn,
.form-layout-footer .sp-container button,
.sp-container .form-layout-footer button {
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 1px;
    font-weight: 500;
    padding: 12px 20px;
}


/***** FORM LAYOUT 1 *****/

.form-layout-1 {
    border: 1px solid #ced4da;
    padding: 30px;
}

.form-layout-1 .form-group {
    margin-bottom: 30px;
}

.form-layout-1 .form-control-label {
    display: block;
}


/***** FORM LAYOUT 2 & 3 *****/

.form-layout-2 .form-group,
.form-layout-3 .form-group {
    position: relative;
    border: 1px solid #ced4da;
    padding: 20px 20px;
    margin-bottom: 0;
    height: 100%;
    transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {

    .form-layout-2 .form-group,
    .form-layout-3 .form-group {
        transition: none;
    }
}

.form-layout-2 .form-group-active,
.form-layout-3 .form-group-active {
    background-color: #f8f9fa;
}

.form-layout-2 .form-control-label,
.form-layout-3 .form-control-label {
    margin-bottom: 8px;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 0.5px;
    display: block;
}

.form-layout-2 .form-control,
.form-layout-2 .dataTables_filter input,
.dataTables_filter .form-layout-2 input,
.form-layout-3 .form-control,
.form-layout-3 .dataTables_filter input,
.dataTables_filter .form-layout-3 input {
    border: 0;
    padding: 0;
    background-color: transparent;
    color: #343a40;
    border-radius: 0;
    font-weight: 500;
}

.form-layout-2 .select2-container--default .select2-selection--single,
.form-layout-3 .select2-container--default .select2-selection--single {
    background-color: transparent;
    border-color: transparent;
    height: auto;
}

.form-layout-2 .select2-container--default .select2-selection--single .select2-selection__rendered,
.form-layout-3 .select2-container--default .select2-selection--single .select2-selection__rendered {
    padding: 0;
    font-weight: 500;
}

.form-layout-2 .select2-container--default .select2-selection--single .select2-selection__arrow,
.form-layout-3 .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 26px;
}


/***** FORM LAYOUT 3 *****/

.form-layout-3 .form-control,
.form-layout-3 .dataTables_filter input,
.dataTables_filter .form-layout-3 input {
    font-weight: 400;
}

.form-layout-3 .select2-container--default .select2-selection--single .select2-selection__rendered {
    font-weight: 400;
}


/***** FORM LAYOUT 4 & 5 *****/

.form-layout-4,
.form-layout-5 {
    padding: 30px;
    border: 1px solid #ced4da;
}

.form-layout-4 .form-control-label,
.form-layout-5 .form-control-label {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}


/***** FORM LAYOUT 5 *****/

@media (min-width: 576px) {
    .form-layout-5 .form-control-label {
        justify-content: flex-end;
    }
}


/***** FORM LAYOUT 6 & 7 *****/

.form-layout-6 .row>div,
.form-layout-7 .row>div {
    border: 1px solid #ced4da;
    padding: 15px 20px;
}

.form-layout-6 .row>div:first-child,
.form-layout-7 .row>div:first-child {
    display: flex;
    align-items: center;
    background-color: #f8f9fa;
    border-right-width: 0;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.form-layout-6 .row+.row>div,
.form-layout-7 .row+.row>div {
    border-top-width: 0;
}

.form-layout-6 .form-control,
.form-layout-6 .dataTables_filter input,
.dataTables_filter .form-layout-6 input,
.form-layout-7 .form-control,
.form-layout-7 .dataTables_filter input,
.dataTables_filter .form-layout-7 input {
    border: 0;
    border-radius: 0;
    padding: 0;
}


/***** FORM LAYOUT 7 *****/

.form-layout-7 .row>div:first-child {
    justify-content: flex-end;
}


/* ########### RTL SUPPORT ########## */

.rtl .form-layout-6 .row>div:first-child,
.rtl .form-layout-7 .row>div:first-child {
    border-right-width: 1px;
    border-left-width: 0;
}


/* ------------------------------------------ */


/* ############### 3.9 Modals ############### */


/* ------------------------------------------ */

.modal.effect-scale .modal-dialog {
    transform: scale(0.7);
    opacity: 0;
    transition: all 0.3s;
}

.modal.effect-scale.show .modal-dialog {
    transform: scale(1);
    opacity: 1;
}

.modal.effect-slide-in-right .modal-dialog {
    transform: translateX(20%);
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
}

.modal.effect-slide-in-right.show .modal-dialog {
    transform: translateX(0);
    opacity: 1;
}

.modal.effect-slide-in-bottom .modal-dialog {
    transform: translateY(20%);
    opacity: 0;
    transition: all 0.3s;
}

.modal.effect-slide-in-bottom.show .modal-dialog {
    transform: translateY(0);
    opacity: 1;
}

.modal.effect-newspaper .modal-dialog {
    transform: scale(0) rotate(720deg);
    opacity: 0;
}

.modal.effect-newspaper.show~.modal-backdrop,
.modal.effect-newspaper .modal-dialog {
    transition: all 0.5s;
}

.modal.effect-newspaper.show .modal-dialog {
    transform: scale(1) rotate(0deg);
    opacity: 1;
}

.modal.effect-fall {
    -webkit-perspective: 1300px;
    -moz-perspective: 1300px;
    perspective: 1300px;
}

.modal.effect-fall .modal-dialog {
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transform: translateZ(600px) rotateX(20deg);
    opacity: 0;
}

.modal.effect-fall.show .modal-dialog {
    transition: all 0.3s ease-in;
    transform: translateZ(0px) rotateX(0deg);
    opacity: 1;
}

.modal.effect-flip-horizontal {
    perspective: 1300px;
}

.modal.effect-flip-horizontal .modal-dialog {
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transform: rotateY(-70deg);
    transition: all 0.3s;
    opacity: 0;
}

.modal.effect-flip-horizontal.show .modal-dialog {
    transform: rotateY(0deg);
    opacity: 1;
}

.modal.effect-flip-vertical {
    perspective: 1300px;
}

.modal.effect-flip-vertical .modal-dialog {
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transform: rotateX(-70deg);
    transition: all 0.3s;
    opacity: 0;
}

.modal.effect-flip-vertical.show .modal-dialog {
    transform: rotateX(0deg);
    opacity: 1;
}

.modal.effect-super-scaled .modal-dialog {
    transform: scale(2);
    opacity: 0;
    transition: all 0.3s;
}

.modal.effect-super-scaled.show .modal-dialog {
    transform: scale(1);
    opacity: 1;
}

.modal.effect-sign {
    perspective: 1300px;
}

.modal.effect-sign .modal-dialog {
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transform: rotateX(-60deg);
    transform-origin: 50% 0;
    opacity: 0;
    transition: all 0.3s;
}

.modal.effect-sign.show .modal-dialog {
    transform: rotateX(0deg);
    opacity: 1;
}

.modal.effect-rotate-bottom {
    perspective: 1300px;
}

.modal.effect-rotate-bottom .modal-dialog {
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transform: translateY(100%) rotateX(90deg);
    transform-origin: 0 100%;
    opacity: 0;
    transition: all 0.3s ease-out;
}

.modal.effect-rotate-bottom.show .modal-dialog {
    transform: translateY(0%) rotateX(0deg);
    opacity: 1;
}

.modal.effect-rotate-left {
    perspective: 1300px;
}

.modal.effect-rotate-left .modal-dialog {
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transform: translateZ(100px) translateX(-30%) rotateY(90deg);
    transform-origin: 0 100%;
    opacity: 0;
    transition: all 0.3s;
}

.modal.effect-rotate-left.show .modal-dialog {
    transform: translateZ(0px) translateX(0%) rotateY(0deg);
    opacity: 1;
}

.modal.effect-just-me .modal-dialog {
    transform: scale(0.8);
    opacity: 0;
    transition: all 0.3s;
}

.modal.effect-just-me .modal-content {
    background-color: transparent;
}

.modal.effect-just-me .close {
    text-shadow: none;
    color: #fff;
}

.modal.effect-just-me .modal-header {
    border-bottom-color: rgba(255, 255, 255, 0.1);
    padding-left: 0;
    padding-right: 0;
}

.modal.effect-just-me .modal-header h6 {
    color: #fff;
    font-weight: 500;
}

.modal.effect-just-me .modal-body {
    color: rgba(255, 255, 255, 0.8);
    padding-left: 0;
    padding-right: 0;
}

.modal.effect-just-me .modal-body h4 {
    color: #fff;
}

.modal.effect-just-me .modal-footer {
    padding-left: 0;
    padding-right: 0;
    border-top-color: rgba(255, 255, 255, 0.1);
}

.modal.effect-just-me.show~.modal-backdrop {
    opacity: .96;
}

.modal.effect-just-me.show .modal-dialog {
    transform: scale(1);
    opacity: 1;
}


/***** RTL SUPPORT *****/

.modal-footer> :not(:last-child) {
    margin-right: 0;
    margin-left: 10px;
}


/* ----------------------------------------------- */


/* ############### 3.10 Navigation ############### */


/* ----------------------------------------------- */

.nav-tabs-for-dark .nav-link {
    color: rgba(255, 255, 255, 0.8);
}

.nav-tabs-for-dark .nav-link.active {
    border-color: transparent;
}

.nav-tabs-for-dark .nav-link:hover,
.nav-tabs-for-dark .nav-link:focus {
    color: #fff;
}

.nav-style-1 .nav-link {
    color: #6c757d;
    font-size: 13px;
    display: block;
    border-radius: 2px;
}

.nav-style-1 .nav-link:hover,
.nav-style-1 .nav-link:focus {
    background-color: #e9ecef;
    color: #495057;
}

.nav-style-1 i {
    display: inline-block;
    width: 20px;
    font-size: 20px;
    margin-right: 7px;
    vertical-align: middle;
    line-height: 14px;
}

.nav-style-2 .nav-link {
    color: #6c757d;
    font-size: 13px;
    display: block;
    position: relative;
    transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .nav-style-2 .nav-link {
        transition: none;
    }
}

.nav-style-2 .nav-link+.nav-link {
    border-top: 1px solid #e9ecef;
}

.nav-style-2 .nav-link:first-child {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

.nav-style-2 .nav-link:last-child {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
}

.nav-style-2 .nav-link::before {
    content: '';
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: 0;
    border-left: 3px solid #17A2B8;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .nav-style-2 .nav-link::before {
        transition: none;
    }
}

.nav-style-2 .nav-link:hover,
.nav-style-2 .nav-link:focus {
    color: #495057;
}

.nav-style-2 .nav-link:hover i,
.nav-style-2 .nav-link:focus i {
    color: #17A2B8;
}

.nav-style-2 .nav-link:hover::before,
.nav-style-2 .nav-link:focus::before {
    opacity: 1;
}

.nav-style-2 .nav-link.active,
.nav-style-2 .nav-link.active:hover,
.nav-style-2 .nav-link.active:focus {
    color: #495057;
}

.nav-style-2 .nav-link.active i,
.nav-style-2 .nav-link.active:hover i,
.nav-style-2 .nav-link.active:focus i {
    color: #17A2B8;
}

.nav-style-2 .nav-link.active::before,
.nav-style-2 .nav-link.active:hover::before,
.nav-style-2 .nav-link.active:focus::before {
    opacity: 1;
}

.nav-style-2 i {
    display: inline-block;
    width: 20px;
    font-size: 20px;
    margin-right: 7px;
    vertical-align: middle;
    line-height: 14px;
    color: #adb5bd;
    transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .nav-style-2 i {
        transition: none;
    }
}

.nav-tabs-style-1 {
    background-color: #e9ecef;
    border: 0;
}

.nav-tabs-style-1 .nav-link {
    border: 0;
    color: #6c757d;
    border-radius: 0;
}

.nav-tabs-style-1 .nav-link:first-child {
    border-top-left-radius: 2px;
}

.nav-tabs-style-1 .nav-link:last-child {
    border-top-right-radius: 2px;
}

.nav-tabs-style-1 .nav-link.active {
    background-color: #fff;
    color: #343a40;
}

.nav-gray-600 .nav-link {
    color: #6c757d;
}

.nav-gray-600 .nav-link:hover,
.nav-gray-600 .nav-link:focus {
    color: #343a40;
    background-color: transparent;
}

.nav-gray-600 .nav-link.active,
.nav-gray-600 .nav-link.active:hover,
.nav-gray-600 .nav-link.active:focus {
    color: #343a40;
    background-color: transparent;
}

.nav-gray-700 .nav-link {
    color: #495057;
}

.nav-gray-700 .nav-link:hover,
.nav-gray-700 .nav-link:focus {
    color: #212529;
    background-color: transparent;
}

.nav-gray-700 .nav-link.active,
.nav-gray-700 .nav-link.active:hover,
.nav-gray-700 .nav-link.active:focus {
    color: #212529;
    background-color: transparent;
}

.nav-white-800 .nav-link {
    color: rgba(255, 255, 255, 0.8);
}

.nav-white-800 .nav-link:hover,
.nav-white-800 .nav-link:focus {
    color: #fff;
    background-color: transparent;
}

.nav-white-800 .nav-link.active,
.nav-white-800 .nav-link.active:hover,
.nav-white-800 .nav-link.active:focus {
    color: #fff;
    background-color: transparent;
}

.nav-pills .nav-link {
    color: #495057;
}

.nav-pills .nav-link:hover,
.nav-pills .nav-link:focus {
    color: #343a40;
    background-color: transparent;
}

.nav-pills .nav-link.active,
.nav-pills .nav-link.active:hover,
.nav-pills .nav-link.active:focus {
    color: #fff;
    background-color: #17A2B8;
}

.nav-pills-for-dark .nav-link {
    color: rgba(255, 255, 255, 0.7);
}

.nav-pills-for-dark .nav-link:hover,
.nav-pills-for-dark .nav-link:focus {
    color: #fff;
    background-color: transparent;
}

.nav-pills-for-dark .nav-link.active,
.nav-pills-for-dark .nav-link.active:hover,
.nav-pills-for-dark .nav-link.active:focus {
    color: #fff;
    background-color: #17A2B8;
}

.nav.active-primary .nav-link.active {
    color: #0866C6;
}

.nav.active-success .nav-link.active {
    color: #23BF08;
}

.nav.active-warning .nav-link.active {
    color: #F49917;
}

.nav.active-danger .nav-link.active {
    color: #DC3545;
}

.nav.active-info .nav-link.active {
    color: #17A2B8;
}

.nav.active-indigo .nav-link.active {
    color: #6610f2;
}

.nav.active-purple .nav-link.active {
    color: #6f42c1;
}

.nav.active-pink .nav-link.active {
    color: #e83e8c;
}

.nav.active-teal .nav-link.active {
    color: #1CAF9A;
}

.nav.active-orange .nav-link.active {
    color: #f27510;
}

.nav-outline {
    height: 100%;
}

.nav-outline .nav-item {
    height: 100%;
}

.nav-outline .nav-link {
    color: #6c757d;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
}

.nav-outline .nav-link::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    border-left: 3px solid #343a40;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .nav-outline .nav-link::before {
        transition: none;
    }
}

.nav-outline .nav-link:hover,
.nav-outline .nav-link:focus {
    color: #343a40;
}

.nav-outline .nav-link.active,
.nav-outline .nav-link.active:hover,
.nav-outline .nav-link.active:focus {
    color: #343a40;
}

.nav-outline .nav-link.active::before,
.nav-outline .nav-link.active:hover::before,
.nav-outline .nav-link.active:focus::before {
    opacity: 1;
}

.nav-outline.flex-row .nav-item,
.nav-outline.flex-xs-row .nav-item,
.nav-outline.flex-sm-row .nav-item,
.nav-outline.flex-md-row .nav-item,
.nav-outline.flex-lg-row .nav-item,
.nav-outline.flex-xl-row .nav-item {
    padding-left: 1rem;
    padding-right: 1rem;
}

.nav-outline.flex-row .nav-link,
.nav-outline.flex-xs-row .nav-link,
.nav-outline.flex-sm-row .nav-link,
.nav-outline.flex-md-row .nav-link,
.nav-outline.flex-lg-row .nav-link,
.nav-outline.flex-xl-row .nav-link {
    padding-left: 0;
    padding-right: 0;
}

.nav-outline.flex-row .nav-link::before,
.nav-outline.flex-xs-row .nav-link::before,
.nav-outline.flex-sm-row .nav-link::before,
.nav-outline.flex-md-row .nav-link::before,
.nav-outline.flex-lg-row .nav-link::before,
.nav-outline.flex-xl-row .nav-link::before {
    border-left: 0;
    right: 0;
    border-bottom: 3px solid #343a40;
}

.nav-outline.nav-outline-for-dark .nav-link {
    color: rgba(255, 255, 255, 0.8);
}

.nav-outline.nav-outline-for-dark .nav-link::before {
    border-color: #fff;
}

.nav-outline.nav-outline-for-dark .nav-link:hover,
.nav-outline.nav-outline-for-dark .nav-link:focus {
    color: #fff;
}

.nav-outline.nav-outline-for-dark .nav-link.active,
.nav-outline.nav-outline-for-dark .nav-link.active:hover,
.nav-outline.nav-outline-for-dark .nav-link.active:focus {
    color: #fff;
}

.nav-outline.active-primary .nav-link.active,
.nav-outline.active-primary .nav-link.active:hover,
.nav-outline.active-primary .nav-link.active:focus {
    color: #0866C6;
}

.nav-outline.active-primary .nav-link.active::before,
.nav-outline.active-primary .nav-link.active:hover::before,
.nav-outline.active-primary .nav-link.active:focus::before {
    border-color: #0866C6;
}

.nav-outline.active-success .nav-link.active,
.nav-outline.active-success .nav-link.active:hover,
.nav-outline.active-success .nav-link.active:focus {
    color: #23BF08;
}

.nav-outline.active-success .nav-link.active::before,
.nav-outline.active-success .nav-link.active:hover::before,
.nav-outline.active-success .nav-link.active:focus::before {
    border-color: #23BF08;
}

.nav-outline.active-warning .nav-link.active,
.nav-outline.active-warning .nav-link.active:hover,
.nav-outline.active-warning .nav-link.active:focus {
    color: #F49917;
}

.nav-outline.active-warning .nav-link.active::before,
.nav-outline.active-warning .nav-link.active:hover::before,
.nav-outline.active-warning .nav-link.active:focus::before {
    border-color: #F49917;
}

.nav-outline.active-danger .nav-link.active,
.nav-outline.active-danger .nav-link.active:hover,
.nav-outline.active-danger .nav-link.active:focus {
    color: #DC3545;
}

.nav-outline.active-danger .nav-link.active::before,
.nav-outline.active-danger .nav-link.active:hover::before,
.nav-outline.active-danger .nav-link.active:focus::before {
    border-color: #DC3545;
}

.nav-outline.active-info .nav-link.active,
.nav-outline.active-info .nav-link.active:hover,
.nav-outline.active-info .nav-link.active:focus {
    color: #17A2B8;
}

.nav-outline.active-info .nav-link.active::before,
.nav-outline.active-info .nav-link.active:hover::before,
.nav-outline.active-info .nav-link.active:focus::before {
    border-color: #17A2B8;
}

.nav-outline.active-orange .nav-link.active,
.nav-outline.active-orange .nav-link.active:hover,
.nav-outline.active-orange .nav-link.active:focus {
    color: #f27510;
}

.nav-outline.active-orange .nav-link.active::before,
.nav-outline.active-orange .nav-link.active:hover::before,
.nav-outline.active-orange .nav-link.active:focus::before {
    border-color: #f27510;
}

.nav-outline.active-indigo .nav-link.active,
.nav-outline.active-indigo .nav-link.active:hover,
.nav-outline.active-indigo .nav-link.active:focus {
    color: #6610f2;
}

.nav-outline.active-indigo .nav-link.active::before,
.nav-outline.active-indigo .nav-link.active:hover::before,
.nav-outline.active-indigo .nav-link.active:focus::before {
    border-color: #6610f2;
}

.nav-outline.active-purple .nav-link.active,
.nav-outline.active-purple .nav-link.active:hover,
.nav-outline.active-purple .nav-link.active:focus {
    color: #6f42c1;
}

.nav-outline.active-purple .nav-link.active::before,
.nav-outline.active-purple .nav-link.active:hover::before,
.nav-outline.active-purple .nav-link.active:focus::before {
    border-color: #6f42c1;
}

.nav-outline.active-pink .nav-link.active,
.nav-outline.active-pink .nav-link.active:hover,
.nav-outline.active-pink .nav-link.active:focus {
    color: #e83e8c;
}

.nav-outline.active-pink .nav-link.active::before,
.nav-outline.active-pink .nav-link.active:hover::before,
.nav-outline.active-pink .nav-link.active:focus::before {
    border-color: #e83e8c;
}

.nav-outline.active-teal .nav-link.active,
.nav-outline.active-teal .nav-link.active:hover,
.nav-outline.active-teal .nav-link.active:focus {
    color: #1CAF9A;
}

.nav-outline.active-teal .nav-link.active::before,
.nav-outline.active-teal .nav-link.active:hover::before,
.nav-outline.active-teal .nav-link.active:focus::before {
    border-color: #1CAF9A;
}

.nav-effect-1 {
    height: 100%;
    align-items: center;
}

.nav-effect-1 .nav-item {
    height: 100%;
    margin: 0 5px;
}

.nav-effect-1 .nav-link {
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    text-align: center;
    color: #6c757d;
    -webkit-transition: color 0.4s;
    transition: color 0.4s;
}

.nav-effect-1 .nav-link:hover,
.nav-effect-1 .nav-link:focus {
    color: #343a40;
}

.nav-effect-1 .nav-link::before,
.nav-effect-1 .nav-link::after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background: #17A2B8;
    -webkit-transform: scale3d(0, 1, 1);
    transform: scale3d(0, 1, 1);
    -webkit-transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
}

.nav-effect-1 .nav-link::before {
    top: 0;
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
}

.nav-effect-1 .nav-link::after {
    bottom: 0;
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
}

.nav-effect-1 .nav-link.active {
    color: #17A2B8;
}

.nav-effect-1 .nav-link.active::before,
.nav-effect-1 .nav-link.active::after {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    -webkit-transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
    transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
}

.nav-effect-2 {
    height: 100%;
    align-items: center;
}

.nav-effect-2 .nav-item {
    height: 100%;
    margin: 0 5px;
}

.nav-effect-2 .nav-link {
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    text-align: center;
    color: #6c757d;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
}

.nav-effect-2 .nav-link::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 1rem;
    right: 1rem;
    height: 4px;
    background: #17A2B8;
    -webkit-transform: scale3d(0, 1, 1);
    transform: scale3d(0, 1, 1);
    -webkit-transition: -webkit-transform 0.1s;
    transition: transform 0.1s;
}

.nav-effect-2 .nav-link:hover,
.nav-effect-2 .nav-link:focus {
    color: #e9ecef;
}

.nav-effect-2 .nav-link.active {
    color: #17A2B8;
}

.nav-effect-2 .nav-link.active::before {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}

.nav-effect-3 .nav-item::before,
.nav-effect-3 .nav-item::after,
.nav-effect-3 .nav-link::before,
.nav-effect-3 .nav-link::after {
    content: '';
    position: absolute;
    background: #e83e8c;
    -webkit-transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
}

.nav-effect-3 .nav-item {
    margin: 0 5px;
    position: relative;
}

.nav-effect-3 .nav-item.current .nav-link {
    color: #e83e8c;
}

.nav-effect-3 .nav-item::before,
.nav-effect-3 .nav-item::after {
    top: 0;
    width: 2px;
    height: 100%;
    -webkit-transform: scale3d(1, 0, 1);
    transform: scale3d(1, 0, 1);
}

.nav-effect-3 .nav-item::before {
    left: 0;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
}

.nav-effect-3 .nav-item::after {
    right: 0;
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
}

.nav-effect-3 .nav-link {
    position: relative;
    display: block;
    text-align: center;
    color: #6c757d;
    -webkit-transition: color 0.4s;
    transition: color 0.4s;
}

.nav-effect-3 .nav-link:hover,
.nav-effect-3 .nav-link:focus {
    color: #343a40;
}

.nav-effect-3 .nav-link::before,
.nav-effect-3 .nav-link::after {
    left: 0;
    width: 100%;
    height: 2px;
    -webkit-transform: scale3d(0, 1, 1);
    transform: scale3d(0, 1, 1);
}

.nav-effect-3 .nav-link::before {
    top: 0;
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
}

.nav-effect-3 .nav-link::after {
    bottom: 0;
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
}

.nav-effect-3 .current::before,
.nav-effect-3 .current::after,
.nav-effect-3 .current .nav-link::before,
.nav-effect-3 .current .nav-link::after {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
}

.nav-effect-4 .nav-item {
    margin: 0 5px;
}

.nav-effect-4 .nav-link {
    position: relative;
    display: block;
    width: 100px;
    color: #adb5bd;
    -webkit-transition: color 0.2s;
    transition: color 0.2s;
}

.nav-effect-4 .nav-link:hover,
.nav-effect-4 .nav-link:focus {
    color: #fff;
}

.nav-effect-4 .nav-link::before,
.nav-effect-4 .nav-link::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid #F49917;
    -webkit-transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
}

.nav-effect-4 .nav-link::before {
    border-width: 0 2px;
    -webkit-transform: scale3d(1, 0, 1);
    transform: scale3d(1, 0, 1);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
}

.nav-effect-4 .nav-link::after {
    border-width: 2px 0;
    -webkit-transform: scale3d(0, 1, 1);
    transform: scale3d(0, 1, 1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
}

.nav-effect-4 .nav-link.active {
    color: #F49917;
}

.nav-effect-4 .nav-link.active::before,
.nav-effect-4 .nav-link.active::after {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
}

.nav-effect-5 {
    align-items: center;
}

.nav-effect-5 .nav-link::before,
.nav-effect-5 .nav-link::after {
    display: inline-block;
    opacity: 0;
    -webkit-transition: -webkit-transform 0.3s, opacity 0.2s;
    -moz-transition: -moz-transform 0.3s, opacity 0.2s;
    transition: transform 0.3s, opacity 0.2s;
}

.nav-effect-5 .nav-link::before {
    margin-right: 10px;
    content: '[';
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    transform: translateX(20px);
}

.nav-effect-5 .nav-link::after {
    margin-left: 10px;
    content: ']';
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    transform: translateX(-20px);
}

.nav-effect-5 .nav-link:hover::before,
.nav-effect-5 .nav-link:hover::after,
.nav-effect-5 .nav-link:focus::before,
.nav-effect-5 .nav-link:focus::after {
    opacity: 1;
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    transform: translateX(0px);
}

.nav-effect-5 .nav-link.active::before,
.nav-effect-5 .nav-link.active::after,
.nav-effect-5 .nav-link.active:hover::before,
.nav-effect-5 .nav-link.active:hover::after,
.nav-effect-5 .nav-link.active:focus::before,
.nav-effect-5 .nav-link.active:focus::after {
    opacity: 1;
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    transform: translateX(0px);
}

.nav-effect-6 {
    align-items: center;
}

.nav-effect-6 .nav-item {
    margin: 0;
}

.nav-effect-6 .nav-link {
    color: #fff;
    padding-left: 0;
    padding-right: 0;
    line-height: 44px;
    perspective: 1000px;
}

.nav-effect-6 .nav-link span {
    position: relative;
    display: inline-block;
    padding: 0 30px;
    background-color: #17A2B8;
    transition: transform 0.3s;
    transform-origin: 50% 0;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.nav-effect-6 .nav-link span::before {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0965a0;
    content: attr(data-hover);
    transition: background-color 0.3s;
    transform: rotateX(-90deg);
    transform-origin: 50% 0;
    text-align: center;
}

.nav-effect-6 .nav-link:hover span,
.nav-effect-6 .nav-link:focus span {
    -webkit-transform: rotateX(90deg) translateY(-22px);
    -moz-transform: rotateX(90deg) translateY(-22px);
    transform: rotateX(90deg) translateY(-22px);
}

.nav-effect-6 .nav-link:hover span::before,
.nav-effect-6 .nav-link:focus span::before {
    background-color: #0866C6;
}

.nav-effect-6 .nav-link.active span,
.nav-effect-6 .nav-link.active:hover span,
.nav-effect-6 .nav-link.active:focus span {
    -webkit-transform: rotateX(90deg) translateY(-22px);
    -moz-transform: rotateX(90deg) translateY(-22px);
    transform: rotateX(90deg) translateY(-22px);
}

.nav-effect-6 .nav-link.active span::before,
.nav-effect-6 .nav-link.active:hover span::before,
.nav-effect-6 .nav-link.active:focus span::before {
    background-color: #0866C6;
}

.nav-effect-7 {
    align-items: center;
}

.nav-effect-7 .nav-item {
    padding-left: 10px;
    padding-right: 10px;
}

.nav-effect-7 .nav-link {
    position: relative;
    padding: 10px;
    color: #6c757d;
    font-weight: 700;
    text-shadow: none;
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    transition: color 0.3s;
}

.nav-effect-7 .nav-link::before,
.nav-effect-7 .nav-link::after {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #DC3545;
    content: '';
    opacity: 0;
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    -moz-transition: opacity 0.3s, -moz-transform 0.3s;
    transition: opacity 0.3s, transform 0.3s;
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    transform: translateY(-10px);
}

.nav-effect-7 .nav-link::before {
    top: 0;
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    transform: translateY(-10px);
}

.nav-effect-7 .nav-link::after {
    bottom: 0;
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    transform: translateY(10px);
}

.nav-effect-7 .nav-link:hover,
.nav-effect-7 .nav-link:focus {
    color: #DC3545;
}

.nav-effect-7 .nav-link:hover::before,
.nav-effect-7 .nav-link:hover::after,
.nav-effect-7 .nav-link:focus::before,
.nav-effect-7 .nav-link:focus::after {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    transform: translateY(0px);
}

.nav-effect-8 {
    align-items: center;
}

.nav-effect-8 .nav-item {
    padding-left: 15px;
    padding-right: 15px;
}

.nav-effect-8 .nav-item+.nav-item {
    margin-top: 25px;
}

@media (min-width: 768px) {
    .nav-effect-8.flex-md-row .nav-item+.nav-item {
        margin-top: 0;
    }
}

.nav-effect-8 .nav-link {
    position: relative;
    overflow: hidden;
    padding: 0 5px;
    height: 20px;
    color: rgba(255, 255, 255, 0.75);
}

.nav-effect-8 .nav-link span {
    position: relative;
    display: inline-block;
    -webkit-transition: -webkit-transform 0.3s;
    -moz-transition: -moz-transform 0.3s;
    transition: transform 0.3s;
}

.nav-effect-8 .nav-link span::before {
    position: absolute;
    top: 100%;
    content: attr(data-hover);
    font-weight: 700;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    color: #23BF08;
}

.nav-effect-8 .nav-link:hover span,
.nav-effect-8 .nav-link:focus span {
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    transform: translateY(-100%);
}


/* ########## RTL SUPPORT ########## */

.rtl .nav {
    padding-right: 0;
}

.rtl .nav-style-1,
.rtl .nav-style-2 {
    text-align: right;
}

.rtl .nav-style-1 i,
.rtl .nav-style-2 i {
    margin-right: 0;
    margin-left: 7px;
}

.rtl .nav-effect-5 .nav-link::before {
    margin-left: 10px;
    margin-right: 0;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    transform: translateX(-20px);
}

.rtl .nav-effect-5 .nav-link::after {
    margin-right: 10px;
    margin-left: 0;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    transform: translateX(20px);
}

.rtl .nav-effect-5 .nav-link:hover::before,
.rtl .nav-effect-5 .nav-link:hover::after,
.rtl .nav-effect-5 .nav-link:focus::before,
.rtl .nav-effect-5 .nav-link:focus::after {
    opacity: 1;
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    transform: translateX(0px);
}

.rtl .nav-effect-5 .nav-link.active::before,
.rtl .nav-effect-5 .nav-link.active::after,
.rtl .nav-effect-5 .nav-link.active:hover::before,
.rtl .nav-effect-5 .nav-link.active:hover::after,
.rtl .nav-effect-5 .nav-link.active:focus::before,
.rtl .nav-effect-5 .nav-link.active:focus::after {
    opacity: 1;
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    transform: translateX(0px);
}


/* ----------------------------------------------- */


/* ############### 3.11 Pagination ############### */


/* ----------------------------------------------- */

.pagination .page-item+.page-item {
    padding-left: 2px;
}

.pagination .page-item .page-link {
    color: #495057;
    padding: 11px 0;
    width: 35px;
    height: 40px;
    text-align: center;
}

.pagination .page-item .page-link:hover,
.pagination .page-item .page-link:focus {
    background-color: #e9ecef;
}

.pagination .active .page-link,
.pagination .active .page-link:hover,
.pagination .active .page-link:focus {
    color: #fff;
    background-color: #17A2B8;
    border-color: transparent;
}

.pagination-basic .page-item .page-link {
    border: 0;
    width: 35px;
    height: 40px;
    text-align: center;
    padding: 11px 0;
    background-color: #e9ecef;
    border-radius: 3px;
}

.pagination-basic .page-item .page-link:hover,
.pagination-basic .page-item .page-link:focus {
    background-color: #dee2e6;
}

.pagination-basic .active .page-link,
.pagination-basic .active .page-link:hover,
.pagination-basic .active .page-link:focus {
    border: 0;
    background-color: #17A2B8;
}

.pagination-basic .disabled .page-link {
    background-color: transparent;
}

.pagination-dark .page-item.disabled .page-link {
    background-color: transparent;
    color: rgba(255, 255, 255, 0.2);
}

.pagination-dark .page-item.active .page-link,
.pagination-dark .page-item.active .page-link:hover,
.pagination-dark .page-item.active .page-link:focus {
    border-color: transparent;
    background-color: #fff;
    color: #343a40;
}

.pagination-dark .page-item .page-link {
    color: rgba(255, 255, 255, 0.5);
    border: 0;
    width: 35px;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
    border-radius: 2px;
}

.pagination-dark .page-item .page-link:hover,
.pagination-dark .page-item .page-link:focus {
    background-color: rgba(255, 255, 255, 0.2);
    color: #fff;
}

.pagination-circle .page-item+.page-item {
    margin-left: 5px;
}

.pagination-circle .page-item .page-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    border: 1px solid #dee2e6;
    border-radius: 100%;
}

.pagination-circle .page-item span.page-link {
    border: 0;
}

.pagination-circle .page-item.active .page-link,
.pagination-circle .page-item.active .page-link:hover,
.pagination-circle .page-item.active .page-link:focus {
    background-color: #17A2B8;
    border-color: transparent;
    color: #fff;
}

.pagination-circle.pagination-dark .page-item .page-link {
    border-color: rgba(255, 255, 255, 0.2);
}

.pagination-circle.pagination-dark .active .page-link {
    border-color: transparent;
}

.pagination-rounded .page-item+.page-item {
    padding-left: 8px;
}

.pagination-rounded .page-item .page-link {
    background-color: transparent;
    border: 1px solid #dee2e6;
    border-radius: 3px;
}

.pagination-rounded .active .page-link,
.pagination-rounded .active .page-link:hover,
.pagination-rounded .active .page-link:focus {
    border-color: transparent;
    background-color: #17A2B8;
}

.pagination-rounded.pagination-dark .page-item .page-link {
    border-color: rgba(255, 255, 255, 0.1);
}

.pagination-rounded.pagination-dark .page-item span.page-link {
    border: 0;
}

.pagination-circle .page-item span.page-link,
.pagination-rounded .page-item span.page-link {
    border-color: transparent;
    padding-left: 0;
    padding-right: 0;
    width: auto;
}

.pagination-circle .page-item.disabled .page-link,
.pagination-rounded .page-item.disabled .page-link {
    background-color: transparent;
}

.pagination-dark .page-link {
    background-color: transparent;
    color: #fff;
    border-color: rgba(255, 255, 255, 0.2);
}

.pagination-dark .page-link:hover,
.pagination-dark .page-link:focus {
    background-color: #343a40;
}

.pagination-primary .page-link {
    color: #6c757d;
}

.pagination-primary .page-link:hover,
.pagination-primary .page-link:focus {
    color: #343a40;
    background-color: #e9ecef;
}

.pagination-primary .active .page-link,
.pagination-primary .active .page-link:hover,
.pagination-primary .active .page-link:focus {
    background-color: #0866C6;
    color: #fff;
    border-color: transparent;
}

.pagination-primary.pagination-dark .active .page-link {
    background-color: #0866C6;
    color: #fff;
}

.pagination-success .page-link {
    color: #6c757d;
}

.pagination-success .page-link:hover,
.pagination-success .page-link:focus {
    color: #343a40;
    background-color: #e9ecef;
}

.pagination-success .active .page-link,
.pagination-success .active .page-link:hover,
.pagination-success .active .page-link:focus {
    background-color: #23BF08;
    color: #fff;
    border-color: transparent;
}

.pagination-success.pagination-dark .active .page-link {
    background-color: #23BF08;
    color: #fff;
}

.pagination-warning .page-link {
    color: #6c757d;
}

.pagination-warning .page-link:hover,
.pagination-warning .page-link:focus {
    color: #343a40;
    background-color: #e9ecef;
}

.pagination-warning .active .page-link,
.pagination-warning .active .page-link:hover,
.pagination-warning .active .page-link:focus {
    background-color: #F49917;
    color: #fff;
    border-color: transparent;
}

.pagination-warning.pagination-dark .active .page-link {
    background-color: #F49917;
    color: #fff;
}

.pagination-danger .page-link {
    color: #6c757d;
}

.pagination-danger .page-link:hover,
.pagination-danger .page-link:focus {
    color: #343a40;
    background-color: #e9ecef;
}

.pagination-danger .active .page-link,
.pagination-danger .active .page-link:hover,
.pagination-danger .active .page-link:focus {
    background-color: #DC3545;
    color: #fff;
    border-color: transparent;
}

.pagination-danger.pagination-dark .active .page-link {
    background-color: #DC3545;
    color: #fff;
}

.pagination-info .page-link {
    color: #6c757d;
}

.pagination-info .page-link:hover,
.pagination-info .page-link:focus {
    color: #343a40;
    background-color: #e9ecef;
}

.pagination-info .active .page-link,
.pagination-info .active .page-link:hover,
.pagination-info .active .page-link:focus {
    background-color: #17A2B8;
    color: #fff;
    border-color: transparent;
}

.pagination-info.pagination-dark .active .page-link {
    background-color: #17A2B8;
    color: #fff;
}

.pagination-inverse .page-link {
    color: #6c757d;
}

.pagination-inverse .page-link:hover,
.pagination-inverse .page-link:focus {
    color: #343a40;
    background-color: #e9ecef;
}

.pagination-inverse .active .page-link,
.pagination-inverse .active .page-link:hover,
.pagination-inverse .active .page-link:focus {
    background-color: #343a40;
    color: #fff;
    border-color: transparent;
}

.pagination-inverse.pagination-dark .active .page-link {
    background-color: #343a40;
    color: #fff;
}

.pagination-indigo .page-link {
    color: #6c757d;
}

.pagination-indigo .page-link:hover,
.pagination-indigo .page-link:focus {
    color: #343a40;
    background-color: #e9ecef;
}

.pagination-indigo .active .page-link,
.pagination-indigo .active .page-link:hover,
.pagination-indigo .active .page-link:focus {
    background-color: #6610f2;
    color: #fff;
    border-color: transparent;
}

.pagination-indigo.pagination-dark .active .page-link {
    background-color: #6610f2;
    color: #fff;
}

.pagination-purple .page-link {
    color: #6c757d;
}

.pagination-purple .page-link:hover,
.pagination-purple .page-link:focus {
    color: #343a40;
    background-color: #e9ecef;
}

.pagination-purple .active .page-link,
.pagination-purple .active .page-link:hover,
.pagination-purple .active .page-link:focus {
    background-color: #6f42c1;
    color: #fff;
    border-color: transparent;
}

.pagination-purple.pagination-dark .active .page-link {
    background-color: #6f42c1;
    color: #fff;
}

.pagination-pink .page-link {
    color: #6c757d;
}

.pagination-pink .page-link:hover,
.pagination-pink .page-link:focus {
    color: #343a40;
    background-color: #e9ecef;
}

.pagination-pink .active .page-link,
.pagination-pink .active .page-link:hover,
.pagination-pink .active .page-link:focus {
    background-color: #e83e8c;
    color: #fff;
    border-color: transparent;
}

.pagination-pink.pagination-dark .active .page-link {
    background-color: #e83e8c;
    color: #fff;
}

.pagination-orange .page-link {
    color: #6c757d;
}

.pagination-orange .page-link:hover,
.pagination-orange .page-link:focus {
    color: #343a40;
    background-color: #e9ecef;
}

.pagination-orange .active .page-link,
.pagination-orange .active .page-link:hover,
.pagination-orange .active .page-link:focus {
    background-color: #f27510;
    color: #fff;
    border-color: transparent;
}

.pagination-orange.pagination-dark .active .page-link {
    background-color: #f27510;
    color: #fff;
}

.pagination-teal .page-link {
    color: #6c757d;
}

.pagination-teal .page-link:hover,
.pagination-teal .page-link:focus {
    color: #343a40;
    background-color: #e9ecef;
}

.pagination-teal .active .page-link,
.pagination-teal .active .page-link:hover,
.pagination-teal .active .page-link:focus {
    background-color: #1CAF9A;
    color: #fff;
    border-color: transparent;
}

.pagination-teal.pagination-dark .active .page-link {
    background-color: #1CAF9A;
    color: #fff;
}


/* ########## RTL SUPPORT ########## */

.rtl .pagination {
    padding: 0;
}

.rtl .pagination .page-item+.page-item {
    padding-left: 0;
    padding-right: 3px;
}


/* -------------------------------------------- */


/* ############### 3.12 Popover ############### */


/* -------------------------------------------- */

.popover {
    font-size: 12px;
    padding: 0;
    border-radius: 2px;
}

.popover-header {
    text-transform: uppercase;
    font-size: 12px;
    color: #343a40;
    letter-spacing: 0.5px;
    padding: 12px 15px;
    background-color: #fff;
    border-color: #dee2e6;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

.popover-header::before {
    display: none !important;
}

.popover-body {
    padding: 15px;
}

.popover-body p:last-child {
    margin-bottom: 0;
}

.popover-head-primary .popover-header {
    color: #fff;
    background-color: #0866C6;
}

.popover-head-primary.bs-popover-bottom .arrow::after,
.popover-head-primary.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.popover-head-primary.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #0866C6;
}

.popover-head-success .popover-header {
    color: #fff;
    background-color: #23BF08;
}

.popover-head-success.bs-popover-bottom .arrow::after,
.popover-head-success.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.popover-head-success.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #23BF08;
}

.popover-head-warning .popover-header {
    color: #fff;
    background-color: #F49917;
}

.popover-head-warning.bs-popover-bottom .arrow::after,
.popover-head-warning.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.popover-head-warning.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #F49917;
}

.popover-head-danger .popover-header {
    color: #fff;
    background-color: #DC3545;
}

.popover-head-danger.bs-popover-bottom .arrow::after,
.popover-head-danger.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.popover-head-danger.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #DC3545;
}

.popover-head-info .popover-header {
    color: #fff;
    background-color: #17A2B8;
}

.popover-head-info.bs-popover-bottom .arrow::after,
.popover-head-info.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.popover-head-info.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #17A2B8;
}

.popover-head-teal .popover-header {
    color: #fff;
    background-color: #1CAF9A;
}

.popover-head-teal.bs-popover-bottom .arrow::after,
.popover-head-teal.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.popover-head-teal.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #1CAF9A;
}

.popover-head-indigo .popover-header {
    color: #fff;
    background-color: #6610f2;
}

.popover-head-indigo.bs-popover-bottom .arrow::after,
.popover-head-indigo.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.popover-head-indigo.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #6610f2;
}

.popover-head-purple .popover-header {
    color: #fff;
    background-color: #6f42c1;
}

.popover-head-purple.bs-popover-bottom .arrow::after,
.popover-head-purple.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.popover-head-purple.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #6f42c1;
}

.popover-head-orange .popover-header {
    color: #fff;
    background-color: #f27510;
}

.popover-head-orange.bs-popover-bottom .arrow::after,
.popover-head-orange.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.popover-head-orange.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #f27510;
}

.popover-head-pink .popover-header {
    color: #fff;
    background-color: #e83e8c;
}

.popover-head-pink.bs-popover-bottom .arrow::after,
.popover-head-pink.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.popover-head-pink.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #e83e8c;
}

.popover-head-primary,
.popover-head-primary .popover-header,
.popover-head-success,
.popover-head-success .popover-header,
.popover-head-warning,
.popover-head-warning .popover-header,
.popover-head-danger,
.popover-head-danger .popover-header,
.popover-head-info,
.popover-head-info .popover-header,
.popover-head-teal,
.popover-head-teal .popover-header,
.popover-head-indigo,
.popover-head-indigo .popover-header,
.popover-head-purple,
.popover-head-purple .popover-header,
.popover-head-orange,
.popover-head-orange .popover-header,
.popover-head-pink,
.popover-head-pink .popover-header,
.popover-head-dark,
.popover-head-dark .popover-header {
    border: 0;
}

.popover-head-primary .popover-body,
.popover-head-success .popover-body,
.popover-head-warning .popover-body,
.popover-head-danger .popover-body,
.popover-head-info .popover-body,
.popover-head-teal .popover-body,
.popover-head-indigo .popover-body,
.popover-head-purple .popover-body,
.popover-head-orange .popover-body,
.popover-head-pink .popover-body,
.popover-head-dark .popover-body {
    border: 1px solid #dee2e6;
    border-top-width: 0;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
}

.popover-head-primary.bs-popover-bottom .arrow::before,
.popover-head-primary.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover-head-primary.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover-head-success.bs-popover-bottom .arrow::before,
.popover-head-success.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover-head-success.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover-head-warning.bs-popover-bottom .arrow::before,
.popover-head-warning.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover-head-warning.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover-head-danger.bs-popover-bottom .arrow::before,
.popover-head-danger.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover-head-danger.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover-head-info.bs-popover-bottom .arrow::before,
.popover-head-info.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover-head-info.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover-head-teal.bs-popover-bottom .arrow::before,
.popover-head-teal.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover-head-teal.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover-head-indigo.bs-popover-bottom .arrow::before,
.popover-head-indigo.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover-head-indigo.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover-head-purple.bs-popover-bottom .arrow::before,
.popover-head-purple.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover-head-purple.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover-head-orange.bs-popover-bottom .arrow::before,
.popover-head-orange.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover-head-orange.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover-head-pink.bs-popover-bottom .arrow::before,
.popover-head-pink.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover-head-pink.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover-head-dark.bs-popover-bottom .arrow::before,
.popover-head-dark.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover-head-dark.bs-popover-auto[x-placement^="bottom"] .arrow::before {
    display: none;
}

.popover-primary {
    background-color: #0866C6;
}

.popover-primary.bs-popover-top .arrow::after,
.popover-primary.bs-popover-auto[x-placement^="top"] .arrow::after,
.popover-primary.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #0866C6;
}

.popover-primary.bs-popover-bottom .arrow::after,
.popover-primary.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.popover-primary.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #0866C6;
}

.popover-primary.bs-popover-left .arrow::after,
.popover-primary.bs-popover-auto[x-placement^="left"] .arrow::after,
.popover-primary.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #0866C6;
}

.popover-primary.bs-popover-right .arrow::after,
.popover-primary.bs-popover-auto[x-placement^="right"] .arrow::after,
.popover-primary.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #0866C6;
}

.popover-success {
    background-color: #23BF08;
}

.popover-success.bs-popover-top .arrow::after,
.popover-success.bs-popover-auto[x-placement^="top"] .arrow::after,
.popover-success.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #23BF08;
}

.popover-success.bs-popover-bottom .arrow::after,
.popover-success.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.popover-success.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #23BF08;
}

.popover-success.bs-popover-left .arrow::after,
.popover-success.bs-popover-auto[x-placement^="left"] .arrow::after,
.popover-success.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #23BF08;
}

.popover-success.bs-popover-right .arrow::after,
.popover-success.bs-popover-auto[x-placement^="right"] .arrow::after,
.popover-success.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #23BF08;
}

.popover-warning {
    background-color: #F49917;
}

.popover-warning.bs-popover-top .arrow::after,
.popover-warning.bs-popover-auto[x-placement^="top"] .arrow::after,
.popover-warning.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #F49917;
}

.popover-warning.bs-popover-bottom .arrow::after,
.popover-warning.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.popover-warning.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #F49917;
}

.popover-warning.bs-popover-left .arrow::after,
.popover-warning.bs-popover-auto[x-placement^="left"] .arrow::after,
.popover-warning.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #F49917;
}

.popover-warning.bs-popover-right .arrow::after,
.popover-warning.bs-popover-auto[x-placement^="right"] .arrow::after,
.popover-warning.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #F49917;
}

.popover-danger {
    background-color: #DC3545;
}

.popover-danger.bs-popover-top .arrow::after,
.popover-danger.bs-popover-auto[x-placement^="top"] .arrow::after,
.popover-danger.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #DC3545;
}

.popover-danger.bs-popover-bottom .arrow::after,
.popover-danger.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.popover-danger.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #DC3545;
}

.popover-danger.bs-popover-left .arrow::after,
.popover-danger.bs-popover-auto[x-placement^="left"] .arrow::after,
.popover-danger.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #DC3545;
}

.popover-danger.bs-popover-right .arrow::after,
.popover-danger.bs-popover-auto[x-placement^="right"] .arrow::after,
.popover-danger.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #DC3545;
}

.popover-info {
    background-color: #17A2B8;
}

.popover-info.bs-popover-top .arrow::after,
.popover-info.bs-popover-auto[x-placement^="top"] .arrow::after,
.popover-info.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #17A2B8;
}

.popover-info.bs-popover-bottom .arrow::after,
.popover-info.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.popover-info.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #17A2B8;
}

.popover-info.bs-popover-left .arrow::after,
.popover-info.bs-popover-auto[x-placement^="left"] .arrow::after,
.popover-info.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #17A2B8;
}

.popover-info.bs-popover-right .arrow::after,
.popover-info.bs-popover-auto[x-placement^="right"] .arrow::after,
.popover-info.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #17A2B8;
}

.popover-teal {
    background-color: #1CAF9A;
}

.popover-teal.bs-popover-top .arrow::after,
.popover-teal.bs-popover-auto[x-placement^="top"] .arrow::after,
.popover-teal.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #1CAF9A;
}

.popover-teal.bs-popover-bottom .arrow::after,
.popover-teal.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.popover-teal.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #1CAF9A;
}

.popover-teal.bs-popover-left .arrow::after,
.popover-teal.bs-popover-auto[x-placement^="left"] .arrow::after,
.popover-teal.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #1CAF9A;
}

.popover-teal.bs-popover-right .arrow::after,
.popover-teal.bs-popover-auto[x-placement^="right"] .arrow::after,
.popover-teal.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #1CAF9A;
}

.popover-indigo {
    background-color: #6610f2;
}

.popover-indigo.bs-popover-top .arrow::after,
.popover-indigo.bs-popover-auto[x-placement^="top"] .arrow::after,
.popover-indigo.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #6610f2;
}

.popover-indigo.bs-popover-bottom .arrow::after,
.popover-indigo.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.popover-indigo.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #6610f2;
}

.popover-indigo.bs-popover-left .arrow::after,
.popover-indigo.bs-popover-auto[x-placement^="left"] .arrow::after,
.popover-indigo.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #6610f2;
}

.popover-indigo.bs-popover-right .arrow::after,
.popover-indigo.bs-popover-auto[x-placement^="right"] .arrow::after,
.popover-indigo.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #6610f2;
}

.popover-purple {
    background-color: #6f42c1;
}

.popover-purple.bs-popover-top .arrow::after,
.popover-purple.bs-popover-auto[x-placement^="top"] .arrow::after,
.popover-purple.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #6f42c1;
}

.popover-purple.bs-popover-bottom .arrow::after,
.popover-purple.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.popover-purple.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #6f42c1;
}

.popover-purple.bs-popover-left .arrow::after,
.popover-purple.bs-popover-auto[x-placement^="left"] .arrow::after,
.popover-purple.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #6f42c1;
}

.popover-purple.bs-popover-right .arrow::after,
.popover-purple.bs-popover-auto[x-placement^="right"] .arrow::after,
.popover-purple.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #6f42c1;
}

.popover-orange {
    background-color: #f27510;
}

.popover-orange.bs-popover-top .arrow::after,
.popover-orange.bs-popover-auto[x-placement^="top"] .arrow::after,
.popover-orange.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #f27510;
}

.popover-orange.bs-popover-bottom .arrow::after,
.popover-orange.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.popover-orange.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #f27510;
}

.popover-orange.bs-popover-left .arrow::after,
.popover-orange.bs-popover-auto[x-placement^="left"] .arrow::after,
.popover-orange.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #f27510;
}

.popover-orange.bs-popover-right .arrow::after,
.popover-orange.bs-popover-auto[x-placement^="right"] .arrow::after,
.popover-orange.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #f27510;
}

.popover-pink {
    background-color: #e83e8c;
}

.popover-pink.bs-popover-top .arrow::after,
.popover-pink.bs-popover-auto[x-placement^="top"] .arrow::after,
.popover-pink.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #e83e8c;
}

.popover-pink.bs-popover-bottom .arrow::after,
.popover-pink.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.popover-pink.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #e83e8c;
}

.popover-pink.bs-popover-left .arrow::after,
.popover-pink.bs-popover-auto[x-placement^="left"] .arrow::after,
.popover-pink.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #e83e8c;
}

.popover-pink.bs-popover-right .arrow::after,
.popover-pink.bs-popover-auto[x-placement^="right"] .arrow::after,
.popover-pink.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #e83e8c;
}

.popover-dark {
    background-color: #343a40;
}

.popover-dark.bs-popover-top .arrow::after,
.popover-dark.bs-popover-auto[x-placement^="top"] .arrow::after,
.popover-dark.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #343a40;
}

.popover-dark.bs-popover-bottom .arrow::after,
.popover-dark.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.popover-dark.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #343a40;
}

.popover-dark.bs-popover-left .arrow::after,
.popover-dark.bs-popover-auto[x-placement^="left"] .arrow::after,
.popover-dark.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #343a40;
}

.popover-dark.bs-popover-right .arrow::after,
.popover-dark.bs-popover-auto[x-placement^="right"] .arrow::after,
.popover-dark.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #343a40;
}

.popover-primary,
.popover-success,
.popover-warning,
.popover-danger,
.popover-info,
.popover-teal,
.popover-indigo,
.popover-purple,
.popover-orange,
.popover-pink,
.popover-dark {
    border: 0;
}

.popover-primary .popover-header,
.popover-success .popover-header,
.popover-warning .popover-header,
.popover-danger .popover-header,
.popover-info .popover-header,
.popover-teal .popover-header,
.popover-indigo .popover-header,
.popover-purple .popover-header,
.popover-orange .popover-header,
.popover-pink .popover-header,
.popover-dark .popover-header {
    border: 0;
    background-color: transparent;
    padding-bottom: 0;
    padding-top: 15px;
    color: #fff;
}

.popover-primary .popover-body,
.popover-success .popover-body,
.popover-warning .popover-body,
.popover-danger .popover-body,
.popover-info .popover-body,
.popover-teal .popover-body,
.popover-indigo .popover-body,
.popover-purple .popover-body,
.popover-orange .popover-body,
.popover-pink .popover-body,
.popover-dark .popover-body {
    color: rgba(255, 255, 255, 0.8);
}

.popover-primary .arrow::before,
.popover-success .arrow::before,
.popover-warning .arrow::before,
.popover-danger .arrow::before,
.popover-info .arrow::before,
.popover-teal .arrow::before,
.popover-indigo .arrow::before,
.popover-purple .arrow::before,
.popover-orange .arrow::before,
.popover-pink .arrow::before,
.popover-dark .arrow::before {
    display: none !important;
}


/* --------------------------------------------- */


/* ############### 3.13 Progress ############### */


/* --------------------------------------------- */

.progress-bar {
    font-size: 11px;
}


/* -------------------------------------------- */


/* ############### 3.15 Toltips ############### */


/* -------------------------------------------- */

.tooltip-inner {
    padding: 8px 15px;
    font-size: 13px;
    border-radius: 2px;
}

.tooltip-primary .tooltip-inner {
    background-color: #0866C6;
    color: #fff;
}

.tooltip-primary.bs-tooltip-top .arrow::before,
.tooltip-primary.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.tooltip-primary.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: #0866C6;
}

.tooltip-primary.bs-tooltip-bottom .arrow::before,
.tooltip-primary.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.tooltip-primary.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: #0866C6;
}

.tooltip-primary.bs-tooltip-left .arrow::before,
.tooltip-primary.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.tooltip-primary.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-left-color: #0866C6;
}

.tooltip-primary.bs-tooltip-right .arrow::before,
.tooltip-primary.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.tooltip-primary.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: #0866C6;
}

.tooltip-success .tooltip-inner {
    background-color: #23BF08;
    color: #fff;
}

.tooltip-success.bs-tooltip-top .arrow::before,
.tooltip-success.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.tooltip-success.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: #23BF08;
}

.tooltip-success.bs-tooltip-bottom .arrow::before,
.tooltip-success.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.tooltip-success.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: #23BF08;
}

.tooltip-success.bs-tooltip-left .arrow::before,
.tooltip-success.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.tooltip-success.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-left-color: #23BF08;
}

.tooltip-success.bs-tooltip-right .arrow::before,
.tooltip-success.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.tooltip-success.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: #23BF08;
}

.tooltip-warning .tooltip-inner {
    background-color: #F49917;
    color: #fff;
}

.tooltip-warning.bs-tooltip-top .arrow::before,
.tooltip-warning.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.tooltip-warning.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: #F49917;
}

.tooltip-warning.bs-tooltip-bottom .arrow::before,
.tooltip-warning.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.tooltip-warning.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: #F49917;
}

.tooltip-warning.bs-tooltip-left .arrow::before,
.tooltip-warning.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.tooltip-warning.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-left-color: #F49917;
}

.tooltip-warning.bs-tooltip-right .arrow::before,
.tooltip-warning.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.tooltip-warning.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: #F49917;
}

.tooltip-danger .tooltip-inner {
    background-color: #DC3545;
    color: #fff;
}

.tooltip-danger.bs-tooltip-top .arrow::before,
.tooltip-danger.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.tooltip-danger.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: #DC3545;
}

.tooltip-danger.bs-tooltip-bottom .arrow::before,
.tooltip-danger.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.tooltip-danger.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: #DC3545;
}

.tooltip-danger.bs-tooltip-left .arrow::before,
.tooltip-danger.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.tooltip-danger.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-left-color: #DC3545;
}

.tooltip-danger.bs-tooltip-right .arrow::before,
.tooltip-danger.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.tooltip-danger.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: #DC3545;
}

.tooltip-info .tooltip-inner {
    background-color: #17A2B8;
    color: #fff;
}

.tooltip-info.bs-tooltip-top .arrow::before,
.tooltip-info.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.tooltip-info.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: #17A2B8;
}

.tooltip-info.bs-tooltip-bottom .arrow::before,
.tooltip-info.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.tooltip-info.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: #17A2B8;
}

.tooltip-info.bs-tooltip-left .arrow::before,
.tooltip-info.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.tooltip-info.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-left-color: #17A2B8;
}

.tooltip-info.bs-tooltip-right .arrow::before,
.tooltip-info.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.tooltip-info.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: #17A2B8;
}

.tooltip-indigo .tooltip-inner {
    background-color: #6610f2;
    color: #fff;
}

.tooltip-indigo.bs-tooltip-top .arrow::before,
.tooltip-indigo.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.tooltip-indigo.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: #6610f2;
}

.tooltip-indigo.bs-tooltip-bottom .arrow::before,
.tooltip-indigo.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.tooltip-indigo.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: #6610f2;
}

.tooltip-indigo.bs-tooltip-left .arrow::before,
.tooltip-indigo.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.tooltip-indigo.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-left-color: #6610f2;
}

.tooltip-indigo.bs-tooltip-right .arrow::before,
.tooltip-indigo.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.tooltip-indigo.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: #6610f2;
}

.tooltip-purple .tooltip-inner {
    background-color: #6f42c1;
    color: #fff;
}

.tooltip-purple.bs-tooltip-top .arrow::before,
.tooltip-purple.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.tooltip-purple.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: #6f42c1;
}

.tooltip-purple.bs-tooltip-bottom .arrow::before,
.tooltip-purple.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.tooltip-purple.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: #6f42c1;
}

.tooltip-purple.bs-tooltip-left .arrow::before,
.tooltip-purple.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.tooltip-purple.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-left-color: #6f42c1;
}

.tooltip-purple.bs-tooltip-right .arrow::before,
.tooltip-purple.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.tooltip-purple.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: #6f42c1;
}

.tooltip-orange .tooltip-inner {
    background-color: #f27510;
    color: #fff;
}

.tooltip-orange.bs-tooltip-top .arrow::before,
.tooltip-orange.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.tooltip-orange.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: #f27510;
}

.tooltip-orange.bs-tooltip-bottom .arrow::before,
.tooltip-orange.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.tooltip-orange.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: #f27510;
}

.tooltip-orange.bs-tooltip-left .arrow::before,
.tooltip-orange.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.tooltip-orange.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-left-color: #f27510;
}

.tooltip-orange.bs-tooltip-right .arrow::before,
.tooltip-orange.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.tooltip-orange.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: #f27510;
}

.tooltip-teal .tooltip-inner {
    background-color: #1CAF9A;
    color: #fff;
}

.tooltip-teal.bs-tooltip-top .arrow::before,
.tooltip-teal.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.tooltip-teal.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: #1CAF9A;
}

.tooltip-teal.bs-tooltip-bottom .arrow::before,
.tooltip-teal.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.tooltip-teal.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: #1CAF9A;
}

.tooltip-teal.bs-tooltip-left .arrow::before,
.tooltip-teal.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.tooltip-teal.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-left-color: #1CAF9A;
}

.tooltip-teal.bs-tooltip-right .arrow::before,
.tooltip-teal.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.tooltip-teal.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: #1CAF9A;
}

.tooltip-pink .tooltip-inner {
    background-color: #e83e8c;
    color: #fff;
}

.tooltip-pink.bs-tooltip-top .arrow::before,
.tooltip-pink.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.tooltip-pink.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: #e83e8c;
}

.tooltip-pink.bs-tooltip-bottom .arrow::before,
.tooltip-pink.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.tooltip-pink.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: #e83e8c;
}

.tooltip-pink.bs-tooltip-left .arrow::before,
.tooltip-pink.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.tooltip-pink.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-left-color: #e83e8c;
}

.tooltip-pink.bs-tooltip-right .arrow::before,
.tooltip-pink.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.tooltip-pink.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: #e83e8c;
}


/* ------------------------------------------- */


/* ############### 3.14 Tables ############### */


/* ------------------------------------------- */

.table {
    border-collapse: separate;
    border-spacing: 0;
}

.table th.valign-middle,
.table td.valign-middle {
    vertical-align: middle;
}

.table thead>tr>th,
.table tfoot>tr>th {
    border-top: 0;
    border-bottom: 0;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    color: #343a40;
    letter-spacing: 0.5px;
}

.table .thead-colored>tr>th,
.table .thead-colored>tr>td {
    font-weight: 500;
    color: rgba(255, 255, 255, 0.75);
    border-color: rgba(255, 255, 255, 0.2);
    border-bottom-width: 0;
    border-top-width: 0;
}

.table .thead-colored>tr>th:first-child,
.table .thead-colored>tr>td:first-child {
    border-top-left-radius: 3px;
}

.table .thead-colored>tr>th:last-child,
.table .thead-colored>tr>td:last-child {
    border-top-right-radius: 3px;
}

.table .thead-colored+tbody>tr:first-child>td,
.table .thead-colored+tbody>tr:first-child>th {
    border-top-width: 0;
}

.table .thead-primary>tr>th,
.table .thead-primary>tr>td {
    background-color: #0866C6;
}

.table .thead-success>tr>th,
.table .thead-success>tr>td {
    background-color: #23BF08;
}

.table .thead-warning>tr>th,
.table .thead-warning>tr>td {
    background-color: #F49917;
}

.table .thead-danger>tr>th,
.table .thead-danger>tr>td {
    background-color: #DC3545;
}

.table .thead-info>tr>th,
.table .thead-info>tr>td {
    background-color: #17A2B8;
}

.table .thead-teal>tr>th,
.table .thead-teal>tr>td {
    background-color: #1CAF9A;
}

.table .thead-indigo>tr>th,
.table .thead-indigo>tr>td {
    background-color: #6610f2;
}

.table .thead-purple>tr>th,
.table .thead-purple>tr>td {
    background-color: #6f42c1;
}

.table .thead-pink>tr>th,
.table .thead-pink>tr>td {
    background-color: #e83e8c;
}

.table .thead-orange>tr>th,
.table .thead-orange>tr>td {
    background-color: #f27510;
}

.table .thead-dark>tr>th,
.table .thead-dark>tr>td {
    background-color: #343a40;
}

.table .thead-light>tr>th,
.table .thead-light>tr>td {
    background-color: #e9ecef;
    color: #343a40;
}

.table tbody>tr>th {
    color: #343a40;
    font-weight: 500;
}

.table-bordered {
    border: 0;
}

.table-bordered>thead>tr>th,
.table-bordered>thead>tr>td {
    border-bottom: 1px solid #e9ecef;
    border-top: 0;
    border-right-width: 0;
}

.table-bordered>thead>tr>th:first-child,
.table-bordered>thead>tr>td:first-child {
    border-left-width: 0;
}

.table-bordered>tbody>tr>th,
.table-bordered>tbody>tr>td {
    border-right-width: 0;
    border-top-width: 0;
}

.table-bordered>tbody>tr>th:first-child,
.table-bordered>tbody>tr>td:first-child {
    border-left-width: 0;
}

.table-bordered>tbody>tr:last-child>th,
.table-bordered>tbody>tr:last-child>td {
    border-bottom-width: 0;
}

.table-colored {
    border-collapse: separate;
    border-spacing: 0;
    border-width: 0;
    border-radius: 3px;
}

.table-colored>thead>tr>th,
.table-colored>thead>tr>td {
    color: rgba(255, 255, 255, 0.75);
    font-weight: 500;
    border-top: 0;
}

.table-colored>thead>tr:first-child>th:first-child,
.table-colored>thead>tr:first-child>td:first-child {
    border-top-left-radius: 3px;
}

.table-colored>thead>tr:first-child>th:last-child,
.table-colored>thead>tr:first-child>td:last-child {
    border-top-right-radius: 3px;
}

.table-colored>tbody>tr>th,
.table-colored>tbody>tr>td {
    color: #fff;
    border-color: rgba(255, 255, 255, 0.4);
}

.table-colored>tbody>tr:first-child>th,
.table-colored>tbody>tr:first-child>td {
    border-top-width: 0;
}

.table-colored>tbody>tr:last-child>th,
.table-colored>tbody>tr:last-child>td {
    border-bottom-width: 0;
}

.table-colored.table-bordered thead tr>th,
.table-colored.table-bordered thead tr>td {
    border-top: 0;
    border-bottom: 0;
    border-color: rgba(255, 255, 255, 0.3);
}

.table-colored.table-bordered thead tr>th:first-child,
.table-colored.table-bordered thead tr>td:first-child {
    border-left-width: 0;
}

.table-colored.table-bordered thead tr>th:last-child,
.table-colored.table-bordered thead tr>td:last-child {
    border-right-width: 0;
}

.table-colored.table-bordered tbody tr>th:first-child,
.table-colored.table-bordered tbody tr>td:first-child {
    border-left-width: 0;
}

.table-colored.table-bordered tbody tr>th:last-child,
.table-colored.table-bordered tbody tr>td:last-child {
    border-right-width: 0;
}

.table-colored.table-bordered tbody tr:last-child>th:first-child,
.table-colored.table-bordered tbody tr:last-child>td:first-child {
    border-bottom-left-radius: 3px;
}

.table-colored.table-bordered tbody tr:last-child>th:last-child,
.table-colored.table-bordered tbody tr:last-child>td:last-child {
    border-bottom-right-radius: 3px;
}

.table-primary>thead>tr>th,
.table-primary>thead>tr>td {
    background-color: #0866C6;
}

.table-primary>tbody>tr>th,
.table-primary>tbody>tr>td {
    background-color: #d0e6fd;
    color: #043364;
}

.table-primary.table-striped tbody tr:nth-of-type(2n+1) {
    background-color: transparent;
}

.table-primary.table-striped tbody tr:nth-of-type(2n+1)>th,
.table-primary.table-striped tbody tr:nth-of-type(2n+1)>td {
    background-color: #b7d9fc;
}

.table-success>thead>tr>th,
.table-success>thead>tr>td {
    background-color: #23BF08;
}

.table-success>tbody>tr>th,
.table-success>tbody>tr>td {
    background-color: #d1fdc9;
    color: #115d04;
}

.table-success.table-striped tbody tr:nth-of-type(2n+1) {
    background-color: transparent;
}

.table-success.table-striped tbody tr:nth-of-type(2n+1)>th,
.table-success.table-striped tbody tr:nth-of-type(2n+1)>td {
    background-color: #bcfcb1;
}

.table-warning>thead>tr>th,
.table-warning>thead>tr>td {
    background-color: #F49917;
}

.table-warning>tbody>tr>th,
.table-warning>tbody>tr>td {
    background-color: #fdefda;
    color: #9e6007;
}

.table-warning.table-striped tbody tr:nth-of-type(2n+1) {
    background-color: transparent;
}

.table-warning.table-striped tbody tr:nth-of-type(2n+1)>th,
.table-warning.table-striped tbody tr:nth-of-type(2n+1)>td {
    background-color: #fce4c1;
}

.table-danger>thead>tr>th,
.table-danger>thead>tr>td {
    background-color: #DC3545;
}

.table-danger>tbody>tr>th,
.table-danger>tbody>tr>td {
    background-color: #fae3e5;
    color: #921925;
}

.table-danger.table-striped tbody tr:nth-of-type(2n+1) {
    background-color: transparent;
}

.table-danger.table-striped tbody tr:nth-of-type(2n+1)>th,
.table-danger.table-striped tbody tr:nth-of-type(2n+1)>td {
    background-color: #f6cdd1;
}

.table-info>thead>tr>th,
.table-info>thead>tr>td {
    background-color: #17A2B8;
}

.table-info>tbody>tr>th,
.table-info>tbody>tr>td {
    background-color: #beeff7;
    color: #0c525d;
}

.table-info.table-striped tbody tr:nth-of-type(2n+1) {
    background-color: transparent;
}

.table-info.table-striped tbody tr:nth-of-type(2n+1)>th,
.table-info.table-striped tbody tr:nth-of-type(2n+1)>td {
    background-color: #a7e9f4;
}

.table-teal>thead>tr>th,
.table-teal>thead>tr>td {
    background-color: #1CAF9A;
}

.table-teal>tbody>tr>th,
.table-teal>tbody>tr>td {
    background-color: #a6f1e6;
    color: #0e574d;
}

.table-teal.table-striped tbody tr:nth-of-type(2n+1) {
    background-color: transparent;
}

.table-teal.table-striped tbody tr:nth-of-type(2n+1)>th,
.table-teal.table-striped tbody tr:nth-of-type(2n+1)>td {
    background-color: #90ede0;
}

.table-indigo>thead>tr>th,
.table-indigo>thead>tr>td {
    background-color: #6610f2;
}

.table-indigo>tbody>tr>th,
.table-indigo>tbody>tr>td {
    background-color: #e2d1fd;
    color: #3d0894;
}

.table-indigo.table-striped tbody tr:nth-of-type(2n+1) {
    background-color: transparent;
}

.table-indigo.table-striped tbody tr:nth-of-type(2n+1)>th,
.table-indigo.table-striped tbody tr:nth-of-type(2n+1)>td {
    background-color: #d2b9fb;
}

.table-purple>thead>tr>th,
.table-purple>thead>tr>td {
    background-color: #6f42c1;
}

.table-purple>tbody>tr>th,
.table-purple>tbody>tr>td {
    background-color: #e4dcf3;
    color: #432776;
}

.table-purple.table-striped tbody tr:nth-of-type(2n+1) {
    background-color: transparent;
}

.table-purple.table-striped tbody tr:nth-of-type(2n+1)>th,
.table-purple.table-striped tbody tr:nth-of-type(2n+1)>td {
    background-color: #d5c8ed;
}

.table-pink>thead>tr>th,
.table-pink>thead>tr>td {
    background-color: #e83e8c;
}

.table-pink>tbody>tr>th,
.table-pink>tbody>tr>td {
    background-color: #fdebf3;
    color: #ac145a;
}

.table-pink.table-striped tbody tr:nth-of-type(2n+1) {
    background-color: transparent;
}

.table-pink.table-striped tbody tr:nth-of-type(2n+1)>th,
.table-pink.table-striped tbody tr:nth-of-type(2n+1)>td {
    background-color: #fad4e6;
}

.table-orange>thead>tr>th,
.table-orange>thead>tr>td {
    background-color: #f27510;
}

.table-orange>tbody>tr>th,
.table-orange>tbody>tr>td {
    background-color: #fde5d1;
    color: #944708;
}

.table-orange.table-striped tbody tr:nth-of-type(2n+1) {
    background-color: transparent;
}

.table-orange.table-striped tbody tr:nth-of-type(2n+1)>th,
.table-orange.table-striped tbody tr:nth-of-type(2n+1)>td {
    background-color: #fbd7b9;
}

.table-dark>thead>tr>th,
.table-dark>thead>tr>td {
    background-color: #343a40;
}

.table-dark>tbody>tr>th,
.table-dark>tbody>tr>td {
    background-color: #dee2e6;
    color: #060708;
}

.table-dark.table-striped tbody tr:nth-of-type(2n+1) {
    background-color: transparent;
}

.table-dark.table-striped tbody tr:nth-of-type(2n+1)>th,
.table-dark.table-striped tbody tr:nth-of-type(2n+1)>td {
    background-color: #cfd5db;
}

.table-light>thead>tr>th,
.table-light>thead>tr>td {
    background-color: #ced4da;
}

.table-light>tbody>tr>th,
.table-light>tbody>tr>td {
    background-color: #e9ecef;
    color: #94a1ae;
}

.table-light.table-striped tbody tr:nth-of-type(2n+1) {
    background-color: transparent;
}

.table-light.table-striped tbody tr:nth-of-type(2n+1)>th,
.table-light.table-striped tbody tr:nth-of-type(2n+1)>td {
    background-color: #dadfe4;
}

.table-light>thead>tr>th,
.table-light>thead>tr>td {
    color: #343a40;
}


/* ########## RTL SUPPORT ########## */

.rtl th {
    text-align: right;
}

.rtl .table .thead-colored>tr>th:first-child,
.rtl .table .thead-colored>tr>td:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 3px;
}

.rtl .table .thead-colored>tr>th:last-child,
.rtl .table .thead-colored>tr>td:last-child {
    border-top-right-radius: 0;
    border-top-left-radius: 3px;
}

.rtl .table-bordered>thead>tr>th,
.rtl .table-bordered>thead>tr>td {
    border-left-width: 0;
}

.rtl .table-bordered>thead>tr>th:first-child,
.rtl .table-bordered>thead>tr>td:first-child {
    border-left-width: 1px;
    border-right-width: 0;
}

.rtl .table-bordered>tbody>tr>th,
.rtl .table-bordered>tbody>tr>td {
    border-left-width: 0;
}

.rtl .table-bordered>tbody>tr>th:first-child,
.rtl .table-bordered>tbody>tr>td:first-child {
    border-left-width: 1px;
    border-right-width: 0;
}

.rtl .table-colored>thead>tr:first-child>th:first-child,
.rtl .table-colored>thead>tr:first-child>td:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 3px;
}

.rtl .table-colored>thead>tr:first-child>th:last-child,
.rtl .table-colored>thead>tr:first-child>td:last-child {
    border-top-right-radius: 0;
    border-top-left-radius: 3px;
}


/* -------------------------------------------- */


/* ############### 3.16 Widgets ############### */


/* -------------------------------------------- */

.widget-1 {
    border-radius: 3px;
}

.widget-1>div:first-child .card {
    border-left: 0;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.widget-1>div:last-child .card {
    border-right: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

@media (min-width: 576px) {
    .widget-1>div:first-child .card {
        border-top-right-radius: 0;
    }

    .widget-1>div:nth-child(2) .card {
        border-top-right-radius: 3px;
        border-left: 1px solid #e9ecef;
    }

    .widget-1>div:nth-child(3) .card {
        border-bottom-left-radius: 3px;
    }

    .widget-1>div:last-child .card {
        border-bottom-left-radius: 0;
        border-left: 1px solid #e9ecef;
    }
}

@media (min-width: 992px) {
    .widget-1>div:first-child .card {
        border-bottom-left-radius: 3px;
    }

    .widget-1>div:nth-child(2) .card {
        border-top-right-radius: 0;
    }

    .widget-1>div:nth-child(3) .card {
        border-left: 1px solid #e9ecef;
        border-bottom-left-radius: 0;
    }

    .widget-1>div:last-child .card {
        border-top-right-radius: 3px;
    }
}

.widget-1 .card {
    border: 0;
    border-radius: 0;
}

.widget-1 .card-header {
    border-bottom: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.widget-1 .card-header a {
    color: #6c757d;
}

.widget-1 .card-header a:hover,
.widget-1 .card-header a:focus {
    color: #17A2B8;
}

.widget-1 .card-header a i {
    line-height: 0;
}

.widget-1 .card-title {
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 12px;
    font-weight: 600;
    color: #343a40;
    margin-bottom: 0;
}

.widget-1 .card-body {
    padding: 0 20px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.widget-1 .card-body span:last-child {
    font-family: "Lato", "Helvetica Neue", Arial, sans-serif;
    font-weight: 700;
    color: #343a40;
    font-size: 24px;
}

.widget-1 .card-footer {
    border-color: #e9ecef;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
}

.widget-1 .card-footer h6 {
    font-family: "Lato", "Helvetica Neue", Arial, sans-serif;
    font-weight: 700;
}


/* RTL widget-1 */

.rtl .widget-1>div:first-child .card {
    border-right: 0;
}

.rtl .widget-1>div:last-child .card {
    border-left: 0;
}

@media (min-width: 576px) {
    .rtl .widget-1>div:first-child .card {
        border-top-left-radius: 0;
    }

    .rtl .widget-1>div:nth-child(2) .card {
        border-top-right-radius: 0;
        border-top-left-radius: 3px;
        border-right: 1px solid #e9ecef;
        border-left: 0;
    }

    .rtl .widget-1>div:nth-child(3) .card {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 3px;
    }

    .rtl .widget-1>div:last-child .card {
        border-bottom-right-radius: 0;
        border-right: 1px solid #e9ecef;
        border-left: 0;
    }
}

@media (min-width: 992px) {
    .rtl .widget-1>div:first-child .card {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 0;
    }

    .rtl .widget-1>div:nth-child(2) .card {
        border-top-left-radius: 0;
    }

    .rtl .widget-1>div:nth-child(3) .card {
        border-right: 1px solid #e9ecef;
        border-left: 0;
        border-bottom-right-radius: 0;
    }

    .rtl .widget-1>div:last-child .card {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
    }
}

.widget-2>.card {
    border: 0;
}

.widget-2 .card-header {
    padding: 20px;
    background-color: transparent;
}

@media (min-width: 480px) {
    .widget-2 .card-header {
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.widget-2 .card-header .btn-group {
    text-transform: uppercase;
    margin-top: 10px;
    margin-right: -10px;
}

@media (min-width: 576px) {
    .widget-2 .card-header .btn-group {
        margin-top: 0;
    }
}

.widget-2 .card-header .btn-group .btn,
.widget-2 .card-header .btn-group .sp-container button,
.sp-container .widget-2 .card-header .btn-group button {
    border: 0;
    background-color: #f8f9fa;
    color: #6c757d;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 11px;
}

.widget-2 .card-header .btn-group .btn:first-child,
.widget-2 .card-header .btn-group .sp-container button:first-child,
.sp-container .widget-2 .card-header .btn-group button:first-child {
    border-top-left-radius: 1px;
    border-bottom-left-radius: 1px;
}

.widget-2 .card-header .btn-group .btn:last-child,
.widget-2 .card-header .btn-group .sp-container button:last-child,
.sp-container .widget-2 .card-header .btn-group button:last-child {
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px;
}

.widget-2 .card-header .btn-group .btn:hover,
.widget-2 .card-header .btn-group .sp-container button:hover,
.sp-container .widget-2 .card-header .btn-group button:hover,
.widget-2 .card-header .btn-group .btn:focus,
.widget-2 .card-header .btn-group .sp-container button:focus,
.sp-container .widget-2 .card-header .btn-group button:focus {
    background-color: #e9ecef;
}

.widget-2 .card-header .btn-group .btn.active,
.widget-2 .card-header .btn-group .sp-container button.active,
.sp-container .widget-2 .card-header .btn-group button.active {
    background-color: #e9ecef;
}

.widget-2 .card-title {
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 12px;
    font-weight: 600;
    color: #343a40;
    margin-bottom: 0;
}

.widget-3 figure {
    margin-bottom: 0;
}

.widget-3 figure,
.widget-3 img {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.widget-3>div:last-child {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

@media (min-width: 768px) {

    .widget-3 figure,
    .widget-3 img {
        border-top-right-radius: 0;
        border-bottom-left-radius: 3px;
    }

    .widget-3>div:last-child {
        border-bottom-left-radius: 0;
        border-top-right-radius: 3px;
    }

    .widget-3.flex-row-reverse figure,
    .widget-3.flex-row-reverse img {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    .widget-3.flex-row-reverse>div:last-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
    }
}


/* RTL widget-3 */

@media (min-width: 768px) {
    .rtl .widget-3 img {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

@media (min-width: 768px) {
    .rtl .widget-3.flex-row-reverse img {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.widget-4 .card-header {
    border-bottom-color: #e9ecef;
    background-color: transparent;
    padding: 20px 25px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

@media (min-width: 576px) {
    .widget-4 .card-header {
        justify-content: space-between;
    }
}

.widget-4 .card-header a {
    color: #6c757d;
}

.widget-4 .card-header a:hover,
.widget-4 .card-header a:focus {
    color: #343a40;
}

.widget-4 .card-body {
    background-color: #f8f9fa;
    text-align: center;
    padding-top: 70px;
    padding-bottom: 25px;
}

.widget-4 .card-profile-img {
    position: absolute;
    text-align: center;
    pointer-events: none;
    left: 50%;
    top: 25px;
    margin-left: -50px;
}

.widget-4 .card-profile-img img {
    width: 100px;
    border-radius: 100%;
}

.widget-4 .card-footer {
    background-color: transparent;
    border-top-color: #e9ecef;
}

.widget-5 {
    border: 0;
    font-size: 14px;
}

.widget-5 .card-header {
    padding: 15px 20px;
    background-color: transparent;
    border-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
}

.widget-5 .card-header span {
    letter-spacing: 0.5px;
    font-weight: 500;
    color: #343a40;
    font-size: 13px;
}

.widget-5 .card-header a {
    color: #6c757d;
}

.widget-5 .card-header a:hover,
.widget-5 .card-header a:focus {
    color: #17A2B8;
}

.widget-5 .list-group-item {
    border-color: #e9ecef;
}

.widget-5 .list-group-item.media {
    display: flex;
    align-items: center;
}

.widget-5 .list-group-item img {
    width: 50px;
    border-radius: 100%;
    display: flex;
    margin-right: 15px;
}

.widget-5 .list-group-item .msg-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3px;
}

.widget-5 .list-group-item .msg-top span:first-child {
    color: #343a40;
    font-weight: 500;
    font-size: 14px;
}

.widget-5 .list-group-item .msg-top span:last-child {
    color: #adb5bd;
    font-size: 11px;
    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
}

.widget-5 .list-group-item .msg-summary {
    font-size: 12px;
    color: #6c757d;
    margin-bottom: 0;
}

.widget-5 .card-footer {
    background-color: transparent;
    border-top: 0;
    text-align: center;
}


/* RTL widget-5 */

.rtl .widget-5 .list-group-item img {
    margin-left: 15px;
    margin-right: 0;
}

.widget-6 {
    border: 0;
}

.widget-6 .card-header {
    background-color: transparent;
    border-color: #e9ecef;
    padding: 20px 25px;
}

.widget-6 .card-header>span {
    margin-left: 60px;
}

@media (min-width: 480px) {
    .widget-6 .card-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .widget-6 .card-header>span {
        margin-left: 0;
    }
}

.widget-6 .card-footer {
    background-color: transparent;
    border-color: #e9ecef;
}

.widget-6 .card-body {
    background-color: #f8f9fa;
}

.widget-6 .chat-time {
    font-size: 11px;
    color: #adb5bd;
    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
    margin-left: 5px;
}

.widget-6 .chat-1,
.widget-6 .chat-2 {
    padding: 10px;
    display: inline-block;
}

@media (min-width: 768px) {

    .widget-6 .chat-1,
    .widget-6 .chat-2 {
        max-width: 90%;
    }
}

.widget-6 .chat-1 {
    background-color: #e9ecef;
}

.widget-6 .chat-2 {
    background-color: #a7e9f4;
}


/* RTL widget-6 */

.rtl .widget-6 .card-header>span {
    margin-right: 60px;
    margin-left: 0;
}

@media (min-width: 480px) {
    .rtl .widget-6 .card-header>span {
        margin-right: 0;
    }
}

.rtl .widget-6 .chat-time {
    margin-left: 0;
    margin-right: 5px;
}

.widget-7 {
    border: 0;
    padding: 30px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.widget-8 {
    border: 0;
    padding: 25px;
}

.widget-8 .wt-duration {
    font-size: 12px;
    align-items: center;
}

.widget-8 a {
    color: #6c757d;
}

.widget-8 a:hover,
.widget-8 a:focus {
    color: #17A2B8;
}

.widget-8 .wt-song {
    display: flex;
    justify-content: center;
}

@media (min-width: 576px) {
    .widget-8 .wt-song {
        justify-content: flex-start;
        margin-bottom: 20px;
    }
}

@media (min-width: 992px) {
    .widget-8 .wt-song {
        margin-bottom: 0;
    }
}

.widget-8 .wt-control {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

@media (min-width: 576px) {
    .widget-8 .wt-control {
        margin-top: 0;
        justify-content: flex-start;
    }
}

@media (min-width: 992px) {
    .widget-8 .wt-control {
        justify-content: center;
    }
}

.widget-8 .wt-volume {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

@media (min-width: 576px) {
    .widget-8 .wt-volume {
        justify-content: flex-end;
        margin-top: 0;
    }
}

.widget-8.widget-dark .progress {
    background-color: rgba(255, 255, 255, 0.1);
}

.widget-8.widget-dark a {
    color: rgba(255, 255, 255, 0.5);
}

.widget-8.widget-dark a:hover,
.widget-8.widget-dark a:focus {
    color: #fff;
}

.widget-9 {
    border: 0;
}

.widget-9 .list-group-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
}

.widget-9 .wt-play {
    font-size: 14px;
    color: #6c757d;
    margin-right: 15px;
}

.widget-9 .wt-play:hover,
.widget-9 .wt-play:focus {
    color: #17A2B8;
}

.widget-9 .wt-time {
    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
    font-size: 12px;
}


/* RTL widget-9 */

.rtl .widget-9 .wt-play {
    margin-right: 0;
    margin-left: 15px;
}

.rtl .widget-9 .wt-time {
    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
    font-size: 12px;
}

.widget-10 {
    border: 0;
}

.widget-10 .card-header {
    background-color: transparent;
    border-color: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.widget-10 .card-title {
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 12px;
    font-weight: 600;
    color: #343a40;
    margin-bottom: 0;
}

.widget-10 .list-group-item {
    border-color: #e9ecef;
}

.widget-11 {
    border: 0;
}

.widget-11 .card-header {
    background-color: transparent;
    border-color: #e9ecef;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.widget-11 .card-title {
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 12px;
    font-weight: 600;
    color: #343a40;
    margin-bottom: 0;
}

.widget-11 li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #343a40;
}

.widget-11 li>span {
    font-family: "Lato", "Helvetica Neue", Arial, sans-serif;
    font-size: 13px;
    color: #6c757d;
}

.widget-11 li+li {
    margin-top: 5px;
}

.widget-11 .card-footer {
    background-color: transparent;
    border-color: #e9ecef;
    text-align: center;
    font-size: 12px;
}

.widget-12 {
    border: 0;
}

.widget-12 .card-header {
    background-color: transparent;
}

.widget-12 .card-title {
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 12px;
    font-weight: 600;
    color: #343a40;
    margin-bottom: 0;
}

.widget-13 {
    border: 0;
}

.widget-13 .card-header {
    background-color: transparent;
    border-color: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.widget-13 .card-title {
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 12px;
    font-weight: 600;
    color: #343a40;
    margin-bottom: 0;
}

.widget-13 .list-group-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.widget-13 .list-group-item>a {
    font-size: 12px;
}

.widget-14 {
    border: 0;
}

.widget-14 .card-title {
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 12px;
    font-weight: 600;
    color: #343a40;
    margin-bottom: 10px;
}

@media (min-width: 480px) {
    .widget-14 .card-title {
        font-size: 14px;
    }
}

.widget-14 .card-body {
    padding: 25px;
}

@media (min-width: 480px) {
    .widget-14 .card-body {
        padding: 40px;
    }
}

.widget-14 .card-footer {
    background-color: transparent;
    border-top: 0;
    padding: 0 25px 25px;
}

@media (min-width: 480px) {
    .widget-14 .card-footer {
        padding: 0 40px 40px;
    }
}

.widget-14 .card-footer .btn,
.widget-14 .card-footer .sp-container button,
.sp-container .widget-14 .card-footer button {
    text-transform: uppercase;
    font-family: "Montserrat", "Fira Sans", "Helvetica Neue", Arial, sans-serif;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.5px;
    padding: 12px 20px;
}

@media (min-width: 480px) {

    .widget-14 .card-footer .btn,
    .widget-14 .card-footer .sp-container button,
    .sp-container .widget-14 .card-footer button {
        padding: 12px 40px;
    }
}

.widget-15 {
    border: 0;
}

.widget-15 .card-title {
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 12px;
    font-weight: 600;
    color: #343a40;
    margin-bottom: 10px;
}

@media (min-width: 480px) {
    .widget-15 .card-title {
        font-size: 14px;
    }
}

.widget-15 .card-body {
    padding: 25px;
}

@media (min-width: 480px) {
    .widget-15 .card-body {
        padding: 40px;
    }
}

.widget-15 .card-footer {
    background-color: transparent;
    border-top: 0;
    padding: 0 25px 25px;
}

@media (min-width: 480px) {
    .widget-15 .card-footer {
        padding: 0 40px 40px;
    }
}

.widget-15 .card-footer .btn,
.widget-15 .card-footer .sp-container button,
.sp-container .widget-15 .card-footer button {
    text-transform: uppercase;
    font-family: "Montserrat", "Fira Sans", "Helvetica Neue", Arial, sans-serif;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.5px;
    padding: 12px 20px;
}

@media (min-width: 480px) {

    .widget-15 .card-footer .btn,
    .widget-15 .card-footer .sp-container button,
    .sp-container .widget-15 .card-footer button {
        padding: 12px 40px;
    }
}

.widget-16 img {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

@media (min-width: 768px) {
    .widget-16 img {
        border-top-right-radius: 0;
        border-bottom-left-radius: 3px;
    }
}

.widget-17 img {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

@media (min-width: 768px) {
    .widget-17 img {
        border-top-left-radius: 0;
        border-bottom-right-radius: 3px;
    }
}

.widget-18 {
    border: 0;
    padding-top: 60px;
    padding-bottom: 60px;
}

@media (min-width: 576px) {
    .widget-18 .wt-content {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.widget-18 .wt-content-item {
    text-align: center;
    padding-left: 25px;
    padding-right: 25px;
}

.widget-18 .wt-title {
    font-size: 24px;
    font-weight: 300;
    color: #343a40;
}

@media (min-width: 576px) {
    .widget-18 .wt-title {
        font-size: 40px;
    }
}

.widget-18 .input-group {
    margin-bottom: 0;
}

@media (min-width: 576px) {
    .widget-18 .input-group {
        width: 250px;
    }
}

@media (min-width: 768px) {
    .widget-18 .input-group {
        width: 400px;
    }
}

.widget-18 .input-group-text {
    padding-left: 15px;
    padding-right: 15px;
    background-color: transparent;
    color: #adb5bd;
    font-size: 16px;
    line-height: 1;
}

.widget-18 .form-control,
.widget-18 .dataTables_filter input,
.dataTables_filter .widget-18 input {
    padding-top: 12px;
    padding-bottom: 12px;
    height: auto;
}

.widget-18 .btn,
.widget-18 .sp-container button,
.sp-container .widget-18 button {
    padding: 12px 35px;
    border-left: 0;
    height: 100%;
    width: 100%;
}

@media (min-width: 576px) {

    .widget-18 .btn,
    .widget-18 .sp-container button,
    .sp-container .widget-18 button {
        margin-left: 10px;
        width: auto;
    }
}

.widget-18 .nav {
    display: none;
    justify-content: center;
    margin-top: 25px;
}

@media (min-width: 576px) {
    .widget-18 .nav {
        display: flex;
    }
}

.widget-18 .nav-link {
    color: #17A2B8;
    padding-left: 2px;
    padding-right: 2px;
    padding-bottom: 0;
}

.widget-18 .nav-link p {
    margin-bottom: 0;
    margin-top: 10px;
}


/** RTL SUPPORT */

@media (min-width: 576px) {

    .rtl .widget-18 .btn,
    .rtl .widget-18 .sp-container button,
    .sp-container .rtl .widget-18 button {
        margin-left: 0;
        margin-right: 10px;
    }
}


/* ------------------------------------------------------- */


/* ############### 4. CUSTOM VENDOR STYLES ############### */


/* ------------------------------------------------------- */


/* -------------------------------------------- */


/* ############### 4.1 Chartist ############### */


/* -------------------------------------------- */

.br-chartist .ct-label,
.br-chartist-dark .ct-label {
    font-size: 11px;
}

.br-chartist .ct-line,
.br-chartist-dark .ct-line {
    stroke-width: 1px;
}

.br-chartist .ct-point,
.br-chartist-dark .ct-point {
    stroke-width: 8px;
}

.br-chartist .ct-chart-pie .ct-label,
.br-chartist .ct-chart-donut .ct-label,
.br-chartist-dark .ct-chart-pie .ct-label,
.br-chartist-dark .ct-chart-donut .ct-label {
    font-size: 16px;
    fill: rgba(255, 255, 255, 0.75);
}

.br-chartist .ct-series-a .ct-point,
.br-chartist .ct-series-a .ct-line,
.br-chartist .ct-series-a .ct-bar,
.br-chartist .ct-series-a .ct-slice-donut,
.br-chartist-dark .ct-series-a .ct-point,
.br-chartist-dark .ct-series-a .ct-line,
.br-chartist-dark .ct-series-a .ct-bar,
.br-chartist-dark .ct-series-a .ct-slice-donut {
    stroke: #ED1E79;
}

.br-chartist .ct-series-a .ct-slice-pie,
.br-chartist .ct-series-a .ct-slice-donut-solid,
.br-chartist-dark .ct-series-a .ct-slice-pie,
.br-chartist-dark .ct-series-a .ct-slice-donut-solid {
    fill: #ED1E79;
    stroke: #fff;
}

.br-chartist .ct-series-b .ct-point,
.br-chartist .ct-series-b .ct-line,
.br-chartist .ct-series-b .ct-bar,
.br-chartist .ct-series-b .ct-slice-donut,
.br-chartist-dark .ct-series-b .ct-point,
.br-chartist-dark .ct-series-b .ct-line,
.br-chartist-dark .ct-series-b .ct-bar,
.br-chartist-dark .ct-series-b .ct-slice-donut {
    stroke: #93268F;
}

.br-chartist .ct-series-b .ct-slice-pie,
.br-chartist .ct-series-b .ct-slice-donut-solid,
.br-chartist-dark .ct-series-b .ct-slice-pie,
.br-chartist-dark .ct-series-b .ct-slice-donut-solid {
    fill: #93268F;
    stroke: #fff;
}

.br-chartist .ct-series-c .ct-point,
.br-chartist .ct-series-c .ct-line,
.br-chartist .ct-series-c .ct-bar,
.br-chartist .ct-series-c .ct-slice-donut,
.br-chartist-dark .ct-series-c .ct-point,
.br-chartist-dark .ct-series-c .ct-line,
.br-chartist-dark .ct-series-c .ct-bar,
.br-chartist-dark .ct-series-c .ct-slice-donut {
    stroke: #818AC3;
}

.br-chartist .ct-series-c .ct-slice-pie,
.br-chartist .ct-series-c .ct-slice-donut-solid,
.br-chartist-dark .ct-series-c .ct-slice-pie,
.br-chartist-dark .ct-series-c .ct-slice-donut-solid {
    fill: #818AC3;
    stroke: #fff;
}

.br-chartist .ct-series-d .ct-point,
.br-chartist .ct-series-d .ct-line,
.br-chartist .ct-series-d .ct-bar,
.br-chartist .ct-series-d .ct-slice-donut,
.br-chartist-dark .ct-series-d .ct-point,
.br-chartist-dark .ct-series-d .ct-line,
.br-chartist-dark .ct-series-d .ct-bar,
.br-chartist-dark .ct-series-d .ct-slice-donut {
    stroke: #0082CD;
}

.br-chartist .ct-series-d .ct-slice-pie,
.br-chartist .ct-series-d .ct-slice-donut-solid,
.br-chartist-dark .ct-series-d .ct-slice-pie,
.br-chartist-dark .ct-series-d .ct-slice-donut-solid {
    fill: #0082CD;
    stroke: #fff;
}

.br-chartist .ct-series-e .ct-point,
.br-chartist .ct-series-e .ct-line,
.br-chartist .ct-series-e .ct-bar,
.br-chartist .ct-series-e .ct-slice-donut,
.br-chartist-dark .ct-series-e .ct-point,
.br-chartist-dark .ct-series-e .ct-line,
.br-chartist-dark .ct-series-e .ct-bar,
.br-chartist-dark .ct-series-e .ct-slice-donut {
    stroke: #EC7A9C;
}

.br-chartist .ct-series-e .ct-slice-pie,
.br-chartist .ct-series-e .ct-slice-donut-solid,
.br-chartist-dark .ct-series-e .ct-slice-pie,
.br-chartist-dark .ct-series-e .ct-slice-donut-solid {
    fill: #EC7A9C;
    stroke: #fff;
}

.br-chartist-dark .ct-grid {
    stroke: rgba(255, 255, 255, 0.15);
}

.br-chartist-dark .ct-label {
    color: #868ba1;
}


/* ---------------------------------------------- */


/* ############### 4.2 Codemirror ############### */


/* ---------------------------------------------- */

.CodeMirror-simplescroll-horizontal div,
.CodeMirror-simplescroll-vertical div {
    border: 0;
    border-radius: 0;
}

.cmHeight100p .CodeMirror {
    height: 100%;
}


/* ---------------------------------------------- */


/* ############### 4.3 DataTables ############### */


/* ---------------------------------------------- */

table.dataTable {
    border: 1px solid #dee2e6;
    margin-bottom: 15px;
}

table.dataTable thead th,
table.dataTable thead td {
    padding: 0.75rem;
    border-bottom: 1px solid #dee2e6;
    position: relative;
}

table.dataTable thead th.sorting::after,
table.dataTable thead th.sorting_asc::after,
table.dataTable thead th.sorting_desc::after,
table.dataTable thead td.sorting::after,
table.dataTable thead td.sorting_asc::after,
table.dataTable thead td.sorting_desc::after {
    content: '';
    border: 4px solid transparent;
    border-top-color: #ced4da;
    position: absolute;
    z-index: 10;
    top: 22px;
    right: 8px;
}

table.dataTable thead th.sorting::before,
table.dataTable thead th.sorting_asc::before,
table.dataTable thead th.sorting_desc::before,
table.dataTable thead td.sorting::before,
table.dataTable thead td.sorting_asc::before,
table.dataTable thead td.sorting_desc::before {
    content: '';
    border: 4px solid transparent;
    border-bottom-color: #ced4da;
    position: absolute;
    z-index: 10;
    top: 12px;
    right: 8px;
}

table.dataTable thead th.sorting_asc::before,
table.dataTable thead td.sorting_asc::before {
    border-bottom-color: #17A2B8;
}

table.dataTable thead th.sorting_desc::after,
table.dataTable thead td.sorting_desc::after {
    border-top-color: #17A2B8;
}

table.dataTable tbody th,
table.dataTable tbody td {
    padding: 0.40rem;
    font-size: 13px;
}

table.dataTable tbody th .btn-drp,
table.dataTable tbody td .btn-drp {
    height: 26px;
    padding: 0px 7px;
    font-size: 13px;
}

table.dataTable tbody th input[type="checkbox"],
table.dataTable tbody td input[type="checkbox"] {
    margin-right: 6px;
}

table.dataTable.row-border tbody th,
table.dataTable.row-border tbody td,
table.dataTable.display tbody th,
table.dataTable.display tbody td {
    border-top-color: #dee2e6;
}

table.dataTable.no-footer {
    border-bottom-color: #dee2e6;
}

.dataTables_length {
    padding-bottom: 10px;
}

.dataTables_length .select2-container {
    width: 60px;
    margin-left: 0;
    margin-right: 10px;
}

.dataTables_filter {
    padding-bottom: 10px;
    padding-right: 5px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    padding-top: 0.54rem;
    padding-bottom: 0.54rem;
    background-color: #e9ecef;
    border-color: transparent;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button:focus {
    background-color: #ced4da;
    background-image: none;
    border-color: transparent;
    color: #343a40 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:active {
    box-shadow: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:focus {
    background-color: #17A2B8;
    background-image: none;
    border-color: transparent;
    color: #fff !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:focus {
    background-color: #e9ecef;
    color: #adb5bd !important;
}


/* ############### RTL SUPPORT ############### */

.rtl table.dataTable thead th.sorting::after,
.rtl table.dataTable thead th.sorting_asc::after,
.rtl table.dataTable thead th.sorting_desc::after,
.rtl table.dataTable thead td.sorting::after,
.rtl table.dataTable thead td.sorting_asc::after,
.rtl table.dataTable thead td.sorting_desc::after {
    right: auto;
    left: 8px;
}

.rtl table.dataTable thead th.sorting::before,
.rtl table.dataTable thead th.sorting_asc::before,
.rtl table.dataTable thead th.sorting_desc::before,
.rtl table.dataTable thead td.sorting::before,
.rtl table.dataTable thead td.sorting_asc::before,
.rtl table.dataTable thead td.sorting_desc::before {
    right: auto;
    left: 8px;
}

.rtl .dataTables_length .select2-container {
    margin-right: 0;
    margin-left: 10px;
}

.rtl .dataTables_filter {
    padding-right: 0;
    padding-left: 5px;
}


/* ---------------------------------------- */


/* ############### 4.4 Flot ############### */


/* ---------------------------------------- */

.tooltipflot {
    background-color: #000;
    font-size: 11px;
    padding: 5px 10px;
    color: rgba(255, 255, 255, 0.7);
    border-radius: 2px;
}

.legendColorBox>div {
    border: 0 !important;
    padding: 0 !important;
}

.legendLabel {
    font-size: 11px;
    padding-left: 5px;
}


/* -------------------------------------------------- */


/* ############### 4.5 IonRangeSlider ############### */


/* -------------------------------------------------- */

.irs-min,
.irs-max {
    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
    border-radius: 1px;
}

.irs-from,
.irs-to,
.irs-single {
    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
    background-color: #17A2B8;
    border-radius: 1px;
}

.irs-bar {
    background-image: none;
    background-color: #17A2B8;
}

.irs-bar-edge {
    background-image: none;
    background-color: #17A2B8;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}

.irs-line-left {
    background-image: none;
    background-color: #dee2e6;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}

.irs-line-mid {
    background-image: none;
    background-color: #dee2e6;
}

.irs-line-right {
    background-image: none;
    background-color: #dee2e6;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}

.irs-from::after,
.irs-to::after,
.irs-single::after {
    border-top-color: #17A2B8;
}

.irs-slider {
    background-image: none;
}

.irs-slider:before {
    content: '';
    width: 2px;
    height: 18px;
    position: absolute;
    background-color: #17A2B8;
    left: 50%;
    margin-left: -1px;
}

.irs-slider:hover:before,
.irs-slider:focus:before {
    background-color: #107282;
}


/* #################### COLOR VARIANT SKINS #################### */

.irs-primary .irs-bar,
.irs-primary .irs-bar-edge,
.irs-primary .irs-slider::before {
    background-color: #0866C6;
}

.irs-primary .irs-from,
.irs-primary .irs-to,
.irs-primary .irs-single {
    background-color: #0866C6;
}

.irs-primary .irs-from::after,
.irs-primary .irs-to::after,
.irs-primary .irs-single::after {
    border-top-color: #0866C6;
}

.irs-success .irs-bar,
.irs-success .irs-bar-edge,
.irs-success .irs-slider::before {
    background-color: #23BF08;
}

.irs-success .irs-from,
.irs-success .irs-to,
.irs-success .irs-single {
    background-color: #23BF08;
}

.irs-success .irs-from::after,
.irs-success .irs-to::after,
.irs-success .irs-single::after {
    border-top-color: #23BF08;
}

.irs-warning .irs-bar,
.irs-warning .irs-bar-edge,
.irs-warning .irs-slider::before {
    background-color: #F49917;
}

.irs-warning .irs-from,
.irs-warning .irs-to,
.irs-warning .irs-single {
    background-color: #F49917;
}

.irs-warning .irs-from::after,
.irs-warning .irs-to::after,
.irs-warning .irs-single::after {
    border-top-color: #F49917;
}

.irs-danger .irs-bar,
.irs-danger .irs-bar-edge,
.irs-danger .irs-slider::before {
    background-color: #DC3545;
}

.irs-danger .irs-from,
.irs-danger .irs-to,
.irs-danger .irs-single {
    background-color: #DC3545;
}

.irs-danger .irs-from::after,
.irs-danger .irs-to::after,
.irs-danger .irs-single::after {
    border-top-color: #DC3545;
}

.irs-info .irs-bar,
.irs-info .irs-bar-edge,
.irs-info .irs-slider::before {
    background-color: #17A2B8;
}

.irs-info .irs-from,
.irs-info .irs-to,
.irs-info .irs-single {
    background-color: #17A2B8;
}

.irs-info .irs-from::after,
.irs-info .irs-to::after,
.irs-info .irs-single::after {
    border-top-color: #17A2B8;
}

.irs-indigo .irs-bar,
.irs-indigo .irs-bar-edge,
.irs-indigo .irs-slider::before {
    background-color: #6610f2;
}

.irs-indigo .irs-from,
.irs-indigo .irs-to,
.irs-indigo .irs-single {
    background-color: #6610f2;
}

.irs-indigo .irs-from::after,
.irs-indigo .irs-to::after,
.irs-indigo .irs-single::after {
    border-top-color: #6610f2;
}

.irs-purple .irs-bar,
.irs-purple .irs-bar-edge,
.irs-purple .irs-slider::before {
    background-color: #6f42c1;
}

.irs-purple .irs-from,
.irs-purple .irs-to,
.irs-purple .irs-single {
    background-color: #6f42c1;
}

.irs-purple .irs-from::after,
.irs-purple .irs-to::after,
.irs-purple .irs-single::after {
    border-top-color: #6f42c1;
}

.irs-pink .irs-bar,
.irs-pink .irs-bar-edge,
.irs-pink .irs-slider::before {
    background-color: #e83e8c;
}

.irs-pink .irs-from,
.irs-pink .irs-to,
.irs-pink .irs-single {
    background-color: #e83e8c;
}

.irs-pink .irs-from::after,
.irs-pink .irs-to::after,
.irs-pink .irs-single::after {
    border-top-color: #e83e8c;
}

.irs-teal .irs-bar,
.irs-teal .irs-bar-edge,
.irs-teal .irs-slider::before {
    background-color: #1CAF9A;
}

.irs-teal .irs-from,
.irs-teal .irs-to,
.irs-teal .irs-single {
    background-color: #1CAF9A;
}

.irs-teal .irs-from::after,
.irs-teal .irs-to::after,
.irs-teal .irs-single::after {
    border-top-color: #1CAF9A;
}

.irs-orange .irs-bar,
.irs-orange .irs-bar-edge,
.irs-orange .irs-slider::before {
    background-color: #f27510;
}

.irs-orange .irs-from,
.irs-orange .irs-to,
.irs-orange .irs-single {
    background-color: #f27510;
}

.irs-orange .irs-from::after,
.irs-orange .irs-to::after,
.irs-orange .irs-single::after {
    border-top-color: #f27510;
}

.irs-dark .irs-bar,
.irs-dark .irs-bar-edge,
.irs-dark .irs-slider::before {
    background-color: #343a40;
}

.irs-dark .irs-from,
.irs-dark .irs-to,
.irs-dark .irs-single {
    background-color: #343a40;
}

.irs-dark .irs-from::after,
.irs-dark .irs-to::after,
.irs-dark .irs-single::after {
    border-top-color: #343a40;
}


/* #################### MODERN SKIN #################### */

.irs-modern .irs-line-left,
.irs-modern .irs-line-mid,
.irs-modern .irs-line-right {
    height: 6px;
    box-shadow: inset -2px 2px 2px 0 rgba(34, 34, 34, 0.1);
}

.irs-modern .irs-bar,
.irs-modern .irs-bar-edge {
    height: 6px;
    box-shadow: inset 0 1px 1px 0 rgba(34, 34, 34, 0.1);
}

.irs-modern .irs-slider {
    top: 19px;
    background-color: #fff;
    border: 1px solid #17A2B8;
    border-radius: 2px;
}

.irs-modern .irs-slider::before {
    width: 4px;
    height: 9px;
    background-color: #fff;
    border-left: 1px solid #17A2B8;
    border-right: 1px solid #17A2B8;
    margin-left: -2px;
    top: 3px;
}

.irs-modern.irs-primary .irs-slider {
    border-color: #0866C6;
}

.irs-modern.irs-primary .irs-slider::before {
    border-color: #0866C6;
}

.irs-modern.irs-success .irs-slider {
    border-color: #23BF08;
}

.irs-modern.irs-success .irs-slider::before {
    border-color: #23BF08;
}

.irs-modern.irs-warning .irs-slider {
    border-color: #F49917;
}

.irs-modern.irs-warning .irs-slider::before {
    border-color: #F49917;
}

.irs-modern.irs-danger .irs-slider {
    border-color: #DC3545;
}

.irs-modern.irs-danger .irs-slider::before {
    border-color: #DC3545;
}

.irs-modern.irs-info .irs-slider {
    border-color: #17A2B8;
}

.irs-modern.irs-info .irs-slider::before {
    border-color: #17A2B8;
}

.irs-modern.irs-teal .irs-slider {
    border-color: #1CAF9A;
}

.irs-modern.irs-teal .irs-slider::before {
    border-color: #1CAF9A;
}

.irs-modern.irs-indigo .irs-slider {
    border-color: #6610f2;
}

.irs-modern.irs-indigo .irs-slider::before {
    border-color: #6610f2;
}

.irs-modern.irs-purple .irs-slider {
    border-color: #6f42c1;
}

.irs-modern.irs-purple .irs-slider::before {
    border-color: #6f42c1;
}

.irs-modern.irs-pink .irs-slider {
    border-color: #e83e8c;
}

.irs-modern.irs-pink .irs-slider::before {
    border-color: #e83e8c;
}

.irs-modern.irs-orange .irs-slider {
    border-color: #f27510;
}

.irs-modern.irs-orange .irs-slider::before {
    border-color: #f27510;
}

.irs-modern.irs-dark .irs-slider {
    border-color: #343a40;
}

.irs-modern.irs-dark .irs-slider::before {
    border-color: #343a40;
}


/* #################### OUTLINE SKIN #################### */

.irs-outline .irs-line {
    border: 1px solid #17A2B8;
    height: 10px;
    border-radius: 5px;
}

.irs-outline .irs-line-left,
.irs-outline .irs-line-mid,
.irs-outline .irs-line-right {
    height: 10px;
    background-color: transparent;
}

.irs-outline .irs-bar,
.irs-outline .irs-bar-edge {
    height: 10px;
}

.irs-outline .irs-bar-edge {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.irs-outline .irs-slider {
    top: 21px;
    width: 18px;
    background-color: #fff;
    border: 1px solid #17A2B8;
    border-radius: 100%;
}

.irs-outline .irs-slider::before {
    margin-left: -4px;
    top: 4px;
    width: 8px;
    height: 8px;
    border-radius: 100%;
}

.irs-outline .irs-slider:hover::before,
.irs-outline .irs-slider:focus::before {
    background-color: #148ea1;
}

.irs-outline.irs-primary .irs-line,
.irs-outline.irs-primary .irs-slider {
    border-color: #0866C6;
}

.irs-outline.irs-primary .irs-line:hover::before,
.irs-outline.irs-primary .irs-line:focus::before,
.irs-outline.irs-primary .irs-slider:hover::before,
.irs-outline.irs-primary .irs-slider:focus::before {
    background-color: #0759ad;
}

.irs-outline.irs-success .irs-line,
.irs-outline.irs-success .irs-slider {
    border-color: #23BF08;
}

.irs-outline.irs-success .irs-line:hover::before,
.irs-outline.irs-success .irs-line:focus::before,
.irs-outline.irs-success .irs-slider:hover::before,
.irs-outline.irs-success .irs-slider:focus::before {
    background-color: #1fa707;
}

.irs-outline.irs-warning .irs-line,
.irs-outline.irs-warning .irs-slider {
    border-color: #F49917;
}

.irs-outline.irs-warning .irs-line:hover::before,
.irs-outline.irs-warning .irs-line:focus::before,
.irs-outline.irs-warning .irs-slider:hover::before,
.irs-outline.irs-warning .irs-slider:focus::before {
    background-color: #e78c0b;
}

.irs-outline.irs-danger .irs-line,
.irs-outline.irs-danger .irs-slider {
    border-color: #DC3545;
}

.irs-outline.irs-danger .irs-line:hover::before,
.irs-outline.irs-danger .irs-line:focus::before,
.irs-outline.irs-danger .irs-slider:hover::before,
.irs-outline.irs-danger .irs-slider:focus::before {
    background-color: #d32535;
}

.irs-outline.irs-info .irs-line,
.irs-outline.irs-info .irs-slider {
    border-color: #17A2B8;
}

.irs-outline.irs-info .irs-line:hover::before,
.irs-outline.irs-info .irs-line:focus::before,
.irs-outline.irs-info .irs-slider:hover::before,
.irs-outline.irs-info .irs-slider:focus::before {
    background-color: #148ea1;
}

.irs-outline.irs-teal .irs-line,
.irs-outline.irs-teal .irs-slider {
    border-color: #1CAF9A;
}

.irs-outline.irs-teal .irs-line:hover::before,
.irs-outline.irs-teal .irs-line:focus::before,
.irs-outline.irs-teal .irs-slider:hover::before,
.irs-outline.irs-teal .irs-slider:focus::before {
    background-color: #189987;
}

.irs-outline.irs-orange .irs-line,
.irs-outline.irs-orange .irs-slider {
    border-color: #f27510;
}

.irs-outline.irs-orange .irs-line:hover::before,
.irs-outline.irs-orange .irs-line:focus::before,
.irs-outline.irs-orange .irs-slider:hover::before,
.irs-outline.irs-orange .irs-slider:focus::before {
    background-color: #dd690c;
}

.irs-outline.irs-indigo .irs-line,
.irs-outline.irs-indigo .irs-slider {
    border-color: #6610f2;
}

.irs-outline.irs-indigo .irs-line:hover::before,
.irs-outline.irs-indigo .irs-line:focus::before,
.irs-outline.irs-indigo .irs-slider:hover::before,
.irs-outline.irs-indigo .irs-slider:focus::before {
    background-color: #5b0cdd;
}

.irs-outline.irs-purple .irs-line,
.irs-outline.irs-purple .irs-slider {
    border-color: #6f42c1;
}

.irs-outline.irs-purple .irs-line:hover::before,
.irs-outline.irs-purple .irs-line:focus::before,
.irs-outline.irs-purple .irs-slider:hover::before,
.irs-outline.irs-purple .irs-slider:focus::before {
    background-color: #643ab0;
}

.irs-outline.irs-pink .irs-line,
.irs-outline.irs-pink .irs-slider {
    border-color: #e83e8c;
}

.irs-outline.irs-pink .irs-line:hover::before,
.irs-outline.irs-pink .irs-line:focus::before,
.irs-outline.irs-pink .irs-slider:hover::before,
.irs-outline.irs-pink .irs-slider:focus::before {
    background-color: #e5277e;
}

.irs-outline.irs-dark .irs-line,
.irs-outline.irs-dark .irs-slider {
    border-color: #343a40;
}

.irs-outline.irs-dark .irs-line:hover::before,
.irs-outline.irs-dark .irs-line:focus::before,
.irs-outline.irs-dark .irs-slider:hover::before,
.irs-outline.irs-dark .irs-slider:focus::before {
    background-color: #292d32;
}


/* ------------------------------------------------ */


/* ############### 4.6 jQuery Steps ############### */


/* ------------------------------------------------ */


/***** DEFAULT STYLE WIZARD *****/

.wizard>.steps {
    margin-bottom: 10px;
}

.wizard>.steps>ul {
    padding: 0;
    margin-bottom: 0;
    display: flex;
}

.wizard>.steps>ul li {
    float: none;
    display: block;
    width: auto;
}

.wizard>.steps>ul li .current-info {
    display: none;
}

.wizard>.steps>ul li .title {
    margin-left: 5px;
    white-space: nowrap;
    transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .wizard>.steps>ul li .title {
        transition: none;
    }
}

@media (min-width: 576px) {
    .wizard>.steps>ul li .title {
        display: none;
        margin-left: 10px;
    }
}

@media (min-width: 768px) {
    .wizard>.steps>ul li .title {
        display: inline-block;
    }
}

.wizard>.steps>ul li+li {
    margin-left: 5px;
}

@media (min-width: 992px) {
    .wizard>.steps>ul li+li {
        margin-left: 10px;
    }
}

.wizard>.steps a,
.wizard>.steps a:hover,
.wizard>.steps a:active {
    font-weight: 500;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e9ecef;
    padding: 12px 15px;
    border-radius: 3px;
}

@media (min-width: 1200px) {

    .wizard>.steps a,
    .wizard>.steps a:hover,
    .wizard>.steps a:active {
        justify-content: flex-start;
    }
}

.wizard>.steps a .number,
.wizard>.steps a:hover .number,
.wizard>.steps a:active .number {
    flex-shrink: 0;
    font-weight: 700;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    display: block;
    text-align: center;
    line-height: 1.8;
    width: 30px;
    height: 30px;
    border: 2px solid #adb5bd;
    border-radius: 100%;
    transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {

    .wizard>.steps a .number,
    .wizard>.steps a:hover .number,
    .wizard>.steps a:active .number {
        transition: none;
    }
}

@media (min-width: 576px) {

    .wizard>.steps a .number,
    .wizard>.steps a:hover .number,
    .wizard>.steps a:active .number {
        font-size: 13px;
        font-weight: 700;
        line-height: 1.9;
        width: 30px;
        height: 30px;
    }
}

.wizard>.steps .disabled {
    display: none;
}

@media (min-width: 576px) {
    .wizard>.steps .disabled {
        display: block;
    }
}

.wizard>.steps .disabled a,
.wizard>.steps .disabled a:hover,
.wizard>.steps .disabled a:active {
    color: #adb5bd;
}

.wizard>.steps .current a,
.wizard>.steps .current a:hover,
.wizard>.steps .current a:active {
    background-color: #17A2B8;
    color: #fff;
}

.wizard>.steps .current a .title,
.wizard>.steps .current a:hover .title,
.wizard>.steps .current a:active .title {
    display: none;
}

@media (min-width: 576px) {

    .wizard>.steps .current a .title,
    .wizard>.steps .current a:hover .title,
    .wizard>.steps .current a:active .title {
        display: inline-block;
    }
}

.wizard>.steps .current a .number,
.wizard>.steps .current a:hover .number,
.wizard>.steps .current a:active .number {
    border-color: #fff;
}

.wizard>.steps .done a,
.wizard>.steps .done a:hover,
.wizard>.steps .done a:active {
    background-color: #1CAF9A;
    color: #fff;
}

.wizard>.steps .done a .title,
.wizard>.steps .done a:hover .title,
.wizard>.steps .done a:active .title {
    display: none;
}

@media (min-width: 768px) {

    .wizard>.steps .done a .title,
    .wizard>.steps .done a:hover .title,
    .wizard>.steps .done a:active .title {
        display: inline-block;
    }
}

.wizard>.steps .done a .number,
.wizard>.steps .done a:hover .number,
.wizard>.steps .done a:active .number {
    border-color: #fff;
}

.wizard>.content {
    background-color: #e9ecef;
    min-height: 150px;
    padding: 20px;
    border-radius: 3px;
}

@media (min-width: 768px) {
    .wizard>.content {
        padding: 25px;
    }
}

@media (min-width: 992px) {
    .wizard>.content {
        padding: 30px;
    }
}

.wizard>.content>.title {
    font-size: 18px;
    color: #212529;
    font-weight: 700;
    margin-bottom: 5px;
    display: none;
}

.wizard>.content>.title.current {
    display: block;
}

.wizard>.content>.body {
    float: none;
    position: static;
    width: auto;
    height: auto;
}

.wizard>.content>.body input.parsley-error {
    border-color: #DC3545;
}

.wizard>.content>.body input.parsley-error+ul {
    list-style: none !important;
}

.wizard>.actions {
    margin-top: 10px;
}

.wizard>.actions>ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
}

.wizard>.actions>ul>li:last-child a {
    background-color: #1CAF9A;
}

.wizard>.actions a,
.wizard>.actions a:hover,
.wizard>.actions a:active {
    display: block;
    background-color: #17A2B8;
    padding: 9px 25px;
    line-height: 1.573;
    color: #fff;
    border-radius: 3px;
}

.wizard>.actions .disabled a,
.wizard>.actions .disabled a:hover,
.wizard>.actions .disabled a:active {
    background-color: #adb5bd;
}

.wizard.vertical>.steps {
    padding-right: 10px;
}

@media (min-width: 576px) {
    .wizard.vertical>.steps {
        float: left;
        width: 20%;
    }
}

@media (min-width: 768px) {
    .wizard.vertical>.steps {
        width: 15%;
    }
}

@media (min-width: 992px) {
    .wizard.vertical>.steps {
        width: 30%;
    }
}

@media (min-width: 576px) {
    .wizard.vertical>.steps ul {
        flex-direction: column;
    }
}

.wizard.vertical>.steps ul li+li {
    margin-left: 10px;
}

@media (min-width: 576px) {
    .wizard.vertical>.steps ul li+li {
        margin-top: 10px;
        margin-left: 0;
    }
}

.wizard.vertical>.steps ul li .title {
    display: none;
}

@media (min-width: 992px) {
    .wizard.vertical>.steps ul li .title {
        display: block;
    }
}

@media (min-width: 992px) {
    .wizard.vertical>.steps a {
        justify-content: flex-start;
    }
}

.wizard.vertical>.steps .current a .title {
    display: inline-block;
}

@media (min-width: 576px) {
    .wizard.vertical>.steps .current a .title {
        display: none;
    }
}

@media (min-width: 992px) {
    .wizard.vertical>.steps .current a .title {
        display: inline-block;
    }
}

.wizard.vertical>.content {
    margin: 0;
    padding: 20px;
}

@media (min-width: 576px) {
    .wizard.vertical>.content {
        border-top-width: 0;
        border-bottom-width: 0;
        width: 80%;
        float: right;
    }
}

@media (min-width: 768px) {
    .wizard.vertical>.content {
        width: 85%;
    }
}

@media (min-width: 992px) {
    .wizard.vertical>.content {
        width: 70%;
        padding: 25px;
    }
}

@media (min-width: 576px) {
    .wizard.vertical>.actions {
        width: 80%;
        float: right;
    }
}

@media (min-width: 768px) {
    .wizard.vertical>.actions {
        width: 85%;
    }
}

@media (min-width: 992px) {
    .wizard.vertical>.actions {
        width: 70%;
    }
}

.wizard.vertical>.actions ul {
    float: none;
    margin: 0;
    padding: 0;
}


/****** EQUAL COLUMN WIDTH STEP INDICATOR *****/

.step-equal-width>.steps>ul {
    display: flex;
}

.step-equal-width>.steps>ul>li {
    flex: 1;
    width: auto;
    float: none;
}


/***** CUSTOM STYLES *****/

.wizard-style-1>.steps>ul a,
.wizard-style-1>.steps>ul a:hover,
.wizard-style-1>.steps>ul a:active {
    padding: 0;
    height: 50px;
}

.wizard-style-1>.steps>ul a .number,
.wizard-style-1>.steps>ul a:hover .number,
.wizard-style-1>.steps>ul a:active .number {
    width: 50px;
    height: 100%;
    border: 0;
    font-size: 18px;
    font-weight: bold;
    color: #6c757d;
    background-color: #dee2e6;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.wizard-style-1>.steps>ul a .title,
.wizard-style-1>.steps>ul a:hover .title,
.wizard-style-1>.steps>ul a:active .title {
    margin-right: 20px;
    margin-left: 20px;
}

.wizard-style-1>.steps>ul .current a .number,
.wizard-style-1>.steps>ul .current a:hover .number,
.wizard-style-1>.steps>ul .current a:active .number {
    background-color: #148ea1;
    color: #fff;
}

.wizard-style-1>.steps>ul .done a .number,
.wizard-style-1>.steps>ul .done a:hover .number,
.wizard-style-1>.steps>ul .done a:active .number {
    background-color: #189987;
    color: #fff;
}

.wizard-style-2>.steps>ul a,
.wizard-style-2>.steps>ul a:hover,
.wizard-style-2>.steps>ul a:active {
    padding: 0;
    height: 50px;
    border-radius: 50px;
}

.wizard-style-2>.steps>ul a .number,
.wizard-style-2>.steps>ul a:hover .number,
.wizard-style-2>.steps>ul a:active .number {
    width: 50px;
    height: 100%;
    border: 2px solid #e9ecef;
    font-size: 18px;
    font-weight: bold;
    color: #6c757d;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.wizard-style-2>.steps>ul a .title,
.wizard-style-2>.steps>ul a:hover .title,
.wizard-style-2>.steps>ul a:active .title {
    margin-right: 20px;
}

.wizard-style-2>.steps>ul .current a .number,
.wizard-style-2>.steps>ul .current a:hover .number,
.wizard-style-2>.steps>ul .current a:active .number {
    border-color: #17A2B8;
    color: #17A2B8;
}

.wizard-style-2>.steps>ul .done a .number,
.wizard-style-2>.steps>ul .done a:hover .number,
.wizard-style-2>.steps>ul .done a:active .number {
    border-color: #1CAF9A;
    color: #1CAF9A;
}

.wizard-style-3>.steps>ul a,
.wizard-style-3>.steps>ul a:hover,
.wizard-style-3>.steps>ul a:active {
    padding: 0;
    height: 50px;
    border-radius: 0;
    background-color: transparent;
}

.wizard-style-3>.steps>ul a .number,
.wizard-style-3>.steps>ul a:hover .number,
.wizard-style-3>.steps>ul a:active .number {
    width: 50px;
    height: 100%;
    border-width: 0;
    background-color: #e9ecef;
    font-weight: bold;
    font-size: 18px;
    color: #adb5bd;
    display: flex;
    align-items: center;
    justify-content: center;
}

.wizard-style-3>.steps>ul a .title,
.wizard-style-3>.steps>ul a:hover .title,
.wizard-style-3>.steps>ul a:active .title {
    margin-right: 20px;
}

.wizard-style-3>.steps>ul .current a,
.wizard-style-3>.steps>ul .current a:hover,
.wizard-style-3>.steps>ul .current a:active {
    color: #17A2B8;
    background-color: transparent;
}

.wizard-style-3>.steps>ul .current a .number,
.wizard-style-3>.steps>ul .current a:hover .number,
.wizard-style-3>.steps>ul .current a:active .number {
    background-color: #17A2B8;
    color: #fff;
}

.wizard-style-3>.steps>ul .done a,
.wizard-style-3>.steps>ul .done a:hover,
.wizard-style-3>.steps>ul .done a:active {
    background-color: transparent;
    color: #1CAF9A;
}

.wizard-style-3>.steps>ul .done a .number,
.wizard-style-3>.steps>ul .done a:hover .number,
.wizard-style-3>.steps>ul .done a:active .number {
    background-color: #1CAF9A;
    color: #fff;
}


/***** RTL SUPPORT *****/

.rtl .wizard>.steps>ul>li .title {
    margin-left: 0;
    margin-right: 5px;
}

@media (min-width: 576px) {
    .rtl .wizard>.steps>ul>li .title {
        margin-right: 10px;
    }
}

.rtl .wizard>.steps>ul>li+li {
    margin-left: 0;
    margin-right: 5px;
}

@media (min-width: 992px) {
    .rtl .wizard>.steps>ul>li+li {
        margin-right: 10px;
    }
}

.rtl .wizard.vertical>.steps>ul>li+li {
    margin-right: 0;
}

.rtl .wizard-style-1>.steps>ul a,
.rtl .wizard-style-2>.steps>ul a {
    padding-left: 15px;
}

.rtl .wizard-style-1>.steps>ul .number {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}


/* ------------------------------------------ */


/* ############### 4.8 JQVMap ############### */


/* ------------------------------------------ */

.jqvmap-label {
    padding: 2px 8px;
    background-color: rgba(17, 17, 17, 0.9);
    border-radius: 2px;
}

.jqvmap-zoomin,
.jqvmap-zoomout {
    font-size: 20px;
    padding: 0;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
}

.jqvmap-zoomout {
    top: 32px;
}


/* Setup basic CSS for Label */

.jqvmap-pin {
    font-family: "Fira Sans", "Helvetica Neue", Arial, sans-serif;
    cursor: default;
    pointer-events: none;
}


/* Hide Whichever Labels you want */

#jqvmap1_ri_pin,
#jqvmap1_dc_pin,
#jqvmap1_de_pin,
#jqvmap1_md_pin {
    display: none;
}


/* Reposition Labels that are not quite right ( labels are centered in shape, and sometimes need tweaking ) */

#jqvmap1_ak_pin {
    margin-top: -2%;
}

#jqvmap1_ca_pin {
    margin-left: -2%;
}

#jqvmap1_ct_pin {
    margin-top: -0.25%;
    margin-left: -0.25%;
}

#jqvmap1_fl_pin {
    margin-left: 5%;
}

#jqvmap1_id_pin {
    margin-top: 3%;
    margin-left: -1%;
}

#jqvmap1_ky_pin {
    margin-left: 2%;
}

#jqvmap1_la_pin {
    margin-left: -2%;
}

#jqvmap1_mi_pin {
    margin-top: 4%;
    margin-left: 3%;
}

#jqvmap1_ma_pin {
    margin-top: -0.25%;
}

#jqvmap1_mn_pin {
    margin-top: 2%;
    margin-left: -2%;
}

#jqvmap1_nh_pin {
    margin-top: 1%;
    margin-left: -0.25%;
}

#jqvmap1_nj_pin {
    margin-top: 1%;
}

#jqvmap1_ok_pin {
    margin-left: 2%;
}

#jqvmap1_va_pin {
    margin-left: 2%;
}

#jqvmap1_wv_pin {
    margin-left: -1%;
    margin-top: 1%;
}


/* Add responsibe support to resize labels for difference screen sizes */

@media only screen and (min-width: 320px) {
    .jqvmap-pin {
        font-size: 6px;
    }
}

@media only screen and (min-width: 480px) {
    .jqvmap-pin {
        font-size: 8px;
    }
}

@media only screen and (min-width: 640px) {
    .jqvmap-pin {
        font-size: 10px;
    }
}

@media only screen and (min-width: 800px) {
    .jqvmap-pin {
        font-size: 12px;
    }
}

@media only screen and (min-width: 1024px) {
    .jqvmap-pin {
        font-size: 14px;
    }
}


/* --------------------------------------------- */


/* ############### 4.7 jQuery UI ############### */


/* --------------------------------------------- */


/***** DATEPICKER *****/

.ui-datepicker {
    background-color: #fff;
    border: 1px solid #ced4da;
    font-family: inherit;
    font-size: inherit;
    padding: 10px;
    margin: 1px 0 0;
    border-radius: 3px;
    display: none;
    width: auto !important;
    z-index: 5 !important;
}

.ui-datepicker .ui-datepicker-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    color: #868ba1;
    padding: 0 0 5px;
    letter-spacing: 1px;
    border: 0;
    background-color: transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next,
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev {
    text-indent: -99999px;
    color: #6c757d;
    top: 1px;
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next::before,
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev::before {
    font-size: 12px;
    font-family: 'Ionicons';
    position: absolute;
    top: 0;
    text-indent: 0;
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next:hover::before,
.ui-datepicker .ui-datepicker-header .ui-datepicker-next:focus::before,
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev:hover::before,
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev:focus::before {
    color: #343a40;
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next {
    order: 3;
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next:before {
    right: 5px;
    content: '\f125';
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-prev:before {
    left: 5px;
    content: '\f124';
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next-hover,
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev-hover {
    color: #495057;
    cursor: pointer;
    top: 1px;
    border: 0;
    background-color: transparent;
}

.ui-datepicker .ui-datepicker-title {
    color: #17A2B8;
}

.ui-datepicker .ui-datepicker-calendar {
    margin: 0;
    background-color: transparent;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.ui-datepicker .ui-datepicker-calendar th {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 1px;
    padding: 6px 10px;
    color: #a3a7b7;
}

@media (max-width: 320px) {
    .ui-datepicker .ui-datepicker-calendar th {
        padding: 4px 0;
        letter-spacing: normal;
    }
}

.ui-datepicker .ui-datepicker-calendar td {
    border: 1px solid #fff;
    padding: 0;
    background-color: #fff;
    text-align: right;
}

.ui-datepicker .ui-datepicker-calendar td:last-child {
    border-right: 0;
}

.ui-datepicker .ui-datepicker-calendar td.ui-datepicker-other-month .ui-state-default {
    color: #ccc;
}

.ui-datepicker .ui-datepicker-calendar td span,
.ui-datepicker .ui-datepicker-calendar td a {
    transition: all 0.2s ease-in-out;
    padding: 5px;
    background-color: #f8f9fa;
    color: #868ba1;
    padding: 6px 10px;
    display: block;
    font-weight: 400;
    font-size: 12px;
    border: 0;
    border-radius: 1px;
}

@media screen and (prefers-reduced-motion: reduce) {

    .ui-datepicker .ui-datepicker-calendar td span,
    .ui-datepicker .ui-datepicker-calendar td a {
        transition: none;
    }
}

.ui-datepicker .ui-datepicker-calendar td a:hover {
    background-color: #E9ECEF;
    color: #868ba1;
}

.ui-datepicker .ui-datepicker-calendar .ui-datepicker-today a {
    background-color: #E9ECEF;
    color: #868ba1;
}

.ui-datepicker-multi .ui-datepicker-group {
    padding-right: 15px;
    width: auto;
    float: left;
}

.ui-datepicker-multi .ui-datepicker-group .ui-datepicker-title {
    margin: auto;
}

.ui-datepicker-multi .ui-datepicker-group .ui-datepicker-prev::before {
    left: 10px;
}

.ui-datepicker-multi .ui-datepicker-group .ui-datepicker-next::before {
    right: 10px;
}

.ui-datepicker-multi .ui-datepicker-group table {
    margin: 0;
}

.ui-datepicker-multi .ui-datepicker-group-last {
    padding-right: 0;
}

.ui-datepicker-inline {
    max-width: 270px;
    border-color: #ced4da;
}


/* #################### DATEPICKER SKINS #################### */

.fc-datepicker.bd-0 .ui-datepicker {
    border: 0;
}

.fc-datepicker-color .ui-datepicker {
    border: 0;
}

.fc-datepicker-color .ui-datepicker-title {
    color: #fff;
}

.fc-datepicker-color .ui-datepicker-header .ui-datepicker-next,
.fc-datepicker-color .ui-datepicker-header .ui-datepicker-prev {
    color: rgba(255, 255, 255, 0.5);
}

.fc-datepicker-color .ui-datepicker-header .ui-datepicker-next.ui-state-hover::before,
.fc-datepicker-color .ui-datepicker-header .ui-datepicker-prev.ui-state-hover::before {
    color: #fff;
}

.fc-datepicker-color .ui-datepicker-calendar th {
    color: rgba(255, 255, 255, 0.5);
    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
}

.fc-datepicker-color .ui-datepicker-calendar td {
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.2);
}

.fc-datepicker-color .ui-datepicker-calendar td:last-child {
    border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.fc-datepicker-color .ui-datepicker-calendar td.ui-datepicker-other-month .ui-state-default {
    color: rgba(255, 255, 255, 0.5);
}

.fc-datepicker-color .ui-datepicker-calendar td span,
.fc-datepicker-color .ui-datepicker-calendar td a {
    background-color: transparent;
    color: #fff;
}

.fc-datepicker-color .ui-datepicker-calendar td a:hover,
.fc-datepicker-color .ui-datepicker-calendar td a:focus {
    background-color: rgba(0, 0, 0, 0.1);
    color: #fff;
}

.fc-datepicker-color .ui-datepicker-calendar .ui-datepicker-today a {
    background-color: rgba(0, 0, 0, 0.15);
    color: #fff;
}

.fc-datepicker-primary .ui-datepicker {
    background-color: #0866C6;
}

.fc-datepicker-success .ui-datepicker {
    background-color: #23BF08;
}

.fc-datepicker-warning .ui-datepicker {
    background-color: #F49917;
}

.fc-datepicker-danger .ui-datepicker {
    background-color: #DC3545;
}

.fc-datepicker-info .ui-datepicker {
    background-color: #17A2B8;
}

.fc-datepicker-indigo .ui-datepicker {
    background-color: #6610f2;
}

.fc-datepicker-purple .ui-datepicker {
    background-color: #6f42c1;
}

.fc-datepicker-pink .ui-datepicker {
    background-color: #e83e8c;
}

.fc-datepicker-teal .ui-datepicker {
    background-color: #1CAF9A;
}

.fc-datepicker-orange .ui-datepicker {
    background-color: #f27510;
}

.fc-datepicker-dark .ui-datepicker {
    background-color: #343a40;
}


/* ------------------------------------------- */


/* ############### 4.9 Leaflet ############### */


/* ------------------------------------------- */

.leaflet-popup-content-wrapper {
    border-radius: 3px;
}


/* -------------------------------------------------- */


/* ############### 4.10 Medium Editor ############### */


/* -------------------------------------------------- */

.medium-editor-element p:last-child {
    margin-bottom: 0;
}

.medium-editor-toolbar {
    background-image: none;
    background-color: transparent;
    border: 0;
    box-shadow: none;
}

.medium-editor-toolbar li button {
    background-color: #1D2939;
    background-image: none;
    box-shadow: none;
    border-right: 0;
}

.medium-editor-toolbar li button:hover,
.medium-editor-toolbar li button:focus {
    background-color: #1a2432;
    color: #17A2B8;
}

.medium-editor-toolbar li .medium-editor-button-first {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-left: 0;
}

.medium-editor-toolbar li .medium-editor-button-last {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.medium-editor-toolbar li .medium-editor-button-active {
    background-image: none;
    background-color: #1a2432;
    color: #17A2B8;
}

.medium-toolbar-arrow-under::after {
    border-top-color: #1D2939;
}


/* ------------------------------------------- */


/* ############### 4.11 Morris ############### */


/* ------------------------------------------- */

.morris-hover.morris-default-style {
    border-radius: 3px;
    border-width: 1px;
}


/* -------------------------------------------- */


/* ############### 4.12 Parsley ############### */


/* -------------------------------------------- */

input.parsley-error:focus,
textarea.parsley-error:focus {
    box-shadow: none !important;
}

input.parsley-success:focus,
textarea.parsley-success:focus {
    box-shadow: none !important;
}

.parsley-checkbox.parsley-error {
    display: inline-block;
    padding: 10px;
    border: 1px solid #DC3545;
}

.parsley-checkbox.parsley-success {
    display: inline-block;
    padding: 10px;
    border: 1px solid #23BF08;
}

.parsley-errors-list {
    color: #DC3545;
    list-style: none;
    font-size: 12px;
    padding: 0;
    margin-bottom: 0;
}

.parsley-errors-list li {
    margin: 5px 0 0;
}


/***** CUSTOM STYLED ERROR MESSAGE *****/

.parsley-style-1 .parsley-input.parsley-error .form-control,
.parsley-style-1 .parsley-input.parsley-error .dataTables_filter input,
.dataTables_filter .parsley-style-1 .parsley-input.parsley-error input {
    background-color: #fef9f9;
    border-color: #DC3545;
    border-bottom-width: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.parsley-style-1 .parsley-input.parsley-success .form-control,
.parsley-style-1 .parsley-input.parsley-success .dataTables_filter input,
.dataTables_filter .parsley-style-1 .parsley-input.parsley-success input {
    border-color: #23BF08;
}

.parsley-style-1 .parsley-checkbox.parsley-error {
    border: 1px solid #DC3545;
    background-color: #fef9f9;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

.parsley-style-1 .parsley-select.parsley-error .select2-container--default .select2-selection--single {
    background-color: #fef9f9;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.parsley-style-1 .parsley-errors-list.filled {
    background-color: #DC3545;
    color: #fff;
    padding: 7px 10px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
}

.parsley-style-1 .parsley-errors-list.filled li:first-child {
    margin-top: 0;
}


/* ------------------------------------------------------ */


/* ############### 4.13 Perfect Scrollbar ############### */


/* ------------------------------------------------------ */

.ps {
    overflow: hidden;
}

.ps>.ps__rail-y {
    width: 5px;
    background-color: rgba(33, 37, 41, 0.04);
    z-index: 10;
    position: absolute;
    left: auto !important;
    right: 0;
    opacity: 0;
    transition: opacity 0.2s;
}

@media screen and (prefers-reduced-motion: reduce) {
    .ps>.ps__rail-y {
        transition: none;
    }
}

.ps>.ps__rail-y>.ps__thumb-y {
    position: absolute;
    border-radius: 0;
    width: 2px;
    left: 2px;
    background-color: #adb5bd;
}

.ps.ps--active-y:hover>.ps__rail-y,
.ps.ps--active-y:focus>.ps__rail-y {
    opacity: 1;
}


/***** RTL SUPPORT *****/

.rtl .ps>.ps__rail-y {
    left: 0 !important;
    right: auto !important;
}


/* --------------------------------------------- */


/* ############### 4.14 Rickshaw ############### */


/* --------------------------------------------- */

.rickshaw_graph svg:not(:root) {
    overflow: visible;
}

.rickshaw_graph svg {
    overflow-y: visible;
    overflow-x: hidden;
}

.rickshaw_graph svg .y_grid .tick text {
    display: none;
}

.rickshaw_graph .y_ticks text {
    font-size: 11px;
}


/* -------------------------------------------- */


/* ############### 4.15 Select2 ############### */


/* -------------------------------------------- */

.select2-results__option {
    border-radius: 2px;
    margin-bottom: 1px;
    font-size: 0.76563rem;
}

.select2-container--default .select2-selection--single {
    background-color: #fff;
    border-color: #ced4da;
    border-radius: 3px;
    height: calc(2.6125rem + 2px);
    outline: none;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #495057;
    line-height: calc(2.6125rem + 2px)-0.1rem;
    padding-left: 0.75rem;
    height: 100%;
    display: flex;
    align-items: center;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #6c757d;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    width: 30px;
    height: calc(2.6125rem + 2px);
    line-height: calc(2.6125rem + 2px);
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    margin-top: -3px;
}

.select2-container--default .select2-selection--multiple {
    background-color: #fff;
    border-color: #ced4da;
    border-radius: 3px;
    min-height: calc(2.6125rem + 2px);
    outline: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
    padding: 0 4px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    position: relative;
    margin-top: 5px;
    margin-right: 4px;
    padding: 3px 10px 3px 20px;
    border-color: transparent;
    border-radius: 2px;
    background-color: #0866C6;
    color: #fff;
    line-height: 1.45;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: #fff;
    opacity: .5;
    font-size: 12px;
    display: inline-block;
    position: absolute;
    top: 4px;
    left: 7px;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    border-color: #ced4da;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    border-color: #ced4da;
    border-radius: 3px;
}

.select2-container--default .select2-results__option[aria-selected="true"] {
    background-color: #f8f9fa;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #0866C6;
}

.select2-container--default .select2-results>.select2-results__options {
    margin: 4px;
}

.select2-container--default .select2-search--inline .select2-search__field {
    margin-top: 5px;
    line-height: 26px;
    padding-left: 7px;
}

.select2-container--default.select2-container--disabled .select2-selection__choice {
    padding-left: 10px;
    background-color: #adb5bd;
}

.select2-container--default.select2-container--disabled .select2-selection__choice .select2-selection__choice__remove {
    display: none;
}

.select2-container--open .select2-selection--single,
.select2-container--open .select2-selection--multiple {
    background-color: #fff;
    border-color: #ced4da;
}

.select2-container--open .select2-dropdown--above {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.select2-container--open .select2-dropdown--below {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    top: 0;
}

.select2-dropdown {
    border-color: #ced4da;
    z-index: 200;
}

.select2-search--dropdown {
    padding-bottom: 0;
}

.has-success .select2-container--default .select2-selection--single,
.parsley-select.parsley-success .select2-container--default .select2-selection--single {
    border-color: #23BF08;
}

.has-warning .select2-container--default .select2-selection--single {
    border-color: #F49917;
}

.has-danger .select2-container--default .select2-selection--single,
.parsley-select.parsley-error .select2-container--default .select2-selection--single {
    border-color: #DC3545;
}

.select2-xs+.select2-container {
    font-size: 12px;
}

.select2-dropdown-xs .select2-results__option {
    font-size: 12px;
}

.select2-sm+.select2-container {
    font-size: 14px;
}

.select2-dropdown-sm .select2-results__option {
    font-size: 14px;
}

.select2-bd-0+.select2-container--default .select2-selection--single {
    border-width: 0;
}

.bg-gray+.select2-container--default .select2-selection--single {
    background-color: #3f474e;
}

.bg-gray+.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #dee2e6;
}


/* #################### SELECT 2 SKINS #################### */

.select2-selection.select2-for-dark {
    border-color: rgba(52, 58, 64, 0.1);
}

.select2-selection.select2-outline-primary {
    border-color: #0866C6;
    background-color: transparent;
}

.select2-selection.select2-outline-primary .select2-selection__rendered {
    color: #0866C6;
}

.select2-selection.select2-outline-primary .select2-selection__arrow b {
    border-top-color: #0866C6;
}

.select2-selection.select2-outline-success {
    border-color: #23BF08;
    background-color: transparent;
}

.select2-selection.select2-outline-success .select2-selection__rendered {
    color: #23BF08;
}

.select2-selection.select2-outline-success .select2-selection__arrow b {
    border-top-color: #23BF08;
}

.select2-selection.select2-outline-warning {
    border-color: #F49917;
    background-color: transparent;
}

.select2-selection.select2-outline-warning .select2-selection__rendered {
    color: #F49917;
}

.select2-selection.select2-outline-warning .select2-selection__arrow b {
    border-top-color: #F49917;
}

.select2-selection.select2-outline-danger {
    border-color: #DC3545;
    background-color: transparent;
}

.select2-selection.select2-outline-danger .select2-selection__rendered {
    color: #DC3545;
}

.select2-selection.select2-outline-danger .select2-selection__arrow b {
    border-top-color: #DC3545;
}

.select2-selection.select2-outline-info {
    border-color: #17A2B8;
    background-color: transparent;
}

.select2-selection.select2-outline-info .select2-selection__rendered {
    color: #17A2B8;
}

.select2-selection.select2-outline-info .select2-selection__arrow b {
    border-top-color: #17A2B8;
}

.select2-selection.select2-outline-teal {
    border-color: #1CAF9A;
    background-color: transparent;
}

.select2-selection.select2-outline-teal .select2-selection__rendered {
    color: #1CAF9A;
}

.select2-selection.select2-outline-teal .select2-selection__arrow b {
    border-top-color: #1CAF9A;
}

.select2-selection.select2-outline-indigo {
    border-color: #6610f2;
    background-color: transparent;
}

.select2-selection.select2-outline-indigo .select2-selection__rendered {
    color: #6610f2;
}

.select2-selection.select2-outline-indigo .select2-selection__arrow b {
    border-top-color: #6610f2;
}

.select2-selection.select2-outline-purple {
    border-color: #6f42c1;
    background-color: transparent;
}

.select2-selection.select2-outline-purple .select2-selection__rendered {
    color: #6f42c1;
}

.select2-selection.select2-outline-purple .select2-selection__arrow b {
    border-top-color: #6f42c1;
}

.select2-selection.select2-outline-pink {
    border-color: #e83e8c;
    background-color: transparent;
}

.select2-selection.select2-outline-pink .select2-selection__rendered {
    color: #e83e8c;
}

.select2-selection.select2-outline-pink .select2-selection__arrow b {
    border-top-color: #e83e8c;
}

.select2-selection.select2-outline-orange {
    border-color: #f27510;
    background-color: transparent;
}

.select2-selection.select2-outline-orange .select2-selection__rendered {
    color: #f27510;
}

.select2-selection.select2-outline-orange .select2-selection__arrow b {
    border-top-color: #f27510;
}

.select2-selection.select2-outline-white {
    border-color: rgba(255, 255, 255, 0.5);
    background-color: transparent;
}

.select2-selection.select2-outline-white .select2-selection__rendered {
    color: rgba(255, 255, 255, 0.5);
}

.select2-selection.select2-outline-white .select2-selection__arrow b {
    border-top-color: rgba(255, 255, 255, 0.5);
}

.select2-selection.select2-full-color {
    border-color: transparent;
}

.select2-selection.select2-full-color .select2-selection__placeholder {
    color: rgba(255, 255, 255, 0.6);
}

.select2-selection.select2-full-color .select2-selection__rendered {
    color: #fff;
}

.select2-selection.select2-full-color .select2-selection__arrow b {
    border-top-color: rgba(255, 255, 255, 0.6);
}

.select2-selection.select2-primary {
    background-color: #0866C6;
}

.select2-selection.select2-success {
    background-color: #23BF08;
}

.select2-selection.select2-warning {
    background-color: #F49917;
}

.select2-selection.select2-danger {
    background-color: #DC3545;
}

.select2-selection.select2-info {
    background-color: #17A2B8;
}

.select2-selection.select2-indigo {
    background-color: #6610f2;
}

.select2-selection.select2-purple {
    background-color: #6f42c1;
}

.select2-selection.select2-pink {
    background-color: #e83e8c;
}

.select2-selection.select2-teal {
    background-color: #1CAF9A;
}

.select2-selection.select2-orange {
    background-color: #f27510;
}

.select2-selection.select2-dark {
    background-color: #343a40;
}

.select2-container--default.select2-container--open .select2-outline-primary .select2-selection__arrow b {
    border-bottom-color: #0866C6;
}

.select2-container--default.select2-container--open .select2-outline-success .select2-selection__arrow b {
    border-bottom-color: #23BF08;
}

.select2-container--default.select2-container--open .select2-outline-warning .select2-selection__arrow b {
    border-bottom-color: #F49917;
}

.select2-container--default.select2-container--open .select2-outline-danger .select2-selection__arrow b {
    border-bottom-color: #DC3545;
}

.select2-container--default.select2-container--open .select2-outline-info .select2-selection__arrow b {
    border-bottom-color: #17A2B8;
}

.select2-container--default.select2-container--open .select2-outline-indigo .select2-selection__arrow b {
    border-bottom-color: #6610f2;
}

.select2-container--default.select2-container--open .select2-outline-purple .select2-selection__arrow b {
    border-bottom-color: #6f42c1;
}

.select2-container--default.select2-container--open .select2-outline-pink .select2-selection__arrow b {
    border-bottom-color: #e83e8c;
}

.select2-container--default.select2-container--open .select2-outline-teal .select2-selection__arrow b {
    border-bottom-color: #1CAF9A;
}

.select2-container--default.select2-container--open .select2-outline-orange .select2-selection__arrow b {
    border-bottom-color: #f27510;
}

.select2-container--default.select2-container--open .select2-outline-white .select2-selection__arrow b {
    border-bottom-color: rgba(255, 255, 255, 0.5);
}

.select2-container--default.select2-container--open .select2-full-color .select2-selection__arrow b {
    border-bottom-color: rgba(255, 255, 255, 0.6);
}

.hover-success .select2-results__option--highlighted[aria-selected] {
    background-color: #23BF08;
}

.hover-warning .select2-results__option--highlighted[aria-selected] {
    background-color: #F49917;
}

.hover-danger .select2-results__option--highlighted[aria-selected] {
    background-color: #DC3545;
}

.hover-info .select2-results__option--highlighted[aria-selected] {
    background-color: #17A2B8;
}

.hover-indigo .select2-results__option--highlighted[aria-selected] {
    background-color: #6610f2;
}

.hover-purple .select2-results__option--highlighted[aria-selected] {
    background-color: #6f42c1;
}

.hover-orange .select2-results__option--highlighted[aria-selected] {
    background-color: #f27510;
}

.hover-pink .select2-results__option--highlighted[aria-selected] {
    background-color: #e83e8c;
}

.hover-teal .select2-results__option--highlighted[aria-selected] {
    background-color: #1CAF9A;
}

.hover-dark .select2-results__option--highlighted[aria-selected] {
    background-color: #343a40;
}

.select2-drop-color {
    border-color: transparent;
}

.select2-drop-color .select2-results__option {
    color: rgba(255, 255, 255, 0.6);
}

.select2-drop-color .select2-results__option--highlighted[aria-selected] {
    background-color: rgba(0, 0, 0, 0.2);
}

.select2-drop-color .select2-results__option[aria-selected="true"] {
    background-color: rgba(0, 0, 0, 0.08);
}

.select2-drop-primary {
    background-color: #0866C6;
}

.select2-drop-success {
    background-color: #23BF08;
}

.select2-drop-warning {
    background-color: #F49917;
}

.select2-drop-danger {
    background-color: #DC3545;
}

.select2-drop-info {
    background-color: #17A2B8;
}

.select2-drop-indigo {
    background-color: #6610f2;
}

.select2-drop-purple {
    background-color: #6f42c1;
}

.select2-drop-pink {
    background-color: #e83e8c;
}

.select2-drop-teal {
    background-color: #1CAF9A;
}

.select2-drop-orange {
    background-color: #f27510;
}

.select2-drop-dark {
    background-color: #343a40;
}


/* ---------------------------------------------- */


/* ############### 4.16 Sparkline ############### */


/* ---------------------------------------------- */

.jqstooltip {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    border: 0 !important;
    border-radius: 2px;
}


/* --------------------------------------------- */


/* ############### 4.17 Spectrum ############### */


/* --------------------------------------------- */

.sp-container {
    background-color: #fff;
    border-color: #adb5bd;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21);
    z-index: 200;
}

.sp-container button {
    border: 0;
    padding: 8px 15px;
    background-image: none;
    background-color: #ced4da;
    font-family: "Fira Sans", "Helvetica Neue", Arial, sans-serif;
    font-size: 12px;
    text-shadow: none;
    text-transform: capitalize;
    border-radius: 2px;
}

.sp-container button:hover,
.sp-container button:focus {
    border: 0;
    background-image: none;
    background-color: #adb5bd;
    text-shadow: none;
}

.sp-button-container .sp-cancel {
    font-family: "Fira Sans", "Helvetica Neue", Arial, sans-serif;
    font-size: 12px;
    text-transform: capitalize;
    color: #6c757d !important;
}

.sp-button-container .sp-cancel:hover,
.sp-button-container .sp-cancel:focus {
    color: #343a40 !important;
    text-decoration: none;
}

.sp-picker-container {
    border-left: 0;
}

.sp-replacer {
    border-color: #adb5bd;
    background-color: #fff;
}

.sp-replacer:hover,
.sp-replacer:focus {
    border-color: #6c757d;
}

.sp-replacer.sp-active {
    border-color: #6c757d;
}

.sp-dd {
    text-indent: -99999px;
    position: relative;
    width: 10px;
}

.sp-dd::before {
    content: '\f35f';
    font-family: 'Ionicons';
    font-size: 14px;
    position: absolute;
    text-indent: 0;
    left: 0;
    z-index: 10;
}

.sp-preview {
    border: 0;
}

.sp-dragger {
    background-color: transparent;
}

.sp-choose {
    background-color: #dee2e6;
}

.sp-palette .sp-thumb-el:hover,
.sp-palette .sp-thumb-el.sp-thumb-active {
    border-color: #343a40;
}


/* ----------------------------------------------- */


/* ############### 4.18 Summernote ############### */


/* ----------------------------------------------- */

.note-editor.note-frame {
    border: 1px solid #ced4da;
}

.note-editor.note-frame .note-editing-area .note-editable {
    padding: 10px;
}

.note-editor.note-frame .note-statusbar {
    border-top: 1px solid #ced4da;
}

.note-toolbar {
    position: relative;
}

.card-header.note-toolbar {
    padding: 10px;
    background-color: #f8f9fa;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.card-header.note-toolbar>.btn-group {
    margin: 0 10px 10px 0;
}

.card-header.note-toolbar>.btn-group.note-insert,
.card-header.note-toolbar>.btn-group.note-view {
    margin-bottom: 0;
}

@media (min-width: 480px) {

    .card-header.note-toolbar>.btn-group.note-para,
    .card-header.note-toolbar>.btn-group.note-table {
        margin-bottom: 0;
    }
}

@media (min-width: 768px) {

    .card-header.note-toolbar>.btn-group.note-para,
    .card-header.note-toolbar>.btn-group.note-table,
    .card-header.note-toolbar>.btn-group.note-insert {
        margin-bottom: 10px;
    }
}

@media (min-width: 992px) {
    .card-header.note-toolbar>.btn-group.note-insert {
        margin-bottom: 0;
    }
}

@media (min-width: 1200px) {

    .card-header.note-toolbar>.btn-group,
    .card-header.note-toolbar>.btn-group.note-para,
    .card-header.note-toolbar>.btn-group.note-table {
        margin-bottom: 0;
    }
}

.card-header.note-toolbar>.btn-group .btn,
.card-header.note-toolbar>.btn-group .sp-container button,
.sp-container .card-header.note-toolbar>.btn-group button {
    background-color: #fff;
    border: 1px solid #ced4da;
    padding: 8px 10px;
    color: #495057;
    font-size: 12px;
    border-radius: 2px;
}

.card-header.note-toolbar>.btn-group .btn:hover,
.card-header.note-toolbar>.btn-group .sp-container button:hover,
.sp-container .card-header.note-toolbar>.btn-group button:hover,
.card-header.note-toolbar>.btn-group .btn:focus,
.card-header.note-toolbar>.btn-group .sp-container button:focus,
.sp-container .card-header.note-toolbar>.btn-group button:focus,
.card-header.note-toolbar>.btn-group .btn:active,
.card-header.note-toolbar>.btn-group .sp-container button:active,
.sp-container .card-header.note-toolbar>.btn-group button:active,
.card-header.note-toolbar>.btn-group .btn.active,
.card-header.note-toolbar>.btn-group .sp-container button.active,
.sp-container .card-header.note-toolbar>.btn-group button.active {
    border-color: #adb5bd;
    background-color: #e9ecef;
}

.card-header.note-toolbar>.btn-group .dropdown-item {
    padding: 7px 15px;
}

.card-header.note-toolbar>.btn-group .dropdown-item:hover,
.card-header.note-toolbar>.btn-group .dropdown-item:focus {
    background-color: #e9ecef;
}

.card-header.note-toolbar>.btn-group .dropdown-item p,
.card-header.note-toolbar>.btn-group .dropdown-item blockquote,
.card-header.note-toolbar>.btn-group .dropdown-item pre {
    margin-bottom: 0;
    font-family: "Fira Sans", "Helvetica Neue", Arial, sans-serif;
    font-size: 13px;
}

.card-header.note-toolbar>.btn-group .dropdown-item h1,
.card-header.note-toolbar>.btn-group .dropdown-item h2,
.card-header.note-toolbar>.btn-group .dropdown-item h3,
.card-header.note-toolbar>.btn-group .dropdown-item h4,
.card-header.note-toolbar>.btn-group .dropdown-item h5,
.card-header.note-toolbar>.btn-group .dropdown-item h6 {
    font-size: 13px;
    font-weight: 400;
}

.card-header.note-toolbar>.btn-group .dropdown-toggle::after {
    margin-left: 8px;
}

.card-header.note-toolbar>.btn-group.note-color .dropdown-toggle::after {
    margin-left: -3px;
}

.card-header.note-toolbar>.btn-group .dropdown-fontname .dropdown-item {
    padding-left: 5px;
}

.card-header.note-toolbar>.btn-group .dropdown-fontname .dropdown-item span {
    font-family: "Fira Sans", "Helvetica Neue", Arial, sans-serif !important;
    font-size: 12px;
}


/* ---------------------------------------------- */


/* ############### 4.19 TagsInput ############### */


/* ---------------------------------------------- */

.bootstrap-tagsinput {
    border-color: #ced4da;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0 6px 5px;
    min-height: 40px;
    display: block;
    border-radius: 3px;
}

.bootstrap-tagsinput .label {
    background-color: #17A2B8;
    color: #fff;
    font-size: 0.76563rem;
    padding: 2px 8px;
    margin-top: 6px;
    display: inline-block;
    border-radius: 2px;
}

.bootstrap-tagsinput input {
    padding-top: 8px;
}

.bootstrap-tagsinput .tag [data-role="remove"] {
    opacity: .75;
}

.bootstrap-tagsinput .tag [data-role="remove"]::after {
    content: '\f2d7';
    font-family: 'Ionicons';
    font-size: 11px;
}

.bootstrap-tagsinput .tag [data-role="remove"]:hover,
.bootstrap-tagsinput .tag [data-role="remove"]:focus,
.bootstrap-tagsinput .tag [data-role="remove"]:active {
    opacity: 1;
    box-shadow: none;
}


/* -------------------------------------------- */


/* ############### 4.20 Toggles ############### */


/* -------------------------------------------- */

.br-toggle {
    width: 56px;
    height: 25px;
    background-color: rgba(0, 0, 0, 0.3);
    display: inline-block;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    border-radius: 3px;
    transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .br-toggle {
        transition: none;
    }
}

.br-toggle.on {
    background-color: #141d28;
}

.br-toggle.on .br-toggle-switch {
    left: 35px;
}

.br-toggle+.br-toggle {
    margin-left: 5px;
}

.br-toggle-rounded {
    border-radius: 25px;
}

.br-toggle-rounded .br-toggle-switch {
    border-radius: 100%;
}

.br-toggle-rounded .br-toggle-switch::before {
    left: -23px;
}

.br-toggle-rounded .br-toggle-switch::after {
    right: -27px;
}

.br-toggle-switch {
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 3px;
    width: 18px;
    background-color: #fff;
    border-radius: 2px;
    transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .br-toggle-switch {
        transition: none;
    }
}

.br-toggle-switch::before,
.br-toggle-switch::after {
    position: absolute;
    top: 2px;
    color: #fff;
    text-transform: uppercase;
    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 1px;
    text-shadow: 1px 1px rgba(33, 37, 41, 0.1);
}

.br-toggle-switch::before {
    content: 'on';
    left: -25px;
}

.br-toggle-switch::after {
    content: 'off';
    right: -28px;
}

.br-toggle-primary.on {
    background-color: #0866C6;
}

.br-toggle-success.on {
    background-color: #23BF08;
}

.br-toggle-warning.on {
    background-color: #F49917;
}

.br-toggle-danger.on {
    background-color: #DC3545;
}

.br-toggle-info.on {
    background-color: #17A2B8;
}

.br-toggle.disabled {
    opacity: 0.5;
    pointer-events: none;
}


/* ----------------------------------------------- */


/* ############### 4.21 DataTable ############### */


/* ----------------------------------------------- */

table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child::before {
    border-width: 0;
    box-shadow: none;
    top: 15px;
    left: 8px;
    background-color: #ced4da;
}


/* ----------------------------------------------------------- */


/* ############### 5. BRACKET PLUS CORE STYLES ############### */


/* ----------------------------------------------------------- */


/*
 * Bracket Plus v1.0.0 (https://themepixels.me/bracketplus)
 * Copyright 2017-2018 ThemePixels
 * Licensed under ThemeForest License
 */

.br-header {
    height: 60px;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    background-color: #fff;
    box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.16);
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .br-header {
        transition: none;
    }
}

.br-header::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -4px;
    width: 3px;
    background-color: #fff;
    z-index: 1040;
}

@media (min-width: 992px) {
    .br-header {
        left: 230px;
    }
}

.br-header-left {
    display: flex;
}

.br-header-left .form-control,
.br-header-left .dataTables_filter input,
.dataTables_filter .br-header-left input {
    border: 0;
    border-radius: 0;
    padding: 0 20px;
}

.br-header-left .form-control:focus,
.br-header-left .dataTables_filter input:focus,
.dataTables_filter .br-header-left input:focus {
    box-shadow: none;
}

.br-header-left .input-group {
    border-right: 1px solid #ced4da;
    align-items: center;
}

.br-header-left .input-group-btn .btn,
.br-header-left .input-group-btn .sp-container button,
.sp-container .br-header-left .input-group-btn button {
    background-color: #fff;
    border-radius: 0;
    border: 0;
    color: #6c757d;
    padding-left: 20px;
    padding-right: 20px;
    height: 100%;
    line-height: 1;
}

.br-header-left .input-group-btn .btn:focus,
.br-header-left .input-group-btn .sp-container button:focus,
.sp-container .br-header-left .input-group-btn button:focus,
.br-header-left .input-group-btn .btn:active,
.br-header-left .input-group-btn .sp-container button:active,
.sp-container .br-header-left .input-group-btn button:active {
    color: #343a40;
    box-shadow: none !important;
    background-color: #fff;
}

.br-header-right {
    display: flex;
    align-items: center;
}

.br-header-right a {
    color: #6c757d;
}

.br-header-right a:hover,
.br-header-right a:focus {
    color: #343a40;
}

@media (max-width: 479px) {
    .br-header-right .dropdown:first-child .dropdown-menu {
        transform: translateX(145px) !important;
    }

    .br-header-right .dropdown:nth-child(2) .dropdown-menu {
        transform: translateX(113px) !important;
    }

    .br-header-right .dropdown:last-child .dropdown-menu {
        transform: translateX(49px) !important;
    }
}

.br-header-right .dropdown .dropdown-menu {
    position: relative;
    border-top: 0;
    margin-top: 1px;
    box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.16);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    left: auto !important;
    right: -1px !important;
    top: 55px !important;
    transform: none !important;
    will-change: unset !important;
}

.br-header-right .dropdown .dropdown-menu::before {
    content: '';
    position: absolute;
    top: -4px;
    left: -2px;
    right: 0;
    height: 4px;
    background-color: #fff;
    z-index: 1040;
}

.br-header-right .dropdown:last-child .dropdown-menu {
    padding: 10px 20px 25px;
    width: 250px;
}

.br-header-right .dropdown-footer {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    border-top: 1px solid #ced4da;
}

.br-header-right .dropdown-footer a {
    font-size: 12px;
}

.br-header-right .dropdown-footer a i {
    margin-right: 5px;
}

.nav-link-profile {
    position: relative;
    color: #6c757d;
    transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .nav-link-profile {
        transition: none;
    }
}

.nav-link-profile img {
    opacity: .85;
    transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .nav-link-profile img {
        transition: none;
    }
}

.nav-link-profile .square-10 {
    right: 15px;
    bottom: 10px;
    position: absolute;
    border-radius: 100%;
    border: 2px solid #fff;
}

.nav-link-profile:hover,
.nav-link-profile:focus {
    color: #495057;
}

.nav-link-profile:hover img,
.nav-link-profile:focus img {
    opacity: 1;
}

.logged-name {
    display: inline-block;
    font-size: 13px;
    margin-right: 5px;
}

.logged-fullname {
    margin-top: 15px;
    margin-bottom: 5px;
    color: #343a40;
}

.logged-fullname+p {
    font-size: 12px;
    color: #6c757d;
}

.profile-earning-label {
    display: block;
    margin-bottom: 5px;
    font-size: 11px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #17A2B8;
    font-weight: 500;
}

.profile-earning-amount {
    margin-bottom: 0;
    color: #343a40;
    font-family: "Lato", "Helvetica Neue", Arial, sans-serif;
    font-weight: 700;
}

.profile-earning-text {
    font-size: 11px;
    color: #adb5bd;
}

.dropdown-menu-header {
    padding: 0;
    width: 300px;
}

.dropdown-menu-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: 1px solid #e9ecef;
}

.dropdown-menu-label label {
    font-size: 12px;
    color: #17A2B8;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 0;
}

.dropdown-menu-label a {
    font-size: 11px;
}

.user-profile-nav {
    margin: 0;
    font-size: 13px;
}

.user-profile-nav a {
    display: block;
    padding: 7px 10px;
    border-radius: 2px;
    transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .user-profile-nav a {
        transition: none;
    }
}

.user-profile-nav a:hover,
.user-profile-nav a:focus {
    background-color: #dee2e6;
}

.user-profile-nav .icon {
    display: inline-block;
    width: 20px;
    line-height: 0;
    text-align: center;
    font-size: 20px;
    position: relative;
    color: #17A2B8;
    top: 2px;
    margin-right: 5px;
}

.user-profile-nav .ion-ios-folder,
.user-profile-nav .ion-power {
    font-size: 18px;
}

.media-list-link {
    display: block;
    transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .media-list-link {
        transition: none;
    }
}

.media-list-link.read {
    background-color: #f8f9fa;
}

.media-list-link:hover,
.media-list-link:focus {
    background-color: #e9ecef;
}

.media-list-link .media {
    padding: 15px 20px;
}

.media-list-link .media img {
    width: 40px;
    border-radius: 100%;
}

.media-list-link .media-body {
    margin-left: 15px;
}

.media-list-link .media-body p {
    font-size: 12px;
    margin-bottom: 0;
}

.media-list-link .media-body>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}

.media-list-link .media-body>div p {
    margin-bottom: 0;
    font-weight: 500;
    color: #343a40;
    font-size: 14px;
}

.media-list-link .media-body>div span {
    font-size: 11px;
    color: #adb5bd;
}

.media-list-link .noti-text {
    color: #495057;
}

.media-list-link .noti-text strong {
    color: #343a40;
    font-weight: 500;
}

.media-list-link .noti-text+span {
    font-size: 12px;
}

.media-list-link+.media-list-link {
    margin-top: 1px;
}

.navicon-left {
    width: 60px;
    height: 60px;
    border-right: 1px solid #ced4da;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navicon-left a {
    color: #6c757d;
    font-size: 20px;
    transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .navicon-left a {
        transition: none;
    }
}

.navicon-left a:hover,
.navicon-left a:focus {
    color: #343a40;
}

.navicon-right {
    display: flex;
    width: 60px;
    height: 60px;
    border-left: 1px solid #ced4da;
    align-items: center;
    justify-content: center;
}

.navicon-right a {
    color: #6c757d;
    font-size: 24px;
}

.navicon-right a:hover,
.navicon-right a:focus {
    color: #343a40;
}

.br-sideleft {
    position: fixed;
    top: 60px;
    left: -230px;
    bottom: 0;
    z-index: 100;
    width: 230px;
    background-color: #1D2939;
    padding-left: 10px;
    padding-right: 10px;
    transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .br-sideleft {
        transition: none;
    }
}

@media (min-width: 992px) {
    .br-sideleft {
        left: 0;
    }
}

.br-logo {
    position: fixed;
    top: 0;
    left: -230px;
    width: 230px;
    height: 60px;
    background-color: #fff;
    font-size: 24px;
    font-weight: 700;
    padding: 0 15px;
    display: flex;
    align-items: center;
    z-index: 1030;
    transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .br-logo {
        transition: none;
    }
}

@media (min-width: 992px) {
    .br-logo {
        left: 0;
        border-right: 1px solid #ced4da;
    }
}

.br-logo>a {
    color: #212529;
    letter-spacing: -0.5px;
}

.br-logo>a i {
    font-style: normal;
    font-weight: 500;
    color: #17A2B8;
}

.br-logo>a span {
    font-weight: 300;
    color: #1CAF9A;
    display: inline-block;
    transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .br-logo>a span {
        transition: none;
    }
}

.br-logo>a span:first-child {
    padding-right: 2px;
}

.br-logo>a span:last-child {
    padding-left: 2px;
}

.br-logo>a:hover,
.br-logo>a:focus {
    text-decoration: none;
}

.br-logo>a:hover span:first-child,
.br-logo>a:focus span:first-child {
    transform: translateX(-5px);
}

.br-logo>a:hover span:last-child,
.br-logo>a:focus span:last-child {
    transform: translateX(5px);
}

.sidebar-label {
    display: block;
    text-transform: uppercase;
    font-size: 10px;
    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
    color: #fff;
    letter-spacing: 1px;
    white-space: nowrap;
}

.br-sideleft-menu {
    padding: 0;
    margin: 0;
    transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .br-sideleft-menu {
        transition: none;
    }
}

.br-menu-item {
    display: block;
    position: relative;
}

.br-menu-link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 11px;
    height: 50px;
    letter-spacing: 0.2px;
    color: #adb5bd;
    font-size: 14px;
    position: relative;
    border-radius: 2px;
    transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .br-menu-link {
        transition: none;
    }
}

.br-menu-link.with-sub::after {
    content: '\f123';
    font-family: 'Ionicons';
    font-weight: 400;
    position: absolute;
    font-size: 9px;
    top: calc(50% - 6px);
    right: 10px;
    color: #6c757d;
    transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .br-menu-link.with-sub::after {
        transition: none;
    }
}

.br-menu-link:hover,
.br-menu-link:focus {
    color: #fff;
    background-color: #18222f;
}

.br-menu-link:focus,
.br-menu-link:active {
    outline: none;
}

.br-menu-link.active {
    background-image: linear-gradient(to right, #1CAF9A 0%, #17A2B8 100%);
    background-repeat: repeat-x;
    color: #fff;
}

.br-menu-link.active::after {
    color: #fff;
}

.br-menu-link.active+.br-menu-sub {
    display: block;
}

.br-menu-link.show-sub {
    background-color: #141d28;
    color: #fff;
}

.menu-item-icon {
    line-height: 0;
    width: 18px;
    transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .menu-item-icon {
        transition: none;
    }
}

.menu-item-label {
    display: block;
    white-space: nowrap;
    margin-left: 8px;
    margin-right: auto;
}

.menu-item-label,
.menu-item-arrow {
    transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {

    .menu-item-label,
    .menu-item-arrow {
        transition: none;
    }
}

.br-menu-sub {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 21px;
    background-color: #1a2432;
    display: none;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.br-menu-sub .sub-item {
    display: block;
    position: relative;
}

.br-menu-sub .sub-link {
    display: block;
    font-size: 13px;
    color: #adb5bd;
    padding: 7px 0 7px 17px;
    white-space: nowrap;
    position: relative;
    transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .br-menu-sub .sub-link {
        transition: none;
    }
}

.br-menu-sub .sub-link::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -3px;
    margin-top: -.5px;
    width: 5px;
    height: 1px;
    background-color: #fff;
    opacity: .5;
    transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .br-menu-sub .sub-link::before {
        transition: none;
    }
}

.br-menu-sub .sub-link:hover,
.br-menu-sub .sub-link:focus {
    color: #17A2B8;
}

.br-menu-sub .sub-link:hover::before,
.br-menu-sub .sub-link:focus::before {
    width: 20px;
    left: -11px;
    opacity: 1;
    background-color: #17A2B8;
}

.br-menu-sub .sub-link.active {
    color: #17A2B8;
}

.br-menu-sub .sub-link.active::before {
    width: 20px;
    left: -11px;
    opacity: 1;
    background-color: #17A2B8;
}

.info-list .info-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
}

.info-list .info-list-item+.info-list-item {
    margin-top: 20px;
}

.info-list .info-list-label {
    font-size: 10px;
    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    color: rgba(255, 255, 255, 0.3);
    margin-bottom: 2px;
    white-space: nowrap;
}

.info-list .info-list-amount {
    font-family: "Lato", "Helvetica Neue", Arial, sans-serif;
    color: #fff;
    font-weight: 400;
    margin-bottom: 0;
}

@media (min-width: 992px) {
    .collapsed-menu .br-logo {
        left: -230px;
    }

    .collapsed-menu .br-header {
        left: 0;
    }

    .collapsed-menu .br-sideleft {
        width: 60px;
    }

    .collapsed-menu .br-mainpanel {
        margin-left: 60px;
    }

    .collapsed-menu .br-menu-link.with-sub::after {
        opacity: 0;
    }

    .collapsed-menu .br-menu-link.active+.br-menu-sub {
        display: none;
    }

    .collapsed-menu .br-sideleft .sidebar-label {
        visibility: hidden;
    }

    .collapsed-menu .info-list {
        display: none;
    }

    .collapsed-menu .br-contentpanel {
        margin-left: 290px;
    }
}

@media (min-width: 992px) {
    .expand-menu .br-sideleft .sidebar-label {
        visibility: visible;
    }

    .expand-menu .br-sideleft {
        width: 230px;
    }

    .expand-menu .br-menu-link.with-sub::after {
        opacity: 1;
    }

    .expand-menu .info-list {
        display: block;
    }
}

.br-sideright {
    position: fixed;
    top: 0;
    right: -280px;
    bottom: 0;
    z-index: 2000;
    width: 280px;
    background-color: #1D2939;
    transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .br-sideright {
        transition: none;
    }
}

.br-sideright .tab-pane .ps__scrollbar-y-rail {
    visibility: hidden;
}

@media (max-width: 991.98px) {

    .show-left .br-header,
    .show-left .br-mainpanel {
        transform: translateX(230px);
    }

    .show-left .br-logo,
    .show-left .br-sideleft {
        left: 0;
        z-index: 2000;
    }

    .show-left .menu-item-label {
        display: block !important;
        opacity: 1 !important;
    }

    .show-left .br-contentpanel {
        transform: translateX(230px);
    }
}

.show-right .br-logo,
.show-right .br-sideleft,
.show-right .br-header,
.show-right .br-mainpanel,
.show-right .br-subleft,
.show-right .br-contentpanel {
    transform: translateX(-280px);
}

.show-right .br-sideright {
    right: 0;
}

.show-left,
.show-right {
    overflow: hidden;
}

.show-left::before,
.show-right::before {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2000;
    background-color: rgba(29, 41, 57, 0.25);
}

@media (min-width: 992px) {
    .show-left::before {
        display: none;
    }
}

.show-left.show-right::before {
    display: block;
}

.sidebar-tabs {
    height: 60px;
    background-color: #18222f;
    border-bottom: 0;
}

.sidebar-tabs .nav-item {
    margin-bottom: 0;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.sidebar-tabs .nav-link {
    color: #6c757d;
    border: 0;
    padding: 13px 24px;
    border-radius: 0;
    width: 100%;
}

.sidebar-tabs .nav-link.active {
    background-color: #1D2939;
    color: #1CAF9A;
}

.contact-list-link {
    font-size: 13px;
    color: rgba(255, 255, 255, 0.6);
    display: block;
    border-radius: 2px;
    transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .contact-list-link {
        transition: none;
    }
}

.contact-list-link img {
    width: 40px;
    border-radius: 100%;
}

.contact-list-link.new {
    background-color: #1a2534;
}

.contact-list-link .d-flex {
    align-items: center;
    justify-content: flex-start;
    padding: 10px 15px;
}

.contact-list-link .contact-person {
    margin-left: 10px;
    margin-right: auto;
}

.contact-list-link .contact-person p {
    margin-bottom: 0;
}

.contact-list-link .contact-person span {
    font-size: 12px;
    opacity: .5;
    display: inline-block;
}

.contact-list-link:hover,
.contact-list-link:focus {
    color: #fff;
    background-color: #18222f;
}

.contact-list-link+.contact-list-link {
    margin-top: 1px;
}

.contact-status-indicator {
    width: 10px;
    height: 10px;
    position: absolute;
    bottom: 0;
    right: 0;
    border: 2px solid #1D2939;
    border-radius: 50px;
}

.media-file-list {
    padding: 0 25px 20px;
    margin-top: 20px;
}

.media-file-list .media-body {
    color: rgba(255, 255, 255, 0.8);
    margin-left: 10px;
    margin-right: auto;
}

.br-time {
    color: #fff;
    font-family: "Lato", "Helvetica Neue", Arial, sans-serif;
    margin-bottom: 5px;
}

.br-date {
    color: rgba(255, 255, 255, 0.3);
    font-weight: 300;
}

.sidebar-datepicker {
    padding-left: 25px;
    padding-right: 25px;
    position: relative;
}

.sidebar-datepicker .ui-datepicker-inline {
    position: relative;
    font-size: 13px;
    padding: 10px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background-color: transparent;
    border-radius: 0;
}

.sidebar-datepicker .ui-datepicker-inline .ui-datepicker-title {
    color: #fff;
    font-size: 11px;
    font-weight: 400;
}

.sidebar-datepicker .ui-datepicker-header {
    text-align: center;
    padding-bottom: 10px;
    color: #fff;
}

.sidebar-datepicker .ui-datepicker-header .ui-datepicker-next:hover::before,
.sidebar-datepicker .ui-datepicker-header .ui-datepicker-next:focus::before,
.sidebar-datepicker .ui-datepicker-header .ui-datepicker-prev:hover::before,
.sidebar-datepicker .ui-datepicker-header .ui-datepicker-prev:focus::before {
    color: #fff;
}

.sidebar-datepicker .ui-datepicker-calendar {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}

.sidebar-datepicker .ui-datepicker-calendar thead th {
    text-transform: uppercase;
    color: #17A2B8;
    font-weight: normal;
    font-size: 10px;
    text-align: center;
    padding-bottom: 5px;
    padding-left: 0;
    padding-right: 0;
}

.sidebar-datepicker .ui-datepicker-calendar td {
    border: 0;
    background-color: transparent;
}

.sidebar-datepicker .ui-datepicker-calendar td a {
    background-color: transparent;
}

.sidebar-datepicker .ui-datepicker-calendar .ui-state-default {
    display: block;
    text-align: center;
    padding: 2px;
    color: rgba(255, 255, 255, 0.6);
    font-size: 12px;
    border: 1px solid transparent;
}

.sidebar-datepicker .ui-datepicker-calendar .ui-state-default:hover,
.sidebar-datepicker .ui-datepicker-calendar .ui-state-default:focus {
    background-color: #1a2432;
    color: #fff;
}

.sidebar-datepicker .ui-datepicker-calendar .ui-datepicker-today .ui-state-default {
    background-color: transparent;
    color: #1CAF9A;
    border-color: #1CAF9A;
}

.sidebar-event-list .list-group-item {
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.1);
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.sidebar-event-list .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.sidebar-event-list .list-group-item:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.sidebar-event-list .list-group-item h6 {
    color: #fff;
    font-size: 13px;
    margin-bottom: 5px;
    font-weight: 400;
}

.sidebar-event-list .list-group-item p {
    margin-bottom: 0;
    color: rgba(255, 255, 255, 0.2);
    font-size: 12px;
}

.sidebar-settings-item {
    padding: 20px 25px;
    color: #fff;
}

.sidebar-settings-item .form-control,
.sidebar-settings-item .dataTables_filter input,
.dataTables_filter .sidebar-settings-item input {
    transition: all 0.2s ease-in-out;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #141d28;
    border-color: transparent;
    color: #adb5bd;
}

@media screen and (prefers-reduced-motion: reduce) {

    .sidebar-settings-item .form-control,
    .sidebar-settings-item .dataTables_filter input,
    .dataTables_filter .sidebar-settings-item input {
        transition: none;
    }
}

.br-mainpanel {
    margin-left: 0;
    margin-top: 60px;
    transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .br-mainpanel {
        transition: none;
    }
}

@media (min-width: 992px) {
    .br-mainpanel {
        margin-left: 230px;
    }
}

.br-pageheader {
    display: flex;
    align-items: center;
    background-color: #dee2e6;
    padding: 15px;
}

@media (min-width: 576px) {
    .br-pageheader {
        padding: 15px 20px;
    }
}

.br-pageheader .breadcrumb {
    background-color: transparent;
}

.br-pageheader .breadcrumb-item:not(.active) {
    color: #6c757d;
}

.br-pageheader .breadcrumb-item:not(.active):hover,
.br-pageheader .breadcrumb-item:not(.active):focus {
    color: #495057;
}

.br-pageheader .breadcrumb-item.active {
    color: #495057;
}

.br-pagetitle {
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
}

.br-pagetitle .icon {
    font-size: 70px;
    line-height: 0;
    color: #343a40;
}

.br-pagetitle>div {
    margin-top: 10px;
}

.br-pagetitle h4 {
    margin-bottom: 5px;
    color: #343a40;
}

.br-pagetitle p {
    margin-bottom: 0;
}

@media (min-width: 576px) {
    .br-pagetitle {
        display: flex;
        align-items: center;
        padding-top: 25px;
        padding-left: 30px;
        padding-right: 30px;
    }

    .br-pagetitle>div {
        padding-left: 20px;
        margin-top: 0;
    }
}

.br-pagebody {
    margin-top: 30px;
    padding: 0 15px;
}

@media (min-width: 576px) {
    .br-pagebody {
        padding: 0 30px;
    }
}

.br-section-label {
    color: #343a40;
    text-transform: uppercase;
    font-weight: bold !important;
    font-size: 14px !important;
    margin-top: 80px;
    margin-bottom: 10px;
}

.br-section-text {
    margin-bottom: 25px;
}

@media (min-width: 992px) {
    .br-section-text {
        margin-bottom: 50px;
    }
}

.br-section-label-sm {
    font-size: 11px;
    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: 40px;
    margin-bottom: 10px;
    color: #6c757d;
}

.br-section-wrapper {
    background-color: #fff;
    padding: 30px 20px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21);
    border-radius: 3px;
}

@media (min-width: 576px) {
    .br-section-wrapper {
        padding: 30px;
    }
}

@media (min-width: 992px) {
    .br-section-wrapper {
        padding: 20px;
    }
}

.br-section-wrapper .br-section-label:first-child {
    margin-top: 0;
}

.br-footer {
    font-size: 12px;
    padding: 20px 15px;
}

.br-footer a {
    color: #6c757d;
}

.br-footer a:hover,
.br-footer a:focus {
    color: #343a40;
}

.br-footer .footer-right {
    margin-top: 20px;
}

@media (min-width: 480px) {
    .br-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .br-footer .footer-right {
        margin-top: 0;
    }
}

@media (min-width: 576px) {
    .br-footer {
        padding: 30px;
    }
}

.br-contentpanel {
    margin-top: 60px;
    transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .br-contentpanel {
        transition: none;
    }
}

@media (min-width: 992px) {
    .br-contentpanel {
        margin-left: 230px;
    }
}

.tooltip-static-demo .tooltip {
    z-index: 1;
    opacity: 1;
    position: relative;
    display: inline-block;
    margin: 0 10px;
}

.tooltip-static-demo .bs-tooltip-top .arrow,
.tooltip-static-demo .bs-tooltip-auto[x-placement^="top"] .arrow,
.tooltip-static-demo .bs-tooltip-bottom .arrow,
.tooltip-static-demo .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    left: 50%;
    margin-left: -2px;
}

.tooltip-static-demo .bs-tooltip-left,
.tooltip-static-demo .bs-tooltip-auto[x-placement^="left"],
.tooltip-static-demo .bs-tooltip-right,
.tooltip-static-demo .bs-tooltip-auto[x-placement^="right"] {
    margin-top: 5px;
}

.tooltip-static-demo .bs-tooltip-left .arrow,
.tooltip-static-demo .bs-tooltip-auto[x-placement^="left"] .arrow,
.tooltip-static-demo .bs-tooltip-right .arrow,
.tooltip-static-demo .bs-tooltip-auto[x-placement^="right"] .arrow {
    top: 50%;
    margin-top: -3px;
}

.popover-static-demo .popover {
    z-index: 1;
    opacity: 1;
    position: relative;
    display: inline-block;
    margin: 0 10px;
}

.popover-static-demo .bs-popover-top .arrow,
.popover-static-demo .bs-popover-auto[x-placement^="top"] .arrow,
.popover-static-demo .bs-popover-bottom .arrow,
.popover-static-demo .bs-popover-auto[x-placement^="bottom"] .arrow {
    left: 50%;
    margin-left: -5px;
}

.popover-static-demo .bs-popover-left,
.popover-static-demo .bs-popover-auto[x-placement^="left"],
.popover-static-demo .bs-popover-right,
.popover-static-demo .bs-popover-auto[x-placement^="right"] {
    margin-top: 5px;
}

.popover-static-demo .bs-popover-left .arrow,
.popover-static-demo .bs-popover-auto[x-placement^="left"] .arrow,
.popover-static-demo .bs-popover-right .arrow,
.popover-static-demo .bs-popover-auto[x-placement^="right"] .arrow {
    top: 50%;
    margin-top: -5px;
}


/* ---------------------------------------------- */


/* ############### 5.1 Email Page ############### */


/* ---------------------------------------------- */

.email.collapsed-menu .br-subleft {
    left: 60px;
}

.email.collapsed-menu .br-mailbox-list {
    left: 290px;
}

.email.collapsed-menu .br-mailbox-body {
    margin-left: 600px;
}

.email.show-right .br-subleft {
    left: -170px;
}

.email.show-right .br-mailbox-list {
    left: 50px;
}

.email.show-right .br-mailbox-body {
    margin-left: 370px;
    margin-right: 260px;
}

@media (max-width: 1199.98px) {

    .email .br-mailbox-list,
    .email.collapsed-menu .br-mailbox-list {
        width: auto;
        right: 0;
    }

    .email .br-mailbox-body,
    .email.collapsed-menu .br-mailbox-body {
        margin-left: 0;
        display: none;
    }
}

@media (max-width: 991.98px) {

    .email .br-subleft,
    .email.collapsed-menu .br-subleft {
        left: 0;
    }

    .email .br-mailbox-list,
    .email.collapsed-menu .br-mailbox-list {
        left: 230px;
    }

    .email.show-left .br-sideleft {
        left: 0;
    }

    .email.show-left .br-subleft {
        left: 230px;
    }

    .email.show-left .br-mailbox-list {
        transform: translateX(230px);
    }

    .email.show-right .br-subleft {
        left: -230px;
    }

    .email.show-right .br-mailbox-list {
        transform: translateX(-260px);
    }
}

@media (max-width: 575.98px) {

    .email .br-subleft,
    .email.collapsed-menu .br-subleft {
        left: -230px;
    }

    .email .br-mailbox-list,
    .email.collapsed-menu .br-mailbox-list {
        left: 0;
    }

    .email.show-mb-left .br-subleft,
    .email.collapsed-menu.show-mb-left .br-subleft {
        left: 0;
    }

    .email.show-mb-left .br-mailbox-list,
    .email.collapsed-menu.show-mb-left .br-mailbox-list {
        transform: translateX(230px);
    }

    .email.show-mb-left.show-left .br-subleft,
    .email.collapsed-menu.show-mb-left.show-left .br-subleft {
        left: 230px;
    }

    .email.show-mb-left.show-left .br-mailbox-list,
    .email.collapsed-menu.show-mb-left.show-left .br-mailbox-list {
        transform: translateX(460px);
    }

    .email.show-mb-left.show-right .br-subleft,
    .email.collapsed-menu.show-mb-left.show-right .br-subleft {
        transform: translateX(-280px);
    }

    .email.show-mb-left.show-right .br-mailbox-list,
    .email.collapsed-menu.show-mb-left.show-right .br-mailbox-list {
        transform: translateX(-280px);
    }
}

.br-subleft {
    position: fixed;
    left: 0;
    top: 60px;
    bottom: 0;
    width: 230px;
    padding: 10px;
    z-index: 10;
    background-color: #26354a;
    transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .br-subleft {
        transition: none;
    }
}

.br-subleft .btn-compose {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    font-size: 11px;
    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
    letter-spacing: 1px;
}

.br-subleft .btn-compose i {
    font-size: 18px;
    line-height: 0;
    margin-right: 7px;
    margin-top: -2px;
}

.br-nav-mailbox {
    margin-top: 10px;
}

.br-nav-mailbox .nav-link {
    color: rgba(255, 255, 255, 0.75);
    display: flex;
    align-items: center;
    height: 38px;
    padding: 0 10px;
    border-radius: 2px;
}

.br-nav-mailbox .nav-link i {
    line-height: 0;
    width: 20px;
    font-size: 24px;
    margin-right: 10px;
    text-align: center;
}

.br-nav-mailbox .nav-link i.ion-ios-folder-outline {
    font-size: 18px;
}

.br-nav-mailbox .nav-link i.ion-ios-pricetag-outline {
    font-size: 18px;
}

.br-nav-mailbox .nav-link:hover,
.br-nav-mailbox .nav-link:focus {
    background-color: #223043;
    color: #17A2B8;
}

.br-nav-mailbox .nav-link.active {
    background-color: #223043;
    color: #17A2B8;
}

.br-nav-mailbox .nav-link+.nav-link {
    margin-top: 1px;
}

.show-mailbox-left {
    border-right: 1px solid #dee2e6;
    color: #6c757d;
    height: 100%;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -15px;
    font-size: 14px;
}

.show-mailbox-left:hover,
.show-mailbox-left:focus {
    color: #343a40;
}

.br-mailbox-list {
    position: fixed;
    top: 60px;
    bottom: 0;
    left: 230px;
    width: 320px;
    background-color: #fff;
    z-index: 100;
    border-right: 1px solid #ced4da;
    transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .br-mailbox-list {
        transition: none;
    }
}

.br-mailbox-list-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    border-bottom: 1px solid #e9ecef;
    padding: 0 15px;
}

.br-mailbox-list-header .btn-group .btn,
.br-mailbox-list-header .btn-group .sp-container button,
.sp-container .br-mailbox-list-header .btn-group button {
    padding: 8px 10px;
    border: 0;
}

.br-mailbox-list-item {
    position: relative;
    padding: 10px 15px;
    background-color: #f8f9fa;
    transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .br-mailbox-list-item {
        transition: none;
    }
}

.br-mailbox-list-item::before {
    content: '';
    position: absolute;
    top: 50%;
    bottom: 50%;
    left: 0;
    width: 2px;
    background-color: #adb5bd;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .br-mailbox-list-item::before {
        transition: none;
    }
}

.br-mailbox-list-item:hover,
.br-mailbox-list-item:focus {
    background-color: #f2f4f6;
    cursor: pointer;
}

.br-mailbox-list-item:hover::before,
.br-mailbox-list-item:focus::before {
    top: -1px;
    bottom: -1px;
    opacity: 1;
}

.br-mailbox-list-item.unread,
.br-mailbox-list-item.unread:hover,
.br-mailbox-list-item.unread:focus {
    background-color: #fff;
}

.br-mailbox-list-item.active,
.br-mailbox-list-item.active:hover,
.br-mailbox-list-item.active:focus {
    background-color: #fff;
}

.br-mailbox-list-item.active::before,
.br-mailbox-list-item.active:hover::before,
.br-mailbox-list-item.active:focus::before {
    background-color: #17A2B8;
    top: -1px;
    bottom: -1px;
    opacity: 1;
}

.br-mailbox-list-item+.br-mailbox-list-item {
    border-top: 1px solid #dee2e6;
}

.br-mailbox-body {
    margin-top: 60px;
    margin-left: 550px;
    transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .br-mailbox-body {
        transition: none;
    }
}

.br-msg-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;
}

.br-msg-header .nav-link {
    font-size: 24px;
    color: #6c757d;
}

.br-msg-body {
    background-color: #fff;
    padding: 40px;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 30px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21);
    border-radius: 3px;
}

.br-msg-body p:last-child {
    margin-bottom: 0;
}


/** WITH SUB LEFT MENU **/

.with-subleft .br-subleft {
    left: -230px;
}

@media (min-width: 992px) {
    .with-subleft .br-subleft {
        left: 0;
    }
}

@media (min-width: 992px) {
    .with-subleft.collapsed-menu .br-subleft {
        left: 60px;
    }
}

@media (max-width: 991.98px) {
    .with-subleft.show-subleft {
        overflow: hidden;
    }

    .with-subleft.show-subleft .br-subleft {
        left: 0;
    }

    .with-subleft.show-subleft .br-contentpanel {
        transform: translateX(230px);
    }

    .with-subleft.show-subleft.show-left .br-subleft {
        left: 230px;
    }

    .with-subleft.show-subleft.show-left .br-contentpanel {
        transform: translateX(460px);
    }

    .with-subleft.show-subleft.show-right .br-subleft {
        transform: translateX(-280px);
    }

    .with-subleft.show-subleft.show-right .br-contentpanel {
        transform: translateX(-280px);
    }
}


/* ------------------------------------------------- */


/* ############### 5.2 Contacts Page ############### */


/* ------------------------------------------------- */

.table-contact tbody tr td:nth-child(4),
.table-contact tbody tr td:nth-child(5),
.table-contact tbody tr th:nth-child(4),
.table-contact tbody tr th:nth-child(5) {
    font-family: "Lato", "Helvetica Neue", Arial, sans-serif;
    font-size: 13px;
}

.table-contact tbody tr td:nth-child(4),
.table-contact tbody tr th:nth-child(4) {
    color: #495057;
}


/* ------------------------------------------------ */


/* ############### 5.3 Profile Page ############### */


/* ------------------------------------------------ */

.br-profile-page .card-header {
    background-color: #17A2B8;
    border-bottom: 0;
    border-radius: 0;
}

.br-profile-page .card-header a {
    color: rgba(255, 255, 255, 0.8);
}

.br-profile-page .card-body {
    background-color: #2e415b;
}

.br-profile-page .br-profile-body {
    max-width: 1150px;
    margin-left: auto;
    margin-right: auto;
    padding: 15px;
}

@media (min-width: 480px) {
    .br-profile-page .br-profile-body {
        padding: 30px;
    }
}

.br-profile-page .profile-skills {
    display: flex;
    flex-wrap: wrap;
}

.br-profile-page .profile-skills li {
    display: block;
    margin-right: 5px;
    margin-bottom: 5px;
}

.br-profile-page .profile-skills span {
    background-color: #e9ecef;
    padding: 5px 10px;
    display: inline-block;
    border-radius: 2px;
}

.br-profile-page .media-list .media+.media {
    border-top: 1px solid #e9ecef;
}

.br-profile-page .media-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
}

.br-profile-page .media-footer a {
    color: #adb5bd;
}


/* --------------------------------------------- */


/* ############### 5.4 Chat Page ############### */


/* --------------------------------------------- */

@media (min-width: 992px) {
    .collapsed-menu .br-chatpanel {
        margin-left: 60px;
    }
}

@media (max-width: 991.98px) {
    .show-left .br-chatpanel {
        transform: translateX(230px);
    }
}

.br-chatpanel {
    margin-top: 60px;
    height: calc(100vh - 60px);
    display: flex;
    align-items: stretch;
    transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .br-chatpanel {
        transition: none;
    }
}

@media (min-width: 992px) {
    .br-chatpanel {
        margin-left: 230px;
    }
}

.br-chatpanel.show-body .br-chatpanel-left {
    display: none;
}

.br-chatpanel.show-body .br-chatpanel-body {
    display: block;
}

.br-chatpanel-left {
    width: 100%;
    background-color: #f8f9fa;
    border-right: 1px solid #ced4da;
}

@media (min-width: 992px) {
    .br-chatpanel-left {
        width: 320px;
    }
}

.br-chatpanel-left .nav {
    align-items: center;
    padding: 20px 20px 0;
}

.br-chatpanel-left .nav .nav-link {
    font-size: 10px;
    font-weight: 500;
    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 0;
    color: #868ba1;
}

.br-chatpanel-left .nav .nav-link.active {
    color: #17A2B8;
    position: relative;
}

.br-chatpanel-left .nav .nav-link.active::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
    border-bottom: 2px solid #17A2B8;
}

.br-chatpanel-left .nav .nav-link+.nav-link {
    margin-left: 20px;
}

.br-active-contacts {
    padding: 20px;
    border-bottom: 1px solid #dee2e6;
}

.br-sidebar-label {
    font-size: 10px;
    font-weight: 500;
    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
    text-transform: uppercase;
    color: #adb5bd;
}

.br-chat-contacts {
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.br-chat-contacts .br-img-user {
    margin: auto;
}

.br-chat-contacts>div {
    text-align: center;
}

.br-chat-contacts>div+div {
    margin-left: 10px;
}

.br-chat-contacts .chat-contacts-more {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: #dee2e6;
    font-size: 11px;
    font-weight: 500;
    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
}

.br-img-user {
    width: 36px;
    height: 36px;
    position: relative;
}

.br-img-user img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
}

.br-img-user::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 5px;
    width: 7px;
    height: 7px;
    background-color: #ced4da;
    border-radius: 100%;
    box-shadow: 0 0 0 2px #f8f9fa;
}

.br-img-user.online::after {
    background-color: #23BF08;
}

.br-chatlist {
    height: calc(100% - 176px);
    overflow: hidden;
    position: relative;
}

.br-chatlist .br-img-user {
    width: 40px;
    height: 40px;
    margin-top: 5px;
}

.br-chatlist .msg-count {
    position: absolute;
    top: -5px;
    left: -5px;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background-color: #DC3545;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
    box-shadow: 0 0 0 2px #f8f9fa;
    display: none;
}

.br-chatlist .media {
    padding: 10px 20px;
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    position: relative;
    margin-top: -1px;
}

.br-chatlist .media.new {
    background-color: #fff;
}

.br-chatlist .media.new .msg-count {
    display: flex;
}

.br-chatlist .media.selected {
    background-color: #e9ecef;
}

.br-chatlist .media.selected .br-img-user::after {
    box-shadow: 0 0 0 2px #e9ecef;
}

.br-chatlist .media.selected::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border-left: 3px solid #17A2B8;
}

.br-chatlist .media-body {
    margin-left: 15px;
}

.br-chatlist .media-body P {
    margin-bottom: 0;
    font-size: 11px;
    line-height: 1.3;
}

.br-chatlist .media-contact-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2px;
}

.br-chatlist .media-contact-name span:first-child {
    font-size: 13px;
    font-weight: 500;
    color: #212529;
}

.br-chatlist .media-contact-name span:last-child {
    font-size: 11px;
    color: #adb5bd;
}

.br-chatpanel-body {
    flex: 1;
    position: relative;
    height: 100%;
    display: none;
}

@media (min-width: 992px) {
    .br-chatpanel-body {
        display: block;
    }
}

.br-chat-header {
    background-color: #fff;
    border-bottom: 1px solid #ced4da;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 20px;
}

.br-chat-header .br-img-user {
    width: 36px;
    height: 36px;
}

.br-chat-header .chat-name {
    margin-left: 10px;
}

.br-chat-header .chat-name h6 {
    color: #212529;
    margin-bottom: 0;
}

.br-chat-header .nav {
    margin-left: auto;
    align-items: center;
}

.br-chat-header .nav-link {
    padding: 0;
    font-size: 24px;
    line-height: 1;
    color: #adb5bd;
    display: none;
}

@media (min-width: 576px) {
    .br-chat-header .nav-link {
        display: block;
    }
}

.br-chat-header .nav-link:hover,
.br-chat-header .nav-link:focus {
    color: #212529;
}

.br-chat-header .nav-link+.nav-link {
    margin-left: 15px;
}

.br-chat-header .nav-link:first-child {
    font-size: 21px;
    display: block;
}

@media (min-width: 576px) {
    .br-chat-header .nav-link:first-child {
        display: none;
    }
}

.br-chat-body {
    position: relative;
    height: calc(100% - 130px);
    overflow-y: auto;
}

.br-chat-body .content-inner {
    padding: 20px;
}

.br-chat-body .br-img-user::after {
    box-shadow: 0 0 0 2px #e9ecef;
}

.br-chat-body .media+.media {
    margin-top: 20px;
}

.br-chat-body .media.flex-row-reverse .media-body {
    margin-left: 0;
    margin-right: 20px;
    align-items: flex-end;
}

@media (min-width: 576px) {
    .br-chat-body .media.flex-row-reverse .media-body {
        margin-left: 55px;
    }
}

.br-chat-body .media.flex-row-reverse .msg-wrapper {
    background-color: #17A2B8;
    color: #fff;
}

.br-chat-body .media-body {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

@media (min-width: 576px) {
    .br-chat-body .media-body {
        margin-right: 55px;
    }
}

.br-chat-body .media-body>div:last-child {
    font-size: 11px;
    color: #6c757d;
    display: flex;
    align-items: center;
}

.br-chat-body .media-body>div:last-child a {
    font-size: 16px;
    line-height: 1;
    margin-left: 5px;
    color: #adb5bd;
}

.br-chat-body .chat-time {
    display: block;
    position: relative;
    text-align: center;
    margin: 20px 0;
}

.br-chat-body .chat-time:first-of-type {
    margin-top: 0;
}

.br-chat-body .chat-time span {
    font-size: 10px;
    font-weight: 500;
    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.br-chat-body .msg-wrapper {
    padding: 10px 15px;
    background-color: #ced4da;
    color: #495057;
    font-size: 13px;
    margin-bottom: 5px;
    border-radius: 2px;
    display: inline-block;
}

.br-chat-footer {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: #fff;
    border-top: 1px solid #ced4da;
    padding: 0 20px;
}

.br-chat-footer .nav {
    display: flex;
    align-items: center;
}

.br-chat-footer .nav:last-child {
    display: none;
}

@media (min-width: 576px) {
    .br-chat-footer .nav:last-child {
        display: flex;
    }
}

.br-chat-footer>div {
    flex: 1;
    margin-left: 20px;
}

@media (min-width: 576px) {
    .br-chat-footer>div {
        margin-right: 20px;
    }
}

.br-chat-footer>div .form-control,
.br-chat-footer>div .dataTables_filter input,
.dataTables_filter .br-chat-footer>div input {
    padding: 0;
    border-width: 0;
}

.br-chat-footer .nav-link {
    padding: 0;
    font-size: 24px;
    color: #adb5bd;
}

.br-chat-footer .nav-link:hover,
.br-chat-footer .nav-link:focus {
    color: #212529;
}

.br-chat-footer .nav-link+.nav-link {
    margin-left: 10px;
}


/* ------------------------------------------- */


/* ############### 5.5 Sitemap ############### */


/* ------------------------------------------- */

.br-sitemap-section a {
    color: #343a40;
}

.br-sitemap-section a:hover,
.br-sitemap-section a:focus {
    color: #17A2B8;
}

.br-sitemap-section h6 {
    font-size: 16px;
    margin-top: 20px;
}

.br-sitemap-section h6:first-child {
    margin-top: 0;
}

.br-sitemap-section ol {
    padding-left: 0;
    list-style: none;
}

.br-sitemap-section ol>li {
    margin-top: 20px;
}

.br-sitemap-section ol>li a {
    color: #17A2B8;
    font-weight: 500;
}

.br-sitemap-section ol>li a:hover,
.br-sitemap-section ol>li a:focus {
    color: #148ea1;
}

.br-sitemap-section ol>li:first-child {
    margin-top: 0;
}

.br-sitemap-section ul {
    list-style: none;
    margin-top: 5px;
    padding-left: 20px;
}

.br-sitemap-section ul li {
    position: relative;
}

.br-sitemap-section ul li::before {
    content: '';
    position: absolute;
    height: 1px;
    width: 8px;
    top: 50%;
    left: -18px;
    background-color: #17A2B8;
}


/* --------------------------------------------------- */


/* ############### 6. FULL RTL SUPPORT ############### */


/* --------------------------------------------------- */

.rtl {
    /* ##### EMAIL PAGE ##### */
    /** WITH SUB LEFT MENU **/
    /** FORM ELEMENTS **/
    /** SITEMAP **/
    /** CHAT PAGE **/
    /* #################### DASHBOARD SKINS #################### */
}

.rtl body {
    text-align: right;
}

.rtl .br-header::before {
    left: auto;
    right: -4px;
}

@media (min-width: 992px) {
    .rtl .br-header {
        left: 0;
        right: 230px;
    }
}

.rtl .br-header-left .input-group .form-control,
.rtl .br-header-left .input-group .dataTables_filter input,
.dataTables_filter .rtl .br-header-left .input-group input,
.rtl .br-header-left .input-group .form-control:not(:last-child),
.rtl .br-header-left .input-group .dataTables_filter input:not(:last-child),
.dataTables_filter .rtl .br-header-left .input-group input:not(:last-child) {
    border-right-width: 0;
}

.rtl .br-header-left .input-group {
    border-right: 0;
    border-left: 1px solid #ced4da;
}

.rtl .br-header-left .input-group-btn .btn,
.rtl .br-header-left .input-group-btn .sp-container button,
.sp-container .rtl .br-header-left .input-group-btn button {
    margin-left: 0;
}

.rtl .br-header-right .dropdown .dropdown-menu {
    right: auto !important;
    left: -1px !important;
}

.rtl .br-header-right .dropdown .dropdown-menu::before {
    right: -2px;
    left: 0;
}

@media (max-width: 479px) {
    .rtl .br-header-right .dropdown:first-child .dropdown-menu {
        transform: translateX(-145px) !important;
    }

    .rtl .br-header-right .dropdown:nth-child(2) .dropdown-menu {
        transform: translateX(-113px) !important;
    }

    .rtl .br-header-right .dropdown:last-child .dropdown-menu {
        transform: translateX(-49px) !important;
    }
}

.rtl .nav-link-profile .square-10 {
    right: auto;
    left: 15px;
}

.rtl .dropdown-menu-header {
    text-align: right;
}

.rtl .logged-name {
    margin-right: 0;
    margin-left: 5px;
}

.rtl .navicon-left {
    border-right: 0;
    border-left: 1px solid #ced4da;
}

.rtl .navicon-right {
    border-left: 0;
    border-right: 1px solid #ced4da;
}

.rtl .br-logo {
    left: auto;
    right: -230px;
}

@media (min-width: 992px) {
    .rtl .br-logo {
        right: 0;
        border-right: 0;
        border-left: 1px solid #ced4da;
    }
}

.rtl .br-logo>a:hover span:first-child,
.rtl .br-logo>a:focus span:first-child {
    transform: translateX(5px);
}

.rtl .br-logo>a:hover span:last-child,
.rtl .br-logo>a:focus span:last-child {
    transform: translateX(-5px);
}

.rtl .dropdown-menu-header {
    right: auto !important;
    left: -1px !important;
}

.rtl .dropdown-menu-header::before {
    right: -2px;
    left: 0;
}

.rtl .user-profile-nav {
    text-align: right;
    padding-right: 0;
}

.rtl .user-profile-nav .icon {
    margin-left: 5px;
    margin-right: 0;
}

.rtl .media-list-link .media-body {
    margin-left: 0;
    margin-right: 15px;
    text-align: right;
}

.rtl .br-sideleft {
    left: auto;
    right: -230px;
}

@media (min-width: 992px) {
    .rtl .br-sideleft {
        right: 0;
    }
}

.rtl .br-menu-link.with-sub::after {
    right: auto;
    left: 13px;
}

.rtl .menu-item-label {
    margin-right: 8px;
    margin-left: auto;
}

.rtl .br-menu-sub {
    padding-left: 0;
    padding-right: 21px;
}

.rtl .br-menu-sub .sub-link {
    padding: 7px 17px 7px 0;
}

.rtl .br-menu-sub .sub-link::before {
    left: auto;
    right: -3px;
}

.rtl .br-menu-sub .sub-link:hover::before,
.rtl .br-menu-sub .sub-link:focus::before {
    left: auto;
    right: -11px;
}

.rtl .br-menu-sub .sub-link.active::before {
    left: auto;
    right: -11px;
}

@media (min-width: 992px) {
    .rtl .collapsed-menu .br-logo {
        left: auto;
        right: -230px;
    }

    .rtl .collapsed-menu .br-header {
        right: 0;
    }

    .rtl .collapsed-menu .br-mainpanel {
        margin-left: 0;
        margin-right: 60px;
    }

    .rtl .collapsed-menu .br-contentpanel {
        margin-left: 0;
        margin-right: 290px;
    }
}

@media (min-width: 992px) {
    .rtl .expand-menu .br-logo {
        left: auto;
        right: 60px;
    }
}

.rtl .br-sideright {
    right: auto;
    left: -280px;
}

.rtl .br-sideright .switch-button-label,
.rtl .br-sideright .switch-button-background {
    float: right;
}

@media (max-width: 991.98px) {

    .rtl .show-left .br-header,
    .rtl .show-left .br-mainpanel {
        transform: translateX(-230px);
    }

    .rtl .show-left .br-logo,
    .rtl .show-left .br-sideleft {
        left: auto;
        right: 0;
    }

    .rtl .show-left .br-contentpanel {
        transform: translateX(-230px);
    }
}

.rtl .show-right .br-logo,
.rtl .show-right .br-sideleft,
.rtl .show-right .br-header,
.rtl .show-right .br-subleft,
.rtl .show-right .br-mainpanel,
.rtl .show-right .br-contentpanel {
    transform: translateX(280px);
}

.rtl .show-right .br-sideright {
    left: 0;
    right: auto;
}

.rtl .sidebar-tabs {
    padding-right: 0;
}

.rtl .br-mainpanel {
    margin-left: 0;
}

@media (min-width: 992px) {
    .rtl .br-mainpanel {
        margin-right: 230px;
        margin-left: 0;
    }
}

.rtl .contact-status-indicator {
    left: 0;
    right: auto;
}

.rtl .br-pageheader {
    direction: ltr;
    display: flex;
    justify-content: flex-end;
}

.rtl .br-pageheader .breadcrumb {
    direction: rtl;
}

@media (min-width: 576px) {
    .rtl .br-pagetitle>div {
        padding-left: 0;
        padding-right: 20px;
    }
}

.rtl .contact-list-link .contact-person {
    margin-right: 10px;
    margin-left: auto;
}

.rtl .media-file-list .media-body {
    margin-right: 10px;
    margin-left: auto;
}

.rtl .email.collapsed-menu .br-subleft {
    left: auto;
    right: 60px;
}

.rtl .email.collapsed-menu .br-mailbox-list {
    left: auto;
    right: 290px;
}

.rtl .email.collapsed-menu .br-mailbox-body {
    margin-left: 0;
    margin-right: 600px;
}

.rtl .email.show-right .br-subleft {
    left: auto;
    right: -170px;
}

.rtl .email.show-right .br-mailbox-list {
    left: auto;
    right: 50px;
}

.rtl .email.show-right .br-mailbox-body {
    margin-right: 370px;
    margin-left: 260px;
}

@media (max-width: 1199.98px) {

    .rtl .email .br-mailbox-list,
    .rtl .email.collapsed-menu .br-mailbox-list {
        left: 0;
    }

    .rtl .email .br-mailbox-body,
    .rtl .email.collapsed-menu .br-mailbox-body {
        margin-right: 0;
    }
}

@media (max-width: 991.98px) {

    .rtl .email .br-subleft,
    .rtl .email.collapsed-menu .br-subleft {
        right: 0;
    }

    .rtl .email .br-mailbox-list,
    .rtl .email.collapsed-menu .br-mailbox-list {
        right: 230px;
    }

    .rtl .email.show-left .br-sideleft {
        right: 0;
    }

    .rtl .email.show-left .br-subleft {
        right: 230px;
    }

    .rtl .email.show-left .br-mailbox-list {
        transform: translateX(-230px);
    }

    .rtl .email.show-right .br-subleft {
        right: -230px;
    }

    .rtl .email.show-right .br-mailbox-list {
        transform: translateX(260px);
    }
}

@media (max-width: 575.98px) {

    .rtl .email .br-subleft,
    .rtl .email.collapsed-menu .br-subleft {
        right: -230px;
    }

    .rtl .email .br-mailbox-list,
    .rtl .email.collapsed-menu .br-mailbox-list {
        right: 0;
    }

    .rtl .email.show-mb-left .br-subleft,
    .rtl .email.collapsed-menu.show-mb-left .br-subleft {
        right: 0;
    }

    .rtl .email.show-mb-left .br-mailbox-list,
    .rtl .email.collapsed-menu.show-mb-left .br-mailbox-list {
        transform: translateX(-230px);
    }

    .rtl .email.show-mb-left.show-left .br-subleft,
    .rtl .email.collapsed-menu.show-mb-left.show-left .br-subleft {
        right: 230px;
    }

    .rtl .email.show-mb-left.show-left .br-mailbox-list,
    .rtl .email.collapsed-menu.show-mb-left.show-left .br-mailbox-list {
        transform: translateX(-460px);
    }

    .rtl .email.show-mb-left.show-right .br-subleft,
    .rtl .email.collapsed-menu.show-mb-left.show-right .br-subleft {
        transform: translateX(280px);
    }

    .rtl .email.show-mb-left.show-right .br-mailbox-list,
    .rtl .email.collapsed-menu.show-mb-left.show-right .br-mailbox-list {
        transform: translateX(280px);
    }
}

.rtl .br-subleft {
    left: auto;
    right: 0;
}

.rtl .br-subleft .btn-compose i {
    margin-left: 7px;
    margin-right: 0;
}

.rtl .br-nav-mailbox .nav-link i {
    margin-left: 10px;
    margin-right: 0;
}

.rtl .show-mailbox-left {
    border-left: 1px solid #dee2e6;
    border-right: 0;
    margin-right: -15px;
    margin-left: 0;
}

.rtl .br-mailbox-list {
    right: 230px;
    left: auto;
    border-left: 1px solid #ced4da;
    border-right: 0;
}

.rtl .br-mailbox-list-item::before {
    left: auto;
    right: 0;
}

.rtl .br-mailbox-body {
    margin-left: 0;
    margin-right: 550px;
}

.rtl .with-subleft .br-subleft {
    left: auto;
    right: -230px;
}

@media (min-width: 992px) {
    .rtl .with-subleft .br-subleft {
        right: 0;
    }
}

@media (min-width: 992px) {
    .rtl .with-subleft.collapsed-menu .br-subleft {
        right: 60px;
    }
}

@media (max-width: 991.98px) {
    .rtl .with-subleft.show-subleft .br-subleft {
        right: 0;
    }

    .rtl .with-subleft.show-subleft .br-contentpanel {
        transform: translateX(-230px);
    }

    .rtl .with-subleft.show-subleft.show-left .br-subleft {
        right: 230px;
    }

    .rtl .with-subleft.show-subleft.show-left .br-contentpanel {
        transform: translateX(-460px);
    }

    .rtl .with-subleft.show-subleft.show-right .br-subleft {
        transform: translateX(280px);
    }

    .rtl .with-subleft.show-subleft.show-right .br-contentpanel {
        transform: translateX(280px);
    }
}

.rtl .input-group-prepend {
    margin-left: -1px;
    margin-right: 0;
}

.rtl .input-group-prepend>.input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.rtl .input-group-append {
    margin-right: 0;
    margin-left: 0;
}

.rtl .input-group-append>.input-group-text,
.rtl .input-group-append>.btn,
.rtl .sp-container .input-group-append>button,
.sp-container .rtl .input-group-append>button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.rtl .custom-file-label {
    text-align: left;
}

.rtl .br-sitemap-section ol {
    padding-right: 0;
}

.rtl .br-sitemap-section ul {
    padding-right: 20px;
    padding-left: 0;
}

.rtl .br-sitemap-section ul li::before {
    left: auto;
    right: -18px;
}

@media (min-width: 992px) {
    .rtl .collapsed-menu .br-chatpanel {
        margin-left: 0;
        margin-right: 60px;
    }
}

@media (min-width: 992px) {
    .rtl .br-chatpanel {
        margin-left: 0;
        margin-right: 230px;
    }
}

.rtl .br-chatpanel-left {
    border-right-width: 0;
    border-left: 1px solid #ced4da;
}

.rtl .br-chatpanel-left .nav .nav-link+.nav-link {
    margin-left: 0;
    margin-right: 20px;
}

.rtl .br-chatlist .media-body {
    margin-left: 0;
    margin-right: 15px;
}

.rtl .br-chatlist .media.selected::before {
    left: auto;
    right: 0;
}

.rtl .br-chat-header .nav {
    margin-left: inherit;
    margin-right: auto;
}

.rtl .br-chat-header .chat-name {
    margin-left: 0;
    margin-right: 10px;
}

.rtl .br-chat-header .nav-link+.nav-link {
    margin-left: 0;
    margin-right: 15px;
}

.rtl .br-chat-body .media-body {
    margin-left: 0;
    margin-right: 20px;
}

@media (min-width: 576px) {
    .rtl .br-chat-body .media-body {
        margin-left: 55px;
    }
}

.rtl .br-chat-body .media.flex-row-reverse .media-body {
    margin-left: 20px;
    margin-right: 0;
}

@media (min-width: 576px) {
    .rtl .br-chat-body .media.flex-row-reverse .media-body {
        margin-right: 55px;
    }
}

.rtl pre,
.rtl code,
.rtl pre code {
    direction: ltr;
    text-align: left;
}

.rtl .br-simple-white .br-sideleft {
    border-left: 1px solid #ced4da;
    border-right: 0;
}

.rtl .br-simple-white .br-menu-sub .nav-link:hover::before,
.rtl .br-simple-white .br-menu-sub .nav-link:focus::before {
    right: -15px;
    left: auto;
}

.rtl .br-simple-white .br-menu-sub .nav-link.active::before {
    right: -15px;
    left: auto;
}

.rtl .br-simple-white .sidebar-tabs .nav-item:first-child .nav-link {
    border-right: 0;
}

.rtl .br-simple-white .br-subleft {
    border-left: 1px solid #dee2e6;
    border-right: 0;
}

.headpanel {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 100;
    height: 70px;
    transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .headpanel {
        transition: none;
    }
}

.headpanel.scroll {
    box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.16);
}

.headpanel .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
}

@media (min-width: 576px) {
    .headpanel .container {
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media (max-width: 991.98px) {
    .headpanel .container {
        max-width: none;
    }
}

@media (min-width: 992px) {
    .headpanel .container {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.headpanel .container .nav {
    background-color: #fff;
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    flex-direction: column;
    padding: 20px 15px;
    border-top: 1px solid #e9ecef;
}

.headpanel .container .nav::after,
.headpanel .container .nav::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 5px;
    background-color: #fff;
}

.headpanel .container .nav::before {
    z-index: 200;
}

.headpanel .container .nav::after {
    z-index: 100;
    bottom: -4px;
    box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.16);
}

@media (min-width: 992px) {
    .headpanel .container .nav {
        flex-direction: row;
        position: relative;
        top: 0;
        left: auto;
        width: auto;
        padding: 0;
        border-top: 0;
    }

    .headpanel .container .nav::before,
    .headpanel .container .nav::after {
        display: none;
    }
}

.headpanel .logo {
    font-size: 24px;
    font-weight: bold;
    color: #343a40;
    position: relative;
    top: -2px;
}

.headpanel .logo span {
    color: #17A2B8;
    font-weight: normal;
}

.headpanel .nav-link {
    color: #495057;
}

.headpanel .nav-link:hover,
.headpanel .nav-link:focus {
    color: #17A2B8;
}

.headpanel .nav-link.active,
.headpanel .nav-link.active:hover,
.headpanel .nav-link.active:focus {
    color: #17A2B8;
}

.br-home-header {
    display: flex;
    align-items: center;
    background-color: #fff;
}

.br-home-header .container {
    padding-top: 100px;
    padding-bottom: 30px;
}

@media (min-width: 992px) {
    .br-home-header {
        height: 100vh;
    }

    .br-home-header .container {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.section2 {
    background-color: #26354a;
}

.btn-outline-white {
    border: 2px solid rgba(255, 255, 255, 0.7);
    color: #fff;
    padding-left: 25px;
    padding-right: 25px;
}

.btn-outline-white:hover,
.btn-outline-white:focus {
    border-color: #fff;
    color: #fff;
}

.br-demo figure {
    position: relative;
    background-color: #fff;
    border-radius: 3px;
    padding: 15px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21);
}

.br-demo figure:hover figcaption,
.br-demo figure:focus figcaption {
    opacity: 1;
}

.br-demo figcaption {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    transition: all 0.2s ease-in-out;
    opacity: 0;
    z-index: 10;
}

@media screen and (prefers-reduced-motion: reduce) {
    .br-demo figcaption {
        transition: none;
    }
}

.br-demo figcaption .btn,
.br-demo figcaption .sp-container button,
.sp-container .br-demo figcaption button {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 500;
    font-family: "Montserrat", "Fira Sans", "Helvetica Neue", Arial, sans-serif;
    padding: 12px 25px;
    letter-spacing: 0.5px;
    border: 0;
}

.br-foot {
    background-color: #1D2939;
    font-size: 13px;
}

.br-foot .container {
    padding: 30px 15px;
}

@media (min-width: 768px) {
    .br-foot .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 0;
        padding-right: 0;
    }
}

.br-foot .social {
    display: block;
    margin-top: 10px;
}

@media (min-width: 768px) {
    .br-foot .social {
        display: inline-block;
        margin-left: 15px;
        margin-top: 0;
    }
}

.br-foot .social a {
    color: rgba(255, 255, 255, 0.5);
}

.br-foot .social a:hover,
.br-foot .social a:focus {
    color: #fff;
}

.br-foot .social a+a {
    margin-left: 2px;
}

.br-holder {
    position: absolute;
    margin-top: -70px;
    height: 70px;
    visibility: hidden;
}


/* Helper Class */

.mg-t--7 {
    margin-top: -7px;
}


/* ------------------------------------------------------------- */


/* ############### 7. UTILITIES / HELPER CLASSES ############### */


/* ------------------------------------------------------------- */


/* ---------------------------------------------- */


/* ############### 7.1 Background ############### */


/* ---------------------------------------------- */

.bg-transparent {
    background-color: transparent;
}

.bg-gray-100 {
    background-color: #f8f9fa;
}

.bg-gray-200 {
    background-color: #e9ecef;
}

.bg-gray-300 {
    background-color: #dee2e6;
}

.bg-gray-400 {
    background-color: #ced4da;
}

.bg-gray-500 {
    background-color: #adb5bd;
}

.bg-gray-600 {
    background-color: #6c757d;
}

.bg-gray-700 {
    background-color: #495057;
}

.bg-gray-800 {
    background-color: #343a40;
}

.bg-gray-900 {
    background-color: #212529;
}

.bg-white-1 {
    background-color: rgba(255, 255, 255, 0.1);
}

.bg-white-2 {
    background-color: rgba(255, 255, 255, 0.2);
}

.bg-white-3 {
    background-color: rgba(255, 255, 255, 0.3);
}

.bg-white-4 {
    background-color: rgba(255, 255, 255, 0.4);
}

.bg-white-5 {
    background-color: rgba(255, 255, 255, 0.5);
}

.bg-white-6 {
    background-color: rgba(255, 255, 255, 0.6);
}

.bg-white-7 {
    background-color: rgba(255, 255, 255, 0.7);
}

.bg-white-8 {
    background-color: rgba(255, 255, 255, 0.8);
}

.bg-white-9 {
    background-color: rgba(255, 255, 255, 0.9);
}

.bg-black-1 {
    background-color: rgba(0, 0, 0, 0.1);
}

.bg-black-2 {
    background-color: rgba(0, 0, 0, 0.2);
}

.bg-black-3 {
    background-color: rgba(0, 0, 0, 0.3);
}

.bg-black-4 {
    background-color: rgba(0, 0, 0, 0.4);
}

.bg-black-5 {
    background-color: rgba(0, 0, 0, 0.5);
}

.bg-black-6 {
    background-color: rgba(0, 0, 0, 0.6);
}

.bg-black-7 {
    background-color: rgba(0, 0, 0, 0.7);
}

.bg-black-8 {
    background-color: rgba(0, 0, 0, 0.8);
}

.bg-black-9 {
    background-color: rgba(0, 0, 0, 0.9);
}

.bg-indigo {
    background-color: #6610f2;
}

.bg-purple {
    background-color: #6f42c1;
}

.bg-pink {
    background-color: #e83e8c;
}

.bg-orange {
    background-color: #f27510;
}

.bg-teal {
    background-color: #1CAF9A;
}

.bg-br-primary {
    background-color: #1D2939;
}

.bg-lightsky {
    background-color: #E4F2F2;
}

.bg-mantle {
    background-image: linear-gradient(to right, #514A9D 0%, #24C6DC 100%);
    background-repeat: repeat-x;
}

.bg-reef {
    background-image: linear-gradient(to right, #3a7bd5 0%, #00d2ff 100%);
    background-repeat: repeat-x;
}

.bg-transfile {
    background-image: linear-gradient(to right, #CB3066 0%, #16BFFD 100%);
    background-repeat: repeat-x;
}

.bg-neon {
    background-image: linear-gradient(to right, #12FFF7 0%, #B3FFAB 100%);
    background-repeat: repeat-x;
}

.bg-delicate {
    background-image: linear-gradient(to right, #E9E4F0 0%, #D3CCE3 100%);
    background-repeat: repeat-x;
}

.bg-emerald {
    background-image: linear-gradient(to right, #56B4D3 0%, #348F50 100%);
    background-repeat: repeat-x;
}

.bg-mojito {
    background-image: linear-gradient(to right, #1D976C 0%, #93F9B9 100%);
    background-repeat: repeat-x;
}

.bg-dance {
    background-image: linear-gradient(to right, #F9D423 0%, #FF4E50 100%);
    background-repeat: repeat-x;
}

.bg-teal-love {
    background-image: linear-gradient(to right, #11FFBD 0%, #AAFFA9 100%);
    background-repeat: repeat-x;
}

.bg-crystal-clear {
    background-image: linear-gradient(to right, #155799 0%, #159957 100%);
    background-repeat: repeat-x;
}

.bg-grandeur {
    background-image: linear-gradient(to right, #000046 0%, #1CB5E0 100%);
    background-repeat: repeat-x;
}

.bg-flickr {
    background-image: linear-gradient(to right, #33001b 0%, #e20477 100%);
    background-repeat: repeat-x;
}

.bg-royal {
    background-image: linear-gradient(to right, #141E30 0%, #243B55 100%);
    background-repeat: repeat-x;
}

.bg-firewatch {
    background-image: linear-gradient(to right, #cb2d3e 0%, #ef473a 100%);
    background-repeat: repeat-x;
}

.bg-teal-info {
    background-image: linear-gradient(to right, #1CAF9A 0%, #17A2B8 100%);
    background-repeat: repeat-x;
}


/* ------------------------------------------ */


/* ############### 7.2 Border ############### */


/* ------------------------------------------ */

.bd-transparent {
    border-color: transparent;
}

.bd {
    border: 1px solid #ced4da;
}

.bd-t {
    border-top: 1px solid #ced4da;
}

.bd-r {
    border-right: 1px solid #ced4da;
}

.bd-b {
    border-bottom: 1px solid #ced4da;
}

.bd-l {
    border-left: 1px solid #ced4da;
}

.bd-y {
    border-top: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
}

.bd-x {
    border-left: 1px solid #ced4da;
    border-right: 1px solid #ced4da;
}

.bd-0 {
    border-width: 0;
}

.bd-1 {
    border-width: 1px;
}

.bd-2 {
    border-width: 2px;
}

.bd-3 {
    border-width: 3px;
}

.bd-4 {
    border-width: 4px;
}

.bd-5 {
    border-width: 5px;
}

.bd-t-0 {
    border-top: 0;
}

.bd-r-0 {
    border-right: 0;
}

.bd-b-0 {
    border-bottom: 0;
}

.bd-l-0 {
    border-left: 0;
}

.bd-t-0-force {
    border-top: 0 !important;
}

.bd-r-0-force {
    border-right: 0 !important;
}

.bd-b-0-force {
    border-bottom: 0 !important;
}

.bd-l-0-force {
    border-left: 0 !important;
}

.bd-y-0 {
    border-top-width: 0;
    border-bottom-width: 0;
}

.bd-y-1 {
    border-top-width: 1px;
    border-bottom-width: 1px;
}

.bd-x-0 {
    border-left-width: 0;
    border-right-width: 0;
}

.bd-x-1 {
    border-left-width: 1px;
    border-right-width: 1px;
}

.bd-primary {
    border-color: #0866C6;
}

.bd-success {
    border-color: #23BF08;
}

.bd-warning {
    border-color: #F49917;
}

.bd-danger {
    border-color: #DC3545;
}

.bd-info {
    border-color: #17A2B8;
}

.bd-white {
    border-color: #fff;
}

.bd-br-primary {
    border-color: #1D2939;
}

.bd-gray-100 {
    border-color: #f8f9fa;
}

.bd-gray-200 {
    border-color: #e9ecef;
}

.bd-gray-300 {
    border-color: #dee2e6;
}

.bd-gray-400 {
    border-color: #ced4da;
}

.bd-gray-500 {
    border-color: #adb5bd;
}

.bd-gray-600 {
    border-color: #6c757d;
}

.bd-gray-700 {
    border-color: #495057;
}

.bd-gray-800 {
    border-color: #343a40;
}

.bd-gray-900 {
    border-color: #212529;
}

.bd-white-1 {
    border-color: rgba(255, 255, 255, 0.1);
}

.bd-white-2 {
    border-color: rgba(255, 255, 255, 0.2);
}

.bd-white-3 {
    border-color: rgba(255, 255, 255, 0.3);
}

.bd-white-4 {
    border-color: rgba(255, 255, 255, 0.4);
}

.bd-white-5 {
    border-color: rgba(255, 255, 255, 0.5);
}

.bd-white-6 {
    border-color: rgba(255, 255, 255, 0.6);
}

.bd-white-7 {
    border-color: rgba(255, 255, 255, 0.7);
}

.bd-white-8 {
    border-color: rgba(255, 255, 255, 0.8);
}

.rounded-5 {
    border-radius: 5px;
}

.rounded-10 {
    border-radius: 10px;
}

.rounded-20 {
    border-radius: 20px;
}

.rounded-30 {
    border-radius: 30px;
}

.rounded-40 {
    border-radius: 40px;
}

.rounded-50 {
    border-radius: 50px;
}

.bd-dashed {
    border-style: dashed;
}

.bd-dotted {
    border-style: dotted;
}

@media (min-width: 480px) {
    .bd-xs {
        border: 1px solid #ced4da;
    }

    .bd-xs-t {
        border-top: 1px solid #ced4da;
    }

    .bd-xs-r {
        border-right: 1px solid #ced4da;
    }

    .bd-xs-b {
        border-bottom: 1px solid #ced4da;
    }

    .bd-xs-l {
        border-left: 1px solid #ced4da;
    }

    .bd-xs-y {
        border-top: 1px solid #ced4da;
        border-bottom: 1px solid #ced4da;
    }

    .bd-xs-x {
        border-left: 1px solid #ced4da;
        border-right: 1px solid #ced4da;
    }
}

@media (min-width: 576px) {
    .bd-sm {
        border: 1px solid #ced4da;
    }

    .bd-sm-t {
        border-top: 1px solid #ced4da;
    }

    .bd-sm-r {
        border-right: 1px solid #ced4da;
    }

    .bd-sm-b {
        border-bottom: 1px solid #ced4da;
    }

    .bd-sm-l {
        border-left: 1px solid #ced4da;
    }

    .bd-sm-y {
        border-top: 1px solid #ced4da;
        border-bottom: 1px solid #ced4da;
    }

    .bd-sm-x {
        border-left: 1px solid #ced4da;
        border-right: 1px solid #ced4da;
    }
}

@media (min-width: 768px) {
    .bd-md {
        border: 1px solid #ced4da;
    }

    .bd-md-t {
        border-top: 1px solid #ced4da;
    }

    .bd-md-r {
        border-right: 1px solid #ced4da;
    }

    .bd-md-b {
        border-bottom: 1px solid #ced4da;
    }

    .bd-md-l {
        border-left: 1px solid #ced4da;
    }

    .bd-md-y {
        border-top: 1px solid #ced4da;
        border-bottom: 1px solid #ced4da;
    }

    .bd-md-x {
        border-left: 1px solid #ced4da;
        border-right: 1px solid #ced4da;
    }
}

@media (min-width: 992px) {
    .bd-lg {
        border: 1px solid #ced4da;
    }

    .bd-lg-t {
        border-top: 1px solid #ced4da;
    }

    .bd-lg-r {
        border-right: 1px solid #ced4da;
    }

    .bd-lg-b {
        border-bottom: 1px solid #ced4da;
    }

    .bd-lg-l {
        border-left: 1px solid #ced4da;
    }

    .bd-lg-y {
        border-top: 1px solid #ced4da;
        border-bottom: 1px solid #ced4da;
    }

    .bd-lg-x {
        border-left: 1px solid #ced4da;
        border-right: 1px solid #ced4da;
    }
}

@media (min-width: 1200px) {
    .bd-xl {
        border: 1px solid #ced4da;
    }

    .bd-xl-t {
        border-top: 1px solid #ced4da;
    }

    .bd-xl-r {
        border-right: 1px solid #ced4da;
    }

    .bd-xl-b {
        border-bottom: 1px solid #ced4da;
    }

    .bd-xl-l {
        border-left: 1px solid #ced4da;
    }

    .bd-xl-y {
        border-top: 1px solid #ced4da;
        border-bottom: 1px solid #ced4da;
    }

    .bd-xl-x {
        border-left: 1px solid #ced4da;
        border-right: 1px solid #ced4da;
    }
}


/* ------------------------------------------ */


/* ############### 7.3 Height ############### */


/* ------------------------------------------ */

.ht-5 {
    height: 5px;
}

.ht-5p {
    height: 5%;
}

.mx-ht-5p {
    max-height: 5%;
}

.mn-ht-5p {
    min-height: 5%;
}

.ht-5-force {
    height: 5px !important;
}

.ht-5p-force {
    height: 5% !important;
}

.ht-10 {
    height: 10px;
}

.ht-10p {
    height: 10%;
}

.mx-ht-10p {
    max-height: 10%;
}

.mn-ht-10p {
    min-height: 10%;
}

.ht-10-force {
    height: 10px !important;
}

.ht-10p-force {
    height: 10% !important;
}

.ht-15 {
    height: 15px;
}

.ht-15p {
    height: 15%;
}

.mx-ht-15p {
    max-height: 15%;
}

.mn-ht-15p {
    min-height: 15%;
}

.ht-15-force {
    height: 15px !important;
}

.ht-15p-force {
    height: 15% !important;
}

.ht-20 {
    height: 20px;
}

.ht-20p {
    height: 20%;
}

.mx-ht-20p {
    max-height: 20%;
}

.mn-ht-20p {
    min-height: 20%;
}

.ht-20-force {
    height: 20px !important;
}

.ht-20p-force {
    height: 20% !important;
}

.ht-25 {
    height: 25px;
}

.ht-25p {
    height: 25%;
}

.mx-ht-25p {
    max-height: 25%;
}

.mn-ht-25p {
    min-height: 25%;
}

.ht-25-force {
    height: 25px !important;
}

.ht-25p-force {
    height: 25% !important;
}

.ht-30 {
    height: 30px;
}

.ht-30p {
    height: 30%;
}

.mx-ht-30p {
    max-height: 30%;
}

.mn-ht-30p {
    min-height: 30%;
}

.ht-30-force {
    height: 30px !important;
}

.ht-30p-force {
    height: 30% !important;
}

.ht-35 {
    height: 35px;
}

.ht-35p {
    height: 35%;
}

.mx-ht-35p {
    max-height: 35%;
}

.mn-ht-35p {
    min-height: 35%;
}

.ht-35-force {
    height: 35px !important;
}

.ht-35p-force {
    height: 35% !important;
}

.ht-40 {
    height: 40px;
}

.ht-40p {
    height: 40%;
}

.mx-ht-40p {
    max-height: 40%;
}

.mn-ht-40p {
    min-height: 40%;
}

.ht-40-force {
    height: 40px !important;
}

.ht-40p-force {
    height: 40% !important;
}

.ht-45 {
    height: 45px;
}

.ht-45p {
    height: 45%;
}

.mx-ht-45p {
    max-height: 45%;
}

.mn-ht-45p {
    min-height: 45%;
}

.ht-45-force {
    height: 45px !important;
}

.ht-45p-force {
    height: 45% !important;
}

.ht-50 {
    height: 50px;
}

.ht-50p {
    height: 50%;
}

.mx-ht-50p {
    max-height: 50%;
}

.mn-ht-50p {
    min-height: 50%;
}

.ht-50-force {
    height: 50px !important;
}

.ht-50p-force {
    height: 50% !important;
}

.ht-55 {
    height: 55px;
}

.ht-55p {
    height: 55%;
}

.mx-ht-55p {
    max-height: 55%;
}

.mn-ht-55p {
    min-height: 55%;
}

.ht-55-force {
    height: 55px !important;
}

.ht-55p-force {
    height: 55% !important;
}

.ht-60 {
    height: 60px;
}

.ht-60p {
    height: 60%;
}

.mx-ht-60p {
    max-height: 60%;
}

.mn-ht-60p {
    min-height: 60%;
}

.ht-60-force {
    height: 60px !important;
}

.ht-60p-force {
    height: 60% !important;
}

.ht-65 {
    height: 65px;
}

.ht-65p {
    height: 65%;
}

.mx-ht-65p {
    max-height: 65%;
}

.mn-ht-65p {
    min-height: 65%;
}

.ht-65-force {
    height: 65px !important;
}

.ht-65p-force {
    height: 65% !important;
}

.ht-70 {
    height: 70px;
}

.ht-70p {
    height: 70%;
}

.mx-ht-70p {
    max-height: 70%;
}

.mn-ht-70p {
    min-height: 70%;
}

.ht-70-force {
    height: 70px !important;
}

.ht-70p-force {
    height: 70% !important;
}

.ht-75 {
    height: 75px;
}

.ht-75p {
    height: 75%;
}

.mx-ht-75p {
    max-height: 75%;
}

.mn-ht-75p {
    min-height: 75%;
}

.ht-75-force {
    height: 75px !important;
}

.ht-75p-force {
    height: 75% !important;
}

.ht-80 {
    height: 80px;
}

.ht-80p {
    height: 80%;
}

.mx-ht-80p {
    max-height: 80%;
}

.mn-ht-80p {
    min-height: 80%;
}

.ht-80-force {
    height: 80px !important;
}

.ht-80p-force {
    height: 80% !important;
}

.ht-85 {
    height: 85px;
}

.ht-85p {
    height: 85%;
}

.mx-ht-85p {
    max-height: 85%;
}

.mn-ht-85p {
    min-height: 85%;
}

.ht-85-force {
    height: 85px !important;
}

.ht-85p-force {
    height: 85% !important;
}

.ht-90 {
    height: 90px;
}

.ht-90p {
    height: 90%;
}

.mx-ht-90p {
    max-height: 90%;
}

.mn-ht-90p {
    min-height: 90%;
}

.ht-90-force {
    height: 90px !important;
}

.ht-90p-force {
    height: 90% !important;
}

.ht-95 {
    height: 95px;
}

.ht-95p {
    height: 95%;
}

.mx-ht-95p {
    max-height: 95%;
}

.mn-ht-95p {
    min-height: 95%;
}

.ht-95-force {
    height: 95px !important;
}

.ht-95p-force {
    height: 95% !important;
}

.ht-100 {
    height: 100px;
}

.ht-100p {
    height: 100%;
}

.mx-ht-100p {
    max-height: 100%;
}

.mn-ht-100p {
    min-height: 100%;
}

.ht-100-force {
    height: 100px !important;
}

.ht-100p-force {
    height: 100% !important;
}

.ht-150 {
    height: 150px;
}

.ht-200 {
    height: 200px;
}

.ht-250 {
    height: 250px;
}

.ht-300 {
    height: 300px;
}

.ht-350 {
    height: 350px;
}

.ht-400 {
    height: 400px;
}

.ht-450 {
    height: 450px;
}

.ht-500 {
    height: 500px;
}

.ht-550 {
    height: 550px;
}

.ht-600 {
    height: 600px;
}

.ht-650 {
    height: 650px;
}

.ht-700 {
    height: 700px;
}

.ht-750 {
    height: 750px;
}

.ht-800 {
    height: 800px;
}

.ht-100v {
    height: 100vh;
}

.ht-1 {
    height: 1px;
}

.ht-2 {
    height: 2px;
}

.ht-3 {
    height: 3px;
}

.ht-4 {
    height: 4px;
}

.ht-6 {
    height: 6px;
}

.ht-7 {
    height: 7px;
}

.ht-8 {
    height: 8px;
}

.ht-9 {
    height: 9px;
}

.ht-auto {
    height: auto;
}

.ht-16 {
    height: 16px;
}

.ht-32 {
    height: 32px;
}

.ht-36 {
    height: 36px;
}

.ht-48 {
    height: 48px;
}

.ht-64 {
    height: 64px;
}

.ht-72 {
    height: 72px;
}

.ht-115 {
    height: 115px;
}

.ht-120 {
    height: 120px;
}

.ht-160 {
    height: 160px;
}

@media (min-width: 480px) {
    .ht-xs-5 {
        height: 5px;
    }

    .ht-xs-5p {
        height: 5%;
    }

    .mx-ht-xs-5p {
        max-height: 5%;
    }

    .mn-ht-xs-5p {
        min-height: 5%;
    }

    .ht-xs-5-force {
        height: 5px !important;
    }

    .ht-xs-5p-force {
        height: 5% !important;
    }

    .ht-xs-10 {
        height: 10px;
    }

    .ht-xs-10p {
        height: 10%;
    }

    .mx-ht-xs-10p {
        max-height: 10%;
    }

    .mn-ht-xs-10p {
        min-height: 10%;
    }

    .ht-xs-10-force {
        height: 10px !important;
    }

    .ht-xs-10p-force {
        height: 10% !important;
    }

    .ht-xs-15 {
        height: 15px;
    }

    .ht-xs-15p {
        height: 15%;
    }

    .mx-ht-xs-15p {
        max-height: 15%;
    }

    .mn-ht-xs-15p {
        min-height: 15%;
    }

    .ht-xs-15-force {
        height: 15px !important;
    }

    .ht-xs-15p-force {
        height: 15% !important;
    }

    .ht-xs-20 {
        height: 20px;
    }

    .ht-xs-20p {
        height: 20%;
    }

    .mx-ht-xs-20p {
        max-height: 20%;
    }

    .mn-ht-xs-20p {
        min-height: 20%;
    }

    .ht-xs-20-force {
        height: 20px !important;
    }

    .ht-xs-20p-force {
        height: 20% !important;
    }

    .ht-xs-25 {
        height: 25px;
    }

    .ht-xs-25p {
        height: 25%;
    }

    .mx-ht-xs-25p {
        max-height: 25%;
    }

    .mn-ht-xs-25p {
        min-height: 25%;
    }

    .ht-xs-25-force {
        height: 25px !important;
    }

    .ht-xs-25p-force {
        height: 25% !important;
    }

    .ht-xs-30 {
        height: 30px;
    }

    .ht-xs-30p {
        height: 30%;
    }

    .mx-ht-xs-30p {
        max-height: 30%;
    }

    .mn-ht-xs-30p {
        min-height: 30%;
    }

    .ht-xs-30-force {
        height: 30px !important;
    }

    .ht-xs-30p-force {
        height: 30% !important;
    }

    .ht-xs-35 {
        height: 35px;
    }

    .ht-xs-35p {
        height: 35%;
    }

    .mx-ht-xs-35p {
        max-height: 35%;
    }

    .mn-ht-xs-35p {
        min-height: 35%;
    }

    .ht-xs-35-force {
        height: 35px !important;
    }

    .ht-xs-35p-force {
        height: 35% !important;
    }

    .ht-xs-40 {
        height: 40px;
    }

    .ht-xs-40p {
        height: 40%;
    }

    .mx-ht-xs-40p {
        max-height: 40%;
    }

    .mn-ht-xs-40p {
        min-height: 40%;
    }

    .ht-xs-40-force {
        height: 40px !important;
    }

    .ht-xs-40p-force {
        height: 40% !important;
    }

    .ht-xs-45 {
        height: 45px;
    }

    .ht-xs-45p {
        height: 45%;
    }

    .mx-ht-xs-45p {
        max-height: 45%;
    }

    .mn-ht-xs-45p {
        min-height: 45%;
    }

    .ht-xs-45-force {
        height: 45px !important;
    }

    .ht-xs-45p-force {
        height: 45% !important;
    }

    .ht-xs-50 {
        height: 50px;
    }

    .ht-xs-50p {
        height: 50%;
    }

    .mx-ht-xs-50p {
        max-height: 50%;
    }

    .mn-ht-xs-50p {
        min-height: 50%;
    }

    .ht-xs-50-force {
        height: 50px !important;
    }

    .ht-xs-50p-force {
        height: 50% !important;
    }

    .ht-xs-55 {
        height: 55px;
    }

    .ht-xs-55p {
        height: 55%;
    }

    .mx-ht-xs-55p {
        max-height: 55%;
    }

    .mn-ht-xs-55p {
        min-height: 55%;
    }

    .ht-xs-55-force {
        height: 55px !important;
    }

    .ht-xs-55p-force {
        height: 55% !important;
    }

    .ht-xs-60 {
        height: 60px;
    }

    .ht-xs-60p {
        height: 60%;
    }

    .mx-ht-xs-60p {
        max-height: 60%;
    }

    .mn-ht-xs-60p {
        min-height: 60%;
    }

    .ht-xs-60-force {
        height: 60px !important;
    }

    .ht-xs-60p-force {
        height: 60% !important;
    }

    .ht-xs-65 {
        height: 65px;
    }

    .ht-xs-65p {
        height: 65%;
    }

    .mx-ht-xs-65p {
        max-height: 65%;
    }

    .mn-ht-xs-65p {
        min-height: 65%;
    }

    .ht-xs-65-force {
        height: 65px !important;
    }

    .ht-xs-65p-force {
        height: 65% !important;
    }

    .ht-xs-70 {
        height: 70px;
    }

    .ht-xs-70p {
        height: 70%;
    }

    .mx-ht-xs-70p {
        max-height: 70%;
    }

    .mn-ht-xs-70p {
        min-height: 70%;
    }

    .ht-xs-70-force {
        height: 70px !important;
    }

    .ht-xs-70p-force {
        height: 70% !important;
    }

    .ht-xs-75 {
        height: 75px;
    }

    .ht-xs-75p {
        height: 75%;
    }

    .mx-ht-xs-75p {
        max-height: 75%;
    }

    .mn-ht-xs-75p {
        min-height: 75%;
    }

    .ht-xs-75-force {
        height: 75px !important;
    }

    .ht-xs-75p-force {
        height: 75% !important;
    }

    .ht-xs-80 {
        height: 80px;
    }

    .ht-xs-80p {
        height: 80%;
    }

    .mx-ht-xs-80p {
        max-height: 80%;
    }

    .mn-ht-xs-80p {
        min-height: 80%;
    }

    .ht-xs-80-force {
        height: 80px !important;
    }

    .ht-xs-80p-force {
        height: 80% !important;
    }

    .ht-xs-85 {
        height: 85px;
    }

    .ht-xs-85p {
        height: 85%;
    }

    .mx-ht-xs-85p {
        max-height: 85%;
    }

    .mn-ht-xs-85p {
        min-height: 85%;
    }

    .ht-xs-85-force {
        height: 85px !important;
    }

    .ht-xs-85p-force {
        height: 85% !important;
    }

    .ht-xs-90 {
        height: 90px;
    }

    .ht-xs-90p {
        height: 90%;
    }

    .mx-ht-xs-90p {
        max-height: 90%;
    }

    .mn-ht-xs-90p {
        min-height: 90%;
    }

    .ht-xs-90-force {
        height: 90px !important;
    }

    .ht-xs-90p-force {
        height: 90% !important;
    }

    .ht-xs-95 {
        height: 95px;
    }

    .ht-xs-95p {
        height: 95%;
    }

    .mx-ht-xs-95p {
        max-height: 95%;
    }

    .mn-ht-xs-95p {
        min-height: 95%;
    }

    .ht-xs-95-force {
        height: 95px !important;
    }

    .ht-xs-95p-force {
        height: 95% !important;
    }

    .ht-xs-100 {
        height: 100px;
    }

    .ht-xs-100p {
        height: 100%;
    }

    .mx-ht-xs-100p {
        max-height: 100%;
    }

    .mn-ht-xs-100p {
        min-height: 100%;
    }

    .ht-xs-100-force {
        height: 100px !important;
    }

    .ht-xs-100p-force {
        height: 100% !important;
    }

    .ht-xs-150 {
        height: 150px;
    }

    .ht-xs-150p {
        height: 150%;
    }

    .mx-ht-xs-150p {
        max-height: 150%;
    }

    .mn-ht-xs-150p {
        min-height: 150%;
    }

    .ht-xs-150-force {
        height: 150px !important;
    }

    .ht-xs-150p-force {
        height: 150% !important;
    }

    .ht-xs-200 {
        height: 200px;
    }

    .ht-xs-200p {
        height: 200%;
    }

    .mx-ht-xs-200p {
        max-height: 200%;
    }

    .mn-ht-xs-200p {
        min-height: 200%;
    }

    .ht-xs-200-force {
        height: 200px !important;
    }

    .ht-xs-200p-force {
        height: 200% !important;
    }

    .ht-xs-250 {
        height: 250px;
    }

    .ht-xs-250p {
        height: 250%;
    }

    .mx-ht-xs-250p {
        max-height: 250%;
    }

    .mn-ht-xs-250p {
        min-height: 250%;
    }

    .ht-xs-250-force {
        height: 250px !important;
    }

    .ht-xs-250p-force {
        height: 250% !important;
    }

    .ht-xs-300 {
        height: 300px;
    }

    .ht-xs-300p {
        height: 300%;
    }

    .mx-ht-xs-300p {
        max-height: 300%;
    }

    .mn-ht-xs-300p {
        min-height: 300%;
    }

    .ht-xs-300-force {
        height: 300px !important;
    }

    .ht-xs-300p-force {
        height: 300% !important;
    }

    .ht-xs-350 {
        height: 350px;
    }

    .ht-xs-350p {
        height: 350%;
    }

    .mx-ht-xs-350p {
        max-height: 350%;
    }

    .mn-ht-xs-350p {
        min-height: 350%;
    }

    .ht-xs-350-force {
        height: 350px !important;
    }

    .ht-xs-350p-force {
        height: 350% !important;
    }

    .ht-xs-400 {
        height: 400px;
    }

    .ht-xs-400p {
        height: 400%;
    }

    .mx-ht-xs-400p {
        max-height: 400%;
    }

    .mn-ht-xs-400p {
        min-height: 400%;
    }

    .ht-xs-400-force {
        height: 400px !important;
    }

    .ht-xs-400p-force {
        height: 400% !important;
    }

    .ht-xs-450 {
        height: 450px;
    }

    .ht-xs-450p {
        height: 450%;
    }

    .mx-ht-xs-450p {
        max-height: 450%;
    }

    .mn-ht-xs-450p {
        min-height: 450%;
    }

    .ht-xs-450-force {
        height: 450px !important;
    }

    .ht-xs-450p-force {
        height: 450% !important;
    }

    .ht-xs-500 {
        height: 500px;
    }

    .ht-xs-500p {
        height: 500%;
    }

    .mx-ht-xs-500p {
        max-height: 500%;
    }

    .mn-ht-xs-500p {
        min-height: 500%;
    }

    .ht-xs-500-force {
        height: 500px !important;
    }

    .ht-xs-500p-force {
        height: 500% !important;
    }

    .ht-xs-550 {
        height: 550px;
    }

    .ht-xs-550p {
        height: 550%;
    }

    .mx-ht-xs-550p {
        max-height: 550%;
    }

    .mn-ht-xs-550p {
        min-height: 550%;
    }

    .ht-xs-550-force {
        height: 550px !important;
    }

    .ht-xs-550p-force {
        height: 550% !important;
    }

    .ht-xs-600 {
        height: 600px;
    }

    .ht-xs-600p {
        height: 600%;
    }

    .mx-ht-xs-600p {
        max-height: 600%;
    }

    .mn-ht-xs-600p {
        min-height: 600%;
    }

    .ht-xs-600-force {
        height: 600px !important;
    }

    .ht-xs-600p-force {
        height: 600% !important;
    }

    .ht-xs-650 {
        height: 650px;
    }

    .ht-xs-650p {
        height: 650%;
    }

    .mx-ht-xs-650p {
        max-height: 650%;
    }

    .mn-ht-xs-650p {
        min-height: 650%;
    }

    .ht-xs-650-force {
        height: 650px !important;
    }

    .ht-xs-650p-force {
        height: 650% !important;
    }

    .ht-xs-700 {
        height: 700px;
    }

    .ht-xs-700p {
        height: 700%;
    }

    .mx-ht-xs-700p {
        max-height: 700%;
    }

    .mn-ht-xs-700p {
        min-height: 700%;
    }

    .ht-xs-700-force {
        height: 700px !important;
    }

    .ht-xs-700p-force {
        height: 700% !important;
    }

    .ht-xs-750 {
        height: 750px;
    }

    .ht-xs-750p {
        height: 750%;
    }

    .mx-ht-xs-750p {
        max-height: 750%;
    }

    .mn-ht-xs-750p {
        min-height: 750%;
    }

    .ht-xs-750-force {
        height: 750px !important;
    }

    .ht-xs-750p-force {
        height: 750% !important;
    }

    .ht-xs-800 {
        height: 800px;
    }

    .ht-xs-800p {
        height: 800%;
    }

    .mx-ht-xs-800p {
        max-height: 800%;
    }

    .mn-ht-xs-800p {
        min-height: 800%;
    }

    .ht-xs-800-force {
        height: 800px !important;
    }

    .ht-xs-800p-force {
        height: 800% !important;
    }

    .ht-xs-850 {
        height: 850px;
    }

    .ht-xs-850p {
        height: 850%;
    }

    .mx-ht-xs-850p {
        max-height: 850%;
    }

    .mn-ht-xs-850p {
        min-height: 850%;
    }

    .ht-xs-850-force {
        height: 850px !important;
    }

    .ht-xs-850p-force {
        height: 850% !important;
    }

    .ht-xs-100v {
        height: 100vh;
    }
}

@media (min-width: 576px) {
    .ht-sm-5 {
        height: 5px;
    }

    .ht-sm-5p {
        height: 5%;
    }

    .mx-ht-sm-5p {
        max-height: 5%;
    }

    .mn-ht-sm-5p {
        min-height: 5%;
    }

    .ht-sm-5-force {
        height: 5px !important;
    }

    .ht-sm-5p-force {
        height: 5% !important;
    }

    .ht-sm-10 {
        height: 10px;
    }

    .ht-sm-10p {
        height: 10%;
    }

    .mx-ht-sm-10p {
        max-height: 10%;
    }

    .mn-ht-sm-10p {
        min-height: 10%;
    }

    .ht-sm-10-force {
        height: 10px !important;
    }

    .ht-sm-10p-force {
        height: 10% !important;
    }

    .ht-sm-15 {
        height: 15px;
    }

    .ht-sm-15p {
        height: 15%;
    }

    .mx-ht-sm-15p {
        max-height: 15%;
    }

    .mn-ht-sm-15p {
        min-height: 15%;
    }

    .ht-sm-15-force {
        height: 15px !important;
    }

    .ht-sm-15p-force {
        height: 15% !important;
    }

    .ht-sm-20 {
        height: 20px;
    }

    .ht-sm-20p {
        height: 20%;
    }

    .mx-ht-sm-20p {
        max-height: 20%;
    }

    .mn-ht-sm-20p {
        min-height: 20%;
    }

    .ht-sm-20-force {
        height: 20px !important;
    }

    .ht-sm-20p-force {
        height: 20% !important;
    }

    .ht-sm-25 {
        height: 25px;
    }

    .ht-sm-25p {
        height: 25%;
    }

    .mx-ht-sm-25p {
        max-height: 25%;
    }

    .mn-ht-sm-25p {
        min-height: 25%;
    }

    .ht-sm-25-force {
        height: 25px !important;
    }

    .ht-sm-25p-force {
        height: 25% !important;
    }

    .ht-sm-30 {
        height: 30px;
    }

    .ht-sm-30p {
        height: 30%;
    }

    .mx-ht-sm-30p {
        max-height: 30%;
    }

    .mn-ht-sm-30p {
        min-height: 30%;
    }

    .ht-sm-30-force {
        height: 30px !important;
    }

    .ht-sm-30p-force {
        height: 30% !important;
    }

    .ht-sm-35 {
        height: 35px;
    }

    .ht-sm-35p {
        height: 35%;
    }

    .mx-ht-sm-35p {
        max-height: 35%;
    }

    .mn-ht-sm-35p {
        min-height: 35%;
    }

    .ht-sm-35-force {
        height: 35px !important;
    }

    .ht-sm-35p-force {
        height: 35% !important;
    }

    .ht-sm-40 {
        height: 40px;
    }

    .ht-sm-40p {
        height: 40%;
    }

    .mx-ht-sm-40p {
        max-height: 40%;
    }

    .mn-ht-sm-40p {
        min-height: 40%;
    }

    .ht-sm-40-force {
        height: 40px !important;
    }

    .ht-sm-40p-force {
        height: 40% !important;
    }

    .ht-sm-45 {
        height: 45px;
    }

    .ht-sm-45p {
        height: 45%;
    }

    .mx-ht-sm-45p {
        max-height: 45%;
    }

    .mn-ht-sm-45p {
        min-height: 45%;
    }

    .ht-sm-45-force {
        height: 45px !important;
    }

    .ht-sm-45p-force {
        height: 45% !important;
    }

    .ht-sm-50 {
        height: 50px;
    }

    .ht-sm-50p {
        height: 50%;
    }

    .mx-ht-sm-50p {
        max-height: 50%;
    }

    .mn-ht-sm-50p {
        min-height: 50%;
    }

    .ht-sm-50-force {
        height: 50px !important;
    }

    .ht-sm-50p-force {
        height: 50% !important;
    }

    .ht-sm-55 {
        height: 55px;
    }

    .ht-sm-55p {
        height: 55%;
    }

    .mx-ht-sm-55p {
        max-height: 55%;
    }

    .mn-ht-sm-55p {
        min-height: 55%;
    }

    .ht-sm-55-force {
        height: 55px !important;
    }

    .ht-sm-55p-force {
        height: 55% !important;
    }

    .ht-sm-60 {
        height: 60px;
    }

    .ht-sm-60p {
        height: 60%;
    }

    .mx-ht-sm-60p {
        max-height: 60%;
    }

    .mn-ht-sm-60p {
        min-height: 60%;
    }

    .ht-sm-60-force {
        height: 60px !important;
    }

    .ht-sm-60p-force {
        height: 60% !important;
    }

    .ht-sm-65 {
        height: 65px;
    }

    .ht-sm-65p {
        height: 65%;
    }

    .mx-ht-sm-65p {
        max-height: 65%;
    }

    .mn-ht-sm-65p {
        min-height: 65%;
    }

    .ht-sm-65-force {
        height: 65px !important;
    }

    .ht-sm-65p-force {
        height: 65% !important;
    }

    .ht-sm-70 {
        height: 70px;
    }

    .ht-sm-70p {
        height: 70%;
    }

    .mx-ht-sm-70p {
        max-height: 70%;
    }

    .mn-ht-sm-70p {
        min-height: 70%;
    }

    .ht-sm-70-force {
        height: 70px !important;
    }

    .ht-sm-70p-force {
        height: 70% !important;
    }

    .ht-sm-75 {
        height: 75px;
    }

    .ht-sm-75p {
        height: 75%;
    }

    .mx-ht-sm-75p {
        max-height: 75%;
    }

    .mn-ht-sm-75p {
        min-height: 75%;
    }

    .ht-sm-75-force {
        height: 75px !important;
    }

    .ht-sm-75p-force {
        height: 75% !important;
    }

    .ht-sm-80 {
        height: 80px;
    }

    .ht-sm-80p {
        height: 80%;
    }

    .mx-ht-sm-80p {
        max-height: 80%;
    }

    .mn-ht-sm-80p {
        min-height: 80%;
    }

    .ht-sm-80-force {
        height: 80px !important;
    }

    .ht-sm-80p-force {
        height: 80% !important;
    }

    .ht-sm-85 {
        height: 85px;
    }

    .ht-sm-85p {
        height: 85%;
    }

    .mx-ht-sm-85p {
        max-height: 85%;
    }

    .mn-ht-sm-85p {
        min-height: 85%;
    }

    .ht-sm-85-force {
        height: 85px !important;
    }

    .ht-sm-85p-force {
        height: 85% !important;
    }

    .ht-sm-90 {
        height: 90px;
    }

    .ht-sm-90p {
        height: 90%;
    }

    .mx-ht-sm-90p {
        max-height: 90%;
    }

    .mn-ht-sm-90p {
        min-height: 90%;
    }

    .ht-sm-90-force {
        height: 90px !important;
    }

    .ht-sm-90p-force {
        height: 90% !important;
    }

    .ht-sm-95 {
        height: 95px;
    }

    .ht-sm-95p {
        height: 95%;
    }

    .mx-ht-sm-95p {
        max-height: 95%;
    }

    .mn-ht-sm-95p {
        min-height: 95%;
    }

    .ht-sm-95-force {
        height: 95px !important;
    }

    .ht-sm-95p-force {
        height: 95% !important;
    }

    .ht-sm-100 {
        height: 100px;
    }

    .ht-sm-100p {
        height: 100%;
    }

    .mx-ht-sm-100p {
        max-height: 100%;
    }

    .mn-ht-sm-100p {
        min-height: 100%;
    }

    .ht-sm-100-force {
        height: 100px !important;
    }

    .ht-sm-100p-force {
        height: 100% !important;
    }

    .ht-sm-150 {
        height: 150px;
    }

    .ht-sm-150p {
        height: 150%;
    }

    .mx-ht-sm-150p {
        max-height: 150%;
    }

    .mn-ht-sm-150p {
        min-height: 150%;
    }

    .ht-sm-150-force {
        height: 150px !important;
    }

    .ht-sm-150p-force {
        height: 150% !important;
    }

    .ht-sm-200 {
        height: 200px;
    }

    .ht-sm-200p {
        height: 200%;
    }

    .mx-ht-sm-200p {
        max-height: 200%;
    }

    .mn-ht-sm-200p {
        min-height: 200%;
    }

    .ht-sm-200-force {
        height: 200px !important;
    }

    .ht-sm-200p-force {
        height: 200% !important;
    }

    .ht-sm-250 {
        height: 250px;
    }

    .ht-sm-250p {
        height: 250%;
    }

    .mx-ht-sm-250p {
        max-height: 250%;
    }

    .mn-ht-sm-250p {
        min-height: 250%;
    }

    .ht-sm-250-force {
        height: 250px !important;
    }

    .ht-sm-250p-force {
        height: 250% !important;
    }

    .ht-sm-300 {
        height: 300px;
    }

    .ht-sm-300p {
        height: 300%;
    }

    .mx-ht-sm-300p {
        max-height: 300%;
    }

    .mn-ht-sm-300p {
        min-height: 300%;
    }

    .ht-sm-300-force {
        height: 300px !important;
    }

    .ht-sm-300p-force {
        height: 300% !important;
    }

    .ht-sm-350 {
        height: 350px;
    }

    .ht-sm-350p {
        height: 350%;
    }

    .mx-ht-sm-350p {
        max-height: 350%;
    }

    .mn-ht-sm-350p {
        min-height: 350%;
    }

    .ht-sm-350-force {
        height: 350px !important;
    }

    .ht-sm-350p-force {
        height: 350% !important;
    }

    .ht-sm-400 {
        height: 400px;
    }

    .ht-sm-400p {
        height: 400%;
    }

    .mx-ht-sm-400p {
        max-height: 400%;
    }

    .mn-ht-sm-400p {
        min-height: 400%;
    }

    .ht-sm-400-force {
        height: 400px !important;
    }

    .ht-sm-400p-force {
        height: 400% !important;
    }

    .ht-sm-450 {
        height: 450px;
    }

    .ht-sm-450p {
        height: 450%;
    }

    .mx-ht-sm-450p {
        max-height: 450%;
    }

    .mn-ht-sm-450p {
        min-height: 450%;
    }

    .ht-sm-450-force {
        height: 450px !important;
    }

    .ht-sm-450p-force {
        height: 450% !important;
    }

    .ht-sm-500 {
        height: 500px;
    }

    .ht-sm-500p {
        height: 500%;
    }

    .mx-ht-sm-500p {
        max-height: 500%;
    }

    .mn-ht-sm-500p {
        min-height: 500%;
    }

    .ht-sm-500-force {
        height: 500px !important;
    }

    .ht-sm-500p-force {
        height: 500% !important;
    }

    .ht-sm-550 {
        height: 550px;
    }

    .ht-sm-550p {
        height: 550%;
    }

    .mx-ht-sm-550p {
        max-height: 550%;
    }

    .mn-ht-sm-550p {
        min-height: 550%;
    }

    .ht-sm-550-force {
        height: 550px !important;
    }

    .ht-sm-550p-force {
        height: 550% !important;
    }

    .ht-sm-600 {
        height: 600px;
    }

    .ht-sm-600p {
        height: 600%;
    }

    .mx-ht-sm-600p {
        max-height: 600%;
    }

    .mn-ht-sm-600p {
        min-height: 600%;
    }

    .ht-sm-600-force {
        height: 600px !important;
    }

    .ht-sm-600p-force {
        height: 600% !important;
    }

    .ht-sm-650 {
        height: 650px;
    }

    .ht-sm-650p {
        height: 650%;
    }

    .mx-ht-sm-650p {
        max-height: 650%;
    }

    .mn-ht-sm-650p {
        min-height: 650%;
    }

    .ht-sm-650-force {
        height: 650px !important;
    }

    .ht-sm-650p-force {
        height: 650% !important;
    }

    .ht-sm-700 {
        height: 700px;
    }

    .ht-sm-700p {
        height: 700%;
    }

    .mx-ht-sm-700p {
        max-height: 700%;
    }

    .mn-ht-sm-700p {
        min-height: 700%;
    }

    .ht-sm-700-force {
        height: 700px !important;
    }

    .ht-sm-700p-force {
        height: 700% !important;
    }

    .ht-sm-750 {
        height: 750px;
    }

    .ht-sm-750p {
        height: 750%;
    }

    .mx-ht-sm-750p {
        max-height: 750%;
    }

    .mn-ht-sm-750p {
        min-height: 750%;
    }

    .ht-sm-750-force {
        height: 750px !important;
    }

    .ht-sm-750p-force {
        height: 750% !important;
    }

    .ht-sm-800 {
        height: 800px;
    }

    .ht-sm-800p {
        height: 800%;
    }

    .mx-ht-sm-800p {
        max-height: 800%;
    }

    .mn-ht-sm-800p {
        min-height: 800%;
    }

    .ht-sm-800-force {
        height: 800px !important;
    }

    .ht-sm-800p-force {
        height: 800% !important;
    }

    .ht-sm-850 {
        height: 850px;
    }

    .ht-sm-850p {
        height: 850%;
    }

    .mx-ht-sm-850p {
        max-height: 850%;
    }

    .mn-ht-sm-850p {
        min-height: 850%;
    }

    .ht-sm-850-force {
        height: 850px !important;
    }

    .ht-sm-850p-force {
        height: 850% !important;
    }

    .ht-sm-100v {
        height: 100vh;
    }
}

@media (min-width: 768px) {
    .ht-md-5 {
        height: 5px;
    }

    .ht-md-5p {
        height: 5%;
    }

    .mx-ht-md-5p {
        max-height: 5%;
    }

    .mn-ht-md-5p {
        min-height: 5%;
    }

    .ht-md-5-force {
        height: 5px !important;
    }

    .ht-md-5p-force {
        height: 5% !important;
    }

    .ht-md-10 {
        height: 10px;
    }

    .ht-md-10p {
        height: 10%;
    }

    .mx-ht-md-10p {
        max-height: 10%;
    }

    .mn-ht-md-10p {
        min-height: 10%;
    }

    .ht-md-10-force {
        height: 10px !important;
    }

    .ht-md-10p-force {
        height: 10% !important;
    }

    .ht-md-15 {
        height: 15px;
    }

    .ht-md-15p {
        height: 15%;
    }

    .mx-ht-md-15p {
        max-height: 15%;
    }

    .mn-ht-md-15p {
        min-height: 15%;
    }

    .ht-md-15-force {
        height: 15px !important;
    }

    .ht-md-15p-force {
        height: 15% !important;
    }

    .ht-md-20 {
        height: 20px;
    }

    .ht-md-20p {
        height: 20%;
    }

    .mx-ht-md-20p {
        max-height: 20%;
    }

    .mn-ht-md-20p {
        min-height: 20%;
    }

    .ht-md-20-force {
        height: 20px !important;
    }

    .ht-md-20p-force {
        height: 20% !important;
    }

    .ht-md-25 {
        height: 25px;
    }

    .ht-md-25p {
        height: 25%;
    }

    .mx-ht-md-25p {
        max-height: 25%;
    }

    .mn-ht-md-25p {
        min-height: 25%;
    }

    .ht-md-25-force {
        height: 25px !important;
    }

    .ht-md-25p-force {
        height: 25% !important;
    }

    .ht-md-30 {
        height: 30px;
    }

    .ht-md-30p {
        height: 30%;
    }

    .mx-ht-md-30p {
        max-height: 30%;
    }

    .mn-ht-md-30p {
        min-height: 30%;
    }

    .ht-md-30-force {
        height: 30px !important;
    }

    .ht-md-30p-force {
        height: 30% !important;
    }

    .ht-md-35 {
        height: 35px;
    }

    .ht-md-35p {
        height: 35%;
    }

    .mx-ht-md-35p {
        max-height: 35%;
    }

    .mn-ht-md-35p {
        min-height: 35%;
    }

    .ht-md-35-force {
        height: 35px !important;
    }

    .ht-md-35p-force {
        height: 35% !important;
    }

    .ht-md-40 {
        height: 40px;
    }

    .ht-md-40p {
        height: 40%;
    }

    .mx-ht-md-40p {
        max-height: 40%;
    }

    .mn-ht-md-40p {
        min-height: 40%;
    }

    .ht-md-40-force {
        height: 40px !important;
    }

    .ht-md-40p-force {
        height: 40% !important;
    }

    .ht-md-45 {
        height: 45px;
    }

    .ht-md-45p {
        height: 45%;
    }

    .mx-ht-md-45p {
        max-height: 45%;
    }

    .mn-ht-md-45p {
        min-height: 45%;
    }

    .ht-md-45-force {
        height: 45px !important;
    }

    .ht-md-45p-force {
        height: 45% !important;
    }

    .ht-md-50 {
        height: 50px;
    }

    .ht-md-50p {
        height: 50%;
    }

    .mx-ht-md-50p {
        max-height: 50%;
    }

    .mn-ht-md-50p {
        min-height: 50%;
    }

    .ht-md-50-force {
        height: 50px !important;
    }

    .ht-md-50p-force {
        height: 50% !important;
    }

    .ht-md-55 {
        height: 55px;
    }

    .ht-md-55p {
        height: 55%;
    }

    .mx-ht-md-55p {
        max-height: 55%;
    }

    .mn-ht-md-55p {
        min-height: 55%;
    }

    .ht-md-55-force {
        height: 55px !important;
    }

    .ht-md-55p-force {
        height: 55% !important;
    }

    .ht-md-60 {
        height: 60px;
    }

    .ht-md-60p {
        height: 60%;
    }

    .mx-ht-md-60p {
        max-height: 60%;
    }

    .mn-ht-md-60p {
        min-height: 60%;
    }

    .ht-md-60-force {
        height: 60px !important;
    }

    .ht-md-60p-force {
        height: 60% !important;
    }

    .ht-md-65 {
        height: 65px;
    }

    .ht-md-65p {
        height: 65%;
    }

    .mx-ht-md-65p {
        max-height: 65%;
    }

    .mn-ht-md-65p {
        min-height: 65%;
    }

    .ht-md-65-force {
        height: 65px !important;
    }

    .ht-md-65p-force {
        height: 65% !important;
    }

    .ht-md-70 {
        height: 70px;
    }

    .ht-md-70p {
        height: 70%;
    }

    .mx-ht-md-70p {
        max-height: 70%;
    }

    .mn-ht-md-70p {
        min-height: 70%;
    }

    .ht-md-70-force {
        height: 70px !important;
    }

    .ht-md-70p-force {
        height: 70% !important;
    }

    .ht-md-75 {
        height: 75px;
    }

    .ht-md-75p {
        height: 75%;
    }

    .mx-ht-md-75p {
        max-height: 75%;
    }

    .mn-ht-md-75p {
        min-height: 75%;
    }

    .ht-md-75-force {
        height: 75px !important;
    }

    .ht-md-75p-force {
        height: 75% !important;
    }

    .ht-md-80 {
        height: 80px;
    }

    .ht-md-80p {
        height: 80%;
    }

    .mx-ht-md-80p {
        max-height: 80%;
    }

    .mn-ht-md-80p {
        min-height: 80%;
    }

    .ht-md-80-force {
        height: 80px !important;
    }

    .ht-md-80p-force {
        height: 80% !important;
    }

    .ht-md-85 {
        height: 85px;
    }

    .ht-md-85p {
        height: 85%;
    }

    .mx-ht-md-85p {
        max-height: 85%;
    }

    .mn-ht-md-85p {
        min-height: 85%;
    }

    .ht-md-85-force {
        height: 85px !important;
    }

    .ht-md-85p-force {
        height: 85% !important;
    }

    .ht-md-90 {
        height: 90px;
    }

    .ht-md-90p {
        height: 90%;
    }

    .mx-ht-md-90p {
        max-height: 90%;
    }

    .mn-ht-md-90p {
        min-height: 90%;
    }

    .ht-md-90-force {
        height: 90px !important;
    }

    .ht-md-90p-force {
        height: 90% !important;
    }

    .ht-md-95 {
        height: 95px;
    }

    .ht-md-95p {
        height: 95%;
    }

    .mx-ht-md-95p {
        max-height: 95%;
    }

    .mn-ht-md-95p {
        min-height: 95%;
    }

    .ht-md-95-force {
        height: 95px !important;
    }

    .ht-md-95p-force {
        height: 95% !important;
    }

    .ht-md-100 {
        height: 100px;
    }

    .ht-md-100p {
        height: 100%;
    }

    .mx-ht-md-100p {
        max-height: 100%;
    }

    .mn-ht-md-100p {
        min-height: 100%;
    }

    .ht-md-100-force {
        height: 100px !important;
    }

    .ht-md-100p-force {
        height: 100% !important;
    }

    .ht-md-150 {
        height: 150px;
    }

    .ht-md-150p {
        height: 150%;
    }

    .mx-ht-md-150p {
        max-height: 150%;
    }

    .mn-ht-md-150p {
        min-height: 150%;
    }

    .ht-md-150-force {
        height: 150px !important;
    }

    .ht-md-150p-force {
        height: 150% !important;
    }

    .ht-md-200 {
        height: 200px;
    }

    .ht-md-200p {
        height: 200%;
    }

    .mx-ht-md-200p {
        max-height: 200%;
    }

    .mn-ht-md-200p {
        min-height: 200%;
    }

    .ht-md-200-force {
        height: 200px !important;
    }

    .ht-md-200p-force {
        height: 200% !important;
    }

    .ht-md-250 {
        height: 250px;
    }

    .ht-md-250p {
        height: 250%;
    }

    .mx-ht-md-250p {
        max-height: 250%;
    }

    .mn-ht-md-250p {
        min-height: 250%;
    }

    .ht-md-250-force {
        height: 250px !important;
    }

    .ht-md-250p-force {
        height: 250% !important;
    }

    .ht-md-300 {
        height: 300px;
    }

    .ht-md-300p {
        height: 300%;
    }

    .mx-ht-md-300p {
        max-height: 300%;
    }

    .mn-ht-md-300p {
        min-height: 300%;
    }

    .ht-md-300-force {
        height: 300px !important;
    }

    .ht-md-300p-force {
        height: 300% !important;
    }

    .ht-md-350 {
        height: 350px;
    }

    .ht-md-350p {
        height: 350%;
    }

    .mx-ht-md-350p {
        max-height: 350%;
    }

    .mn-ht-md-350p {
        min-height: 350%;
    }

    .ht-md-350-force {
        height: 350px !important;
    }

    .ht-md-350p-force {
        height: 350% !important;
    }

    .ht-md-400 {
        height: 400px;
    }

    .ht-md-400p {
        height: 400%;
    }

    .mx-ht-md-400p {
        max-height: 400%;
    }

    .mn-ht-md-400p {
        min-height: 400%;
    }

    .ht-md-400-force {
        height: 400px !important;
    }

    .ht-md-400p-force {
        height: 400% !important;
    }

    .ht-md-450 {
        height: 450px;
    }

    .ht-md-450p {
        height: 450%;
    }

    .mx-ht-md-450p {
        max-height: 450%;
    }

    .mn-ht-md-450p {
        min-height: 450%;
    }

    .ht-md-450-force {
        height: 450px !important;
    }

    .ht-md-450p-force {
        height: 450% !important;
    }

    .ht-md-500 {
        height: 500px;
    }

    .ht-md-500p {
        height: 500%;
    }

    .mx-ht-md-500p {
        max-height: 500%;
    }

    .mn-ht-md-500p {
        min-height: 500%;
    }

    .ht-md-500-force {
        height: 500px !important;
    }

    .ht-md-500p-force {
        height: 500% !important;
    }

    .ht-md-550 {
        height: 550px;
    }

    .ht-md-550p {
        height: 550%;
    }

    .mx-ht-md-550p {
        max-height: 550%;
    }

    .mn-ht-md-550p {
        min-height: 550%;
    }

    .ht-md-550-force {
        height: 550px !important;
    }

    .ht-md-550p-force {
        height: 550% !important;
    }

    .ht-md-600 {
        height: 600px;
    }

    .ht-md-600p {
        height: 600%;
    }

    .mx-ht-md-600p {
        max-height: 600%;
    }

    .mn-ht-md-600p {
        min-height: 600%;
    }

    .ht-md-600-force {
        height: 600px !important;
    }

    .ht-md-600p-force {
        height: 600% !important;
    }

    .ht-md-650 {
        height: 650px;
    }

    .ht-md-650p {
        height: 650%;
    }

    .mx-ht-md-650p {
        max-height: 650%;
    }

    .mn-ht-md-650p {
        min-height: 650%;
    }

    .ht-md-650-force {
        height: 650px !important;
    }

    .ht-md-650p-force {
        height: 650% !important;
    }

    .ht-md-700 {
        height: 700px;
    }

    .ht-md-700p {
        height: 700%;
    }

    .mx-ht-md-700p {
        max-height: 700%;
    }

    .mn-ht-md-700p {
        min-height: 700%;
    }

    .ht-md-700-force {
        height: 700px !important;
    }

    .ht-md-700p-force {
        height: 700% !important;
    }

    .ht-md-750 {
        height: 750px;
    }

    .ht-md-750p {
        height: 750%;
    }

    .mx-ht-md-750p {
        max-height: 750%;
    }

    .mn-ht-md-750p {
        min-height: 750%;
    }

    .ht-md-750-force {
        height: 750px !important;
    }

    .ht-md-750p-force {
        height: 750% !important;
    }

    .ht-md-800 {
        height: 800px;
    }

    .ht-md-800p {
        height: 800%;
    }

    .mx-ht-md-800p {
        max-height: 800%;
    }

    .mn-ht-md-800p {
        min-height: 800%;
    }

    .ht-md-800-force {
        height: 800px !important;
    }

    .ht-md-800p-force {
        height: 800% !important;
    }

    .ht-md-850 {
        height: 850px;
    }

    .ht-md-850p {
        height: 850%;
    }

    .mx-ht-md-850p {
        max-height: 850%;
    }

    .mn-ht-md-850p {
        min-height: 850%;
    }

    .ht-md-850-force {
        height: 850px !important;
    }

    .ht-md-850p-force {
        height: 850% !important;
    }

    .ht-md-100v {
        height: 100vh;
    }
}

@media (min-width: 992px) {
    .ht-lg-5 {
        height: 5px;
    }

    .ht-lg-5p {
        height: 5%;
    }

    .mx-ht-lg-5p {
        max-height: 5%;
    }

    .mn-ht-lg-5p {
        min-height: 5%;
    }

    .ht-lg-5-force {
        height: 5px !important;
    }

    .ht-lg-5p-force {
        height: 5% !important;
    }

    .ht-lg-10 {
        height: 10px;
    }

    .ht-lg-10p {
        height: 10%;
    }

    .mx-ht-lg-10p {
        max-height: 10%;
    }

    .mn-ht-lg-10p {
        min-height: 10%;
    }

    .ht-lg-10-force {
        height: 10px !important;
    }

    .ht-lg-10p-force {
        height: 10% !important;
    }

    .ht-lg-15 {
        height: 15px;
    }

    .ht-lg-15p {
        height: 15%;
    }

    .mx-ht-lg-15p {
        max-height: 15%;
    }

    .mn-ht-lg-15p {
        min-height: 15%;
    }

    .ht-lg-15-force {
        height: 15px !important;
    }

    .ht-lg-15p-force {
        height: 15% !important;
    }

    .ht-lg-20 {
        height: 20px;
    }

    .ht-lg-20p {
        height: 20%;
    }

    .mx-ht-lg-20p {
        max-height: 20%;
    }

    .mn-ht-lg-20p {
        min-height: 20%;
    }

    .ht-lg-20-force {
        height: 20px !important;
    }

    .ht-lg-20p-force {
        height: 20% !important;
    }

    .ht-lg-25 {
        height: 25px;
    }

    .ht-lg-25p {
        height: 25%;
    }

    .mx-ht-lg-25p {
        max-height: 25%;
    }

    .mn-ht-lg-25p {
        min-height: 25%;
    }

    .ht-lg-25-force {
        height: 25px !important;
    }

    .ht-lg-25p-force {
        height: 25% !important;
    }

    .ht-lg-30 {
        height: 30px;
    }

    .ht-lg-30p {
        height: 30%;
    }

    .mx-ht-lg-30p {
        max-height: 30%;
    }

    .mn-ht-lg-30p {
        min-height: 30%;
    }

    .ht-lg-30-force {
        height: 30px !important;
    }

    .ht-lg-30p-force {
        height: 30% !important;
    }

    .ht-lg-35 {
        height: 35px;
    }

    .ht-lg-35p {
        height: 35%;
    }

    .mx-ht-lg-35p {
        max-height: 35%;
    }

    .mn-ht-lg-35p {
        min-height: 35%;
    }

    .ht-lg-35-force {
        height: 35px !important;
    }

    .ht-lg-35p-force {
        height: 35% !important;
    }

    .ht-lg-40 {
        height: 40px;
    }

    .ht-lg-40p {
        height: 40%;
    }

    .mx-ht-lg-40p {
        max-height: 40%;
    }

    .mn-ht-lg-40p {
        min-height: 40%;
    }

    .ht-lg-40-force {
        height: 40px !important;
    }

    .ht-lg-40p-force {
        height: 40% !important;
    }

    .ht-lg-45 {
        height: 45px;
    }

    .ht-lg-45p {
        height: 45%;
    }

    .mx-ht-lg-45p {
        max-height: 45%;
    }

    .mn-ht-lg-45p {
        min-height: 45%;
    }

    .ht-lg-45-force {
        height: 45px !important;
    }

    .ht-lg-45p-force {
        height: 45% !important;
    }

    .ht-lg-50 {
        height: 50px;
    }

    .ht-lg-50p {
        height: 50%;
    }

    .mx-ht-lg-50p {
        max-height: 50%;
    }

    .mn-ht-lg-50p {
        min-height: 50%;
    }

    .ht-lg-50-force {
        height: 50px !important;
    }

    .ht-lg-50p-force {
        height: 50% !important;
    }

    .ht-lg-55 {
        height: 55px;
    }

    .ht-lg-55p {
        height: 55%;
    }

    .mx-ht-lg-55p {
        max-height: 55%;
    }

    .mn-ht-lg-55p {
        min-height: 55%;
    }

    .ht-lg-55-force {
        height: 55px !important;
    }

    .ht-lg-55p-force {
        height: 55% !important;
    }

    .ht-lg-60 {
        height: 60px;
    }

    .ht-lg-60p {
        height: 60%;
    }

    .mx-ht-lg-60p {
        max-height: 60%;
    }

    .mn-ht-lg-60p {
        min-height: 60%;
    }

    .ht-lg-60-force {
        height: 60px !important;
    }

    .ht-lg-60p-force {
        height: 60% !important;
    }

    .ht-lg-65 {
        height: 65px;
    }

    .ht-lg-65p {
        height: 65%;
    }

    .mx-ht-lg-65p {
        max-height: 65%;
    }

    .mn-ht-lg-65p {
        min-height: 65%;
    }

    .ht-lg-65-force {
        height: 65px !important;
    }

    .ht-lg-65p-force {
        height: 65% !important;
    }

    .ht-lg-70 {
        height: 70px;
    }

    .ht-lg-70p {
        height: 70%;
    }

    .mx-ht-lg-70p {
        max-height: 70%;
    }

    .mn-ht-lg-70p {
        min-height: 70%;
    }

    .ht-lg-70-force {
        height: 70px !important;
    }

    .ht-lg-70p-force {
        height: 70% !important;
    }

    .ht-lg-75 {
        height: 75px;
    }

    .ht-lg-75p {
        height: 75%;
    }

    .mx-ht-lg-75p {
        max-height: 75%;
    }

    .mn-ht-lg-75p {
        min-height: 75%;
    }

    .ht-lg-75-force {
        height: 75px !important;
    }

    .ht-lg-75p-force {
        height: 75% !important;
    }

    .ht-lg-80 {
        height: 80px;
    }

    .ht-lg-80p {
        height: 80%;
    }

    .mx-ht-lg-80p {
        max-height: 80%;
    }

    .mn-ht-lg-80p {
        min-height: 80%;
    }

    .ht-lg-80-force {
        height: 80px !important;
    }

    .ht-lg-80p-force {
        height: 80% !important;
    }

    .ht-lg-85 {
        height: 85px;
    }

    .ht-lg-85p {
        height: 85%;
    }

    .mx-ht-lg-85p {
        max-height: 85%;
    }

    .mn-ht-lg-85p {
        min-height: 85%;
    }

    .ht-lg-85-force {
        height: 85px !important;
    }

    .ht-lg-85p-force {
        height: 85% !important;
    }

    .ht-lg-90 {
        height: 90px;
    }

    .ht-lg-90p {
        height: 90%;
    }

    .mx-ht-lg-90p {
        max-height: 90%;
    }

    .mn-ht-lg-90p {
        min-height: 90%;
    }

    .ht-lg-90-force {
        height: 90px !important;
    }

    .ht-lg-90p-force {
        height: 90% !important;
    }

    .ht-lg-95 {
        height: 95px;
    }

    .ht-lg-95p {
        height: 95%;
    }

    .mx-ht-lg-95p {
        max-height: 95%;
    }

    .mn-ht-lg-95p {
        min-height: 95%;
    }

    .ht-lg-95-force {
        height: 95px !important;
    }

    .ht-lg-95p-force {
        height: 95% !important;
    }

    .ht-lg-100 {
        height: 100px;
    }

    .ht-lg-100p {
        height: 100%;
    }

    .mx-ht-lg-100p {
        max-height: 100%;
    }

    .mn-ht-lg-100p {
        min-height: 100%;
    }

    .ht-lg-100-force {
        height: 100px !important;
    }

    .ht-lg-100p-force {
        height: 100% !important;
    }

    .ht-lg-150 {
        height: 150px;
    }

    .ht-lg-150p {
        height: 150%;
    }

    .mx-ht-lg-150p {
        max-height: 150%;
    }

    .mn-ht-lg-150p {
        min-height: 150%;
    }

    .ht-lg-150-force {
        height: 150px !important;
    }

    .ht-lg-150p-force {
        height: 150% !important;
    }

    .ht-lg-200 {
        height: 200px;
    }

    .ht-lg-200p {
        height: 200%;
    }

    .mx-ht-lg-200p {
        max-height: 200%;
    }

    .mn-ht-lg-200p {
        min-height: 200%;
    }

    .ht-lg-200-force {
        height: 200px !important;
    }

    .ht-lg-200p-force {
        height: 200% !important;
    }

    .ht-lg-250 {
        height: 250px;
    }

    .ht-lg-250p {
        height: 250%;
    }

    .mx-ht-lg-250p {
        max-height: 250%;
    }

    .mn-ht-lg-250p {
        min-height: 250%;
    }

    .ht-lg-250-force {
        height: 250px !important;
    }

    .ht-lg-250p-force {
        height: 250% !important;
    }

    .ht-lg-300 {
        height: 300px;
    }

    .ht-lg-300p {
        height: 300%;
    }

    .mx-ht-lg-300p {
        max-height: 300%;
    }

    .mn-ht-lg-300p {
        min-height: 300%;
    }

    .ht-lg-300-force {
        height: 300px !important;
    }

    .ht-lg-300p-force {
        height: 300% !important;
    }

    .ht-lg-350 {
        height: 350px;
    }

    .ht-lg-350p {
        height: 350%;
    }

    .mx-ht-lg-350p {
        max-height: 350%;
    }

    .mn-ht-lg-350p {
        min-height: 350%;
    }

    .ht-lg-350-force {
        height: 350px !important;
    }

    .ht-lg-350p-force {
        height: 350% !important;
    }

    .ht-lg-400 {
        height: 400px;
    }

    .ht-lg-400p {
        height: 400%;
    }

    .mx-ht-lg-400p {
        max-height: 400%;
    }

    .mn-ht-lg-400p {
        min-height: 400%;
    }

    .ht-lg-400-force {
        height: 400px !important;
    }

    .ht-lg-400p-force {
        height: 400% !important;
    }

    .ht-lg-450 {
        height: 450px;
    }

    .ht-lg-450p {
        height: 450%;
    }

    .mx-ht-lg-450p {
        max-height: 450%;
    }

    .mn-ht-lg-450p {
        min-height: 450%;
    }

    .ht-lg-450-force {
        height: 450px !important;
    }

    .ht-lg-450p-force {
        height: 450% !important;
    }

    .ht-lg-500 {
        height: 500px;
    }

    .ht-lg-500p {
        height: 500%;
    }

    .mx-ht-lg-500p {
        max-height: 500%;
    }

    .mn-ht-lg-500p {
        min-height: 500%;
    }

    .ht-lg-500-force {
        height: 500px !important;
    }

    .ht-lg-500p-force {
        height: 500% !important;
    }

    .ht-lg-550 {
        height: 550px;
    }

    .ht-lg-550p {
        height: 550%;
    }

    .mx-ht-lg-550p {
        max-height: 550%;
    }

    .mn-ht-lg-550p {
        min-height: 550%;
    }

    .ht-lg-550-force {
        height: 550px !important;
    }

    .ht-lg-550p-force {
        height: 550% !important;
    }

    .ht-lg-600 {
        height: 600px;
    }

    .ht-lg-600p {
        height: 600%;
    }

    .mx-ht-lg-600p {
        max-height: 600%;
    }

    .mn-ht-lg-600p {
        min-height: 600%;
    }

    .ht-lg-600-force {
        height: 600px !important;
    }

    .ht-lg-600p-force {
        height: 600% !important;
    }

    .ht-lg-650 {
        height: 650px;
    }

    .ht-lg-650p {
        height: 650%;
    }

    .mx-ht-lg-650p {
        max-height: 650%;
    }

    .mn-ht-lg-650p {
        min-height: 650%;
    }

    .ht-lg-650-force {
        height: 650px !important;
    }

    .ht-lg-650p-force {
        height: 650% !important;
    }

    .ht-lg-700 {
        height: 700px;
    }

    .ht-lg-700p {
        height: 700%;
    }

    .mx-ht-lg-700p {
        max-height: 700%;
    }

    .mn-ht-lg-700p {
        min-height: 700%;
    }

    .ht-lg-700-force {
        height: 700px !important;
    }

    .ht-lg-700p-force {
        height: 700% !important;
    }

    .ht-lg-750 {
        height: 750px;
    }

    .ht-lg-750p {
        height: 750%;
    }

    .mx-ht-lg-750p {
        max-height: 750%;
    }

    .mn-ht-lg-750p {
        min-height: 750%;
    }

    .ht-lg-750-force {
        height: 750px !important;
    }

    .ht-lg-750p-force {
        height: 750% !important;
    }

    .ht-lg-800 {
        height: 800px;
    }

    .ht-lg-800p {
        height: 800%;
    }

    .mx-ht-lg-800p {
        max-height: 800%;
    }

    .mn-ht-lg-800p {
        min-height: 800%;
    }

    .ht-lg-800-force {
        height: 800px !important;
    }

    .ht-lg-800p-force {
        height: 800% !important;
    }

    .ht-lg-850 {
        height: 850px;
    }

    .ht-lg-850p {
        height: 850%;
    }

    .mx-ht-lg-850p {
        max-height: 850%;
    }

    .mn-ht-lg-850p {
        min-height: 850%;
    }

    .ht-lg-850-force {
        height: 850px !important;
    }

    .ht-lg-850p-force {
        height: 850% !important;
    }

    .ht-lg-100v {
        height: 100vh;
    }
}

@media (min-width: 1200px) {
    .ht-xl-5 {
        height: 5px;
    }

    .ht-xl-5p {
        height: 5%;
    }

    .mx-ht-xl-5p {
        max-height: 5%;
    }

    .mn-ht-xl-5p {
        min-height: 5%;
    }

    .ht-xl-5-force {
        height: 5px !important;
    }

    .ht-xl-5p-force {
        height: 5% !important;
    }

    .ht-xl-10 {
        height: 10px;
    }

    .ht-xl-10p {
        height: 10%;
    }

    .mx-ht-xl-10p {
        max-height: 10%;
    }

    .mn-ht-xl-10p {
        min-height: 10%;
    }

    .ht-xl-10-force {
        height: 10px !important;
    }

    .ht-xl-10p-force {
        height: 10% !important;
    }

    .ht-xl-15 {
        height: 15px;
    }

    .ht-xl-15p {
        height: 15%;
    }

    .mx-ht-xl-15p {
        max-height: 15%;
    }

    .mn-ht-xl-15p {
        min-height: 15%;
    }

    .ht-xl-15-force {
        height: 15px !important;
    }

    .ht-xl-15p-force {
        height: 15% !important;
    }

    .ht-xl-20 {
        height: 20px;
    }

    .ht-xl-20p {
        height: 20%;
    }

    .mx-ht-xl-20p {
        max-height: 20%;
    }

    .mn-ht-xl-20p {
        min-height: 20%;
    }

    .ht-xl-20-force {
        height: 20px !important;
    }

    .ht-xl-20p-force {
        height: 20% !important;
    }

    .ht-xl-25 {
        height: 25px;
    }

    .ht-xl-25p {
        height: 25%;
    }

    .mx-ht-xl-25p {
        max-height: 25%;
    }

    .mn-ht-xl-25p {
        min-height: 25%;
    }

    .ht-xl-25-force {
        height: 25px !important;
    }

    .ht-xl-25p-force {
        height: 25% !important;
    }

    .ht-xl-30 {
        height: 30px;
    }

    .ht-xl-30p {
        height: 30%;
    }

    .mx-ht-xl-30p {
        max-height: 30%;
    }

    .mn-ht-xl-30p {
        min-height: 30%;
    }

    .ht-xl-30-force {
        height: 30px !important;
    }

    .ht-xl-30p-force {
        height: 30% !important;
    }

    .ht-xl-35 {
        height: 35px;
    }

    .ht-xl-35p {
        height: 35%;
    }

    .mx-ht-xl-35p {
        max-height: 35%;
    }

    .mn-ht-xl-35p {
        min-height: 35%;
    }

    .ht-xl-35-force {
        height: 35px !important;
    }

    .ht-xl-35p-force {
        height: 35% !important;
    }

    .ht-xl-40 {
        height: 40px;
    }

    .ht-xl-40p {
        height: 40%;
    }

    .mx-ht-xl-40p {
        max-height: 40%;
    }

    .mn-ht-xl-40p {
        min-height: 40%;
    }

    .ht-xl-40-force {
        height: 40px !important;
    }

    .ht-xl-40p-force {
        height: 40% !important;
    }

    .ht-xl-45 {
        height: 45px;
    }

    .ht-xl-45p {
        height: 45%;
    }

    .mx-ht-xl-45p {
        max-height: 45%;
    }

    .mn-ht-xl-45p {
        min-height: 45%;
    }

    .ht-xl-45-force {
        height: 45px !important;
    }

    .ht-xl-45p-force {
        height: 45% !important;
    }

    .ht-xl-50 {
        height: 50px;
    }

    .ht-xl-50p {
        height: 50%;
    }

    .mx-ht-xl-50p {
        max-height: 50%;
    }

    .mn-ht-xl-50p {
        min-height: 50%;
    }

    .ht-xl-50-force {
        height: 50px !important;
    }

    .ht-xl-50p-force {
        height: 50% !important;
    }

    .ht-xl-55 {
        height: 55px;
    }

    .ht-xl-55p {
        height: 55%;
    }

    .mx-ht-xl-55p {
        max-height: 55%;
    }

    .mn-ht-xl-55p {
        min-height: 55%;
    }

    .ht-xl-55-force {
        height: 55px !important;
    }

    .ht-xl-55p-force {
        height: 55% !important;
    }

    .ht-xl-60 {
        height: 60px;
    }

    .ht-xl-60p {
        height: 60%;
    }

    .mx-ht-xl-60p {
        max-height: 60%;
    }

    .mn-ht-xl-60p {
        min-height: 60%;
    }

    .ht-xl-60-force {
        height: 60px !important;
    }

    .ht-xl-60p-force {
        height: 60% !important;
    }

    .ht-xl-65 {
        height: 65px;
    }

    .ht-xl-65p {
        height: 65%;
    }

    .mx-ht-xl-65p {
        max-height: 65%;
    }

    .mn-ht-xl-65p {
        min-height: 65%;
    }

    .ht-xl-65-force {
        height: 65px !important;
    }

    .ht-xl-65p-force {
        height: 65% !important;
    }

    .ht-xl-70 {
        height: 70px;
    }

    .ht-xl-70p {
        height: 70%;
    }

    .mx-ht-xl-70p {
        max-height: 70%;
    }

    .mn-ht-xl-70p {
        min-height: 70%;
    }

    .ht-xl-70-force {
        height: 70px !important;
    }

    .ht-xl-70p-force {
        height: 70% !important;
    }

    .ht-xl-75 {
        height: 75px;
    }

    .ht-xl-75p {
        height: 75%;
    }

    .mx-ht-xl-75p {
        max-height: 75%;
    }

    .mn-ht-xl-75p {
        min-height: 75%;
    }

    .ht-xl-75-force {
        height: 75px !important;
    }

    .ht-xl-75p-force {
        height: 75% !important;
    }

    .ht-xl-80 {
        height: 80px;
    }

    .ht-xl-80p {
        height: 80%;
    }

    .mx-ht-xl-80p {
        max-height: 80%;
    }

    .mn-ht-xl-80p {
        min-height: 80%;
    }

    .ht-xl-80-force {
        height: 80px !important;
    }

    .ht-xl-80p-force {
        height: 80% !important;
    }

    .ht-xl-85 {
        height: 85px;
    }

    .ht-xl-85p {
        height: 85%;
    }

    .mx-ht-xl-85p {
        max-height: 85%;
    }

    .mn-ht-xl-85p {
        min-height: 85%;
    }

    .ht-xl-85-force {
        height: 85px !important;
    }

    .ht-xl-85p-force {
        height: 85% !important;
    }

    .ht-xl-90 {
        height: 90px;
    }

    .ht-xl-90p {
        height: 90%;
    }

    .mx-ht-xl-90p {
        max-height: 90%;
    }

    .mn-ht-xl-90p {
        min-height: 90%;
    }

    .ht-xl-90-force {
        height: 90px !important;
    }

    .ht-xl-90p-force {
        height: 90% !important;
    }

    .ht-xl-95 {
        height: 95px;
    }

    .ht-xl-95p {
        height: 95%;
    }

    .mx-ht-xl-95p {
        max-height: 95%;
    }

    .mn-ht-xl-95p {
        min-height: 95%;
    }

    .ht-xl-95-force {
        height: 95px !important;
    }

    .ht-xl-95p-force {
        height: 95% !important;
    }

    .ht-xl-100 {
        height: 100px;
    }

    .ht-xl-100p {
        height: 100%;
    }

    .mx-ht-xl-100p {
        max-height: 100%;
    }

    .mn-ht-xl-100p {
        min-height: 100%;
    }

    .ht-xl-100-force {
        height: 100px !important;
    }

    .ht-xl-100p-force {
        height: 100% !important;
    }

    .ht-xl-150 {
        height: 150px;
    }

    .ht-xl-150p {
        height: 150%;
    }

    .mx-ht-xl-150p {
        max-height: 150%;
    }

    .mn-ht-xl-150p {
        min-height: 150%;
    }

    .ht-xl-150-force {
        height: 150px !important;
    }

    .ht-xl-150p-force {
        height: 150% !important;
    }

    .ht-xl-200 {
        height: 200px;
    }

    .ht-xl-200p {
        height: 200%;
    }

    .mx-ht-xl-200p {
        max-height: 200%;
    }

    .mn-ht-xl-200p {
        min-height: 200%;
    }

    .ht-xl-200-force {
        height: 200px !important;
    }

    .ht-xl-200p-force {
        height: 200% !important;
    }

    .ht-xl-250 {
        height: 250px;
    }

    .ht-xl-250p {
        height: 250%;
    }

    .mx-ht-xl-250p {
        max-height: 250%;
    }

    .mn-ht-xl-250p {
        min-height: 250%;
    }

    .ht-xl-250-force {
        height: 250px !important;
    }

    .ht-xl-250p-force {
        height: 250% !important;
    }

    .ht-xl-300 {
        height: 300px;
    }

    .ht-xl-300p {
        height: 300%;
    }

    .mx-ht-xl-300p {
        max-height: 300%;
    }

    .mn-ht-xl-300p {
        min-height: 300%;
    }

    .ht-xl-300-force {
        height: 300px !important;
    }

    .ht-xl-300p-force {
        height: 300% !important;
    }

    .ht-xl-350 {
        height: 350px;
    }

    .ht-xl-350p {
        height: 350%;
    }

    .mx-ht-xl-350p {
        max-height: 350%;
    }

    .mn-ht-xl-350p {
        min-height: 350%;
    }

    .ht-xl-350-force {
        height: 350px !important;
    }

    .ht-xl-350p-force {
        height: 350% !important;
    }

    .ht-xl-400 {
        height: 400px;
    }

    .ht-xl-400p {
        height: 400%;
    }

    .mx-ht-xl-400p {
        max-height: 400%;
    }

    .mn-ht-xl-400p {
        min-height: 400%;
    }

    .ht-xl-400-force {
        height: 400px !important;
    }

    .ht-xl-400p-force {
        height: 400% !important;
    }

    .ht-xl-450 {
        height: 450px;
    }

    .ht-xl-450p {
        height: 450%;
    }

    .mx-ht-xl-450p {
        max-height: 450%;
    }

    .mn-ht-xl-450p {
        min-height: 450%;
    }

    .ht-xl-450-force {
        height: 450px !important;
    }

    .ht-xl-450p-force {
        height: 450% !important;
    }

    .ht-xl-500 {
        height: 500px;
    }

    .ht-xl-500p {
        height: 500%;
    }

    .mx-ht-xl-500p {
        max-height: 500%;
    }

    .mn-ht-xl-500p {
        min-height: 500%;
    }

    .ht-xl-500-force {
        height: 500px !important;
    }

    .ht-xl-500p-force {
        height: 500% !important;
    }

    .ht-xl-550 {
        height: 550px;
    }

    .ht-xl-550p {
        height: 550%;
    }

    .mx-ht-xl-550p {
        max-height: 550%;
    }

    .mn-ht-xl-550p {
        min-height: 550%;
    }

    .ht-xl-550-force {
        height: 550px !important;
    }

    .ht-xl-550p-force {
        height: 550% !important;
    }

    .ht-xl-600 {
        height: 600px;
    }

    .ht-xl-600p {
        height: 600%;
    }

    .mx-ht-xl-600p {
        max-height: 600%;
    }

    .mn-ht-xl-600p {
        min-height: 600%;
    }

    .ht-xl-600-force {
        height: 600px !important;
    }

    .ht-xl-600p-force {
        height: 600% !important;
    }

    .ht-xl-650 {
        height: 650px;
    }

    .ht-xl-650p {
        height: 650%;
    }

    .mx-ht-xl-650p {
        max-height: 650%;
    }

    .mn-ht-xl-650p {
        min-height: 650%;
    }

    .ht-xl-650-force {
        height: 650px !important;
    }

    .ht-xl-650p-force {
        height: 650% !important;
    }

    .ht-xl-700 {
        height: 700px;
    }

    .ht-xl-700p {
        height: 700%;
    }

    .mx-ht-xl-700p {
        max-height: 700%;
    }

    .mn-ht-xl-700p {
        min-height: 700%;
    }

    .ht-xl-700-force {
        height: 700px !important;
    }

    .ht-xl-700p-force {
        height: 700% !important;
    }

    .ht-xl-750 {
        height: 750px;
    }

    .ht-xl-750p {
        height: 750%;
    }

    .mx-ht-xl-750p {
        max-height: 750%;
    }

    .mn-ht-xl-750p {
        min-height: 750%;
    }

    .ht-xl-750-force {
        height: 750px !important;
    }

    .ht-xl-750p-force {
        height: 750% !important;
    }

    .ht-xl-800 {
        height: 800px;
    }

    .ht-xl-800p {
        height: 800%;
    }

    .mx-ht-xl-800p {
        max-height: 800%;
    }

    .mn-ht-xl-800p {
        min-height: 800%;
    }

    .ht-xl-800-force {
        height: 800px !important;
    }

    .ht-xl-800p-force {
        height: 800% !important;
    }

    .ht-xl-850 {
        height: 850px;
    }

    .ht-xl-850p {
        height: 850%;
    }

    .mx-ht-xl-850p {
        max-height: 850%;
    }

    .mn-ht-xl-850p {
        min-height: 850%;
    }

    .ht-xl-850-force {
        height: 850px !important;
    }

    .ht-xl-850p-force {
        height: 850% !important;
    }

    .ht-xl-100v {
        height: 100vh;
    }
}


/* ----------------------------------------- */


/* ############### 7.4 Hover ############### */


/* ----------------------------------------- */

a.tx-success:hover,
a.tx-success:focus {
    color: #1fa707;
}

a.tx-warning:hover,
a.tx-warning:focus {
    color: #e78c0b;
}

a.tx-danger:hover,
a.tx-danger:focus {
    color: #d32535;
}

a.tx-info:hover,
a.tx-info:focus {
    color: #148ea1;
}

a.hover-gray-700:hover,
a.hover-gray-700:focus {
    color: #495057;
}

a.hover-primary:hover,
a.hover-primary:focus {
    color: #0866C6;
}

a.hover-success:hover,
a.hover-success:focus {
    color: #23BF08;
}

a.hover-info:hover,
a.hover-info:focus {
    color: #17A2B8;
}

a.hover-white:hover,
a.hover-white:focus {
    color: #fff;
}

a.hover-dark:hover,
a.hover-dark:focus {
    color: #212529;
}

a.hover-white-8:hover,
a.hover-white-8:focus {
    color: rgba(255, 255, 255, 0.8);
}


/* ------------------------------------------ */


/* ############### 7.5 Margin ############### */


/* ------------------------------------------ */

.mg-0 {
    margin: 0px;
}

.mg-0-force {
    margin: 0px !important;
}

.mg-y-0 {
    margin-top: 0px;
    margin-bottom: 0px;
}

.mg-y-0-force {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.mg-x-0 {
    margin-left: 0px;
    margin-right: 0px;
}

.mg-x-0-force {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.mg-t-0 {
    margin-top: 0px;
}

.mg-r-0 {
    margin-right: 0px;
}

.mg-b-0 {
    margin-bottom: 0px;
}

.mg-l-0 {
    margin-left: 0px;
}

.mg-t-0-force {
    margin-top: 0px !important;
}

.mg-r-0-force {
    margin-right: 0px !important;
}

.mg-b-0-force {
    margin-bottom: 0px !important;
}

.mg-l-0-force {
    margin-left: 0px !important;
}

.mg-1 {
    margin: 1px;
}

.mg-1-force {
    margin: 1px !important;
}

.mg-y-1 {
    margin-top: 1px;
    margin-bottom: 1px;
}

.mg-y-1-force {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
}

.mg-x-1 {
    margin-left: 1px;
    margin-right: 1px;
}

.mg-x-1-force {
    margin-left: 1px !important;
    margin-right: 1px !important;
}

.mg-t-1 {
    margin-top: 1px;
}

.mg-r-1 {
    margin-right: 1px;
}

.mg-b-1 {
    margin-bottom: 1px;
}

.mg-l-1 {
    margin-left: 1px;
}

.mg-t-1-force {
    margin-top: 1px !important;
}

.mg-r-1-force {
    margin-right: 1px !important;
}

.mg-b-1-force {
    margin-bottom: 1px !important;
}

.mg-l-1-force {
    margin-left: 1px !important;
}

.mg-2 {
    margin: 2px;
}

.mg-2-force {
    margin: 2px !important;
}

.mg-y-2 {
    margin-top: 2px;
    margin-bottom: 2px;
}

.mg-y-2-force {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
}

.mg-x-2 {
    margin-left: 2px;
    margin-right: 2px;
}

.mg-x-2-force {
    margin-left: 2px !important;
    margin-right: 2px !important;
}

.mg-t-2 {
    margin-top: 2px;
}

.mg-r-2 {
    margin-right: 2px;
}

.mg-b-2 {
    margin-bottom: 2px;
}

.mg-l-2 {
    margin-left: 2px;
}

.mg-t-2-force {
    margin-top: 2px !important;
}

.mg-r-2-force {
    margin-right: 2px !important;
}

.mg-b-2-force {
    margin-bottom: 2px !important;
}

.mg-l-2-force {
    margin-left: 2px !important;
}

.mg-3 {
    margin: 3px;
}

.mg-3-force {
    margin: 3px !important;
}

.mg-y-3 {
    margin-top: 3px;
    margin-bottom: 3px;
}

.mg-y-3-force {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
}

.mg-x-3 {
    margin-left: 3px;
    margin-right: 3px;
}

.mg-x-3-force {
    margin-left: 3px !important;
    margin-right: 3px !important;
}

.mg-t-3 {
    margin-top: 3px;
}

.mg-r-3 {
    margin-right: 3px;
}

.mg-b-3 {
    margin-bottom: 3px;
}

.mg-l-3 {
    margin-left: 3px;
}

.mg-t-3-force {
    margin-top: 3px !important;
}

.mg-r-3-force {
    margin-right: 3px !important;
}

.mg-b-3-force {
    margin-bottom: 3px !important;
}

.mg-l-3-force {
    margin-left: 3px !important;
}

.mg-4 {
    margin: 4px;
}

.mg-4-force {
    margin: 4px !important;
}

.mg-y-4 {
    margin-top: 4px;
    margin-bottom: 4px;
}

.mg-y-4-force {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
}

.mg-x-4 {
    margin-left: 4px;
    margin-right: 4px;
}

.mg-x-4-force {
    margin-left: 4px !important;
    margin-right: 4px !important;
}

.mg-t-4 {
    margin-top: 4px;
}

.mg-r-4 {
    margin-right: 4px;
}

.mg-b-4 {
    margin-bottom: 4px;
}

.mg-l-4 {
    margin-left: 4px;
}

.mg-t-4-force {
    margin-top: 4px !important;
}

.mg-r-4-force {
    margin-right: 4px !important;
}

.mg-b-4-force {
    margin-bottom: 4px !important;
}

.mg-l-4-force {
    margin-left: 4px !important;
}

.mg-5 {
    margin: 5px;
}

.mg-5-force {
    margin: 5px !important;
}

.mg-y-5 {
    margin-top: 5px;
    margin-bottom: 5px;
}

.mg-y-5-force {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.mg-x-5 {
    margin-left: 5px;
    margin-right: 5px;
}

.mg-x-5-force {
    margin-left: 5px !important;
    margin-right: 5px !important;
}

.mg-t-5 {
    margin-top: 5px;
}

.mg-r-5 {
    margin-right: 5px;
}

.mg-b-5 {
    margin-bottom: 5px;
}

.mg-l-5 {
    margin-left: 5px;
}

.mg-t-5-force {
    margin-top: 5px !important;
}

.mg-r-5-force {
    margin-right: 5px !important;
}

.mg-b-5-force {
    margin-bottom: 5px !important;
}

.mg-l-5-force {
    margin-left: 5px !important;
}

.mg-6 {
    margin: 6px;
}

.mg-6-force {
    margin: 6px !important;
}

.mg-y-6 {
    margin-top: 6px;
    margin-bottom: 6px;
}

.mg-y-6-force {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
}

.mg-x-6 {
    margin-left: 6px;
    margin-right: 6px;
}

.mg-x-6-force {
    margin-left: 6px !important;
    margin-right: 6px !important;
}

.mg-t-6 {
    margin-top: 6px;
}

.mg-r-6 {
    margin-right: 6px;
}

.mg-b-6 {
    margin-bottom: 6px;
}

.mg-l-6 {
    margin-left: 6px;
}

.mg-t-6-force {
    margin-top: 6px !important;
}

.mg-r-6-force {
    margin-right: 6px !important;
}

.mg-b-6-force {
    margin-bottom: 6px !important;
}

.mg-l-6-force {
    margin-left: 6px !important;
}

.mg-7 {
    margin: 7px;
}

.mg-7-force {
    margin: 7px !important;
}

.mg-y-7 {
    margin-top: 7px;
    margin-bottom: 7px;
}

.mg-y-7-force {
    margin-top: 7px !important;
    margin-bottom: 7px !important;
}

.mg-x-7 {
    margin-left: 7px;
    margin-right: 7px;
}

.mg-x-7-force {
    margin-left: 7px !important;
    margin-right: 7px !important;
}

.mg-t-7 {
    margin-top: 7px;
}

.mg-r-7 {
    margin-right: 7px;
}

.mg-b-7 {
    margin-bottom: 7px;
}

.mg-l-7 {
    margin-left: 7px;
}

.mg-t-7-force {
    margin-top: 7px !important;
}

.mg-r-7-force {
    margin-right: 7px !important;
}

.mg-b-7-force {
    margin-bottom: 7px !important;
}

.mg-l-7-force {
    margin-left: 7px !important;
}

.mg-8 {
    margin: 8px;
}

.mg-8-force {
    margin: 8px !important;
}

.mg-y-8 {
    margin-top: 8px;
    margin-bottom: 8px;
}

.mg-y-8-force {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
}

.mg-x-8 {
    margin-left: 8px;
    margin-right: 8px;
}

.mg-x-8-force {
    margin-left: 8px !important;
    margin-right: 8px !important;
}

.mg-t-8 {
    margin-top: 8px;
}

.mg-r-8 {
    margin-right: 8px;
}

.mg-b-8 {
    margin-bottom: 8px;
}

.mg-l-8 {
    margin-left: 8px;
}

.mg-t-8-force {
    margin-top: 8px !important;
}

.mg-r-8-force {
    margin-right: 8px !important;
}

.mg-b-8-force {
    margin-bottom: 8px !important;
}

.mg-l-8-force {
    margin-left: 8px !important;
}

.mg-9 {
    margin: 9px;
}

.mg-9-force {
    margin: 9px !important;
}

.mg-y-9 {
    margin-top: 9px;
    margin-bottom: 9px;
}

.mg-y-9-force {
    margin-top: 9px !important;
    margin-bottom: 9px !important;
}

.mg-x-9 {
    margin-left: 9px;
    margin-right: 9px;
}

.mg-x-9-force {
    margin-left: 9px !important;
    margin-right: 9px !important;
}

.mg-t-9 {
    margin-top: 9px;
}

.mg-r-9 {
    margin-right: 9px;
}

.mg-b-9 {
    margin-bottom: 9px;
}

.mg-l-9 {
    margin-left: 9px;
}

.mg-t-9-force {
    margin-top: 9px !important;
}

.mg-r-9-force {
    margin-right: 9px !important;
}

.mg-b-9-force {
    margin-bottom: 9px !important;
}

.mg-l-9-force {
    margin-left: 9px !important;
}

.mg-10 {
    margin: 10px;
}

.mg-10-force {
    margin: 10px !important;
}

.mg-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.mg-y-10-force {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.mg-x-10 {
    margin-left: 10px;
    margin-right: 10px;
}

.mg-x-10-force {
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.mg-t-10 {
    margin-top: 10px;
}

.mg-r-10 {
    margin-right: 10px;
}

.mg-b-10 {
    margin-bottom: 10px;
}

.mg-l-10 {
    margin-left: 10px;
}

.mg-t-10-force {
    margin-top: 10px !important;
}

.mg-r-10-force {
    margin-right: 10px !important;
}

.mg-b-10-force {
    margin-bottom: 10px !important;
}

.mg-l-10-force {
    margin-left: 10px !important;
}

.mg-15 {
    margin: 15px;
}

.mg-15-force {
    margin: 15px !important;
}

.mg-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
}

.mg-y-15-force {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}

.mg-x-15 {
    margin-left: 15px;
    margin-right: 15px;
}

.mg-x-15-force {
    margin-left: 15px !important;
    margin-right: 15px !important;
}

.mg-t-15 {
    margin-top: 15px;
}

.mg-r-15 {
    margin-right: 15px;
}

.mg-b-15 {
    margin-bottom: 15px;
}

.mg-l-15 {
    margin-left: 15px;
}

.mg-t-15-force {
    margin-top: 15px !important;
}

.mg-r-15-force {
    margin-right: 15px !important;
}

.mg-b-15-force {
    margin-bottom: 15px !important;
}

.mg-l-15-force {
    margin-left: 15px !important;
}

.mg-20 {
    margin: 20px;
}

.mg-20-force {
    margin: 20px !important;
}

.mg-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.mg-y-20-force {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

.mg-x-20 {
    margin-left: 20px;
    margin-right: 20px;
}

.mg-x-20-force {
    margin-left: 20px !important;
    margin-right: 20px !important;
}

.mg-t-20 {
    margin-top: 20px;
}

.mg-r-20 {
    margin-right: 20px;
}

.mg-b-20 {
    margin-bottom: 20px;
}

.mg-l-20 {
    margin-left: 20px;
}

.mg-t-20-force {
    margin-top: 20px !important;
}

.mg-r-20-force {
    margin-right: 20px !important;
}

.mg-b-20-force {
    margin-bottom: 20px !important;
}

.mg-l-20-force {
    margin-left: 20px !important;
}

.mg-25 {
    margin: 25px;
}

.mg-25-force {
    margin: 25px !important;
}

.mg-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
}

.mg-y-25-force {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
}

.mg-x-25 {
    margin-left: 25px;
    margin-right: 25px;
}

.mg-x-25-force {
    margin-left: 25px !important;
    margin-right: 25px !important;
}

.mg-t-25 {
    margin-top: 25px;
}

.mg-r-25 {
    margin-right: 25px;
}

.mg-b-25 {
    margin-bottom: 25px;
}

.mg-l-25 {
    margin-left: 25px;
}

.mg-t-25-force {
    margin-top: 25px !important;
}

.mg-r-25-force {
    margin-right: 25px !important;
}

.mg-b-25-force {
    margin-bottom: 25px !important;
}

.mg-l-25-force {
    margin-left: 25px !important;
}

.mg-30 {
    margin: 30px;
}

.mg-30-force {
    margin: 30px !important;
}

.mg-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
}

.mg-y-30-force {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
}

.mg-x-30 {
    margin-left: 30px;
    margin-right: 30px;
}

.mg-x-30-force {
    margin-left: 30px !important;
    margin-right: 30px !important;
}

.mg-t-30 {
    margin-top: 30px;
}

.mg-r-30 {
    margin-right: 30px;
}

.mg-b-30 {
    margin-bottom: 30px;
}

.mg-l-30 {
    margin-left: 30px;
}

.mg-t-30-force {
    margin-top: 30px !important;
}

.mg-r-30-force {
    margin-right: 30px !important;
}

.mg-b-30-force {
    margin-bottom: 30px !important;
}

.mg-l-30-force {
    margin-left: 30px !important;
}

.mg-35 {
    margin: 35px;
}

.mg-35-force {
    margin: 35px !important;
}

.mg-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
}

.mg-y-35-force {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
}

.mg-x-35 {
    margin-left: 35px;
    margin-right: 35px;
}

.mg-x-35-force {
    margin-left: 35px !important;
    margin-right: 35px !important;
}

.mg-t-35 {
    margin-top: 35px;
}

.mg-r-35 {
    margin-right: 35px;
}

.mg-b-35 {
    margin-bottom: 35px;
}

.mg-l-35 {
    margin-left: 35px;
}

.mg-t-35-force {
    margin-top: 35px !important;
}

.mg-r-35-force {
    margin-right: 35px !important;
}

.mg-b-35-force {
    margin-bottom: 35px !important;
}

.mg-l-35-force {
    margin-left: 35px !important;
}

.mg-40 {
    margin: 40px;
}

.mg-40-force {
    margin: 40px !important;
}

.mg-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
}

.mg-y-40-force {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
}

.mg-x-40 {
    margin-left: 40px;
    margin-right: 40px;
}

.mg-x-40-force {
    margin-left: 40px !important;
    margin-right: 40px !important;
}

.mg-t-40 {
    margin-top: 40px;
}

.mg-r-40 {
    margin-right: 40px;
}

.mg-b-40 {
    margin-bottom: 40px;
}

.mg-l-40 {
    margin-left: 40px;
}

.mg-t-40-force {
    margin-top: 40px !important;
}

.mg-r-40-force {
    margin-right: 40px !important;
}

.mg-b-40-force {
    margin-bottom: 40px !important;
}

.mg-l-40-force {
    margin-left: 40px !important;
}

.mg-45 {
    margin: 45px;
}

.mg-45-force {
    margin: 45px !important;
}

.mg-y-45 {
    margin-top: 45px;
    margin-bottom: 45px;
}

.mg-y-45-force {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
}

.mg-x-45 {
    margin-left: 45px;
    margin-right: 45px;
}

.mg-x-45-force {
    margin-left: 45px !important;
    margin-right: 45px !important;
}

.mg-t-45 {
    margin-top: 45px;
}

.mg-r-45 {
    margin-right: 45px;
}

.mg-b-45 {
    margin-bottom: 45px;
}

.mg-l-45 {
    margin-left: 45px;
}

.mg-t-45-force {
    margin-top: 45px !important;
}

.mg-r-45-force {
    margin-right: 45px !important;
}

.mg-b-45-force {
    margin-bottom: 45px !important;
}

.mg-l-45-force {
    margin-left: 45px !important;
}

.mg-50 {
    margin: 50px;
}

.mg-50-force {
    margin: 50px !important;
}

.mg-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
}

.mg-y-50-force {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
}

.mg-x-50 {
    margin-left: 50px;
    margin-right: 50px;
}

.mg-x-50-force {
    margin-left: 50px !important;
    margin-right: 50px !important;
}

.mg-t-50 {
    margin-top: 50px;
}

.mg-r-50 {
    margin-right: 50px;
}

.mg-b-50 {
    margin-bottom: 50px;
}

.mg-l-50 {
    margin-left: 50px;
}

.mg-t-50-force {
    margin-top: 50px !important;
}

.mg-r-50-force {
    margin-right: 50px !important;
}

.mg-b-50-force {
    margin-bottom: 50px !important;
}

.mg-l-50-force {
    margin-left: 50px !important;
}

.mg-55 {
    margin: 55px;
}

.mg-55-force {
    margin: 55px !important;
}

.mg-y-55 {
    margin-top: 55px;
    margin-bottom: 55px;
}

.mg-y-55-force {
    margin-top: 55px !important;
    margin-bottom: 55px !important;
}

.mg-x-55 {
    margin-left: 55px;
    margin-right: 55px;
}

.mg-x-55-force {
    margin-left: 55px !important;
    margin-right: 55px !important;
}

.mg-t-55 {
    margin-top: 55px;
}

.mg-r-55 {
    margin-right: 55px;
}

.mg-b-55 {
    margin-bottom: 55px;
}

.mg-l-55 {
    margin-left: 55px;
}

.mg-t-55-force {
    margin-top: 55px !important;
}

.mg-r-55-force {
    margin-right: 55px !important;
}

.mg-b-55-force {
    margin-bottom: 55px !important;
}

.mg-l-55-force {
    margin-left: 55px !important;
}

.mg-60 {
    margin: 60px;
}

.mg-60-force {
    margin: 60px !important;
}

.mg-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
}

.mg-y-60-force {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
}

.mg-x-60 {
    margin-left: 60px;
    margin-right: 60px;
}

.mg-x-60-force {
    margin-left: 60px !important;
    margin-right: 60px !important;
}

.mg-t-60 {
    margin-top: 60px;
}

.mg-r-60 {
    margin-right: 60px;
}

.mg-b-60 {
    margin-bottom: 60px;
}

.mg-l-60 {
    margin-left: 60px;
}

.mg-t-60-force {
    margin-top: 60px !important;
}

.mg-r-60-force {
    margin-right: 60px !important;
}

.mg-b-60-force {
    margin-bottom: 60px !important;
}

.mg-l-60-force {
    margin-left: 60px !important;
}

.mg-65 {
    margin: 65px;
}

.mg-65-force {
    margin: 65px !important;
}

.mg-y-65 {
    margin-top: 65px;
    margin-bottom: 65px;
}

.mg-y-65-force {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
}

.mg-x-65 {
    margin-left: 65px;
    margin-right: 65px;
}

.mg-x-65-force {
    margin-left: 65px !important;
    margin-right: 65px !important;
}

.mg-t-65 {
    margin-top: 65px;
}

.mg-r-65 {
    margin-right: 65px;
}

.mg-b-65 {
    margin-bottom: 65px;
}

.mg-l-65 {
    margin-left: 65px;
}

.mg-t-65-force {
    margin-top: 65px !important;
}

.mg-r-65-force {
    margin-right: 65px !important;
}

.mg-b-65-force {
    margin-bottom: 65px !important;
}

.mg-l-65-force {
    margin-left: 65px !important;
}

.mg-70 {
    margin: 70px;
}

.mg-70-force {
    margin: 70px !important;
}

.mg-y-70 {
    margin-top: 70px;
    margin-bottom: 70px;
}

.mg-y-70-force {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
}

.mg-x-70 {
    margin-left: 70px;
    margin-right: 70px;
}

.mg-x-70-force {
    margin-left: 70px !important;
    margin-right: 70px !important;
}

.mg-t-70 {
    margin-top: 70px;
}

.mg-r-70 {
    margin-right: 70px;
}

.mg-b-70 {
    margin-bottom: 70px;
}

.mg-l-70 {
    margin-left: 70px;
}

.mg-t-70-force {
    margin-top: 70px !important;
}

.mg-r-70-force {
    margin-right: 70px !important;
}

.mg-b-70-force {
    margin-bottom: 70px !important;
}

.mg-l-70-force {
    margin-left: 70px !important;
}

.mg-75 {
    margin: 75px;
}

.mg-75-force {
    margin: 75px !important;
}

.mg-y-75 {
    margin-top: 75px;
    margin-bottom: 75px;
}

.mg-y-75-force {
    margin-top: 75px !important;
    margin-bottom: 75px !important;
}

.mg-x-75 {
    margin-left: 75px;
    margin-right: 75px;
}

.mg-x-75-force {
    margin-left: 75px !important;
    margin-right: 75px !important;
}

.mg-t-75 {
    margin-top: 75px;
}

.mg-r-75 {
    margin-right: 75px;
}

.mg-b-75 {
    margin-bottom: 75px;
}

.mg-l-75 {
    margin-left: 75px;
}

.mg-t-75-force {
    margin-top: 75px !important;
}

.mg-r-75-force {
    margin-right: 75px !important;
}

.mg-b-75-force {
    margin-bottom: 75px !important;
}

.mg-l-75-force {
    margin-left: 75px !important;
}

.mg-80 {
    margin: 80px;
}

.mg-80-force {
    margin: 80px !important;
}

.mg-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
}

.mg-y-80-force {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
}

.mg-x-80 {
    margin-left: 80px;
    margin-right: 80px;
}

.mg-x-80-force {
    margin-left: 80px !important;
    margin-right: 80px !important;
}

.mg-t-80 {
    margin-top: 80px;
}

.mg-r-80 {
    margin-right: 80px;
}

.mg-b-80 {
    margin-bottom: 80px;
}

.mg-l-80 {
    margin-left: 80px;
}

.mg-t-80-force {
    margin-top: 80px !important;
}

.mg-r-80-force {
    margin-right: 80px !important;
}

.mg-b-80-force {
    margin-bottom: 80px !important;
}

.mg-l-80-force {
    margin-left: 80px !important;
}

.mg-85 {
    margin: 85px;
}

.mg-85-force {
    margin: 85px !important;
}

.mg-y-85 {
    margin-top: 85px;
    margin-bottom: 85px;
}

.mg-y-85-force {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
}

.mg-x-85 {
    margin-left: 85px;
    margin-right: 85px;
}

.mg-x-85-force {
    margin-left: 85px !important;
    margin-right: 85px !important;
}

.mg-t-85 {
    margin-top: 85px;
}

.mg-r-85 {
    margin-right: 85px;
}

.mg-b-85 {
    margin-bottom: 85px;
}

.mg-l-85 {
    margin-left: 85px;
}

.mg-t-85-force {
    margin-top: 85px !important;
}

.mg-r-85-force {
    margin-right: 85px !important;
}

.mg-b-85-force {
    margin-bottom: 85px !important;
}

.mg-l-85-force {
    margin-left: 85px !important;
}

.mg-90 {
    margin: 90px;
}

.mg-90-force {
    margin: 90px !important;
}

.mg-y-90 {
    margin-top: 90px;
    margin-bottom: 90px;
}

.mg-y-90-force {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
}

.mg-x-90 {
    margin-left: 90px;
    margin-right: 90px;
}

.mg-x-90-force {
    margin-left: 90px !important;
    margin-right: 90px !important;
}

.mg-t-90 {
    margin-top: 90px;
}

.mg-r-90 {
    margin-right: 90px;
}

.mg-b-90 {
    margin-bottom: 90px;
}

.mg-l-90 {
    margin-left: 90px;
}

.mg-t-90-force {
    margin-top: 90px !important;
}

.mg-r-90-force {
    margin-right: 90px !important;
}

.mg-b-90-force {
    margin-bottom: 90px !important;
}

.mg-l-90-force {
    margin-left: 90px !important;
}

.mg-95 {
    margin: 95px;
}

.mg-95-force {
    margin: 95px !important;
}

.mg-y-95 {
    margin-top: 95px;
    margin-bottom: 95px;
}

.mg-y-95-force {
    margin-top: 95px !important;
    margin-bottom: 95px !important;
}

.mg-x-95 {
    margin-left: 95px;
    margin-right: 95px;
}

.mg-x-95-force {
    margin-left: 95px !important;
    margin-right: 95px !important;
}

.mg-t-95 {
    margin-top: 95px;
}

.mg-r-95 {
    margin-right: 95px;
}

.mg-b-95 {
    margin-bottom: 95px;
}

.mg-l-95 {
    margin-left: 95px;
}

.mg-t-95-force {
    margin-top: 95px !important;
}

.mg-r-95-force {
    margin-right: 95px !important;
}

.mg-b-95-force {
    margin-bottom: 95px !important;
}

.mg-l-95-force {
    margin-left: 95px !important;
}

.mg-100 {
    margin: 100px;
}

.mg-100-force {
    margin: 100px !important;
}

.mg-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
}

.mg-y-100-force {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
}

.mg-x-100 {
    margin-left: 100px;
    margin-right: 100px;
}

.mg-x-100-force {
    margin-left: 100px !important;
    margin-right: 100px !important;
}

.mg-t-100 {
    margin-top: 100px;
}

.mg-r-100 {
    margin-right: 100px;
}

.mg-b-100 {
    margin-bottom: 100px;
}

.mg-l-100 {
    margin-left: 100px;
}

.mg-t-100-force {
    margin-top: 100px !important;
}

.mg-r-100-force {
    margin-right: 100px !important;
}

.mg-b-100-force {
    margin-bottom: 100px !important;
}

.mg-l-100-force {
    margin-left: 100px !important;
}

.mg-105 {
    margin: 105px;
}

.mg-105-force {
    margin: 105px !important;
}

.mg-y-105 {
    margin-top: 105px;
    margin-bottom: 105px;
}

.mg-y-105-force {
    margin-top: 105px !important;
    margin-bottom: 105px !important;
}

.mg-x-105 {
    margin-left: 105px;
    margin-right: 105px;
}

.mg-x-105-force {
    margin-left: 105px !important;
    margin-right: 105px !important;
}

.mg-t-105 {
    margin-top: 105px;
}

.mg-r-105 {
    margin-right: 105px;
}

.mg-b-105 {
    margin-bottom: 105px;
}

.mg-l-105 {
    margin-left: 105px;
}

.mg-t-105-force {
    margin-top: 105px !important;
}

.mg-r-105-force {
    margin-right: 105px !important;
}

.mg-b-105-force {
    margin-bottom: 105px !important;
}

.mg-l-105-force {
    margin-left: 105px !important;
}

.mg-110 {
    margin: 110px;
}

.mg-110-force {
    margin: 110px !important;
}

.mg-y-110 {
    margin-top: 110px;
    margin-bottom: 110px;
}

.mg-y-110-force {
    margin-top: 110px !important;
    margin-bottom: 110px !important;
}

.mg-x-110 {
    margin-left: 110px;
    margin-right: 110px;
}

.mg-x-110-force {
    margin-left: 110px !important;
    margin-right: 110px !important;
}

.mg-t-110 {
    margin-top: 110px;
}

.mg-r-110 {
    margin-right: 110px;
}

.mg-b-110 {
    margin-bottom: 110px;
}

.mg-l-110 {
    margin-left: 110px;
}

.mg-t-110-force {
    margin-top: 110px !important;
}

.mg-r-110-force {
    margin-right: 110px !important;
}

.mg-b-110-force {
    margin-bottom: 110px !important;
}

.mg-l-110-force {
    margin-left: 110px !important;
}

.mg-115 {
    margin: 115px;
}

.mg-115-force {
    margin: 115px !important;
}

.mg-y-115 {
    margin-top: 115px;
    margin-bottom: 115px;
}

.mg-y-115-force {
    margin-top: 115px !important;
    margin-bottom: 115px !important;
}

.mg-x-115 {
    margin-left: 115px;
    margin-right: 115px;
}

.mg-x-115-force {
    margin-left: 115px !important;
    margin-right: 115px !important;
}

.mg-t-115 {
    margin-top: 115px;
}

.mg-r-115 {
    margin-right: 115px;
}

.mg-b-115 {
    margin-bottom: 115px;
}

.mg-l-115 {
    margin-left: 115px;
}

.mg-t-115-force {
    margin-top: 115px !important;
}

.mg-r-115-force {
    margin-right: 115px !important;
}

.mg-b-115-force {
    margin-bottom: 115px !important;
}

.mg-l-115-force {
    margin-left: 115px !important;
}

.mg-120 {
    margin: 120px;
}

.mg-120-force {
    margin: 120px !important;
}

.mg-y-120 {
    margin-top: 120px;
    margin-bottom: 120px;
}

.mg-y-120-force {
    margin-top: 120px !important;
    margin-bottom: 120px !important;
}

.mg-x-120 {
    margin-left: 120px;
    margin-right: 120px;
}

.mg-x-120-force {
    margin-left: 120px !important;
    margin-right: 120px !important;
}

.mg-t-120 {
    margin-top: 120px;
}

.mg-r-120 {
    margin-right: 120px;
}

.mg-b-120 {
    margin-bottom: 120px;
}

.mg-l-120 {
    margin-left: 120px;
}

.mg-t-120-force {
    margin-top: 120px !important;
}

.mg-r-120-force {
    margin-right: 120px !important;
}

.mg-b-120-force {
    margin-bottom: 120px !important;
}

.mg-l-120-force {
    margin-left: 120px !important;
}

.mg-t-auto {
    margin-top: auto;
}

.mg-r-auto {
    margin-right: auto;
}

.mg-b-auto {
    margin-bottom: auto;
}

.mg-l-auto {
    margin-left: auto;
}

.mg-x-auto {
    margin: auto;
}

.mg-l--1 {
    margin-left: -1px;
}

.mg-r--1 {
    margin-right: -1px;
}

.mg-t--1 {
    margin-top: -1px;
}

.mg-l--5 {
    margin-left: -5px;
}

.mg-r--5 {
    margin-right: -5px;
}

@media (min-width: 480px) {
    .mg-xs-0 {
        margin: 0px;
    }

    .mg-xs-0-force {
        margin: 0px !important;
    }

    .mg-xs-y-0 {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .mg-xs-y-0-force {
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

    .mg-xs-x-0 {
        margin-left: 0px;
        margin-right: 0px;
    }

    .mg-xs-x-0-force {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .mg-xs-t-0 {
        margin-top: 0px;
    }

    .mg-xs-r-0 {
        margin-right: 0px;
    }

    .mg-xs-b-0 {
        margin-bottom: 0px;
    }

    .mg-xs-l-0 {
        margin-left: 0px;
    }

    .mg-xs-t-0-force {
        margin-top: 0px !important;
    }

    .mg-xs-r-0-force {
        margin-right: 0px !important;
    }

    .mg-xs-b-0-force {
        margin-bottom: 0px !important;
    }

    .mg-xs-l-0-force {
        margin-left: 0px !important;
    }

    .mg-xs-1 {
        margin: 1px;
    }

    .mg-xs-1-force {
        margin: 1px !important;
    }

    .mg-xs-y-1 {
        margin-top: 1px;
        margin-bottom: 1px;
    }

    .mg-xs-y-1-force {
        margin-top: 1px !important;
        margin-bottom: 1px !important;
    }

    .mg-xs-x-1 {
        margin-left: 1px;
        margin-right: 1px;
    }

    .mg-xs-x-1-force {
        margin-left: 1px !important;
        margin-right: 1px !important;
    }

    .mg-xs-t-1 {
        margin-top: 1px;
    }

    .mg-xs-r-1 {
        margin-right: 1px;
    }

    .mg-xs-b-1 {
        margin-bottom: 1px;
    }

    .mg-xs-l-1 {
        margin-left: 1px;
    }

    .mg-xs-t-1-force {
        margin-top: 1px !important;
    }

    .mg-xs-r-1-force {
        margin-right: 1px !important;
    }

    .mg-xs-b-1-force {
        margin-bottom: 1px !important;
    }

    .mg-xs-l-1-force {
        margin-left: 1px !important;
    }

    .mg-xs-2 {
        margin: 2px;
    }

    .mg-xs-2-force {
        margin: 2px !important;
    }

    .mg-xs-y-2 {
        margin-top: 2px;
        margin-bottom: 2px;
    }

    .mg-xs-y-2-force {
        margin-top: 2px !important;
        margin-bottom: 2px !important;
    }

    .mg-xs-x-2 {
        margin-left: 2px;
        margin-right: 2px;
    }

    .mg-xs-x-2-force {
        margin-left: 2px !important;
        margin-right: 2px !important;
    }

    .mg-xs-t-2 {
        margin-top: 2px;
    }

    .mg-xs-r-2 {
        margin-right: 2px;
    }

    .mg-xs-b-2 {
        margin-bottom: 2px;
    }

    .mg-xs-l-2 {
        margin-left: 2px;
    }

    .mg-xs-t-2-force {
        margin-top: 2px !important;
    }

    .mg-xs-r-2-force {
        margin-right: 2px !important;
    }

    .mg-xs-b-2-force {
        margin-bottom: 2px !important;
    }

    .mg-xs-l-2-force {
        margin-left: 2px !important;
    }

    .mg-xs-3 {
        margin: 3px;
    }

    .mg-xs-3-force {
        margin: 3px !important;
    }

    .mg-xs-y-3 {
        margin-top: 3px;
        margin-bottom: 3px;
    }

    .mg-xs-y-3-force {
        margin-top: 3px !important;
        margin-bottom: 3px !important;
    }

    .mg-xs-x-3 {
        margin-left: 3px;
        margin-right: 3px;
    }

    .mg-xs-x-3-force {
        margin-left: 3px !important;
        margin-right: 3px !important;
    }

    .mg-xs-t-3 {
        margin-top: 3px;
    }

    .mg-xs-r-3 {
        margin-right: 3px;
    }

    .mg-xs-b-3 {
        margin-bottom: 3px;
    }

    .mg-xs-l-3 {
        margin-left: 3px;
    }

    .mg-xs-t-3-force {
        margin-top: 3px !important;
    }

    .mg-xs-r-3-force {
        margin-right: 3px !important;
    }

    .mg-xs-b-3-force {
        margin-bottom: 3px !important;
    }

    .mg-xs-l-3-force {
        margin-left: 3px !important;
    }

    .mg-xs-4 {
        margin: 4px;
    }

    .mg-xs-4-force {
        margin: 4px !important;
    }

    .mg-xs-y-4 {
        margin-top: 4px;
        margin-bottom: 4px;
    }

    .mg-xs-y-4-force {
        margin-top: 4px !important;
        margin-bottom: 4px !important;
    }

    .mg-xs-x-4 {
        margin-left: 4px;
        margin-right: 4px;
    }

    .mg-xs-x-4-force {
        margin-left: 4px !important;
        margin-right: 4px !important;
    }

    .mg-xs-t-4 {
        margin-top: 4px;
    }

    .mg-xs-r-4 {
        margin-right: 4px;
    }

    .mg-xs-b-4 {
        margin-bottom: 4px;
    }

    .mg-xs-l-4 {
        margin-left: 4px;
    }

    .mg-xs-t-4-force {
        margin-top: 4px !important;
    }

    .mg-xs-r-4-force {
        margin-right: 4px !important;
    }

    .mg-xs-b-4-force {
        margin-bottom: 4px !important;
    }

    .mg-xs-l-4-force {
        margin-left: 4px !important;
    }

    .mg-xs-5 {
        margin: 5px;
    }

    .mg-xs-5-force {
        margin: 5px !important;
    }

    .mg-xs-y-5 {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .mg-xs-y-5-force {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
    }

    .mg-xs-x-5 {
        margin-left: 5px;
        margin-right: 5px;
    }

    .mg-xs-x-5-force {
        margin-left: 5px !important;
        margin-right: 5px !important;
    }

    .mg-xs-t-5 {
        margin-top: 5px;
    }

    .mg-xs-r-5 {
        margin-right: 5px;
    }

    .mg-xs-b-5 {
        margin-bottom: 5px;
    }

    .mg-xs-l-5 {
        margin-left: 5px;
    }

    .mg-xs-t-5-force {
        margin-top: 5px !important;
    }

    .mg-xs-r-5-force {
        margin-right: 5px !important;
    }

    .mg-xs-b-5-force {
        margin-bottom: 5px !important;
    }

    .mg-xs-l-5-force {
        margin-left: 5px !important;
    }

    .mg-xs-6 {
        margin: 6px;
    }

    .mg-xs-6-force {
        margin: 6px !important;
    }

    .mg-xs-y-6 {
        margin-top: 6px;
        margin-bottom: 6px;
    }

    .mg-xs-y-6-force {
        margin-top: 6px !important;
        margin-bottom: 6px !important;
    }

    .mg-xs-x-6 {
        margin-left: 6px;
        margin-right: 6px;
    }

    .mg-xs-x-6-force {
        margin-left: 6px !important;
        margin-right: 6px !important;
    }

    .mg-xs-t-6 {
        margin-top: 6px;
    }

    .mg-xs-r-6 {
        margin-right: 6px;
    }

    .mg-xs-b-6 {
        margin-bottom: 6px;
    }

    .mg-xs-l-6 {
        margin-left: 6px;
    }

    .mg-xs-t-6-force {
        margin-top: 6px !important;
    }

    .mg-xs-r-6-force {
        margin-right: 6px !important;
    }

    .mg-xs-b-6-force {
        margin-bottom: 6px !important;
    }

    .mg-xs-l-6-force {
        margin-left: 6px !important;
    }

    .mg-xs-7 {
        margin: 7px;
    }

    .mg-xs-7-force {
        margin: 7px !important;
    }

    .mg-xs-y-7 {
        margin-top: 7px;
        margin-bottom: 7px;
    }

    .mg-xs-y-7-force {
        margin-top: 7px !important;
        margin-bottom: 7px !important;
    }

    .mg-xs-x-7 {
        margin-left: 7px;
        margin-right: 7px;
    }

    .mg-xs-x-7-force {
        margin-left: 7px !important;
        margin-right: 7px !important;
    }

    .mg-xs-t-7 {
        margin-top: 7px;
    }

    .mg-xs-r-7 {
        margin-right: 7px;
    }

    .mg-xs-b-7 {
        margin-bottom: 7px;
    }

    .mg-xs-l-7 {
        margin-left: 7px;
    }

    .mg-xs-t-7-force {
        margin-top: 7px !important;
    }

    .mg-xs-r-7-force {
        margin-right: 7px !important;
    }

    .mg-xs-b-7-force {
        margin-bottom: 7px !important;
    }

    .mg-xs-l-7-force {
        margin-left: 7px !important;
    }

    .mg-xs-8 {
        margin: 8px;
    }

    .mg-xs-8-force {
        margin: 8px !important;
    }

    .mg-xs-y-8 {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .mg-xs-y-8-force {
        margin-top: 8px !important;
        margin-bottom: 8px !important;
    }

    .mg-xs-x-8 {
        margin-left: 8px;
        margin-right: 8px;
    }

    .mg-xs-x-8-force {
        margin-left: 8px !important;
        margin-right: 8px !important;
    }

    .mg-xs-t-8 {
        margin-top: 8px;
    }

    .mg-xs-r-8 {
        margin-right: 8px;
    }

    .mg-xs-b-8 {
        margin-bottom: 8px;
    }

    .mg-xs-l-8 {
        margin-left: 8px;
    }

    .mg-xs-t-8-force {
        margin-top: 8px !important;
    }

    .mg-xs-r-8-force {
        margin-right: 8px !important;
    }

    .mg-xs-b-8-force {
        margin-bottom: 8px !important;
    }

    .mg-xs-l-8-force {
        margin-left: 8px !important;
    }

    .mg-xs-9 {
        margin: 9px;
    }

    .mg-xs-9-force {
        margin: 9px !important;
    }

    .mg-xs-y-9 {
        margin-top: 9px;
        margin-bottom: 9px;
    }

    .mg-xs-y-9-force {
        margin-top: 9px !important;
        margin-bottom: 9px !important;
    }

    .mg-xs-x-9 {
        margin-left: 9px;
        margin-right: 9px;
    }

    .mg-xs-x-9-force {
        margin-left: 9px !important;
        margin-right: 9px !important;
    }

    .mg-xs-t-9 {
        margin-top: 9px;
    }

    .mg-xs-r-9 {
        margin-right: 9px;
    }

    .mg-xs-b-9 {
        margin-bottom: 9px;
    }

    .mg-xs-l-9 {
        margin-left: 9px;
    }

    .mg-xs-t-9-force {
        margin-top: 9px !important;
    }

    .mg-xs-r-9-force {
        margin-right: 9px !important;
    }

    .mg-xs-b-9-force {
        margin-bottom: 9px !important;
    }

    .mg-xs-l-9-force {
        margin-left: 9px !important;
    }

    .mg-xs-10 {
        margin: 10px;
    }

    .mg-xs-10-force {
        margin: 10px !important;
    }

    .mg-xs-y-10 {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .mg-xs-y-10-force {
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }

    .mg-xs-x-10 {
        margin-left: 10px;
        margin-right: 10px;
    }

    .mg-xs-x-10-force {
        margin-left: 10px !important;
        margin-right: 10px !important;
    }

    .mg-xs-t-10 {
        margin-top: 10px;
    }

    .mg-xs-r-10 {
        margin-right: 10px;
    }

    .mg-xs-b-10 {
        margin-bottom: 10px;
    }

    .mg-xs-l-10 {
        margin-left: 10px;
    }

    .mg-xs-t-10-force {
        margin-top: 10px !important;
    }

    .mg-xs-r-10-force {
        margin-right: 10px !important;
    }

    .mg-xs-b-10-force {
        margin-bottom: 10px !important;
    }

    .mg-xs-l-10-force {
        margin-left: 10px !important;
    }

    .mg-xs-15 {
        margin: 15px;
    }

    .mg-xs-15-force {
        margin: 15px !important;
    }

    .mg-xs-y-15 {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .mg-xs-y-15-force {
        margin-top: 15px !important;
        margin-bottom: 15px !important;
    }

    .mg-xs-x-15 {
        margin-left: 15px;
        margin-right: 15px;
    }

    .mg-xs-x-15-force {
        margin-left: 15px !important;
        margin-right: 15px !important;
    }

    .mg-xs-t-15 {
        margin-top: 15px;
    }

    .mg-xs-r-15 {
        margin-right: 15px;
    }

    .mg-xs-b-15 {
        margin-bottom: 15px;
    }

    .mg-xs-l-15 {
        margin-left: 15px;
    }

    .mg-xs-t-15-force {
        margin-top: 15px !important;
    }

    .mg-xs-r-15-force {
        margin-right: 15px !important;
    }

    .mg-xs-b-15-force {
        margin-bottom: 15px !important;
    }

    .mg-xs-l-15-force {
        margin-left: 15px !important;
    }

    .mg-xs-20 {
        margin: 20px;
    }

    .mg-xs-20-force {
        margin: 20px !important;
    }

    .mg-xs-y-20 {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .mg-xs-y-20-force {
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }

    .mg-xs-x-20 {
        margin-left: 20px;
        margin-right: 20px;
    }

    .mg-xs-x-20-force {
        margin-left: 20px !important;
        margin-right: 20px !important;
    }

    .mg-xs-t-20 {
        margin-top: 20px;
    }

    .mg-xs-r-20 {
        margin-right: 20px;
    }

    .mg-xs-b-20 {
        margin-bottom: 20px;
    }

    .mg-xs-l-20 {
        margin-left: 20px;
    }

    .mg-xs-t-20-force {
        margin-top: 20px !important;
    }

    .mg-xs-r-20-force {
        margin-right: 20px !important;
    }

    .mg-xs-b-20-force {
        margin-bottom: 20px !important;
    }

    .mg-xs-l-20-force {
        margin-left: 20px !important;
    }

    .mg-xs-25 {
        margin: 25px;
    }

    .mg-xs-25-force {
        margin: 25px !important;
    }

    .mg-xs-y-25 {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .mg-xs-y-25-force {
        margin-top: 25px !important;
        margin-bottom: 25px !important;
    }

    .mg-xs-x-25 {
        margin-left: 25px;
        margin-right: 25px;
    }

    .mg-xs-x-25-force {
        margin-left: 25px !important;
        margin-right: 25px !important;
    }

    .mg-xs-t-25 {
        margin-top: 25px;
    }

    .mg-xs-r-25 {
        margin-right: 25px;
    }

    .mg-xs-b-25 {
        margin-bottom: 25px;
    }

    .mg-xs-l-25 {
        margin-left: 25px;
    }

    .mg-xs-t-25-force {
        margin-top: 25px !important;
    }

    .mg-xs-r-25-force {
        margin-right: 25px !important;
    }

    .mg-xs-b-25-force {
        margin-bottom: 25px !important;
    }

    .mg-xs-l-25-force {
        margin-left: 25px !important;
    }

    .mg-xs-30 {
        margin: 30px;
    }

    .mg-xs-30-force {
        margin: 30px !important;
    }

    .mg-xs-y-30 {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .mg-xs-y-30-force {
        margin-top: 30px !important;
        margin-bottom: 30px !important;
    }

    .mg-xs-x-30 {
        margin-left: 30px;
        margin-right: 30px;
    }

    .mg-xs-x-30-force {
        margin-left: 30px !important;
        margin-right: 30px !important;
    }

    .mg-xs-t-30 {
        margin-top: 30px;
    }

    .mg-xs-r-30 {
        margin-right: 30px;
    }

    .mg-xs-b-30 {
        margin-bottom: 30px;
    }

    .mg-xs-l-30 {
        margin-left: 30px;
    }

    .mg-xs-t-30-force {
        margin-top: 30px !important;
    }

    .mg-xs-r-30-force {
        margin-right: 30px !important;
    }

    .mg-xs-b-30-force {
        margin-bottom: 30px !important;
    }

    .mg-xs-l-30-force {
        margin-left: 30px !important;
    }

    .mg-xs-35 {
        margin: 35px;
    }

    .mg-xs-35-force {
        margin: 35px !important;
    }

    .mg-xs-y-35 {
        margin-top: 35px;
        margin-bottom: 35px;
    }

    .mg-xs-y-35-force {
        margin-top: 35px !important;
        margin-bottom: 35px !important;
    }

    .mg-xs-x-35 {
        margin-left: 35px;
        margin-right: 35px;
    }

    .mg-xs-x-35-force {
        margin-left: 35px !important;
        margin-right: 35px !important;
    }

    .mg-xs-t-35 {
        margin-top: 35px;
    }

    .mg-xs-r-35 {
        margin-right: 35px;
    }

    .mg-xs-b-35 {
        margin-bottom: 35px;
    }

    .mg-xs-l-35 {
        margin-left: 35px;
    }

    .mg-xs-t-35-force {
        margin-top: 35px !important;
    }

    .mg-xs-r-35-force {
        margin-right: 35px !important;
    }

    .mg-xs-b-35-force {
        margin-bottom: 35px !important;
    }

    .mg-xs-l-35-force {
        margin-left: 35px !important;
    }

    .mg-xs-40 {
        margin: 40px;
    }

    .mg-xs-40-force {
        margin: 40px !important;
    }

    .mg-xs-y-40 {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .mg-xs-y-40-force {
        margin-top: 40px !important;
        margin-bottom: 40px !important;
    }

    .mg-xs-x-40 {
        margin-left: 40px;
        margin-right: 40px;
    }

    .mg-xs-x-40-force {
        margin-left: 40px !important;
        margin-right: 40px !important;
    }

    .mg-xs-t-40 {
        margin-top: 40px;
    }

    .mg-xs-r-40 {
        margin-right: 40px;
    }

    .mg-xs-b-40 {
        margin-bottom: 40px;
    }

    .mg-xs-l-40 {
        margin-left: 40px;
    }

    .mg-xs-t-40-force {
        margin-top: 40px !important;
    }

    .mg-xs-r-40-force {
        margin-right: 40px !important;
    }

    .mg-xs-b-40-force {
        margin-bottom: 40px !important;
    }

    .mg-xs-l-40-force {
        margin-left: 40px !important;
    }

    .mg-xs-45 {
        margin: 45px;
    }

    .mg-xs-45-force {
        margin: 45px !important;
    }

    .mg-xs-y-45 {
        margin-top: 45px;
        margin-bottom: 45px;
    }

    .mg-xs-y-45-force {
        margin-top: 45px !important;
        margin-bottom: 45px !important;
    }

    .mg-xs-x-45 {
        margin-left: 45px;
        margin-right: 45px;
    }

    .mg-xs-x-45-force {
        margin-left: 45px !important;
        margin-right: 45px !important;
    }

    .mg-xs-t-45 {
        margin-top: 45px;
    }

    .mg-xs-r-45 {
        margin-right: 45px;
    }

    .mg-xs-b-45 {
        margin-bottom: 45px;
    }

    .mg-xs-l-45 {
        margin-left: 45px;
    }

    .mg-xs-t-45-force {
        margin-top: 45px !important;
    }

    .mg-xs-r-45-force {
        margin-right: 45px !important;
    }

    .mg-xs-b-45-force {
        margin-bottom: 45px !important;
    }

    .mg-xs-l-45-force {
        margin-left: 45px !important;
    }

    .mg-xs-50 {
        margin: 50px;
    }

    .mg-xs-50-force {
        margin: 50px !important;
    }

    .mg-xs-y-50 {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .mg-xs-y-50-force {
        margin-top: 50px !important;
        margin-bottom: 50px !important;
    }

    .mg-xs-x-50 {
        margin-left: 50px;
        margin-right: 50px;
    }

    .mg-xs-x-50-force {
        margin-left: 50px !important;
        margin-right: 50px !important;
    }

    .mg-xs-t-50 {
        margin-top: 50px;
    }

    .mg-xs-r-50 {
        margin-right: 50px;
    }

    .mg-xs-b-50 {
        margin-bottom: 50px;
    }

    .mg-xs-l-50 {
        margin-left: 50px;
    }

    .mg-xs-t-50-force {
        margin-top: 50px !important;
    }

    .mg-xs-r-50-force {
        margin-right: 50px !important;
    }

    .mg-xs-b-50-force {
        margin-bottom: 50px !important;
    }

    .mg-xs-l-50-force {
        margin-left: 50px !important;
    }

    .mg-xs-55 {
        margin: 55px;
    }

    .mg-xs-55-force {
        margin: 55px !important;
    }

    .mg-xs-y-55 {
        margin-top: 55px;
        margin-bottom: 55px;
    }

    .mg-xs-y-55-force {
        margin-top: 55px !important;
        margin-bottom: 55px !important;
    }

    .mg-xs-x-55 {
        margin-left: 55px;
        margin-right: 55px;
    }

    .mg-xs-x-55-force {
        margin-left: 55px !important;
        margin-right: 55px !important;
    }

    .mg-xs-t-55 {
        margin-top: 55px;
    }

    .mg-xs-r-55 {
        margin-right: 55px;
    }

    .mg-xs-b-55 {
        margin-bottom: 55px;
    }

    .mg-xs-l-55 {
        margin-left: 55px;
    }

    .mg-xs-t-55-force {
        margin-top: 55px !important;
    }

    .mg-xs-r-55-force {
        margin-right: 55px !important;
    }

    .mg-xs-b-55-force {
        margin-bottom: 55px !important;
    }

    .mg-xs-l-55-force {
        margin-left: 55px !important;
    }

    .mg-xs-60 {
        margin: 60px;
    }

    .mg-xs-60-force {
        margin: 60px !important;
    }

    .mg-xs-y-60 {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .mg-xs-y-60-force {
        margin-top: 60px !important;
        margin-bottom: 60px !important;
    }

    .mg-xs-x-60 {
        margin-left: 60px;
        margin-right: 60px;
    }

    .mg-xs-x-60-force {
        margin-left: 60px !important;
        margin-right: 60px !important;
    }

    .mg-xs-t-60 {
        margin-top: 60px;
    }

    .mg-xs-r-60 {
        margin-right: 60px;
    }

    .mg-xs-b-60 {
        margin-bottom: 60px;
    }

    .mg-xs-l-60 {
        margin-left: 60px;
    }

    .mg-xs-t-60-force {
        margin-top: 60px !important;
    }

    .mg-xs-r-60-force {
        margin-right: 60px !important;
    }

    .mg-xs-b-60-force {
        margin-bottom: 60px !important;
    }

    .mg-xs-l-60-force {
        margin-left: 60px !important;
    }

    .mg-xs-65 {
        margin: 65px;
    }

    .mg-xs-65-force {
        margin: 65px !important;
    }

    .mg-xs-y-65 {
        margin-top: 65px;
        margin-bottom: 65px;
    }

    .mg-xs-y-65-force {
        margin-top: 65px !important;
        margin-bottom: 65px !important;
    }

    .mg-xs-x-65 {
        margin-left: 65px;
        margin-right: 65px;
    }

    .mg-xs-x-65-force {
        margin-left: 65px !important;
        margin-right: 65px !important;
    }

    .mg-xs-t-65 {
        margin-top: 65px;
    }

    .mg-xs-r-65 {
        margin-right: 65px;
    }

    .mg-xs-b-65 {
        margin-bottom: 65px;
    }

    .mg-xs-l-65 {
        margin-left: 65px;
    }

    .mg-xs-t-65-force {
        margin-top: 65px !important;
    }

    .mg-xs-r-65-force {
        margin-right: 65px !important;
    }

    .mg-xs-b-65-force {
        margin-bottom: 65px !important;
    }

    .mg-xs-l-65-force {
        margin-left: 65px !important;
    }

    .mg-xs-70 {
        margin: 70px;
    }

    .mg-xs-70-force {
        margin: 70px !important;
    }

    .mg-xs-y-70 {
        margin-top: 70px;
        margin-bottom: 70px;
    }

    .mg-xs-y-70-force {
        margin-top: 70px !important;
        margin-bottom: 70px !important;
    }

    .mg-xs-x-70 {
        margin-left: 70px;
        margin-right: 70px;
    }

    .mg-xs-x-70-force {
        margin-left: 70px !important;
        margin-right: 70px !important;
    }

    .mg-xs-t-70 {
        margin-top: 70px;
    }

    .mg-xs-r-70 {
        margin-right: 70px;
    }

    .mg-xs-b-70 {
        margin-bottom: 70px;
    }

    .mg-xs-l-70 {
        margin-left: 70px;
    }

    .mg-xs-t-70-force {
        margin-top: 70px !important;
    }

    .mg-xs-r-70-force {
        margin-right: 70px !important;
    }

    .mg-xs-b-70-force {
        margin-bottom: 70px !important;
    }

    .mg-xs-l-70-force {
        margin-left: 70px !important;
    }

    .mg-xs-75 {
        margin: 75px;
    }

    .mg-xs-75-force {
        margin: 75px !important;
    }

    .mg-xs-y-75 {
        margin-top: 75px;
        margin-bottom: 75px;
    }

    .mg-xs-y-75-force {
        margin-top: 75px !important;
        margin-bottom: 75px !important;
    }

    .mg-xs-x-75 {
        margin-left: 75px;
        margin-right: 75px;
    }

    .mg-xs-x-75-force {
        margin-left: 75px !important;
        margin-right: 75px !important;
    }

    .mg-xs-t-75 {
        margin-top: 75px;
    }

    .mg-xs-r-75 {
        margin-right: 75px;
    }

    .mg-xs-b-75 {
        margin-bottom: 75px;
    }

    .mg-xs-l-75 {
        margin-left: 75px;
    }

    .mg-xs-t-75-force {
        margin-top: 75px !important;
    }

    .mg-xs-r-75-force {
        margin-right: 75px !important;
    }

    .mg-xs-b-75-force {
        margin-bottom: 75px !important;
    }

    .mg-xs-l-75-force {
        margin-left: 75px !important;
    }

    .mg-xs-80 {
        margin: 80px;
    }

    .mg-xs-80-force {
        margin: 80px !important;
    }

    .mg-xs-y-80 {
        margin-top: 80px;
        margin-bottom: 80px;
    }

    .mg-xs-y-80-force {
        margin-top: 80px !important;
        margin-bottom: 80px !important;
    }

    .mg-xs-x-80 {
        margin-left: 80px;
        margin-right: 80px;
    }

    .mg-xs-x-80-force {
        margin-left: 80px !important;
        margin-right: 80px !important;
    }

    .mg-xs-t-80 {
        margin-top: 80px;
    }

    .mg-xs-r-80 {
        margin-right: 80px;
    }

    .mg-xs-b-80 {
        margin-bottom: 80px;
    }

    .mg-xs-l-80 {
        margin-left: 80px;
    }

    .mg-xs-t-80-force {
        margin-top: 80px !important;
    }

    .mg-xs-r-80-force {
        margin-right: 80px !important;
    }

    .mg-xs-b-80-force {
        margin-bottom: 80px !important;
    }

    .mg-xs-l-80-force {
        margin-left: 80px !important;
    }

    .mg-xs-85 {
        margin: 85px;
    }

    .mg-xs-85-force {
        margin: 85px !important;
    }

    .mg-xs-y-85 {
        margin-top: 85px;
        margin-bottom: 85px;
    }

    .mg-xs-y-85-force {
        margin-top: 85px !important;
        margin-bottom: 85px !important;
    }

    .mg-xs-x-85 {
        margin-left: 85px;
        margin-right: 85px;
    }

    .mg-xs-x-85-force {
        margin-left: 85px !important;
        margin-right: 85px !important;
    }

    .mg-xs-t-85 {
        margin-top: 85px;
    }

    .mg-xs-r-85 {
        margin-right: 85px;
    }

    .mg-xs-b-85 {
        margin-bottom: 85px;
    }

    .mg-xs-l-85 {
        margin-left: 85px;
    }

    .mg-xs-t-85-force {
        margin-top: 85px !important;
    }

    .mg-xs-r-85-force {
        margin-right: 85px !important;
    }

    .mg-xs-b-85-force {
        margin-bottom: 85px !important;
    }

    .mg-xs-l-85-force {
        margin-left: 85px !important;
    }

    .mg-xs-90 {
        margin: 90px;
    }

    .mg-xs-90-force {
        margin: 90px !important;
    }

    .mg-xs-y-90 {
        margin-top: 90px;
        margin-bottom: 90px;
    }

    .mg-xs-y-90-force {
        margin-top: 90px !important;
        margin-bottom: 90px !important;
    }

    .mg-xs-x-90 {
        margin-left: 90px;
        margin-right: 90px;
    }

    .mg-xs-x-90-force {
        margin-left: 90px !important;
        margin-right: 90px !important;
    }

    .mg-xs-t-90 {
        margin-top: 90px;
    }

    .mg-xs-r-90 {
        margin-right: 90px;
    }

    .mg-xs-b-90 {
        margin-bottom: 90px;
    }

    .mg-xs-l-90 {
        margin-left: 90px;
    }

    .mg-xs-t-90-force {
        margin-top: 90px !important;
    }

    .mg-xs-r-90-force {
        margin-right: 90px !important;
    }

    .mg-xs-b-90-force {
        margin-bottom: 90px !important;
    }

    .mg-xs-l-90-force {
        margin-left: 90px !important;
    }

    .mg-xs-95 {
        margin: 95px;
    }

    .mg-xs-95-force {
        margin: 95px !important;
    }

    .mg-xs-y-95 {
        margin-top: 95px;
        margin-bottom: 95px;
    }

    .mg-xs-y-95-force {
        margin-top: 95px !important;
        margin-bottom: 95px !important;
    }

    .mg-xs-x-95 {
        margin-left: 95px;
        margin-right: 95px;
    }

    .mg-xs-x-95-force {
        margin-left: 95px !important;
        margin-right: 95px !important;
    }

    .mg-xs-t-95 {
        margin-top: 95px;
    }

    .mg-xs-r-95 {
        margin-right: 95px;
    }

    .mg-xs-b-95 {
        margin-bottom: 95px;
    }

    .mg-xs-l-95 {
        margin-left: 95px;
    }

    .mg-xs-t-95-force {
        margin-top: 95px !important;
    }

    .mg-xs-r-95-force {
        margin-right: 95px !important;
    }

    .mg-xs-b-95-force {
        margin-bottom: 95px !important;
    }

    .mg-xs-l-95-force {
        margin-left: 95px !important;
    }

    .mg-xs-100 {
        margin: 100px;
    }

    .mg-xs-100-force {
        margin: 100px !important;
    }

    .mg-xs-y-100 {
        margin-top: 100px;
        margin-bottom: 100px;
    }

    .mg-xs-y-100-force {
        margin-top: 100px !important;
        margin-bottom: 100px !important;
    }

    .mg-xs-x-100 {
        margin-left: 100px;
        margin-right: 100px;
    }

    .mg-xs-x-100-force {
        margin-left: 100px !important;
        margin-right: 100px !important;
    }

    .mg-xs-t-100 {
        margin-top: 100px;
    }

    .mg-xs-r-100 {
        margin-right: 100px;
    }

    .mg-xs-b-100 {
        margin-bottom: 100px;
    }

    .mg-xs-l-100 {
        margin-left: 100px;
    }

    .mg-xs-t-100-force {
        margin-top: 100px !important;
    }

    .mg-xs-r-100-force {
        margin-right: 100px !important;
    }

    .mg-xs-b-100-force {
        margin-bottom: 100px !important;
    }

    .mg-xs-l-100-force {
        margin-left: 100px !important;
    }

    .mg-xs-t-auto {
        margin-top: auto;
    }

    .mg-xs-r-auto {
        margin-right: auto;
    }

    .mg-xs-b-auto {
        margin-bottom: auto;
    }

    .mg-xs-l-auto {
        margin-left: auto;
    }

    .mg-xs-auto {
        margin: auto;
    }

    .mg-xs-x-auto {
        margin-left: auto;
        margin-right: auto;
    }

    .mg-xs-l--1 {
        margin-left: -1px;
    }

    .mg-xs-r--1 {
        margin-right: -1px;
    }

    .mg-xs-t--1 {
        margin-top: -1px;
    }
}

@media (min-width: 576px) {
    .mg-sm-0 {
        margin: 0px;
    }

    .mg-sm-0-force {
        margin: 0px !important;
    }

    .mg-sm-y-0 {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .mg-sm-y-0-force {
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

    .mg-sm-x-0 {
        margin-left: 0px;
        margin-right: 0px;
    }

    .mg-sm-x-0-force {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .mg-sm-t-0 {
        margin-top: 0px;
    }

    .mg-sm-r-0 {
        margin-right: 0px;
    }

    .mg-sm-b-0 {
        margin-bottom: 0px;
    }

    .mg-sm-l-0 {
        margin-left: 0px;
    }

    .mg-sm-t-0-force {
        margin-top: 0px !important;
    }

    .mg-sm-r-0-force {
        margin-right: 0px !important;
    }

    .mg-sm-b-0-force {
        margin-bottom: 0px !important;
    }

    .mg-sm-l-0-force {
        margin-left: 0px !important;
    }

    .mg-sm-1 {
        margin: 1px;
    }

    .mg-sm-1-force {
        margin: 1px !important;
    }

    .mg-sm-y-1 {
        margin-top: 1px;
        margin-bottom: 1px;
    }

    .mg-sm-y-1-force {
        margin-top: 1px !important;
        margin-bottom: 1px !important;
    }

    .mg-sm-x-1 {
        margin-left: 1px;
        margin-right: 1px;
    }

    .mg-sm-x-1-force {
        margin-left: 1px !important;
        margin-right: 1px !important;
    }

    .mg-sm-t-1 {
        margin-top: 1px;
    }

    .mg-sm-r-1 {
        margin-right: 1px;
    }

    .mg-sm-b-1 {
        margin-bottom: 1px;
    }

    .mg-sm-l-1 {
        margin-left: 1px;
    }

    .mg-sm-t-1-force {
        margin-top: 1px !important;
    }

    .mg-sm-r-1-force {
        margin-right: 1px !important;
    }

    .mg-sm-b-1-force {
        margin-bottom: 1px !important;
    }

    .mg-sm-l-1-force {
        margin-left: 1px !important;
    }

    .mg-sm-2 {
        margin: 2px;
    }

    .mg-sm-2-force {
        margin: 2px !important;
    }

    .mg-sm-y-2 {
        margin-top: 2px;
        margin-bottom: 2px;
    }

    .mg-sm-y-2-force {
        margin-top: 2px !important;
        margin-bottom: 2px !important;
    }

    .mg-sm-x-2 {
        margin-left: 2px;
        margin-right: 2px;
    }

    .mg-sm-x-2-force {
        margin-left: 2px !important;
        margin-right: 2px !important;
    }

    .mg-sm-t-2 {
        margin-top: 2px;
    }

    .mg-sm-r-2 {
        margin-right: 2px;
    }

    .mg-sm-b-2 {
        margin-bottom: 2px;
    }

    .mg-sm-l-2 {
        margin-left: 2px;
    }

    .mg-sm-t-2-force {
        margin-top: 2px !important;
    }

    .mg-sm-r-2-force {
        margin-right: 2px !important;
    }

    .mg-sm-b-2-force {
        margin-bottom: 2px !important;
    }

    .mg-sm-l-2-force {
        margin-left: 2px !important;
    }

    .mg-sm-3 {
        margin: 3px;
    }

    .mg-sm-3-force {
        margin: 3px !important;
    }

    .mg-sm-y-3 {
        margin-top: 3px;
        margin-bottom: 3px;
    }

    .mg-sm-y-3-force {
        margin-top: 3px !important;
        margin-bottom: 3px !important;
    }

    .mg-sm-x-3 {
        margin-left: 3px;
        margin-right: 3px;
    }

    .mg-sm-x-3-force {
        margin-left: 3px !important;
        margin-right: 3px !important;
    }

    .mg-sm-t-3 {
        margin-top: 3px;
    }

    .mg-sm-r-3 {
        margin-right: 3px;
    }

    .mg-sm-b-3 {
        margin-bottom: 3px;
    }

    .mg-sm-l-3 {
        margin-left: 3px;
    }

    .mg-sm-t-3-force {
        margin-top: 3px !important;
    }

    .mg-sm-r-3-force {
        margin-right: 3px !important;
    }

    .mg-sm-b-3-force {
        margin-bottom: 3px !important;
    }

    .mg-sm-l-3-force {
        margin-left: 3px !important;
    }

    .mg-sm-4 {
        margin: 4px;
    }

    .mg-sm-4-force {
        margin: 4px !important;
    }

    .mg-sm-y-4 {
        margin-top: 4px;
        margin-bottom: 4px;
    }

    .mg-sm-y-4-force {
        margin-top: 4px !important;
        margin-bottom: 4px !important;
    }

    .mg-sm-x-4 {
        margin-left: 4px;
        margin-right: 4px;
    }

    .mg-sm-x-4-force {
        margin-left: 4px !important;
        margin-right: 4px !important;
    }

    .mg-sm-t-4 {
        margin-top: 4px;
    }

    .mg-sm-r-4 {
        margin-right: 4px;
    }

    .mg-sm-b-4 {
        margin-bottom: 4px;
    }

    .mg-sm-l-4 {
        margin-left: 4px;
    }

    .mg-sm-t-4-force {
        margin-top: 4px !important;
    }

    .mg-sm-r-4-force {
        margin-right: 4px !important;
    }

    .mg-sm-b-4-force {
        margin-bottom: 4px !important;
    }

    .mg-sm-l-4-force {
        margin-left: 4px !important;
    }

    .mg-sm-5 {
        margin: 5px;
    }

    .mg-sm-5-force {
        margin: 5px !important;
    }

    .mg-sm-y-5 {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .mg-sm-y-5-force {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
    }

    .mg-sm-x-5 {
        margin-left: 5px;
        margin-right: 5px;
    }

    .mg-sm-x-5-force {
        margin-left: 5px !important;
        margin-right: 5px !important;
    }

    .mg-sm-t-5 {
        margin-top: 5px;
    }

    .mg-sm-r-5 {
        margin-right: 5px;
    }

    .mg-sm-b-5 {
        margin-bottom: 5px;
    }

    .mg-sm-l-5 {
        margin-left: 5px;
    }

    .mg-sm-t-5-force {
        margin-top: 5px !important;
    }

    .mg-sm-r-5-force {
        margin-right: 5px !important;
    }

    .mg-sm-b-5-force {
        margin-bottom: 5px !important;
    }

    .mg-sm-l-5-force {
        margin-left: 5px !important;
    }

    .mg-sm-6 {
        margin: 6px;
    }

    .mg-sm-6-force {
        margin: 6px !important;
    }

    .mg-sm-y-6 {
        margin-top: 6px;
        margin-bottom: 6px;
    }

    .mg-sm-y-6-force {
        margin-top: 6px !important;
        margin-bottom: 6px !important;
    }

    .mg-sm-x-6 {
        margin-left: 6px;
        margin-right: 6px;
    }

    .mg-sm-x-6-force {
        margin-left: 6px !important;
        margin-right: 6px !important;
    }

    .mg-sm-t-6 {
        margin-top: 6px;
    }

    .mg-sm-r-6 {
        margin-right: 6px;
    }

    .mg-sm-b-6 {
        margin-bottom: 6px;
    }

    .mg-sm-l-6 {
        margin-left: 6px;
    }

    .mg-sm-t-6-force {
        margin-top: 6px !important;
    }

    .mg-sm-r-6-force {
        margin-right: 6px !important;
    }

    .mg-sm-b-6-force {
        margin-bottom: 6px !important;
    }

    .mg-sm-l-6-force {
        margin-left: 6px !important;
    }

    .mg-sm-7 {
        margin: 7px;
    }

    .mg-sm-7-force {
        margin: 7px !important;
    }

    .mg-sm-y-7 {
        margin-top: 7px;
        margin-bottom: 7px;
    }

    .mg-sm-y-7-force {
        margin-top: 7px !important;
        margin-bottom: 7px !important;
    }

    .mg-sm-x-7 {
        margin-left: 7px;
        margin-right: 7px;
    }

    .mg-sm-x-7-force {
        margin-left: 7px !important;
        margin-right: 7px !important;
    }

    .mg-sm-t-7 {
        margin-top: 7px;
    }

    .mg-sm-r-7 {
        margin-right: 7px;
    }

    .mg-sm-b-7 {
        margin-bottom: 7px;
    }

    .mg-sm-l-7 {
        margin-left: 7px;
    }

    .mg-sm-t-7-force {
        margin-top: 7px !important;
    }

    .mg-sm-r-7-force {
        margin-right: 7px !important;
    }

    .mg-sm-b-7-force {
        margin-bottom: 7px !important;
    }

    .mg-sm-l-7-force {
        margin-left: 7px !important;
    }

    .mg-sm-8 {
        margin: 8px;
    }

    .mg-sm-8-force {
        margin: 8px !important;
    }

    .mg-sm-y-8 {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .mg-sm-y-8-force {
        margin-top: 8px !important;
        margin-bottom: 8px !important;
    }

    .mg-sm-x-8 {
        margin-left: 8px;
        margin-right: 8px;
    }

    .mg-sm-x-8-force {
        margin-left: 8px !important;
        margin-right: 8px !important;
    }

    .mg-sm-t-8 {
        margin-top: 8px;
    }

    .mg-sm-r-8 {
        margin-right: 8px;
    }

    .mg-sm-b-8 {
        margin-bottom: 8px;
    }

    .mg-sm-l-8 {
        margin-left: 8px;
    }

    .mg-sm-t-8-force {
        margin-top: 8px !important;
    }

    .mg-sm-r-8-force {
        margin-right: 8px !important;
    }

    .mg-sm-b-8-force {
        margin-bottom: 8px !important;
    }

    .mg-sm-l-8-force {
        margin-left: 8px !important;
    }

    .mg-sm-9 {
        margin: 9px;
    }

    .mg-sm-9-force {
        margin: 9px !important;
    }

    .mg-sm-y-9 {
        margin-top: 9px;
        margin-bottom: 9px;
    }

    .mg-sm-y-9-force {
        margin-top: 9px !important;
        margin-bottom: 9px !important;
    }

    .mg-sm-x-9 {
        margin-left: 9px;
        margin-right: 9px;
    }

    .mg-sm-x-9-force {
        margin-left: 9px !important;
        margin-right: 9px !important;
    }

    .mg-sm-t-9 {
        margin-top: 9px;
    }

    .mg-sm-r-9 {
        margin-right: 9px;
    }

    .mg-sm-b-9 {
        margin-bottom: 9px;
    }

    .mg-sm-l-9 {
        margin-left: 9px;
    }

    .mg-sm-t-9-force {
        margin-top: 9px !important;
    }

    .mg-sm-r-9-force {
        margin-right: 9px !important;
    }

    .mg-sm-b-9-force {
        margin-bottom: 9px !important;
    }

    .mg-sm-l-9-force {
        margin-left: 9px !important;
    }

    .mg-sm-10 {
        margin: 10px;
    }

    .mg-sm-10-force {
        margin: 10px !important;
    }

    .mg-sm-y-10 {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .mg-sm-y-10-force {
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }

    .mg-sm-x-10 {
        margin-left: 10px;
        margin-right: 10px;
    }

    .mg-sm-x-10-force {
        margin-left: 10px !important;
        margin-right: 10px !important;
    }

    .mg-sm-t-10 {
        margin-top: 10px;
    }

    .mg-sm-r-10 {
        margin-right: 10px;
    }

    .mg-sm-b-10 {
        margin-bottom: 10px;
    }

    .mg-sm-l-10 {
        margin-left: 10px;
    }

    .mg-sm-t-10-force {
        margin-top: 10px !important;
    }

    .mg-sm-r-10-force {
        margin-right: 10px !important;
    }

    .mg-sm-b-10-force {
        margin-bottom: 10px !important;
    }

    .mg-sm-l-10-force {
        margin-left: 10px !important;
    }

    .mg-sm-15 {
        margin: 15px;
    }

    .mg-sm-15-force {
        margin: 15px !important;
    }

    .mg-sm-y-15 {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .mg-sm-y-15-force {
        margin-top: 15px !important;
        margin-bottom: 15px !important;
    }

    .mg-sm-x-15 {
        margin-left: 15px;
        margin-right: 15px;
    }

    .mg-sm-x-15-force {
        margin-left: 15px !important;
        margin-right: 15px !important;
    }

    .mg-sm-t-15 {
        margin-top: 15px;
    }

    .mg-sm-r-15 {
        margin-right: 15px;
    }

    .mg-sm-b-15 {
        margin-bottom: 15px;
    }

    .mg-sm-l-15 {
        margin-left: 15px;
    }

    .mg-sm-t-15-force {
        margin-top: 15px !important;
    }

    .mg-sm-r-15-force {
        margin-right: 15px !important;
    }

    .mg-sm-b-15-force {
        margin-bottom: 15px !important;
    }

    .mg-sm-l-15-force {
        margin-left: 15px !important;
    }

    .mg-sm-20 {
        margin: 20px;
    }

    .mg-sm-20-force {
        margin: 20px !important;
    }

    .mg-sm-y-20 {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .mg-sm-y-20-force {
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }

    .mg-sm-x-20 {
        margin-left: 20px;
        margin-right: 20px;
    }

    .mg-sm-x-20-force {
        margin-left: 20px !important;
        margin-right: 20px !important;
    }

    .mg-sm-t-20 {
        margin-top: 20px;
    }

    .mg-sm-r-20 {
        margin-right: 20px;
    }

    .mg-sm-b-20 {
        margin-bottom: 20px;
    }

    .mg-sm-l-20 {
        margin-left: 20px;
    }

    .mg-sm-t-20-force {
        margin-top: 20px !important;
    }

    .mg-sm-r-20-force {
        margin-right: 20px !important;
    }

    .mg-sm-b-20-force {
        margin-bottom: 20px !important;
    }

    .mg-sm-l-20-force {
        margin-left: 20px !important;
    }

    .mg-sm-25 {
        margin: 25px;
    }

    .mg-sm-25-force {
        margin: 25px !important;
    }

    .mg-sm-y-25 {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .mg-sm-y-25-force {
        margin-top: 25px !important;
        margin-bottom: 25px !important;
    }

    .mg-sm-x-25 {
        margin-left: 25px;
        margin-right: 25px;
    }

    .mg-sm-x-25-force {
        margin-left: 25px !important;
        margin-right: 25px !important;
    }

    .mg-sm-t-25 {
        margin-top: 25px;
    }

    .mg-sm-r-25 {
        margin-right: 25px;
    }

    .mg-sm-b-25 {
        margin-bottom: 25px;
    }

    .mg-sm-l-25 {
        margin-left: 25px;
    }

    .mg-sm-t-25-force {
        margin-top: 25px !important;
    }

    .mg-sm-r-25-force {
        margin-right: 25px !important;
    }

    .mg-sm-b-25-force {
        margin-bottom: 25px !important;
    }

    .mg-sm-l-25-force {
        margin-left: 25px !important;
    }

    .mg-sm-30 {
        margin: 30px;
    }

    .mg-sm-30-force {
        margin: 30px !important;
    }

    .mg-sm-y-30 {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .mg-sm-y-30-force {
        margin-top: 30px !important;
        margin-bottom: 30px !important;
    }

    .mg-sm-x-30 {
        margin-left: 30px;
        margin-right: 30px;
    }

    .mg-sm-x-30-force {
        margin-left: 30px !important;
        margin-right: 30px !important;
    }

    .mg-sm-t-30 {
        margin-top: 30px;
    }

    .mg-sm-r-30 {
        margin-right: 30px;
    }

    .mg-sm-b-30 {
        margin-bottom: 30px;
    }

    .mg-sm-l-30 {
        margin-left: 30px;
    }

    .mg-sm-t-30-force {
        margin-top: 30px !important;
    }

    .mg-sm-r-30-force {
        margin-right: 30px !important;
    }

    .mg-sm-b-30-force {
        margin-bottom: 30px !important;
    }

    .mg-sm-l-30-force {
        margin-left: 30px !important;
    }

    .mg-sm-35 {
        margin: 35px;
    }

    .mg-sm-35-force {
        margin: 35px !important;
    }

    .mg-sm-y-35 {
        margin-top: 35px;
        margin-bottom: 35px;
    }

    .mg-sm-y-35-force {
        margin-top: 35px !important;
        margin-bottom: 35px !important;
    }

    .mg-sm-x-35 {
        margin-left: 35px;
        margin-right: 35px;
    }

    .mg-sm-x-35-force {
        margin-left: 35px !important;
        margin-right: 35px !important;
    }

    .mg-sm-t-35 {
        margin-top: 35px;
    }

    .mg-sm-r-35 {
        margin-right: 35px;
    }

    .mg-sm-b-35 {
        margin-bottom: 35px;
    }

    .mg-sm-l-35 {
        margin-left: 35px;
    }

    .mg-sm-t-35-force {
        margin-top: 35px !important;
    }

    .mg-sm-r-35-force {
        margin-right: 35px !important;
    }

    .mg-sm-b-35-force {
        margin-bottom: 35px !important;
    }

    .mg-sm-l-35-force {
        margin-left: 35px !important;
    }

    .mg-sm-40 {
        margin: 40px;
    }

    .mg-sm-40-force {
        margin: 40px !important;
    }

    .mg-sm-y-40 {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .mg-sm-y-40-force {
        margin-top: 40px !important;
        margin-bottom: 40px !important;
    }

    .mg-sm-x-40 {
        margin-left: 40px;
        margin-right: 40px;
    }

    .mg-sm-x-40-force {
        margin-left: 40px !important;
        margin-right: 40px !important;
    }

    .mg-sm-t-40 {
        margin-top: 40px;
    }

    .mg-sm-r-40 {
        margin-right: 40px;
    }

    .mg-sm-b-40 {
        margin-bottom: 40px;
    }

    .mg-sm-l-40 {
        margin-left: 40px;
    }

    .mg-sm-t-40-force {
        margin-top: 40px !important;
    }

    .mg-sm-r-40-force {
        margin-right: 40px !important;
    }

    .mg-sm-b-40-force {
        margin-bottom: 40px !important;
    }

    .mg-sm-l-40-force {
        margin-left: 40px !important;
    }

    .mg-sm-45 {
        margin: 45px;
    }

    .mg-sm-45-force {
        margin: 45px !important;
    }

    .mg-sm-y-45 {
        margin-top: 45px;
        margin-bottom: 45px;
    }

    .mg-sm-y-45-force {
        margin-top: 45px !important;
        margin-bottom: 45px !important;
    }

    .mg-sm-x-45 {
        margin-left: 45px;
        margin-right: 45px;
    }

    .mg-sm-x-45-force {
        margin-left: 45px !important;
        margin-right: 45px !important;
    }

    .mg-sm-t-45 {
        margin-top: 45px;
    }

    .mg-sm-r-45 {
        margin-right: 45px;
    }

    .mg-sm-b-45 {
        margin-bottom: 45px;
    }

    .mg-sm-l-45 {
        margin-left: 45px;
    }

    .mg-sm-t-45-force {
        margin-top: 45px !important;
    }

    .mg-sm-r-45-force {
        margin-right: 45px !important;
    }

    .mg-sm-b-45-force {
        margin-bottom: 45px !important;
    }

    .mg-sm-l-45-force {
        margin-left: 45px !important;
    }

    .mg-sm-50 {
        margin: 50px;
    }

    .mg-sm-50-force {
        margin: 50px !important;
    }

    .mg-sm-y-50 {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .mg-sm-y-50-force {
        margin-top: 50px !important;
        margin-bottom: 50px !important;
    }

    .mg-sm-x-50 {
        margin-left: 50px;
        margin-right: 50px;
    }

    .mg-sm-x-50-force {
        margin-left: 50px !important;
        margin-right: 50px !important;
    }

    .mg-sm-t-50 {
        margin-top: 50px;
    }

    .mg-sm-r-50 {
        margin-right: 50px;
    }

    .mg-sm-b-50 {
        margin-bottom: 50px;
    }

    .mg-sm-l-50 {
        margin-left: 50px;
    }

    .mg-sm-t-50-force {
        margin-top: 50px !important;
    }

    .mg-sm-r-50-force {
        margin-right: 50px !important;
    }

    .mg-sm-b-50-force {
        margin-bottom: 50px !important;
    }

    .mg-sm-l-50-force {
        margin-left: 50px !important;
    }

    .mg-sm-55 {
        margin: 55px;
    }

    .mg-sm-55-force {
        margin: 55px !important;
    }

    .mg-sm-y-55 {
        margin-top: 55px;
        margin-bottom: 55px;
    }

    .mg-sm-y-55-force {
        margin-top: 55px !important;
        margin-bottom: 55px !important;
    }

    .mg-sm-x-55 {
        margin-left: 55px;
        margin-right: 55px;
    }

    .mg-sm-x-55-force {
        margin-left: 55px !important;
        margin-right: 55px !important;
    }

    .mg-sm-t-55 {
        margin-top: 55px;
    }

    .mg-sm-r-55 {
        margin-right: 55px;
    }

    .mg-sm-b-55 {
        margin-bottom: 55px;
    }

    .mg-sm-l-55 {
        margin-left: 55px;
    }

    .mg-sm-t-55-force {
        margin-top: 55px !important;
    }

    .mg-sm-r-55-force {
        margin-right: 55px !important;
    }

    .mg-sm-b-55-force {
        margin-bottom: 55px !important;
    }

    .mg-sm-l-55-force {
        margin-left: 55px !important;
    }

    .mg-sm-60 {
        margin: 60px;
    }

    .mg-sm-60-force {
        margin: 60px !important;
    }

    .mg-sm-y-60 {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .mg-sm-y-60-force {
        margin-top: 60px !important;
        margin-bottom: 60px !important;
    }

    .mg-sm-x-60 {
        margin-left: 60px;
        margin-right: 60px;
    }

    .mg-sm-x-60-force {
        margin-left: 60px !important;
        margin-right: 60px !important;
    }

    .mg-sm-t-60 {
        margin-top: 60px;
    }

    .mg-sm-r-60 {
        margin-right: 60px;
    }

    .mg-sm-b-60 {
        margin-bottom: 60px;
    }

    .mg-sm-l-60 {
        margin-left: 60px;
    }

    .mg-sm-t-60-force {
        margin-top: 60px !important;
    }

    .mg-sm-r-60-force {
        margin-right: 60px !important;
    }

    .mg-sm-b-60-force {
        margin-bottom: 60px !important;
    }

    .mg-sm-l-60-force {
        margin-left: 60px !important;
    }

    .mg-sm-65 {
        margin: 65px;
    }

    .mg-sm-65-force {
        margin: 65px !important;
    }

    .mg-sm-y-65 {
        margin-top: 65px;
        margin-bottom: 65px;
    }

    .mg-sm-y-65-force {
        margin-top: 65px !important;
        margin-bottom: 65px !important;
    }

    .mg-sm-x-65 {
        margin-left: 65px;
        margin-right: 65px;
    }

    .mg-sm-x-65-force {
        margin-left: 65px !important;
        margin-right: 65px !important;
    }

    .mg-sm-t-65 {
        margin-top: 65px;
    }

    .mg-sm-r-65 {
        margin-right: 65px;
    }

    .mg-sm-b-65 {
        margin-bottom: 65px;
    }

    .mg-sm-l-65 {
        margin-left: 65px;
    }

    .mg-sm-t-65-force {
        margin-top: 65px !important;
    }

    .mg-sm-r-65-force {
        margin-right: 65px !important;
    }

    .mg-sm-b-65-force {
        margin-bottom: 65px !important;
    }

    .mg-sm-l-65-force {
        margin-left: 65px !important;
    }

    .mg-sm-70 {
        margin: 70px;
    }

    .mg-sm-70-force {
        margin: 70px !important;
    }

    .mg-sm-y-70 {
        margin-top: 70px;
        margin-bottom: 70px;
    }

    .mg-sm-y-70-force {
        margin-top: 70px !important;
        margin-bottom: 70px !important;
    }

    .mg-sm-x-70 {
        margin-left: 70px;
        margin-right: 70px;
    }

    .mg-sm-x-70-force {
        margin-left: 70px !important;
        margin-right: 70px !important;
    }

    .mg-sm-t-70 {
        margin-top: 70px;
    }

    .mg-sm-r-70 {
        margin-right: 70px;
    }

    .mg-sm-b-70 {
        margin-bottom: 70px;
    }

    .mg-sm-l-70 {
        margin-left: 70px;
    }

    .mg-sm-t-70-force {
        margin-top: 70px !important;
    }

    .mg-sm-r-70-force {
        margin-right: 70px !important;
    }

    .mg-sm-b-70-force {
        margin-bottom: 70px !important;
    }

    .mg-sm-l-70-force {
        margin-left: 70px !important;
    }

    .mg-sm-75 {
        margin: 75px;
    }

    .mg-sm-75-force {
        margin: 75px !important;
    }

    .mg-sm-y-75 {
        margin-top: 75px;
        margin-bottom: 75px;
    }

    .mg-sm-y-75-force {
        margin-top: 75px !important;
        margin-bottom: 75px !important;
    }

    .mg-sm-x-75 {
        margin-left: 75px;
        margin-right: 75px;
    }

    .mg-sm-x-75-force {
        margin-left: 75px !important;
        margin-right: 75px !important;
    }

    .mg-sm-t-75 {
        margin-top: 75px;
    }

    .mg-sm-r-75 {
        margin-right: 75px;
    }

    .mg-sm-b-75 {
        margin-bottom: 75px;
    }

    .mg-sm-l-75 {
        margin-left: 75px;
    }

    .mg-sm-t-75-force {
        margin-top: 75px !important;
    }

    .mg-sm-r-75-force {
        margin-right: 75px !important;
    }

    .mg-sm-b-75-force {
        margin-bottom: 75px !important;
    }

    .mg-sm-l-75-force {
        margin-left: 75px !important;
    }

    .mg-sm-80 {
        margin: 80px;
    }

    .mg-sm-80-force {
        margin: 80px !important;
    }

    .mg-sm-y-80 {
        margin-top: 80px;
        margin-bottom: 80px;
    }

    .mg-sm-y-80-force {
        margin-top: 80px !important;
        margin-bottom: 80px !important;
    }

    .mg-sm-x-80 {
        margin-left: 80px;
        margin-right: 80px;
    }

    .mg-sm-x-80-force {
        margin-left: 80px !important;
        margin-right: 80px !important;
    }

    .mg-sm-t-80 {
        margin-top: 80px;
    }

    .mg-sm-r-80 {
        margin-right: 80px;
    }

    .mg-sm-b-80 {
        margin-bottom: 80px;
    }

    .mg-sm-l-80 {
        margin-left: 80px;
    }

    .mg-sm-t-80-force {
        margin-top: 80px !important;
    }

    .mg-sm-r-80-force {
        margin-right: 80px !important;
    }

    .mg-sm-b-80-force {
        margin-bottom: 80px !important;
    }

    .mg-sm-l-80-force {
        margin-left: 80px !important;
    }

    .mg-sm-85 {
        margin: 85px;
    }

    .mg-sm-85-force {
        margin: 85px !important;
    }

    .mg-sm-y-85 {
        margin-top: 85px;
        margin-bottom: 85px;
    }

    .mg-sm-y-85-force {
        margin-top: 85px !important;
        margin-bottom: 85px !important;
    }

    .mg-sm-x-85 {
        margin-left: 85px;
        margin-right: 85px;
    }

    .mg-sm-x-85-force {
        margin-left: 85px !important;
        margin-right: 85px !important;
    }

    .mg-sm-t-85 {
        margin-top: 85px;
    }

    .mg-sm-r-85 {
        margin-right: 85px;
    }

    .mg-sm-b-85 {
        margin-bottom: 85px;
    }

    .mg-sm-l-85 {
        margin-left: 85px;
    }

    .mg-sm-t-85-force {
        margin-top: 85px !important;
    }

    .mg-sm-r-85-force {
        margin-right: 85px !important;
    }

    .mg-sm-b-85-force {
        margin-bottom: 85px !important;
    }

    .mg-sm-l-85-force {
        margin-left: 85px !important;
    }

    .mg-sm-90 {
        margin: 90px;
    }

    .mg-sm-90-force {
        margin: 90px !important;
    }

    .mg-sm-y-90 {
        margin-top: 90px;
        margin-bottom: 90px;
    }

    .mg-sm-y-90-force {
        margin-top: 90px !important;
        margin-bottom: 90px !important;
    }

    .mg-sm-x-90 {
        margin-left: 90px;
        margin-right: 90px;
    }

    .mg-sm-x-90-force {
        margin-left: 90px !important;
        margin-right: 90px !important;
    }

    .mg-sm-t-90 {
        margin-top: 90px;
    }

    .mg-sm-r-90 {
        margin-right: 90px;
    }

    .mg-sm-b-90 {
        margin-bottom: 90px;
    }

    .mg-sm-l-90 {
        margin-left: 90px;
    }

    .mg-sm-t-90-force {
        margin-top: 90px !important;
    }

    .mg-sm-r-90-force {
        margin-right: 90px !important;
    }

    .mg-sm-b-90-force {
        margin-bottom: 90px !important;
    }

    .mg-sm-l-90-force {
        margin-left: 90px !important;
    }

    .mg-sm-95 {
        margin: 95px;
    }

    .mg-sm-95-force {
        margin: 95px !important;
    }

    .mg-sm-y-95 {
        margin-top: 95px;
        margin-bottom: 95px;
    }

    .mg-sm-y-95-force {
        margin-top: 95px !important;
        margin-bottom: 95px !important;
    }

    .mg-sm-x-95 {
        margin-left: 95px;
        margin-right: 95px;
    }

    .mg-sm-x-95-force {
        margin-left: 95px !important;
        margin-right: 95px !important;
    }

    .mg-sm-t-95 {
        margin-top: 95px;
    }

    .mg-sm-r-95 {
        margin-right: 95px;
    }

    .mg-sm-b-95 {
        margin-bottom: 95px;
    }

    .mg-sm-l-95 {
        margin-left: 95px;
    }

    .mg-sm-t-95-force {
        margin-top: 95px !important;
    }

    .mg-sm-r-95-force {
        margin-right: 95px !important;
    }

    .mg-sm-b-95-force {
        margin-bottom: 95px !important;
    }

    .mg-sm-l-95-force {
        margin-left: 95px !important;
    }

    .mg-sm-100 {
        margin: 100px;
    }

    .mg-sm-100-force {
        margin: 100px !important;
    }

    .mg-sm-y-100 {
        margin-top: 100px;
        margin-bottom: 100px;
    }

    .mg-sm-y-100-force {
        margin-top: 100px !important;
        margin-bottom: 100px !important;
    }

    .mg-sm-x-100 {
        margin-left: 100px;
        margin-right: 100px;
    }

    .mg-sm-x-100-force {
        margin-left: 100px !important;
        margin-right: 100px !important;
    }

    .mg-sm-t-100 {
        margin-top: 100px;
    }

    .mg-sm-r-100 {
        margin-right: 100px;
    }

    .mg-sm-b-100 {
        margin-bottom: 100px;
    }

    .mg-sm-l-100 {
        margin-left: 100px;
    }

    .mg-sm-t-100-force {
        margin-top: 100px !important;
    }

    .mg-sm-r-100-force {
        margin-right: 100px !important;
    }

    .mg-sm-b-100-force {
        margin-bottom: 100px !important;
    }

    .mg-sm-l-100-force {
        margin-left: 100px !important;
    }

    .mg-sm-t-auto {
        margin-top: auto;
    }

    .mg-sm-r-auto {
        margin-right: auto;
    }

    .mg-sm-b-auto {
        margin-bottom: auto;
    }

    .mg-sm-l-auto {
        margin-left: auto;
    }

    .mg-sm-auto {
        margin: auto;
    }

    .mg-sm-x-auto {
        margin-left: auto;
        margin-right: auto;
    }

    .mg-sm-l--1 {
        margin-left: -1px;
    }

    .mg-sm-r--1 {
        margin-right: -1px;
    }

    .mg-sm-t--1 {
        margin-top: -1px;
    }
}

@media (min-width: 768px) {
    .mg-md-0 {
        margin: 0px;
    }

    .mg-md-0-force {
        margin: 0px !important;
    }

    .mg-md-y-0 {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .mg-md-y-0-force {
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

    .mg-md-x-0 {
        margin-left: 0px;
        margin-right: 0px;
    }

    .mg-md-x-0-force {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .mg-md-t-0 {
        margin-top: 0px;
    }

    .mg-md-r-0 {
        margin-right: 0px;
    }

    .mg-md-b-0 {
        margin-bottom: 0px;
    }

    .mg-md-l-0 {
        margin-left: 0px;
    }

    .mg-md-t-0-force {
        margin-top: 0px !important;
    }

    .mg-md-r-0-force {
        margin-right: 0px !important;
    }

    .mg-md-b-0-force {
        margin-bottom: 0px !important;
    }

    .mg-md-l-0-force {
        margin-left: 0px !important;
    }

    .mg-md-1 {
        margin: 1px;
    }

    .mg-md-1-force {
        margin: 1px !important;
    }

    .mg-md-y-1 {
        margin-top: 1px;
        margin-bottom: 1px;
    }

    .mg-md-y-1-force {
        margin-top: 1px !important;
        margin-bottom: 1px !important;
    }

    .mg-md-x-1 {
        margin-left: 1px;
        margin-right: 1px;
    }

    .mg-md-x-1-force {
        margin-left: 1px !important;
        margin-right: 1px !important;
    }

    .mg-md-t-1 {
        margin-top: 1px;
    }

    .mg-md-r-1 {
        margin-right: 1px;
    }

    .mg-md-b-1 {
        margin-bottom: 1px;
    }

    .mg-md-l-1 {
        margin-left: 1px;
    }

    .mg-md-t-1-force {
        margin-top: 1px !important;
    }

    .mg-md-r-1-force {
        margin-right: 1px !important;
    }

    .mg-md-b-1-force {
        margin-bottom: 1px !important;
    }

    .mg-md-l-1-force {
        margin-left: 1px !important;
    }

    .mg-md-2 {
        margin: 2px;
    }

    .mg-md-2-force {
        margin: 2px !important;
    }

    .mg-md-y-2 {
        margin-top: 2px;
        margin-bottom: 2px;
    }

    .mg-md-y-2-force {
        margin-top: 2px !important;
        margin-bottom: 2px !important;
    }

    .mg-md-x-2 {
        margin-left: 2px;
        margin-right: 2px;
    }

    .mg-md-x-2-force {
        margin-left: 2px !important;
        margin-right: 2px !important;
    }

    .mg-md-t-2 {
        margin-top: 2px;
    }

    .mg-md-r-2 {
        margin-right: 2px;
    }

    .mg-md-b-2 {
        margin-bottom: 2px;
    }

    .mg-md-l-2 {
        margin-left: 2px;
    }

    .mg-md-t-2-force {
        margin-top: 2px !important;
    }

    .mg-md-r-2-force {
        margin-right: 2px !important;
    }

    .mg-md-b-2-force {
        margin-bottom: 2px !important;
    }

    .mg-md-l-2-force {
        margin-left: 2px !important;
    }

    .mg-md-3 {
        margin: 3px;
    }

    .mg-md-3-force {
        margin: 3px !important;
    }

    .mg-md-y-3 {
        margin-top: 3px;
        margin-bottom: 3px;
    }

    .mg-md-y-3-force {
        margin-top: 3px !important;
        margin-bottom: 3px !important;
    }

    .mg-md-x-3 {
        margin-left: 3px;
        margin-right: 3px;
    }

    .mg-md-x-3-force {
        margin-left: 3px !important;
        margin-right: 3px !important;
    }

    .mg-md-t-3 {
        margin-top: 3px;
    }

    .mg-md-r-3 {
        margin-right: 3px;
    }

    .mg-md-b-3 {
        margin-bottom: 3px;
    }

    .mg-md-l-3 {
        margin-left: 3px;
    }

    .mg-md-t-3-force {
        margin-top: 3px !important;
    }

    .mg-md-r-3-force {
        margin-right: 3px !important;
    }

    .mg-md-b-3-force {
        margin-bottom: 3px !important;
    }

    .mg-md-l-3-force {
        margin-left: 3px !important;
    }

    .mg-md-4 {
        margin: 4px;
    }

    .mg-md-4-force {
        margin: 4px !important;
    }

    .mg-md-y-4 {
        margin-top: 4px;
        margin-bottom: 4px;
    }

    .mg-md-y-4-force {
        margin-top: 4px !important;
        margin-bottom: 4px !important;
    }

    .mg-md-x-4 {
        margin-left: 4px;
        margin-right: 4px;
    }

    .mg-md-x-4-force {
        margin-left: 4px !important;
        margin-right: 4px !important;
    }

    .mg-md-t-4 {
        margin-top: 4px;
    }

    .mg-md-r-4 {
        margin-right: 4px;
    }

    .mg-md-b-4 {
        margin-bottom: 4px;
    }

    .mg-md-l-4 {
        margin-left: 4px;
    }

    .mg-md-t-4-force {
        margin-top: 4px !important;
    }

    .mg-md-r-4-force {
        margin-right: 4px !important;
    }

    .mg-md-b-4-force {
        margin-bottom: 4px !important;
    }

    .mg-md-l-4-force {
        margin-left: 4px !important;
    }

    .mg-md-5 {
        margin: 5px;
    }

    .mg-md-5-force {
        margin: 5px !important;
    }

    .mg-md-y-5 {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .mg-md-y-5-force {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
    }

    .mg-md-x-5 {
        margin-left: 5px;
        margin-right: 5px;
    }

    .mg-md-x-5-force {
        margin-left: 5px !important;
        margin-right: 5px !important;
    }

    .mg-md-t-5 {
        margin-top: 5px;
    }

    .mg-md-r-5 {
        margin-right: 5px;
    }

    .mg-md-b-5 {
        margin-bottom: 5px;
    }

    .mg-md-l-5 {
        margin-left: 5px;
    }

    .mg-md-t-5-force {
        margin-top: 5px !important;
    }

    .mg-md-r-5-force {
        margin-right: 5px !important;
    }

    .mg-md-b-5-force {
        margin-bottom: 5px !important;
    }

    .mg-md-l-5-force {
        margin-left: 5px !important;
    }

    .mg-md-6 {
        margin: 6px;
    }

    .mg-md-6-force {
        margin: 6px !important;
    }

    .mg-md-y-6 {
        margin-top: 6px;
        margin-bottom: 6px;
    }

    .mg-md-y-6-force {
        margin-top: 6px !important;
        margin-bottom: 6px !important;
    }

    .mg-md-x-6 {
        margin-left: 6px;
        margin-right: 6px;
    }

    .mg-md-x-6-force {
        margin-left: 6px !important;
        margin-right: 6px !important;
    }

    .mg-md-t-6 {
        margin-top: 6px;
    }

    .mg-md-r-6 {
        margin-right: 6px;
    }

    .mg-md-b-6 {
        margin-bottom: 6px;
    }

    .mg-md-l-6 {
        margin-left: 6px;
    }

    .mg-md-t-6-force {
        margin-top: 6px !important;
    }

    .mg-md-r-6-force {
        margin-right: 6px !important;
    }

    .mg-md-b-6-force {
        margin-bottom: 6px !important;
    }

    .mg-md-l-6-force {
        margin-left: 6px !important;
    }

    .mg-md-7 {
        margin: 7px;
    }

    .mg-md-7-force {
        margin: 7px !important;
    }

    .mg-md-y-7 {
        margin-top: 7px;
        margin-bottom: 7px;
    }

    .mg-md-y-7-force {
        margin-top: 7px !important;
        margin-bottom: 7px !important;
    }

    .mg-md-x-7 {
        margin-left: 7px;
        margin-right: 7px;
    }

    .mg-md-x-7-force {
        margin-left: 7px !important;
        margin-right: 7px !important;
    }

    .mg-md-t-7 {
        margin-top: 7px;
    }

    .mg-md-r-7 {
        margin-right: 7px;
    }

    .mg-md-b-7 {
        margin-bottom: 7px;
    }

    .mg-md-l-7 {
        margin-left: 7px;
    }

    .mg-md-t-7-force {
        margin-top: 7px !important;
    }

    .mg-md-r-7-force {
        margin-right: 7px !important;
    }

    .mg-md-b-7-force {
        margin-bottom: 7px !important;
    }

    .mg-md-l-7-force {
        margin-left: 7px !important;
    }

    .mg-md-8 {
        margin: 8px;
    }

    .mg-md-8-force {
        margin: 8px !important;
    }

    .mg-md-y-8 {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .mg-md-y-8-force {
        margin-top: 8px !important;
        margin-bottom: 8px !important;
    }

    .mg-md-x-8 {
        margin-left: 8px;
        margin-right: 8px;
    }

    .mg-md-x-8-force {
        margin-left: 8px !important;
        margin-right: 8px !important;
    }

    .mg-md-t-8 {
        margin-top: 8px;
    }

    .mg-md-r-8 {
        margin-right: 8px;
    }

    .mg-md-b-8 {
        margin-bottom: 8px;
    }

    .mg-md-l-8 {
        margin-left: 8px;
    }

    .mg-md-t-8-force {
        margin-top: 8px !important;
    }

    .mg-md-r-8-force {
        margin-right: 8px !important;
    }

    .mg-md-b-8-force {
        margin-bottom: 8px !important;
    }

    .mg-md-l-8-force {
        margin-left: 8px !important;
    }

    .mg-md-9 {
        margin: 9px;
    }

    .mg-md-9-force {
        margin: 9px !important;
    }

    .mg-md-y-9 {
        margin-top: 9px;
        margin-bottom: 9px;
    }

    .mg-md-y-9-force {
        margin-top: 9px !important;
        margin-bottom: 9px !important;
    }

    .mg-md-x-9 {
        margin-left: 9px;
        margin-right: 9px;
    }

    .mg-md-x-9-force {
        margin-left: 9px !important;
        margin-right: 9px !important;
    }

    .mg-md-t-9 {
        margin-top: 9px;
    }

    .mg-md-r-9 {
        margin-right: 9px;
    }

    .mg-md-b-9 {
        margin-bottom: 9px;
    }

    .mg-md-l-9 {
        margin-left: 9px;
    }

    .mg-md-t-9-force {
        margin-top: 9px !important;
    }

    .mg-md-r-9-force {
        margin-right: 9px !important;
    }

    .mg-md-b-9-force {
        margin-bottom: 9px !important;
    }

    .mg-md-l-9-force {
        margin-left: 9px !important;
    }

    .mg-md-10 {
        margin: 10px;
    }

    .mg-md-10-force {
        margin: 10px !important;
    }

    .mg-md-y-10 {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .mg-md-y-10-force {
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }

    .mg-md-x-10 {
        margin-left: 10px;
        margin-right: 10px;
    }

    .mg-md-x-10-force {
        margin-left: 10px !important;
        margin-right: 10px !important;
    }

    .mg-md-t-10 {
        margin-top: 10px;
    }

    .mg-md-r-10 {
        margin-right: 10px;
    }

    .mg-md-b-10 {
        margin-bottom: 10px;
    }

    .mg-md-l-10 {
        margin-left: 10px;
    }

    .mg-md-t-10-force {
        margin-top: 10px !important;
    }

    .mg-md-r-10-force {
        margin-right: 10px !important;
    }

    .mg-md-b-10-force {
        margin-bottom: 10px !important;
    }

    .mg-md-l-10-force {
        margin-left: 10px !important;
    }

    .mg-md-15 {
        margin: 15px;
    }

    .mg-md-15-force {
        margin: 15px !important;
    }

    .mg-md-y-15 {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .mg-md-y-15-force {
        margin-top: 15px !important;
        margin-bottom: 15px !important;
    }

    .mg-md-x-15 {
        margin-left: 15px;
        margin-right: 15px;
    }

    .mg-md-x-15-force {
        margin-left: 15px !important;
        margin-right: 15px !important;
    }

    .mg-md-t-15 {
        margin-top: 15px;
    }

    .mg-md-r-15 {
        margin-right: 15px;
    }

    .mg-md-b-15 {
        margin-bottom: 15px;
    }

    .mg-md-l-15 {
        margin-left: 15px;
    }

    .mg-md-t-15-force {
        margin-top: 15px !important;
    }

    .mg-md-r-15-force {
        margin-right: 15px !important;
    }

    .mg-md-b-15-force {
        margin-bottom: 15px !important;
    }

    .mg-md-l-15-force {
        margin-left: 15px !important;
    }

    .mg-md-20 {
        margin: 20px;
    }

    .mg-md-20-force {
        margin: 20px !important;
    }

    .mg-md-y-20 {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .mg-md-y-20-force {
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }

    .mg-md-x-20 {
        margin-left: 20px;
        margin-right: 20px;
    }

    .mg-md-x-20-force {
        margin-left: 20px !important;
        margin-right: 20px !important;
    }

    .mg-md-t-20 {
        margin-top: 20px;
    }

    .mg-md-r-20 {
        margin-right: 20px;
    }

    .mg-md-b-20 {
        margin-bottom: 20px;
    }

    .mg-md-l-20 {
        margin-left: 20px;
    }

    .mg-md-t-20-force {
        margin-top: 20px !important;
    }

    .mg-md-r-20-force {
        margin-right: 20px !important;
    }

    .mg-md-b-20-force {
        margin-bottom: 20px !important;
    }

    .mg-md-l-20-force {
        margin-left: 20px !important;
    }

    .mg-md-25 {
        margin: 25px;
    }

    .mg-md-25-force {
        margin: 25px !important;
    }

    .mg-md-y-25 {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .mg-md-y-25-force {
        margin-top: 25px !important;
        margin-bottom: 25px !important;
    }

    .mg-md-x-25 {
        margin-left: 25px;
        margin-right: 25px;
    }

    .mg-md-x-25-force {
        margin-left: 25px !important;
        margin-right: 25px !important;
    }

    .mg-md-t-25 {
        margin-top: 25px;
    }

    .mg-md-r-25 {
        margin-right: 25px;
    }

    .mg-md-b-25 {
        margin-bottom: 25px;
    }

    .mg-md-l-25 {
        margin-left: 25px;
    }

    .mg-md-t-25-force {
        margin-top: 25px !important;
    }

    .mg-md-r-25-force {
        margin-right: 25px !important;
    }

    .mg-md-b-25-force {
        margin-bottom: 25px !important;
    }

    .mg-md-l-25-force {
        margin-left: 25px !important;
    }

    .mg-md-30 {
        margin: 30px;
    }

    .mg-md-30-force {
        margin: 30px !important;
    }

    .mg-md-y-30 {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .mg-md-y-30-force {
        margin-top: 30px !important;
        margin-bottom: 30px !important;
    }

    .mg-md-x-30 {
        margin-left: 30px;
        margin-right: 30px;
    }

    .mg-md-x-30-force {
        margin-left: 30px !important;
        margin-right: 30px !important;
    }

    .mg-md-t-30 {
        margin-top: 30px;
    }

    .mg-md-r-30 {
        margin-right: 30px;
    }

    .mg-md-b-30 {
        margin-bottom: 30px;
    }

    .mg-md-l-30 {
        margin-left: 30px;
    }

    .mg-md-t-30-force {
        margin-top: 30px !important;
    }

    .mg-md-r-30-force {
        margin-right: 30px !important;
    }

    .mg-md-b-30-force {
        margin-bottom: 30px !important;
    }

    .mg-md-l-30-force {
        margin-left: 30px !important;
    }

    .mg-md-35 {
        margin: 35px;
    }

    .mg-md-35-force {
        margin: 35px !important;
    }

    .mg-md-y-35 {
        margin-top: 35px;
        margin-bottom: 35px;
    }

    .mg-md-y-35-force {
        margin-top: 35px !important;
        margin-bottom: 35px !important;
    }

    .mg-md-x-35 {
        margin-left: 35px;
        margin-right: 35px;
    }

    .mg-md-x-35-force {
        margin-left: 35px !important;
        margin-right: 35px !important;
    }

    .mg-md-t-35 {
        margin-top: 35px;
    }

    .mg-md-r-35 {
        margin-right: 35px;
    }

    .mg-md-b-35 {
        margin-bottom: 35px;
    }

    .mg-md-l-35 {
        margin-left: 35px;
    }

    .mg-md-t-35-force {
        margin-top: 35px !important;
    }

    .mg-md-r-35-force {
        margin-right: 35px !important;
    }

    .mg-md-b-35-force {
        margin-bottom: 35px !important;
    }

    .mg-md-l-35-force {
        margin-left: 35px !important;
    }

    .mg-md-40 {
        margin: 40px;
    }

    .mg-md-40-force {
        margin: 40px !important;
    }

    .mg-md-y-40 {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .mg-md-y-40-force {
        margin-top: 40px !important;
        margin-bottom: 40px !important;
    }

    .mg-md-x-40 {
        margin-left: 40px;
        margin-right: 40px;
    }

    .mg-md-x-40-force {
        margin-left: 40px !important;
        margin-right: 40px !important;
    }

    .mg-md-t-40 {
        margin-top: 40px;
    }

    .mg-md-r-40 {
        margin-right: 40px;
    }

    .mg-md-b-40 {
        margin-bottom: 40px;
    }

    .mg-md-l-40 {
        margin-left: 40px;
    }

    .mg-md-t-40-force {
        margin-top: 40px !important;
    }

    .mg-md-r-40-force {
        margin-right: 40px !important;
    }

    .mg-md-b-40-force {
        margin-bottom: 40px !important;
    }

    .mg-md-l-40-force {
        margin-left: 40px !important;
    }

    .mg-md-45 {
        margin: 45px;
    }

    .mg-md-45-force {
        margin: 45px !important;
    }

    .mg-md-y-45 {
        margin-top: 45px;
        margin-bottom: 45px;
    }

    .mg-md-y-45-force {
        margin-top: 45px !important;
        margin-bottom: 45px !important;
    }

    .mg-md-x-45 {
        margin-left: 45px;
        margin-right: 45px;
    }

    .mg-md-x-45-force {
        margin-left: 45px !important;
        margin-right: 45px !important;
    }

    .mg-md-t-45 {
        margin-top: 45px;
    }

    .mg-md-r-45 {
        margin-right: 45px;
    }

    .mg-md-b-45 {
        margin-bottom: 45px;
    }

    .mg-md-l-45 {
        margin-left: 45px;
    }

    .mg-md-t-45-force {
        margin-top: 45px !important;
    }

    .mg-md-r-45-force {
        margin-right: 45px !important;
    }

    .mg-md-b-45-force {
        margin-bottom: 45px !important;
    }

    .mg-md-l-45-force {
        margin-left: 45px !important;
    }

    .mg-md-50 {
        margin: 50px;
    }

    .mg-md-50-force {
        margin: 50px !important;
    }

    .mg-md-y-50 {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .mg-md-y-50-force {
        margin-top: 50px !important;
        margin-bottom: 50px !important;
    }

    .mg-md-x-50 {
        margin-left: 50px;
        margin-right: 50px;
    }

    .mg-md-x-50-force {
        margin-left: 50px !important;
        margin-right: 50px !important;
    }

    .mg-md-t-50 {
        margin-top: 50px;
    }

    .mg-md-r-50 {
        margin-right: 50px;
    }

    .mg-md-b-50 {
        margin-bottom: 50px;
    }

    .mg-md-l-50 {
        margin-left: 50px;
    }

    .mg-md-t-50-force {
        margin-top: 50px !important;
    }

    .mg-md-r-50-force {
        margin-right: 50px !important;
    }

    .mg-md-b-50-force {
        margin-bottom: 50px !important;
    }

    .mg-md-l-50-force {
        margin-left: 50px !important;
    }

    .mg-md-55 {
        margin: 55px;
    }

    .mg-md-55-force {
        margin: 55px !important;
    }

    .mg-md-y-55 {
        margin-top: 55px;
        margin-bottom: 55px;
    }

    .mg-md-y-55-force {
        margin-top: 55px !important;
        margin-bottom: 55px !important;
    }

    .mg-md-x-55 {
        margin-left: 55px;
        margin-right: 55px;
    }

    .mg-md-x-55-force {
        margin-left: 55px !important;
        margin-right: 55px !important;
    }

    .mg-md-t-55 {
        margin-top: 55px;
    }

    .mg-md-r-55 {
        margin-right: 55px;
    }

    .mg-md-b-55 {
        margin-bottom: 55px;
    }

    .mg-md-l-55 {
        margin-left: 55px;
    }

    .mg-md-t-55-force {
        margin-top: 55px !important;
    }

    .mg-md-r-55-force {
        margin-right: 55px !important;
    }

    .mg-md-b-55-force {
        margin-bottom: 55px !important;
    }

    .mg-md-l-55-force {
        margin-left: 55px !important;
    }

    .mg-md-60 {
        margin: 60px;
    }

    .mg-md-60-force {
        margin: 60px !important;
    }

    .mg-md-y-60 {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .mg-md-y-60-force {
        margin-top: 60px !important;
        margin-bottom: 60px !important;
    }

    .mg-md-x-60 {
        margin-left: 60px;
        margin-right: 60px;
    }

    .mg-md-x-60-force {
        margin-left: 60px !important;
        margin-right: 60px !important;
    }

    .mg-md-t-60 {
        margin-top: 60px;
    }

    .mg-md-r-60 {
        margin-right: 60px;
    }

    .mg-md-b-60 {
        margin-bottom: 60px;
    }

    .mg-md-l-60 {
        margin-left: 60px;
    }

    .mg-md-t-60-force {
        margin-top: 60px !important;
    }

    .mg-md-r-60-force {
        margin-right: 60px !important;
    }

    .mg-md-b-60-force {
        margin-bottom: 60px !important;
    }

    .mg-md-l-60-force {
        margin-left: 60px !important;
    }

    .mg-md-65 {
        margin: 65px;
    }

    .mg-md-65-force {
        margin: 65px !important;
    }

    .mg-md-y-65 {
        margin-top: 65px;
        margin-bottom: 65px;
    }

    .mg-md-y-65-force {
        margin-top: 65px !important;
        margin-bottom: 65px !important;
    }

    .mg-md-x-65 {
        margin-left: 65px;
        margin-right: 65px;
    }

    .mg-md-x-65-force {
        margin-left: 65px !important;
        margin-right: 65px !important;
    }

    .mg-md-t-65 {
        margin-top: 65px;
    }

    .mg-md-r-65 {
        margin-right: 65px;
    }

    .mg-md-b-65 {
        margin-bottom: 65px;
    }

    .mg-md-l-65 {
        margin-left: 65px;
    }

    .mg-md-t-65-force {
        margin-top: 65px !important;
    }

    .mg-md-r-65-force {
        margin-right: 65px !important;
    }

    .mg-md-b-65-force {
        margin-bottom: 65px !important;
    }

    .mg-md-l-65-force {
        margin-left: 65px !important;
    }

    .mg-md-70 {
        margin: 70px;
    }

    .mg-md-70-force {
        margin: 70px !important;
    }

    .mg-md-y-70 {
        margin-top: 70px;
        margin-bottom: 70px;
    }

    .mg-md-y-70-force {
        margin-top: 70px !important;
        margin-bottom: 70px !important;
    }

    .mg-md-x-70 {
        margin-left: 70px;
        margin-right: 70px;
    }

    .mg-md-x-70-force {
        margin-left: 70px !important;
        margin-right: 70px !important;
    }

    .mg-md-t-70 {
        margin-top: 70px;
    }

    .mg-md-r-70 {
        margin-right: 70px;
    }

    .mg-md-b-70 {
        margin-bottom: 70px;
    }

    .mg-md-l-70 {
        margin-left: 70px;
    }

    .mg-md-t-70-force {
        margin-top: 70px !important;
    }

    .mg-md-r-70-force {
        margin-right: 70px !important;
    }

    .mg-md-b-70-force {
        margin-bottom: 70px !important;
    }

    .mg-md-l-70-force {
        margin-left: 70px !important;
    }

    .mg-md-75 {
        margin: 75px;
    }

    .mg-md-75-force {
        margin: 75px !important;
    }

    .mg-md-y-75 {
        margin-top: 75px;
        margin-bottom: 75px;
    }

    .mg-md-y-75-force {
        margin-top: 75px !important;
        margin-bottom: 75px !important;
    }

    .mg-md-x-75 {
        margin-left: 75px;
        margin-right: 75px;
    }

    .mg-md-x-75-force {
        margin-left: 75px !important;
        margin-right: 75px !important;
    }

    .mg-md-t-75 {
        margin-top: 75px;
    }

    .mg-md-r-75 {
        margin-right: 75px;
    }

    .mg-md-b-75 {
        margin-bottom: 75px;
    }

    .mg-md-l-75 {
        margin-left: 75px;
    }

    .mg-md-t-75-force {
        margin-top: 75px !important;
    }

    .mg-md-r-75-force {
        margin-right: 75px !important;
    }

    .mg-md-b-75-force {
        margin-bottom: 75px !important;
    }

    .mg-md-l-75-force {
        margin-left: 75px !important;
    }

    .mg-md-80 {
        margin: 80px;
    }

    .mg-md-80-force {
        margin: 80px !important;
    }

    .mg-md-y-80 {
        margin-top: 80px;
        margin-bottom: 80px;
    }

    .mg-md-y-80-force {
        margin-top: 80px !important;
        margin-bottom: 80px !important;
    }

    .mg-md-x-80 {
        margin-left: 80px;
        margin-right: 80px;
    }

    .mg-md-x-80-force {
        margin-left: 80px !important;
        margin-right: 80px !important;
    }

    .mg-md-t-80 {
        margin-top: 80px;
    }

    .mg-md-r-80 {
        margin-right: 80px;
    }

    .mg-md-b-80 {
        margin-bottom: 80px;
    }

    .mg-md-l-80 {
        margin-left: 80px;
    }

    .mg-md-t-80-force {
        margin-top: 80px !important;
    }

    .mg-md-r-80-force {
        margin-right: 80px !important;
    }

    .mg-md-b-80-force {
        margin-bottom: 80px !important;
    }

    .mg-md-l-80-force {
        margin-left: 80px !important;
    }

    .mg-md-85 {
        margin: 85px;
    }

    .mg-md-85-force {
        margin: 85px !important;
    }

    .mg-md-y-85 {
        margin-top: 85px;
        margin-bottom: 85px;
    }

    .mg-md-y-85-force {
        margin-top: 85px !important;
        margin-bottom: 85px !important;
    }

    .mg-md-x-85 {
        margin-left: 85px;
        margin-right: 85px;
    }

    .mg-md-x-85-force {
        margin-left: 85px !important;
        margin-right: 85px !important;
    }

    .mg-md-t-85 {
        margin-top: 85px;
    }

    .mg-md-r-85 {
        margin-right: 85px;
    }

    .mg-md-b-85 {
        margin-bottom: 85px;
    }

    .mg-md-l-85 {
        margin-left: 85px;
    }

    .mg-md-t-85-force {
        margin-top: 85px !important;
    }

    .mg-md-r-85-force {
        margin-right: 85px !important;
    }

    .mg-md-b-85-force {
        margin-bottom: 85px !important;
    }

    .mg-md-l-85-force {
        margin-left: 85px !important;
    }

    .mg-md-90 {
        margin: 90px;
    }

    .mg-md-90-force {
        margin: 90px !important;
    }

    .mg-md-y-90 {
        margin-top: 90px;
        margin-bottom: 90px;
    }

    .mg-md-y-90-force {
        margin-top: 90px !important;
        margin-bottom: 90px !important;
    }

    .mg-md-x-90 {
        margin-left: 90px;
        margin-right: 90px;
    }

    .mg-md-x-90-force {
        margin-left: 90px !important;
        margin-right: 90px !important;
    }

    .mg-md-t-90 {
        margin-top: 90px;
    }

    .mg-md-r-90 {
        margin-right: 90px;
    }

    .mg-md-b-90 {
        margin-bottom: 90px;
    }

    .mg-md-l-90 {
        margin-left: 90px;
    }

    .mg-md-t-90-force {
        margin-top: 90px !important;
    }

    .mg-md-r-90-force {
        margin-right: 90px !important;
    }

    .mg-md-b-90-force {
        margin-bottom: 90px !important;
    }

    .mg-md-l-90-force {
        margin-left: 90px !important;
    }

    .mg-md-95 {
        margin: 95px;
    }

    .mg-md-95-force {
        margin: 95px !important;
    }

    .mg-md-y-95 {
        margin-top: 95px;
        margin-bottom: 95px;
    }

    .mg-md-y-95-force {
        margin-top: 95px !important;
        margin-bottom: 95px !important;
    }

    .mg-md-x-95 {
        margin-left: 95px;
        margin-right: 95px;
    }

    .mg-md-x-95-force {
        margin-left: 95px !important;
        margin-right: 95px !important;
    }

    .mg-md-t-95 {
        margin-top: 95px;
    }

    .mg-md-r-95 {
        margin-right: 95px;
    }

    .mg-md-b-95 {
        margin-bottom: 95px;
    }

    .mg-md-l-95 {
        margin-left: 95px;
    }

    .mg-md-t-95-force {
        margin-top: 95px !important;
    }

    .mg-md-r-95-force {
        margin-right: 95px !important;
    }

    .mg-md-b-95-force {
        margin-bottom: 95px !important;
    }

    .mg-md-l-95-force {
        margin-left: 95px !important;
    }

    .mg-md-100 {
        margin: 100px;
    }

    .mg-md-100-force {
        margin: 100px !important;
    }

    .mg-md-y-100 {
        margin-top: 100px;
        margin-bottom: 100px;
    }

    .mg-md-y-100-force {
        margin-top: 100px !important;
        margin-bottom: 100px !important;
    }

    .mg-md-x-100 {
        margin-left: 100px;
        margin-right: 100px;
    }

    .mg-md-x-100-force {
        margin-left: 100px !important;
        margin-right: 100px !important;
    }

    .mg-md-t-100 {
        margin-top: 100px;
    }

    .mg-md-r-100 {
        margin-right: 100px;
    }

    .mg-md-b-100 {
        margin-bottom: 100px;
    }

    .mg-md-l-100 {
        margin-left: 100px;
    }

    .mg-md-t-100-force {
        margin-top: 100px !important;
    }

    .mg-md-r-100-force {
        margin-right: 100px !important;
    }

    .mg-md-b-100-force {
        margin-bottom: 100px !important;
    }

    .mg-md-l-100-force {
        margin-left: 100px !important;
    }

    .mg-md-t-auto {
        margin-top: auto;
    }

    .mg-md-r-auto {
        margin-right: auto;
    }

    .mg-md-b-auto {
        margin-bottom: auto;
    }

    .mg-md-l-auto {
        margin-left: auto;
    }

    .mg-md-auto {
        margin: auto;
    }

    .mg-md-x-auto {
        margin-left: auto;
        margin-right: auto;
    }

    .mg-md-l--1 {
        margin-left: -1px;
    }

    .mg-md-r--1 {
        margin-right: -1px;
    }

    .mg-md-t--1 {
        margin-top: -1px;
    }
}

@media (min-width: 992px) {
    .mg-lg-0 {
        margin: 0px;
    }

    .mg-lg-0-force {
        margin: 0px !important;
    }

    .mg-lg-y-0 {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .mg-lg-y-0-force {
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

    .mg-lg-x-0 {
        margin-left: 0px;
        margin-right: 0px;
    }

    .mg-lg-x-0-force {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .mg-lg-t-0 {
        margin-top: 0px;
    }

    .mg-lg-r-0 {
        margin-right: 0px;
    }

    .mg-lg-b-0 {
        margin-bottom: 0px;
    }

    .mg-lg-l-0 {
        margin-left: 0px;
    }

    .mg-lg-t-0-force {
        margin-top: 0px !important;
    }

    .mg-lg-r-0-force {
        margin-right: 0px !important;
    }

    .mg-lg-b-0-force {
        margin-bottom: 0px !important;
    }

    .mg-lg-l-0-force {
        margin-left: 0px !important;
    }

    .mg-lg-1 {
        margin: 1px;
    }

    .mg-lg-1-force {
        margin: 1px !important;
    }

    .mg-lg-y-1 {
        margin-top: 1px;
        margin-bottom: 1px;
    }

    .mg-lg-y-1-force {
        margin-top: 1px !important;
        margin-bottom: 1px !important;
    }

    .mg-lg-x-1 {
        margin-left: 1px;
        margin-right: 1px;
    }

    .mg-lg-x-1-force {
        margin-left: 1px !important;
        margin-right: 1px !important;
    }

    .mg-lg-t-1 {
        margin-top: 1px;
    }

    .mg-lg-r-1 {
        margin-right: 1px;
    }

    .mg-lg-b-1 {
        margin-bottom: 1px;
    }

    .mg-lg-l-1 {
        margin-left: 1px;
    }

    .mg-lg-t-1-force {
        margin-top: 1px !important;
    }

    .mg-lg-r-1-force {
        margin-right: 1px !important;
    }

    .mg-lg-b-1-force {
        margin-bottom: 1px !important;
    }

    .mg-lg-l-1-force {
        margin-left: 1px !important;
    }

    .mg-lg-2 {
        margin: 2px;
    }

    .mg-lg-2-force {
        margin: 2px !important;
    }

    .mg-lg-y-2 {
        margin-top: 2px;
        margin-bottom: 2px;
    }

    .mg-lg-y-2-force {
        margin-top: 2px !important;
        margin-bottom: 2px !important;
    }

    .mg-lg-x-2 {
        margin-left: 2px;
        margin-right: 2px;
    }

    .mg-lg-x-2-force {
        margin-left: 2px !important;
        margin-right: 2px !important;
    }

    .mg-lg-t-2 {
        margin-top: 2px;
    }

    .mg-lg-r-2 {
        margin-right: 2px;
    }

    .mg-lg-b-2 {
        margin-bottom: 2px;
    }

    .mg-lg-l-2 {
        margin-left: 2px;
    }

    .mg-lg-t-2-force {
        margin-top: 2px !important;
    }

    .mg-lg-r-2-force {
        margin-right: 2px !important;
    }

    .mg-lg-b-2-force {
        margin-bottom: 2px !important;
    }

    .mg-lg-l-2-force {
        margin-left: 2px !important;
    }

    .mg-lg-3 {
        margin: 3px;
    }

    .mg-lg-3-force {
        margin: 3px !important;
    }

    .mg-lg-y-3 {
        margin-top: 3px;
        margin-bottom: 3px;
    }

    .mg-lg-y-3-force {
        margin-top: 3px !important;
        margin-bottom: 3px !important;
    }

    .mg-lg-x-3 {
        margin-left: 3px;
        margin-right: 3px;
    }

    .mg-lg-x-3-force {
        margin-left: 3px !important;
        margin-right: 3px !important;
    }

    .mg-lg-t-3 {
        margin-top: 3px;
    }

    .mg-lg-r-3 {
        margin-right: 3px;
    }

    .mg-lg-b-3 {
        margin-bottom: 3px;
    }

    .mg-lg-l-3 {
        margin-left: 3px;
    }

    .mg-lg-t-3-force {
        margin-top: 3px !important;
    }

    .mg-lg-r-3-force {
        margin-right: 3px !important;
    }

    .mg-lg-b-3-force {
        margin-bottom: 3px !important;
    }

    .mg-lg-l-3-force {
        margin-left: 3px !important;
    }

    .mg-lg-4 {
        margin: 4px;
    }

    .mg-lg-4-force {
        margin: 4px !important;
    }

    .mg-lg-y-4 {
        margin-top: 4px;
        margin-bottom: 4px;
    }

    .mg-lg-y-4-force {
        margin-top: 4px !important;
        margin-bottom: 4px !important;
    }

    .mg-lg-x-4 {
        margin-left: 4px;
        margin-right: 4px;
    }

    .mg-lg-x-4-force {
        margin-left: 4px !important;
        margin-right: 4px !important;
    }

    .mg-lg-t-4 {
        margin-top: 4px;
    }

    .mg-lg-r-4 {
        margin-right: 4px;
    }

    .mg-lg-b-4 {
        margin-bottom: 4px;
    }

    .mg-lg-l-4 {
        margin-left: 4px;
    }

    .mg-lg-t-4-force {
        margin-top: 4px !important;
    }

    .mg-lg-r-4-force {
        margin-right: 4px !important;
    }

    .mg-lg-b-4-force {
        margin-bottom: 4px !important;
    }

    .mg-lg-l-4-force {
        margin-left: 4px !important;
    }

    .mg-lg-5 {
        margin: 5px;
    }

    .mg-lg-5-force {
        margin: 5px !important;
    }

    .mg-lg-y-5 {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .mg-lg-y-5-force {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
    }

    .mg-lg-x-5 {
        margin-left: 5px;
        margin-right: 5px;
    }

    .mg-lg-x-5-force {
        margin-left: 5px !important;
        margin-right: 5px !important;
    }

    .mg-lg-t-5 {
        margin-top: 5px;
    }

    .mg-lg-r-5 {
        margin-right: 5px;
    }

    .mg-lg-b-5 {
        margin-bottom: 5px;
    }

    .mg-lg-l-5 {
        margin-left: 5px;
    }

    .mg-lg-t-5-force {
        margin-top: 5px !important;
    }

    .mg-lg-r-5-force {
        margin-right: 5px !important;
    }

    .mg-lg-b-5-force {
        margin-bottom: 5px !important;
    }

    .mg-lg-l-5-force {
        margin-left: 5px !important;
    }

    .mg-lg-6 {
        margin: 6px;
    }

    .mg-lg-6-force {
        margin: 6px !important;
    }

    .mg-lg-y-6 {
        margin-top: 6px;
        margin-bottom: 6px;
    }

    .mg-lg-y-6-force {
        margin-top: 6px !important;
        margin-bottom: 6px !important;
    }

    .mg-lg-x-6 {
        margin-left: 6px;
        margin-right: 6px;
    }

    .mg-lg-x-6-force {
        margin-left: 6px !important;
        margin-right: 6px !important;
    }

    .mg-lg-t-6 {
        margin-top: 6px;
    }

    .mg-lg-r-6 {
        margin-right: 6px;
    }

    .mg-lg-b-6 {
        margin-bottom: 6px;
    }

    .mg-lg-l-6 {
        margin-left: 6px;
    }

    .mg-lg-t-6-force {
        margin-top: 6px !important;
    }

    .mg-lg-r-6-force {
        margin-right: 6px !important;
    }

    .mg-lg-b-6-force {
        margin-bottom: 6px !important;
    }

    .mg-lg-l-6-force {
        margin-left: 6px !important;
    }

    .mg-lg-7 {
        margin: 7px;
    }

    .mg-lg-7-force {
        margin: 7px !important;
    }

    .mg-lg-y-7 {
        margin-top: 7px;
        margin-bottom: 7px;
    }

    .mg-lg-y-7-force {
        margin-top: 7px !important;
        margin-bottom: 7px !important;
    }

    .mg-lg-x-7 {
        margin-left: 7px;
        margin-right: 7px;
    }

    .mg-lg-x-7-force {
        margin-left: 7px !important;
        margin-right: 7px !important;
    }

    .mg-lg-t-7 {
        margin-top: 7px;
    }

    .mg-lg-r-7 {
        margin-right: 7px;
    }

    .mg-lg-b-7 {
        margin-bottom: 7px;
    }

    .mg-lg-l-7 {
        margin-left: 7px;
    }

    .mg-lg-t-7-force {
        margin-top: 7px !important;
    }

    .mg-lg-r-7-force {
        margin-right: 7px !important;
    }

    .mg-lg-b-7-force {
        margin-bottom: 7px !important;
    }

    .mg-lg-l-7-force {
        margin-left: 7px !important;
    }

    .mg-lg-8 {
        margin: 8px;
    }

    .mg-lg-8-force {
        margin: 8px !important;
    }

    .mg-lg-y-8 {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .mg-lg-y-8-force {
        margin-top: 8px !important;
        margin-bottom: 8px !important;
    }

    .mg-lg-x-8 {
        margin-left: 8px;
        margin-right: 8px;
    }

    .mg-lg-x-8-force {
        margin-left: 8px !important;
        margin-right: 8px !important;
    }

    .mg-lg-t-8 {
        margin-top: 8px;
    }

    .mg-lg-r-8 {
        margin-right: 8px;
    }

    .mg-lg-b-8 {
        margin-bottom: 8px;
    }

    .mg-lg-l-8 {
        margin-left: 8px;
    }

    .mg-lg-t-8-force {
        margin-top: 8px !important;
    }

    .mg-lg-r-8-force {
        margin-right: 8px !important;
    }

    .mg-lg-b-8-force {
        margin-bottom: 8px !important;
    }

    .mg-lg-l-8-force {
        margin-left: 8px !important;
    }

    .mg-lg-9 {
        margin: 9px;
    }

    .mg-lg-9-force {
        margin: 9px !important;
    }

    .mg-lg-y-9 {
        margin-top: 9px;
        margin-bottom: 9px;
    }

    .mg-lg-y-9-force {
        margin-top: 9px !important;
        margin-bottom: 9px !important;
    }

    .mg-lg-x-9 {
        margin-left: 9px;
        margin-right: 9px;
    }

    .mg-lg-x-9-force {
        margin-left: 9px !important;
        margin-right: 9px !important;
    }

    .mg-lg-t-9 {
        margin-top: 9px;
    }

    .mg-lg-r-9 {
        margin-right: 9px;
    }

    .mg-lg-b-9 {
        margin-bottom: 9px;
    }

    .mg-lg-l-9 {
        margin-left: 9px;
    }

    .mg-lg-t-9-force {
        margin-top: 9px !important;
    }

    .mg-lg-r-9-force {
        margin-right: 9px !important;
    }

    .mg-lg-b-9-force {
        margin-bottom: 9px !important;
    }

    .mg-lg-l-9-force {
        margin-left: 9px !important;
    }

    .mg-lg-10 {
        margin: 10px;
    }

    .mg-lg-10-force {
        margin: 10px !important;
    }

    .mg-lg-y-10 {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .mg-lg-y-10-force {
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }

    .mg-lg-x-10 {
        margin-left: 10px;
        margin-right: 10px;
    }

    .mg-lg-x-10-force {
        margin-left: 10px !important;
        margin-right: 10px !important;
    }

    .mg-lg-t-10 {
        margin-top: 10px;
    }

    .mg-lg-r-10 {
        margin-right: 10px;
    }

    .mg-lg-b-10 {
        margin-bottom: 10px;
    }

    .mg-lg-l-10 {
        margin-left: 10px;
    }

    .mg-lg-t-10-force {
        margin-top: 10px !important;
    }

    .mg-lg-r-10-force {
        margin-right: 10px !important;
    }

    .mg-lg-b-10-force {
        margin-bottom: 10px !important;
    }

    .mg-lg-l-10-force {
        margin-left: 10px !important;
    }

    .mg-lg-15 {
        margin: 15px;
    }

    .mg-lg-15-force {
        margin: 15px !important;
    }

    .mg-lg-y-15 {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .mg-lg-y-15-force {
        margin-top: 15px !important;
        margin-bottom: 15px !important;
    }

    .mg-lg-x-15 {
        margin-left: 15px;
        margin-right: 15px;
    }

    .mg-lg-x-15-force {
        margin-left: 15px !important;
        margin-right: 15px !important;
    }

    .mg-lg-t-15 {
        margin-top: 15px;
    }

    .mg-lg-r-15 {
        margin-right: 15px;
    }

    .mg-lg-b-15 {
        margin-bottom: 15px;
    }

    .mg-lg-l-15 {
        margin-left: 15px;
    }

    .mg-lg-t-15-force {
        margin-top: 15px !important;
    }

    .mg-lg-r-15-force {
        margin-right: 15px !important;
    }

    .mg-lg-b-15-force {
        margin-bottom: 15px !important;
    }

    .mg-lg-l-15-force {
        margin-left: 15px !important;
    }

    .mg-lg-20 {
        margin: 20px;
    }

    .mg-lg-20-force {
        margin: 20px !important;
    }

    .mg-lg-y-20 {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .mg-lg-y-20-force {
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }

    .mg-lg-x-20 {
        margin-left: 20px;
        margin-right: 20px;
    }

    .mg-lg-x-20-force {
        margin-left: 20px !important;
        margin-right: 20px !important;
    }

    .mg-lg-t-20 {
        margin-top: 20px;
    }

    .mg-lg-r-20 {
        margin-right: 20px;
    }

    .mg-lg-b-20 {
        margin-bottom: 20px;
    }

    .mg-lg-l-20 {
        margin-left: 20px;
    }

    .mg-lg-t-20-force {
        margin-top: 20px !important;
    }

    .mg-lg-r-20-force {
        margin-right: 20px !important;
    }

    .mg-lg-b-20-force {
        margin-bottom: 20px !important;
    }

    .mg-lg-l-20-force {
        margin-left: 20px !important;
    }

    .mg-lg-25 {
        margin: 25px;
    }

    .mg-lg-25-force {
        margin: 25px !important;
    }

    .mg-lg-y-25 {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .mg-lg-y-25-force {
        margin-top: 25px !important;
        margin-bottom: 25px !important;
    }

    .mg-lg-x-25 {
        margin-left: 25px;
        margin-right: 25px;
    }

    .mg-lg-x-25-force {
        margin-left: 25px !important;
        margin-right: 25px !important;
    }

    .mg-lg-t-25 {
        margin-top: 25px;
    }

    .mg-lg-r-25 {
        margin-right: 25px;
    }

    .mg-lg-b-25 {
        margin-bottom: 25px;
    }

    .mg-lg-l-25 {
        margin-left: 25px;
    }

    .mg-lg-t-25-force {
        margin-top: 25px !important;
    }

    .mg-lg-r-25-force {
        margin-right: 25px !important;
    }

    .mg-lg-b-25-force {
        margin-bottom: 25px !important;
    }

    .mg-lg-l-25-force {
        margin-left: 25px !important;
    }

    .mg-lg-30 {
        margin: 30px;
    }

    .mg-lg-30-force {
        margin: 30px !important;
    }

    .mg-lg-y-30 {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .mg-lg-y-30-force {
        margin-top: 30px !important;
        margin-bottom: 30px !important;
    }

    .mg-lg-x-30 {
        margin-left: 30px;
        margin-right: 30px;
    }

    .mg-lg-x-30-force {
        margin-left: 30px !important;
        margin-right: 30px !important;
    }

    .mg-lg-t-30 {
        margin-top: 30px;
    }

    .mg-lg-r-30 {
        margin-right: 30px;
    }

    .mg-lg-b-30 {
        margin-bottom: 30px;
    }

    .mg-lg-l-30 {
        margin-left: 30px;
    }

    .mg-lg-t-30-force {
        margin-top: 30px !important;
    }

    .mg-lg-r-30-force {
        margin-right: 30px !important;
    }

    .mg-lg-b-30-force {
        margin-bottom: 30px !important;
    }

    .mg-lg-l-30-force {
        margin-left: 30px !important;
    }

    .mg-lg-35 {
        margin: 35px;
    }

    .mg-lg-35-force {
        margin: 35px !important;
    }

    .mg-lg-y-35 {
        margin-top: 35px;
        margin-bottom: 35px;
    }

    .mg-lg-y-35-force {
        margin-top: 35px !important;
        margin-bottom: 35px !important;
    }

    .mg-lg-x-35 {
        margin-left: 35px;
        margin-right: 35px;
    }

    .mg-lg-x-35-force {
        margin-left: 35px !important;
        margin-right: 35px !important;
    }

    .mg-lg-t-35 {
        margin-top: 35px;
    }

    .mg-lg-r-35 {
        margin-right: 35px;
    }

    .mg-lg-b-35 {
        margin-bottom: 35px;
    }

    .mg-lg-l-35 {
        margin-left: 35px;
    }

    .mg-lg-t-35-force {
        margin-top: 35px !important;
    }

    .mg-lg-r-35-force {
        margin-right: 35px !important;
    }

    .mg-lg-b-35-force {
        margin-bottom: 35px !important;
    }

    .mg-lg-l-35-force {
        margin-left: 35px !important;
    }

    .mg-lg-40 {
        margin: 40px;
    }

    .mg-lg-40-force {
        margin: 40px !important;
    }

    .mg-lg-y-40 {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .mg-lg-y-40-force {
        margin-top: 40px !important;
        margin-bottom: 40px !important;
    }

    .mg-lg-x-40 {
        margin-left: 40px;
        margin-right: 40px;
    }

    .mg-lg-x-40-force {
        margin-left: 40px !important;
        margin-right: 40px !important;
    }

    .mg-lg-t-40 {
        margin-top: 40px;
    }

    .mg-lg-r-40 {
        margin-right: 40px;
    }

    .mg-lg-b-40 {
        margin-bottom: 40px;
    }

    .mg-lg-l-40 {
        margin-left: 40px;
    }

    .mg-lg-t-40-force {
        margin-top: 40px !important;
    }

    .mg-lg-r-40-force {
        margin-right: 40px !important;
    }

    .mg-lg-b-40-force {
        margin-bottom: 40px !important;
    }

    .mg-lg-l-40-force {
        margin-left: 40px !important;
    }

    .mg-lg-45 {
        margin: 45px;
    }

    .mg-lg-45-force {
        margin: 45px !important;
    }

    .mg-lg-y-45 {
        margin-top: 45px;
        margin-bottom: 45px;
    }

    .mg-lg-y-45-force {
        margin-top: 45px !important;
        margin-bottom: 45px !important;
    }

    .mg-lg-x-45 {
        margin-left: 45px;
        margin-right: 45px;
    }

    .mg-lg-x-45-force {
        margin-left: 45px !important;
        margin-right: 45px !important;
    }

    .mg-lg-t-45 {
        margin-top: 45px;
    }

    .mg-lg-r-45 {
        margin-right: 45px;
    }

    .mg-lg-b-45 {
        margin-bottom: 45px;
    }

    .mg-lg-l-45 {
        margin-left: 45px;
    }

    .mg-lg-t-45-force {
        margin-top: 45px !important;
    }

    .mg-lg-r-45-force {
        margin-right: 45px !important;
    }

    .mg-lg-b-45-force {
        margin-bottom: 45px !important;
    }

    .mg-lg-l-45-force {
        margin-left: 45px !important;
    }

    .mg-lg-50 {
        margin: 50px;
    }

    .mg-lg-50-force {
        margin: 50px !important;
    }

    .mg-lg-y-50 {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .mg-lg-y-50-force {
        margin-top: 50px !important;
        margin-bottom: 50px !important;
    }

    .mg-lg-x-50 {
        margin-left: 50px;
        margin-right: 50px;
    }

    .mg-lg-x-50-force {
        margin-left: 50px !important;
        margin-right: 50px !important;
    }

    .mg-lg-t-50 {
        margin-top: 50px;
    }

    .mg-lg-r-50 {
        margin-right: 50px;
    }

    .mg-lg-b-50 {
        margin-bottom: 50px;
    }

    .mg-lg-l-50 {
        margin-left: 50px;
    }

    .mg-lg-t-50-force {
        margin-top: 50px !important;
    }

    .mg-lg-r-50-force {
        margin-right: 50px !important;
    }

    .mg-lg-b-50-force {
        margin-bottom: 50px !important;
    }

    .mg-lg-l-50-force {
        margin-left: 50px !important;
    }

    .mg-lg-55 {
        margin: 55px;
    }

    .mg-lg-55-force {
        margin: 55px !important;
    }

    .mg-lg-y-55 {
        margin-top: 55px;
        margin-bottom: 55px;
    }

    .mg-lg-y-55-force {
        margin-top: 55px !important;
        margin-bottom: 55px !important;
    }

    .mg-lg-x-55 {
        margin-left: 55px;
        margin-right: 55px;
    }

    .mg-lg-x-55-force {
        margin-left: 55px !important;
        margin-right: 55px !important;
    }

    .mg-lg-t-55 {
        margin-top: 55px;
    }

    .mg-lg-r-55 {
        margin-right: 55px;
    }

    .mg-lg-b-55 {
        margin-bottom: 55px;
    }

    .mg-lg-l-55 {
        margin-left: 55px;
    }

    .mg-lg-t-55-force {
        margin-top: 55px !important;
    }

    .mg-lg-r-55-force {
        margin-right: 55px !important;
    }

    .mg-lg-b-55-force {
        margin-bottom: 55px !important;
    }

    .mg-lg-l-55-force {
        margin-left: 55px !important;
    }

    .mg-lg-60 {
        margin: 60px;
    }

    .mg-lg-60-force {
        margin: 60px !important;
    }

    .mg-lg-y-60 {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .mg-lg-y-60-force {
        margin-top: 60px !important;
        margin-bottom: 60px !important;
    }

    .mg-lg-x-60 {
        margin-left: 60px;
        margin-right: 60px;
    }

    .mg-lg-x-60-force {
        margin-left: 60px !important;
        margin-right: 60px !important;
    }

    .mg-lg-t-60 {
        margin-top: 60px;
    }

    .mg-lg-r-60 {
        margin-right: 60px;
    }

    .mg-lg-b-60 {
        margin-bottom: 60px;
    }

    .mg-lg-l-60 {
        margin-left: 60px;
    }

    .mg-lg-t-60-force {
        margin-top: 60px !important;
    }

    .mg-lg-r-60-force {
        margin-right: 60px !important;
    }

    .mg-lg-b-60-force {
        margin-bottom: 60px !important;
    }

    .mg-lg-l-60-force {
        margin-left: 60px !important;
    }

    .mg-lg-65 {
        margin: 65px;
    }

    .mg-lg-65-force {
        margin: 65px !important;
    }

    .mg-lg-y-65 {
        margin-top: 65px;
        margin-bottom: 65px;
    }

    .mg-lg-y-65-force {
        margin-top: 65px !important;
        margin-bottom: 65px !important;
    }

    .mg-lg-x-65 {
        margin-left: 65px;
        margin-right: 65px;
    }

    .mg-lg-x-65-force {
        margin-left: 65px !important;
        margin-right: 65px !important;
    }

    .mg-lg-t-65 {
        margin-top: 65px;
    }

    .mg-lg-r-65 {
        margin-right: 65px;
    }

    .mg-lg-b-65 {
        margin-bottom: 65px;
    }

    .mg-lg-l-65 {
        margin-left: 65px;
    }

    .mg-lg-t-65-force {
        margin-top: 65px !important;
    }

    .mg-lg-r-65-force {
        margin-right: 65px !important;
    }

    .mg-lg-b-65-force {
        margin-bottom: 65px !important;
    }

    .mg-lg-l-65-force {
        margin-left: 65px !important;
    }

    .mg-lg-70 {
        margin: 70px;
    }

    .mg-lg-70-force {
        margin: 70px !important;
    }

    .mg-lg-y-70 {
        margin-top: 70px;
        margin-bottom: 70px;
    }

    .mg-lg-y-70-force {
        margin-top: 70px !important;
        margin-bottom: 70px !important;
    }

    .mg-lg-x-70 {
        margin-left: 70px;
        margin-right: 70px;
    }

    .mg-lg-x-70-force {
        margin-left: 70px !important;
        margin-right: 70px !important;
    }

    .mg-lg-t-70 {
        margin-top: 70px;
    }

    .mg-lg-r-70 {
        margin-right: 70px;
    }

    .mg-lg-b-70 {
        margin-bottom: 70px;
    }

    .mg-lg-l-70 {
        margin-left: 70px;
    }

    .mg-lg-t-70-force {
        margin-top: 70px !important;
    }

    .mg-lg-r-70-force {
        margin-right: 70px !important;
    }

    .mg-lg-b-70-force {
        margin-bottom: 70px !important;
    }

    .mg-lg-l-70-force {
        margin-left: 70px !important;
    }

    .mg-lg-75 {
        margin: 75px;
    }

    .mg-lg-75-force {
        margin: 75px !important;
    }

    .mg-lg-y-75 {
        margin-top: 75px;
        margin-bottom: 75px;
    }

    .mg-lg-y-75-force {
        margin-top: 75px !important;
        margin-bottom: 75px !important;
    }

    .mg-lg-x-75 {
        margin-left: 75px;
        margin-right: 75px;
    }

    .mg-lg-x-75-force {
        margin-left: 75px !important;
        margin-right: 75px !important;
    }

    .mg-lg-t-75 {
        margin-top: 75px;
    }

    .mg-lg-r-75 {
        margin-right: 75px;
    }

    .mg-lg-b-75 {
        margin-bottom: 75px;
    }

    .mg-lg-l-75 {
        margin-left: 75px;
    }

    .mg-lg-t-75-force {
        margin-top: 75px !important;
    }

    .mg-lg-r-75-force {
        margin-right: 75px !important;
    }

    .mg-lg-b-75-force {
        margin-bottom: 75px !important;
    }

    .mg-lg-l-75-force {
        margin-left: 75px !important;
    }

    .mg-lg-80 {
        margin: 80px;
    }

    .mg-lg-80-force {
        margin: 80px !important;
    }

    .mg-lg-y-80 {
        margin-top: 80px;
        margin-bottom: 80px;
    }

    .mg-lg-y-80-force {
        margin-top: 80px !important;
        margin-bottom: 80px !important;
    }

    .mg-lg-x-80 {
        margin-left: 80px;
        margin-right: 80px;
    }

    .mg-lg-x-80-force {
        margin-left: 80px !important;
        margin-right: 80px !important;
    }

    .mg-lg-t-80 {
        margin-top: 80px;
    }

    .mg-lg-r-80 {
        margin-right: 80px;
    }

    .mg-lg-b-80 {
        margin-bottom: 80px;
    }

    .mg-lg-l-80 {
        margin-left: 80px;
    }

    .mg-lg-t-80-force {
        margin-top: 80px !important;
    }

    .mg-lg-r-80-force {
        margin-right: 80px !important;
    }

    .mg-lg-b-80-force {
        margin-bottom: 80px !important;
    }

    .mg-lg-l-80-force {
        margin-left: 80px !important;
    }

    .mg-lg-85 {
        margin: 85px;
    }

    .mg-lg-85-force {
        margin: 85px !important;
    }

    .mg-lg-y-85 {
        margin-top: 85px;
        margin-bottom: 85px;
    }

    .mg-lg-y-85-force {
        margin-top: 85px !important;
        margin-bottom: 85px !important;
    }

    .mg-lg-x-85 {
        margin-left: 85px;
        margin-right: 85px;
    }

    .mg-lg-x-85-force {
        margin-left: 85px !important;
        margin-right: 85px !important;
    }

    .mg-lg-t-85 {
        margin-top: 85px;
    }

    .mg-lg-r-85 {
        margin-right: 85px;
    }

    .mg-lg-b-85 {
        margin-bottom: 85px;
    }

    .mg-lg-l-85 {
        margin-left: 85px;
    }

    .mg-lg-t-85-force {
        margin-top: 85px !important;
    }

    .mg-lg-r-85-force {
        margin-right: 85px !important;
    }

    .mg-lg-b-85-force {
        margin-bottom: 85px !important;
    }

    .mg-lg-l-85-force {
        margin-left: 85px !important;
    }

    .mg-lg-90 {
        margin: 90px;
    }

    .mg-lg-90-force {
        margin: 90px !important;
    }

    .mg-lg-y-90 {
        margin-top: 90px;
        margin-bottom: 90px;
    }

    .mg-lg-y-90-force {
        margin-top: 90px !important;
        margin-bottom: 90px !important;
    }

    .mg-lg-x-90 {
        margin-left: 90px;
        margin-right: 90px;
    }

    .mg-lg-x-90-force {
        margin-left: 90px !important;
        margin-right: 90px !important;
    }

    .mg-lg-t-90 {
        margin-top: 90px;
    }

    .mg-lg-r-90 {
        margin-right: 90px;
    }

    .mg-lg-b-90 {
        margin-bottom: 90px;
    }

    .mg-lg-l-90 {
        margin-left: 90px;
    }

    .mg-lg-t-90-force {
        margin-top: 90px !important;
    }

    .mg-lg-r-90-force {
        margin-right: 90px !important;
    }

    .mg-lg-b-90-force {
        margin-bottom: 90px !important;
    }

    .mg-lg-l-90-force {
        margin-left: 90px !important;
    }

    .mg-lg-95 {
        margin: 95px;
    }

    .mg-lg-95-force {
        margin: 95px !important;
    }

    .mg-lg-y-95 {
        margin-top: 95px;
        margin-bottom: 95px;
    }

    .mg-lg-y-95-force {
        margin-top: 95px !important;
        margin-bottom: 95px !important;
    }

    .mg-lg-x-95 {
        margin-left: 95px;
        margin-right: 95px;
    }

    .mg-lg-x-95-force {
        margin-left: 95px !important;
        margin-right: 95px !important;
    }

    .mg-lg-t-95 {
        margin-top: 95px;
    }

    .mg-lg-r-95 {
        margin-right: 95px;
    }

    .mg-lg-b-95 {
        margin-bottom: 95px;
    }

    .mg-lg-l-95 {
        margin-left: 95px;
    }

    .mg-lg-t-95-force {
        margin-top: 95px !important;
    }

    .mg-lg-r-95-force {
        margin-right: 95px !important;
    }

    .mg-lg-b-95-force {
        margin-bottom: 95px !important;
    }

    .mg-lg-l-95-force {
        margin-left: 95px !important;
    }

    .mg-lg-100 {
        margin: 100px;
    }

    .mg-lg-100-force {
        margin: 100px !important;
    }

    .mg-lg-y-100 {
        margin-top: 100px;
        margin-bottom: 100px;
    }

    .mg-lg-y-100-force {
        margin-top: 100px !important;
        margin-bottom: 100px !important;
    }

    .mg-lg-x-100 {
        margin-left: 100px;
        margin-right: 100px;
    }

    .mg-lg-x-100-force {
        margin-left: 100px !important;
        margin-right: 100px !important;
    }

    .mg-lg-t-100 {
        margin-top: 100px;
    }

    .mg-lg-r-100 {
        margin-right: 100px;
    }

    .mg-lg-b-100 {
        margin-bottom: 100px;
    }

    .mg-lg-l-100 {
        margin-left: 100px;
    }

    .mg-lg-t-100-force {
        margin-top: 100px !important;
    }

    .mg-lg-r-100-force {
        margin-right: 100px !important;
    }

    .mg-lg-b-100-force {
        margin-bottom: 100px !important;
    }

    .mg-lg-l-100-force {
        margin-left: 100px !important;
    }

    .mg-lg-t-auto {
        margin-top: auto;
    }

    .mg-lg-r-auto {
        margin-right: auto;
    }

    .mg-lg-b-auto {
        margin-bottom: auto;
    }

    .mg-lg-l-auto {
        margin-left: auto;
    }

    .mg-lg-auto {
        margin: auto;
    }

    .mg-lg-x-auto {
        margin-left: auto;
        margin-right: auto;
    }

    .mg-lg-l--1 {
        margin-left: -1px;
    }

    .mg-lg-r--1 {
        margin-right: -1px;
    }

    .mg-lg-t--1 {
        margin-top: -1px;
    }
}

@media (min-width: 1200px) {
    .mg-xl-0 {
        margin: 0px;
    }

    .mg-xl-0-force {
        margin: 0px !important;
    }

    .mg-xl-y-0 {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .mg-xl-y-0-force {
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

    .mg-xl-x-0 {
        margin-left: 0px;
        margin-right: 0px;
    }

    .mg-xl-x-0-force {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .mg-xl-t-0 {
        margin-top: 0px;
    }

    .mg-xl-r-0 {
        margin-right: 0px;
    }

    .mg-xl-b-0 {
        margin-bottom: 0px;
    }

    .mg-xl-l-0 {
        margin-left: 0px;
    }

    .mg-xl-t-0-force {
        margin-top: 0px !important;
    }

    .mg-xl-r-0-force {
        margin-right: 0px !important;
    }

    .mg-xl-b-0-force {
        margin-bottom: 0px !important;
    }

    .mg-xl-l-0-force {
        margin-left: 0px !important;
    }

    .mg-xl-1 {
        margin: 1px;
    }

    .mg-xl-1-force {
        margin: 1px !important;
    }

    .mg-xl-y-1 {
        margin-top: 1px;
        margin-bottom: 1px;
    }

    .mg-xl-y-1-force {
        margin-top: 1px !important;
        margin-bottom: 1px !important;
    }

    .mg-xl-x-1 {
        margin-left: 1px;
        margin-right: 1px;
    }

    .mg-xl-x-1-force {
        margin-left: 1px !important;
        margin-right: 1px !important;
    }

    .mg-xl-t-1 {
        margin-top: 1px;
    }

    .mg-xl-r-1 {
        margin-right: 1px;
    }

    .mg-xl-b-1 {
        margin-bottom: 1px;
    }

    .mg-xl-l-1 {
        margin-left: 1px;
    }

    .mg-xl-t-1-force {
        margin-top: 1px !important;
    }

    .mg-xl-r-1-force {
        margin-right: 1px !important;
    }

    .mg-xl-b-1-force {
        margin-bottom: 1px !important;
    }

    .mg-xl-l-1-force {
        margin-left: 1px !important;
    }

    .mg-xl-2 {
        margin: 2px;
    }

    .mg-xl-2-force {
        margin: 2px !important;
    }

    .mg-xl-y-2 {
        margin-top: 2px;
        margin-bottom: 2px;
    }

    .mg-xl-y-2-force {
        margin-top: 2px !important;
        margin-bottom: 2px !important;
    }

    .mg-xl-x-2 {
        margin-left: 2px;
        margin-right: 2px;
    }

    .mg-xl-x-2-force {
        margin-left: 2px !important;
        margin-right: 2px !important;
    }

    .mg-xl-t-2 {
        margin-top: 2px;
    }

    .mg-xl-r-2 {
        margin-right: 2px;
    }

    .mg-xl-b-2 {
        margin-bottom: 2px;
    }

    .mg-xl-l-2 {
        margin-left: 2px;
    }

    .mg-xl-t-2-force {
        margin-top: 2px !important;
    }

    .mg-xl-r-2-force {
        margin-right: 2px !important;
    }

    .mg-xl-b-2-force {
        margin-bottom: 2px !important;
    }

    .mg-xl-l-2-force {
        margin-left: 2px !important;
    }

    .mg-xl-3 {
        margin: 3px;
    }

    .mg-xl-3-force {
        margin: 3px !important;
    }

    .mg-xl-y-3 {
        margin-top: 3px;
        margin-bottom: 3px;
    }

    .mg-xl-y-3-force {
        margin-top: 3px !important;
        margin-bottom: 3px !important;
    }

    .mg-xl-x-3 {
        margin-left: 3px;
        margin-right: 3px;
    }

    .mg-xl-x-3-force {
        margin-left: 3px !important;
        margin-right: 3px !important;
    }

    .mg-xl-t-3 {
        margin-top: 3px;
    }

    .mg-xl-r-3 {
        margin-right: 3px;
    }

    .mg-xl-b-3 {
        margin-bottom: 3px;
    }

    .mg-xl-l-3 {
        margin-left: 3px;
    }

    .mg-xl-t-3-force {
        margin-top: 3px !important;
    }

    .mg-xl-r-3-force {
        margin-right: 3px !important;
    }

    .mg-xl-b-3-force {
        margin-bottom: 3px !important;
    }

    .mg-xl-l-3-force {
        margin-left: 3px !important;
    }

    .mg-xl-4 {
        margin: 4px;
    }

    .mg-xl-4-force {
        margin: 4px !important;
    }

    .mg-xl-y-4 {
        margin-top: 4px;
        margin-bottom: 4px;
    }

    .mg-xl-y-4-force {
        margin-top: 4px !important;
        margin-bottom: 4px !important;
    }

    .mg-xl-x-4 {
        margin-left: 4px;
        margin-right: 4px;
    }

    .mg-xl-x-4-force {
        margin-left: 4px !important;
        margin-right: 4px !important;
    }

    .mg-xl-t-4 {
        margin-top: 4px;
    }

    .mg-xl-r-4 {
        margin-right: 4px;
    }

    .mg-xl-b-4 {
        margin-bottom: 4px;
    }

    .mg-xl-l-4 {
        margin-left: 4px;
    }

    .mg-xl-t-4-force {
        margin-top: 4px !important;
    }

    .mg-xl-r-4-force {
        margin-right: 4px !important;
    }

    .mg-xl-b-4-force {
        margin-bottom: 4px !important;
    }

    .mg-xl-l-4-force {
        margin-left: 4px !important;
    }

    .mg-xl-5 {
        margin: 5px;
    }

    .mg-xl-5-force {
        margin: 5px !important;
    }

    .mg-xl-y-5 {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .mg-xl-y-5-force {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
    }

    .mg-xl-x-5 {
        margin-left: 5px;
        margin-right: 5px;
    }

    .mg-xl-x-5-force {
        margin-left: 5px !important;
        margin-right: 5px !important;
    }

    .mg-xl-t-5 {
        margin-top: 5px;
    }

    .mg-xl-r-5 {
        margin-right: 5px;
    }

    .mg-xl-b-5 {
        margin-bottom: 5px;
    }

    .mg-xl-l-5 {
        margin-left: 5px;
    }

    .mg-xl-t-5-force {
        margin-top: 5px !important;
    }

    .mg-xl-r-5-force {
        margin-right: 5px !important;
    }

    .mg-xl-b-5-force {
        margin-bottom: 5px !important;
    }

    .mg-xl-l-5-force {
        margin-left: 5px !important;
    }

    .mg-xl-6 {
        margin: 6px;
    }

    .mg-xl-6-force {
        margin: 6px !important;
    }

    .mg-xl-y-6 {
        margin-top: 6px;
        margin-bottom: 6px;
    }

    .mg-xl-y-6-force {
        margin-top: 6px !important;
        margin-bottom: 6px !important;
    }

    .mg-xl-x-6 {
        margin-left: 6px;
        margin-right: 6px;
    }

    .mg-xl-x-6-force {
        margin-left: 6px !important;
        margin-right: 6px !important;
    }

    .mg-xl-t-6 {
        margin-top: 6px;
    }

    .mg-xl-r-6 {
        margin-right: 6px;
    }

    .mg-xl-b-6 {
        margin-bottom: 6px;
    }

    .mg-xl-l-6 {
        margin-left: 6px;
    }

    .mg-xl-t-6-force {
        margin-top: 6px !important;
    }

    .mg-xl-r-6-force {
        margin-right: 6px !important;
    }

    .mg-xl-b-6-force {
        margin-bottom: 6px !important;
    }

    .mg-xl-l-6-force {
        margin-left: 6px !important;
    }

    .mg-xl-7 {
        margin: 7px;
    }

    .mg-xl-7-force {
        margin: 7px !important;
    }

    .mg-xl-y-7 {
        margin-top: 7px;
        margin-bottom: 7px;
    }

    .mg-xl-y-7-force {
        margin-top: 7px !important;
        margin-bottom: 7px !important;
    }

    .mg-xl-x-7 {
        margin-left: 7px;
        margin-right: 7px;
    }

    .mg-xl-x-7-force {
        margin-left: 7px !important;
        margin-right: 7px !important;
    }

    .mg-xl-t-7 {
        margin-top: 7px;
    }

    .mg-xl-r-7 {
        margin-right: 7px;
    }

    .mg-xl-b-7 {
        margin-bottom: 7px;
    }

    .mg-xl-l-7 {
        margin-left: 7px;
    }

    .mg-xl-t-7-force {
        margin-top: 7px !important;
    }

    .mg-xl-r-7-force {
        margin-right: 7px !important;
    }

    .mg-xl-b-7-force {
        margin-bottom: 7px !important;
    }

    .mg-xl-l-7-force {
        margin-left: 7px !important;
    }

    .mg-xl-8 {
        margin: 8px;
    }

    .mg-xl-8-force {
        margin: 8px !important;
    }

    .mg-xl-y-8 {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .mg-xl-y-8-force {
        margin-top: 8px !important;
        margin-bottom: 8px !important;
    }

    .mg-xl-x-8 {
        margin-left: 8px;
        margin-right: 8px;
    }

    .mg-xl-x-8-force {
        margin-left: 8px !important;
        margin-right: 8px !important;
    }

    .mg-xl-t-8 {
        margin-top: 8px;
    }

    .mg-xl-r-8 {
        margin-right: 8px;
    }

    .mg-xl-b-8 {
        margin-bottom: 8px;
    }

    .mg-xl-l-8 {
        margin-left: 8px;
    }

    .mg-xl-t-8-force {
        margin-top: 8px !important;
    }

    .mg-xl-r-8-force {
        margin-right: 8px !important;
    }

    .mg-xl-b-8-force {
        margin-bottom: 8px !important;
    }

    .mg-xl-l-8-force {
        margin-left: 8px !important;
    }

    .mg-xl-9 {
        margin: 9px;
    }

    .mg-xl-9-force {
        margin: 9px !important;
    }

    .mg-xl-y-9 {
        margin-top: 9px;
        margin-bottom: 9px;
    }

    .mg-xl-y-9-force {
        margin-top: 9px !important;
        margin-bottom: 9px !important;
    }

    .mg-xl-x-9 {
        margin-left: 9px;
        margin-right: 9px;
    }

    .mg-xl-x-9-force {
        margin-left: 9px !important;
        margin-right: 9px !important;
    }

    .mg-xl-t-9 {
        margin-top: 9px;
    }

    .mg-xl-r-9 {
        margin-right: 9px;
    }

    .mg-xl-b-9 {
        margin-bottom: 9px;
    }

    .mg-xl-l-9 {
        margin-left: 9px;
    }

    .mg-xl-t-9-force {
        margin-top: 9px !important;
    }

    .mg-xl-r-9-force {
        margin-right: 9px !important;
    }

    .mg-xl-b-9-force {
        margin-bottom: 9px !important;
    }

    .mg-xl-l-9-force {
        margin-left: 9px !important;
    }

    .mg-xl-10 {
        margin: 10px;
    }

    .mg-xl-10-force {
        margin: 10px !important;
    }

    .mg-xl-y-10 {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .mg-xl-y-10-force {
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }

    .mg-xl-x-10 {
        margin-left: 10px;
        margin-right: 10px;
    }

    .mg-xl-x-10-force {
        margin-left: 10px !important;
        margin-right: 10px !important;
    }

    .mg-xl-t-10 {
        margin-top: 10px;
    }

    .mg-xl-r-10 {
        margin-right: 10px;
    }

    .mg-xl-b-10 {
        margin-bottom: 10px;
    }

    .mg-xl-l-10 {
        margin-left: 10px;
    }

    .mg-xl-t-10-force {
        margin-top: 10px !important;
    }

    .mg-xl-r-10-force {
        margin-right: 10px !important;
    }

    .mg-xl-b-10-force {
        margin-bottom: 10px !important;
    }

    .mg-xl-l-10-force {
        margin-left: 10px !important;
    }

    .mg-xl-15 {
        margin: 15px;
    }

    .mg-xl-15-force {
        margin: 15px !important;
    }

    .mg-xl-y-15 {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .mg-xl-y-15-force {
        margin-top: 15px !important;
        margin-bottom: 15px !important;
    }

    .mg-xl-x-15 {
        margin-left: 15px;
        margin-right: 15px;
    }

    .mg-xl-x-15-force {
        margin-left: 15px !important;
        margin-right: 15px !important;
    }

    .mg-xl-t-15 {
        margin-top: 15px;
    }

    .mg-xl-r-15 {
        margin-right: 15px;
    }

    .mg-xl-b-15 {
        margin-bottom: 15px;
    }

    .mg-xl-l-15 {
        margin-left: 15px;
    }

    .mg-xl-t-15-force {
        margin-top: 15px !important;
    }

    .mg-xl-r-15-force {
        margin-right: 15px !important;
    }

    .mg-xl-b-15-force {
        margin-bottom: 15px !important;
    }

    .mg-xl-l-15-force {
        margin-left: 15px !important;
    }

    .mg-xl-20 {
        margin: 20px;
    }

    .mg-xl-20-force {
        margin: 20px !important;
    }

    .mg-xl-y-20 {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .mg-xl-y-20-force {
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }

    .mg-xl-x-20 {
        margin-left: 20px;
        margin-right: 20px;
    }

    .mg-xl-x-20-force {
        margin-left: 20px !important;
        margin-right: 20px !important;
    }

    .mg-xl-t-20 {
        margin-top: 20px;
    }

    .mg-xl-r-20 {
        margin-right: 20px;
    }

    .mg-xl-b-20 {
        margin-bottom: 20px;
    }

    .mg-xl-l-20 {
        margin-left: 20px;
    }

    .mg-xl-t-20-force {
        margin-top: 20px !important;
    }

    .mg-xl-r-20-force {
        margin-right: 20px !important;
    }

    .mg-xl-b-20-force {
        margin-bottom: 20px !important;
    }

    .mg-xl-l-20-force {
        margin-left: 20px !important;
    }

    .mg-xl-25 {
        margin: 25px;
    }

    .mg-xl-25-force {
        margin: 25px !important;
    }

    .mg-xl-y-25 {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .mg-xl-y-25-force {
        margin-top: 25px !important;
        margin-bottom: 25px !important;
    }

    .mg-xl-x-25 {
        margin-left: 25px;
        margin-right: 25px;
    }

    .mg-xl-x-25-force {
        margin-left: 25px !important;
        margin-right: 25px !important;
    }

    .mg-xl-t-25 {
        margin-top: 25px;
    }

    .mg-xl-r-25 {
        margin-right: 25px;
    }

    .mg-xl-b-25 {
        margin-bottom: 25px;
    }

    .mg-xl-l-25 {
        margin-left: 25px;
    }

    .mg-xl-t-25-force {
        margin-top: 25px !important;
    }

    .mg-xl-r-25-force {
        margin-right: 25px !important;
    }

    .mg-xl-b-25-force {
        margin-bottom: 25px !important;
    }

    .mg-xl-l-25-force {
        margin-left: 25px !important;
    }

    .mg-xl-30 {
        margin: 30px;
    }

    .mg-xl-30-force {
        margin: 30px !important;
    }

    .mg-xl-y-30 {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .mg-xl-y-30-force {
        margin-top: 30px !important;
        margin-bottom: 30px !important;
    }

    .mg-xl-x-30 {
        margin-left: 30px;
        margin-right: 30px;
    }

    .mg-xl-x-30-force {
        margin-left: 30px !important;
        margin-right: 30px !important;
    }

    .mg-xl-t-30 {
        margin-top: 30px;
    }

    .mg-xl-r-30 {
        margin-right: 30px;
    }

    .mg-xl-b-30 {
        margin-bottom: 30px;
    }

    .mg-xl-l-30 {
        margin-left: 30px;
    }

    .mg-xl-t-30-force {
        margin-top: 30px !important;
    }

    .mg-xl-r-30-force {
        margin-right: 30px !important;
    }

    .mg-xl-b-30-force {
        margin-bottom: 30px !important;
    }

    .mg-xl-l-30-force {
        margin-left: 30px !important;
    }

    .mg-xl-35 {
        margin: 35px;
    }

    .mg-xl-35-force {
        margin: 35px !important;
    }

    .mg-xl-y-35 {
        margin-top: 35px;
        margin-bottom: 35px;
    }

    .mg-xl-y-35-force {
        margin-top: 35px !important;
        margin-bottom: 35px !important;
    }

    .mg-xl-x-35 {
        margin-left: 35px;
        margin-right: 35px;
    }

    .mg-xl-x-35-force {
        margin-left: 35px !important;
        margin-right: 35px !important;
    }

    .mg-xl-t-35 {
        margin-top: 35px;
    }

    .mg-xl-r-35 {
        margin-right: 35px;
    }

    .mg-xl-b-35 {
        margin-bottom: 35px;
    }

    .mg-xl-l-35 {
        margin-left: 35px;
    }

    .mg-xl-t-35-force {
        margin-top: 35px !important;
    }

    .mg-xl-r-35-force {
        margin-right: 35px !important;
    }

    .mg-xl-b-35-force {
        margin-bottom: 35px !important;
    }

    .mg-xl-l-35-force {
        margin-left: 35px !important;
    }

    .mg-xl-40 {
        margin: 40px;
    }

    .mg-xl-40-force {
        margin: 40px !important;
    }

    .mg-xl-y-40 {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .mg-xl-y-40-force {
        margin-top: 40px !important;
        margin-bottom: 40px !important;
    }

    .mg-xl-x-40 {
        margin-left: 40px;
        margin-right: 40px;
    }

    .mg-xl-x-40-force {
        margin-left: 40px !important;
        margin-right: 40px !important;
    }

    .mg-xl-t-40 {
        margin-top: 40px;
    }

    .mg-xl-r-40 {
        margin-right: 40px;
    }

    .mg-xl-b-40 {
        margin-bottom: 40px;
    }

    .mg-xl-l-40 {
        margin-left: 40px;
    }

    .mg-xl-t-40-force {
        margin-top: 40px !important;
    }

    .mg-xl-r-40-force {
        margin-right: 40px !important;
    }

    .mg-xl-b-40-force {
        margin-bottom: 40px !important;
    }

    .mg-xl-l-40-force {
        margin-left: 40px !important;
    }

    .mg-xl-45 {
        margin: 45px;
    }

    .mg-xl-45-force {
        margin: 45px !important;
    }

    .mg-xl-y-45 {
        margin-top: 45px;
        margin-bottom: 45px;
    }

    .mg-xl-y-45-force {
        margin-top: 45px !important;
        margin-bottom: 45px !important;
    }

    .mg-xl-x-45 {
        margin-left: 45px;
        margin-right: 45px;
    }

    .mg-xl-x-45-force {
        margin-left: 45px !important;
        margin-right: 45px !important;
    }

    .mg-xl-t-45 {
        margin-top: 45px;
    }

    .mg-xl-r-45 {
        margin-right: 45px;
    }

    .mg-xl-b-45 {
        margin-bottom: 45px;
    }

    .mg-xl-l-45 {
        margin-left: 45px;
    }

    .mg-xl-t-45-force {
        margin-top: 45px !important;
    }

    .mg-xl-r-45-force {
        margin-right: 45px !important;
    }

    .mg-xl-b-45-force {
        margin-bottom: 45px !important;
    }

    .mg-xl-l-45-force {
        margin-left: 45px !important;
    }

    .mg-xl-50 {
        margin: 50px;
    }

    .mg-xl-50-force {
        margin: 50px !important;
    }

    .mg-xl-y-50 {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .mg-xl-y-50-force {
        margin-top: 50px !important;
        margin-bottom: 50px !important;
    }

    .mg-xl-x-50 {
        margin-left: 50px;
        margin-right: 50px;
    }

    .mg-xl-x-50-force {
        margin-left: 50px !important;
        margin-right: 50px !important;
    }

    .mg-xl-t-50 {
        margin-top: 50px;
    }

    .mg-xl-r-50 {
        margin-right: 50px;
    }

    .mg-xl-b-50 {
        margin-bottom: 50px;
    }

    .mg-xl-l-50 {
        margin-left: 50px;
    }

    .mg-xl-t-50-force {
        margin-top: 50px !important;
    }

    .mg-xl-r-50-force {
        margin-right: 50px !important;
    }

    .mg-xl-b-50-force {
        margin-bottom: 50px !important;
    }

    .mg-xl-l-50-force {
        margin-left: 50px !important;
    }

    .mg-xl-55 {
        margin: 55px;
    }

    .mg-xl-55-force {
        margin: 55px !important;
    }

    .mg-xl-y-55 {
        margin-top: 55px;
        margin-bottom: 55px;
    }

    .mg-xl-y-55-force {
        margin-top: 55px !important;
        margin-bottom: 55px !important;
    }

    .mg-xl-x-55 {
        margin-left: 55px;
        margin-right: 55px;
    }

    .mg-xl-x-55-force {
        margin-left: 55px !important;
        margin-right: 55px !important;
    }

    .mg-xl-t-55 {
        margin-top: 55px;
    }

    .mg-xl-r-55 {
        margin-right: 55px;
    }

    .mg-xl-b-55 {
        margin-bottom: 55px;
    }

    .mg-xl-l-55 {
        margin-left: 55px;
    }

    .mg-xl-t-55-force {
        margin-top: 55px !important;
    }

    .mg-xl-r-55-force {
        margin-right: 55px !important;
    }

    .mg-xl-b-55-force {
        margin-bottom: 55px !important;
    }

    .mg-xl-l-55-force {
        margin-left: 55px !important;
    }

    .mg-xl-60 {
        margin: 60px;
    }

    .mg-xl-60-force {
        margin: 60px !important;
    }

    .mg-xl-y-60 {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .mg-xl-y-60-force {
        margin-top: 60px !important;
        margin-bottom: 60px !important;
    }

    .mg-xl-x-60 {
        margin-left: 60px;
        margin-right: 60px;
    }

    .mg-xl-x-60-force {
        margin-left: 60px !important;
        margin-right: 60px !important;
    }

    .mg-xl-t-60 {
        margin-top: 60px;
    }

    .mg-xl-r-60 {
        margin-right: 60px;
    }

    .mg-xl-b-60 {
        margin-bottom: 60px;
    }

    .mg-xl-l-60 {
        margin-left: 60px;
    }

    .mg-xl-t-60-force {
        margin-top: 60px !important;
    }

    .mg-xl-r-60-force {
        margin-right: 60px !important;
    }

    .mg-xl-b-60-force {
        margin-bottom: 60px !important;
    }

    .mg-xl-l-60-force {
        margin-left: 60px !important;
    }

    .mg-xl-65 {
        margin: 65px;
    }

    .mg-xl-65-force {
        margin: 65px !important;
    }

    .mg-xl-y-65 {
        margin-top: 65px;
        margin-bottom: 65px;
    }

    .mg-xl-y-65-force {
        margin-top: 65px !important;
        margin-bottom: 65px !important;
    }

    .mg-xl-x-65 {
        margin-left: 65px;
        margin-right: 65px;
    }

    .mg-xl-x-65-force {
        margin-left: 65px !important;
        margin-right: 65px !important;
    }

    .mg-xl-t-65 {
        margin-top: 65px;
    }

    .mg-xl-r-65 {
        margin-right: 65px;
    }

    .mg-xl-b-65 {
        margin-bottom: 65px;
    }

    .mg-xl-l-65 {
        margin-left: 65px;
    }

    .mg-xl-t-65-force {
        margin-top: 65px !important;
    }

    .mg-xl-r-65-force {
        margin-right: 65px !important;
    }

    .mg-xl-b-65-force {
        margin-bottom: 65px !important;
    }

    .mg-xl-l-65-force {
        margin-left: 65px !important;
    }

    .mg-xl-70 {
        margin: 70px;
    }

    .mg-xl-70-force {
        margin: 70px !important;
    }

    .mg-xl-y-70 {
        margin-top: 70px;
        margin-bottom: 70px;
    }

    .mg-xl-y-70-force {
        margin-top: 70px !important;
        margin-bottom: 70px !important;
    }

    .mg-xl-x-70 {
        margin-left: 70px;
        margin-right: 70px;
    }

    .mg-xl-x-70-force {
        margin-left: 70px !important;
        margin-right: 70px !important;
    }

    .mg-xl-t-70 {
        margin-top: 70px;
    }

    .mg-xl-r-70 {
        margin-right: 70px;
    }

    .mg-xl-b-70 {
        margin-bottom: 70px;
    }

    .mg-xl-l-70 {
        margin-left: 70px;
    }

    .mg-xl-t-70-force {
        margin-top: 70px !important;
    }

    .mg-xl-r-70-force {
        margin-right: 70px !important;
    }

    .mg-xl-b-70-force {
        margin-bottom: 70px !important;
    }

    .mg-xl-l-70-force {
        margin-left: 70px !important;
    }

    .mg-xl-75 {
        margin: 75px;
    }

    .mg-xl-75-force {
        margin: 75px !important;
    }

    .mg-xl-y-75 {
        margin-top: 75px;
        margin-bottom: 75px;
    }

    .mg-xl-y-75-force {
        margin-top: 75px !important;
        margin-bottom: 75px !important;
    }

    .mg-xl-x-75 {
        margin-left: 75px;
        margin-right: 75px;
    }

    .mg-xl-x-75-force {
        margin-left: 75px !important;
        margin-right: 75px !important;
    }

    .mg-xl-t-75 {
        margin-top: 75px;
    }

    .mg-xl-r-75 {
        margin-right: 75px;
    }

    .mg-xl-b-75 {
        margin-bottom: 75px;
    }

    .mg-xl-l-75 {
        margin-left: 75px;
    }

    .mg-xl-t-75-force {
        margin-top: 75px !important;
    }

    .mg-xl-r-75-force {
        margin-right: 75px !important;
    }

    .mg-xl-b-75-force {
        margin-bottom: 75px !important;
    }

    .mg-xl-l-75-force {
        margin-left: 75px !important;
    }

    .mg-xl-80 {
        margin: 80px;
    }

    .mg-xl-80-force {
        margin: 80px !important;
    }

    .mg-xl-y-80 {
        margin-top: 80px;
        margin-bottom: 80px;
    }

    .mg-xl-y-80-force {
        margin-top: 80px !important;
        margin-bottom: 80px !important;
    }

    .mg-xl-x-80 {
        margin-left: 80px;
        margin-right: 80px;
    }

    .mg-xl-x-80-force {
        margin-left: 80px !important;
        margin-right: 80px !important;
    }

    .mg-xl-t-80 {
        margin-top: 80px;
    }

    .mg-xl-r-80 {
        margin-right: 80px;
    }

    .mg-xl-b-80 {
        margin-bottom: 80px;
    }

    .mg-xl-l-80 {
        margin-left: 80px;
    }

    .mg-xl-t-80-force {
        margin-top: 80px !important;
    }

    .mg-xl-r-80-force {
        margin-right: 80px !important;
    }

    .mg-xl-b-80-force {
        margin-bottom: 80px !important;
    }

    .mg-xl-l-80-force {
        margin-left: 80px !important;
    }

    .mg-xl-85 {
        margin: 85px;
    }

    .mg-xl-85-force {
        margin: 85px !important;
    }

    .mg-xl-y-85 {
        margin-top: 85px;
        margin-bottom: 85px;
    }

    .mg-xl-y-85-force {
        margin-top: 85px !important;
        margin-bottom: 85px !important;
    }

    .mg-xl-x-85 {
        margin-left: 85px;
        margin-right: 85px;
    }

    .mg-xl-x-85-force {
        margin-left: 85px !important;
        margin-right: 85px !important;
    }

    .mg-xl-t-85 {
        margin-top: 85px;
    }

    .mg-xl-r-85 {
        margin-right: 85px;
    }

    .mg-xl-b-85 {
        margin-bottom: 85px;
    }

    .mg-xl-l-85 {
        margin-left: 85px;
    }

    .mg-xl-t-85-force {
        margin-top: 85px !important;
    }

    .mg-xl-r-85-force {
        margin-right: 85px !important;
    }

    .mg-xl-b-85-force {
        margin-bottom: 85px !important;
    }

    .mg-xl-l-85-force {
        margin-left: 85px !important;
    }

    .mg-xl-90 {
        margin: 90px;
    }

    .mg-xl-90-force {
        margin: 90px !important;
    }

    .mg-xl-y-90 {
        margin-top: 90px;
        margin-bottom: 90px;
    }

    .mg-xl-y-90-force {
        margin-top: 90px !important;
        margin-bottom: 90px !important;
    }

    .mg-xl-x-90 {
        margin-left: 90px;
        margin-right: 90px;
    }

    .mg-xl-x-90-force {
        margin-left: 90px !important;
        margin-right: 90px !important;
    }

    .mg-xl-t-90 {
        margin-top: 90px;
    }

    .mg-xl-r-90 {
        margin-right: 90px;
    }

    .mg-xl-b-90 {
        margin-bottom: 90px;
    }

    .mg-xl-l-90 {
        margin-left: 90px;
    }

    .mg-xl-t-90-force {
        margin-top: 90px !important;
    }

    .mg-xl-r-90-force {
        margin-right: 90px !important;
    }

    .mg-xl-b-90-force {
        margin-bottom: 90px !important;
    }

    .mg-xl-l-90-force {
        margin-left: 90px !important;
    }

    .mg-xl-95 {
        margin: 95px;
    }

    .mg-xl-95-force {
        margin: 95px !important;
    }

    .mg-xl-y-95 {
        margin-top: 95px;
        margin-bottom: 95px;
    }

    .mg-xl-y-95-force {
        margin-top: 95px !important;
        margin-bottom: 95px !important;
    }

    .mg-xl-x-95 {
        margin-left: 95px;
        margin-right: 95px;
    }

    .mg-xl-x-95-force {
        margin-left: 95px !important;
        margin-right: 95px !important;
    }

    .mg-xl-t-95 {
        margin-top: 95px;
    }

    .mg-xl-r-95 {
        margin-right: 95px;
    }

    .mg-xl-b-95 {
        margin-bottom: 95px;
    }

    .mg-xl-l-95 {
        margin-left: 95px;
    }

    .mg-xl-t-95-force {
        margin-top: 95px !important;
    }

    .mg-xl-r-95-force {
        margin-right: 95px !important;
    }

    .mg-xl-b-95-force {
        margin-bottom: 95px !important;
    }

    .mg-xl-l-95-force {
        margin-left: 95px !important;
    }

    .mg-xl-100 {
        margin: 100px;
    }

    .mg-xl-100-force {
        margin: 100px !important;
    }

    .mg-xl-y-100 {
        margin-top: 100px;
        margin-bottom: 100px;
    }

    .mg-xl-y-100-force {
        margin-top: 100px !important;
        margin-bottom: 100px !important;
    }

    .mg-xl-x-100 {
        margin-left: 100px;
        margin-right: 100px;
    }

    .mg-xl-x-100-force {
        margin-left: 100px !important;
        margin-right: 100px !important;
    }

    .mg-xl-t-100 {
        margin-top: 100px;
    }

    .mg-xl-r-100 {
        margin-right: 100px;
    }

    .mg-xl-b-100 {
        margin-bottom: 100px;
    }

    .mg-xl-l-100 {
        margin-left: 100px;
    }

    .mg-xl-t-100-force {
        margin-top: 100px !important;
    }

    .mg-xl-r-100-force {
        margin-right: 100px !important;
    }

    .mg-xl-b-100-force {
        margin-bottom: 100px !important;
    }

    .mg-xl-l-100-force {
        margin-left: 100px !important;
    }

    .mg-xl-t-auto {
        margin-top: auto;
    }

    .mg-xl-r-auto {
        margin-right: auto;
    }

    .mg-xl-b-auto {
        margin-bottom: auto;
    }

    .mg-xl-l-auto {
        margin-left: auto;
    }

    .mg-xl-auto {
        margin: auto;
    }

    .mg-xl-x-auto {
        margin-left: auto;
        margin-right: auto;
    }

    .mg-xl-l--1 {
        margin-left: -1px;
    }

    .mg-xl-r--1 {
        margin-right: -1px;
    }

    .mg-xl-t--1 {
        margin-top: -1px;
    }
}


/* ---------------------------------------- */


/* ############### 7.6 Misc ############### */


/* ---------------------------------------- */

.op-0 {
    opacity: 0;
}

.op-0-force {
    opacity: 0 !important;
}

.op-1 {
    opacity: 0.1;
}

.op-1-force {
    opacity: 0.1 !important;
}

.op-2 {
    opacity: 0.2;
}

.op-2-force {
    opacity: 0.2 !important;
}

.op-3 {
    opacity: 0.3;
}

.op-3-force {
    opacity: 0.3 !important;
}

.op-4 {
    opacity: 0.4;
}

.op-4-force {
    opacity: 0.4 !important;
}

.op-5 {
    opacity: 0.5;
}

.op-5-force {
    opacity: 0.5 !important;
}

.op-6 {
    opacity: 0.6;
}

.op-6-force {
    opacity: 0.6 !important;
}

.op-7 {
    opacity: 0.7;
}

.op-7-force {
    opacity: 0.7 !important;
}

.op-8 {
    opacity: 0.8;
}

.op-8-force {
    opacity: 0.8 !important;
}

.op-9 {
    opacity: 0.9;
}

.op-9-force {
    opacity: 0.9 !important;
}

.opacity-1 {
    opacity: 1;
}

.flex-1 {
    flex: 1;
}

.transition {
    transition: all 0.2s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
    .transition {
        transition: none;
    }
}

.overflow-auto {
    overflow: auto;
}

.overflow-y-auto {
    overflow-y: auto;
}

.overflow-x-auto {
    overflow-x: auto;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-y-hidden {
    overflow-y: hidden;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.overflow-visible {
    overflow: visible;
}

.overflow-y-visible {
    overflow-y: visible;
}

.overflow-x-visible {
    overflow-x: visible;
}

.overflow-scroll {
    overflow: scroll;
}

.overflow-y-scroll {
    overflow-y: scroll;
}

.overflow-x-scroll {
    overflow-x: scroll;
}

.object-position-top {
    object-position: top;
}

.object-fit-cover {
    object-fit: cover;
}

.no-object-fit .object-fit-cover {
    visibility: hidden;
}

.img-fit-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hr-light-100 {
    border-color: rgba(255, 255, 255, 0.05);
}

.ft-left {
    float: left;
}

.ft-right {
    float: right;
}

.ft-none {
    float: none;
}

.ft-none-force {
    float: none !important;
}

.shadow-base {
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.21);
}

.valign-middle {
    vertical-align: middle;
}

.tr-y-1 {
    transform: translateY(1px);
}

.space-nowrap {
    white-space: nowrap;
}

@media (min-width: 480px) {
    .op-xs-0 {
        opacity: 0;
    }

    .op-xs-0-force {
        opacity: 0 !important;
    }

    .op-xs-1 {
        opacity: 0.1;
    }

    .op-xs-1-force {
        opacity: 0.1 !important;
    }

    .op-xs-2 {
        opacity: 0.2;
    }

    .op-xs-2-force {
        opacity: 0.2 !important;
    }

    .op-xs-3 {
        opacity: 0.3;
    }

    .op-xs-3-force {
        opacity: 0.3 !important;
    }

    .op-xs-4 {
        opacity: 0.4;
    }

    .op-xs-4-force {
        opacity: 0.4 !important;
    }

    .op-xs-5 {
        opacity: 0.5;
    }

    .op-xs-5-force {
        opacity: 0.5 !important;
    }

    .op-xs-6 {
        opacity: 0.6;
    }

    .op-xs-6-force {
        opacity: 0.6 !important;
    }

    .op-xs-7 {
        opacity: 0.7;
    }

    .op-xs-7-force {
        opacity: 0.7 !important;
    }

    .op-xs-8 {
        opacity: 0.8;
    }

    .op-xs-8-force {
        opacity: 0.8 !important;
    }

    .op-xs-9 {
        opacity: 0.9;
    }

    .op-xs-9-force {
        opacity: 0.9 !important;
    }

    .d-xs-block {
        display: block;
    }

    .d-xs-inline {
        display: inline;
    }

    .d-xs-inline-block {
        display: inline-block;
    }

    .d-xs-none {
        display: none;
    }

    .d-xs-flex {
        display: flex;
    }

    .d-xs-table {
        display: table;
    }

    .d-xs-block-force {
        display: block !important;
    }

    .d-xs-inline-force {
        display: inline !important;
    }

    .d-xs-inline-block-force {
        display: inline-block !important;
    }

    .d-xs-none-force {
        display: none !important;
    }

    .d-xs-flex-force {
        display: flex !important;
    }

    .d-xs-table-force {
        display: table !important;
    }
}

@media (min-width: 576px) {
    .op-sm-0 {
        opacity: 0;
    }

    .op-sm-0-force {
        opacity: 0 !important;
    }

    .op-sm-1 {
        opacity: 0.1;
    }

    .op-sm-1-force {
        opacity: 0.1 !important;
    }

    .op-sm-2 {
        opacity: 0.2;
    }

    .op-sm-2-force {
        opacity: 0.2 !important;
    }

    .op-sm-3 {
        opacity: 0.3;
    }

    .op-sm-3-force {
        opacity: 0.3 !important;
    }

    .op-sm-4 {
        opacity: 0.4;
    }

    .op-sm-4-force {
        opacity: 0.4 !important;
    }

    .op-sm-5 {
        opacity: 0.5;
    }

    .op-sm-5-force {
        opacity: 0.5 !important;
    }

    .op-sm-6 {
        opacity: 0.6;
    }

    .op-sm-6-force {
        opacity: 0.6 !important;
    }

    .op-sm-7 {
        opacity: 0.7;
    }

    .op-sm-7-force {
        opacity: 0.7 !important;
    }

    .op-sm-8 {
        opacity: 0.8;
    }

    .op-sm-8-force {
        opacity: 0.8 !important;
    }

    .op-sm-9 {
        opacity: 0.9;
    }

    .op-sm-9-force {
        opacity: 0.9 !important;
    }

    .d-sm-block {
        display: block;
    }

    .d-sm-inline {
        display: inline;
    }

    .d-sm-inline-block {
        display: inline-block;
    }

    .d-sm-none {
        display: none;
    }

    .d-sm-flex {
        display: flex;
    }

    .d-sm-table {
        display: table;
    }

    .d-sm-block-force {
        display: block !important;
    }

    .d-sm-inline-force {
        display: inline !important;
    }

    .d-sm-inline-block-force {
        display: inline-block !important;
    }

    .d-sm-none-force {
        display: none !important;
    }

    .d-sm-flex-force {
        display: flex !important;
    }

    .d-sm-table-force {
        display: table !important;
    }
}

@media (min-width: 768px) {
    .op-md-0 {
        opacity: 0;
    }

    .op-md-0-force {
        opacity: 0 !important;
    }

    .op-md-1 {
        opacity: 0.1;
    }

    .op-md-1-force {
        opacity: 0.1 !important;
    }

    .op-md-2 {
        opacity: 0.2;
    }

    .op-md-2-force {
        opacity: 0.2 !important;
    }

    .op-md-3 {
        opacity: 0.3;
    }

    .op-md-3-force {
        opacity: 0.3 !important;
    }

    .op-md-4 {
        opacity: 0.4;
    }

    .op-md-4-force {
        opacity: 0.4 !important;
    }

    .op-md-5 {
        opacity: 0.5;
    }

    .op-md-5-force {
        opacity: 0.5 !important;
    }

    .op-md-6 {
        opacity: 0.6;
    }

    .op-md-6-force {
        opacity: 0.6 !important;
    }

    .op-md-7 {
        opacity: 0.7;
    }

    .op-md-7-force {
        opacity: 0.7 !important;
    }

    .op-md-8 {
        opacity: 0.8;
    }

    .op-md-8-force {
        opacity: 0.8 !important;
    }

    .op-md-9 {
        opacity: 0.9;
    }

    .op-md-9-force {
        opacity: 0.9 !important;
    }

    .d-md-block {
        display: block;
    }

    .d-md-inline {
        display: inline;
    }

    .d-md-inline-block {
        display: inline-block;
    }

    .d-md-none {
        display: none;
    }

    .d-md-flex {
        display: flex;
    }

    .d-md-table {
        display: table;
    }

    .d-md-block-force {
        display: block !important;
    }

    .d-md-inline-force {
        display: inline !important;
    }

    .d-md-inline-block-force {
        display: inline-block !important;
    }

    .d-md-none-force {
        display: none !important;
    }

    .d-md-flex-force {
        display: flex !important;
    }

    .d-md-table-force {
        display: table !important;
    }
}

@media (min-width: 992px) {
    .op-lg-0 {
        opacity: 0;
    }

    .op-lg-0-force {
        opacity: 0 !important;
    }

    .op-lg-1 {
        opacity: 0.1;
    }

    .op-lg-1-force {
        opacity: 0.1 !important;
    }

    .op-lg-2 {
        opacity: 0.2;
    }

    .op-lg-2-force {
        opacity: 0.2 !important;
    }

    .op-lg-3 {
        opacity: 0.3;
    }

    .op-lg-3-force {
        opacity: 0.3 !important;
    }

    .op-lg-4 {
        opacity: 0.4;
    }

    .op-lg-4-force {
        opacity: 0.4 !important;
    }

    .op-lg-5 {
        opacity: 0.5;
    }

    .op-lg-5-force {
        opacity: 0.5 !important;
    }

    .op-lg-6 {
        opacity: 0.6;
    }

    .op-lg-6-force {
        opacity: 0.6 !important;
    }

    .op-lg-7 {
        opacity: 0.7;
    }

    .op-lg-7-force {
        opacity: 0.7 !important;
    }

    .op-lg-8 {
        opacity: 0.8;
    }

    .op-lg-8-force {
        opacity: 0.8 !important;
    }

    .op-lg-9 {
        opacity: 0.9;
    }

    .op-lg-9-force {
        opacity: 0.9 !important;
    }

    .d-lg-block {
        display: block;
    }

    .d-lg-inline {
        display: inline;
    }

    .d-lg-inline-block {
        display: inline-block;
    }

    .d-lg-none {
        display: none;
    }

    .d-lg-flex {
        display: flex;
    }

    .d-lg-table {
        display: table;
    }

    .d-lg-block-force {
        display: block !important;
    }

    .d-lg-inline-force {
        display: inline !important;
    }

    .d-lg-inline-block-force {
        display: inline-block !important;
    }

    .d-lg-none-force {
        display: none !important;
    }

    .d-lg-flex-force {
        display: flex !important;
    }

    .d-lg-table-force {
        display: table !important;
    }
}

@media (min-width: 1200px) {
    .op-xl-0 {
        opacity: 0;
    }

    .op-xl-0-force {
        opacity: 0 !important;
    }

    .op-xl-1 {
        opacity: 0.1;
    }

    .op-xl-1-force {
        opacity: 0.1 !important;
    }

    .op-xl-2 {
        opacity: 0.2;
    }

    .op-xl-2-force {
        opacity: 0.2 !important;
    }

    .op-xl-3 {
        opacity: 0.3;
    }

    .op-xl-3-force {
        opacity: 0.3 !important;
    }

    .op-xl-4 {
        opacity: 0.4;
    }

    .op-xl-4-force {
        opacity: 0.4 !important;
    }

    .op-xl-5 {
        opacity: 0.5;
    }

    .op-xl-5-force {
        opacity: 0.5 !important;
    }

    .op-xl-6 {
        opacity: 0.6;
    }

    .op-xl-6-force {
        opacity: 0.6 !important;
    }

    .op-xl-7 {
        opacity: 0.7;
    }

    .op-xl-7-force {
        opacity: 0.7 !important;
    }

    .op-xl-8 {
        opacity: 0.8;
    }

    .op-xl-8-force {
        opacity: 0.8 !important;
    }

    .op-xl-9 {
        opacity: 0.9;
    }

    .op-xl-9-force {
        opacity: 0.9 !important;
    }

    .d-xl-block {
        display: block;
    }

    .d-xl-inline {
        display: inline;
    }

    .d-xl-inline-block {
        display: inline-block;
    }

    .d-xl-none {
        display: none;
    }

    .d-xl-flex {
        display: flex;
    }

    .d-xl-table {
        display: table;
    }

    .d-xl-block-force {
        display: block !important;
    }

    .d-xl-inline-force {
        display: inline !important;
    }

    .d-xl-inline-block-force {
        display: inline-block !important;
    }

    .d-xl-none-force {
        display: none !important;
    }

    .d-xl-flex-force {
        display: flex !important;
    }

    .d-xl-table-force {
        display: table !important;
    }
}


/* ------------------------------------------- */


/* ############### 7.7 Padding ############### */


/* ------------------------------------------- */

.pd-0 {
    padding: 0px;
}

.pd-0-force {
    padding: 0px !important;
}

.pd-y-0 {
    padding-top: 0px;
    padding-bottom: 0px;
}

.pd-y-0-force {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.pd-x-0 {
    padding-left: 0px;
    padding-right: 0px;
}

.pd-x-0-force {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.pd-t-0 {
    padding-top: 0px;
}

.pd-r-0 {
    padding-right: 0px;
}

.pd-b-0 {
    padding-bottom: 0px;
}

.pd-l-0 {
    padding-left: 0px;
}

.pd-t-0-force {
    padding-top: 0px !important;
}

.pd-r-0-force {
    padding-right: 0px !important;
}

.pd-b-0-force {
    padding-bottom: 0px !important;
}

.pd-l-0-force {
    padding-left: 0px !important;
}

.pd-1 {
    padding: 1px;
}

.pd-1-force {
    padding: 1px !important;
}

.pd-y-1 {
    padding-top: 1px;
    padding-bottom: 1px;
}

.pd-y-1-force {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
}

.pd-x-1 {
    padding-left: 1px;
    padding-right: 1px;
}

.pd-x-1-force {
    padding-left: 1px !important;
    padding-right: 1px !important;
}

.pd-t-1 {
    padding-top: 1px;
}

.pd-r-1 {
    padding-right: 1px;
}

.pd-b-1 {
    padding-bottom: 1px;
}

.pd-l-1 {
    padding-left: 1px;
}

.pd-t-1-force {
    padding-top: 1px !important;
}

.pd-r-1-force {
    padding-right: 1px !important;
}

.pd-b-1-force {
    padding-bottom: 1px !important;
}

.pd-l-1-force {
    padding-left: 1px !important;
}

.pd-2 {
    padding: 2px;
}

.pd-2-force {
    padding: 2px !important;
}

.pd-y-2 {
    padding-top: 2px;
    padding-bottom: 2px;
}

.pd-y-2-force {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}

.pd-x-2 {
    padding-left: 2px;
    padding-right: 2px;
}

.pd-x-2-force {
    padding-left: 2px !important;
    padding-right: 2px !important;
}

.pd-t-2 {
    padding-top: 2px;
}

.pd-r-2 {
    padding-right: 2px;
}

.pd-b-2 {
    padding-bottom: 2px;
}

.pd-l-2 {
    padding-left: 2px;
}

.pd-t-2-force {
    padding-top: 2px !important;
}

.pd-r-2-force {
    padding-right: 2px !important;
}

.pd-b-2-force {
    padding-bottom: 2px !important;
}

.pd-l-2-force {
    padding-left: 2px !important;
}

.pd-3 {
    padding: 3px;
}

.pd-3-force {
    padding: 3px !important;
}

.pd-y-3 {
    padding-top: 3px;
    padding-bottom: 3px;
}

.pd-y-3-force {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
}

.pd-x-3 {
    padding-left: 3px;
    padding-right: 3px;
}

.pd-x-3-force {
    padding-left: 3px !important;
    padding-right: 3px !important;
}

.pd-t-3 {
    padding-top: 3px;
}

.pd-r-3 {
    padding-right: 3px;
}

.pd-b-3 {
    padding-bottom: 3px;
}

.pd-l-3 {
    padding-left: 3px;
}

.pd-t-3-force {
    padding-top: 3px !important;
}

.pd-r-3-force {
    padding-right: 3px !important;
}

.pd-b-3-force {
    padding-bottom: 3px !important;
}

.pd-l-3-force {
    padding-left: 3px !important;
}

.pd-4 {
    padding: 4px;
}

.pd-4-force {
    padding: 4px !important;
}

.pd-y-4 {
    padding-top: 4px;
    padding-bottom: 4px;
}

.pd-y-4-force {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}

.pd-x-4 {
    padding-left: 4px;
    padding-right: 4px;
}

.pd-x-4-force {
    padding-left: 4px !important;
    padding-right: 4px !important;
}

.pd-t-4 {
    padding-top: 4px;
}

.pd-r-4 {
    padding-right: 4px;
}

.pd-b-4 {
    padding-bottom: 4px;
}

.pd-l-4 {
    padding-left: 4px;
}

.pd-t-4-force {
    padding-top: 4px !important;
}

.pd-r-4-force {
    padding-right: 4px !important;
}

.pd-b-4-force {
    padding-bottom: 4px !important;
}

.pd-l-4-force {
    padding-left: 4px !important;
}

.pd-5 {
    padding: 5px;
}

.pd-5-force {
    padding: 5px !important;
}

.pd-y-5 {
    padding-top: 5px;
    padding-bottom: 5px;
}

.pd-y-5-force {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.pd-x-5 {
    padding-left: 5px;
    padding-right: 5px;
}

.pd-x-5-force {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.pd-t-5 {
    padding-top: 5px;
}

.pd-r-5 {
    padding-right: 5px;
}

.pd-b-5 {
    padding-bottom: 5px;
}

.pd-l-5 {
    padding-left: 5px;
}

.pd-t-5-force {
    padding-top: 5px !important;
}

.pd-r-5-force {
    padding-right: 5px !important;
}

.pd-b-5-force {
    padding-bottom: 5px !important;
}

.pd-l-5-force {
    padding-left: 5px !important;
}

.pd-6 {
    padding: 6px;
}

.pd-6-force {
    padding: 6px !important;
}

.pd-y-6 {
    padding-top: 6px;
    padding-bottom: 6px;
}

.pd-y-6-force {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}

.pd-x-6 {
    padding-left: 6px;
    padding-right: 6px;
}

.pd-x-6-force {
    padding-left: 6px !important;
    padding-right: 6px !important;
}

.pd-t-6 {
    padding-top: 6px;
}

.pd-r-6 {
    padding-right: 6px;
}

.pd-b-6 {
    padding-bottom: 6px;
}

.pd-l-6 {
    padding-left: 6px;
}

.pd-t-6-force {
    padding-top: 6px !important;
}

.pd-r-6-force {
    padding-right: 6px !important;
}

.pd-b-6-force {
    padding-bottom: 6px !important;
}

.pd-l-6-force {
    padding-left: 6px !important;
}

.pd-7 {
    padding: 7px;
}

.pd-7-force {
    padding: 7px !important;
}

.pd-y-7 {
    padding-top: 7px;
    padding-bottom: 7px;
}

.pd-y-7-force {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
}

.pd-x-7 {
    padding-left: 7px;
    padding-right: 7px;
}

.pd-x-7-force {
    padding-left: 7px !important;
    padding-right: 7px !important;
}

.pd-t-7 {
    padding-top: 7px;
}

.pd-r-7 {
    padding-right: 7px;
}

.pd-b-7 {
    padding-bottom: 7px;
}

.pd-l-7 {
    padding-left: 7px;
}

.pd-t-7-force {
    padding-top: 7px !important;
}

.pd-r-7-force {
    padding-right: 7px !important;
}

.pd-b-7-force {
    padding-bottom: 7px !important;
}

.pd-l-7-force {
    padding-left: 7px !important;
}

.pd-8 {
    padding: 8px;
}

.pd-8-force {
    padding: 8px !important;
}

.pd-y-8 {
    padding-top: 8px;
    padding-bottom: 8px;
}

.pd-y-8-force {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.pd-x-8 {
    padding-left: 8px;
    padding-right: 8px;
}

.pd-x-8-force {
    padding-left: 8px !important;
    padding-right: 8px !important;
}

.pd-t-8 {
    padding-top: 8px;
}

.pd-r-8 {
    padding-right: 8px;
}

.pd-b-8 {
    padding-bottom: 8px;
}

.pd-l-8 {
    padding-left: 8px;
}

.pd-t-8-force {
    padding-top: 8px !important;
}

.pd-r-8-force {
    padding-right: 8px !important;
}

.pd-b-8-force {
    padding-bottom: 8px !important;
}

.pd-l-8-force {
    padding-left: 8px !important;
}

.pd-9 {
    padding: 9px;
}

.pd-9-force {
    padding: 9px !important;
}

.pd-y-9 {
    padding-top: 9px;
    padding-bottom: 9px;
}

.pd-y-9-force {
    padding-top: 9px !important;
    padding-bottom: 9px !important;
}

.pd-x-9 {
    padding-left: 9px;
    padding-right: 9px;
}

.pd-x-9-force {
    padding-left: 9px !important;
    padding-right: 9px !important;
}

.pd-t-9 {
    padding-top: 9px;
}

.pd-r-9 {
    padding-right: 9px;
}

.pd-b-9 {
    padding-bottom: 9px;
}

.pd-l-9 {
    padding-left: 9px;
}

.pd-t-9-force {
    padding-top: 9px !important;
}

.pd-r-9-force {
    padding-right: 9px !important;
}

.pd-b-9-force {
    padding-bottom: 9px !important;
}

.pd-l-9-force {
    padding-left: 9px !important;
}

.pd-10 {
    padding: 10px;
}

.pd-10-force {
    padding: 10px !important;
}

.pd-y-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.pd-y-10-force {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.pd-x-10 {
    padding-left: 10px;
    padding-right: 10px;
}

.pd-x-10-force {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.pd-t-10 {
    padding-top: 10px;
}

.pd-r-10 {
    padding-right: 10px;
}

.pd-b-10 {
    padding-bottom: 10px;
}

.pd-l-10 {
    padding-left: 10px;
}

.pd-t-10-force {
    padding-top: 10px !important;
}

.pd-r-10-force {
    padding-right: 10px !important;
}

.pd-b-10-force {
    padding-bottom: 10px !important;
}

.pd-l-10-force {
    padding-left: 10px !important;
}

.pd-15 {
    padding: 15px;
}

.pd-15-force {
    padding: 15px !important;
}

.pd-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.pd-y-15-force {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

.pd-x-15 {
    padding-left: 15px;
    padding-right: 15px;
}

.pd-x-15-force {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.pd-t-15 {
    padding-top: 15px;
}

.pd-r-15 {
    padding-right: 15px;
}

.pd-b-15 {
    padding-bottom: 15px;
}

.pd-l-15 {
    padding-left: 15px;
}

.pd-t-15-force {
    padding-top: 15px !important;
}

.pd-r-15-force {
    padding-right: 15px !important;
}

.pd-b-15-force {
    padding-bottom: 15px !important;
}

.pd-l-15-force {
    padding-left: 15px !important;
}

.pd-20 {
    padding: 20px;
}

.pd-20-force {
    padding: 20px !important;
}

.pd-y-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.pd-y-20-force {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}

.pd-x-20 {
    padding-left: 20px;
    padding-right: 20px;
}

.pd-x-20-force {
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.pd-t-20 {
    padding-top: 20px;
}

.pd-r-20 {
    padding-right: 20px;
}

.pd-b-20 {
    padding-bottom: 20px;
}

.pd-l-20 {
    padding-left: 20px;
}

.pd-t-20-force {
    padding-top: 20px !important;
}

.pd-r-20-force {
    padding-right: 20px !important;
}

.pd-b-20-force {
    padding-bottom: 20px !important;
}

.pd-l-20-force {
    padding-left: 20px !important;
}

.pd-25 {
    padding: 25px;
}

.pd-25-force {
    padding: 25px !important;
}

.pd-y-25 {
    padding-top: 25px;
    padding-bottom: 25px;
}

.pd-y-25-force {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
}

.pd-x-25 {
    padding-left: 25px;
    padding-right: 25px;
}

.pd-x-25-force {
    padding-left: 25px !important;
    padding-right: 25px !important;
}

.pd-t-25 {
    padding-top: 25px;
}

.pd-r-25 {
    padding-right: 25px;
}

.pd-b-25 {
    padding-bottom: 25px;
}

.pd-l-25 {
    padding-left: 25px;
}

.pd-t-25-force {
    padding-top: 25px !important;
}

.pd-r-25-force {
    padding-right: 25px !important;
}

.pd-b-25-force {
    padding-bottom: 25px !important;
}

.pd-l-25-force {
    padding-left: 25px !important;
}

.pd-30 {
    padding: 30px;
}

.pd-30-force {
    padding: 30px !important;
}

.pd-y-30 {
    padding-top: 30px;
    padding-bottom: 30px;
}

.pd-y-30-force {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
}

.pd-x-30 {
    padding-left: 30px;
    padding-right: 30px;
}

.pd-x-30-force {
    padding-left: 30px !important;
    padding-right: 30px !important;
}

.pd-t-30 {
    padding-top: 30px;
}

.pd-r-30 {
    padding-right: 30px;
}

.pd-b-30 {
    padding-bottom: 30px;
}

.pd-l-30 {
    padding-left: 30px;
}

.pd-t-30-force {
    padding-top: 30px !important;
}

.pd-r-30-force {
    padding-right: 30px !important;
}

.pd-b-30-force {
    padding-bottom: 30px !important;
}

.pd-l-30-force {
    padding-left: 30px !important;
}

.pd-35 {
    padding: 35px;
}

.pd-35-force {
    padding: 35px !important;
}

.pd-y-35 {
    padding-top: 35px;
    padding-bottom: 35px;
}

.pd-y-35-force {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
}

.pd-x-35 {
    padding-left: 35px;
    padding-right: 35px;
}

.pd-x-35-force {
    padding-left: 35px !important;
    padding-right: 35px !important;
}

.pd-t-35 {
    padding-top: 35px;
}

.pd-r-35 {
    padding-right: 35px;
}

.pd-b-35 {
    padding-bottom: 35px;
}

.pd-l-35 {
    padding-left: 35px;
}

.pd-t-35-force {
    padding-top: 35px !important;
}

.pd-r-35-force {
    padding-right: 35px !important;
}

.pd-b-35-force {
    padding-bottom: 35px !important;
}

.pd-l-35-force {
    padding-left: 35px !important;
}

.pd-40 {
    padding: 40px;
}

.pd-40-force {
    padding: 40px !important;
}

.pd-y-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.pd-y-40-force {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
}

.pd-x-40 {
    padding-left: 40px;
    padding-right: 40px;
}

.pd-x-40-force {
    padding-left: 40px !important;
    padding-right: 40px !important;
}

.pd-t-40 {
    padding-top: 40px;
}

.pd-r-40 {
    padding-right: 40px;
}

.pd-b-40 {
    padding-bottom: 40px;
}

.pd-l-40 {
    padding-left: 40px;
}

.pd-t-40-force {
    padding-top: 40px !important;
}

.pd-r-40-force {
    padding-right: 40px !important;
}

.pd-b-40-force {
    padding-bottom: 40px !important;
}

.pd-l-40-force {
    padding-left: 40px !important;
}

.pd-45 {
    padding: 45px;
}

.pd-45-force {
    padding: 45px !important;
}

.pd-y-45 {
    padding-top: 45px;
    padding-bottom: 45px;
}

.pd-y-45-force {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
}

.pd-x-45 {
    padding-left: 45px;
    padding-right: 45px;
}

.pd-x-45-force {
    padding-left: 45px !important;
    padding-right: 45px !important;
}

.pd-t-45 {
    padding-top: 45px;
}

.pd-r-45 {
    padding-right: 45px;
}

.pd-b-45 {
    padding-bottom: 45px;
}

.pd-l-45 {
    padding-left: 45px;
}

.pd-t-45-force {
    padding-top: 45px !important;
}

.pd-r-45-force {
    padding-right: 45px !important;
}

.pd-b-45-force {
    padding-bottom: 45px !important;
}

.pd-l-45-force {
    padding-left: 45px !important;
}

.pd-50 {
    padding: 50px;
}

.pd-50-force {
    padding: 50px !important;
}

.pd-y-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.pd-y-50-force {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
}

.pd-x-50 {
    padding-left: 50px;
    padding-right: 50px;
}

.pd-x-50-force {
    padding-left: 50px !important;
    padding-right: 50px !important;
}

.pd-t-50 {
    padding-top: 50px;
}

.pd-r-50 {
    padding-right: 50px;
}

.pd-b-50 {
    padding-bottom: 50px;
}

.pd-l-50 {
    padding-left: 50px;
}

.pd-t-50-force {
    padding-top: 50px !important;
}

.pd-r-50-force {
    padding-right: 50px !important;
}

.pd-b-50-force {
    padding-bottom: 50px !important;
}

.pd-l-50-force {
    padding-left: 50px !important;
}

.pd-55 {
    padding: 55px;
}

.pd-55-force {
    padding: 55px !important;
}

.pd-y-55 {
    padding-top: 55px;
    padding-bottom: 55px;
}

.pd-y-55-force {
    padding-top: 55px !important;
    padding-bottom: 55px !important;
}

.pd-x-55 {
    padding-left: 55px;
    padding-right: 55px;
}

.pd-x-55-force {
    padding-left: 55px !important;
    padding-right: 55px !important;
}

.pd-t-55 {
    padding-top: 55px;
}

.pd-r-55 {
    padding-right: 55px;
}

.pd-b-55 {
    padding-bottom: 55px;
}

.pd-l-55 {
    padding-left: 55px;
}

.pd-t-55-force {
    padding-top: 55px !important;
}

.pd-r-55-force {
    padding-right: 55px !important;
}

.pd-b-55-force {
    padding-bottom: 55px !important;
}

.pd-l-55-force {
    padding-left: 55px !important;
}

.pd-60 {
    padding: 60px;
}

.pd-60-force {
    padding: 60px !important;
}

.pd-y-60 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.pd-y-60-force {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
}

.pd-x-60 {
    padding-left: 60px;
    padding-right: 60px;
}

.pd-x-60-force {
    padding-left: 60px !important;
    padding-right: 60px !important;
}

.pd-t-60 {
    padding-top: 60px;
}

.pd-r-60 {
    padding-right: 60px;
}

.pd-b-60 {
    padding-bottom: 60px;
}

.pd-l-60 {
    padding-left: 60px;
}

.pd-t-60-force {
    padding-top: 60px !important;
}

.pd-r-60-force {
    padding-right: 60px !important;
}

.pd-b-60-force {
    padding-bottom: 60px !important;
}

.pd-l-60-force {
    padding-left: 60px !important;
}

.pd-65 {
    padding: 65px;
}

.pd-65-force {
    padding: 65px !important;
}

.pd-y-65 {
    padding-top: 65px;
    padding-bottom: 65px;
}

.pd-y-65-force {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
}

.pd-x-65 {
    padding-left: 65px;
    padding-right: 65px;
}

.pd-x-65-force {
    padding-left: 65px !important;
    padding-right: 65px !important;
}

.pd-t-65 {
    padding-top: 65px;
}

.pd-r-65 {
    padding-right: 65px;
}

.pd-b-65 {
    padding-bottom: 65px;
}

.pd-l-65 {
    padding-left: 65px;
}

.pd-t-65-force {
    padding-top: 65px !important;
}

.pd-r-65-force {
    padding-right: 65px !important;
}

.pd-b-65-force {
    padding-bottom: 65px !important;
}

.pd-l-65-force {
    padding-left: 65px !important;
}

.pd-70 {
    padding: 70px;
}

.pd-70-force {
    padding: 70px !important;
}

.pd-y-70 {
    padding-top: 70px;
    padding-bottom: 70px;
}

.pd-y-70-force {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
}

.pd-x-70 {
    padding-left: 70px;
    padding-right: 70px;
}

.pd-x-70-force {
    padding-left: 70px !important;
    padding-right: 70px !important;
}

.pd-t-70 {
    padding-top: 70px;
}

.pd-r-70 {
    padding-right: 70px;
}

.pd-b-70 {
    padding-bottom: 70px;
}

.pd-l-70 {
    padding-left: 70px;
}

.pd-t-70-force {
    padding-top: 70px !important;
}

.pd-r-70-force {
    padding-right: 70px !important;
}

.pd-b-70-force {
    padding-bottom: 70px !important;
}

.pd-l-70-force {
    padding-left: 70px !important;
}

.pd-75 {
    padding: 75px;
}

.pd-75-force {
    padding: 75px !important;
}

.pd-y-75 {
    padding-top: 75px;
    padding-bottom: 75px;
}

.pd-y-75-force {
    padding-top: 75px !important;
    padding-bottom: 75px !important;
}

.pd-x-75 {
    padding-left: 75px;
    padding-right: 75px;
}

.pd-x-75-force {
    padding-left: 75px !important;
    padding-right: 75px !important;
}

.pd-t-75 {
    padding-top: 75px;
}

.pd-r-75 {
    padding-right: 75px;
}

.pd-b-75 {
    padding-bottom: 75px;
}

.pd-l-75 {
    padding-left: 75px;
}

.pd-t-75-force {
    padding-top: 75px !important;
}

.pd-r-75-force {
    padding-right: 75px !important;
}

.pd-b-75-force {
    padding-bottom: 75px !important;
}

.pd-l-75-force {
    padding-left: 75px !important;
}

.pd-80 {
    padding: 80px;
}

.pd-80-force {
    padding: 80px !important;
}

.pd-y-80 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.pd-y-80-force {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
}

.pd-x-80 {
    padding-left: 80px;
    padding-right: 80px;
}

.pd-x-80-force {
    padding-left: 80px !important;
    padding-right: 80px !important;
}

.pd-t-80 {
    padding-top: 80px;
}

.pd-r-80 {
    padding-right: 80px;
}

.pd-b-80 {
    padding-bottom: 80px;
}

.pd-l-80 {
    padding-left: 80px;
}

.pd-t-80-force {
    padding-top: 80px !important;
}

.pd-r-80-force {
    padding-right: 80px !important;
}

.pd-b-80-force {
    padding-bottom: 80px !important;
}

.pd-l-80-force {
    padding-left: 80px !important;
}

.pd-85 {
    padding: 85px;
}

.pd-85-force {
    padding: 85px !important;
}

.pd-y-85 {
    padding-top: 85px;
    padding-bottom: 85px;
}

.pd-y-85-force {
    padding-top: 85px !important;
    padding-bottom: 85px !important;
}

.pd-x-85 {
    padding-left: 85px;
    padding-right: 85px;
}

.pd-x-85-force {
    padding-left: 85px !important;
    padding-right: 85px !important;
}

.pd-t-85 {
    padding-top: 85px;
}

.pd-r-85 {
    padding-right: 85px;
}

.pd-b-85 {
    padding-bottom: 85px;
}

.pd-l-85 {
    padding-left: 85px;
}

.pd-t-85-force {
    padding-top: 85px !important;
}

.pd-r-85-force {
    padding-right: 85px !important;
}

.pd-b-85-force {
    padding-bottom: 85px !important;
}

.pd-l-85-force {
    padding-left: 85px !important;
}

.pd-90 {
    padding: 90px;
}

.pd-90-force {
    padding: 90px !important;
}

.pd-y-90 {
    padding-top: 90px;
    padding-bottom: 90px;
}

.pd-y-90-force {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
}

.pd-x-90 {
    padding-left: 90px;
    padding-right: 90px;
}

.pd-x-90-force {
    padding-left: 90px !important;
    padding-right: 90px !important;
}

.pd-t-90 {
    padding-top: 90px;
}

.pd-r-90 {
    padding-right: 90px;
}

.pd-b-90 {
    padding-bottom: 90px;
}

.pd-l-90 {
    padding-left: 90px;
}

.pd-t-90-force {
    padding-top: 90px !important;
}

.pd-r-90-force {
    padding-right: 90px !important;
}

.pd-b-90-force {
    padding-bottom: 90px !important;
}

.pd-l-90-force {
    padding-left: 90px !important;
}

.pd-95 {
    padding: 95px;
}

.pd-95-force {
    padding: 95px !important;
}

.pd-y-95 {
    padding-top: 95px;
    padding-bottom: 95px;
}

.pd-y-95-force {
    padding-top: 95px !important;
    padding-bottom: 95px !important;
}

.pd-x-95 {
    padding-left: 95px;
    padding-right: 95px;
}

.pd-x-95-force {
    padding-left: 95px !important;
    padding-right: 95px !important;
}

.pd-t-95 {
    padding-top: 95px;
}

.pd-r-95 {
    padding-right: 95px;
}

.pd-b-95 {
    padding-bottom: 95px;
}

.pd-l-95 {
    padding-left: 95px;
}

.pd-t-95-force {
    padding-top: 95px !important;
}

.pd-r-95-force {
    padding-right: 95px !important;
}

.pd-b-95-force {
    padding-bottom: 95px !important;
}

.pd-l-95-force {
    padding-left: 95px !important;
}

.pd-100 {
    padding: 100px;
}

.pd-100-force {
    padding: 100px !important;
}

.pd-y-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.pd-y-100-force {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
}

.pd-x-100 {
    padding-left: 100px;
    padding-right: 100px;
}

.pd-x-100-force {
    padding-left: 100px !important;
    padding-right: 100px !important;
}

.pd-t-100 {
    padding-top: 100px;
}

.pd-r-100 {
    padding-right: 100px;
}

.pd-b-100 {
    padding-bottom: 100px;
}

.pd-l-100 {
    padding-left: 100px;
}

.pd-t-100-force {
    padding-top: 100px !important;
}

.pd-r-100-force {
    padding-right: 100px !important;
}

.pd-b-100-force {
    padding-bottom: 100px !important;
}

.pd-l-100-force {
    padding-left: 100px !important;
}

.pd-110 {
    padding: 110px;
}

.pd-110-force {
    padding: 110px !important;
}

.pd-y-110 {
    padding-top: 110px;
    padding-bottom: 110px;
}

.pd-y-110-force {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
}

.pd-x-110 {
    padding-left: 110px;
    padding-right: 110px;
}

.pd-x-110-force {
    padding-left: 110px !important;
    padding-right: 110px !important;
}

.pd-t-110 {
    padding-top: 110px;
}

.pd-r-110 {
    padding-right: 110px;
}

.pd-b-110 {
    padding-bottom: 110px;
}

.pd-l-110 {
    padding-left: 110px;
}

.pd-t-110-force {
    padding-top: 110px !important;
}

.pd-r-110-force {
    padding-right: 110px !important;
}

.pd-b-110-force {
    padding-bottom: 110px !important;
}

.pd-l-110-force {
    padding-left: 110px !important;
}

.pd-120 {
    padding: 120px;
}

.pd-120-force {
    padding: 120px !important;
}

.pd-y-120 {
    padding-top: 120px;
    padding-bottom: 120px;
}

.pd-y-120-force {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
}

.pd-x-120 {
    padding-left: 120px;
    padding-right: 120px;
}

.pd-x-120-force {
    padding-left: 120px !important;
    padding-right: 120px !important;
}

.pd-t-120 {
    padding-top: 120px;
}

.pd-r-120 {
    padding-right: 120px;
}

.pd-b-120 {
    padding-bottom: 120px;
}

.pd-l-120 {
    padding-left: 120px;
}

.pd-t-120-force {
    padding-top: 120px !important;
}

.pd-r-120-force {
    padding-right: 120px !important;
}

.pd-b-120-force {
    padding-bottom: 120px !important;
}

.pd-l-120-force {
    padding-left: 120px !important;
}

.pd-130 {
    padding: 130px;
}

.pd-130-force {
    padding: 130px !important;
}

.pd-y-130 {
    padding-top: 130px;
    padding-bottom: 130px;
}

.pd-y-130-force {
    padding-top: 130px !important;
    padding-bottom: 130px !important;
}

.pd-x-130 {
    padding-left: 130px;
    padding-right: 130px;
}

.pd-x-130-force {
    padding-left: 130px !important;
    padding-right: 130px !important;
}

.pd-t-130 {
    padding-top: 130px;
}

.pd-r-130 {
    padding-right: 130px;
}

.pd-b-130 {
    padding-bottom: 130px;
}

.pd-l-130 {
    padding-left: 130px;
}

.pd-t-130-force {
    padding-top: 130px !important;
}

.pd-r-130-force {
    padding-right: 130px !important;
}

.pd-b-130-force {
    padding-bottom: 130px !important;
}

.pd-l-130-force {
    padding-left: 130px !important;
}

.pd-140 {
    padding: 140px;
}

.pd-140-force {
    padding: 140px !important;
}

.pd-y-140 {
    padding-top: 140px;
    padding-bottom: 140px;
}

.pd-y-140-force {
    padding-top: 140px !important;
    padding-bottom: 140px !important;
}

.pd-x-140 {
    padding-left: 140px;
    padding-right: 140px;
}

.pd-x-140-force {
    padding-left: 140px !important;
    padding-right: 140px !important;
}

.pd-t-140 {
    padding-top: 140px;
}

.pd-r-140 {
    padding-right: 140px;
}

.pd-b-140 {
    padding-bottom: 140px;
}

.pd-l-140 {
    padding-left: 140px;
}

.pd-t-140-force {
    padding-top: 140px !important;
}

.pd-r-140-force {
    padding-right: 140px !important;
}

.pd-b-140-force {
    padding-bottom: 140px !important;
}

.pd-l-140-force {
    padding-left: 140px !important;
}

.pd-150 {
    padding: 150px;
}

.pd-150-force {
    padding: 150px !important;
}

.pd-y-150 {
    padding-top: 150px;
    padding-bottom: 150px;
}

.pd-y-150-force {
    padding-top: 150px !important;
    padding-bottom: 150px !important;
}

.pd-x-150 {
    padding-left: 150px;
    padding-right: 150px;
}

.pd-x-150-force {
    padding-left: 150px !important;
    padding-right: 150px !important;
}

.pd-t-150 {
    padding-top: 150px;
}

.pd-r-150 {
    padding-right: 150px;
}

.pd-b-150 {
    padding-bottom: 150px;
}

.pd-l-150 {
    padding-left: 150px;
}

.pd-t-150-force {
    padding-top: 150px !important;
}

.pd-r-150-force {
    padding-right: 150px !important;
}

.pd-b-150-force {
    padding-bottom: 150px !important;
}

.pd-l-150-force {
    padding-left: 150px !important;
}

.pd-160 {
    padding: 160px;
}

.pd-160-force {
    padding: 160px !important;
}

.pd-y-160 {
    padding-top: 160px;
    padding-bottom: 160px;
}

.pd-y-160-force {
    padding-top: 160px !important;
    padding-bottom: 160px !important;
}

.pd-x-160 {
    padding-left: 160px;
    padding-right: 160px;
}

.pd-x-160-force {
    padding-left: 160px !important;
    padding-right: 160px !important;
}

.pd-t-160 {
    padding-top: 160px;
}

.pd-r-160 {
    padding-right: 160px;
}

.pd-b-160 {
    padding-bottom: 160px;
}

.pd-l-160 {
    padding-left: 160px;
}

.pd-t-160-force {
    padding-top: 160px !important;
}

.pd-r-160-force {
    padding-right: 160px !important;
}

.pd-b-160-force {
    padding-bottom: 160px !important;
}

.pd-l-160-force {
    padding-left: 160px !important;
}

.pd-170 {
    padding: 170px;
}

.pd-170-force {
    padding: 170px !important;
}

.pd-y-170 {
    padding-top: 170px;
    padding-bottom: 170px;
}

.pd-y-170-force {
    padding-top: 170px !important;
    padding-bottom: 170px !important;
}

.pd-x-170 {
    padding-left: 170px;
    padding-right: 170px;
}

.pd-x-170-force {
    padding-left: 170px !important;
    padding-right: 170px !important;
}

.pd-t-170 {
    padding-top: 170px;
}

.pd-r-170 {
    padding-right: 170px;
}

.pd-b-170 {
    padding-bottom: 170px;
}

.pd-l-170 {
    padding-left: 170px;
}

.pd-t-170-force {
    padding-top: 170px !important;
}

.pd-r-170-force {
    padding-right: 170px !important;
}

.pd-b-170-force {
    padding-bottom: 170px !important;
}

.pd-l-170-force {
    padding-left: 170px !important;
}

.pd-180 {
    padding: 180px;
}

.pd-180-force {
    padding: 180px !important;
}

.pd-y-180 {
    padding-top: 180px;
    padding-bottom: 180px;
}

.pd-y-180-force {
    padding-top: 180px !important;
    padding-bottom: 180px !important;
}

.pd-x-180 {
    padding-left: 180px;
    padding-right: 180px;
}

.pd-x-180-force {
    padding-left: 180px !important;
    padding-right: 180px !important;
}

.pd-t-180 {
    padding-top: 180px;
}

.pd-r-180 {
    padding-right: 180px;
}

.pd-b-180 {
    padding-bottom: 180px;
}

.pd-l-180 {
    padding-left: 180px;
}

.pd-t-180-force {
    padding-top: 180px !important;
}

.pd-r-180-force {
    padding-right: 180px !important;
}

.pd-b-180-force {
    padding-bottom: 180px !important;
}

.pd-l-180-force {
    padding-left: 180px !important;
}

.pd-190 {
    padding: 190px;
}

.pd-190-force {
    padding: 190px !important;
}

.pd-y-190 {
    padding-top: 190px;
    padding-bottom: 190px;
}

.pd-y-190-force {
    padding-top: 190px !important;
    padding-bottom: 190px !important;
}

.pd-x-190 {
    padding-left: 190px;
    padding-right: 190px;
}

.pd-x-190-force {
    padding-left: 190px !important;
    padding-right: 190px !important;
}

.pd-t-190 {
    padding-top: 190px;
}

.pd-r-190 {
    padding-right: 190px;
}

.pd-b-190 {
    padding-bottom: 190px;
}

.pd-l-190 {
    padding-left: 190px;
}

.pd-t-190-force {
    padding-top: 190px !important;
}

.pd-r-190-force {
    padding-right: 190px !important;
}

.pd-b-190-force {
    padding-bottom: 190px !important;
}

.pd-l-190-force {
    padding-left: 190px !important;
}

.pd-200 {
    padding: 200px;
}

.pd-200-force {
    padding: 200px !important;
}

.pd-y-200 {
    padding-top: 200px;
    padding-bottom: 200px;
}

.pd-y-200-force {
    padding-top: 200px !important;
    padding-bottom: 200px !important;
}

.pd-x-200 {
    padding-left: 200px;
    padding-right: 200px;
}

.pd-x-200-force {
    padding-left: 200px !important;
    padding-right: 200px !important;
}

.pd-t-200 {
    padding-top: 200px;
}

.pd-r-200 {
    padding-right: 200px;
}

.pd-b-200 {
    padding-bottom: 200px;
}

.pd-l-200 {
    padding-left: 200px;
}

.pd-t-200-force {
    padding-top: 200px !important;
}

.pd-r-200-force {
    padding-right: 200px !important;
}

.pd-b-200-force {
    padding-bottom: 200px !important;
}

.pd-l-200-force {
    padding-left: 200px !important;
}

@media (min-width: 480px) {
    .pd-xs-0 {
        padding: 0px;
    }

    .pd-xs-0-force {
        padding: 0px !important;
    }

    .pd-xs-y-0 {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .pd-xs-y-0-force {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .pd-xs-x-0 {
        padding-left: 0px;
        padding-right: 0px;
    }

    .pd-xs-x-0-force {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .pd-xs-t-0 {
        padding-top: 0px;
    }

    .pd-xs-r-0 {
        padding-right: 0px;
    }

    .pd-xs-b-0 {
        padding-bottom: 0px;
    }

    .pd-xs-l-0 {
        padding-left: 0px;
    }

    .pd-xs-t-0-force {
        padding-top: 0px !important;
    }

    .pd-xs-r-0-force {
        padding-right: 0px !important;
    }

    .pd-xs-b-0-force {
        padding-bottom: 0px !important;
    }

    .pd-xs-l-0-force {
        padding-left: 0px !important;
    }

    .pd-xs-1 {
        padding: 1px;
    }

    .pd-xs-1-force {
        padding: 1px !important;
    }

    .pd-xs-y-1 {
        padding-top: 1px;
        padding-bottom: 1px;
    }

    .pd-xs-y-1-force {
        padding-top: 1px !important;
        padding-bottom: 1px !important;
    }

    .pd-xs-x-1 {
        padding-left: 1px;
        padding-right: 1px;
    }

    .pd-xs-x-1-force {
        padding-left: 1px !important;
        padding-right: 1px !important;
    }

    .pd-xs-t-1 {
        padding-top: 1px;
    }

    .pd-xs-r-1 {
        padding-right: 1px;
    }

    .pd-xs-b-1 {
        padding-bottom: 1px;
    }

    .pd-xs-l-1 {
        padding-left: 1px;
    }

    .pd-xs-t-1-force {
        padding-top: 1px !important;
    }

    .pd-xs-r-1-force {
        padding-right: 1px !important;
    }

    .pd-xs-b-1-force {
        padding-bottom: 1px !important;
    }

    .pd-xs-l-1-force {
        padding-left: 1px !important;
    }

    .pd-xs-2 {
        padding: 2px;
    }

    .pd-xs-2-force {
        padding: 2px !important;
    }

    .pd-xs-y-2 {
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .pd-xs-y-2-force {
        padding-top: 2px !important;
        padding-bottom: 2px !important;
    }

    .pd-xs-x-2 {
        padding-left: 2px;
        padding-right: 2px;
    }

    .pd-xs-x-2-force {
        padding-left: 2px !important;
        padding-right: 2px !important;
    }

    .pd-xs-t-2 {
        padding-top: 2px;
    }

    .pd-xs-r-2 {
        padding-right: 2px;
    }

    .pd-xs-b-2 {
        padding-bottom: 2px;
    }

    .pd-xs-l-2 {
        padding-left: 2px;
    }

    .pd-xs-t-2-force {
        padding-top: 2px !important;
    }

    .pd-xs-r-2-force {
        padding-right: 2px !important;
    }

    .pd-xs-b-2-force {
        padding-bottom: 2px !important;
    }

    .pd-xs-l-2-force {
        padding-left: 2px !important;
    }

    .pd-xs-3 {
        padding: 3px;
    }

    .pd-xs-3-force {
        padding: 3px !important;
    }

    .pd-xs-y-3 {
        padding-top: 3px;
        padding-bottom: 3px;
    }

    .pd-xs-y-3-force {
        padding-top: 3px !important;
        padding-bottom: 3px !important;
    }

    .pd-xs-x-3 {
        padding-left: 3px;
        padding-right: 3px;
    }

    .pd-xs-x-3-force {
        padding-left: 3px !important;
        padding-right: 3px !important;
    }

    .pd-xs-t-3 {
        padding-top: 3px;
    }

    .pd-xs-r-3 {
        padding-right: 3px;
    }

    .pd-xs-b-3 {
        padding-bottom: 3px;
    }

    .pd-xs-l-3 {
        padding-left: 3px;
    }

    .pd-xs-t-3-force {
        padding-top: 3px !important;
    }

    .pd-xs-r-3-force {
        padding-right: 3px !important;
    }

    .pd-xs-b-3-force {
        padding-bottom: 3px !important;
    }

    .pd-xs-l-3-force {
        padding-left: 3px !important;
    }

    .pd-xs-4 {
        padding: 4px;
    }

    .pd-xs-4-force {
        padding: 4px !important;
    }

    .pd-xs-y-4 {
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .pd-xs-y-4-force {
        padding-top: 4px !important;
        padding-bottom: 4px !important;
    }

    .pd-xs-x-4 {
        padding-left: 4px;
        padding-right: 4px;
    }

    .pd-xs-x-4-force {
        padding-left: 4px !important;
        padding-right: 4px !important;
    }

    .pd-xs-t-4 {
        padding-top: 4px;
    }

    .pd-xs-r-4 {
        padding-right: 4px;
    }

    .pd-xs-b-4 {
        padding-bottom: 4px;
    }

    .pd-xs-l-4 {
        padding-left: 4px;
    }

    .pd-xs-t-4-force {
        padding-top: 4px !important;
    }

    .pd-xs-r-4-force {
        padding-right: 4px !important;
    }

    .pd-xs-b-4-force {
        padding-bottom: 4px !important;
    }

    .pd-xs-l-4-force {
        padding-left: 4px !important;
    }

    .pd-xs-5 {
        padding: 5px;
    }

    .pd-xs-5-force {
        padding: 5px !important;
    }

    .pd-xs-y-5 {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .pd-xs-y-5-force {
        padding-top: 5px !important;
        padding-bottom: 5px !important;
    }

    .pd-xs-x-5 {
        padding-left: 5px;
        padding-right: 5px;
    }

    .pd-xs-x-5-force {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }

    .pd-xs-t-5 {
        padding-top: 5px;
    }

    .pd-xs-r-5 {
        padding-right: 5px;
    }

    .pd-xs-b-5 {
        padding-bottom: 5px;
    }

    .pd-xs-l-5 {
        padding-left: 5px;
    }

    .pd-xs-t-5-force {
        padding-top: 5px !important;
    }

    .pd-xs-r-5-force {
        padding-right: 5px !important;
    }

    .pd-xs-b-5-force {
        padding-bottom: 5px !important;
    }

    .pd-xs-l-5-force {
        padding-left: 5px !important;
    }

    .pd-xs-6 {
        padding: 6px;
    }

    .pd-xs-6-force {
        padding: 6px !important;
    }

    .pd-xs-y-6 {
        padding-top: 6px;
        padding-bottom: 6px;
    }

    .pd-xs-y-6-force {
        padding-top: 6px !important;
        padding-bottom: 6px !important;
    }

    .pd-xs-x-6 {
        padding-left: 6px;
        padding-right: 6px;
    }

    .pd-xs-x-6-force {
        padding-left: 6px !important;
        padding-right: 6px !important;
    }

    .pd-xs-t-6 {
        padding-top: 6px;
    }

    .pd-xs-r-6 {
        padding-right: 6px;
    }

    .pd-xs-b-6 {
        padding-bottom: 6px;
    }

    .pd-xs-l-6 {
        padding-left: 6px;
    }

    .pd-xs-t-6-force {
        padding-top: 6px !important;
    }

    .pd-xs-r-6-force {
        padding-right: 6px !important;
    }

    .pd-xs-b-6-force {
        padding-bottom: 6px !important;
    }

    .pd-xs-l-6-force {
        padding-left: 6px !important;
    }

    .pd-xs-7 {
        padding: 7px;
    }

    .pd-xs-7-force {
        padding: 7px !important;
    }

    .pd-xs-y-7 {
        padding-top: 7px;
        padding-bottom: 7px;
    }

    .pd-xs-y-7-force {
        padding-top: 7px !important;
        padding-bottom: 7px !important;
    }

    .pd-xs-x-7 {
        padding-left: 7px;
        padding-right: 7px;
    }

    .pd-xs-x-7-force {
        padding-left: 7px !important;
        padding-right: 7px !important;
    }

    .pd-xs-t-7 {
        padding-top: 7px;
    }

    .pd-xs-r-7 {
        padding-right: 7px;
    }

    .pd-xs-b-7 {
        padding-bottom: 7px;
    }

    .pd-xs-l-7 {
        padding-left: 7px;
    }

    .pd-xs-t-7-force {
        padding-top: 7px !important;
    }

    .pd-xs-r-7-force {
        padding-right: 7px !important;
    }

    .pd-xs-b-7-force {
        padding-bottom: 7px !important;
    }

    .pd-xs-l-7-force {
        padding-left: 7px !important;
    }

    .pd-xs-8 {
        padding: 8px;
    }

    .pd-xs-8-force {
        padding: 8px !important;
    }

    .pd-xs-y-8 {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .pd-xs-y-8-force {
        padding-top: 8px !important;
        padding-bottom: 8px !important;
    }

    .pd-xs-x-8 {
        padding-left: 8px;
        padding-right: 8px;
    }

    .pd-xs-x-8-force {
        padding-left: 8px !important;
        padding-right: 8px !important;
    }

    .pd-xs-t-8 {
        padding-top: 8px;
    }

    .pd-xs-r-8 {
        padding-right: 8px;
    }

    .pd-xs-b-8 {
        padding-bottom: 8px;
    }

    .pd-xs-l-8 {
        padding-left: 8px;
    }

    .pd-xs-t-8-force {
        padding-top: 8px !important;
    }

    .pd-xs-r-8-force {
        padding-right: 8px !important;
    }

    .pd-xs-b-8-force {
        padding-bottom: 8px !important;
    }

    .pd-xs-l-8-force {
        padding-left: 8px !important;
    }

    .pd-xs-9 {
        padding: 9px;
    }

    .pd-xs-9-force {
        padding: 9px !important;
    }

    .pd-xs-y-9 {
        padding-top: 9px;
        padding-bottom: 9px;
    }

    .pd-xs-y-9-force {
        padding-top: 9px !important;
        padding-bottom: 9px !important;
    }

    .pd-xs-x-9 {
        padding-left: 9px;
        padding-right: 9px;
    }

    .pd-xs-x-9-force {
        padding-left: 9px !important;
        padding-right: 9px !important;
    }

    .pd-xs-t-9 {
        padding-top: 9px;
    }

    .pd-xs-r-9 {
        padding-right: 9px;
    }

    .pd-xs-b-9 {
        padding-bottom: 9px;
    }

    .pd-xs-l-9 {
        padding-left: 9px;
    }

    .pd-xs-t-9-force {
        padding-top: 9px !important;
    }

    .pd-xs-r-9-force {
        padding-right: 9px !important;
    }

    .pd-xs-b-9-force {
        padding-bottom: 9px !important;
    }

    .pd-xs-l-9-force {
        padding-left: 9px !important;
    }

    .pd-xs-10 {
        padding: 10px;
    }

    .pd-xs-10-force {
        padding: 10px !important;
    }

    .pd-xs-y-10 {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .pd-xs-y-10-force {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }

    .pd-xs-x-10 {
        padding-left: 10px;
        padding-right: 10px;
    }

    .pd-xs-x-10-force {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .pd-xs-t-10 {
        padding-top: 10px;
    }

    .pd-xs-r-10 {
        padding-right: 10px;
    }

    .pd-xs-b-10 {
        padding-bottom: 10px;
    }

    .pd-xs-l-10 {
        padding-left: 10px;
    }

    .pd-xs-t-10-force {
        padding-top: 10px !important;
    }

    .pd-xs-r-10-force {
        padding-right: 10px !important;
    }

    .pd-xs-b-10-force {
        padding-bottom: 10px !important;
    }

    .pd-xs-l-10-force {
        padding-left: 10px !important;
    }

    .pd-xs-15 {
        padding: 15px;
    }

    .pd-xs-15-force {
        padding: 15px !important;
    }

    .pd-xs-y-15 {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .pd-xs-y-15-force {
        padding-top: 15px !important;
        padding-bottom: 15px !important;
    }

    .pd-xs-x-15 {
        padding-left: 15px;
        padding-right: 15px;
    }

    .pd-xs-x-15-force {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .pd-xs-t-15 {
        padding-top: 15px;
    }

    .pd-xs-r-15 {
        padding-right: 15px;
    }

    .pd-xs-b-15 {
        padding-bottom: 15px;
    }

    .pd-xs-l-15 {
        padding-left: 15px;
    }

    .pd-xs-t-15-force {
        padding-top: 15px !important;
    }

    .pd-xs-r-15-force {
        padding-right: 15px !important;
    }

    .pd-xs-b-15-force {
        padding-bottom: 15px !important;
    }

    .pd-xs-l-15-force {
        padding-left: 15px !important;
    }

    .pd-xs-20 {
        padding: 20px;
    }

    .pd-xs-20-force {
        padding: 20px !important;
    }

    .pd-xs-y-20 {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .pd-xs-y-20-force {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }

    .pd-xs-x-20 {
        padding-left: 20px;
        padding-right: 20px;
    }

    .pd-xs-x-20-force {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .pd-xs-t-20 {
        padding-top: 20px;
    }

    .pd-xs-r-20 {
        padding-right: 20px;
    }

    .pd-xs-b-20 {
        padding-bottom: 20px;
    }

    .pd-xs-l-20 {
        padding-left: 20px;
    }

    .pd-xs-t-20-force {
        padding-top: 20px !important;
    }

    .pd-xs-r-20-force {
        padding-right: 20px !important;
    }

    .pd-xs-b-20-force {
        padding-bottom: 20px !important;
    }

    .pd-xs-l-20-force {
        padding-left: 20px !important;
    }

    .pd-xs-25 {
        padding: 25px;
    }

    .pd-xs-25-force {
        padding: 25px !important;
    }

    .pd-xs-y-25 {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .pd-xs-y-25-force {
        padding-top: 25px !important;
        padding-bottom: 25px !important;
    }

    .pd-xs-x-25 {
        padding-left: 25px;
        padding-right: 25px;
    }

    .pd-xs-x-25-force {
        padding-left: 25px !important;
        padding-right: 25px !important;
    }

    .pd-xs-t-25 {
        padding-top: 25px;
    }

    .pd-xs-r-25 {
        padding-right: 25px;
    }

    .pd-xs-b-25 {
        padding-bottom: 25px;
    }

    .pd-xs-l-25 {
        padding-left: 25px;
    }

    .pd-xs-t-25-force {
        padding-top: 25px !important;
    }

    .pd-xs-r-25-force {
        padding-right: 25px !important;
    }

    .pd-xs-b-25-force {
        padding-bottom: 25px !important;
    }

    .pd-xs-l-25-force {
        padding-left: 25px !important;
    }

    .pd-xs-30 {
        padding: 30px;
    }

    .pd-xs-30-force {
        padding: 30px !important;
    }

    .pd-xs-y-30 {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .pd-xs-y-30-force {
        padding-top: 30px !important;
        padding-bottom: 30px !important;
    }

    .pd-xs-x-30 {
        padding-left: 30px;
        padding-right: 30px;
    }

    .pd-xs-x-30-force {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }

    .pd-xs-t-30 {
        padding-top: 30px;
    }

    .pd-xs-r-30 {
        padding-right: 30px;
    }

    .pd-xs-b-30 {
        padding-bottom: 30px;
    }

    .pd-xs-l-30 {
        padding-left: 30px;
    }

    .pd-xs-t-30-force {
        padding-top: 30px !important;
    }

    .pd-xs-r-30-force {
        padding-right: 30px !important;
    }

    .pd-xs-b-30-force {
        padding-bottom: 30px !important;
    }

    .pd-xs-l-30-force {
        padding-left: 30px !important;
    }

    .pd-xs-35 {
        padding: 35px;
    }

    .pd-xs-35-force {
        padding: 35px !important;
    }

    .pd-xs-y-35 {
        padding-top: 35px;
        padding-bottom: 35px;
    }

    .pd-xs-y-35-force {
        padding-top: 35px !important;
        padding-bottom: 35px !important;
    }

    .pd-xs-x-35 {
        padding-left: 35px;
        padding-right: 35px;
    }

    .pd-xs-x-35-force {
        padding-left: 35px !important;
        padding-right: 35px !important;
    }

    .pd-xs-t-35 {
        padding-top: 35px;
    }

    .pd-xs-r-35 {
        padding-right: 35px;
    }

    .pd-xs-b-35 {
        padding-bottom: 35px;
    }

    .pd-xs-l-35 {
        padding-left: 35px;
    }

    .pd-xs-t-35-force {
        padding-top: 35px !important;
    }

    .pd-xs-r-35-force {
        padding-right: 35px !important;
    }

    .pd-xs-b-35-force {
        padding-bottom: 35px !important;
    }

    .pd-xs-l-35-force {
        padding-left: 35px !important;
    }

    .pd-xs-40 {
        padding: 40px;
    }

    .pd-xs-40-force {
        padding: 40px !important;
    }

    .pd-xs-y-40 {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .pd-xs-y-40-force {
        padding-top: 40px !important;
        padding-bottom: 40px !important;
    }

    .pd-xs-x-40 {
        padding-left: 40px;
        padding-right: 40px;
    }

    .pd-xs-x-40-force {
        padding-left: 40px !important;
        padding-right: 40px !important;
    }

    .pd-xs-t-40 {
        padding-top: 40px;
    }

    .pd-xs-r-40 {
        padding-right: 40px;
    }

    .pd-xs-b-40 {
        padding-bottom: 40px;
    }

    .pd-xs-l-40 {
        padding-left: 40px;
    }

    .pd-xs-t-40-force {
        padding-top: 40px !important;
    }

    .pd-xs-r-40-force {
        padding-right: 40px !important;
    }

    .pd-xs-b-40-force {
        padding-bottom: 40px !important;
    }

    .pd-xs-l-40-force {
        padding-left: 40px !important;
    }

    .pd-xs-45 {
        padding: 45px;
    }

    .pd-xs-45-force {
        padding: 45px !important;
    }

    .pd-xs-y-45 {
        padding-top: 45px;
        padding-bottom: 45px;
    }

    .pd-xs-y-45-force {
        padding-top: 45px !important;
        padding-bottom: 45px !important;
    }

    .pd-xs-x-45 {
        padding-left: 45px;
        padding-right: 45px;
    }

    .pd-xs-x-45-force {
        padding-left: 45px !important;
        padding-right: 45px !important;
    }

    .pd-xs-t-45 {
        padding-top: 45px;
    }

    .pd-xs-r-45 {
        padding-right: 45px;
    }

    .pd-xs-b-45 {
        padding-bottom: 45px;
    }

    .pd-xs-l-45 {
        padding-left: 45px;
    }

    .pd-xs-t-45-force {
        padding-top: 45px !important;
    }

    .pd-xs-r-45-force {
        padding-right: 45px !important;
    }

    .pd-xs-b-45-force {
        padding-bottom: 45px !important;
    }

    .pd-xs-l-45-force {
        padding-left: 45px !important;
    }

    .pd-xs-50 {
        padding: 50px;
    }

    .pd-xs-50-force {
        padding: 50px !important;
    }

    .pd-xs-y-50 {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .pd-xs-y-50-force {
        padding-top: 50px !important;
        padding-bottom: 50px !important;
    }

    .pd-xs-x-50 {
        padding-left: 50px;
        padding-right: 50px;
    }

    .pd-xs-x-50-force {
        padding-left: 50px !important;
        padding-right: 50px !important;
    }

    .pd-xs-t-50 {
        padding-top: 50px;
    }

    .pd-xs-r-50 {
        padding-right: 50px;
    }

    .pd-xs-b-50 {
        padding-bottom: 50px;
    }

    .pd-xs-l-50 {
        padding-left: 50px;
    }

    .pd-xs-t-50-force {
        padding-top: 50px !important;
    }

    .pd-xs-r-50-force {
        padding-right: 50px !important;
    }

    .pd-xs-b-50-force {
        padding-bottom: 50px !important;
    }

    .pd-xs-l-50-force {
        padding-left: 50px !important;
    }

    .pd-xs-55 {
        padding: 55px;
    }

    .pd-xs-55-force {
        padding: 55px !important;
    }

    .pd-xs-y-55 {
        padding-top: 55px;
        padding-bottom: 55px;
    }

    .pd-xs-y-55-force {
        padding-top: 55px !important;
        padding-bottom: 55px !important;
    }

    .pd-xs-x-55 {
        padding-left: 55px;
        padding-right: 55px;
    }

    .pd-xs-x-55-force {
        padding-left: 55px !important;
        padding-right: 55px !important;
    }

    .pd-xs-t-55 {
        padding-top: 55px;
    }

    .pd-xs-r-55 {
        padding-right: 55px;
    }

    .pd-xs-b-55 {
        padding-bottom: 55px;
    }

    .pd-xs-l-55 {
        padding-left: 55px;
    }

    .pd-xs-t-55-force {
        padding-top: 55px !important;
    }

    .pd-xs-r-55-force {
        padding-right: 55px !important;
    }

    .pd-xs-b-55-force {
        padding-bottom: 55px !important;
    }

    .pd-xs-l-55-force {
        padding-left: 55px !important;
    }

    .pd-xs-60 {
        padding: 60px;
    }

    .pd-xs-60-force {
        padding: 60px !important;
    }

    .pd-xs-y-60 {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .pd-xs-y-60-force {
        padding-top: 60px !important;
        padding-bottom: 60px !important;
    }

    .pd-xs-x-60 {
        padding-left: 60px;
        padding-right: 60px;
    }

    .pd-xs-x-60-force {
        padding-left: 60px !important;
        padding-right: 60px !important;
    }

    .pd-xs-t-60 {
        padding-top: 60px;
    }

    .pd-xs-r-60 {
        padding-right: 60px;
    }

    .pd-xs-b-60 {
        padding-bottom: 60px;
    }

    .pd-xs-l-60 {
        padding-left: 60px;
    }

    .pd-xs-t-60-force {
        padding-top: 60px !important;
    }

    .pd-xs-r-60-force {
        padding-right: 60px !important;
    }

    .pd-xs-b-60-force {
        padding-bottom: 60px !important;
    }

    .pd-xs-l-60-force {
        padding-left: 60px !important;
    }

    .pd-xs-65 {
        padding: 65px;
    }

    .pd-xs-65-force {
        padding: 65px !important;
    }

    .pd-xs-y-65 {
        padding-top: 65px;
        padding-bottom: 65px;
    }

    .pd-xs-y-65-force {
        padding-top: 65px !important;
        padding-bottom: 65px !important;
    }

    .pd-xs-x-65 {
        padding-left: 65px;
        padding-right: 65px;
    }

    .pd-xs-x-65-force {
        padding-left: 65px !important;
        padding-right: 65px !important;
    }

    .pd-xs-t-65 {
        padding-top: 65px;
    }

    .pd-xs-r-65 {
        padding-right: 65px;
    }

    .pd-xs-b-65 {
        padding-bottom: 65px;
    }

    .pd-xs-l-65 {
        padding-left: 65px;
    }

    .pd-xs-t-65-force {
        padding-top: 65px !important;
    }

    .pd-xs-r-65-force {
        padding-right: 65px !important;
    }

    .pd-xs-b-65-force {
        padding-bottom: 65px !important;
    }

    .pd-xs-l-65-force {
        padding-left: 65px !important;
    }

    .pd-xs-70 {
        padding: 70px;
    }

    .pd-xs-70-force {
        padding: 70px !important;
    }

    .pd-xs-y-70 {
        padding-top: 70px;
        padding-bottom: 70px;
    }

    .pd-xs-y-70-force {
        padding-top: 70px !important;
        padding-bottom: 70px !important;
    }

    .pd-xs-x-70 {
        padding-left: 70px;
        padding-right: 70px;
    }

    .pd-xs-x-70-force {
        padding-left: 70px !important;
        padding-right: 70px !important;
    }

    .pd-xs-t-70 {
        padding-top: 70px;
    }

    .pd-xs-r-70 {
        padding-right: 70px;
    }

    .pd-xs-b-70 {
        padding-bottom: 70px;
    }

    .pd-xs-l-70 {
        padding-left: 70px;
    }

    .pd-xs-t-70-force {
        padding-top: 70px !important;
    }

    .pd-xs-r-70-force {
        padding-right: 70px !important;
    }

    .pd-xs-b-70-force {
        padding-bottom: 70px !important;
    }

    .pd-xs-l-70-force {
        padding-left: 70px !important;
    }

    .pd-xs-75 {
        padding: 75px;
    }

    .pd-xs-75-force {
        padding: 75px !important;
    }

    .pd-xs-y-75 {
        padding-top: 75px;
        padding-bottom: 75px;
    }

    .pd-xs-y-75-force {
        padding-top: 75px !important;
        padding-bottom: 75px !important;
    }

    .pd-xs-x-75 {
        padding-left: 75px;
        padding-right: 75px;
    }

    .pd-xs-x-75-force {
        padding-left: 75px !important;
        padding-right: 75px !important;
    }

    .pd-xs-t-75 {
        padding-top: 75px;
    }

    .pd-xs-r-75 {
        padding-right: 75px;
    }

    .pd-xs-b-75 {
        padding-bottom: 75px;
    }

    .pd-xs-l-75 {
        padding-left: 75px;
    }

    .pd-xs-t-75-force {
        padding-top: 75px !important;
    }

    .pd-xs-r-75-force {
        padding-right: 75px !important;
    }

    .pd-xs-b-75-force {
        padding-bottom: 75px !important;
    }

    .pd-xs-l-75-force {
        padding-left: 75px !important;
    }

    .pd-xs-80 {
        padding: 80px;
    }

    .pd-xs-80-force {
        padding: 80px !important;
    }

    .pd-xs-y-80 {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .pd-xs-y-80-force {
        padding-top: 80px !important;
        padding-bottom: 80px !important;
    }

    .pd-xs-x-80 {
        padding-left: 80px;
        padding-right: 80px;
    }

    .pd-xs-x-80-force {
        padding-left: 80px !important;
        padding-right: 80px !important;
    }

    .pd-xs-t-80 {
        padding-top: 80px;
    }

    .pd-xs-r-80 {
        padding-right: 80px;
    }

    .pd-xs-b-80 {
        padding-bottom: 80px;
    }

    .pd-xs-l-80 {
        padding-left: 80px;
    }

    .pd-xs-t-80-force {
        padding-top: 80px !important;
    }

    .pd-xs-r-80-force {
        padding-right: 80px !important;
    }

    .pd-xs-b-80-force {
        padding-bottom: 80px !important;
    }

    .pd-xs-l-80-force {
        padding-left: 80px !important;
    }

    .pd-xs-85 {
        padding: 85px;
    }

    .pd-xs-85-force {
        padding: 85px !important;
    }

    .pd-xs-y-85 {
        padding-top: 85px;
        padding-bottom: 85px;
    }

    .pd-xs-y-85-force {
        padding-top: 85px !important;
        padding-bottom: 85px !important;
    }

    .pd-xs-x-85 {
        padding-left: 85px;
        padding-right: 85px;
    }

    .pd-xs-x-85-force {
        padding-left: 85px !important;
        padding-right: 85px !important;
    }

    .pd-xs-t-85 {
        padding-top: 85px;
    }

    .pd-xs-r-85 {
        padding-right: 85px;
    }

    .pd-xs-b-85 {
        padding-bottom: 85px;
    }

    .pd-xs-l-85 {
        padding-left: 85px;
    }

    .pd-xs-t-85-force {
        padding-top: 85px !important;
    }

    .pd-xs-r-85-force {
        padding-right: 85px !important;
    }

    .pd-xs-b-85-force {
        padding-bottom: 85px !important;
    }

    .pd-xs-l-85-force {
        padding-left: 85px !important;
    }

    .pd-xs-90 {
        padding: 90px;
    }

    .pd-xs-90-force {
        padding: 90px !important;
    }

    .pd-xs-y-90 {
        padding-top: 90px;
        padding-bottom: 90px;
    }

    .pd-xs-y-90-force {
        padding-top: 90px !important;
        padding-bottom: 90px !important;
    }

    .pd-xs-x-90 {
        padding-left: 90px;
        padding-right: 90px;
    }

    .pd-xs-x-90-force {
        padding-left: 90px !important;
        padding-right: 90px !important;
    }

    .pd-xs-t-90 {
        padding-top: 90px;
    }

    .pd-xs-r-90 {
        padding-right: 90px;
    }

    .pd-xs-b-90 {
        padding-bottom: 90px;
    }

    .pd-xs-l-90 {
        padding-left: 90px;
    }

    .pd-xs-t-90-force {
        padding-top: 90px !important;
    }

    .pd-xs-r-90-force {
        padding-right: 90px !important;
    }

    .pd-xs-b-90-force {
        padding-bottom: 90px !important;
    }

    .pd-xs-l-90-force {
        padding-left: 90px !important;
    }

    .pd-xs-95 {
        padding: 95px;
    }

    .pd-xs-95-force {
        padding: 95px !important;
    }

    .pd-xs-y-95 {
        padding-top: 95px;
        padding-bottom: 95px;
    }

    .pd-xs-y-95-force {
        padding-top: 95px !important;
        padding-bottom: 95px !important;
    }

    .pd-xs-x-95 {
        padding-left: 95px;
        padding-right: 95px;
    }

    .pd-xs-x-95-force {
        padding-left: 95px !important;
        padding-right: 95px !important;
    }

    .pd-xs-t-95 {
        padding-top: 95px;
    }

    .pd-xs-r-95 {
        padding-right: 95px;
    }

    .pd-xs-b-95 {
        padding-bottom: 95px;
    }

    .pd-xs-l-95 {
        padding-left: 95px;
    }

    .pd-xs-t-95-force {
        padding-top: 95px !important;
    }

    .pd-xs-r-95-force {
        padding-right: 95px !important;
    }

    .pd-xs-b-95-force {
        padding-bottom: 95px !important;
    }

    .pd-xs-l-95-force {
        padding-left: 95px !important;
    }

    .pd-xs-100 {
        padding: 100px;
    }

    .pd-xs-100-force {
        padding: 100px !important;
    }

    .pd-xs-y-100 {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .pd-xs-y-100-force {
        padding-top: 100px !important;
        padding-bottom: 100px !important;
    }

    .pd-xs-x-100 {
        padding-left: 100px;
        padding-right: 100px;
    }

    .pd-xs-x-100-force {
        padding-left: 100px !important;
        padding-right: 100px !important;
    }

    .pd-xs-t-100 {
        padding-top: 100px;
    }

    .pd-xs-r-100 {
        padding-right: 100px;
    }

    .pd-xs-b-100 {
        padding-bottom: 100px;
    }

    .pd-xs-l-100 {
        padding-left: 100px;
    }

    .pd-xs-t-100-force {
        padding-top: 100px !important;
    }

    .pd-xs-r-100-force {
        padding-right: 100px !important;
    }

    .pd-xs-b-100-force {
        padding-bottom: 100px !important;
    }

    .pd-xs-l-100-force {
        padding-left: 100px !important;
    }

    .pd-xs-110 {
        padding: 110px;
    }

    .pd-xs-110-force {
        padding: 110px !important;
    }

    .pd-xs-y-110 {
        padding-top: 110px;
        padding-bottom: 110px;
    }

    .pd-xs-y-110-force {
        padding-top: 110px !important;
        padding-bottom: 110px !important;
    }

    .pd-xs-x-110 {
        padding-left: 110px;
        padding-right: 110px;
    }

    .pd-xs-x-110-force {
        padding-left: 110px !important;
        padding-right: 110px !important;
    }

    .pd-xs-t-110 {
        padding-top: 110px;
    }

    .pd-xs-r-110 {
        padding-right: 110px;
    }

    .pd-xs-b-110 {
        padding-bottom: 110px;
    }

    .pd-xs-l-110 {
        padding-left: 110px;
    }

    .pd-xs-t-110-force {
        padding-top: 110px !important;
    }

    .pd-xs-r-110-force {
        padding-right: 110px !important;
    }

    .pd-xs-b-110-force {
        padding-bottom: 110px !important;
    }

    .pd-xs-l-110-force {
        padding-left: 110px !important;
    }

    .pd-xs-120 {
        padding: 120px;
    }

    .pd-xs-120-force {
        padding: 120px !important;
    }

    .pd-xs-y-120 {
        padding-top: 120px;
        padding-bottom: 120px;
    }

    .pd-xs-y-120-force {
        padding-top: 120px !important;
        padding-bottom: 120px !important;
    }

    .pd-xs-x-120 {
        padding-left: 120px;
        padding-right: 120px;
    }

    .pd-xs-x-120-force {
        padding-left: 120px !important;
        padding-right: 120px !important;
    }

    .pd-xs-t-120 {
        padding-top: 120px;
    }

    .pd-xs-r-120 {
        padding-right: 120px;
    }

    .pd-xs-b-120 {
        padding-bottom: 120px;
    }

    .pd-xs-l-120 {
        padding-left: 120px;
    }

    .pd-xs-t-120-force {
        padding-top: 120px !important;
    }

    .pd-xs-r-120-force {
        padding-right: 120px !important;
    }

    .pd-xs-b-120-force {
        padding-bottom: 120px !important;
    }

    .pd-xs-l-120-force {
        padding-left: 120px !important;
    }

    .pd-xs-130 {
        padding: 130px;
    }

    .pd-xs-130-force {
        padding: 130px !important;
    }

    .pd-xs-y-130 {
        padding-top: 130px;
        padding-bottom: 130px;
    }

    .pd-xs-y-130-force {
        padding-top: 130px !important;
        padding-bottom: 130px !important;
    }

    .pd-xs-x-130 {
        padding-left: 130px;
        padding-right: 130px;
    }

    .pd-xs-x-130-force {
        padding-left: 130px !important;
        padding-right: 130px !important;
    }

    .pd-xs-t-130 {
        padding-top: 130px;
    }

    .pd-xs-r-130 {
        padding-right: 130px;
    }

    .pd-xs-b-130 {
        padding-bottom: 130px;
    }

    .pd-xs-l-130 {
        padding-left: 130px;
    }

    .pd-xs-t-130-force {
        padding-top: 130px !important;
    }

    .pd-xs-r-130-force {
        padding-right: 130px !important;
    }

    .pd-xs-b-130-force {
        padding-bottom: 130px !important;
    }

    .pd-xs-l-130-force {
        padding-left: 130px !important;
    }

    .pd-xs-140 {
        padding: 140px;
    }

    .pd-xs-140-force {
        padding: 140px !important;
    }

    .pd-xs-y-140 {
        padding-top: 140px;
        padding-bottom: 140px;
    }

    .pd-xs-y-140-force {
        padding-top: 140px !important;
        padding-bottom: 140px !important;
    }

    .pd-xs-x-140 {
        padding-left: 140px;
        padding-right: 140px;
    }

    .pd-xs-x-140-force {
        padding-left: 140px !important;
        padding-right: 140px !important;
    }

    .pd-xs-t-140 {
        padding-top: 140px;
    }

    .pd-xs-r-140 {
        padding-right: 140px;
    }

    .pd-xs-b-140 {
        padding-bottom: 140px;
    }

    .pd-xs-l-140 {
        padding-left: 140px;
    }

    .pd-xs-t-140-force {
        padding-top: 140px !important;
    }

    .pd-xs-r-140-force {
        padding-right: 140px !important;
    }

    .pd-xs-b-140-force {
        padding-bottom: 140px !important;
    }

    .pd-xs-l-140-force {
        padding-left: 140px !important;
    }

    .pd-xs-150 {
        padding: 150px;
    }

    .pd-xs-150-force {
        padding: 150px !important;
    }

    .pd-xs-y-150 {
        padding-top: 150px;
        padding-bottom: 150px;
    }

    .pd-xs-y-150-force {
        padding-top: 150px !important;
        padding-bottom: 150px !important;
    }

    .pd-xs-x-150 {
        padding-left: 150px;
        padding-right: 150px;
    }

    .pd-xs-x-150-force {
        padding-left: 150px !important;
        padding-right: 150px !important;
    }

    .pd-xs-t-150 {
        padding-top: 150px;
    }

    .pd-xs-r-150 {
        padding-right: 150px;
    }

    .pd-xs-b-150 {
        padding-bottom: 150px;
    }

    .pd-xs-l-150 {
        padding-left: 150px;
    }

    .pd-xs-t-150-force {
        padding-top: 150px !important;
    }

    .pd-xs-r-150-force {
        padding-right: 150px !important;
    }

    .pd-xs-b-150-force {
        padding-bottom: 150px !important;
    }

    .pd-xs-l-150-force {
        padding-left: 150px !important;
    }

    .pd-xs-160 {
        padding: 160px;
    }

    .pd-xs-160-force {
        padding: 160px !important;
    }

    .pd-xs-y-160 {
        padding-top: 160px;
        padding-bottom: 160px;
    }

    .pd-xs-y-160-force {
        padding-top: 160px !important;
        padding-bottom: 160px !important;
    }

    .pd-xs-x-160 {
        padding-left: 160px;
        padding-right: 160px;
    }

    .pd-xs-x-160-force {
        padding-left: 160px !important;
        padding-right: 160px !important;
    }

    .pd-xs-t-160 {
        padding-top: 160px;
    }

    .pd-xs-r-160 {
        padding-right: 160px;
    }

    .pd-xs-b-160 {
        padding-bottom: 160px;
    }

    .pd-xs-l-160 {
        padding-left: 160px;
    }

    .pd-xs-t-160-force {
        padding-top: 160px !important;
    }

    .pd-xs-r-160-force {
        padding-right: 160px !important;
    }

    .pd-xs-b-160-force {
        padding-bottom: 160px !important;
    }

    .pd-xs-l-160-force {
        padding-left: 160px !important;
    }

    .pd-xs-170 {
        padding: 170px;
    }

    .pd-xs-170-force {
        padding: 170px !important;
    }

    .pd-xs-y-170 {
        padding-top: 170px;
        padding-bottom: 170px;
    }

    .pd-xs-y-170-force {
        padding-top: 170px !important;
        padding-bottom: 170px !important;
    }

    .pd-xs-x-170 {
        padding-left: 170px;
        padding-right: 170px;
    }

    .pd-xs-x-170-force {
        padding-left: 170px !important;
        padding-right: 170px !important;
    }

    .pd-xs-t-170 {
        padding-top: 170px;
    }

    .pd-xs-r-170 {
        padding-right: 170px;
    }

    .pd-xs-b-170 {
        padding-bottom: 170px;
    }

    .pd-xs-l-170 {
        padding-left: 170px;
    }

    .pd-xs-t-170-force {
        padding-top: 170px !important;
    }

    .pd-xs-r-170-force {
        padding-right: 170px !important;
    }

    .pd-xs-b-170-force {
        padding-bottom: 170px !important;
    }

    .pd-xs-l-170-force {
        padding-left: 170px !important;
    }

    .pd-xs-180 {
        padding: 180px;
    }

    .pd-xs-180-force {
        padding: 180px !important;
    }

    .pd-xs-y-180 {
        padding-top: 180px;
        padding-bottom: 180px;
    }

    .pd-xs-y-180-force {
        padding-top: 180px !important;
        padding-bottom: 180px !important;
    }

    .pd-xs-x-180 {
        padding-left: 180px;
        padding-right: 180px;
    }

    .pd-xs-x-180-force {
        padding-left: 180px !important;
        padding-right: 180px !important;
    }

    .pd-xs-t-180 {
        padding-top: 180px;
    }

    .pd-xs-r-180 {
        padding-right: 180px;
    }

    .pd-xs-b-180 {
        padding-bottom: 180px;
    }

    .pd-xs-l-180 {
        padding-left: 180px;
    }

    .pd-xs-t-180-force {
        padding-top: 180px !important;
    }

    .pd-xs-r-180-force {
        padding-right: 180px !important;
    }

    .pd-xs-b-180-force {
        padding-bottom: 180px !important;
    }

    .pd-xs-l-180-force {
        padding-left: 180px !important;
    }

    .pd-xs-190 {
        padding: 190px;
    }

    .pd-xs-190-force {
        padding: 190px !important;
    }

    .pd-xs-y-190 {
        padding-top: 190px;
        padding-bottom: 190px;
    }

    .pd-xs-y-190-force {
        padding-top: 190px !important;
        padding-bottom: 190px !important;
    }

    .pd-xs-x-190 {
        padding-left: 190px;
        padding-right: 190px;
    }

    .pd-xs-x-190-force {
        padding-left: 190px !important;
        padding-right: 190px !important;
    }

    .pd-xs-t-190 {
        padding-top: 190px;
    }

    .pd-xs-r-190 {
        padding-right: 190px;
    }

    .pd-xs-b-190 {
        padding-bottom: 190px;
    }

    .pd-xs-l-190 {
        padding-left: 190px;
    }

    .pd-xs-t-190-force {
        padding-top: 190px !important;
    }

    .pd-xs-r-190-force {
        padding-right: 190px !important;
    }

    .pd-xs-b-190-force {
        padding-bottom: 190px !important;
    }

    .pd-xs-l-190-force {
        padding-left: 190px !important;
    }

    .pd-xs-200 {
        padding: 200px;
    }

    .pd-xs-200-force {
        padding: 200px !important;
    }

    .pd-xs-y-200 {
        padding-top: 200px;
        padding-bottom: 200px;
    }

    .pd-xs-y-200-force {
        padding-top: 200px !important;
        padding-bottom: 200px !important;
    }

    .pd-xs-x-200 {
        padding-left: 200px;
        padding-right: 200px;
    }

    .pd-xs-x-200-force {
        padding-left: 200px !important;
        padding-right: 200px !important;
    }

    .pd-xs-t-200 {
        padding-top: 200px;
    }

    .pd-xs-r-200 {
        padding-right: 200px;
    }

    .pd-xs-b-200 {
        padding-bottom: 200px;
    }

    .pd-xs-l-200 {
        padding-left: 200px;
    }

    .pd-xs-t-200-force {
        padding-top: 200px !important;
    }

    .pd-xs-r-200-force {
        padding-right: 200px !important;
    }

    .pd-xs-b-200-force {
        padding-bottom: 200px !important;
    }

    .pd-xs-l-200-force {
        padding-left: 200px !important;
    }
}

@media (min-width: 576px) {
    .pd-sm-0 {
        padding: 0px;
    }

    .pd-sm-0-force {
        padding: 0px !important;
    }

    .pd-sm-y-0 {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .pd-sm-y-0-force {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .pd-sm-x-0 {
        padding-left: 0px;
        padding-right: 0px;
    }

    .pd-sm-x-0-force {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .pd-sm-t-0 {
        padding-top: 0px;
    }

    .pd-sm-r-0 {
        padding-right: 0px;
    }

    .pd-sm-b-0 {
        padding-bottom: 0px;
    }

    .pd-sm-l-0 {
        padding-left: 0px;
    }

    .pd-sm-t-0-force {
        padding-top: 0px !important;
    }

    .pd-sm-r-0-force {
        padding-right: 0px !important;
    }

    .pd-sm-b-0-force {
        padding-bottom: 0px !important;
    }

    .pd-sm-l-0-force {
        padding-left: 0px !important;
    }

    .pd-sm-1 {
        padding: 1px;
    }

    .pd-sm-1-force {
        padding: 1px !important;
    }

    .pd-sm-y-1 {
        padding-top: 1px;
        padding-bottom: 1px;
    }

    .pd-sm-y-1-force {
        padding-top: 1px !important;
        padding-bottom: 1px !important;
    }

    .pd-sm-x-1 {
        padding-left: 1px;
        padding-right: 1px;
    }

    .pd-sm-x-1-force {
        padding-left: 1px !important;
        padding-right: 1px !important;
    }

    .pd-sm-t-1 {
        padding-top: 1px;
    }

    .pd-sm-r-1 {
        padding-right: 1px;
    }

    .pd-sm-b-1 {
        padding-bottom: 1px;
    }

    .pd-sm-l-1 {
        padding-left: 1px;
    }

    .pd-sm-t-1-force {
        padding-top: 1px !important;
    }

    .pd-sm-r-1-force {
        padding-right: 1px !important;
    }

    .pd-sm-b-1-force {
        padding-bottom: 1px !important;
    }

    .pd-sm-l-1-force {
        padding-left: 1px !important;
    }

    .pd-sm-2 {
        padding: 2px;
    }

    .pd-sm-2-force {
        padding: 2px !important;
    }

    .pd-sm-y-2 {
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .pd-sm-y-2-force {
        padding-top: 2px !important;
        padding-bottom: 2px !important;
    }

    .pd-sm-x-2 {
        padding-left: 2px;
        padding-right: 2px;
    }

    .pd-sm-x-2-force {
        padding-left: 2px !important;
        padding-right: 2px !important;
    }

    .pd-sm-t-2 {
        padding-top: 2px;
    }

    .pd-sm-r-2 {
        padding-right: 2px;
    }

    .pd-sm-b-2 {
        padding-bottom: 2px;
    }

    .pd-sm-l-2 {
        padding-left: 2px;
    }

    .pd-sm-t-2-force {
        padding-top: 2px !important;
    }

    .pd-sm-r-2-force {
        padding-right: 2px !important;
    }

    .pd-sm-b-2-force {
        padding-bottom: 2px !important;
    }

    .pd-sm-l-2-force {
        padding-left: 2px !important;
    }

    .pd-sm-3 {
        padding: 3px;
    }

    .pd-sm-3-force {
        padding: 3px !important;
    }

    .pd-sm-y-3 {
        padding-top: 3px;
        padding-bottom: 3px;
    }

    .pd-sm-y-3-force {
        padding-top: 3px !important;
        padding-bottom: 3px !important;
    }

    .pd-sm-x-3 {
        padding-left: 3px;
        padding-right: 3px;
    }

    .pd-sm-x-3-force {
        padding-left: 3px !important;
        padding-right: 3px !important;
    }

    .pd-sm-t-3 {
        padding-top: 3px;
    }

    .pd-sm-r-3 {
        padding-right: 3px;
    }

    .pd-sm-b-3 {
        padding-bottom: 3px;
    }

    .pd-sm-l-3 {
        padding-left: 3px;
    }

    .pd-sm-t-3-force {
        padding-top: 3px !important;
    }

    .pd-sm-r-3-force {
        padding-right: 3px !important;
    }

    .pd-sm-b-3-force {
        padding-bottom: 3px !important;
    }

    .pd-sm-l-3-force {
        padding-left: 3px !important;
    }

    .pd-sm-4 {
        padding: 4px;
    }

    .pd-sm-4-force {
        padding: 4px !important;
    }

    .pd-sm-y-4 {
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .pd-sm-y-4-force {
        padding-top: 4px !important;
        padding-bottom: 4px !important;
    }

    .pd-sm-x-4 {
        padding-left: 4px;
        padding-right: 4px;
    }

    .pd-sm-x-4-force {
        padding-left: 4px !important;
        padding-right: 4px !important;
    }

    .pd-sm-t-4 {
        padding-top: 4px;
    }

    .pd-sm-r-4 {
        padding-right: 4px;
    }

    .pd-sm-b-4 {
        padding-bottom: 4px;
    }

    .pd-sm-l-4 {
        padding-left: 4px;
    }

    .pd-sm-t-4-force {
        padding-top: 4px !important;
    }

    .pd-sm-r-4-force {
        padding-right: 4px !important;
    }

    .pd-sm-b-4-force {
        padding-bottom: 4px !important;
    }

    .pd-sm-l-4-force {
        padding-left: 4px !important;
    }

    .pd-sm-5 {
        padding: 5px;
    }

    .pd-sm-5-force {
        padding: 5px !important;
    }

    .pd-sm-y-5 {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .pd-sm-y-5-force {
        padding-top: 5px !important;
        padding-bottom: 5px !important;
    }

    .pd-sm-x-5 {
        padding-left: 5px;
        padding-right: 5px;
    }

    .pd-sm-x-5-force {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }

    .pd-sm-t-5 {
        padding-top: 5px;
    }

    .pd-sm-r-5 {
        padding-right: 5px;
    }

    .pd-sm-b-5 {
        padding-bottom: 5px;
    }

    .pd-sm-l-5 {
        padding-left: 5px;
    }

    .pd-sm-t-5-force {
        padding-top: 5px !important;
    }

    .pd-sm-r-5-force {
        padding-right: 5px !important;
    }

    .pd-sm-b-5-force {
        padding-bottom: 5px !important;
    }

    .pd-sm-l-5-force {
        padding-left: 5px !important;
    }

    .pd-sm-6 {
        padding: 6px;
    }

    .pd-sm-6-force {
        padding: 6px !important;
    }

    .pd-sm-y-6 {
        padding-top: 6px;
        padding-bottom: 6px;
    }

    .pd-sm-y-6-force {
        padding-top: 6px !important;
        padding-bottom: 6px !important;
    }

    .pd-sm-x-6 {
        padding-left: 6px;
        padding-right: 6px;
    }

    .pd-sm-x-6-force {
        padding-left: 6px !important;
        padding-right: 6px !important;
    }

    .pd-sm-t-6 {
        padding-top: 6px;
    }

    .pd-sm-r-6 {
        padding-right: 6px;
    }

    .pd-sm-b-6 {
        padding-bottom: 6px;
    }

    .pd-sm-l-6 {
        padding-left: 6px;
    }

    .pd-sm-t-6-force {
        padding-top: 6px !important;
    }

    .pd-sm-r-6-force {
        padding-right: 6px !important;
    }

    .pd-sm-b-6-force {
        padding-bottom: 6px !important;
    }

    .pd-sm-l-6-force {
        padding-left: 6px !important;
    }

    .pd-sm-7 {
        padding: 7px;
    }

    .pd-sm-7-force {
        padding: 7px !important;
    }

    .pd-sm-y-7 {
        padding-top: 7px;
        padding-bottom: 7px;
    }

    .pd-sm-y-7-force {
        padding-top: 7px !important;
        padding-bottom: 7px !important;
    }

    .pd-sm-x-7 {
        padding-left: 7px;
        padding-right: 7px;
    }

    .pd-sm-x-7-force {
        padding-left: 7px !important;
        padding-right: 7px !important;
    }

    .pd-sm-t-7 {
        padding-top: 7px;
    }

    .pd-sm-r-7 {
        padding-right: 7px;
    }

    .pd-sm-b-7 {
        padding-bottom: 7px;
    }

    .pd-sm-l-7 {
        padding-left: 7px;
    }

    .pd-sm-t-7-force {
        padding-top: 7px !important;
    }

    .pd-sm-r-7-force {
        padding-right: 7px !important;
    }

    .pd-sm-b-7-force {
        padding-bottom: 7px !important;
    }

    .pd-sm-l-7-force {
        padding-left: 7px !important;
    }

    .pd-sm-8 {
        padding: 8px;
    }

    .pd-sm-8-force {
        padding: 8px !important;
    }

    .pd-sm-y-8 {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .pd-sm-y-8-force {
        padding-top: 8px !important;
        padding-bottom: 8px !important;
    }

    .pd-sm-x-8 {
        padding-left: 8px;
        padding-right: 8px;
    }

    .pd-sm-x-8-force {
        padding-left: 8px !important;
        padding-right: 8px !important;
    }

    .pd-sm-t-8 {
        padding-top: 8px;
    }

    .pd-sm-r-8 {
        padding-right: 8px;
    }

    .pd-sm-b-8 {
        padding-bottom: 8px;
    }

    .pd-sm-l-8 {
        padding-left: 8px;
    }

    .pd-sm-t-8-force {
        padding-top: 8px !important;
    }

    .pd-sm-r-8-force {
        padding-right: 8px !important;
    }

    .pd-sm-b-8-force {
        padding-bottom: 8px !important;
    }

    .pd-sm-l-8-force {
        padding-left: 8px !important;
    }

    .pd-sm-9 {
        padding: 9px;
    }

    .pd-sm-9-force {
        padding: 9px !important;
    }

    .pd-sm-y-9 {
        padding-top: 9px;
        padding-bottom: 9px;
    }

    .pd-sm-y-9-force {
        padding-top: 9px !important;
        padding-bottom: 9px !important;
    }

    .pd-sm-x-9 {
        padding-left: 9px;
        padding-right: 9px;
    }

    .pd-sm-x-9-force {
        padding-left: 9px !important;
        padding-right: 9px !important;
    }

    .pd-sm-t-9 {
        padding-top: 9px;
    }

    .pd-sm-r-9 {
        padding-right: 9px;
    }

    .pd-sm-b-9 {
        padding-bottom: 9px;
    }

    .pd-sm-l-9 {
        padding-left: 9px;
    }

    .pd-sm-t-9-force {
        padding-top: 9px !important;
    }

    .pd-sm-r-9-force {
        padding-right: 9px !important;
    }

    .pd-sm-b-9-force {
        padding-bottom: 9px !important;
    }

    .pd-sm-l-9-force {
        padding-left: 9px !important;
    }

    .pd-sm-10 {
        padding: 10px;
    }

    .pd-sm-10-force {
        padding: 10px !important;
    }

    .pd-sm-y-10 {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .pd-sm-y-10-force {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }

    .pd-sm-x-10 {
        padding-left: 10px;
        padding-right: 10px;
    }

    .pd-sm-x-10-force {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .pd-sm-t-10 {
        padding-top: 10px;
    }

    .pd-sm-r-10 {
        padding-right: 10px;
    }

    .pd-sm-b-10 {
        padding-bottom: 10px;
    }

    .pd-sm-l-10 {
        padding-left: 10px;
    }

    .pd-sm-t-10-force {
        padding-top: 10px !important;
    }

    .pd-sm-r-10-force {
        padding-right: 10px !important;
    }

    .pd-sm-b-10-force {
        padding-bottom: 10px !important;
    }

    .pd-sm-l-10-force {
        padding-left: 10px !important;
    }

    .pd-sm-15 {
        padding: 15px;
    }

    .pd-sm-15-force {
        padding: 15px !important;
    }

    .pd-sm-y-15 {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .pd-sm-y-15-force {
        padding-top: 15px !important;
        padding-bottom: 15px !important;
    }

    .pd-sm-x-15 {
        padding-left: 15px;
        padding-right: 15px;
    }

    .pd-sm-x-15-force {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .pd-sm-t-15 {
        padding-top: 15px;
    }

    .pd-sm-r-15 {
        padding-right: 15px;
    }

    .pd-sm-b-15 {
        padding-bottom: 15px;
    }

    .pd-sm-l-15 {
        padding-left: 15px;
    }

    .pd-sm-t-15-force {
        padding-top: 15px !important;
    }

    .pd-sm-r-15-force {
        padding-right: 15px !important;
    }

    .pd-sm-b-15-force {
        padding-bottom: 15px !important;
    }

    .pd-sm-l-15-force {
        padding-left: 15px !important;
    }

    .pd-sm-20 {
        padding: 20px;
    }

    .pd-sm-20-force {
        padding: 20px !important;
    }

    .pd-sm-y-20 {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .pd-sm-y-20-force {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }

    .pd-sm-x-20 {
        padding-left: 20px;
        padding-right: 20px;
    }

    .pd-sm-x-20-force {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .pd-sm-t-20 {
        padding-top: 20px;
    }

    .pd-sm-r-20 {
        padding-right: 20px;
    }

    .pd-sm-b-20 {
        padding-bottom: 20px;
    }

    .pd-sm-l-20 {
        padding-left: 20px;
    }

    .pd-sm-t-20-force {
        padding-top: 20px !important;
    }

    .pd-sm-r-20-force {
        padding-right: 20px !important;
    }

    .pd-sm-b-20-force {
        padding-bottom: 20px !important;
    }

    .pd-sm-l-20-force {
        padding-left: 20px !important;
    }

    .pd-sm-25 {
        padding: 25px;
    }

    .pd-sm-25-force {
        padding: 25px !important;
    }

    .pd-sm-y-25 {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .pd-sm-y-25-force {
        padding-top: 25px !important;
        padding-bottom: 25px !important;
    }

    .pd-sm-x-25 {
        padding-left: 25px;
        padding-right: 25px;
    }

    .pd-sm-x-25-force {
        padding-left: 25px !important;
        padding-right: 25px !important;
    }

    .pd-sm-t-25 {
        padding-top: 25px;
    }

    .pd-sm-r-25 {
        padding-right: 25px;
    }

    .pd-sm-b-25 {
        padding-bottom: 25px;
    }

    .pd-sm-l-25 {
        padding-left: 25px;
    }

    .pd-sm-t-25-force {
        padding-top: 25px !important;
    }

    .pd-sm-r-25-force {
        padding-right: 25px !important;
    }

    .pd-sm-b-25-force {
        padding-bottom: 25px !important;
    }

    .pd-sm-l-25-force {
        padding-left: 25px !important;
    }

    .pd-sm-30 {
        padding: 30px;
    }

    .pd-sm-30-force {
        padding: 30px !important;
    }

    .pd-sm-y-30 {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .pd-sm-y-30-force {
        padding-top: 30px !important;
        padding-bottom: 30px !important;
    }

    .pd-sm-x-30 {
        padding-left: 30px;
        padding-right: 30px;
    }

    .pd-sm-x-30-force {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }

    .pd-sm-t-30 {
        padding-top: 30px;
    }

    .pd-sm-r-30 {
        padding-right: 30px;
    }

    .pd-sm-b-30 {
        padding-bottom: 30px;
    }

    .pd-sm-l-30 {
        padding-left: 30px;
    }

    .pd-sm-t-30-force {
        padding-top: 30px !important;
    }

    .pd-sm-r-30-force {
        padding-right: 30px !important;
    }

    .pd-sm-b-30-force {
        padding-bottom: 30px !important;
    }

    .pd-sm-l-30-force {
        padding-left: 30px !important;
    }

    .pd-sm-35 {
        padding: 35px;
    }

    .pd-sm-35-force {
        padding: 35px !important;
    }

    .pd-sm-y-35 {
        padding-top: 35px;
        padding-bottom: 35px;
    }

    .pd-sm-y-35-force {
        padding-top: 35px !important;
        padding-bottom: 35px !important;
    }

    .pd-sm-x-35 {
        padding-left: 35px;
        padding-right: 35px;
    }

    .pd-sm-x-35-force {
        padding-left: 35px !important;
        padding-right: 35px !important;
    }

    .pd-sm-t-35 {
        padding-top: 35px;
    }

    .pd-sm-r-35 {
        padding-right: 35px;
    }

    .pd-sm-b-35 {
        padding-bottom: 35px;
    }

    .pd-sm-l-35 {
        padding-left: 35px;
    }

    .pd-sm-t-35-force {
        padding-top: 35px !important;
    }

    .pd-sm-r-35-force {
        padding-right: 35px !important;
    }

    .pd-sm-b-35-force {
        padding-bottom: 35px !important;
    }

    .pd-sm-l-35-force {
        padding-left: 35px !important;
    }

    .pd-sm-40 {
        padding: 40px;
    }

    .pd-sm-40-force {
        padding: 40px !important;
    }

    .pd-sm-y-40 {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .pd-sm-y-40-force {
        padding-top: 40px !important;
        padding-bottom: 40px !important;
    }

    .pd-sm-x-40 {
        padding-left: 40px;
        padding-right: 40px;
    }

    .pd-sm-x-40-force {
        padding-left: 40px !important;
        padding-right: 40px !important;
    }

    .pd-sm-t-40 {
        padding-top: 40px;
    }

    .pd-sm-r-40 {
        padding-right: 40px;
    }

    .pd-sm-b-40 {
        padding-bottom: 40px;
    }

    .pd-sm-l-40 {
        padding-left: 40px;
    }

    .pd-sm-t-40-force {
        padding-top: 40px !important;
    }

    .pd-sm-r-40-force {
        padding-right: 40px !important;
    }

    .pd-sm-b-40-force {
        padding-bottom: 40px !important;
    }

    .pd-sm-l-40-force {
        padding-left: 40px !important;
    }

    .pd-sm-45 {
        padding: 45px;
    }

    .pd-sm-45-force {
        padding: 45px !important;
    }

    .pd-sm-y-45 {
        padding-top: 45px;
        padding-bottom: 45px;
    }

    .pd-sm-y-45-force {
        padding-top: 45px !important;
        padding-bottom: 45px !important;
    }

    .pd-sm-x-45 {
        padding-left: 45px;
        padding-right: 45px;
    }

    .pd-sm-x-45-force {
        padding-left: 45px !important;
        padding-right: 45px !important;
    }

    .pd-sm-t-45 {
        padding-top: 45px;
    }

    .pd-sm-r-45 {
        padding-right: 45px;
    }

    .pd-sm-b-45 {
        padding-bottom: 45px;
    }

    .pd-sm-l-45 {
        padding-left: 45px;
    }

    .pd-sm-t-45-force {
        padding-top: 45px !important;
    }

    .pd-sm-r-45-force {
        padding-right: 45px !important;
    }

    .pd-sm-b-45-force {
        padding-bottom: 45px !important;
    }

    .pd-sm-l-45-force {
        padding-left: 45px !important;
    }

    .pd-sm-50 {
        padding: 50px;
    }

    .pd-sm-50-force {
        padding: 50px !important;
    }

    .pd-sm-y-50 {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .pd-sm-y-50-force {
        padding-top: 50px !important;
        padding-bottom: 50px !important;
    }

    .pd-sm-x-50 {
        padding-left: 50px;
        padding-right: 50px;
    }

    .pd-sm-x-50-force {
        padding-left: 50px !important;
        padding-right: 50px !important;
    }

    .pd-sm-t-50 {
        padding-top: 50px;
    }

    .pd-sm-r-50 {
        padding-right: 50px;
    }

    .pd-sm-b-50 {
        padding-bottom: 50px;
    }

    .pd-sm-l-50 {
        padding-left: 50px;
    }

    .pd-sm-t-50-force {
        padding-top: 50px !important;
    }

    .pd-sm-r-50-force {
        padding-right: 50px !important;
    }

    .pd-sm-b-50-force {
        padding-bottom: 50px !important;
    }

    .pd-sm-l-50-force {
        padding-left: 50px !important;
    }

    .pd-sm-55 {
        padding: 55px;
    }

    .pd-sm-55-force {
        padding: 55px !important;
    }

    .pd-sm-y-55 {
        padding-top: 55px;
        padding-bottom: 55px;
    }

    .pd-sm-y-55-force {
        padding-top: 55px !important;
        padding-bottom: 55px !important;
    }

    .pd-sm-x-55 {
        padding-left: 55px;
        padding-right: 55px;
    }

    .pd-sm-x-55-force {
        padding-left: 55px !important;
        padding-right: 55px !important;
    }

    .pd-sm-t-55 {
        padding-top: 55px;
    }

    .pd-sm-r-55 {
        padding-right: 55px;
    }

    .pd-sm-b-55 {
        padding-bottom: 55px;
    }

    .pd-sm-l-55 {
        padding-left: 55px;
    }

    .pd-sm-t-55-force {
        padding-top: 55px !important;
    }

    .pd-sm-r-55-force {
        padding-right: 55px !important;
    }

    .pd-sm-b-55-force {
        padding-bottom: 55px !important;
    }

    .pd-sm-l-55-force {
        padding-left: 55px !important;
    }

    .pd-sm-60 {
        padding: 60px;
    }

    .pd-sm-60-force {
        padding: 60px !important;
    }

    .pd-sm-y-60 {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .pd-sm-y-60-force {
        padding-top: 60px !important;
        padding-bottom: 60px !important;
    }

    .pd-sm-x-60 {
        padding-left: 60px;
        padding-right: 60px;
    }

    .pd-sm-x-60-force {
        padding-left: 60px !important;
        padding-right: 60px !important;
    }

    .pd-sm-t-60 {
        padding-top: 60px;
    }

    .pd-sm-r-60 {
        padding-right: 60px;
    }

    .pd-sm-b-60 {
        padding-bottom: 60px;
    }

    .pd-sm-l-60 {
        padding-left: 60px;
    }

    .pd-sm-t-60-force {
        padding-top: 60px !important;
    }

    .pd-sm-r-60-force {
        padding-right: 60px !important;
    }

    .pd-sm-b-60-force {
        padding-bottom: 60px !important;
    }

    .pd-sm-l-60-force {
        padding-left: 60px !important;
    }

    .pd-sm-65 {
        padding: 65px;
    }

    .pd-sm-65-force {
        padding: 65px !important;
    }

    .pd-sm-y-65 {
        padding-top: 65px;
        padding-bottom: 65px;
    }

    .pd-sm-y-65-force {
        padding-top: 65px !important;
        padding-bottom: 65px !important;
    }

    .pd-sm-x-65 {
        padding-left: 65px;
        padding-right: 65px;
    }

    .pd-sm-x-65-force {
        padding-left: 65px !important;
        padding-right: 65px !important;
    }

    .pd-sm-t-65 {
        padding-top: 65px;
    }

    .pd-sm-r-65 {
        padding-right: 65px;
    }

    .pd-sm-b-65 {
        padding-bottom: 65px;
    }

    .pd-sm-l-65 {
        padding-left: 65px;
    }

    .pd-sm-t-65-force {
        padding-top: 65px !important;
    }

    .pd-sm-r-65-force {
        padding-right: 65px !important;
    }

    .pd-sm-b-65-force {
        padding-bottom: 65px !important;
    }

    .pd-sm-l-65-force {
        padding-left: 65px !important;
    }

    .pd-sm-70 {
        padding: 70px;
    }

    .pd-sm-70-force {
        padding: 70px !important;
    }

    .pd-sm-y-70 {
        padding-top: 70px;
        padding-bottom: 70px;
    }

    .pd-sm-y-70-force {
        padding-top: 70px !important;
        padding-bottom: 70px !important;
    }

    .pd-sm-x-70 {
        padding-left: 70px;
        padding-right: 70px;
    }

    .pd-sm-x-70-force {
        padding-left: 70px !important;
        padding-right: 70px !important;
    }

    .pd-sm-t-70 {
        padding-top: 70px;
    }

    .pd-sm-r-70 {
        padding-right: 70px;
    }

    .pd-sm-b-70 {
        padding-bottom: 70px;
    }

    .pd-sm-l-70 {
        padding-left: 70px;
    }

    .pd-sm-t-70-force {
        padding-top: 70px !important;
    }

    .pd-sm-r-70-force {
        padding-right: 70px !important;
    }

    .pd-sm-b-70-force {
        padding-bottom: 70px !important;
    }

    .pd-sm-l-70-force {
        padding-left: 70px !important;
    }

    .pd-sm-75 {
        padding: 75px;
    }

    .pd-sm-75-force {
        padding: 75px !important;
    }

    .pd-sm-y-75 {
        padding-top: 75px;
        padding-bottom: 75px;
    }

    .pd-sm-y-75-force {
        padding-top: 75px !important;
        padding-bottom: 75px !important;
    }

    .pd-sm-x-75 {
        padding-left: 75px;
        padding-right: 75px;
    }

    .pd-sm-x-75-force {
        padding-left: 75px !important;
        padding-right: 75px !important;
    }

    .pd-sm-t-75 {
        padding-top: 75px;
    }

    .pd-sm-r-75 {
        padding-right: 75px;
    }

    .pd-sm-b-75 {
        padding-bottom: 75px;
    }

    .pd-sm-l-75 {
        padding-left: 75px;
    }

    .pd-sm-t-75-force {
        padding-top: 75px !important;
    }

    .pd-sm-r-75-force {
        padding-right: 75px !important;
    }

    .pd-sm-b-75-force {
        padding-bottom: 75px !important;
    }

    .pd-sm-l-75-force {
        padding-left: 75px !important;
    }

    .pd-sm-80 {
        padding: 80px;
    }

    .pd-sm-80-force {
        padding: 80px !important;
    }

    .pd-sm-y-80 {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .pd-sm-y-80-force {
        padding-top: 80px !important;
        padding-bottom: 80px !important;
    }

    .pd-sm-x-80 {
        padding-left: 80px;
        padding-right: 80px;
    }

    .pd-sm-x-80-force {
        padding-left: 80px !important;
        padding-right: 80px !important;
    }

    .pd-sm-t-80 {
        padding-top: 80px;
    }

    .pd-sm-r-80 {
        padding-right: 80px;
    }

    .pd-sm-b-80 {
        padding-bottom: 80px;
    }

    .pd-sm-l-80 {
        padding-left: 80px;
    }

    .pd-sm-t-80-force {
        padding-top: 80px !important;
    }

    .pd-sm-r-80-force {
        padding-right: 80px !important;
    }

    .pd-sm-b-80-force {
        padding-bottom: 80px !important;
    }

    .pd-sm-l-80-force {
        padding-left: 80px !important;
    }

    .pd-sm-85 {
        padding: 85px;
    }

    .pd-sm-85-force {
        padding: 85px !important;
    }

    .pd-sm-y-85 {
        padding-top: 85px;
        padding-bottom: 85px;
    }

    .pd-sm-y-85-force {
        padding-top: 85px !important;
        padding-bottom: 85px !important;
    }

    .pd-sm-x-85 {
        padding-left: 85px;
        padding-right: 85px;
    }

    .pd-sm-x-85-force {
        padding-left: 85px !important;
        padding-right: 85px !important;
    }

    .pd-sm-t-85 {
        padding-top: 85px;
    }

    .pd-sm-r-85 {
        padding-right: 85px;
    }

    .pd-sm-b-85 {
        padding-bottom: 85px;
    }

    .pd-sm-l-85 {
        padding-left: 85px;
    }

    .pd-sm-t-85-force {
        padding-top: 85px !important;
    }

    .pd-sm-r-85-force {
        padding-right: 85px !important;
    }

    .pd-sm-b-85-force {
        padding-bottom: 85px !important;
    }

    .pd-sm-l-85-force {
        padding-left: 85px !important;
    }

    .pd-sm-90 {
        padding: 90px;
    }

    .pd-sm-90-force {
        padding: 90px !important;
    }

    .pd-sm-y-90 {
        padding-top: 90px;
        padding-bottom: 90px;
    }

    .pd-sm-y-90-force {
        padding-top: 90px !important;
        padding-bottom: 90px !important;
    }

    .pd-sm-x-90 {
        padding-left: 90px;
        padding-right: 90px;
    }

    .pd-sm-x-90-force {
        padding-left: 90px !important;
        padding-right: 90px !important;
    }

    .pd-sm-t-90 {
        padding-top: 90px;
    }

    .pd-sm-r-90 {
        padding-right: 90px;
    }

    .pd-sm-b-90 {
        padding-bottom: 90px;
    }

    .pd-sm-l-90 {
        padding-left: 90px;
    }

    .pd-sm-t-90-force {
        padding-top: 90px !important;
    }

    .pd-sm-r-90-force {
        padding-right: 90px !important;
    }

    .pd-sm-b-90-force {
        padding-bottom: 90px !important;
    }

    .pd-sm-l-90-force {
        padding-left: 90px !important;
    }

    .pd-sm-95 {
        padding: 95px;
    }

    .pd-sm-95-force {
        padding: 95px !important;
    }

    .pd-sm-y-95 {
        padding-top: 95px;
        padding-bottom: 95px;
    }

    .pd-sm-y-95-force {
        padding-top: 95px !important;
        padding-bottom: 95px !important;
    }

    .pd-sm-x-95 {
        padding-left: 95px;
        padding-right: 95px;
    }

    .pd-sm-x-95-force {
        padding-left: 95px !important;
        padding-right: 95px !important;
    }

    .pd-sm-t-95 {
        padding-top: 95px;
    }

    .pd-sm-r-95 {
        padding-right: 95px;
    }

    .pd-sm-b-95 {
        padding-bottom: 95px;
    }

    .pd-sm-l-95 {
        padding-left: 95px;
    }

    .pd-sm-t-95-force {
        padding-top: 95px !important;
    }

    .pd-sm-r-95-force {
        padding-right: 95px !important;
    }

    .pd-sm-b-95-force {
        padding-bottom: 95px !important;
    }

    .pd-sm-l-95-force {
        padding-left: 95px !important;
    }

    .pd-sm-100 {
        padding: 100px;
    }

    .pd-sm-100-force {
        padding: 100px !important;
    }

    .pd-sm-y-100 {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .pd-sm-y-100-force {
        padding-top: 100px !important;
        padding-bottom: 100px !important;
    }

    .pd-sm-x-100 {
        padding-left: 100px;
        padding-right: 100px;
    }

    .pd-sm-x-100-force {
        padding-left: 100px !important;
        padding-right: 100px !important;
    }

    .pd-sm-t-100 {
        padding-top: 100px;
    }

    .pd-sm-r-100 {
        padding-right: 100px;
    }

    .pd-sm-b-100 {
        padding-bottom: 100px;
    }

    .pd-sm-l-100 {
        padding-left: 100px;
    }

    .pd-sm-t-100-force {
        padding-top: 100px !important;
    }

    .pd-sm-r-100-force {
        padding-right: 100px !important;
    }

    .pd-sm-b-100-force {
        padding-bottom: 100px !important;
    }

    .pd-sm-l-100-force {
        padding-left: 100px !important;
    }

    .pd-sm-110 {
        padding: 110px;
    }

    .pd-sm-110-force {
        padding: 110px !important;
    }

    .pd-sm-y-110 {
        padding-top: 110px;
        padding-bottom: 110px;
    }

    .pd-sm-y-110-force {
        padding-top: 110px !important;
        padding-bottom: 110px !important;
    }

    .pd-sm-x-110 {
        padding-left: 110px;
        padding-right: 110px;
    }

    .pd-sm-x-110-force {
        padding-left: 110px !important;
        padding-right: 110px !important;
    }

    .pd-sm-t-110 {
        padding-top: 110px;
    }

    .pd-sm-r-110 {
        padding-right: 110px;
    }

    .pd-sm-b-110 {
        padding-bottom: 110px;
    }

    .pd-sm-l-110 {
        padding-left: 110px;
    }

    .pd-sm-t-110-force {
        padding-top: 110px !important;
    }

    .pd-sm-r-110-force {
        padding-right: 110px !important;
    }

    .pd-sm-b-110-force {
        padding-bottom: 110px !important;
    }

    .pd-sm-l-110-force {
        padding-left: 110px !important;
    }

    .pd-sm-120 {
        padding: 120px;
    }

    .pd-sm-120-force {
        padding: 120px !important;
    }

    .pd-sm-y-120 {
        padding-top: 120px;
        padding-bottom: 120px;
    }

    .pd-sm-y-120-force {
        padding-top: 120px !important;
        padding-bottom: 120px !important;
    }

    .pd-sm-x-120 {
        padding-left: 120px;
        padding-right: 120px;
    }

    .pd-sm-x-120-force {
        padding-left: 120px !important;
        padding-right: 120px !important;
    }

    .pd-sm-t-120 {
        padding-top: 120px;
    }

    .pd-sm-r-120 {
        padding-right: 120px;
    }

    .pd-sm-b-120 {
        padding-bottom: 120px;
    }

    .pd-sm-l-120 {
        padding-left: 120px;
    }

    .pd-sm-t-120-force {
        padding-top: 120px !important;
    }

    .pd-sm-r-120-force {
        padding-right: 120px !important;
    }

    .pd-sm-b-120-force {
        padding-bottom: 120px !important;
    }

    .pd-sm-l-120-force {
        padding-left: 120px !important;
    }

    .pd-sm-130 {
        padding: 130px;
    }

    .pd-sm-130-force {
        padding: 130px !important;
    }

    .pd-sm-y-130 {
        padding-top: 130px;
        padding-bottom: 130px;
    }

    .pd-sm-y-130-force {
        padding-top: 130px !important;
        padding-bottom: 130px !important;
    }

    .pd-sm-x-130 {
        padding-left: 130px;
        padding-right: 130px;
    }

    .pd-sm-x-130-force {
        padding-left: 130px !important;
        padding-right: 130px !important;
    }

    .pd-sm-t-130 {
        padding-top: 130px;
    }

    .pd-sm-r-130 {
        padding-right: 130px;
    }

    .pd-sm-b-130 {
        padding-bottom: 130px;
    }

    .pd-sm-l-130 {
        padding-left: 130px;
    }

    .pd-sm-t-130-force {
        padding-top: 130px !important;
    }

    .pd-sm-r-130-force {
        padding-right: 130px !important;
    }

    .pd-sm-b-130-force {
        padding-bottom: 130px !important;
    }

    .pd-sm-l-130-force {
        padding-left: 130px !important;
    }

    .pd-sm-140 {
        padding: 140px;
    }

    .pd-sm-140-force {
        padding: 140px !important;
    }

    .pd-sm-y-140 {
        padding-top: 140px;
        padding-bottom: 140px;
    }

    .pd-sm-y-140-force {
        padding-top: 140px !important;
        padding-bottom: 140px !important;
    }

    .pd-sm-x-140 {
        padding-left: 140px;
        padding-right: 140px;
    }

    .pd-sm-x-140-force {
        padding-left: 140px !important;
        padding-right: 140px !important;
    }

    .pd-sm-t-140 {
        padding-top: 140px;
    }

    .pd-sm-r-140 {
        padding-right: 140px;
    }

    .pd-sm-b-140 {
        padding-bottom: 140px;
    }

    .pd-sm-l-140 {
        padding-left: 140px;
    }

    .pd-sm-t-140-force {
        padding-top: 140px !important;
    }

    .pd-sm-r-140-force {
        padding-right: 140px !important;
    }

    .pd-sm-b-140-force {
        padding-bottom: 140px !important;
    }

    .pd-sm-l-140-force {
        padding-left: 140px !important;
    }

    .pd-sm-150 {
        padding: 150px;
    }

    .pd-sm-150-force {
        padding: 150px !important;
    }

    .pd-sm-y-150 {
        padding-top: 150px;
        padding-bottom: 150px;
    }

    .pd-sm-y-150-force {
        padding-top: 150px !important;
        padding-bottom: 150px !important;
    }

    .pd-sm-x-150 {
        padding-left: 150px;
        padding-right: 150px;
    }

    .pd-sm-x-150-force {
        padding-left: 150px !important;
        padding-right: 150px !important;
    }

    .pd-sm-t-150 {
        padding-top: 150px;
    }

    .pd-sm-r-150 {
        padding-right: 150px;
    }

    .pd-sm-b-150 {
        padding-bottom: 150px;
    }

    .pd-sm-l-150 {
        padding-left: 150px;
    }

    .pd-sm-t-150-force {
        padding-top: 150px !important;
    }

    .pd-sm-r-150-force {
        padding-right: 150px !important;
    }

    .pd-sm-b-150-force {
        padding-bottom: 150px !important;
    }

    .pd-sm-l-150-force {
        padding-left: 150px !important;
    }

    .pd-sm-160 {
        padding: 160px;
    }

    .pd-sm-160-force {
        padding: 160px !important;
    }

    .pd-sm-y-160 {
        padding-top: 160px;
        padding-bottom: 160px;
    }

    .pd-sm-y-160-force {
        padding-top: 160px !important;
        padding-bottom: 160px !important;
    }

    .pd-sm-x-160 {
        padding-left: 160px;
        padding-right: 160px;
    }

    .pd-sm-x-160-force {
        padding-left: 160px !important;
        padding-right: 160px !important;
    }

    .pd-sm-t-160 {
        padding-top: 160px;
    }

    .pd-sm-r-160 {
        padding-right: 160px;
    }

    .pd-sm-b-160 {
        padding-bottom: 160px;
    }

    .pd-sm-l-160 {
        padding-left: 160px;
    }

    .pd-sm-t-160-force {
        padding-top: 160px !important;
    }

    .pd-sm-r-160-force {
        padding-right: 160px !important;
    }

    .pd-sm-b-160-force {
        padding-bottom: 160px !important;
    }

    .pd-sm-l-160-force {
        padding-left: 160px !important;
    }

    .pd-sm-170 {
        padding: 170px;
    }

    .pd-sm-170-force {
        padding: 170px !important;
    }

    .pd-sm-y-170 {
        padding-top: 170px;
        padding-bottom: 170px;
    }

    .pd-sm-y-170-force {
        padding-top: 170px !important;
        padding-bottom: 170px !important;
    }

    .pd-sm-x-170 {
        padding-left: 170px;
        padding-right: 170px;
    }

    .pd-sm-x-170-force {
        padding-left: 170px !important;
        padding-right: 170px !important;
    }

    .pd-sm-t-170 {
        padding-top: 170px;
    }

    .pd-sm-r-170 {
        padding-right: 170px;
    }

    .pd-sm-b-170 {
        padding-bottom: 170px;
    }

    .pd-sm-l-170 {
        padding-left: 170px;
    }

    .pd-sm-t-170-force {
        padding-top: 170px !important;
    }

    .pd-sm-r-170-force {
        padding-right: 170px !important;
    }

    .pd-sm-b-170-force {
        padding-bottom: 170px !important;
    }

    .pd-sm-l-170-force {
        padding-left: 170px !important;
    }

    .pd-sm-180 {
        padding: 180px;
    }

    .pd-sm-180-force {
        padding: 180px !important;
    }

    .pd-sm-y-180 {
        padding-top: 180px;
        padding-bottom: 180px;
    }

    .pd-sm-y-180-force {
        padding-top: 180px !important;
        padding-bottom: 180px !important;
    }

    .pd-sm-x-180 {
        padding-left: 180px;
        padding-right: 180px;
    }

    .pd-sm-x-180-force {
        padding-left: 180px !important;
        padding-right: 180px !important;
    }

    .pd-sm-t-180 {
        padding-top: 180px;
    }

    .pd-sm-r-180 {
        padding-right: 180px;
    }

    .pd-sm-b-180 {
        padding-bottom: 180px;
    }

    .pd-sm-l-180 {
        padding-left: 180px;
    }

    .pd-sm-t-180-force {
        padding-top: 180px !important;
    }

    .pd-sm-r-180-force {
        padding-right: 180px !important;
    }

    .pd-sm-b-180-force {
        padding-bottom: 180px !important;
    }

    .pd-sm-l-180-force {
        padding-left: 180px !important;
    }

    .pd-sm-190 {
        padding: 190px;
    }

    .pd-sm-190-force {
        padding: 190px !important;
    }

    .pd-sm-y-190 {
        padding-top: 190px;
        padding-bottom: 190px;
    }

    .pd-sm-y-190-force {
        padding-top: 190px !important;
        padding-bottom: 190px !important;
    }

    .pd-sm-x-190 {
        padding-left: 190px;
        padding-right: 190px;
    }

    .pd-sm-x-190-force {
        padding-left: 190px !important;
        padding-right: 190px !important;
    }

    .pd-sm-t-190 {
        padding-top: 190px;
    }

    .pd-sm-r-190 {
        padding-right: 190px;
    }

    .pd-sm-b-190 {
        padding-bottom: 190px;
    }

    .pd-sm-l-190 {
        padding-left: 190px;
    }

    .pd-sm-t-190-force {
        padding-top: 190px !important;
    }

    .pd-sm-r-190-force {
        padding-right: 190px !important;
    }

    .pd-sm-b-190-force {
        padding-bottom: 190px !important;
    }

    .pd-sm-l-190-force {
        padding-left: 190px !important;
    }

    .pd-sm-200 {
        padding: 200px;
    }

    .pd-sm-200-force {
        padding: 200px !important;
    }

    .pd-sm-y-200 {
        padding-top: 200px;
        padding-bottom: 200px;
    }

    .pd-sm-y-200-force {
        padding-top: 200px !important;
        padding-bottom: 200px !important;
    }

    .pd-sm-x-200 {
        padding-left: 200px;
        padding-right: 200px;
    }

    .pd-sm-x-200-force {
        padding-left: 200px !important;
        padding-right: 200px !important;
    }

    .pd-sm-t-200 {
        padding-top: 200px;
    }

    .pd-sm-r-200 {
        padding-right: 200px;
    }

    .pd-sm-b-200 {
        padding-bottom: 200px;
    }

    .pd-sm-l-200 {
        padding-left: 200px;
    }

    .pd-sm-t-200-force {
        padding-top: 200px !important;
    }

    .pd-sm-r-200-force {
        padding-right: 200px !important;
    }

    .pd-sm-b-200-force {
        padding-bottom: 200px !important;
    }

    .pd-sm-l-200-force {
        padding-left: 200px !important;
    }
}

@media (min-width: 768px) {
    .pd-md-0 {
        padding: 0px;
    }

    .pd-md-0-force {
        padding: 0px !important;
    }

    .pd-md-y-0 {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .pd-md-y-0-force {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .pd-md-x-0 {
        padding-left: 0px;
        padding-right: 0px;
    }

    .pd-md-x-0-force {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .pd-md-t-0 {
        padding-top: 0px;
    }

    .pd-md-r-0 {
        padding-right: 0px;
    }

    .pd-md-b-0 {
        padding-bottom: 0px;
    }

    .pd-md-l-0 {
        padding-left: 0px;
    }

    .pd-md-t-0-force {
        padding-top: 0px !important;
    }

    .pd-md-r-0-force {
        padding-right: 0px !important;
    }

    .pd-md-b-0-force {
        padding-bottom: 0px !important;
    }

    .pd-md-l-0-force {
        padding-left: 0px !important;
    }

    .pd-md-1 {
        padding: 1px;
    }

    .pd-md-1-force {
        padding: 1px !important;
    }

    .pd-md-y-1 {
        padding-top: 1px;
        padding-bottom: 1px;
    }

    .pd-md-y-1-force {
        padding-top: 1px !important;
        padding-bottom: 1px !important;
    }

    .pd-md-x-1 {
        padding-left: 1px;
        padding-right: 1px;
    }

    .pd-md-x-1-force {
        padding-left: 1px !important;
        padding-right: 1px !important;
    }

    .pd-md-t-1 {
        padding-top: 1px;
    }

    .pd-md-r-1 {
        padding-right: 1px;
    }

    .pd-md-b-1 {
        padding-bottom: 1px;
    }

    .pd-md-l-1 {
        padding-left: 1px;
    }

    .pd-md-t-1-force {
        padding-top: 1px !important;
    }

    .pd-md-r-1-force {
        padding-right: 1px !important;
    }

    .pd-md-b-1-force {
        padding-bottom: 1px !important;
    }

    .pd-md-l-1-force {
        padding-left: 1px !important;
    }

    .pd-md-2 {
        padding: 2px;
    }

    .pd-md-2-force {
        padding: 2px !important;
    }

    .pd-md-y-2 {
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .pd-md-y-2-force {
        padding-top: 2px !important;
        padding-bottom: 2px !important;
    }

    .pd-md-x-2 {
        padding-left: 2px;
        padding-right: 2px;
    }

    .pd-md-x-2-force {
        padding-left: 2px !important;
        padding-right: 2px !important;
    }

    .pd-md-t-2 {
        padding-top: 2px;
    }

    .pd-md-r-2 {
        padding-right: 2px;
    }

    .pd-md-b-2 {
        padding-bottom: 2px;
    }

    .pd-md-l-2 {
        padding-left: 2px;
    }

    .pd-md-t-2-force {
        padding-top: 2px !important;
    }

    .pd-md-r-2-force {
        padding-right: 2px !important;
    }

    .pd-md-b-2-force {
        padding-bottom: 2px !important;
    }

    .pd-md-l-2-force {
        padding-left: 2px !important;
    }

    .pd-md-3 {
        padding: 3px;
    }

    .pd-md-3-force {
        padding: 3px !important;
    }

    .pd-md-y-3 {
        padding-top: 3px;
        padding-bottom: 3px;
    }

    .pd-md-y-3-force {
        padding-top: 3px !important;
        padding-bottom: 3px !important;
    }

    .pd-md-x-3 {
        padding-left: 3px;
        padding-right: 3px;
    }

    .pd-md-x-3-force {
        padding-left: 3px !important;
        padding-right: 3px !important;
    }

    .pd-md-t-3 {
        padding-top: 3px;
    }

    .pd-md-r-3 {
        padding-right: 3px;
    }

    .pd-md-b-3 {
        padding-bottom: 3px;
    }

    .pd-md-l-3 {
        padding-left: 3px;
    }

    .pd-md-t-3-force {
        padding-top: 3px !important;
    }

    .pd-md-r-3-force {
        padding-right: 3px !important;
    }

    .pd-md-b-3-force {
        padding-bottom: 3px !important;
    }

    .pd-md-l-3-force {
        padding-left: 3px !important;
    }

    .pd-md-4 {
        padding: 4px;
    }

    .pd-md-4-force {
        padding: 4px !important;
    }

    .pd-md-y-4 {
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .pd-md-y-4-force {
        padding-top: 4px !important;
        padding-bottom: 4px !important;
    }

    .pd-md-x-4 {
        padding-left: 4px;
        padding-right: 4px;
    }

    .pd-md-x-4-force {
        padding-left: 4px !important;
        padding-right: 4px !important;
    }

    .pd-md-t-4 {
        padding-top: 4px;
    }

    .pd-md-r-4 {
        padding-right: 4px;
    }

    .pd-md-b-4 {
        padding-bottom: 4px;
    }

    .pd-md-l-4 {
        padding-left: 4px;
    }

    .pd-md-t-4-force {
        padding-top: 4px !important;
    }

    .pd-md-r-4-force {
        padding-right: 4px !important;
    }

    .pd-md-b-4-force {
        padding-bottom: 4px !important;
    }

    .pd-md-l-4-force {
        padding-left: 4px !important;
    }

    .pd-md-5 {
        padding: 5px;
    }

    .pd-md-5-force {
        padding: 5px !important;
    }

    .pd-md-y-5 {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .pd-md-y-5-force {
        padding-top: 5px !important;
        padding-bottom: 5px !important;
    }

    .pd-md-x-5 {
        padding-left: 5px;
        padding-right: 5px;
    }

    .pd-md-x-5-force {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }

    .pd-md-t-5 {
        padding-top: 5px;
    }

    .pd-md-r-5 {
        padding-right: 5px;
    }

    .pd-md-b-5 {
        padding-bottom: 5px;
    }

    .pd-md-l-5 {
        padding-left: 5px;
    }

    .pd-md-t-5-force {
        padding-top: 5px !important;
    }

    .pd-md-r-5-force {
        padding-right: 5px !important;
    }

    .pd-md-b-5-force {
        padding-bottom: 5px !important;
    }

    .pd-md-l-5-force {
        padding-left: 5px !important;
    }

    .pd-md-6 {
        padding: 6px;
    }

    .pd-md-6-force {
        padding: 6px !important;
    }

    .pd-md-y-6 {
        padding-top: 6px;
        padding-bottom: 6px;
    }

    .pd-md-y-6-force {
        padding-top: 6px !important;
        padding-bottom: 6px !important;
    }

    .pd-md-x-6 {
        padding-left: 6px;
        padding-right: 6px;
    }

    .pd-md-x-6-force {
        padding-left: 6px !important;
        padding-right: 6px !important;
    }

    .pd-md-t-6 {
        padding-top: 6px;
    }

    .pd-md-r-6 {
        padding-right: 6px;
    }

    .pd-md-b-6 {
        padding-bottom: 6px;
    }

    .pd-md-l-6 {
        padding-left: 6px;
    }

    .pd-md-t-6-force {
        padding-top: 6px !important;
    }

    .pd-md-r-6-force {
        padding-right: 6px !important;
    }

    .pd-md-b-6-force {
        padding-bottom: 6px !important;
    }

    .pd-md-l-6-force {
        padding-left: 6px !important;
    }

    .pd-md-7 {
        padding: 7px;
    }

    .pd-md-7-force {
        padding: 7px !important;
    }

    .pd-md-y-7 {
        padding-top: 7px;
        padding-bottom: 7px;
    }

    .pd-md-y-7-force {
        padding-top: 7px !important;
        padding-bottom: 7px !important;
    }

    .pd-md-x-7 {
        padding-left: 7px;
        padding-right: 7px;
    }

    .pd-md-x-7-force {
        padding-left: 7px !important;
        padding-right: 7px !important;
    }

    .pd-md-t-7 {
        padding-top: 7px;
    }

    .pd-md-r-7 {
        padding-right: 7px;
    }

    .pd-md-b-7 {
        padding-bottom: 7px;
    }

    .pd-md-l-7 {
        padding-left: 7px;
    }

    .pd-md-t-7-force {
        padding-top: 7px !important;
    }

    .pd-md-r-7-force {
        padding-right: 7px !important;
    }

    .pd-md-b-7-force {
        padding-bottom: 7px !important;
    }

    .pd-md-l-7-force {
        padding-left: 7px !important;
    }

    .pd-md-8 {
        padding: 8px;
    }

    .pd-md-8-force {
        padding: 8px !important;
    }

    .pd-md-y-8 {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .pd-md-y-8-force {
        padding-top: 8px !important;
        padding-bottom: 8px !important;
    }

    .pd-md-x-8 {
        padding-left: 8px;
        padding-right: 8px;
    }

    .pd-md-x-8-force {
        padding-left: 8px !important;
        padding-right: 8px !important;
    }

    .pd-md-t-8 {
        padding-top: 8px;
    }

    .pd-md-r-8 {
        padding-right: 8px;
    }

    .pd-md-b-8 {
        padding-bottom: 8px;
    }

    .pd-md-l-8 {
        padding-left: 8px;
    }

    .pd-md-t-8-force {
        padding-top: 8px !important;
    }

    .pd-md-r-8-force {
        padding-right: 8px !important;
    }

    .pd-md-b-8-force {
        padding-bottom: 8px !important;
    }

    .pd-md-l-8-force {
        padding-left: 8px !important;
    }

    .pd-md-9 {
        padding: 9px;
    }

    .pd-md-9-force {
        padding: 9px !important;
    }

    .pd-md-y-9 {
        padding-top: 9px;
        padding-bottom: 9px;
    }

    .pd-md-y-9-force {
        padding-top: 9px !important;
        padding-bottom: 9px !important;
    }

    .pd-md-x-9 {
        padding-left: 9px;
        padding-right: 9px;
    }

    .pd-md-x-9-force {
        padding-left: 9px !important;
        padding-right: 9px !important;
    }

    .pd-md-t-9 {
        padding-top: 9px;
    }

    .pd-md-r-9 {
        padding-right: 9px;
    }

    .pd-md-b-9 {
        padding-bottom: 9px;
    }

    .pd-md-l-9 {
        padding-left: 9px;
    }

    .pd-md-t-9-force {
        padding-top: 9px !important;
    }

    .pd-md-r-9-force {
        padding-right: 9px !important;
    }

    .pd-md-b-9-force {
        padding-bottom: 9px !important;
    }

    .pd-md-l-9-force {
        padding-left: 9px !important;
    }

    .pd-md-10 {
        padding: 10px;
    }

    .pd-md-10-force {
        padding: 10px !important;
    }

    .pd-md-y-10 {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .pd-md-y-10-force {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }

    .pd-md-x-10 {
        padding-left: 10px;
        padding-right: 10px;
    }

    .pd-md-x-10-force {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .pd-md-t-10 {
        padding-top: 10px;
    }

    .pd-md-r-10 {
        padding-right: 10px;
    }

    .pd-md-b-10 {
        padding-bottom: 10px;
    }

    .pd-md-l-10 {
        padding-left: 10px;
    }

    .pd-md-t-10-force {
        padding-top: 10px !important;
    }

    .pd-md-r-10-force {
        padding-right: 10px !important;
    }

    .pd-md-b-10-force {
        padding-bottom: 10px !important;
    }

    .pd-md-l-10-force {
        padding-left: 10px !important;
    }

    .pd-md-15 {
        padding: 15px;
    }

    .pd-md-15-force {
        padding: 15px !important;
    }

    .pd-md-y-15 {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .pd-md-y-15-force {
        padding-top: 15px !important;
        padding-bottom: 15px !important;
    }

    .pd-md-x-15 {
        padding-left: 15px;
        padding-right: 15px;
    }

    .pd-md-x-15-force {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .pd-md-t-15 {
        padding-top: 15px;
    }

    .pd-md-r-15 {
        padding-right: 15px;
    }

    .pd-md-b-15 {
        padding-bottom: 15px;
    }

    .pd-md-l-15 {
        padding-left: 15px;
    }

    .pd-md-t-15-force {
        padding-top: 15px !important;
    }

    .pd-md-r-15-force {
        padding-right: 15px !important;
    }

    .pd-md-b-15-force {
        padding-bottom: 15px !important;
    }

    .pd-md-l-15-force {
        padding-left: 15px !important;
    }

    .pd-md-20 {
        padding: 20px;
    }

    .pd-md-20-force {
        padding: 20px !important;
    }

    .pd-md-y-20 {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .pd-md-y-20-force {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }

    .pd-md-x-20 {
        padding-left: 20px;
        padding-right: 20px;
    }

    .pd-md-x-20-force {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .pd-md-t-20 {
        padding-top: 20px;
    }

    .pd-md-r-20 {
        padding-right: 20px;
    }

    .pd-md-b-20 {
        padding-bottom: 20px;
    }

    .pd-md-l-20 {
        padding-left: 20px;
    }

    .pd-md-t-20-force {
        padding-top: 20px !important;
    }

    .pd-md-r-20-force {
        padding-right: 20px !important;
    }

    .pd-md-b-20-force {
        padding-bottom: 20px !important;
    }

    .pd-md-l-20-force {
        padding-left: 20px !important;
    }

    .pd-md-25 {
        padding: 25px;
    }

    .pd-md-25-force {
        padding: 25px !important;
    }

    .pd-md-y-25 {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .pd-md-y-25-force {
        padding-top: 25px !important;
        padding-bottom: 25px !important;
    }

    .pd-md-x-25 {
        padding-left: 25px;
        padding-right: 25px;
    }

    .pd-md-x-25-force {
        padding-left: 25px !important;
        padding-right: 25px !important;
    }

    .pd-md-t-25 {
        padding-top: 25px;
    }

    .pd-md-r-25 {
        padding-right: 25px;
    }

    .pd-md-b-25 {
        padding-bottom: 25px;
    }

    .pd-md-l-25 {
        padding-left: 25px;
    }

    .pd-md-t-25-force {
        padding-top: 25px !important;
    }

    .pd-md-r-25-force {
        padding-right: 25px !important;
    }

    .pd-md-b-25-force {
        padding-bottom: 25px !important;
    }

    .pd-md-l-25-force {
        padding-left: 25px !important;
    }

    .pd-md-30 {
        padding: 30px;
    }

    .pd-md-30-force {
        padding: 30px !important;
    }

    .pd-md-y-30 {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .pd-md-y-30-force {
        padding-top: 30px !important;
        padding-bottom: 30px !important;
    }

    .pd-md-x-30 {
        padding-left: 30px;
        padding-right: 30px;
    }

    .pd-md-x-30-force {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }

    .pd-md-t-30 {
        padding-top: 30px;
    }

    .pd-md-r-30 {
        padding-right: 30px;
    }

    .pd-md-b-30 {
        padding-bottom: 30px;
    }

    .pd-md-l-30 {
        padding-left: 30px;
    }

    .pd-md-t-30-force {
        padding-top: 30px !important;
    }

    .pd-md-r-30-force {
        padding-right: 30px !important;
    }

    .pd-md-b-30-force {
        padding-bottom: 30px !important;
    }

    .pd-md-l-30-force {
        padding-left: 30px !important;
    }

    .pd-md-35 {
        padding: 35px;
    }

    .pd-md-35-force {
        padding: 35px !important;
    }

    .pd-md-y-35 {
        padding-top: 35px;
        padding-bottom: 35px;
    }

    .pd-md-y-35-force {
        padding-top: 35px !important;
        padding-bottom: 35px !important;
    }

    .pd-md-x-35 {
        padding-left: 35px;
        padding-right: 35px;
    }

    .pd-md-x-35-force {
        padding-left: 35px !important;
        padding-right: 35px !important;
    }

    .pd-md-t-35 {
        padding-top: 35px;
    }

    .pd-md-r-35 {
        padding-right: 35px;
    }

    .pd-md-b-35 {
        padding-bottom: 35px;
    }

    .pd-md-l-35 {
        padding-left: 35px;
    }

    .pd-md-t-35-force {
        padding-top: 35px !important;
    }

    .pd-md-r-35-force {
        padding-right: 35px !important;
    }

    .pd-md-b-35-force {
        padding-bottom: 35px !important;
    }

    .pd-md-l-35-force {
        padding-left: 35px !important;
    }

    .pd-md-40 {
        padding: 40px;
    }

    .pd-md-40-force {
        padding: 40px !important;
    }

    .pd-md-y-40 {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .pd-md-y-40-force {
        padding-top: 40px !important;
        padding-bottom: 40px !important;
    }

    .pd-md-x-40 {
        padding-left: 40px;
        padding-right: 40px;
    }

    .pd-md-x-40-force {
        padding-left: 40px !important;
        padding-right: 40px !important;
    }

    .pd-md-t-40 {
        padding-top: 40px;
    }

    .pd-md-r-40 {
        padding-right: 40px;
    }

    .pd-md-b-40 {
        padding-bottom: 40px;
    }

    .pd-md-l-40 {
        padding-left: 40px;
    }

    .pd-md-t-40-force {
        padding-top: 40px !important;
    }

    .pd-md-r-40-force {
        padding-right: 40px !important;
    }

    .pd-md-b-40-force {
        padding-bottom: 40px !important;
    }

    .pd-md-l-40-force {
        padding-left: 40px !important;
    }

    .pd-md-45 {
        padding: 45px;
    }

    .pd-md-45-force {
        padding: 45px !important;
    }

    .pd-md-y-45 {
        padding-top: 45px;
        padding-bottom: 45px;
    }

    .pd-md-y-45-force {
        padding-top: 45px !important;
        padding-bottom: 45px !important;
    }

    .pd-md-x-45 {
        padding-left: 45px;
        padding-right: 45px;
    }

    .pd-md-x-45-force {
        padding-left: 45px !important;
        padding-right: 45px !important;
    }

    .pd-md-t-45 {
        padding-top: 45px;
    }

    .pd-md-r-45 {
        padding-right: 45px;
    }

    .pd-md-b-45 {
        padding-bottom: 45px;
    }

    .pd-md-l-45 {
        padding-left: 45px;
    }

    .pd-md-t-45-force {
        padding-top: 45px !important;
    }

    .pd-md-r-45-force {
        padding-right: 45px !important;
    }

    .pd-md-b-45-force {
        padding-bottom: 45px !important;
    }

    .pd-md-l-45-force {
        padding-left: 45px !important;
    }

    .pd-md-50 {
        padding: 50px;
    }

    .pd-md-50-force {
        padding: 50px !important;
    }

    .pd-md-y-50 {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .pd-md-y-50-force {
        padding-top: 50px !important;
        padding-bottom: 50px !important;
    }

    .pd-md-x-50 {
        padding-left: 50px;
        padding-right: 50px;
    }

    .pd-md-x-50-force {
        padding-left: 50px !important;
        padding-right: 50px !important;
    }

    .pd-md-t-50 {
        padding-top: 50px;
    }

    .pd-md-r-50 {
        padding-right: 50px;
    }

    .pd-md-b-50 {
        padding-bottom: 50px;
    }

    .pd-md-l-50 {
        padding-left: 50px;
    }

    .pd-md-t-50-force {
        padding-top: 50px !important;
    }

    .pd-md-r-50-force {
        padding-right: 50px !important;
    }

    .pd-md-b-50-force {
        padding-bottom: 50px !important;
    }

    .pd-md-l-50-force {
        padding-left: 50px !important;
    }

    .pd-md-55 {
        padding: 55px;
    }

    .pd-md-55-force {
        padding: 55px !important;
    }

    .pd-md-y-55 {
        padding-top: 55px;
        padding-bottom: 55px;
    }

    .pd-md-y-55-force {
        padding-top: 55px !important;
        padding-bottom: 55px !important;
    }

    .pd-md-x-55 {
        padding-left: 55px;
        padding-right: 55px;
    }

    .pd-md-x-55-force {
        padding-left: 55px !important;
        padding-right: 55px !important;
    }

    .pd-md-t-55 {
        padding-top: 55px;
    }

    .pd-md-r-55 {
        padding-right: 55px;
    }

    .pd-md-b-55 {
        padding-bottom: 55px;
    }

    .pd-md-l-55 {
        padding-left: 55px;
    }

    .pd-md-t-55-force {
        padding-top: 55px !important;
    }

    .pd-md-r-55-force {
        padding-right: 55px !important;
    }

    .pd-md-b-55-force {
        padding-bottom: 55px !important;
    }

    .pd-md-l-55-force {
        padding-left: 55px !important;
    }

    .pd-md-60 {
        padding: 60px;
    }

    .pd-md-60-force {
        padding: 60px !important;
    }

    .pd-md-y-60 {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .pd-md-y-60-force {
        padding-top: 60px !important;
        padding-bottom: 60px !important;
    }

    .pd-md-x-60 {
        padding-left: 60px;
        padding-right: 60px;
    }

    .pd-md-x-60-force {
        padding-left: 60px !important;
        padding-right: 60px !important;
    }

    .pd-md-t-60 {
        padding-top: 60px;
    }

    .pd-md-r-60 {
        padding-right: 60px;
    }

    .pd-md-b-60 {
        padding-bottom: 60px;
    }

    .pd-md-l-60 {
        padding-left: 60px;
    }

    .pd-md-t-60-force {
        padding-top: 60px !important;
    }

    .pd-md-r-60-force {
        padding-right: 60px !important;
    }

    .pd-md-b-60-force {
        padding-bottom: 60px !important;
    }

    .pd-md-l-60-force {
        padding-left: 60px !important;
    }

    .pd-md-65 {
        padding: 65px;
    }

    .pd-md-65-force {
        padding: 65px !important;
    }

    .pd-md-y-65 {
        padding-top: 65px;
        padding-bottom: 65px;
    }

    .pd-md-y-65-force {
        padding-top: 65px !important;
        padding-bottom: 65px !important;
    }

    .pd-md-x-65 {
        padding-left: 65px;
        padding-right: 65px;
    }

    .pd-md-x-65-force {
        padding-left: 65px !important;
        padding-right: 65px !important;
    }

    .pd-md-t-65 {
        padding-top: 65px;
    }

    .pd-md-r-65 {
        padding-right: 65px;
    }

    .pd-md-b-65 {
        padding-bottom: 65px;
    }

    .pd-md-l-65 {
        padding-left: 65px;
    }

    .pd-md-t-65-force {
        padding-top: 65px !important;
    }

    .pd-md-r-65-force {
        padding-right: 65px !important;
    }

    .pd-md-b-65-force {
        padding-bottom: 65px !important;
    }

    .pd-md-l-65-force {
        padding-left: 65px !important;
    }

    .pd-md-70 {
        padding: 70px;
    }

    .pd-md-70-force {
        padding: 70px !important;
    }

    .pd-md-y-70 {
        padding-top: 70px;
        padding-bottom: 70px;
    }

    .pd-md-y-70-force {
        padding-top: 70px !important;
        padding-bottom: 70px !important;
    }

    .pd-md-x-70 {
        padding-left: 70px;
        padding-right: 70px;
    }

    .pd-md-x-70-force {
        padding-left: 70px !important;
        padding-right: 70px !important;
    }

    .pd-md-t-70 {
        padding-top: 70px;
    }

    .pd-md-r-70 {
        padding-right: 70px;
    }

    .pd-md-b-70 {
        padding-bottom: 70px;
    }

    .pd-md-l-70 {
        padding-left: 70px;
    }

    .pd-md-t-70-force {
        padding-top: 70px !important;
    }

    .pd-md-r-70-force {
        padding-right: 70px !important;
    }

    .pd-md-b-70-force {
        padding-bottom: 70px !important;
    }

    .pd-md-l-70-force {
        padding-left: 70px !important;
    }

    .pd-md-75 {
        padding: 75px;
    }

    .pd-md-75-force {
        padding: 75px !important;
    }

    .pd-md-y-75 {
        padding-top: 75px;
        padding-bottom: 75px;
    }

    .pd-md-y-75-force {
        padding-top: 75px !important;
        padding-bottom: 75px !important;
    }

    .pd-md-x-75 {
        padding-left: 75px;
        padding-right: 75px;
    }

    .pd-md-x-75-force {
        padding-left: 75px !important;
        padding-right: 75px !important;
    }

    .pd-md-t-75 {
        padding-top: 75px;
    }

    .pd-md-r-75 {
        padding-right: 75px;
    }

    .pd-md-b-75 {
        padding-bottom: 75px;
    }

    .pd-md-l-75 {
        padding-left: 75px;
    }

    .pd-md-t-75-force {
        padding-top: 75px !important;
    }

    .pd-md-r-75-force {
        padding-right: 75px !important;
    }

    .pd-md-b-75-force {
        padding-bottom: 75px !important;
    }

    .pd-md-l-75-force {
        padding-left: 75px !important;
    }

    .pd-md-80 {
        padding: 80px;
    }

    .pd-md-80-force {
        padding: 80px !important;
    }

    .pd-md-y-80 {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .pd-md-y-80-force {
        padding-top: 80px !important;
        padding-bottom: 80px !important;
    }

    .pd-md-x-80 {
        padding-left: 80px;
        padding-right: 80px;
    }

    .pd-md-x-80-force {
        padding-left: 80px !important;
        padding-right: 80px !important;
    }

    .pd-md-t-80 {
        padding-top: 80px;
    }

    .pd-md-r-80 {
        padding-right: 80px;
    }

    .pd-md-b-80 {
        padding-bottom: 80px;
    }

    .pd-md-l-80 {
        padding-left: 80px;
    }

    .pd-md-t-80-force {
        padding-top: 80px !important;
    }

    .pd-md-r-80-force {
        padding-right: 80px !important;
    }

    .pd-md-b-80-force {
        padding-bottom: 80px !important;
    }

    .pd-md-l-80-force {
        padding-left: 80px !important;
    }

    .pd-md-85 {
        padding: 85px;
    }

    .pd-md-85-force {
        padding: 85px !important;
    }

    .pd-md-y-85 {
        padding-top: 85px;
        padding-bottom: 85px;
    }

    .pd-md-y-85-force {
        padding-top: 85px !important;
        padding-bottom: 85px !important;
    }

    .pd-md-x-85 {
        padding-left: 85px;
        padding-right: 85px;
    }

    .pd-md-x-85-force {
        padding-left: 85px !important;
        padding-right: 85px !important;
    }

    .pd-md-t-85 {
        padding-top: 85px;
    }

    .pd-md-r-85 {
        padding-right: 85px;
    }

    .pd-md-b-85 {
        padding-bottom: 85px;
    }

    .pd-md-l-85 {
        padding-left: 85px;
    }

    .pd-md-t-85-force {
        padding-top: 85px !important;
    }

    .pd-md-r-85-force {
        padding-right: 85px !important;
    }

    .pd-md-b-85-force {
        padding-bottom: 85px !important;
    }

    .pd-md-l-85-force {
        padding-left: 85px !important;
    }

    .pd-md-90 {
        padding: 90px;
    }

    .pd-md-90-force {
        padding: 90px !important;
    }

    .pd-md-y-90 {
        padding-top: 90px;
        padding-bottom: 90px;
    }

    .pd-md-y-90-force {
        padding-top: 90px !important;
        padding-bottom: 90px !important;
    }

    .pd-md-x-90 {
        padding-left: 90px;
        padding-right: 90px;
    }

    .pd-md-x-90-force {
        padding-left: 90px !important;
        padding-right: 90px !important;
    }

    .pd-md-t-90 {
        padding-top: 90px;
    }

    .pd-md-r-90 {
        padding-right: 90px;
    }

    .pd-md-b-90 {
        padding-bottom: 90px;
    }

    .pd-md-l-90 {
        padding-left: 90px;
    }

    .pd-md-t-90-force {
        padding-top: 90px !important;
    }

    .pd-md-r-90-force {
        padding-right: 90px !important;
    }

    .pd-md-b-90-force {
        padding-bottom: 90px !important;
    }

    .pd-md-l-90-force {
        padding-left: 90px !important;
    }

    .pd-md-95 {
        padding: 95px;
    }

    .pd-md-95-force {
        padding: 95px !important;
    }

    .pd-md-y-95 {
        padding-top: 95px;
        padding-bottom: 95px;
    }

    .pd-md-y-95-force {
        padding-top: 95px !important;
        padding-bottom: 95px !important;
    }

    .pd-md-x-95 {
        padding-left: 95px;
        padding-right: 95px;
    }

    .pd-md-x-95-force {
        padding-left: 95px !important;
        padding-right: 95px !important;
    }

    .pd-md-t-95 {
        padding-top: 95px;
    }

    .pd-md-r-95 {
        padding-right: 95px;
    }

    .pd-md-b-95 {
        padding-bottom: 95px;
    }

    .pd-md-l-95 {
        padding-left: 95px;
    }

    .pd-md-t-95-force {
        padding-top: 95px !important;
    }

    .pd-md-r-95-force {
        padding-right: 95px !important;
    }

    .pd-md-b-95-force {
        padding-bottom: 95px !important;
    }

    .pd-md-l-95-force {
        padding-left: 95px !important;
    }

    .pd-md-100 {
        padding: 100px;
    }

    .pd-md-100-force {
        padding: 100px !important;
    }

    .pd-md-y-100 {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .pd-md-y-100-force {
        padding-top: 100px !important;
        padding-bottom: 100px !important;
    }

    .pd-md-x-100 {
        padding-left: 100px;
        padding-right: 100px;
    }

    .pd-md-x-100-force {
        padding-left: 100px !important;
        padding-right: 100px !important;
    }

    .pd-md-t-100 {
        padding-top: 100px;
    }

    .pd-md-r-100 {
        padding-right: 100px;
    }

    .pd-md-b-100 {
        padding-bottom: 100px;
    }

    .pd-md-l-100 {
        padding-left: 100px;
    }

    .pd-md-t-100-force {
        padding-top: 100px !important;
    }

    .pd-md-r-100-force {
        padding-right: 100px !important;
    }

    .pd-md-b-100-force {
        padding-bottom: 100px !important;
    }

    .pd-md-l-100-force {
        padding-left: 100px !important;
    }

    .pd-md-110 {
        padding: 110px;
    }

    .pd-md-110-force {
        padding: 110px !important;
    }

    .pd-md-y-110 {
        padding-top: 110px;
        padding-bottom: 110px;
    }

    .pd-md-y-110-force {
        padding-top: 110px !important;
        padding-bottom: 110px !important;
    }

    .pd-md-x-110 {
        padding-left: 110px;
        padding-right: 110px;
    }

    .pd-md-x-110-force {
        padding-left: 110px !important;
        padding-right: 110px !important;
    }

    .pd-md-t-110 {
        padding-top: 110px;
    }

    .pd-md-r-110 {
        padding-right: 110px;
    }

    .pd-md-b-110 {
        padding-bottom: 110px;
    }

    .pd-md-l-110 {
        padding-left: 110px;
    }

    .pd-md-t-110-force {
        padding-top: 110px !important;
    }

    .pd-md-r-110-force {
        padding-right: 110px !important;
    }

    .pd-md-b-110-force {
        padding-bottom: 110px !important;
    }

    .pd-md-l-110-force {
        padding-left: 110px !important;
    }

    .pd-md-120 {
        padding: 120px;
    }

    .pd-md-120-force {
        padding: 120px !important;
    }

    .pd-md-y-120 {
        padding-top: 120px;
        padding-bottom: 120px;
    }

    .pd-md-y-120-force {
        padding-top: 120px !important;
        padding-bottom: 120px !important;
    }

    .pd-md-x-120 {
        padding-left: 120px;
        padding-right: 120px;
    }

    .pd-md-x-120-force {
        padding-left: 120px !important;
        padding-right: 120px !important;
    }

    .pd-md-t-120 {
        padding-top: 120px;
    }

    .pd-md-r-120 {
        padding-right: 120px;
    }

    .pd-md-b-120 {
        padding-bottom: 120px;
    }

    .pd-md-l-120 {
        padding-left: 120px;
    }

    .pd-md-t-120-force {
        padding-top: 120px !important;
    }

    .pd-md-r-120-force {
        padding-right: 120px !important;
    }

    .pd-md-b-120-force {
        padding-bottom: 120px !important;
    }

    .pd-md-l-120-force {
        padding-left: 120px !important;
    }

    .pd-md-130 {
        padding: 130px;
    }

    .pd-md-130-force {
        padding: 130px !important;
    }

    .pd-md-y-130 {
        padding-top: 130px;
        padding-bottom: 130px;
    }

    .pd-md-y-130-force {
        padding-top: 130px !important;
        padding-bottom: 130px !important;
    }

    .pd-md-x-130 {
        padding-left: 130px;
        padding-right: 130px;
    }

    .pd-md-x-130-force {
        padding-left: 130px !important;
        padding-right: 130px !important;
    }

    .pd-md-t-130 {
        padding-top: 130px;
    }

    .pd-md-r-130 {
        padding-right: 130px;
    }

    .pd-md-b-130 {
        padding-bottom: 130px;
    }

    .pd-md-l-130 {
        padding-left: 130px;
    }

    .pd-md-t-130-force {
        padding-top: 130px !important;
    }

    .pd-md-r-130-force {
        padding-right: 130px !important;
    }

    .pd-md-b-130-force {
        padding-bottom: 130px !important;
    }

    .pd-md-l-130-force {
        padding-left: 130px !important;
    }

    .pd-md-140 {
        padding: 140px;
    }

    .pd-md-140-force {
        padding: 140px !important;
    }

    .pd-md-y-140 {
        padding-top: 140px;
        padding-bottom: 140px;
    }

    .pd-md-y-140-force {
        padding-top: 140px !important;
        padding-bottom: 140px !important;
    }

    .pd-md-x-140 {
        padding-left: 140px;
        padding-right: 140px;
    }

    .pd-md-x-140-force {
        padding-left: 140px !important;
        padding-right: 140px !important;
    }

    .pd-md-t-140 {
        padding-top: 140px;
    }

    .pd-md-r-140 {
        padding-right: 140px;
    }

    .pd-md-b-140 {
        padding-bottom: 140px;
    }

    .pd-md-l-140 {
        padding-left: 140px;
    }

    .pd-md-t-140-force {
        padding-top: 140px !important;
    }

    .pd-md-r-140-force {
        padding-right: 140px !important;
    }

    .pd-md-b-140-force {
        padding-bottom: 140px !important;
    }

    .pd-md-l-140-force {
        padding-left: 140px !important;
    }

    .pd-md-150 {
        padding: 150px;
    }

    .pd-md-150-force {
        padding: 150px !important;
    }

    .pd-md-y-150 {
        padding-top: 150px;
        padding-bottom: 150px;
    }

    .pd-md-y-150-force {
        padding-top: 150px !important;
        padding-bottom: 150px !important;
    }

    .pd-md-x-150 {
        padding-left: 150px;
        padding-right: 150px;
    }

    .pd-md-x-150-force {
        padding-left: 150px !important;
        padding-right: 150px !important;
    }

    .pd-md-t-150 {
        padding-top: 150px;
    }

    .pd-md-r-150 {
        padding-right: 150px;
    }

    .pd-md-b-150 {
        padding-bottom: 150px;
    }

    .pd-md-l-150 {
        padding-left: 150px;
    }

    .pd-md-t-150-force {
        padding-top: 150px !important;
    }

    .pd-md-r-150-force {
        padding-right: 150px !important;
    }

    .pd-md-b-150-force {
        padding-bottom: 150px !important;
    }

    .pd-md-l-150-force {
        padding-left: 150px !important;
    }

    .pd-md-160 {
        padding: 160px;
    }

    .pd-md-160-force {
        padding: 160px !important;
    }

    .pd-md-y-160 {
        padding-top: 160px;
        padding-bottom: 160px;
    }

    .pd-md-y-160-force {
        padding-top: 160px !important;
        padding-bottom: 160px !important;
    }

    .pd-md-x-160 {
        padding-left: 160px;
        padding-right: 160px;
    }

    .pd-md-x-160-force {
        padding-left: 160px !important;
        padding-right: 160px !important;
    }

    .pd-md-t-160 {
        padding-top: 160px;
    }

    .pd-md-r-160 {
        padding-right: 160px;
    }

    .pd-md-b-160 {
        padding-bottom: 160px;
    }

    .pd-md-l-160 {
        padding-left: 160px;
    }

    .pd-md-t-160-force {
        padding-top: 160px !important;
    }

    .pd-md-r-160-force {
        padding-right: 160px !important;
    }

    .pd-md-b-160-force {
        padding-bottom: 160px !important;
    }

    .pd-md-l-160-force {
        padding-left: 160px !important;
    }

    .pd-md-170 {
        padding: 170px;
    }

    .pd-md-170-force {
        padding: 170px !important;
    }

    .pd-md-y-170 {
        padding-top: 170px;
        padding-bottom: 170px;
    }

    .pd-md-y-170-force {
        padding-top: 170px !important;
        padding-bottom: 170px !important;
    }

    .pd-md-x-170 {
        padding-left: 170px;
        padding-right: 170px;
    }

    .pd-md-x-170-force {
        padding-left: 170px !important;
        padding-right: 170px !important;
    }

    .pd-md-t-170 {
        padding-top: 170px;
    }

    .pd-md-r-170 {
        padding-right: 170px;
    }

    .pd-md-b-170 {
        padding-bottom: 170px;
    }

    .pd-md-l-170 {
        padding-left: 170px;
    }

    .pd-md-t-170-force {
        padding-top: 170px !important;
    }

    .pd-md-r-170-force {
        padding-right: 170px !important;
    }

    .pd-md-b-170-force {
        padding-bottom: 170px !important;
    }

    .pd-md-l-170-force {
        padding-left: 170px !important;
    }

    .pd-md-180 {
        padding: 180px;
    }

    .pd-md-180-force {
        padding: 180px !important;
    }

    .pd-md-y-180 {
        padding-top: 180px;
        padding-bottom: 180px;
    }

    .pd-md-y-180-force {
        padding-top: 180px !important;
        padding-bottom: 180px !important;
    }

    .pd-md-x-180 {
        padding-left: 180px;
        padding-right: 180px;
    }

    .pd-md-x-180-force {
        padding-left: 180px !important;
        padding-right: 180px !important;
    }

    .pd-md-t-180 {
        padding-top: 180px;
    }

    .pd-md-r-180 {
        padding-right: 180px;
    }

    .pd-md-b-180 {
        padding-bottom: 180px;
    }

    .pd-md-l-180 {
        padding-left: 180px;
    }

    .pd-md-t-180-force {
        padding-top: 180px !important;
    }

    .pd-md-r-180-force {
        padding-right: 180px !important;
    }

    .pd-md-b-180-force {
        padding-bottom: 180px !important;
    }

    .pd-md-l-180-force {
        padding-left: 180px !important;
    }

    .pd-md-190 {
        padding: 190px;
    }

    .pd-md-190-force {
        padding: 190px !important;
    }

    .pd-md-y-190 {
        padding-top: 190px;
        padding-bottom: 190px;
    }

    .pd-md-y-190-force {
        padding-top: 190px !important;
        padding-bottom: 190px !important;
    }

    .pd-md-x-190 {
        padding-left: 190px;
        padding-right: 190px;
    }

    .pd-md-x-190-force {
        padding-left: 190px !important;
        padding-right: 190px !important;
    }

    .pd-md-t-190 {
        padding-top: 190px;
    }

    .pd-md-r-190 {
        padding-right: 190px;
    }

    .pd-md-b-190 {
        padding-bottom: 190px;
    }

    .pd-md-l-190 {
        padding-left: 190px;
    }

    .pd-md-t-190-force {
        padding-top: 190px !important;
    }

    .pd-md-r-190-force {
        padding-right: 190px !important;
    }

    .pd-md-b-190-force {
        padding-bottom: 190px !important;
    }

    .pd-md-l-190-force {
        padding-left: 190px !important;
    }

    .pd-md-200 {
        padding: 200px;
    }

    .pd-md-200-force {
        padding: 200px !important;
    }

    .pd-md-y-200 {
        padding-top: 200px;
        padding-bottom: 200px;
    }

    .pd-md-y-200-force {
        padding-top: 200px !important;
        padding-bottom: 200px !important;
    }

    .pd-md-x-200 {
        padding-left: 200px;
        padding-right: 200px;
    }

    .pd-md-x-200-force {
        padding-left: 200px !important;
        padding-right: 200px !important;
    }

    .pd-md-t-200 {
        padding-top: 200px;
    }

    .pd-md-r-200 {
        padding-right: 200px;
    }

    .pd-md-b-200 {
        padding-bottom: 200px;
    }

    .pd-md-l-200 {
        padding-left: 200px;
    }

    .pd-md-t-200-force {
        padding-top: 200px !important;
    }

    .pd-md-r-200-force {
        padding-right: 200px !important;
    }

    .pd-md-b-200-force {
        padding-bottom: 200px !important;
    }

    .pd-md-l-200-force {
        padding-left: 200px !important;
    }
}

@media (min-width: 992px) {
    .pd-lg-0 {
        padding: 0px;
    }

    .pd-lg-0-force {
        padding: 0px !important;
    }

    .pd-lg-y-0 {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .pd-lg-y-0-force {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .pd-lg-x-0 {
        padding-left: 0px;
        padding-right: 0px;
    }

    .pd-lg-x-0-force {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .pd-lg-t-0 {
        padding-top: 0px;
    }

    .pd-lg-r-0 {
        padding-right: 0px;
    }

    .pd-lg-b-0 {
        padding-bottom: 0px;
    }

    .pd-lg-l-0 {
        padding-left: 0px;
    }

    .pd-lg-t-0-force {
        padding-top: 0px !important;
    }

    .pd-lg-r-0-force {
        padding-right: 0px !important;
    }

    .pd-lg-b-0-force {
        padding-bottom: 0px !important;
    }

    .pd-lg-l-0-force {
        padding-left: 0px !important;
    }

    .pd-lg-1 {
        padding: 1px;
    }

    .pd-lg-1-force {
        padding: 1px !important;
    }

    .pd-lg-y-1 {
        padding-top: 1px;
        padding-bottom: 1px;
    }

    .pd-lg-y-1-force {
        padding-top: 1px !important;
        padding-bottom: 1px !important;
    }

    .pd-lg-x-1 {
        padding-left: 1px;
        padding-right: 1px;
    }

    .pd-lg-x-1-force {
        padding-left: 1px !important;
        padding-right: 1px !important;
    }

    .pd-lg-t-1 {
        padding-top: 1px;
    }

    .pd-lg-r-1 {
        padding-right: 1px;
    }

    .pd-lg-b-1 {
        padding-bottom: 1px;
    }

    .pd-lg-l-1 {
        padding-left: 1px;
    }

    .pd-lg-t-1-force {
        padding-top: 1px !important;
    }

    .pd-lg-r-1-force {
        padding-right: 1px !important;
    }

    .pd-lg-b-1-force {
        padding-bottom: 1px !important;
    }

    .pd-lg-l-1-force {
        padding-left: 1px !important;
    }

    .pd-lg-2 {
        padding: 2px;
    }

    .pd-lg-2-force {
        padding: 2px !important;
    }

    .pd-lg-y-2 {
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .pd-lg-y-2-force {
        padding-top: 2px !important;
        padding-bottom: 2px !important;
    }

    .pd-lg-x-2 {
        padding-left: 2px;
        padding-right: 2px;
    }

    .pd-lg-x-2-force {
        padding-left: 2px !important;
        padding-right: 2px !important;
    }

    .pd-lg-t-2 {
        padding-top: 2px;
    }

    .pd-lg-r-2 {
        padding-right: 2px;
    }

    .pd-lg-b-2 {
        padding-bottom: 2px;
    }

    .pd-lg-l-2 {
        padding-left: 2px;
    }

    .pd-lg-t-2-force {
        padding-top: 2px !important;
    }

    .pd-lg-r-2-force {
        padding-right: 2px !important;
    }

    .pd-lg-b-2-force {
        padding-bottom: 2px !important;
    }

    .pd-lg-l-2-force {
        padding-left: 2px !important;
    }

    .pd-lg-3 {
        padding: 3px;
    }

    .pd-lg-3-force {
        padding: 3px !important;
    }

    .pd-lg-y-3 {
        padding-top: 3px;
        padding-bottom: 3px;
    }

    .pd-lg-y-3-force {
        padding-top: 3px !important;
        padding-bottom: 3px !important;
    }

    .pd-lg-x-3 {
        padding-left: 3px;
        padding-right: 3px;
    }

    .pd-lg-x-3-force {
        padding-left: 3px !important;
        padding-right: 3px !important;
    }

    .pd-lg-t-3 {
        padding-top: 3px;
    }

    .pd-lg-r-3 {
        padding-right: 3px;
    }

    .pd-lg-b-3 {
        padding-bottom: 3px;
    }

    .pd-lg-l-3 {
        padding-left: 3px;
    }

    .pd-lg-t-3-force {
        padding-top: 3px !important;
    }

    .pd-lg-r-3-force {
        padding-right: 3px !important;
    }

    .pd-lg-b-3-force {
        padding-bottom: 3px !important;
    }

    .pd-lg-l-3-force {
        padding-left: 3px !important;
    }

    .pd-lg-4 {
        padding: 4px;
    }

    .pd-lg-4-force {
        padding: 4px !important;
    }

    .pd-lg-y-4 {
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .pd-lg-y-4-force {
        padding-top: 4px !important;
        padding-bottom: 4px !important;
    }

    .pd-lg-x-4 {
        padding-left: 4px;
        padding-right: 4px;
    }

    .pd-lg-x-4-force {
        padding-left: 4px !important;
        padding-right: 4px !important;
    }

    .pd-lg-t-4 {
        padding-top: 4px;
    }

    .pd-lg-r-4 {
        padding-right: 4px;
    }

    .pd-lg-b-4 {
        padding-bottom: 4px;
    }

    .pd-lg-l-4 {
        padding-left: 4px;
    }

    .pd-lg-t-4-force {
        padding-top: 4px !important;
    }

    .pd-lg-r-4-force {
        padding-right: 4px !important;
    }

    .pd-lg-b-4-force {
        padding-bottom: 4px !important;
    }

    .pd-lg-l-4-force {
        padding-left: 4px !important;
    }

    .pd-lg-5 {
        padding: 5px;
    }

    .pd-lg-5-force {
        padding: 5px !important;
    }

    .pd-lg-y-5 {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .pd-lg-y-5-force {
        padding-top: 5px !important;
        padding-bottom: 5px !important;
    }

    .pd-lg-x-5 {
        padding-left: 5px;
        padding-right: 5px;
    }

    .pd-lg-x-5-force {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }

    .pd-lg-t-5 {
        padding-top: 5px;
    }

    .pd-lg-r-5 {
        padding-right: 5px;
    }

    .pd-lg-b-5 {
        padding-bottom: 5px;
    }

    .pd-lg-l-5 {
        padding-left: 5px;
    }

    .pd-lg-t-5-force {
        padding-top: 5px !important;
    }

    .pd-lg-r-5-force {
        padding-right: 5px !important;
    }

    .pd-lg-b-5-force {
        padding-bottom: 5px !important;
    }

    .pd-lg-l-5-force {
        padding-left: 5px !important;
    }

    .pd-lg-6 {
        padding: 6px;
    }

    .pd-lg-6-force {
        padding: 6px !important;
    }

    .pd-lg-y-6 {
        padding-top: 6px;
        padding-bottom: 6px;
    }

    .pd-lg-y-6-force {
        padding-top: 6px !important;
        padding-bottom: 6px !important;
    }

    .pd-lg-x-6 {
        padding-left: 6px;
        padding-right: 6px;
    }

    .pd-lg-x-6-force {
        padding-left: 6px !important;
        padding-right: 6px !important;
    }

    .pd-lg-t-6 {
        padding-top: 6px;
    }

    .pd-lg-r-6 {
        padding-right: 6px;
    }

    .pd-lg-b-6 {
        padding-bottom: 6px;
    }

    .pd-lg-l-6 {
        padding-left: 6px;
    }

    .pd-lg-t-6-force {
        padding-top: 6px !important;
    }

    .pd-lg-r-6-force {
        padding-right: 6px !important;
    }

    .pd-lg-b-6-force {
        padding-bottom: 6px !important;
    }

    .pd-lg-l-6-force {
        padding-left: 6px !important;
    }

    .pd-lg-7 {
        padding: 7px;
    }

    .pd-lg-7-force {
        padding: 7px !important;
    }

    .pd-lg-y-7 {
        padding-top: 7px;
        padding-bottom: 7px;
    }

    .pd-lg-y-7-force {
        padding-top: 7px !important;
        padding-bottom: 7px !important;
    }

    .pd-lg-x-7 {
        padding-left: 7px;
        padding-right: 7px;
    }

    .pd-lg-x-7-force {
        padding-left: 7px !important;
        padding-right: 7px !important;
    }

    .pd-lg-t-7 {
        padding-top: 7px;
    }

    .pd-lg-r-7 {
        padding-right: 7px;
    }

    .pd-lg-b-7 {
        padding-bottom: 7px;
    }

    .pd-lg-l-7 {
        padding-left: 7px;
    }

    .pd-lg-t-7-force {
        padding-top: 7px !important;
    }

    .pd-lg-r-7-force {
        padding-right: 7px !important;
    }

    .pd-lg-b-7-force {
        padding-bottom: 7px !important;
    }

    .pd-lg-l-7-force {
        padding-left: 7px !important;
    }

    .pd-lg-8 {
        padding: 8px;
    }

    .pd-lg-8-force {
        padding: 8px !important;
    }

    .pd-lg-y-8 {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .pd-lg-y-8-force {
        padding-top: 8px !important;
        padding-bottom: 8px !important;
    }

    .pd-lg-x-8 {
        padding-left: 8px;
        padding-right: 8px;
    }

    .pd-lg-x-8-force {
        padding-left: 8px !important;
        padding-right: 8px !important;
    }

    .pd-lg-t-8 {
        padding-top: 8px;
    }

    .pd-lg-r-8 {
        padding-right: 8px;
    }

    .pd-lg-b-8 {
        padding-bottom: 8px;
    }

    .pd-lg-l-8 {
        padding-left: 8px;
    }

    .pd-lg-t-8-force {
        padding-top: 8px !important;
    }

    .pd-lg-r-8-force {
        padding-right: 8px !important;
    }

    .pd-lg-b-8-force {
        padding-bottom: 8px !important;
    }

    .pd-lg-l-8-force {
        padding-left: 8px !important;
    }

    .pd-lg-9 {
        padding: 9px;
    }

    .pd-lg-9-force {
        padding: 9px !important;
    }

    .pd-lg-y-9 {
        padding-top: 9px;
        padding-bottom: 9px;
    }

    .pd-lg-y-9-force {
        padding-top: 9px !important;
        padding-bottom: 9px !important;
    }

    .pd-lg-x-9 {
        padding-left: 9px;
        padding-right: 9px;
    }

    .pd-lg-x-9-force {
        padding-left: 9px !important;
        padding-right: 9px !important;
    }

    .pd-lg-t-9 {
        padding-top: 9px;
    }

    .pd-lg-r-9 {
        padding-right: 9px;
    }

    .pd-lg-b-9 {
        padding-bottom: 9px;
    }

    .pd-lg-l-9 {
        padding-left: 9px;
    }

    .pd-lg-t-9-force {
        padding-top: 9px !important;
    }

    .pd-lg-r-9-force {
        padding-right: 9px !important;
    }

    .pd-lg-b-9-force {
        padding-bottom: 9px !important;
    }

    .pd-lg-l-9-force {
        padding-left: 9px !important;
    }

    .pd-lg-10 {
        padding: 10px;
    }

    .pd-lg-10-force {
        padding: 10px !important;
    }

    .pd-lg-y-10 {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .pd-lg-y-10-force {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }

    .pd-lg-x-10 {
        padding-left: 10px;
        padding-right: 10px;
    }

    .pd-lg-x-10-force {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .pd-lg-t-10 {
        padding-top: 10px;
    }

    .pd-lg-r-10 {
        padding-right: 10px;
    }

    .pd-lg-b-10 {
        padding-bottom: 10px;
    }

    .pd-lg-l-10 {
        padding-left: 10px;
    }

    .pd-lg-t-10-force {
        padding-top: 10px !important;
    }

    .pd-lg-r-10-force {
        padding-right: 10px !important;
    }

    .pd-lg-b-10-force {
        padding-bottom: 10px !important;
    }

    .pd-lg-l-10-force {
        padding-left: 10px !important;
    }

    .pd-lg-15 {
        padding: 15px;
    }

    .pd-lg-15-force {
        padding: 15px !important;
    }

    .pd-lg-y-15 {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .pd-lg-y-15-force {
        padding-top: 15px !important;
        padding-bottom: 15px !important;
    }

    .pd-lg-x-15 {
        padding-left: 15px;
        padding-right: 15px;
    }

    .pd-lg-x-15-force {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .pd-lg-t-15 {
        padding-top: 15px;
    }

    .pd-lg-r-15 {
        padding-right: 15px;
    }

    .pd-lg-b-15 {
        padding-bottom: 15px;
    }

    .pd-lg-l-15 {
        padding-left: 15px;
    }

    .pd-lg-t-15-force {
        padding-top: 15px !important;
    }

    .pd-lg-r-15-force {
        padding-right: 15px !important;
    }

    .pd-lg-b-15-force {
        padding-bottom: 15px !important;
    }

    .pd-lg-l-15-force {
        padding-left: 15px !important;
    }

    .pd-lg-20 {
        padding: 20px;
    }

    .pd-lg-20-force {
        padding: 20px !important;
    }

    .pd-lg-y-20 {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .pd-lg-y-20-force {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }

    .pd-lg-x-20 {
        padding-left: 20px;
        padding-right: 20px;
    }

    .pd-lg-x-20-force {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .pd-lg-t-20 {
        padding-top: 20px;
    }

    .pd-lg-r-20 {
        padding-right: 20px;
    }

    .pd-lg-b-20 {
        padding-bottom: 20px;
    }

    .pd-lg-l-20 {
        padding-left: 20px;
    }

    .pd-lg-t-20-force {
        padding-top: 20px !important;
    }

    .pd-lg-r-20-force {
        padding-right: 20px !important;
    }

    .pd-lg-b-20-force {
        padding-bottom: 20px !important;
    }

    .pd-lg-l-20-force {
        padding-left: 20px !important;
    }

    .pd-lg-25 {
        padding: 25px;
    }

    .pd-lg-25-force {
        padding: 25px !important;
    }

    .pd-lg-y-25 {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .pd-lg-y-25-force {
        padding-top: 25px !important;
        padding-bottom: 25px !important;
    }

    .pd-lg-x-25 {
        padding-left: 25px;
        padding-right: 25px;
    }

    .pd-lg-x-25-force {
        padding-left: 25px !important;
        padding-right: 25px !important;
    }

    .pd-lg-t-25 {
        padding-top: 25px;
    }

    .pd-lg-r-25 {
        padding-right: 25px;
    }

    .pd-lg-b-25 {
        padding-bottom: 25px;
    }

    .pd-lg-l-25 {
        padding-left: 25px;
    }

    .pd-lg-t-25-force {
        padding-top: 25px !important;
    }

    .pd-lg-r-25-force {
        padding-right: 25px !important;
    }

    .pd-lg-b-25-force {
        padding-bottom: 25px !important;
    }

    .pd-lg-l-25-force {
        padding-left: 25px !important;
    }

    .pd-lg-30 {
        padding: 30px;
    }

    .pd-lg-30-force {
        padding: 30px !important;
    }

    .pd-lg-y-30 {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .pd-lg-y-30-force {
        padding-top: 30px !important;
        padding-bottom: 30px !important;
    }

    .pd-lg-x-30 {
        padding-left: 30px;
        padding-right: 30px;
    }

    .pd-lg-x-30-force {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }

    .pd-lg-t-30 {
        padding-top: 30px;
    }

    .pd-lg-r-30 {
        padding-right: 30px;
    }

    .pd-lg-b-30 {
        padding-bottom: 30px;
    }

    .pd-lg-l-30 {
        padding-left: 30px;
    }

    .pd-lg-t-30-force {
        padding-top: 30px !important;
    }

    .pd-lg-r-30-force {
        padding-right: 30px !important;
    }

    .pd-lg-b-30-force {
        padding-bottom: 30px !important;
    }

    .pd-lg-l-30-force {
        padding-left: 30px !important;
    }

    .pd-lg-35 {
        padding: 35px;
    }

    .pd-lg-35-force {
        padding: 35px !important;
    }

    .pd-lg-y-35 {
        padding-top: 35px;
        padding-bottom: 35px;
    }

    .pd-lg-y-35-force {
        padding-top: 35px !important;
        padding-bottom: 35px !important;
    }

    .pd-lg-x-35 {
        padding-left: 35px;
        padding-right: 35px;
    }

    .pd-lg-x-35-force {
        padding-left: 35px !important;
        padding-right: 35px !important;
    }

    .pd-lg-t-35 {
        padding-top: 35px;
    }

    .pd-lg-r-35 {
        padding-right: 35px;
    }

    .pd-lg-b-35 {
        padding-bottom: 35px;
    }

    .pd-lg-l-35 {
        padding-left: 35px;
    }

    .pd-lg-t-35-force {
        padding-top: 35px !important;
    }

    .pd-lg-r-35-force {
        padding-right: 35px !important;
    }

    .pd-lg-b-35-force {
        padding-bottom: 35px !important;
    }

    .pd-lg-l-35-force {
        padding-left: 35px !important;
    }

    .pd-lg-40 {
        padding: 40px;
    }

    .pd-lg-40-force {
        padding: 40px !important;
    }

    .pd-lg-y-40 {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .pd-lg-y-40-force {
        padding-top: 40px !important;
        padding-bottom: 40px !important;
    }

    .pd-lg-x-40 {
        padding-left: 40px;
        padding-right: 40px;
    }

    .pd-lg-x-40-force {
        padding-left: 40px !important;
        padding-right: 40px !important;
    }

    .pd-lg-t-40 {
        padding-top: 40px;
    }

    .pd-lg-r-40 {
        padding-right: 40px;
    }

    .pd-lg-b-40 {
        padding-bottom: 40px;
    }

    .pd-lg-l-40 {
        padding-left: 40px;
    }

    .pd-lg-t-40-force {
        padding-top: 40px !important;
    }

    .pd-lg-r-40-force {
        padding-right: 40px !important;
    }

    .pd-lg-b-40-force {
        padding-bottom: 40px !important;
    }

    .pd-lg-l-40-force {
        padding-left: 40px !important;
    }

    .pd-lg-45 {
        padding: 45px;
    }

    .pd-lg-45-force {
        padding: 45px !important;
    }

    .pd-lg-y-45 {
        padding-top: 45px;
        padding-bottom: 45px;
    }

    .pd-lg-y-45-force {
        padding-top: 45px !important;
        padding-bottom: 45px !important;
    }

    .pd-lg-x-45 {
        padding-left: 45px;
        padding-right: 45px;
    }

    .pd-lg-x-45-force {
        padding-left: 45px !important;
        padding-right: 45px !important;
    }

    .pd-lg-t-45 {
        padding-top: 45px;
    }

    .pd-lg-r-45 {
        padding-right: 45px;
    }

    .pd-lg-b-45 {
        padding-bottom: 45px;
    }

    .pd-lg-l-45 {
        padding-left: 45px;
    }

    .pd-lg-t-45-force {
        padding-top: 45px !important;
    }

    .pd-lg-r-45-force {
        padding-right: 45px !important;
    }

    .pd-lg-b-45-force {
        padding-bottom: 45px !important;
    }

    .pd-lg-l-45-force {
        padding-left: 45px !important;
    }

    .pd-lg-50 {
        padding: 50px;
    }

    .pd-lg-50-force {
        padding: 50px !important;
    }

    .pd-lg-y-50 {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .pd-lg-y-50-force {
        padding-top: 50px !important;
        padding-bottom: 50px !important;
    }

    .pd-lg-x-50 {
        padding-left: 50px;
        padding-right: 50px;
    }

    .pd-lg-x-50-force {
        padding-left: 50px !important;
        padding-right: 50px !important;
    }

    .pd-lg-t-50 {
        padding-top: 50px;
    }

    .pd-lg-r-50 {
        padding-right: 50px;
    }

    .pd-lg-b-50 {
        padding-bottom: 50px;
    }

    .pd-lg-l-50 {
        padding-left: 50px;
    }

    .pd-lg-t-50-force {
        padding-top: 50px !important;
    }

    .pd-lg-r-50-force {
        padding-right: 50px !important;
    }

    .pd-lg-b-50-force {
        padding-bottom: 50px !important;
    }

    .pd-lg-l-50-force {
        padding-left: 50px !important;
    }

    .pd-lg-55 {
        padding: 55px;
    }

    .pd-lg-55-force {
        padding: 55px !important;
    }

    .pd-lg-y-55 {
        padding-top: 55px;
        padding-bottom: 55px;
    }

    .pd-lg-y-55-force {
        padding-top: 55px !important;
        padding-bottom: 55px !important;
    }

    .pd-lg-x-55 {
        padding-left: 55px;
        padding-right: 55px;
    }

    .pd-lg-x-55-force {
        padding-left: 55px !important;
        padding-right: 55px !important;
    }

    .pd-lg-t-55 {
        padding-top: 55px;
    }

    .pd-lg-r-55 {
        padding-right: 55px;
    }

    .pd-lg-b-55 {
        padding-bottom: 55px;
    }

    .pd-lg-l-55 {
        padding-left: 55px;
    }

    .pd-lg-t-55-force {
        padding-top: 55px !important;
    }

    .pd-lg-r-55-force {
        padding-right: 55px !important;
    }

    .pd-lg-b-55-force {
        padding-bottom: 55px !important;
    }

    .pd-lg-l-55-force {
        padding-left: 55px !important;
    }

    .pd-lg-60 {
        padding: 60px;
    }

    .pd-lg-60-force {
        padding: 60px !important;
    }

    .pd-lg-y-60 {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .pd-lg-y-60-force {
        padding-top: 60px !important;
        padding-bottom: 60px !important;
    }

    .pd-lg-x-60 {
        padding-left: 60px;
        padding-right: 60px;
    }

    .pd-lg-x-60-force {
        padding-left: 60px !important;
        padding-right: 60px !important;
    }

    .pd-lg-t-60 {
        padding-top: 60px;
    }

    .pd-lg-r-60 {
        padding-right: 60px;
    }

    .pd-lg-b-60 {
        padding-bottom: 60px;
    }

    .pd-lg-l-60 {
        padding-left: 60px;
    }

    .pd-lg-t-60-force {
        padding-top: 60px !important;
    }

    .pd-lg-r-60-force {
        padding-right: 60px !important;
    }

    .pd-lg-b-60-force {
        padding-bottom: 60px !important;
    }

    .pd-lg-l-60-force {
        padding-left: 60px !important;
    }

    .pd-lg-65 {
        padding: 65px;
    }

    .pd-lg-65-force {
        padding: 65px !important;
    }

    .pd-lg-y-65 {
        padding-top: 65px;
        padding-bottom: 65px;
    }

    .pd-lg-y-65-force {
        padding-top: 65px !important;
        padding-bottom: 65px !important;
    }

    .pd-lg-x-65 {
        padding-left: 65px;
        padding-right: 65px;
    }

    .pd-lg-x-65-force {
        padding-left: 65px !important;
        padding-right: 65px !important;
    }

    .pd-lg-t-65 {
        padding-top: 65px;
    }

    .pd-lg-r-65 {
        padding-right: 65px;
    }

    .pd-lg-b-65 {
        padding-bottom: 65px;
    }

    .pd-lg-l-65 {
        padding-left: 65px;
    }

    .pd-lg-t-65-force {
        padding-top: 65px !important;
    }

    .pd-lg-r-65-force {
        padding-right: 65px !important;
    }

    .pd-lg-b-65-force {
        padding-bottom: 65px !important;
    }

    .pd-lg-l-65-force {
        padding-left: 65px !important;
    }

    .pd-lg-70 {
        padding: 70px;
    }

    .pd-lg-70-force {
        padding: 70px !important;
    }

    .pd-lg-y-70 {
        padding-top: 70px;
        padding-bottom: 70px;
    }

    .pd-lg-y-70-force {
        padding-top: 70px !important;
        padding-bottom: 70px !important;
    }

    .pd-lg-x-70 {
        padding-left: 70px;
        padding-right: 70px;
    }

    .pd-lg-x-70-force {
        padding-left: 70px !important;
        padding-right: 70px !important;
    }

    .pd-lg-t-70 {
        padding-top: 70px;
    }

    .pd-lg-r-70 {
        padding-right: 70px;
    }

    .pd-lg-b-70 {
        padding-bottom: 70px;
    }

    .pd-lg-l-70 {
        padding-left: 70px;
    }

    .pd-lg-t-70-force {
        padding-top: 70px !important;
    }

    .pd-lg-r-70-force {
        padding-right: 70px !important;
    }

    .pd-lg-b-70-force {
        padding-bottom: 70px !important;
    }

    .pd-lg-l-70-force {
        padding-left: 70px !important;
    }

    .pd-lg-75 {
        padding: 75px;
    }

    .pd-lg-75-force {
        padding: 75px !important;
    }

    .pd-lg-y-75 {
        padding-top: 75px;
        padding-bottom: 75px;
    }

    .pd-lg-y-75-force {
        padding-top: 75px !important;
        padding-bottom: 75px !important;
    }

    .pd-lg-x-75 {
        padding-left: 75px;
        padding-right: 75px;
    }

    .pd-lg-x-75-force {
        padding-left: 75px !important;
        padding-right: 75px !important;
    }

    .pd-lg-t-75 {
        padding-top: 75px;
    }

    .pd-lg-r-75 {
        padding-right: 75px;
    }

    .pd-lg-b-75 {
        padding-bottom: 75px;
    }

    .pd-lg-l-75 {
        padding-left: 75px;
    }

    .pd-lg-t-75-force {
        padding-top: 75px !important;
    }

    .pd-lg-r-75-force {
        padding-right: 75px !important;
    }

    .pd-lg-b-75-force {
        padding-bottom: 75px !important;
    }

    .pd-lg-l-75-force {
        padding-left: 75px !important;
    }

    .pd-lg-80 {
        padding: 80px;
    }

    .pd-lg-80-force {
        padding: 80px !important;
    }

    .pd-lg-y-80 {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .pd-lg-y-80-force {
        padding-top: 80px !important;
        padding-bottom: 80px !important;
    }

    .pd-lg-x-80 {
        padding-left: 80px;
        padding-right: 80px;
    }

    .pd-lg-x-80-force {
        padding-left: 80px !important;
        padding-right: 80px !important;
    }

    .pd-lg-t-80 {
        padding-top: 80px;
    }

    .pd-lg-r-80 {
        padding-right: 80px;
    }

    .pd-lg-b-80 {
        padding-bottom: 80px;
    }

    .pd-lg-l-80 {
        padding-left: 80px;
    }

    .pd-lg-t-80-force {
        padding-top: 80px !important;
    }

    .pd-lg-r-80-force {
        padding-right: 80px !important;
    }

    .pd-lg-b-80-force {
        padding-bottom: 80px !important;
    }

    .pd-lg-l-80-force {
        padding-left: 80px !important;
    }

    .pd-lg-85 {
        padding: 85px;
    }

    .pd-lg-85-force {
        padding: 85px !important;
    }

    .pd-lg-y-85 {
        padding-top: 85px;
        padding-bottom: 85px;
    }

    .pd-lg-y-85-force {
        padding-top: 85px !important;
        padding-bottom: 85px !important;
    }

    .pd-lg-x-85 {
        padding-left: 85px;
        padding-right: 85px;
    }

    .pd-lg-x-85-force {
        padding-left: 85px !important;
        padding-right: 85px !important;
    }

    .pd-lg-t-85 {
        padding-top: 85px;
    }

    .pd-lg-r-85 {
        padding-right: 85px;
    }

    .pd-lg-b-85 {
        padding-bottom: 85px;
    }

    .pd-lg-l-85 {
        padding-left: 85px;
    }

    .pd-lg-t-85-force {
        padding-top: 85px !important;
    }

    .pd-lg-r-85-force {
        padding-right: 85px !important;
    }

    .pd-lg-b-85-force {
        padding-bottom: 85px !important;
    }

    .pd-lg-l-85-force {
        padding-left: 85px !important;
    }

    .pd-lg-90 {
        padding: 90px;
    }

    .pd-lg-90-force {
        padding: 90px !important;
    }

    .pd-lg-y-90 {
        padding-top: 90px;
        padding-bottom: 90px;
    }

    .pd-lg-y-90-force {
        padding-top: 90px !important;
        padding-bottom: 90px !important;
    }

    .pd-lg-x-90 {
        padding-left: 90px;
        padding-right: 90px;
    }

    .pd-lg-x-90-force {
        padding-left: 90px !important;
        padding-right: 90px !important;
    }

    .pd-lg-t-90 {
        padding-top: 90px;
    }

    .pd-lg-r-90 {
        padding-right: 90px;
    }

    .pd-lg-b-90 {
        padding-bottom: 90px;
    }

    .pd-lg-l-90 {
        padding-left: 90px;
    }

    .pd-lg-t-90-force {
        padding-top: 90px !important;
    }

    .pd-lg-r-90-force {
        padding-right: 90px !important;
    }

    .pd-lg-b-90-force {
        padding-bottom: 90px !important;
    }

    .pd-lg-l-90-force {
        padding-left: 90px !important;
    }

    .pd-lg-95 {
        padding: 95px;
    }

    .pd-lg-95-force {
        padding: 95px !important;
    }

    .pd-lg-y-95 {
        padding-top: 95px;
        padding-bottom: 95px;
    }

    .pd-lg-y-95-force {
        padding-top: 95px !important;
        padding-bottom: 95px !important;
    }

    .pd-lg-x-95 {
        padding-left: 95px;
        padding-right: 95px;
    }

    .pd-lg-x-95-force {
        padding-left: 95px !important;
        padding-right: 95px !important;
    }

    .pd-lg-t-95 {
        padding-top: 95px;
    }

    .pd-lg-r-95 {
        padding-right: 95px;
    }

    .pd-lg-b-95 {
        padding-bottom: 95px;
    }

    .pd-lg-l-95 {
        padding-left: 95px;
    }

    .pd-lg-t-95-force {
        padding-top: 95px !important;
    }

    .pd-lg-r-95-force {
        padding-right: 95px !important;
    }

    .pd-lg-b-95-force {
        padding-bottom: 95px !important;
    }

    .pd-lg-l-95-force {
        padding-left: 95px !important;
    }

    .pd-lg-100 {
        padding: 100px;
    }

    .pd-lg-100-force {
        padding: 100px !important;
    }

    .pd-lg-y-100 {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .pd-lg-y-100-force {
        padding-top: 100px !important;
        padding-bottom: 100px !important;
    }

    .pd-lg-x-100 {
        padding-left: 100px;
        padding-right: 100px;
    }

    .pd-lg-x-100-force {
        padding-left: 100px !important;
        padding-right: 100px !important;
    }

    .pd-lg-t-100 {
        padding-top: 100px;
    }

    .pd-lg-r-100 {
        padding-right: 100px;
    }

    .pd-lg-b-100 {
        padding-bottom: 100px;
    }

    .pd-lg-l-100 {
        padding-left: 100px;
    }

    .pd-lg-t-100-force {
        padding-top: 100px !important;
    }

    .pd-lg-r-100-force {
        padding-right: 100px !important;
    }

    .pd-lg-b-100-force {
        padding-bottom: 100px !important;
    }

    .pd-lg-l-100-force {
        padding-left: 100px !important;
    }

    .pd-lg-110 {
        padding: 110px;
    }

    .pd-lg-110-force {
        padding: 110px !important;
    }

    .pd-lg-y-110 {
        padding-top: 110px;
        padding-bottom: 110px;
    }

    .pd-lg-y-110-force {
        padding-top: 110px !important;
        padding-bottom: 110px !important;
    }

    .pd-lg-x-110 {
        padding-left: 110px;
        padding-right: 110px;
    }

    .pd-lg-x-110-force {
        padding-left: 110px !important;
        padding-right: 110px !important;
    }

    .pd-lg-t-110 {
        padding-top: 110px;
    }

    .pd-lg-r-110 {
        padding-right: 110px;
    }

    .pd-lg-b-110 {
        padding-bottom: 110px;
    }

    .pd-lg-l-110 {
        padding-left: 110px;
    }

    .pd-lg-t-110-force {
        padding-top: 110px !important;
    }

    .pd-lg-r-110-force {
        padding-right: 110px !important;
    }

    .pd-lg-b-110-force {
        padding-bottom: 110px !important;
    }

    .pd-lg-l-110-force {
        padding-left: 110px !important;
    }

    .pd-lg-120 {
        padding: 120px;
    }

    .pd-lg-120-force {
        padding: 120px !important;
    }

    .pd-lg-y-120 {
        padding-top: 120px;
        padding-bottom: 120px;
    }

    .pd-lg-y-120-force {
        padding-top: 120px !important;
        padding-bottom: 120px !important;
    }

    .pd-lg-x-120 {
        padding-left: 120px;
        padding-right: 120px;
    }

    .pd-lg-x-120-force {
        padding-left: 120px !important;
        padding-right: 120px !important;
    }

    .pd-lg-t-120 {
        padding-top: 120px;
    }

    .pd-lg-r-120 {
        padding-right: 120px;
    }

    .pd-lg-b-120 {
        padding-bottom: 120px;
    }

    .pd-lg-l-120 {
        padding-left: 120px;
    }

    .pd-lg-t-120-force {
        padding-top: 120px !important;
    }

    .pd-lg-r-120-force {
        padding-right: 120px !important;
    }

    .pd-lg-b-120-force {
        padding-bottom: 120px !important;
    }

    .pd-lg-l-120-force {
        padding-left: 120px !important;
    }

    .pd-lg-130 {
        padding: 130px;
    }

    .pd-lg-130-force {
        padding: 130px !important;
    }

    .pd-lg-y-130 {
        padding-top: 130px;
        padding-bottom: 130px;
    }

    .pd-lg-y-130-force {
        padding-top: 130px !important;
        padding-bottom: 130px !important;
    }

    .pd-lg-x-130 {
        padding-left: 130px;
        padding-right: 130px;
    }

    .pd-lg-x-130-force {
        padding-left: 130px !important;
        padding-right: 130px !important;
    }

    .pd-lg-t-130 {
        padding-top: 130px;
    }

    .pd-lg-r-130 {
        padding-right: 130px;
    }

    .pd-lg-b-130 {
        padding-bottom: 130px;
    }

    .pd-lg-l-130 {
        padding-left: 130px;
    }

    .pd-lg-t-130-force {
        padding-top: 130px !important;
    }

    .pd-lg-r-130-force {
        padding-right: 130px !important;
    }

    .pd-lg-b-130-force {
        padding-bottom: 130px !important;
    }

    .pd-lg-l-130-force {
        padding-left: 130px !important;
    }

    .pd-lg-140 {
        padding: 140px;
    }

    .pd-lg-140-force {
        padding: 140px !important;
    }

    .pd-lg-y-140 {
        padding-top: 140px;
        padding-bottom: 140px;
    }

    .pd-lg-y-140-force {
        padding-top: 140px !important;
        padding-bottom: 140px !important;
    }

    .pd-lg-x-140 {
        padding-left: 140px;
        padding-right: 140px;
    }

    .pd-lg-x-140-force {
        padding-left: 140px !important;
        padding-right: 140px !important;
    }

    .pd-lg-t-140 {
        padding-top: 140px;
    }

    .pd-lg-r-140 {
        padding-right: 140px;
    }

    .pd-lg-b-140 {
        padding-bottom: 140px;
    }

    .pd-lg-l-140 {
        padding-left: 140px;
    }

    .pd-lg-t-140-force {
        padding-top: 140px !important;
    }

    .pd-lg-r-140-force {
        padding-right: 140px !important;
    }

    .pd-lg-b-140-force {
        padding-bottom: 140px !important;
    }

    .pd-lg-l-140-force {
        padding-left: 140px !important;
    }

    .pd-lg-150 {
        padding: 150px;
    }

    .pd-lg-150-force {
        padding: 150px !important;
    }

    .pd-lg-y-150 {
        padding-top: 150px;
        padding-bottom: 150px;
    }

    .pd-lg-y-150-force {
        padding-top: 150px !important;
        padding-bottom: 150px !important;
    }

    .pd-lg-x-150 {
        padding-left: 150px;
        padding-right: 150px;
    }

    .pd-lg-x-150-force {
        padding-left: 150px !important;
        padding-right: 150px !important;
    }

    .pd-lg-t-150 {
        padding-top: 150px;
    }

    .pd-lg-r-150 {
        padding-right: 150px;
    }

    .pd-lg-b-150 {
        padding-bottom: 150px;
    }

    .pd-lg-l-150 {
        padding-left: 150px;
    }

    .pd-lg-t-150-force {
        padding-top: 150px !important;
    }

    .pd-lg-r-150-force {
        padding-right: 150px !important;
    }

    .pd-lg-b-150-force {
        padding-bottom: 150px !important;
    }

    .pd-lg-l-150-force {
        padding-left: 150px !important;
    }

    .pd-lg-160 {
        padding: 160px;
    }

    .pd-lg-160-force {
        padding: 160px !important;
    }

    .pd-lg-y-160 {
        padding-top: 160px;
        padding-bottom: 160px;
    }

    .pd-lg-y-160-force {
        padding-top: 160px !important;
        padding-bottom: 160px !important;
    }

    .pd-lg-x-160 {
        padding-left: 160px;
        padding-right: 160px;
    }

    .pd-lg-x-160-force {
        padding-left: 160px !important;
        padding-right: 160px !important;
    }

    .pd-lg-t-160 {
        padding-top: 160px;
    }

    .pd-lg-r-160 {
        padding-right: 160px;
    }

    .pd-lg-b-160 {
        padding-bottom: 160px;
    }

    .pd-lg-l-160 {
        padding-left: 160px;
    }

    .pd-lg-t-160-force {
        padding-top: 160px !important;
    }

    .pd-lg-r-160-force {
        padding-right: 160px !important;
    }

    .pd-lg-b-160-force {
        padding-bottom: 160px !important;
    }

    .pd-lg-l-160-force {
        padding-left: 160px !important;
    }

    .pd-lg-170 {
        padding: 170px;
    }

    .pd-lg-170-force {
        padding: 170px !important;
    }

    .pd-lg-y-170 {
        padding-top: 170px;
        padding-bottom: 170px;
    }

    .pd-lg-y-170-force {
        padding-top: 170px !important;
        padding-bottom: 170px !important;
    }

    .pd-lg-x-170 {
        padding-left: 170px;
        padding-right: 170px;
    }

    .pd-lg-x-170-force {
        padding-left: 170px !important;
        padding-right: 170px !important;
    }

    .pd-lg-t-170 {
        padding-top: 170px;
    }

    .pd-lg-r-170 {
        padding-right: 170px;
    }

    .pd-lg-b-170 {
        padding-bottom: 170px;
    }

    .pd-lg-l-170 {
        padding-left: 170px;
    }

    .pd-lg-t-170-force {
        padding-top: 170px !important;
    }

    .pd-lg-r-170-force {
        padding-right: 170px !important;
    }

    .pd-lg-b-170-force {
        padding-bottom: 170px !important;
    }

    .pd-lg-l-170-force {
        padding-left: 170px !important;
    }

    .pd-lg-180 {
        padding: 180px;
    }

    .pd-lg-180-force {
        padding: 180px !important;
    }

    .pd-lg-y-180 {
        padding-top: 180px;
        padding-bottom: 180px;
    }

    .pd-lg-y-180-force {
        padding-top: 180px !important;
        padding-bottom: 180px !important;
    }

    .pd-lg-x-180 {
        padding-left: 180px;
        padding-right: 180px;
    }

    .pd-lg-x-180-force {
        padding-left: 180px !important;
        padding-right: 180px !important;
    }

    .pd-lg-t-180 {
        padding-top: 180px;
    }

    .pd-lg-r-180 {
        padding-right: 180px;
    }

    .pd-lg-b-180 {
        padding-bottom: 180px;
    }

    .pd-lg-l-180 {
        padding-left: 180px;
    }

    .pd-lg-t-180-force {
        padding-top: 180px !important;
    }

    .pd-lg-r-180-force {
        padding-right: 180px !important;
    }

    .pd-lg-b-180-force {
        padding-bottom: 180px !important;
    }

    .pd-lg-l-180-force {
        padding-left: 180px !important;
    }

    .pd-lg-190 {
        padding: 190px;
    }

    .pd-lg-190-force {
        padding: 190px !important;
    }

    .pd-lg-y-190 {
        padding-top: 190px;
        padding-bottom: 190px;
    }

    .pd-lg-y-190-force {
        padding-top: 190px !important;
        padding-bottom: 190px !important;
    }

    .pd-lg-x-190 {
        padding-left: 190px;
        padding-right: 190px;
    }

    .pd-lg-x-190-force {
        padding-left: 190px !important;
        padding-right: 190px !important;
    }

    .pd-lg-t-190 {
        padding-top: 190px;
    }

    .pd-lg-r-190 {
        padding-right: 190px;
    }

    .pd-lg-b-190 {
        padding-bottom: 190px;
    }

    .pd-lg-l-190 {
        padding-left: 190px;
    }

    .pd-lg-t-190-force {
        padding-top: 190px !important;
    }

    .pd-lg-r-190-force {
        padding-right: 190px !important;
    }

    .pd-lg-b-190-force {
        padding-bottom: 190px !important;
    }

    .pd-lg-l-190-force {
        padding-left: 190px !important;
    }

    .pd-lg-200 {
        padding: 200px;
    }

    .pd-lg-200-force {
        padding: 200px !important;
    }

    .pd-lg-y-200 {
        padding-top: 200px;
        padding-bottom: 200px;
    }

    .pd-lg-y-200-force {
        padding-top: 200px !important;
        padding-bottom: 200px !important;
    }

    .pd-lg-x-200 {
        padding-left: 200px;
        padding-right: 200px;
    }

    .pd-lg-x-200-force {
        padding-left: 200px !important;
        padding-right: 200px !important;
    }

    .pd-lg-t-200 {
        padding-top: 200px;
    }

    .pd-lg-r-200 {
        padding-right: 200px;
    }

    .pd-lg-b-200 {
        padding-bottom: 200px;
    }

    .pd-lg-l-200 {
        padding-left: 200px;
    }

    .pd-lg-t-200-force {
        padding-top: 200px !important;
    }

    .pd-lg-r-200-force {
        padding-right: 200px !important;
    }

    .pd-lg-b-200-force {
        padding-bottom: 200px !important;
    }

    .pd-lg-l-200-force {
        padding-left: 200px !important;
    }
}

@media (min-width: 1200px) {
    .pd-xl-0 {
        padding: 0px;
    }

    .pd-xl-0-force {
        padding: 0px !important;
    }

    .pd-xl-y-0 {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .pd-xl-y-0-force {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .pd-xl-x-0 {
        padding-left: 0px;
        padding-right: 0px;
    }

    .pd-xl-x-0-force {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .pd-xl-t-0 {
        padding-top: 0px;
    }

    .pd-xl-r-0 {
        padding-right: 0px;
    }

    .pd-xl-b-0 {
        padding-bottom: 0px;
    }

    .pd-xl-l-0 {
        padding-left: 0px;
    }

    .pd-xl-t-0-force {
        padding-top: 0px !important;
    }

    .pd-xl-r-0-force {
        padding-right: 0px !important;
    }

    .pd-xl-b-0-force {
        padding-bottom: 0px !important;
    }

    .pd-xl-l-0-force {
        padding-left: 0px !important;
    }

    .pd-xl-1 {
        padding: 1px;
    }

    .pd-xl-1-force {
        padding: 1px !important;
    }

    .pd-xl-y-1 {
        padding-top: 1px;
        padding-bottom: 1px;
    }

    .pd-xl-y-1-force {
        padding-top: 1px !important;
        padding-bottom: 1px !important;
    }

    .pd-xl-x-1 {
        padding-left: 1px;
        padding-right: 1px;
    }

    .pd-xl-x-1-force {
        padding-left: 1px !important;
        padding-right: 1px !important;
    }

    .pd-xl-t-1 {
        padding-top: 1px;
    }

    .pd-xl-r-1 {
        padding-right: 1px;
    }

    .pd-xl-b-1 {
        padding-bottom: 1px;
    }

    .pd-xl-l-1 {
        padding-left: 1px;
    }

    .pd-xl-t-1-force {
        padding-top: 1px !important;
    }

    .pd-xl-r-1-force {
        padding-right: 1px !important;
    }

    .pd-xl-b-1-force {
        padding-bottom: 1px !important;
    }

    .pd-xl-l-1-force {
        padding-left: 1px !important;
    }

    .pd-xl-2 {
        padding: 2px;
    }

    .pd-xl-2-force {
        padding: 2px !important;
    }

    .pd-xl-y-2 {
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .pd-xl-y-2-force {
        padding-top: 2px !important;
        padding-bottom: 2px !important;
    }

    .pd-xl-x-2 {
        padding-left: 2px;
        padding-right: 2px;
    }

    .pd-xl-x-2-force {
        padding-left: 2px !important;
        padding-right: 2px !important;
    }

    .pd-xl-t-2 {
        padding-top: 2px;
    }

    .pd-xl-r-2 {
        padding-right: 2px;
    }

    .pd-xl-b-2 {
        padding-bottom: 2px;
    }

    .pd-xl-l-2 {
        padding-left: 2px;
    }

    .pd-xl-t-2-force {
        padding-top: 2px !important;
    }

    .pd-xl-r-2-force {
        padding-right: 2px !important;
    }

    .pd-xl-b-2-force {
        padding-bottom: 2px !important;
    }

    .pd-xl-l-2-force {
        padding-left: 2px !important;
    }

    .pd-xl-3 {
        padding: 3px;
    }

    .pd-xl-3-force {
        padding: 3px !important;
    }

    .pd-xl-y-3 {
        padding-top: 3px;
        padding-bottom: 3px;
    }

    .pd-xl-y-3-force {
        padding-top: 3px !important;
        padding-bottom: 3px !important;
    }

    .pd-xl-x-3 {
        padding-left: 3px;
        padding-right: 3px;
    }

    .pd-xl-x-3-force {
        padding-left: 3px !important;
        padding-right: 3px !important;
    }

    .pd-xl-t-3 {
        padding-top: 3px;
    }

    .pd-xl-r-3 {
        padding-right: 3px;
    }

    .pd-xl-b-3 {
        padding-bottom: 3px;
    }

    .pd-xl-l-3 {
        padding-left: 3px;
    }

    .pd-xl-t-3-force {
        padding-top: 3px !important;
    }

    .pd-xl-r-3-force {
        padding-right: 3px !important;
    }

    .pd-xl-b-3-force {
        padding-bottom: 3px !important;
    }

    .pd-xl-l-3-force {
        padding-left: 3px !important;
    }

    .pd-xl-4 {
        padding: 4px;
    }

    .pd-xl-4-force {
        padding: 4px !important;
    }

    .pd-xl-y-4 {
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .pd-xl-y-4-force {
        padding-top: 4px !important;
        padding-bottom: 4px !important;
    }

    .pd-xl-x-4 {
        padding-left: 4px;
        padding-right: 4px;
    }

    .pd-xl-x-4-force {
        padding-left: 4px !important;
        padding-right: 4px !important;
    }

    .pd-xl-t-4 {
        padding-top: 4px;
    }

    .pd-xl-r-4 {
        padding-right: 4px;
    }

    .pd-xl-b-4 {
        padding-bottom: 4px;
    }

    .pd-xl-l-4 {
        padding-left: 4px;
    }

    .pd-xl-t-4-force {
        padding-top: 4px !important;
    }

    .pd-xl-r-4-force {
        padding-right: 4px !important;
    }

    .pd-xl-b-4-force {
        padding-bottom: 4px !important;
    }

    .pd-xl-l-4-force {
        padding-left: 4px !important;
    }

    .pd-xl-5 {
        padding: 5px;
    }

    .pd-xl-5-force {
        padding: 5px !important;
    }

    .pd-xl-y-5 {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .pd-xl-y-5-force {
        padding-top: 5px !important;
        padding-bottom: 5px !important;
    }

    .pd-xl-x-5 {
        padding-left: 5px;
        padding-right: 5px;
    }

    .pd-xl-x-5-force {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }

    .pd-xl-t-5 {
        padding-top: 5px;
    }

    .pd-xl-r-5 {
        padding-right: 5px;
    }

    .pd-xl-b-5 {
        padding-bottom: 5px;
    }

    .pd-xl-l-5 {
        padding-left: 5px;
    }

    .pd-xl-t-5-force {
        padding-top: 5px !important;
    }

    .pd-xl-r-5-force {
        padding-right: 5px !important;
    }

    .pd-xl-b-5-force {
        padding-bottom: 5px !important;
    }

    .pd-xl-l-5-force {
        padding-left: 5px !important;
    }

    .pd-xl-6 {
        padding: 6px;
    }

    .pd-xl-6-force {
        padding: 6px !important;
    }

    .pd-xl-y-6 {
        padding-top: 6px;
        padding-bottom: 6px;
    }

    .pd-xl-y-6-force {
        padding-top: 6px !important;
        padding-bottom: 6px !important;
    }

    .pd-xl-x-6 {
        padding-left: 6px;
        padding-right: 6px;
    }

    .pd-xl-x-6-force {
        padding-left: 6px !important;
        padding-right: 6px !important;
    }

    .pd-xl-t-6 {
        padding-top: 6px;
    }

    .pd-xl-r-6 {
        padding-right: 6px;
    }

    .pd-xl-b-6 {
        padding-bottom: 6px;
    }

    .pd-xl-l-6 {
        padding-left: 6px;
    }

    .pd-xl-t-6-force {
        padding-top: 6px !important;
    }

    .pd-xl-r-6-force {
        padding-right: 6px !important;
    }

    .pd-xl-b-6-force {
        padding-bottom: 6px !important;
    }

    .pd-xl-l-6-force {
        padding-left: 6px !important;
    }

    .pd-xl-7 {
        padding: 7px;
    }

    .pd-xl-7-force {
        padding: 7px !important;
    }

    .pd-xl-y-7 {
        padding-top: 7px;
        padding-bottom: 7px;
    }

    .pd-xl-y-7-force {
        padding-top: 7px !important;
        padding-bottom: 7px !important;
    }

    .pd-xl-x-7 {
        padding-left: 7px;
        padding-right: 7px;
    }

    .pd-xl-x-7-force {
        padding-left: 7px !important;
        padding-right: 7px !important;
    }

    .pd-xl-t-7 {
        padding-top: 7px;
    }

    .pd-xl-r-7 {
        padding-right: 7px;
    }

    .pd-xl-b-7 {
        padding-bottom: 7px;
    }

    .pd-xl-l-7 {
        padding-left: 7px;
    }

    .pd-xl-t-7-force {
        padding-top: 7px !important;
    }

    .pd-xl-r-7-force {
        padding-right: 7px !important;
    }

    .pd-xl-b-7-force {
        padding-bottom: 7px !important;
    }

    .pd-xl-l-7-force {
        padding-left: 7px !important;
    }

    .pd-xl-8 {
        padding: 8px;
    }

    .pd-xl-8-force {
        padding: 8px !important;
    }

    .pd-xl-y-8 {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .pd-xl-y-8-force {
        padding-top: 8px !important;
        padding-bottom: 8px !important;
    }

    .pd-xl-x-8 {
        padding-left: 8px;
        padding-right: 8px;
    }

    .pd-xl-x-8-force {
        padding-left: 8px !important;
        padding-right: 8px !important;
    }

    .pd-xl-t-8 {
        padding-top: 8px;
    }

    .pd-xl-r-8 {
        padding-right: 8px;
    }

    .pd-xl-b-8 {
        padding-bottom: 8px;
    }

    .pd-xl-l-8 {
        padding-left: 8px;
    }

    .pd-xl-t-8-force {
        padding-top: 8px !important;
    }

    .pd-xl-r-8-force {
        padding-right: 8px !important;
    }

    .pd-xl-b-8-force {
        padding-bottom: 8px !important;
    }

    .pd-xl-l-8-force {
        padding-left: 8px !important;
    }

    .pd-xl-9 {
        padding: 9px;
    }

    .pd-xl-9-force {
        padding: 9px !important;
    }

    .pd-xl-y-9 {
        padding-top: 9px;
        padding-bottom: 9px;
    }

    .pd-xl-y-9-force {
        padding-top: 9px !important;
        padding-bottom: 9px !important;
    }

    .pd-xl-x-9 {
        padding-left: 9px;
        padding-right: 9px;
    }

    .pd-xl-x-9-force {
        padding-left: 9px !important;
        padding-right: 9px !important;
    }

    .pd-xl-t-9 {
        padding-top: 9px;
    }

    .pd-xl-r-9 {
        padding-right: 9px;
    }

    .pd-xl-b-9 {
        padding-bottom: 9px;
    }

    .pd-xl-l-9 {
        padding-left: 9px;
    }

    .pd-xl-t-9-force {
        padding-top: 9px !important;
    }

    .pd-xl-r-9-force {
        padding-right: 9px !important;
    }

    .pd-xl-b-9-force {
        padding-bottom: 9px !important;
    }

    .pd-xl-l-9-force {
        padding-left: 9px !important;
    }

    .pd-xl-10 {
        padding: 10px;
    }

    .pd-xl-10-force {
        padding: 10px !important;
    }

    .pd-xl-y-10 {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .pd-xl-y-10-force {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }

    .pd-xl-x-10 {
        padding-left: 10px;
        padding-right: 10px;
    }

    .pd-xl-x-10-force {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .pd-xl-t-10 {
        padding-top: 10px;
    }

    .pd-xl-r-10 {
        padding-right: 10px;
    }

    .pd-xl-b-10 {
        padding-bottom: 10px;
    }

    .pd-xl-l-10 {
        padding-left: 10px;
    }

    .pd-xl-t-10-force {
        padding-top: 10px !important;
    }

    .pd-xl-r-10-force {
        padding-right: 10px !important;
    }

    .pd-xl-b-10-force {
        padding-bottom: 10px !important;
    }

    .pd-xl-l-10-force {
        padding-left: 10px !important;
    }

    .pd-xl-15 {
        padding: 15px;
    }

    .pd-xl-15-force {
        padding: 15px !important;
    }

    .pd-xl-y-15 {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .pd-xl-y-15-force {
        padding-top: 15px !important;
        padding-bottom: 15px !important;
    }

    .pd-xl-x-15 {
        padding-left: 15px;
        padding-right: 15px;
    }

    .pd-xl-x-15-force {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .pd-xl-t-15 {
        padding-top: 15px;
    }

    .pd-xl-r-15 {
        padding-right: 15px;
    }

    .pd-xl-b-15 {
        padding-bottom: 15px;
    }

    .pd-xl-l-15 {
        padding-left: 15px;
    }

    .pd-xl-t-15-force {
        padding-top: 15px !important;
    }

    .pd-xl-r-15-force {
        padding-right: 15px !important;
    }

    .pd-xl-b-15-force {
        padding-bottom: 15px !important;
    }

    .pd-xl-l-15-force {
        padding-left: 15px !important;
    }

    .pd-xl-20 {
        padding: 20px;
    }

    .pd-xl-20-force {
        padding: 20px !important;
    }

    .pd-xl-y-20 {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .pd-xl-y-20-force {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }

    .pd-xl-x-20 {
        padding-left: 20px;
        padding-right: 20px;
    }

    .pd-xl-x-20-force {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .pd-xl-t-20 {
        padding-top: 20px;
    }

    .pd-xl-r-20 {
        padding-right: 20px;
    }

    .pd-xl-b-20 {
        padding-bottom: 20px;
    }

    .pd-xl-l-20 {
        padding-left: 20px;
    }

    .pd-xl-t-20-force {
        padding-top: 20px !important;
    }

    .pd-xl-r-20-force {
        padding-right: 20px !important;
    }

    .pd-xl-b-20-force {
        padding-bottom: 20px !important;
    }

    .pd-xl-l-20-force {
        padding-left: 20px !important;
    }

    .pd-xl-25 {
        padding: 25px;
    }

    .pd-xl-25-force {
        padding: 25px !important;
    }

    .pd-xl-y-25 {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .pd-xl-y-25-force {
        padding-top: 25px !important;
        padding-bottom: 25px !important;
    }

    .pd-xl-x-25 {
        padding-left: 25px;
        padding-right: 25px;
    }

    .pd-xl-x-25-force {
        padding-left: 25px !important;
        padding-right: 25px !important;
    }

    .pd-xl-t-25 {
        padding-top: 25px;
    }

    .pd-xl-r-25 {
        padding-right: 25px;
    }

    .pd-xl-b-25 {
        padding-bottom: 25px;
    }

    .pd-xl-l-25 {
        padding-left: 25px;
    }

    .pd-xl-t-25-force {
        padding-top: 25px !important;
    }

    .pd-xl-r-25-force {
        padding-right: 25px !important;
    }

    .pd-xl-b-25-force {
        padding-bottom: 25px !important;
    }

    .pd-xl-l-25-force {
        padding-left: 25px !important;
    }

    .pd-xl-30 {
        padding: 30px;
    }

    .pd-xl-30-force {
        padding: 30px !important;
    }

    .pd-xl-y-30 {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .pd-xl-y-30-force {
        padding-top: 30px !important;
        padding-bottom: 30px !important;
    }

    .pd-xl-x-30 {
        padding-left: 30px;
        padding-right: 30px;
    }

    .pd-xl-x-30-force {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }

    .pd-xl-t-30 {
        padding-top: 30px;
    }

    .pd-xl-r-30 {
        padding-right: 30px;
    }

    .pd-xl-b-30 {
        padding-bottom: 30px;
    }

    .pd-xl-l-30 {
        padding-left: 30px;
    }

    .pd-xl-t-30-force {
        padding-top: 30px !important;
    }

    .pd-xl-r-30-force {
        padding-right: 30px !important;
    }

    .pd-xl-b-30-force {
        padding-bottom: 30px !important;
    }

    .pd-xl-l-30-force {
        padding-left: 30px !important;
    }

    .pd-xl-35 {
        padding: 35px;
    }

    .pd-xl-35-force {
        padding: 35px !important;
    }

    .pd-xl-y-35 {
        padding-top: 35px;
        padding-bottom: 35px;
    }

    .pd-xl-y-35-force {
        padding-top: 35px !important;
        padding-bottom: 35px !important;
    }

    .pd-xl-x-35 {
        padding-left: 35px;
        padding-right: 35px;
    }

    .pd-xl-x-35-force {
        padding-left: 35px !important;
        padding-right: 35px !important;
    }

    .pd-xl-t-35 {
        padding-top: 35px;
    }

    .pd-xl-r-35 {
        padding-right: 35px;
    }

    .pd-xl-b-35 {
        padding-bottom: 35px;
    }

    .pd-xl-l-35 {
        padding-left: 35px;
    }

    .pd-xl-t-35-force {
        padding-top: 35px !important;
    }

    .pd-xl-r-35-force {
        padding-right: 35px !important;
    }

    .pd-xl-b-35-force {
        padding-bottom: 35px !important;
    }

    .pd-xl-l-35-force {
        padding-left: 35px !important;
    }

    .pd-xl-40 {
        padding: 40px;
    }

    .pd-xl-40-force {
        padding: 40px !important;
    }

    .pd-xl-y-40 {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .pd-xl-y-40-force {
        padding-top: 40px !important;
        padding-bottom: 40px !important;
    }

    .pd-xl-x-40 {
        padding-left: 40px;
        padding-right: 40px;
    }

    .pd-xl-x-40-force {
        padding-left: 40px !important;
        padding-right: 40px !important;
    }

    .pd-xl-t-40 {
        padding-top: 40px;
    }

    .pd-xl-r-40 {
        padding-right: 40px;
    }

    .pd-xl-b-40 {
        padding-bottom: 40px;
    }

    .pd-xl-l-40 {
        padding-left: 40px;
    }

    .pd-xl-t-40-force {
        padding-top: 40px !important;
    }

    .pd-xl-r-40-force {
        padding-right: 40px !important;
    }

    .pd-xl-b-40-force {
        padding-bottom: 40px !important;
    }

    .pd-xl-l-40-force {
        padding-left: 40px !important;
    }

    .pd-xl-45 {
        padding: 45px;
    }

    .pd-xl-45-force {
        padding: 45px !important;
    }

    .pd-xl-y-45 {
        padding-top: 45px;
        padding-bottom: 45px;
    }

    .pd-xl-y-45-force {
        padding-top: 45px !important;
        padding-bottom: 45px !important;
    }

    .pd-xl-x-45 {
        padding-left: 45px;
        padding-right: 45px;
    }

    .pd-xl-x-45-force {
        padding-left: 45px !important;
        padding-right: 45px !important;
    }

    .pd-xl-t-45 {
        padding-top: 45px;
    }

    .pd-xl-r-45 {
        padding-right: 45px;
    }

    .pd-xl-b-45 {
        padding-bottom: 45px;
    }

    .pd-xl-l-45 {
        padding-left: 45px;
    }

    .pd-xl-t-45-force {
        padding-top: 45px !important;
    }

    .pd-xl-r-45-force {
        padding-right: 45px !important;
    }

    .pd-xl-b-45-force {
        padding-bottom: 45px !important;
    }

    .pd-xl-l-45-force {
        padding-left: 45px !important;
    }

    .pd-xl-50 {
        padding: 50px;
    }

    .pd-xl-50-force {
        padding: 50px !important;
    }

    .pd-xl-y-50 {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .pd-xl-y-50-force {
        padding-top: 50px !important;
        padding-bottom: 50px !important;
    }

    .pd-xl-x-50 {
        padding-left: 50px;
        padding-right: 50px;
    }

    .pd-xl-x-50-force {
        padding-left: 50px !important;
        padding-right: 50px !important;
    }

    .pd-xl-t-50 {
        padding-top: 50px;
    }

    .pd-xl-r-50 {
        padding-right: 50px;
    }

    .pd-xl-b-50 {
        padding-bottom: 50px;
    }

    .pd-xl-l-50 {
        padding-left: 50px;
    }

    .pd-xl-t-50-force {
        padding-top: 50px !important;
    }

    .pd-xl-r-50-force {
        padding-right: 50px !important;
    }

    .pd-xl-b-50-force {
        padding-bottom: 50px !important;
    }

    .pd-xl-l-50-force {
        padding-left: 50px !important;
    }

    .pd-xl-55 {
        padding: 55px;
    }

    .pd-xl-55-force {
        padding: 55px !important;
    }

    .pd-xl-y-55 {
        padding-top: 55px;
        padding-bottom: 55px;
    }

    .pd-xl-y-55-force {
        padding-top: 55px !important;
        padding-bottom: 55px !important;
    }

    .pd-xl-x-55 {
        padding-left: 55px;
        padding-right: 55px;
    }

    .pd-xl-x-55-force {
        padding-left: 55px !important;
        padding-right: 55px !important;
    }

    .pd-xl-t-55 {
        padding-top: 55px;
    }

    .pd-xl-r-55 {
        padding-right: 55px;
    }

    .pd-xl-b-55 {
        padding-bottom: 55px;
    }

    .pd-xl-l-55 {
        padding-left: 55px;
    }

    .pd-xl-t-55-force {
        padding-top: 55px !important;
    }

    .pd-xl-r-55-force {
        padding-right: 55px !important;
    }

    .pd-xl-b-55-force {
        padding-bottom: 55px !important;
    }

    .pd-xl-l-55-force {
        padding-left: 55px !important;
    }

    .pd-xl-60 {
        padding: 60px;
    }

    .pd-xl-60-force {
        padding: 60px !important;
    }

    .pd-xl-y-60 {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .pd-xl-y-60-force {
        padding-top: 60px !important;
        padding-bottom: 60px !important;
    }

    .pd-xl-x-60 {
        padding-left: 60px;
        padding-right: 60px;
    }

    .pd-xl-x-60-force {
        padding-left: 60px !important;
        padding-right: 60px !important;
    }

    .pd-xl-t-60 {
        padding-top: 60px;
    }

    .pd-xl-r-60 {
        padding-right: 60px;
    }

    .pd-xl-b-60 {
        padding-bottom: 60px;
    }

    .pd-xl-l-60 {
        padding-left: 60px;
    }

    .pd-xl-t-60-force {
        padding-top: 60px !important;
    }

    .pd-xl-r-60-force {
        padding-right: 60px !important;
    }

    .pd-xl-b-60-force {
        padding-bottom: 60px !important;
    }

    .pd-xl-l-60-force {
        padding-left: 60px !important;
    }

    .pd-xl-65 {
        padding: 65px;
    }

    .pd-xl-65-force {
        padding: 65px !important;
    }

    .pd-xl-y-65 {
        padding-top: 65px;
        padding-bottom: 65px;
    }

    .pd-xl-y-65-force {
        padding-top: 65px !important;
        padding-bottom: 65px !important;
    }

    .pd-xl-x-65 {
        padding-left: 65px;
        padding-right: 65px;
    }

    .pd-xl-x-65-force {
        padding-left: 65px !important;
        padding-right: 65px !important;
    }

    .pd-xl-t-65 {
        padding-top: 65px;
    }

    .pd-xl-r-65 {
        padding-right: 65px;
    }

    .pd-xl-b-65 {
        padding-bottom: 65px;
    }

    .pd-xl-l-65 {
        padding-left: 65px;
    }

    .pd-xl-t-65-force {
        padding-top: 65px !important;
    }

    .pd-xl-r-65-force {
        padding-right: 65px !important;
    }

    .pd-xl-b-65-force {
        padding-bottom: 65px !important;
    }

    .pd-xl-l-65-force {
        padding-left: 65px !important;
    }

    .pd-xl-70 {
        padding: 70px;
    }

    .pd-xl-70-force {
        padding: 70px !important;
    }

    .pd-xl-y-70 {
        padding-top: 70px;
        padding-bottom: 70px;
    }

    .pd-xl-y-70-force {
        padding-top: 70px !important;
        padding-bottom: 70px !important;
    }

    .pd-xl-x-70 {
        padding-left: 70px;
        padding-right: 70px;
    }

    .pd-xl-x-70-force {
        padding-left: 70px !important;
        padding-right: 70px !important;
    }

    .pd-xl-t-70 {
        padding-top: 70px;
    }

    .pd-xl-r-70 {
        padding-right: 70px;
    }

    .pd-xl-b-70 {
        padding-bottom: 70px;
    }

    .pd-xl-l-70 {
        padding-left: 70px;
    }

    .pd-xl-t-70-force {
        padding-top: 70px !important;
    }

    .pd-xl-r-70-force {
        padding-right: 70px !important;
    }

    .pd-xl-b-70-force {
        padding-bottom: 70px !important;
    }

    .pd-xl-l-70-force {
        padding-left: 70px !important;
    }

    .pd-xl-75 {
        padding: 75px;
    }

    .pd-xl-75-force {
        padding: 75px !important;
    }

    .pd-xl-y-75 {
        padding-top: 75px;
        padding-bottom: 75px;
    }

    .pd-xl-y-75-force {
        padding-top: 75px !important;
        padding-bottom: 75px !important;
    }

    .pd-xl-x-75 {
        padding-left: 75px;
        padding-right: 75px;
    }

    .pd-xl-x-75-force {
        padding-left: 75px !important;
        padding-right: 75px !important;
    }

    .pd-xl-t-75 {
        padding-top: 75px;
    }

    .pd-xl-r-75 {
        padding-right: 75px;
    }

    .pd-xl-b-75 {
        padding-bottom: 75px;
    }

    .pd-xl-l-75 {
        padding-left: 75px;
    }

    .pd-xl-t-75-force {
        padding-top: 75px !important;
    }

    .pd-xl-r-75-force {
        padding-right: 75px !important;
    }

    .pd-xl-b-75-force {
        padding-bottom: 75px !important;
    }

    .pd-xl-l-75-force {
        padding-left: 75px !important;
    }

    .pd-xl-80 {
        padding: 80px;
    }

    .pd-xl-80-force {
        padding: 80px !important;
    }

    .pd-xl-y-80 {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .pd-xl-y-80-force {
        padding-top: 80px !important;
        padding-bottom: 80px !important;
    }

    .pd-xl-x-80 {
        padding-left: 80px;
        padding-right: 80px;
    }

    .pd-xl-x-80-force {
        padding-left: 80px !important;
        padding-right: 80px !important;
    }

    .pd-xl-t-80 {
        padding-top: 80px;
    }

    .pd-xl-r-80 {
        padding-right: 80px;
    }

    .pd-xl-b-80 {
        padding-bottom: 80px;
    }

    .pd-xl-l-80 {
        padding-left: 80px;
    }

    .pd-xl-t-80-force {
        padding-top: 80px !important;
    }

    .pd-xl-r-80-force {
        padding-right: 80px !important;
    }

    .pd-xl-b-80-force {
        padding-bottom: 80px !important;
    }

    .pd-xl-l-80-force {
        padding-left: 80px !important;
    }

    .pd-xl-85 {
        padding: 85px;
    }

    .pd-xl-85-force {
        padding: 85px !important;
    }

    .pd-xl-y-85 {
        padding-top: 85px;
        padding-bottom: 85px;
    }

    .pd-xl-y-85-force {
        padding-top: 85px !important;
        padding-bottom: 85px !important;
    }

    .pd-xl-x-85 {
        padding-left: 85px;
        padding-right: 85px;
    }

    .pd-xl-x-85-force {
        padding-left: 85px !important;
        padding-right: 85px !important;
    }

    .pd-xl-t-85 {
        padding-top: 85px;
    }

    .pd-xl-r-85 {
        padding-right: 85px;
    }

    .pd-xl-b-85 {
        padding-bottom: 85px;
    }

    .pd-xl-l-85 {
        padding-left: 85px;
    }

    .pd-xl-t-85-force {
        padding-top: 85px !important;
    }

    .pd-xl-r-85-force {
        padding-right: 85px !important;
    }

    .pd-xl-b-85-force {
        padding-bottom: 85px !important;
    }

    .pd-xl-l-85-force {
        padding-left: 85px !important;
    }

    .pd-xl-90 {
        padding: 90px;
    }

    .pd-xl-90-force {
        padding: 90px !important;
    }

    .pd-xl-y-90 {
        padding-top: 90px;
        padding-bottom: 90px;
    }

    .pd-xl-y-90-force {
        padding-top: 90px !important;
        padding-bottom: 90px !important;
    }

    .pd-xl-x-90 {
        padding-left: 90px;
        padding-right: 90px;
    }

    .pd-xl-x-90-force {
        padding-left: 90px !important;
        padding-right: 90px !important;
    }

    .pd-xl-t-90 {
        padding-top: 90px;
    }

    .pd-xl-r-90 {
        padding-right: 90px;
    }

    .pd-xl-b-90 {
        padding-bottom: 90px;
    }

    .pd-xl-l-90 {
        padding-left: 90px;
    }

    .pd-xl-t-90-force {
        padding-top: 90px !important;
    }

    .pd-xl-r-90-force {
        padding-right: 90px !important;
    }

    .pd-xl-b-90-force {
        padding-bottom: 90px !important;
    }

    .pd-xl-l-90-force {
        padding-left: 90px !important;
    }

    .pd-xl-95 {
        padding: 95px;
    }

    .pd-xl-95-force {
        padding: 95px !important;
    }

    .pd-xl-y-95 {
        padding-top: 95px;
        padding-bottom: 95px;
    }

    .pd-xl-y-95-force {
        padding-top: 95px !important;
        padding-bottom: 95px !important;
    }

    .pd-xl-x-95 {
        padding-left: 95px;
        padding-right: 95px;
    }

    .pd-xl-x-95-force {
        padding-left: 95px !important;
        padding-right: 95px !important;
    }

    .pd-xl-t-95 {
        padding-top: 95px;
    }

    .pd-xl-r-95 {
        padding-right: 95px;
    }

    .pd-xl-b-95 {
        padding-bottom: 95px;
    }

    .pd-xl-l-95 {
        padding-left: 95px;
    }

    .pd-xl-t-95-force {
        padding-top: 95px !important;
    }

    .pd-xl-r-95-force {
        padding-right: 95px !important;
    }

    .pd-xl-b-95-force {
        padding-bottom: 95px !important;
    }

    .pd-xl-l-95-force {
        padding-left: 95px !important;
    }

    .pd-xl-100 {
        padding: 100px;
    }

    .pd-xl-100-force {
        padding: 100px !important;
    }

    .pd-xl-y-100 {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .pd-xl-y-100-force {
        padding-top: 100px !important;
        padding-bottom: 100px !important;
    }

    .pd-xl-x-100 {
        padding-left: 100px;
        padding-right: 100px;
    }

    .pd-xl-x-100-force {
        padding-left: 100px !important;
        padding-right: 100px !important;
    }

    .pd-xl-t-100 {
        padding-top: 100px;
    }

    .pd-xl-r-100 {
        padding-right: 100px;
    }

    .pd-xl-b-100 {
        padding-bottom: 100px;
    }

    .pd-xl-l-100 {
        padding-left: 100px;
    }

    .pd-xl-t-100-force {
        padding-top: 100px !important;
    }

    .pd-xl-r-100-force {
        padding-right: 100px !important;
    }

    .pd-xl-b-100-force {
        padding-bottom: 100px !important;
    }

    .pd-xl-l-100-force {
        padding-left: 100px !important;
    }

    .pd-xl-110 {
        padding: 110px;
    }

    .pd-xl-110-force {
        padding: 110px !important;
    }

    .pd-xl-y-110 {
        padding-top: 110px;
        padding-bottom: 110px;
    }

    .pd-xl-y-110-force {
        padding-top: 110px !important;
        padding-bottom: 110px !important;
    }

    .pd-xl-x-110 {
        padding-left: 110px;
        padding-right: 110px;
    }

    .pd-xl-x-110-force {
        padding-left: 110px !important;
        padding-right: 110px !important;
    }

    .pd-xl-t-110 {
        padding-top: 110px;
    }

    .pd-xl-r-110 {
        padding-right: 110px;
    }

    .pd-xl-b-110 {
        padding-bottom: 110px;
    }

    .pd-xl-l-110 {
        padding-left: 110px;
    }

    .pd-xl-t-110-force {
        padding-top: 110px !important;
    }

    .pd-xl-r-110-force {
        padding-right: 110px !important;
    }

    .pd-xl-b-110-force {
        padding-bottom: 110px !important;
    }

    .pd-xl-l-110-force {
        padding-left: 110px !important;
    }

    .pd-xl-120 {
        padding: 120px;
    }

    .pd-xl-120-force {
        padding: 120px !important;
    }

    .pd-xl-y-120 {
        padding-top: 120px;
        padding-bottom: 120px;
    }

    .pd-xl-y-120-force {
        padding-top: 120px !important;
        padding-bottom: 120px !important;
    }

    .pd-xl-x-120 {
        padding-left: 120px;
        padding-right: 120px;
    }

    .pd-xl-x-120-force {
        padding-left: 120px !important;
        padding-right: 120px !important;
    }

    .pd-xl-t-120 {
        padding-top: 120px;
    }

    .pd-xl-r-120 {
        padding-right: 120px;
    }

    .pd-xl-b-120 {
        padding-bottom: 120px;
    }

    .pd-xl-l-120 {
        padding-left: 120px;
    }

    .pd-xl-t-120-force {
        padding-top: 120px !important;
    }

    .pd-xl-r-120-force {
        padding-right: 120px !important;
    }

    .pd-xl-b-120-force {
        padding-bottom: 120px !important;
    }

    .pd-xl-l-120-force {
        padding-left: 120px !important;
    }

    .pd-xl-130 {
        padding: 130px;
    }

    .pd-xl-130-force {
        padding: 130px !important;
    }

    .pd-xl-y-130 {
        padding-top: 130px;
        padding-bottom: 130px;
    }

    .pd-xl-y-130-force {
        padding-top: 130px !important;
        padding-bottom: 130px !important;
    }

    .pd-xl-x-130 {
        padding-left: 130px;
        padding-right: 130px;
    }

    .pd-xl-x-130-force {
        padding-left: 130px !important;
        padding-right: 130px !important;
    }

    .pd-xl-t-130 {
        padding-top: 130px;
    }

    .pd-xl-r-130 {
        padding-right: 130px;
    }

    .pd-xl-b-130 {
        padding-bottom: 130px;
    }

    .pd-xl-l-130 {
        padding-left: 130px;
    }

    .pd-xl-t-130-force {
        padding-top: 130px !important;
    }

    .pd-xl-r-130-force {
        padding-right: 130px !important;
    }

    .pd-xl-b-130-force {
        padding-bottom: 130px !important;
    }

    .pd-xl-l-130-force {
        padding-left: 130px !important;
    }

    .pd-xl-140 {
        padding: 140px;
    }

    .pd-xl-140-force {
        padding: 140px !important;
    }

    .pd-xl-y-140 {
        padding-top: 140px;
        padding-bottom: 140px;
    }

    .pd-xl-y-140-force {
        padding-top: 140px !important;
        padding-bottom: 140px !important;
    }

    .pd-xl-x-140 {
        padding-left: 140px;
        padding-right: 140px;
    }

    .pd-xl-x-140-force {
        padding-left: 140px !important;
        padding-right: 140px !important;
    }

    .pd-xl-t-140 {
        padding-top: 140px;
    }

    .pd-xl-r-140 {
        padding-right: 140px;
    }

    .pd-xl-b-140 {
        padding-bottom: 140px;
    }

    .pd-xl-l-140 {
        padding-left: 140px;
    }

    .pd-xl-t-140-force {
        padding-top: 140px !important;
    }

    .pd-xl-r-140-force {
        padding-right: 140px !important;
    }

    .pd-xl-b-140-force {
        padding-bottom: 140px !important;
    }

    .pd-xl-l-140-force {
        padding-left: 140px !important;
    }

    .pd-xl-150 {
        padding: 150px;
    }

    .pd-xl-150-force {
        padding: 150px !important;
    }

    .pd-xl-y-150 {
        padding-top: 150px;
        padding-bottom: 150px;
    }

    .pd-xl-y-150-force {
        padding-top: 150px !important;
        padding-bottom: 150px !important;
    }

    .pd-xl-x-150 {
        padding-left: 150px;
        padding-right: 150px;
    }

    .pd-xl-x-150-force {
        padding-left: 150px !important;
        padding-right: 150px !important;
    }

    .pd-xl-t-150 {
        padding-top: 150px;
    }

    .pd-xl-r-150 {
        padding-right: 150px;
    }

    .pd-xl-b-150 {
        padding-bottom: 150px;
    }

    .pd-xl-l-150 {
        padding-left: 150px;
    }

    .pd-xl-t-150-force {
        padding-top: 150px !important;
    }

    .pd-xl-r-150-force {
        padding-right: 150px !important;
    }

    .pd-xl-b-150-force {
        padding-bottom: 150px !important;
    }

    .pd-xl-l-150-force {
        padding-left: 150px !important;
    }

    .pd-xl-160 {
        padding: 160px;
    }

    .pd-xl-160-force {
        padding: 160px !important;
    }

    .pd-xl-y-160 {
        padding-top: 160px;
        padding-bottom: 160px;
    }

    .pd-xl-y-160-force {
        padding-top: 160px !important;
        padding-bottom: 160px !important;
    }

    .pd-xl-x-160 {
        padding-left: 160px;
        padding-right: 160px;
    }

    .pd-xl-x-160-force {
        padding-left: 160px !important;
        padding-right: 160px !important;
    }

    .pd-xl-t-160 {
        padding-top: 160px;
    }

    .pd-xl-r-160 {
        padding-right: 160px;
    }

    .pd-xl-b-160 {
        padding-bottom: 160px;
    }

    .pd-xl-l-160 {
        padding-left: 160px;
    }

    .pd-xl-t-160-force {
        padding-top: 160px !important;
    }

    .pd-xl-r-160-force {
        padding-right: 160px !important;
    }

    .pd-xl-b-160-force {
        padding-bottom: 160px !important;
    }

    .pd-xl-l-160-force {
        padding-left: 160px !important;
    }

    .pd-xl-170 {
        padding: 170px;
    }

    .pd-xl-170-force {
        padding: 170px !important;
    }

    .pd-xl-y-170 {
        padding-top: 170px;
        padding-bottom: 170px;
    }

    .pd-xl-y-170-force {
        padding-top: 170px !important;
        padding-bottom: 170px !important;
    }

    .pd-xl-x-170 {
        padding-left: 170px;
        padding-right: 170px;
    }

    .pd-xl-x-170-force {
        padding-left: 170px !important;
        padding-right: 170px !important;
    }

    .pd-xl-t-170 {
        padding-top: 170px;
    }

    .pd-xl-r-170 {
        padding-right: 170px;
    }

    .pd-xl-b-170 {
        padding-bottom: 170px;
    }

    .pd-xl-l-170 {
        padding-left: 170px;
    }

    .pd-xl-t-170-force {
        padding-top: 170px !important;
    }

    .pd-xl-r-170-force {
        padding-right: 170px !important;
    }

    .pd-xl-b-170-force {
        padding-bottom: 170px !important;
    }

    .pd-xl-l-170-force {
        padding-left: 170px !important;
    }

    .pd-xl-180 {
        padding: 180px;
    }

    .pd-xl-180-force {
        padding: 180px !important;
    }

    .pd-xl-y-180 {
        padding-top: 180px;
        padding-bottom: 180px;
    }

    .pd-xl-y-180-force {
        padding-top: 180px !important;
        padding-bottom: 180px !important;
    }

    .pd-xl-x-180 {
        padding-left: 180px;
        padding-right: 180px;
    }

    .pd-xl-x-180-force {
        padding-left: 180px !important;
        padding-right: 180px !important;
    }

    .pd-xl-t-180 {
        padding-top: 180px;
    }

    .pd-xl-r-180 {
        padding-right: 180px;
    }

    .pd-xl-b-180 {
        padding-bottom: 180px;
    }

    .pd-xl-l-180 {
        padding-left: 180px;
    }

    .pd-xl-t-180-force {
        padding-top: 180px !important;
    }

    .pd-xl-r-180-force {
        padding-right: 180px !important;
    }

    .pd-xl-b-180-force {
        padding-bottom: 180px !important;
    }

    .pd-xl-l-180-force {
        padding-left: 180px !important;
    }

    .pd-xl-190 {
        padding: 190px;
    }

    .pd-xl-190-force {
        padding: 190px !important;
    }

    .pd-xl-y-190 {
        padding-top: 190px;
        padding-bottom: 190px;
    }

    .pd-xl-y-190-force {
        padding-top: 190px !important;
        padding-bottom: 190px !important;
    }

    .pd-xl-x-190 {
        padding-left: 190px;
        padding-right: 190px;
    }

    .pd-xl-x-190-force {
        padding-left: 190px !important;
        padding-right: 190px !important;
    }

    .pd-xl-t-190 {
        padding-top: 190px;
    }

    .pd-xl-r-190 {
        padding-right: 190px;
    }

    .pd-xl-b-190 {
        padding-bottom: 190px;
    }

    .pd-xl-l-190 {
        padding-left: 190px;
    }

    .pd-xl-t-190-force {
        padding-top: 190px !important;
    }

    .pd-xl-r-190-force {
        padding-right: 190px !important;
    }

    .pd-xl-b-190-force {
        padding-bottom: 190px !important;
    }

    .pd-xl-l-190-force {
        padding-left: 190px !important;
    }

    .pd-xl-200 {
        padding: 200px;
    }

    .pd-xl-200-force {
        padding: 200px !important;
    }

    .pd-xl-y-200 {
        padding-top: 200px;
        padding-bottom: 200px;
    }

    .pd-xl-y-200-force {
        padding-top: 200px !important;
        padding-bottom: 200px !important;
    }

    .pd-xl-x-200 {
        padding-left: 200px;
        padding-right: 200px;
    }

    .pd-xl-x-200-force {
        padding-left: 200px !important;
        padding-right: 200px !important;
    }

    .pd-xl-t-200 {
        padding-top: 200px;
    }

    .pd-xl-r-200 {
        padding-right: 200px;
    }

    .pd-xl-b-200 {
        padding-bottom: 200px;
    }

    .pd-xl-l-200 {
        padding-left: 200px;
    }

    .pd-xl-t-200-force {
        padding-top: 200px !important;
    }

    .pd-xl-r-200-force {
        padding-right: 200px !important;
    }

    .pd-xl-b-200-force {
        padding-bottom: 200px !important;
    }

    .pd-xl-l-200-force {
        padding-left: 200px !important;
    }
}

.pd-12 {
    padding: 12px;
}

.pd-12-force {
    padding: 12px !important;
}

.pd-y-12 {
    padding-top: 12px;
    padding-bottom: 12px;
}

.pd-y-12-force {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}

.pd-x-12 {
    padding-left: 12px;
    padding-right: 12px;
}

.pd-x-12-force {
    padding-left: 12px !important;
    padding-right: 12px !important;
}

.pd-t-12 {
    padding-top: 12px;
}

.pd-r-12 {
    padding-right: 12px;
}

.pd-b-12 {
    padding-bottom: 12px;
}

.pd-l-12 {
    padding-left: 12px;
}

.pd-t-12-force {
    padding-top: 12px !important;
}

.pd-r-12-force {
    padding-right: 12px !important;
}

.pd-b-12-force {
    padding-bottom: 12px !important;
}

.pd-l-12-force {
    padding-left: 12px !important;
}

.pd-13 {
    padding: 13px;
}

.pd-13-force {
    padding: 13px !important;
}

.pd-y-13 {
    padding-top: 13px;
    padding-bottom: 13px;
}

.pd-y-13-force {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
}

.pd-x-13 {
    padding-left: 13px;
    padding-right: 13px;
}

.pd-x-13-force {
    padding-left: 13px !important;
    padding-right: 13px !important;
}

.pd-t-13 {
    padding-top: 13px;
}

.pd-r-13 {
    padding-right: 13px;
}

.pd-b-13 {
    padding-bottom: 13px;
}

.pd-l-13 {
    padding-left: 13px;
}

.pd-t-13-force {
    padding-top: 13px !important;
}

.pd-r-13-force {
    padding-right: 13px !important;
}

.pd-b-13-force {
    padding-bottom: 13px !important;
}

.pd-l-13-force {
    padding-left: 13px !important;
}


/* -------------------------------------------- */


/* ############### 7.8 Position ############### */


/* -------------------------------------------- */

.pos-absolute {
    position: absolute;
}

.pos-relative {
    position: relative;
}

.pos-fixed {
    position: fixed;
}

.pos-static {
    position: static;
}

.pos-absolute-force {
    position: absolute !important;
}

.pos-relative-force {
    position: relative !important;
}

.pos-fixed-force {
    position: fixed !important;
}

.pos-static-force {
    position: static !important;
}

.t-0 {
    top: 0px;
}

.r-0 {
    right: 0px;
}

.b-0 {
    bottom: 0px;
}

.l-0 {
    left: 0px;
}

.t--0 {
    top: -0px;
}

.r--0 {
    right: -0px;
}

.b--0 {
    bottom: -0px;
}

.l--0 {
    left: -0px;
}

.a-0 {
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.x-0 {
    left: 0px;
    right: 0px;
}

.y-0 {
    left: 0px;
    right: 0px;
}

.t-5 {
    top: 5px;
}

.r-5 {
    right: 5px;
}

.b-5 {
    bottom: 5px;
}

.l-5 {
    left: 5px;
}

.t--5 {
    top: -5px;
}

.r--5 {
    right: -5px;
}

.b--5 {
    bottom: -5px;
}

.l--5 {
    left: -5px;
}

.a-5 {
    top: 5px;
    right: 5px;
    bottom: 5px;
    left: 5px;
}

.x-5 {
    left: 5px;
    right: 5px;
}

.y-5 {
    left: 5px;
    right: 5px;
}

.t-10 {
    top: 10px;
}

.r-10 {
    right: 10px;
}

.b-10 {
    bottom: 10px;
}

.l-10 {
    left: 10px;
}

.t--10 {
    top: -10px;
}

.r--10 {
    right: -10px;
}

.b--10 {
    bottom: -10px;
}

.l--10 {
    left: -10px;
}

.a-10 {
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px;
}

.x-10 {
    left: 10px;
    right: 10px;
}

.y-10 {
    left: 10px;
    right: 10px;
}

.t-15 {
    top: 15px;
}

.r-15 {
    right: 15px;
}

.b-15 {
    bottom: 15px;
}

.l-15 {
    left: 15px;
}

.t--15 {
    top: -15px;
}

.r--15 {
    right: -15px;
}

.b--15 {
    bottom: -15px;
}

.l--15 {
    left: -15px;
}

.a-15 {
    top: 15px;
    right: 15px;
    bottom: 15px;
    left: 15px;
}

.x-15 {
    left: 15px;
    right: 15px;
}

.y-15 {
    left: 15px;
    right: 15px;
}

.t-20 {
    top: 20px;
}

.r-20 {
    right: 20px;
}

.b-20 {
    bottom: 20px;
}

.l-20 {
    left: 20px;
}

.t--20 {
    top: -20px;
}

.r--20 {
    right: -20px;
}

.b--20 {
    bottom: -20px;
}

.l--20 {
    left: -20px;
}

.a-20 {
    top: 20px;
    right: 20px;
    bottom: 20px;
    left: 20px;
}

.x-20 {
    left: 20px;
    right: 20px;
}

.y-20 {
    left: 20px;
    right: 20px;
}

.t-25 {
    top: 25px;
}

.r-25 {
    right: 25px;
}

.b-25 {
    bottom: 25px;
}

.l-25 {
    left: 25px;
}

.t--25 {
    top: -25px;
}

.r--25 {
    right: -25px;
}

.b--25 {
    bottom: -25px;
}

.l--25 {
    left: -25px;
}

.a-25 {
    top: 25px;
    right: 25px;
    bottom: 25px;
    left: 25px;
}

.x-25 {
    left: 25px;
    right: 25px;
}

.y-25 {
    left: 25px;
    right: 25px;
}

.t-30 {
    top: 30px;
}

.r-30 {
    right: 30px;
}

.b-30 {
    bottom: 30px;
}

.l-30 {
    left: 30px;
}

.t--30 {
    top: -30px;
}

.r--30 {
    right: -30px;
}

.b--30 {
    bottom: -30px;
}

.l--30 {
    left: -30px;
}

.a-30 {
    top: 30px;
    right: 30px;
    bottom: 30px;
    left: 30px;
}

.x-30 {
    left: 30px;
    right: 30px;
}

.y-30 {
    left: 30px;
    right: 30px;
}

.t-35 {
    top: 35px;
}

.r-35 {
    right: 35px;
}

.b-35 {
    bottom: 35px;
}

.l-35 {
    left: 35px;
}

.t--35 {
    top: -35px;
}

.r--35 {
    right: -35px;
}

.b--35 {
    bottom: -35px;
}

.l--35 {
    left: -35px;
}

.a-35 {
    top: 35px;
    right: 35px;
    bottom: 35px;
    left: 35px;
}

.x-35 {
    left: 35px;
    right: 35px;
}

.y-35 {
    left: 35px;
    right: 35px;
}

.t-40 {
    top: 40px;
}

.r-40 {
    right: 40px;
}

.b-40 {
    bottom: 40px;
}

.l-40 {
    left: 40px;
}

.t--40 {
    top: -40px;
}

.r--40 {
    right: -40px;
}

.b--40 {
    bottom: -40px;
}

.l--40 {
    left: -40px;
}

.a-40 {
    top: 40px;
    right: 40px;
    bottom: 40px;
    left: 40px;
}

.x-40 {
    left: 40px;
    right: 40px;
}

.y-40 {
    left: 40px;
    right: 40px;
}

.t-45 {
    top: 45px;
}

.r-45 {
    right: 45px;
}

.b-45 {
    bottom: 45px;
}

.l-45 {
    left: 45px;
}

.t--45 {
    top: -45px;
}

.r--45 {
    right: -45px;
}

.b--45 {
    bottom: -45px;
}

.l--45 {
    left: -45px;
}

.a-45 {
    top: 45px;
    right: 45px;
    bottom: 45px;
    left: 45px;
}

.x-45 {
    left: 45px;
    right: 45px;
}

.y-45 {
    left: 45px;
    right: 45px;
}

.t-50 {
    top: 50px;
}

.r-50 {
    right: 50px;
}

.b-50 {
    bottom: 50px;
}

.l-50 {
    left: 50px;
}

.t--50 {
    top: -50px;
}

.r--50 {
    right: -50px;
}

.b--50 {
    bottom: -50px;
}

.l--50 {
    left: -50px;
}

.a-50 {
    top: 50px;
    right: 50px;
    bottom: 50px;
    left: 50px;
}

.x-50 {
    left: 50px;
    right: 50px;
}

.y-50 {
    left: 50px;
    right: 50px;
}

.t-55 {
    top: 55px;
}

.r-55 {
    right: 55px;
}

.b-55 {
    bottom: 55px;
}

.l-55 {
    left: 55px;
}

.t--55 {
    top: -55px;
}

.r--55 {
    right: -55px;
}

.b--55 {
    bottom: -55px;
}

.l--55 {
    left: -55px;
}

.a-55 {
    top: 55px;
    right: 55px;
    bottom: 55px;
    left: 55px;
}

.x-55 {
    left: 55px;
    right: 55px;
}

.y-55 {
    left: 55px;
    right: 55px;
}

.t-60 {
    top: 60px;
}

.r-60 {
    right: 60px;
}

.b-60 {
    bottom: 60px;
}

.l-60 {
    left: 60px;
}

.t--60 {
    top: -60px;
}

.r--60 {
    right: -60px;
}

.b--60 {
    bottom: -60px;
}

.l--60 {
    left: -60px;
}

.a-60 {
    top: 60px;
    right: 60px;
    bottom: 60px;
    left: 60px;
}

.x-60 {
    left: 60px;
    right: 60px;
}

.y-60 {
    left: 60px;
    right: 60px;
}

.t-65 {
    top: 65px;
}

.r-65 {
    right: 65px;
}

.b-65 {
    bottom: 65px;
}

.l-65 {
    left: 65px;
}

.t--65 {
    top: -65px;
}

.r--65 {
    right: -65px;
}

.b--65 {
    bottom: -65px;
}

.l--65 {
    left: -65px;
}

.a-65 {
    top: 65px;
    right: 65px;
    bottom: 65px;
    left: 65px;
}

.x-65 {
    left: 65px;
    right: 65px;
}

.y-65 {
    left: 65px;
    right: 65px;
}

.t-70 {
    top: 70px;
}

.r-70 {
    right: 70px;
}

.b-70 {
    bottom: 70px;
}

.l-70 {
    left: 70px;
}

.t--70 {
    top: -70px;
}

.r--70 {
    right: -70px;
}

.b--70 {
    bottom: -70px;
}

.l--70 {
    left: -70px;
}

.a-70 {
    top: 70px;
    right: 70px;
    bottom: 70px;
    left: 70px;
}

.x-70 {
    left: 70px;
    right: 70px;
}

.y-70 {
    left: 70px;
    right: 70px;
}

.t-75 {
    top: 75px;
}

.r-75 {
    right: 75px;
}

.b-75 {
    bottom: 75px;
}

.l-75 {
    left: 75px;
}

.t--75 {
    top: -75px;
}

.r--75 {
    right: -75px;
}

.b--75 {
    bottom: -75px;
}

.l--75 {
    left: -75px;
}

.a-75 {
    top: 75px;
    right: 75px;
    bottom: 75px;
    left: 75px;
}

.x-75 {
    left: 75px;
    right: 75px;
}

.y-75 {
    left: 75px;
    right: 75px;
}

.t-80 {
    top: 80px;
}

.r-80 {
    right: 80px;
}

.b-80 {
    bottom: 80px;
}

.l-80 {
    left: 80px;
}

.t--80 {
    top: -80px;
}

.r--80 {
    right: -80px;
}

.b--80 {
    bottom: -80px;
}

.l--80 {
    left: -80px;
}

.a-80 {
    top: 80px;
    right: 80px;
    bottom: 80px;
    left: 80px;
}

.x-80 {
    left: 80px;
    right: 80px;
}

.y-80 {
    left: 80px;
    right: 80px;
}

.t-85 {
    top: 85px;
}

.r-85 {
    right: 85px;
}

.b-85 {
    bottom: 85px;
}

.l-85 {
    left: 85px;
}

.t--85 {
    top: -85px;
}

.r--85 {
    right: -85px;
}

.b--85 {
    bottom: -85px;
}

.l--85 {
    left: -85px;
}

.a-85 {
    top: 85px;
    right: 85px;
    bottom: 85px;
    left: 85px;
}

.x-85 {
    left: 85px;
    right: 85px;
}

.y-85 {
    left: 85px;
    right: 85px;
}

.t-90 {
    top: 90px;
}

.r-90 {
    right: 90px;
}

.b-90 {
    bottom: 90px;
}

.l-90 {
    left: 90px;
}

.t--90 {
    top: -90px;
}

.r--90 {
    right: -90px;
}

.b--90 {
    bottom: -90px;
}

.l--90 {
    left: -90px;
}

.a-90 {
    top: 90px;
    right: 90px;
    bottom: 90px;
    left: 90px;
}

.x-90 {
    left: 90px;
    right: 90px;
}

.y-90 {
    left: 90px;
    right: 90px;
}

.t-95 {
    top: 95px;
}

.r-95 {
    right: 95px;
}

.b-95 {
    bottom: 95px;
}

.l-95 {
    left: 95px;
}

.t--95 {
    top: -95px;
}

.r--95 {
    right: -95px;
}

.b--95 {
    bottom: -95px;
}

.l--95 {
    left: -95px;
}

.a-95 {
    top: 95px;
    right: 95px;
    bottom: 95px;
    left: 95px;
}

.x-95 {
    left: 95px;
    right: 95px;
}

.y-95 {
    left: 95px;
    right: 95px;
}

.t-100 {
    top: 100px;
}

.r-100 {
    right: 100px;
}

.b-100 {
    bottom: 100px;
}

.l-100 {
    left: 100px;
}

.t--100 {
    top: -100px;
}

.r--100 {
    right: -100px;
}

.b--100 {
    bottom: -100px;
}

.l--100 {
    left: -100px;
}

.a-100 {
    top: 100px;
    right: 100px;
    bottom: 100px;
    left: 100px;
}

.x-100 {
    left: 100px;
    right: 100px;
}

.y-100 {
    left: 100px;
    right: 100px;
}

.t-2 {
    top: 2px;
}

.r--5 {
    right: -5px;
}

.z-index-10 {
    z-index: 10;
}

.z-index-50 {
    z-index: 50;
}

.z-index-100 {
    z-index: 100;
}

.z-index-150 {
    z-index: 150;
}

.z-index-200 {
    z-index: 200;
}

@media (min-width: 480px) {
    .pos-xs-absolute {
        position: absolute;
    }

    .pos-xs-relative {
        position: relative;
    }

    .pos-xs-fixed {
        position: fixed;
    }

    .pos-xs-static {
        position: static;
    }

    .t-xs-auto {
        top: auto;
    }

    .r-xs-auto {
        right: auto;
    }

    .b-xs-auto {
        bottom: auto;
    }

    .l-xs-auto {
        left: auto;
    }
}

@media (min-width: 576px) {
    .pos-sm-absolute {
        position: absolute;
    }

    .pos-sm-relative {
        position: relative;
    }

    .pos-sm-fixed {
        position: fixed;
    }

    .pos-sm-static {
        position: static;
    }

    .t-sm-auto {
        top: auto;
    }

    .r-sm-auto {
        right: auto;
    }

    .b-sm-auto {
        bottom: auto;
    }

    .l-sm-auto {
        left: auto;
    }
}

@media (min-width: 768px) {
    .pos-md-absolute {
        position: absolute;
    }

    .pos-md-relative {
        position: relative;
    }

    .pos-md-fixed {
        position: fixed;
    }

    .pos-md-static {
        position: static;
    }

    .t-md-auto {
        top: auto;
    }

    .r-md-auto {
        right: auto;
    }

    .b-md-auto {
        bottom: auto;
    }

    .l-md-auto {
        left: auto;
    }
}

@media (min-width: 992px) {
    .pos-lg-absolute {
        position: absolute;
    }

    .pos-lg-relative {
        position: relative;
    }

    .pos-lg-fixed {
        position: fixed;
    }

    .pos-lg-static {
        position: static;
    }

    .t-lg-auto {
        top: auto;
    }

    .r-lg-auto {
        right: auto;
    }

    .b-lg-auto {
        bottom: auto;
    }

    .l-lg-auto {
        left: auto;
    }
}

@media (min-width: 1200px) {
    .pos-xl-absolute {
        position: absolute;
    }

    .pos-xl-relative {
        position: relative;
    }

    .pos-xl-fixed {
        position: fixed;
    }

    .pos-xl-static {
        position: static;
    }

    .t-xl-auto {
        top: auto;
    }

    .r-xl-auto {
        right: auto;
    }

    .b-xl-auto {
        bottom: auto;
    }

    .l-xl-auto {
        left: auto;
    }
}


/* ---------------------------------------------- */


/* ############### 7.9 Typography ############### */


/* ---------------------------------------------- */

.tx-black {
    font-weight: 900;
}

.tx-bold {
    font-weight: 700;
}

.tx-semibold {
    font-weight: 600;
}

.tx-medium {
    font-weight: 500;
}

.tx-normal {
    font-weight: 400;
}

.tx-light {
    font-weight: 300;
}

.tx-thin {
    font-weight: 200;
}

.tx-xthin {
    font-weight: 100;
}

.tx-poppins {
    font-family: "Poppins", "Helvetica Neue", Arial, sans-serif;
}

.tx-roboto {
    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
}

.tx-hind {
    font-family: "Hind", "Helvetica Neue", Arial, sans-serif;
}

.tx-firasans {
    font-family: "Fira Sans", "Helvetica Neue", Arial, sans-serif;
}

.tx-source {
    font-family: "Source Sans Pro", "Helvetica Neue", Arial, sans-serif;
}

.tx-mont {
    font-family: "Montserrat", "Fira Sans", "Helvetica Neue", Arial, sans-serif;
}

.tx-lato {
    font-family: "Lato", "Helvetica Neue", Arial, sans-serif;
}

.tx-sserif {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.tx-barcode {
    font-family: "Libre Barcode 39 Extended Text", cursive;
}

.tx-white {
    color: #fff;
}

.tx-black {
    color: #000;
}

.tx-primary {
    color: #0866C6;
}

.tx-success {
    color: #23BF08;
}

.tx-warning {
    color: #F49917;
}

.tx-danger {
    color: #DC3545;
}

.tx-info {
    color: #17A2B8;
}

.tx-inverse {
    color: #343a40;
}

.tx-teal {
    color: #1CAF9A;
}

.tx-dark {
    color: #343a40;
}

.tx-indigo {
    color: #6610f2;
}

.tx-purple {
    color: #6f42c1;
}

.tx-orange {
    color: #f27510;
}

.tx-pink {
    color: #e83e8c;
}

.tx-gray-100 {
    color: #f8f9fa;
}

.tx-gray-200 {
    color: #e9ecef;
}

.tx-gray-300 {
    color: #dee2e6;
}

.tx-gray-400 {
    color: #ced4da;
}

.tx-gray-500 {
    color: #adb5bd;
}

.tx-gray-600 {
    color: #6c757d;
}

.tx-gray-700 {
    color: #495057;
}

.tx-gray-800 {
    color: #343a40;
}

.tx-gray-900 {
    color: #212529;
}

.tx-br-primary {
    color: #1D2939;
}

.tx-white-2 {
    color: rgba(255, 255, 255, 0.2);
}

.tx-white-3 {
    color: rgba(255, 255, 255, 0.3);
}

.tx-white-4 {
    color: rgba(255, 255, 255, 0.4);
}

.tx-white-5 {
    color: rgba(255, 255, 255, 0.5);
}

.tx-white-6 {
    color: rgba(255, 255, 255, 0.6);
}

.tx-white-7 {
    color: rgba(255, 255, 255, 0.7);
}

.tx-white-8 {
    color: rgba(255, 255, 255, 0.8);
}

.tx-spacing-1 {
    letter-spacing: 0.5px;
}

.tx-spacing-2 {
    letter-spacing: 1px;
}

.tx-spacing-3 {
    letter-spacing: 1.5px;
}

.tx-spacing-4 {
    letter-spacing: 2px;
}

.tx-spacing-5 {
    letter-spacing: 2.5px;
}

.tx-spacing-6 {
    letter-spacing: 3px;
}

.tx-spacing-7 {
    letter-spacing: 3.5px;
}

.tx-spacing-8 {
    letter-spacing: 4px;
}

.tx-spacing--1 {
    letter-spacing: -0.5px;
}

.tx-spacing--2 {
    letter-spacing: -1px;
}

.tx-spacing--3 {
    letter-spacing: -1.5px;
}

.tx-spacing--4 {
    letter-spacing: -2px;
}

.tx-spacing--5 {
    letter-spacing: -2.5px;
}

.tx-spacing--6 {
    letter-spacing: -3px;
}

.tx-spacing--7 {
    letter-spacing: -3.5px;
}

.tx-spacing--8 {
    letter-spacing: -4px;
}

.tx-uppercase {
    text-transform: uppercase;
}

.tx-lowercase {
    text-transform: lowercase;
}

.tx-transform-none {
    text-transform: none;
}

.tx-center {
    text-align: center;
}

.tx-right {
    text-align: right;
}

.tx-left {
    text-align: left;
}

.tx-center-force {
    text-align: center !important;
}

.tx-right-force {
    text-align: right !important;
}

.tx-left-force {
    text-align: left !important;
}

.tx-italic {
    font-style: italic;
}

.tx-style-normal {
    font-style: normal;
}

.lh-base {
    line-height: 1.5;
}

.lh-normal {
    line-height: normal;
}

.lh-0 {
    line-height: 0;
}

.lh-1 {
    line-height: 1.1;
}

.lh-2 {
    line-height: 1.2;
}

.lh-3 {
    line-height: 1.3;
}

.lh-4 {
    line-height: 1.4;
}

.lh-5 {
    line-height: 1.5;
}

.lh-6 {
    line-height: 1.6;
}

.lh-7 {
    line-height: 1.7;
}

.lh-8 {
    line-height: 1.8;
}

.lh-9 {
    line-height: 1.9;
}

.lh-10 {
    line-height: 2.0;
}

.lh-11 {
    line-height: 2.1;
}

.lh-12 {
    line-height: 2.2;
}

.lh-13 {
    line-height: 2.3;
}

.lh-14 {
    line-height: 2.4;
}

.lh-15 {
    line-height: 2.5;
}

.valign-top {
    vertical-align: top;
}

.valign-middle {
    vertical-align: middle;
}

.valign-bottom {
    vertical-align: baseline;
}

.valign-top-force {
    vertical-align: top !important;
}

.valign-middle-force {
    vertical-align: middle !important;
}

.valign-bottom-force {
    vertical-align: baseline !important;
}

.tx-8 {
    font-size: 8px;
}

.tx-8-force {
    font-size: 8px !important;
}

.tx-9 {
    font-size: 9px;
}

.tx-9-force {
    font-size: 9px !important;
}

.tx-10 {
    font-size: 10px;
}

.tx-10-force {
    font-size: 10px !important;
}

.tx-11 {
    font-size: 11px;
}

.tx-11-force {
    font-size: 11px !important;
}

.tx-12 {
    font-size: 12px;
}

.tx-12-force {
    font-size: 12px !important;
}

.tx-13 {
    font-size: 13px;
}

.tx-13-force {
    font-size: 13px !important;
}

.tx-14 {
    font-size: 14px;
}

.tx-14-force {
    font-size: 14px !important;
}

.tx-15 {
    font-size: 15px;
}

.tx-15-force {
    font-size: 15px !important;
}

.tx-16 {
    font-size: 16px;
}

.tx-16-force {
    font-size: 16px !important;
}

.tx-18 {
    font-size: 18px;
}

.tx-18-force {
    font-size: 18px !important;
}

.tx-20 {
    font-size: 20px;
}

.tx-20-force {
    font-size: 20px !important;
}

.tx-22 {
    font-size: 22px;
}

.tx-22-force {
    font-size: 22px !important;
}

.tx-24 {
    font-size: 24px;
}

.tx-24-force {
    font-size: 24px !important;
}

.tx-26 {
    font-size: 26px;
}

.tx-26-force {
    font-size: 26px !important;
}

.tx-28 {
    font-size: 28px;
}

.tx-28-force {
    font-size: 28px !important;
}

.tx-30 {
    font-size: 30px;
}

.tx-30-force {
    font-size: 30px !important;
}

.tx-32 {
    font-size: 32px;
}

.tx-32-force {
    font-size: 32px !important;
}

.tx-34 {
    font-size: 34px;
}

.tx-34-force {
    font-size: 34px !important;
}

.tx-36 {
    font-size: 36px;
}

.tx-36-force {
    font-size: 36px !important;
}

.tx-38 {
    font-size: 38px;
}

.tx-38-force {
    font-size: 38px !important;
}

.tx-40 {
    font-size: 40px;
}

.tx-40-force {
    font-size: 40px !important;
}

.tx-42 {
    font-size: 42px;
}

.tx-42-force {
    font-size: 42px !important;
}

.tx-44 {
    font-size: 44px;
}

.tx-44-force {
    font-size: 44px !important;
}

.tx-46 {
    font-size: 46px;
}

.tx-46-force {
    font-size: 46px !important;
}

.tx-48 {
    font-size: 48px;
}

.tx-48-force {
    font-size: 48px !important;
}

.tx-50 {
    font-size: 50px;
}

.tx-50-force {
    font-size: 50px !important;
}

.tx-52 {
    font-size: 52px;
}

.tx-52-force {
    font-size: 52px !important;
}

.tx-54 {
    font-size: 54px;
}

.tx-54-force {
    font-size: 54px !important;
}

.tx-56 {
    font-size: 56px;
}

.tx-56-force {
    font-size: 56px !important;
}

.tx-58 {
    font-size: 58px;
}

.tx-58-force {
    font-size: 58px !important;
}

.tx-60 {
    font-size: 60px;
}

.tx-60-force {
    font-size: 60px !important;
}

.tx-62 {
    font-size: 62px;
}

.tx-62-force {
    font-size: 62px !important;
}

.tx-64 {
    font-size: 64px;
}

.tx-64-force {
    font-size: 64px !important;
}

.tx-66 {
    font-size: 66px;
}

.tx-66-force {
    font-size: 66px !important;
}

.tx-68 {
    font-size: 68px;
}

.tx-68-force {
    font-size: 68px !important;
}

.tx-70 {
    font-size: 70px;
}

.tx-70-force {
    font-size: 70px !important;
}

.tx-72 {
    font-size: 72px;
}

.tx-72-force {
    font-size: 72px !important;
}

.tx-74 {
    font-size: 74px;
}

.tx-74-force {
    font-size: 74px !important;
}

.tx-76 {
    font-size: 76px;
}

.tx-76-force {
    font-size: 76px !important;
}

.tx-78 {
    font-size: 78px;
}

.tx-78-force {
    font-size: 78px !important;
}

.tx-80 {
    font-size: 80px;
}

.tx-80-force {
    font-size: 80px !important;
}

.tx-82 {
    font-size: 82px;
}

.tx-82-force {
    font-size: 82px !important;
}

.tx-84 {
    font-size: 84px;
}

.tx-84-force {
    font-size: 84px !important;
}

.tx-86 {
    font-size: 86px;
}

.tx-86-force {
    font-size: 86px !important;
}

.tx-88 {
    font-size: 88px;
}

.tx-88-force {
    font-size: 88px !important;
}

.tx-90 {
    font-size: 90px;
}

.tx-90-force {
    font-size: 90px !important;
}

.tx-92 {
    font-size: 92px;
}

.tx-92-force {
    font-size: 92px !important;
}

.tx-94 {
    font-size: 94px;
}

.tx-94-force {
    font-size: 94px !important;
}

.tx-96 {
    font-size: 96px;
}

.tx-96-force {
    font-size: 96px !important;
}

.tx-98 {
    font-size: 98px;
}

.tx-98-force {
    font-size: 98px !important;
}

.tx-100 {
    font-size: 100px;
}

.tx-100-force {
    font-size: 100px !important;
}

.tx-102 {
    font-size: 102px;
}

.tx-102-force {
    font-size: 102px !important;
}

.tx-104 {
    font-size: 104px;
}

.tx-104-force {
    font-size: 104px !important;
}

.tx-106 {
    font-size: 106px;
}

.tx-106-force {
    font-size: 106px !important;
}

.tx-108 {
    font-size: 108px;
}

.tx-108-force {
    font-size: 108px !important;
}

.tx-110 {
    font-size: 110px;
}

.tx-110-force {
    font-size: 110px !important;
}

.tx-112 {
    font-size: 112px;
}

.tx-112-force {
    font-size: 112px !important;
}

.tx-114 {
    font-size: 114px;
}

.tx-114-force {
    font-size: 114px !important;
}

.tx-116 {
    font-size: 116px;
}

.tx-116-force {
    font-size: 116px !important;
}

.tx-118 {
    font-size: 118px;
}

.tx-118-force {
    font-size: 118px !important;
}

.tx-120 {
    font-size: 120px;
}

.tx-120-force {
    font-size: 120px !important;
}

.tx-122 {
    font-size: 122px;
}

.tx-122-force {
    font-size: 122px !important;
}

.tx-124 {
    font-size: 124px;
}

.tx-124-force {
    font-size: 124px !important;
}

.tx-126 {
    font-size: 126px;
}

.tx-126-force {
    font-size: 126px !important;
}

.tx-128 {
    font-size: 128px;
}

.tx-128-force {
    font-size: 128px !important;
}

.tx-130 {
    font-size: 130px;
}

.tx-130-force {
    font-size: 130px !important;
}

.tx-132 {
    font-size: 132px;
}

.tx-132-force {
    font-size: 132px !important;
}

.tx-134 {
    font-size: 134px;
}

.tx-134-force {
    font-size: 134px !important;
}

.tx-136 {
    font-size: 136px;
}

.tx-136-force {
    font-size: 136px !important;
}

.tx-138 {
    font-size: 138px;
}

.tx-138-force {
    font-size: 138px !important;
}

.tx-140 {
    font-size: 140px;
}

.tx-140-force {
    font-size: 140px !important;
}

@media (min-width: 480px) {
    .tx-xs-8 {
        font-size: 8px;
    }

    .tx-xs-8-force {
        font-size: 8px !important;
    }

    .tx-xs-9 {
        font-size: 9px;
    }

    .tx-xs-9-force {
        font-size: 9px !important;
    }

    .tx-xs-10 {
        font-size: 10px;
    }

    .tx-xs-10-force {
        font-size: 10px !important;
    }

    .tx-xs-11 {
        font-size: 11px;
    }

    .tx-xs-11-force {
        font-size: 11px !important;
    }

    .tx-xs-12 {
        font-size: 12px;
    }

    .tx-xs-12-force {
        font-size: 12px !important;
    }

    .tx-xs-13 {
        font-size: 13px;
    }

    .tx-xs-13-force {
        font-size: 13px !important;
    }

    .tx-xs-14 {
        font-size: 14px;
    }

    .tx-xs-14-force {
        font-size: 14px !important;
    }

    .tx-xs-15 {
        font-size: 15px;
    }

    .tx-xs-15-force {
        font-size: 15px !important;
    }

    .tx-xs-16 {
        font-size: 16px;
    }

    .tx-xs-16-force {
        font-size: 16px !important;
    }

    .tx-xs-18 {
        font-size: 18px;
    }

    .tx-xs-18-force {
        font-size: 18px !important;
    }

    .tx-xs-20 {
        font-size: 20px;
    }

    .tx-xs-20-force {
        font-size: 20px !important;
    }

    .tx-xs-22 {
        font-size: 22px;
    }

    .tx-xs-22-force {
        font-size: 22px !important;
    }

    .tx-xs-24 {
        font-size: 24px;
    }

    .tx-xs-24-force {
        font-size: 24px !important;
    }

    .tx-xs-26 {
        font-size: 26px;
    }

    .tx-xs-26-force {
        font-size: 26px !important;
    }

    .tx-xs-28 {
        font-size: 28px;
    }

    .tx-xs-28-force {
        font-size: 28px !important;
    }

    .tx-xs-30 {
        font-size: 30px;
    }

    .tx-xs-30-force {
        font-size: 30px !important;
    }

    .tx-xs-32 {
        font-size: 32px;
    }

    .tx-xs-32-force {
        font-size: 32px !important;
    }

    .tx-xs-34 {
        font-size: 34px;
    }

    .tx-xs-34-force {
        font-size: 34px !important;
    }

    .tx-xs-36 {
        font-size: 36px;
    }

    .tx-xs-36-force {
        font-size: 36px !important;
    }

    .tx-xs-38 {
        font-size: 38px;
    }

    .tx-xs-38-force {
        font-size: 38px !important;
    }

    .tx-xs-40 {
        font-size: 40px;
    }

    .tx-xs-40-force {
        font-size: 40px !important;
    }

    .tx-xs-42 {
        font-size: 42px;
    }

    .tx-xs-42-force {
        font-size: 42px !important;
    }

    .tx-xs-44 {
        font-size: 44px;
    }

    .tx-xs-44-force {
        font-size: 44px !important;
    }

    .tx-xs-46 {
        font-size: 46px;
    }

    .tx-xs-46-force {
        font-size: 46px !important;
    }

    .tx-xs-48 {
        font-size: 48px;
    }

    .tx-xs-48-force {
        font-size: 48px !important;
    }

    .tx-xs-50 {
        font-size: 50px;
    }

    .tx-xs-50-force {
        font-size: 50px !important;
    }

    .tx-xs-52 {
        font-size: 52px;
    }

    .tx-xs-52-force {
        font-size: 52px !important;
    }

    .tx-xs-54 {
        font-size: 54px;
    }

    .tx-xs-54-force {
        font-size: 54px !important;
    }

    .tx-xs-56 {
        font-size: 56px;
    }

    .tx-xs-56-force {
        font-size: 56px !important;
    }

    .tx-xs-58 {
        font-size: 58px;
    }

    .tx-xs-58-force {
        font-size: 58px !important;
    }

    .tx-xs-60 {
        font-size: 60px;
    }

    .tx-xs-60-force {
        font-size: 60px !important;
    }

    .tx-xs-62 {
        font-size: 62px;
    }

    .tx-xs-62-force {
        font-size: 62px !important;
    }

    .tx-xs-64 {
        font-size: 64px;
    }

    .tx-xs-64-force {
        font-size: 64px !important;
    }

    .tx-xs-66 {
        font-size: 66px;
    }

    .tx-xs-66-force {
        font-size: 66px !important;
    }

    .tx-xs-68 {
        font-size: 68px;
    }

    .tx-xs-68-force {
        font-size: 68px !important;
    }

    .tx-xs-70 {
        font-size: 70px;
    }

    .tx-xs-70-force {
        font-size: 70px !important;
    }

    .tx-xs-72 {
        font-size: 72px;
    }

    .tx-xs-72-force {
        font-size: 72px !important;
    }

    .tx-xs-74 {
        font-size: 74px;
    }

    .tx-xs-74-force {
        font-size: 74px !important;
    }

    .tx-xs-76 {
        font-size: 76px;
    }

    .tx-xs-76-force {
        font-size: 76px !important;
    }

    .tx-xs-78 {
        font-size: 78px;
    }

    .tx-xs-78-force {
        font-size: 78px !important;
    }

    .tx-xs-80 {
        font-size: 80px;
    }

    .tx-xs-80-force {
        font-size: 80px !important;
    }

    .tx-xs-82 {
        font-size: 82px;
    }

    .tx-xs-82-force {
        font-size: 82px !important;
    }

    .tx-xs-84 {
        font-size: 84px;
    }

    .tx-xs-84-force {
        font-size: 84px !important;
    }

    .tx-xs-86 {
        font-size: 86px;
    }

    .tx-xs-86-force {
        font-size: 86px !important;
    }

    .tx-xs-88 {
        font-size: 88px;
    }

    .tx-xs-88-force {
        font-size: 88px !important;
    }

    .tx-xs-90 {
        font-size: 90px;
    }

    .tx-xs-90-force {
        font-size: 90px !important;
    }

    .tx-xs-92 {
        font-size: 92px;
    }

    .tx-xs-92-force {
        font-size: 92px !important;
    }

    .tx-xs-94 {
        font-size: 94px;
    }

    .tx-xs-94-force {
        font-size: 94px !important;
    }

    .tx-xs-96 {
        font-size: 96px;
    }

    .tx-xs-96-force {
        font-size: 96px !important;
    }

    .tx-xs-98 {
        font-size: 98px;
    }

    .tx-xs-98-force {
        font-size: 98px !important;
    }

    .tx-xs-100 {
        font-size: 100px;
    }

    .tx-xs-100-force {
        font-size: 100px !important;
    }

    .tx-xs-102 {
        font-size: 102px;
    }

    .tx-xs-102-force {
        font-size: 102px !important;
    }

    .tx-xs-104 {
        font-size: 104px;
    }

    .tx-xs-104-force {
        font-size: 104px !important;
    }

    .tx-xs-106 {
        font-size: 106px;
    }

    .tx-xs-106-force {
        font-size: 106px !important;
    }

    .tx-xs-108 {
        font-size: 108px;
    }

    .tx-xs-108-force {
        font-size: 108px !important;
    }

    .tx-xs-110 {
        font-size: 110px;
    }

    .tx-xs-110-force {
        font-size: 110px !important;
    }

    .tx-xs-112 {
        font-size: 112px;
    }

    .tx-xs-112-force {
        font-size: 112px !important;
    }

    .tx-xs-114 {
        font-size: 114px;
    }

    .tx-xs-114-force {
        font-size: 114px !important;
    }

    .tx-xs-116 {
        font-size: 116px;
    }

    .tx-xs-116-force {
        font-size: 116px !important;
    }

    .tx-xs-118 {
        font-size: 118px;
    }

    .tx-xs-118-force {
        font-size: 118px !important;
    }

    .tx-xs-120 {
        font-size: 120px;
    }

    .tx-xs-120-force {
        font-size: 120px !important;
    }

    .tx-xs-122 {
        font-size: 122px;
    }

    .tx-xs-122-force {
        font-size: 122px !important;
    }

    .tx-xs-124 {
        font-size: 124px;
    }

    .tx-xs-124-force {
        font-size: 124px !important;
    }

    .tx-xs-126 {
        font-size: 126px;
    }

    .tx-xs-126-force {
        font-size: 126px !important;
    }

    .tx-xs-128 {
        font-size: 128px;
    }

    .tx-xs-128-force {
        font-size: 128px !important;
    }

    .tx-xs-130 {
        font-size: 130px;
    }

    .tx-xs-130-force {
        font-size: 130px !important;
    }

    .tx-xs-132 {
        font-size: 132px;
    }

    .tx-xs-132-force {
        font-size: 132px !important;
    }

    .tx-xs-134 {
        font-size: 134px;
    }

    .tx-xs-134-force {
        font-size: 134px !important;
    }

    .tx-xs-136 {
        font-size: 136px;
    }

    .tx-xs-136-force {
        font-size: 136px !important;
    }

    .tx-xs-138 {
        font-size: 138px;
    }

    .tx-xs-138-force {
        font-size: 138px !important;
    }

    .tx-xs-140 {
        font-size: 140px;
    }

    .tx-xs-140-force {
        font-size: 140px !important;
    }

    .tx-xs-bold {
        font-weight: 700;
    }

    .tx-xs-semibold {
        font-weight: 600;
    }

    .tx-xs-medium {
        font-weight: 500;
    }

    .tx-xs-normal {
        font-weight: 400;
    }

    .tx-xs-light {
        font-weight: 300;
    }

    .tx-xs-thin {
        font-weight: 200;
    }

    .tx-xs-xthin {
        font-weight: 100;
    }

    .lh-xs-0 {
        line-height: 0;
    }

    .lh-xs-1 {
        line-height: 1.1;
    }

    .lh-xs-2 {
        line-height: 1.2;
    }

    .lh-xs-3 {
        line-height: 1.3;
    }

    .lh-xs-4 {
        line-height: 1.4;
    }

    .lh-xs-5 {
        line-height: 1.5;
    }

    .lh-xs-6 {
        line-height: 1.6;
    }

    .lh-xs-7 {
        line-height: 1.7;
    }

    .lh-xs-8 {
        line-height: 1.8;
    }

    .lh-xs-9 {
        line-height: 1.9;
    }

    .lh-xs-10 {
        line-height: 2.0;
    }

    .lh-xs-11 {
        line-height: 2.1;
    }

    .lh-xs-12 {
        line-height: 2.2;
    }

    .lh-xs-13 {
        line-height: 2.3;
    }

    .lh-xs-14 {
        line-height: 2.4;
    }

    .lh-xs-15 {
        line-height: 2.5;
    }

    .tx-xs-center {
        text-align: center;
    }

    .tx-xs-right {
        text-align: right;
    }

    .tx-xs-left {
        text-align: left;
    }

    .tx-xs-center-force {
        text-align: center !important;
    }

    .tx-xs-right-force {
        text-align: right !important;
    }

    .tx-xs-left-force {
        text-align: left !important;
    }
}

@media (min-width: 576px) {
    .tx-sm-8 {
        font-size: 8px;
    }

    .tx-sm-8-force {
        font-size: 8px !important;
    }

    .tx-sm-9 {
        font-size: 9px;
    }

    .tx-sm-9-force {
        font-size: 9px !important;
    }

    .tx-sm-10 {
        font-size: 10px;
    }

    .tx-sm-10-force {
        font-size: 10px !important;
    }

    .tx-sm-11 {
        font-size: 11px;
    }

    .tx-sm-11-force {
        font-size: 11px !important;
    }

    .tx-sm-12 {
        font-size: 12px;
    }

    .tx-sm-12-force {
        font-size: 12px !important;
    }

    .tx-sm-13 {
        font-size: 13px;
    }

    .tx-sm-13-force {
        font-size: 13px !important;
    }

    .tx-sm-14 {
        font-size: 14px;
    }

    .tx-sm-14-force {
        font-size: 14px !important;
    }

    .tx-sm-15 {
        font-size: 15px;
    }

    .tx-sm-15-force {
        font-size: 15px !important;
    }

    .tx-sm-16 {
        font-size: 16px;
    }

    .tx-sm-16-force {
        font-size: 16px !important;
    }

    .tx-sm-18 {
        font-size: 18px;
    }

    .tx-sm-18-force {
        font-size: 18px !important;
    }

    .tx-sm-20 {
        font-size: 20px;
    }

    .tx-sm-20-force {
        font-size: 20px !important;
    }

    .tx-sm-22 {
        font-size: 22px;
    }

    .tx-sm-22-force {
        font-size: 22px !important;
    }

    .tx-sm-24 {
        font-size: 24px;
    }

    .tx-sm-24-force {
        font-size: 24px !important;
    }

    .tx-sm-26 {
        font-size: 26px;
    }

    .tx-sm-26-force {
        font-size: 26px !important;
    }

    .tx-sm-28 {
        font-size: 28px;
    }

    .tx-sm-28-force {
        font-size: 28px !important;
    }

    .tx-sm-30 {
        font-size: 30px;
    }

    .tx-sm-30-force {
        font-size: 30px !important;
    }

    .tx-sm-32 {
        font-size: 32px;
    }

    .tx-sm-32-force {
        font-size: 32px !important;
    }

    .tx-sm-34 {
        font-size: 34px;
    }

    .tx-sm-34-force {
        font-size: 34px !important;
    }

    .tx-sm-36 {
        font-size: 36px;
    }

    .tx-sm-36-force {
        font-size: 36px !important;
    }

    .tx-sm-38 {
        font-size: 38px;
    }

    .tx-sm-38-force {
        font-size: 38px !important;
    }

    .tx-sm-40 {
        font-size: 40px;
    }

    .tx-sm-40-force {
        font-size: 40px !important;
    }

    .tx-sm-42 {
        font-size: 42px;
    }

    .tx-sm-42-force {
        font-size: 42px !important;
    }

    .tx-sm-44 {
        font-size: 44px;
    }

    .tx-sm-44-force {
        font-size: 44px !important;
    }

    .tx-sm-46 {
        font-size: 46px;
    }

    .tx-sm-46-force {
        font-size: 46px !important;
    }

    .tx-sm-48 {
        font-size: 48px;
    }

    .tx-sm-48-force {
        font-size: 48px !important;
    }

    .tx-sm-50 {
        font-size: 50px;
    }

    .tx-sm-50-force {
        font-size: 50px !important;
    }

    .tx-sm-52 {
        font-size: 52px;
    }

    .tx-sm-52-force {
        font-size: 52px !important;
    }

    .tx-sm-54 {
        font-size: 54px;
    }

    .tx-sm-54-force {
        font-size: 54px !important;
    }

    .tx-sm-56 {
        font-size: 56px;
    }

    .tx-sm-56-force {
        font-size: 56px !important;
    }

    .tx-sm-58 {
        font-size: 58px;
    }

    .tx-sm-58-force {
        font-size: 58px !important;
    }

    .tx-sm-60 {
        font-size: 60px;
    }

    .tx-sm-60-force {
        font-size: 60px !important;
    }

    .tx-sm-62 {
        font-size: 62px;
    }

    .tx-sm-62-force {
        font-size: 62px !important;
    }

    .tx-sm-64 {
        font-size: 64px;
    }

    .tx-sm-64-force {
        font-size: 64px !important;
    }

    .tx-sm-66 {
        font-size: 66px;
    }

    .tx-sm-66-force {
        font-size: 66px !important;
    }

    .tx-sm-68 {
        font-size: 68px;
    }

    .tx-sm-68-force {
        font-size: 68px !important;
    }

    .tx-sm-70 {
        font-size: 70px;
    }

    .tx-sm-70-force {
        font-size: 70px !important;
    }

    .tx-sm-72 {
        font-size: 72px;
    }

    .tx-sm-72-force {
        font-size: 72px !important;
    }

    .tx-sm-74 {
        font-size: 74px;
    }

    .tx-sm-74-force {
        font-size: 74px !important;
    }

    .tx-sm-76 {
        font-size: 76px;
    }

    .tx-sm-76-force {
        font-size: 76px !important;
    }

    .tx-sm-78 {
        font-size: 78px;
    }

    .tx-sm-78-force {
        font-size: 78px !important;
    }

    .tx-sm-80 {
        font-size: 80px;
    }

    .tx-sm-80-force {
        font-size: 80px !important;
    }

    .tx-sm-82 {
        font-size: 82px;
    }

    .tx-sm-82-force {
        font-size: 82px !important;
    }

    .tx-sm-84 {
        font-size: 84px;
    }

    .tx-sm-84-force {
        font-size: 84px !important;
    }

    .tx-sm-86 {
        font-size: 86px;
    }

    .tx-sm-86-force {
        font-size: 86px !important;
    }

    .tx-sm-88 {
        font-size: 88px;
    }

    .tx-sm-88-force {
        font-size: 88px !important;
    }

    .tx-sm-90 {
        font-size: 90px;
    }

    .tx-sm-90-force {
        font-size: 90px !important;
    }

    .tx-sm-92 {
        font-size: 92px;
    }

    .tx-sm-92-force {
        font-size: 92px !important;
    }

    .tx-sm-94 {
        font-size: 94px;
    }

    .tx-sm-94-force {
        font-size: 94px !important;
    }

    .tx-sm-96 {
        font-size: 96px;
    }

    .tx-sm-96-force {
        font-size: 96px !important;
    }

    .tx-sm-98 {
        font-size: 98px;
    }

    .tx-sm-98-force {
        font-size: 98px !important;
    }

    .tx-sm-100 {
        font-size: 100px;
    }

    .tx-sm-100-force {
        font-size: 100px !important;
    }

    .tx-sm-102 {
        font-size: 102px;
    }

    .tx-sm-102-force {
        font-size: 102px !important;
    }

    .tx-sm-104 {
        font-size: 104px;
    }

    .tx-sm-104-force {
        font-size: 104px !important;
    }

    .tx-sm-106 {
        font-size: 106px;
    }

    .tx-sm-106-force {
        font-size: 106px !important;
    }

    .tx-sm-108 {
        font-size: 108px;
    }

    .tx-sm-108-force {
        font-size: 108px !important;
    }

    .tx-sm-110 {
        font-size: 110px;
    }

    .tx-sm-110-force {
        font-size: 110px !important;
    }

    .tx-sm-112 {
        font-size: 112px;
    }

    .tx-sm-112-force {
        font-size: 112px !important;
    }

    .tx-sm-114 {
        font-size: 114px;
    }

    .tx-sm-114-force {
        font-size: 114px !important;
    }

    .tx-sm-116 {
        font-size: 116px;
    }

    .tx-sm-116-force {
        font-size: 116px !important;
    }

    .tx-sm-118 {
        font-size: 118px;
    }

    .tx-sm-118-force {
        font-size: 118px !important;
    }

    .tx-sm-120 {
        font-size: 120px;
    }

    .tx-sm-120-force {
        font-size: 120px !important;
    }

    .tx-sm-122 {
        font-size: 122px;
    }

    .tx-sm-122-force {
        font-size: 122px !important;
    }

    .tx-sm-124 {
        font-size: 124px;
    }

    .tx-sm-124-force {
        font-size: 124px !important;
    }

    .tx-sm-126 {
        font-size: 126px;
    }

    .tx-sm-126-force {
        font-size: 126px !important;
    }

    .tx-sm-128 {
        font-size: 128px;
    }

    .tx-sm-128-force {
        font-size: 128px !important;
    }

    .tx-sm-130 {
        font-size: 130px;
    }

    .tx-sm-130-force {
        font-size: 130px !important;
    }

    .tx-sm-132 {
        font-size: 132px;
    }

    .tx-sm-132-force {
        font-size: 132px !important;
    }

    .tx-sm-134 {
        font-size: 134px;
    }

    .tx-sm-134-force {
        font-size: 134px !important;
    }

    .tx-sm-136 {
        font-size: 136px;
    }

    .tx-sm-136-force {
        font-size: 136px !important;
    }

    .tx-sm-138 {
        font-size: 138px;
    }

    .tx-sm-138-force {
        font-size: 138px !important;
    }

    .tx-sm-140 {
        font-size: 140px;
    }

    .tx-sm-140-force {
        font-size: 140px !important;
    }

    .tx-sm-bold {
        font-weight: 700;
    }

    .tx-sm-semibold {
        font-weight: 600;
    }

    .tx-sm-medium {
        font-weight: 500;
    }

    .tx-sm-normal {
        font-weight: 400;
    }

    .tx-sm-light {
        font-weight: 300;
    }

    .tx-sm-thin {
        font-weight: 200;
    }

    .tx-sm-xthin {
        font-weight: 100;
    }

    .lh-sm-0 {
        line-height: 0;
    }

    .lh-sm-1 {
        line-height: 1.1;
    }

    .lh-sm-2 {
        line-height: 1.2;
    }

    .lh-sm-3 {
        line-height: 1.3;
    }

    .lh-sm-4 {
        line-height: 1.4;
    }

    .lh-sm-5 {
        line-height: 1.5;
    }

    .lh-sm-6 {
        line-height: 1.6;
    }

    .lh-sm-7 {
        line-height: 1.7;
    }

    .lh-sm-8 {
        line-height: 1.8;
    }

    .lh-sm-9 {
        line-height: 1.9;
    }

    .lh-sm-10 {
        line-height: 2.0;
    }

    .lh-sm-11 {
        line-height: 2.1;
    }

    .lh-sm-12 {
        line-height: 2.2;
    }

    .lh-sm-13 {
        line-height: 2.3;
    }

    .lh-sm-14 {
        line-height: 2.4;
    }

    .lh-sm-15 {
        line-height: 2.5;
    }

    .tx-sm-center {
        text-align: center;
    }

    .tx-sm-right {
        text-align: right;
    }

    .tx-sm-left {
        text-align: left;
    }

    .tx-sm-center-force {
        text-align: center !important;
    }

    .tx-sm-right-force {
        text-align: right !important;
    }

    .tx-sm-left-force {
        text-align: left !important;
    }
}

@media (min-width: 768px) {
    .tx-md-8 {
        font-size: 8px;
    }

    .tx-md-8-force {
        font-size: 8px !important;
    }

    .tx-md-9 {
        font-size: 9px;
    }

    .tx-md-9-force {
        font-size: 9px !important;
    }

    .tx-md-10 {
        font-size: 10px;
    }

    .tx-md-10-force {
        font-size: 10px !important;
    }

    .tx-md-11 {
        font-size: 11px;
    }

    .tx-md-11-force {
        font-size: 11px !important;
    }

    .tx-md-12 {
        font-size: 12px;
    }

    .tx-md-12-force {
        font-size: 12px !important;
    }

    .tx-md-13 {
        font-size: 13px;
    }

    .tx-md-13-force {
        font-size: 13px !important;
    }

    .tx-md-14 {
        font-size: 14px;
    }

    .tx-md-14-force {
        font-size: 14px !important;
    }

    .tx-md-15 {
        font-size: 15px;
    }

    .tx-md-15-force {
        font-size: 15px !important;
    }

    .tx-md-16 {
        font-size: 16px;
    }

    .tx-md-16-force {
        font-size: 16px !important;
    }

    .tx-md-18 {
        font-size: 18px;
    }

    .tx-md-18-force {
        font-size: 18px !important;
    }

    .tx-md-20 {
        font-size: 20px;
    }

    .tx-md-20-force {
        font-size: 20px !important;
    }

    .tx-md-22 {
        font-size: 22px;
    }

    .tx-md-22-force {
        font-size: 22px !important;
    }

    .tx-md-24 {
        font-size: 24px;
    }

    .tx-md-24-force {
        font-size: 24px !important;
    }

    .tx-md-26 {
        font-size: 26px;
    }

    .tx-md-26-force {
        font-size: 26px !important;
    }

    .tx-md-28 {
        font-size: 28px;
    }

    .tx-md-28-force {
        font-size: 28px !important;
    }

    .tx-md-30 {
        font-size: 30px;
    }

    .tx-md-30-force {
        font-size: 30px !important;
    }

    .tx-md-32 {
        font-size: 32px;
    }

    .tx-md-32-force {
        font-size: 32px !important;
    }

    .tx-md-34 {
        font-size: 34px;
    }

    .tx-md-34-force {
        font-size: 34px !important;
    }

    .tx-md-36 {
        font-size: 36px;
    }

    .tx-md-36-force {
        font-size: 36px !important;
    }

    .tx-md-38 {
        font-size: 38px;
    }

    .tx-md-38-force {
        font-size: 38px !important;
    }

    .tx-md-40 {
        font-size: 40px;
    }

    .tx-md-40-force {
        font-size: 40px !important;
    }

    .tx-md-42 {
        font-size: 42px;
    }

    .tx-md-42-force {
        font-size: 42px !important;
    }

    .tx-md-44 {
        font-size: 44px;
    }

    .tx-md-44-force {
        font-size: 44px !important;
    }

    .tx-md-46 {
        font-size: 46px;
    }

    .tx-md-46-force {
        font-size: 46px !important;
    }

    .tx-md-48 {
        font-size: 48px;
    }

    .tx-md-48-force {
        font-size: 48px !important;
    }

    .tx-md-50 {
        font-size: 50px;
    }

    .tx-md-50-force {
        font-size: 50px !important;
    }

    .tx-md-52 {
        font-size: 52px;
    }

    .tx-md-52-force {
        font-size: 52px !important;
    }

    .tx-md-54 {
        font-size: 54px;
    }

    .tx-md-54-force {
        font-size: 54px !important;
    }

    .tx-md-56 {
        font-size: 56px;
    }

    .tx-md-56-force {
        font-size: 56px !important;
    }

    .tx-md-58 {
        font-size: 58px;
    }

    .tx-md-58-force {
        font-size: 58px !important;
    }

    .tx-md-60 {
        font-size: 60px;
    }

    .tx-md-60-force {
        font-size: 60px !important;
    }

    .tx-md-62 {
        font-size: 62px;
    }

    .tx-md-62-force {
        font-size: 62px !important;
    }

    .tx-md-64 {
        font-size: 64px;
    }

    .tx-md-64-force {
        font-size: 64px !important;
    }

    .tx-md-66 {
        font-size: 66px;
    }

    .tx-md-66-force {
        font-size: 66px !important;
    }

    .tx-md-68 {
        font-size: 68px;
    }

    .tx-md-68-force {
        font-size: 68px !important;
    }

    .tx-md-70 {
        font-size: 70px;
    }

    .tx-md-70-force {
        font-size: 70px !important;
    }

    .tx-md-72 {
        font-size: 72px;
    }

    .tx-md-72-force {
        font-size: 72px !important;
    }

    .tx-md-74 {
        font-size: 74px;
    }

    .tx-md-74-force {
        font-size: 74px !important;
    }

    .tx-md-76 {
        font-size: 76px;
    }

    .tx-md-76-force {
        font-size: 76px !important;
    }

    .tx-md-78 {
        font-size: 78px;
    }

    .tx-md-78-force {
        font-size: 78px !important;
    }

    .tx-md-80 {
        font-size: 80px;
    }

    .tx-md-80-force {
        font-size: 80px !important;
    }

    .tx-md-82 {
        font-size: 82px;
    }

    .tx-md-82-force {
        font-size: 82px !important;
    }

    .tx-md-84 {
        font-size: 84px;
    }

    .tx-md-84-force {
        font-size: 84px !important;
    }

    .tx-md-86 {
        font-size: 86px;
    }

    .tx-md-86-force {
        font-size: 86px !important;
    }

    .tx-md-88 {
        font-size: 88px;
    }

    .tx-md-88-force {
        font-size: 88px !important;
    }

    .tx-md-90 {
        font-size: 90px;
    }

    .tx-md-90-force {
        font-size: 90px !important;
    }

    .tx-md-92 {
        font-size: 92px;
    }

    .tx-md-92-force {
        font-size: 92px !important;
    }

    .tx-md-94 {
        font-size: 94px;
    }

    .tx-md-94-force {
        font-size: 94px !important;
    }

    .tx-md-96 {
        font-size: 96px;
    }

    .tx-md-96-force {
        font-size: 96px !important;
    }

    .tx-md-98 {
        font-size: 98px;
    }

    .tx-md-98-force {
        font-size: 98px !important;
    }

    .tx-md-100 {
        font-size: 100px;
    }

    .tx-md-100-force {
        font-size: 100px !important;
    }

    .tx-md-102 {
        font-size: 102px;
    }

    .tx-md-102-force {
        font-size: 102px !important;
    }

    .tx-md-104 {
        font-size: 104px;
    }

    .tx-md-104-force {
        font-size: 104px !important;
    }

    .tx-md-106 {
        font-size: 106px;
    }

    .tx-md-106-force {
        font-size: 106px !important;
    }

    .tx-md-108 {
        font-size: 108px;
    }

    .tx-md-108-force {
        font-size: 108px !important;
    }

    .tx-md-110 {
        font-size: 110px;
    }

    .tx-md-110-force {
        font-size: 110px !important;
    }

    .tx-md-112 {
        font-size: 112px;
    }

    .tx-md-112-force {
        font-size: 112px !important;
    }

    .tx-md-114 {
        font-size: 114px;
    }

    .tx-md-114-force {
        font-size: 114px !important;
    }

    .tx-md-116 {
        font-size: 116px;
    }

    .tx-md-116-force {
        font-size: 116px !important;
    }

    .tx-md-118 {
        font-size: 118px;
    }

    .tx-md-118-force {
        font-size: 118px !important;
    }

    .tx-md-120 {
        font-size: 120px;
    }

    .tx-md-120-force {
        font-size: 120px !important;
    }

    .tx-md-122 {
        font-size: 122px;
    }

    .tx-md-122-force {
        font-size: 122px !important;
    }

    .tx-md-124 {
        font-size: 124px;
    }

    .tx-md-124-force {
        font-size: 124px !important;
    }

    .tx-md-126 {
        font-size: 126px;
    }

    .tx-md-126-force {
        font-size: 126px !important;
    }

    .tx-md-128 {
        font-size: 128px;
    }

    .tx-md-128-force {
        font-size: 128px !important;
    }

    .tx-md-130 {
        font-size: 130px;
    }

    .tx-md-130-force {
        font-size: 130px !important;
    }

    .tx-md-132 {
        font-size: 132px;
    }

    .tx-md-132-force {
        font-size: 132px !important;
    }

    .tx-md-134 {
        font-size: 134px;
    }

    .tx-md-134-force {
        font-size: 134px !important;
    }

    .tx-md-136 {
        font-size: 136px;
    }

    .tx-md-136-force {
        font-size: 136px !important;
    }

    .tx-md-138 {
        font-size: 138px;
    }

    .tx-md-138-force {
        font-size: 138px !important;
    }

    .tx-md-140 {
        font-size: 140px;
    }

    .tx-md-140-force {
        font-size: 140px !important;
    }

    .tx-md-bold {
        font-weight: 700;
    }

    .tx-md-semibold {
        font-weight: 600;
    }

    .tx-md-medium {
        font-weight: 500;
    }

    .tx-md-normal {
        font-weight: 400;
    }

    .tx-md-light {
        font-weight: 300;
    }

    .tx-md-thin {
        font-weight: 200;
    }

    .tx-md-xthin {
        font-weight: 100;
    }

    .lh-md-0 {
        line-height: 0;
    }

    .lh-md-1 {
        line-height: 1.1;
    }

    .lh-md-2 {
        line-height: 1.2;
    }

    .lh-md-3 {
        line-height: 1.3;
    }

    .lh-md-4 {
        line-height: 1.4;
    }

    .lh-md-5 {
        line-height: 1.5;
    }

    .lh-md-6 {
        line-height: 1.6;
    }

    .lh-md-7 {
        line-height: 1.7;
    }

    .lh-md-8 {
        line-height: 1.8;
    }

    .lh-md-9 {
        line-height: 1.9;
    }

    .lh-md-10 {
        line-height: 2.0;
    }

    .lh-md-11 {
        line-height: 2.1;
    }

    .lh-md-12 {
        line-height: 2.2;
    }

    .lh-md-13 {
        line-height: 2.3;
    }

    .lh-md-14 {
        line-height: 2.4;
    }

    .lh-md-15 {
        line-height: 2.5;
    }

    .tx-md-center {
        text-align: center;
    }

    .tx-md-right {
        text-align: right;
    }

    .tx-md-left {
        text-align: left;
    }

    .tx-md-center-force {
        text-align: center !important;
    }

    .tx-md-right-force {
        text-align: right !important;
    }

    .tx-md-left-force {
        text-align: left !important;
    }
}

@media (min-width: 992px) {
    .tx-lg-8 {
        font-size: 8px;
    }

    .tx-lg-8-force {
        font-size: 8px !important;
    }

    .tx-lg-9 {
        font-size: 9px;
    }

    .tx-lg-9-force {
        font-size: 9px !important;
    }

    .tx-lg-10 {
        font-size: 10px;
    }

    .tx-lg-10-force {
        font-size: 10px !important;
    }

    .tx-lg-11 {
        font-size: 11px;
    }

    .tx-lg-11-force {
        font-size: 11px !important;
    }

    .tx-lg-12 {
        font-size: 12px;
    }

    .tx-lg-12-force {
        font-size: 12px !important;
    }

    .tx-lg-13 {
        font-size: 13px;
    }

    .tx-lg-13-force {
        font-size: 13px !important;
    }

    .tx-lg-14 {
        font-size: 14px;
    }

    .tx-lg-14-force {
        font-size: 14px !important;
    }

    .tx-lg-15 {
        font-size: 15px;
    }

    .tx-lg-15-force {
        font-size: 15px !important;
    }

    .tx-lg-16 {
        font-size: 16px;
    }

    .tx-lg-16-force {
        font-size: 16px !important;
    }

    .tx-lg-18 {
        font-size: 18px;
    }

    .tx-lg-18-force {
        font-size: 18px !important;
    }

    .tx-lg-20 {
        font-size: 20px;
    }

    .tx-lg-20-force {
        font-size: 20px !important;
    }

    .tx-lg-22 {
        font-size: 22px;
    }

    .tx-lg-22-force {
        font-size: 22px !important;
    }

    .tx-lg-24 {
        font-size: 24px;
    }

    .tx-lg-24-force {
        font-size: 24px !important;
    }

    .tx-lg-26 {
        font-size: 26px;
    }

    .tx-lg-26-force {
        font-size: 26px !important;
    }

    .tx-lg-28 {
        font-size: 28px;
    }

    .tx-lg-28-force {
        font-size: 28px !important;
    }

    .tx-lg-30 {
        font-size: 30px;
    }

    .tx-lg-30-force {
        font-size: 30px !important;
    }

    .tx-lg-32 {
        font-size: 32px;
    }

    .tx-lg-32-force {
        font-size: 32px !important;
    }

    .tx-lg-34 {
        font-size: 34px;
    }

    .tx-lg-34-force {
        font-size: 34px !important;
    }

    .tx-lg-36 {
        font-size: 36px;
    }

    .tx-lg-36-force {
        font-size: 36px !important;
    }

    .tx-lg-38 {
        font-size: 38px;
    }

    .tx-lg-38-force {
        font-size: 38px !important;
    }

    .tx-lg-40 {
        font-size: 40px;
    }

    .tx-lg-40-force {
        font-size: 40px !important;
    }

    .tx-lg-42 {
        font-size: 42px;
    }

    .tx-lg-42-force {
        font-size: 42px !important;
    }

    .tx-lg-44 {
        font-size: 44px;
    }

    .tx-lg-44-force {
        font-size: 44px !important;
    }

    .tx-lg-46 {
        font-size: 46px;
    }

    .tx-lg-46-force {
        font-size: 46px !important;
    }

    .tx-lg-48 {
        font-size: 48px;
    }

    .tx-lg-48-force {
        font-size: 48px !important;
    }

    .tx-lg-50 {
        font-size: 50px;
    }

    .tx-lg-50-force {
        font-size: 50px !important;
    }

    .tx-lg-52 {
        font-size: 52px;
    }

    .tx-lg-52-force {
        font-size: 52px !important;
    }

    .tx-lg-54 {
        font-size: 54px;
    }

    .tx-lg-54-force {
        font-size: 54px !important;
    }

    .tx-lg-56 {
        font-size: 56px;
    }

    .tx-lg-56-force {
        font-size: 56px !important;
    }

    .tx-lg-58 {
        font-size: 58px;
    }

    .tx-lg-58-force {
        font-size: 58px !important;
    }

    .tx-lg-60 {
        font-size: 60px;
    }

    .tx-lg-60-force {
        font-size: 60px !important;
    }

    .tx-lg-62 {
        font-size: 62px;
    }

    .tx-lg-62-force {
        font-size: 62px !important;
    }

    .tx-lg-64 {
        font-size: 64px;
    }

    .tx-lg-64-force {
        font-size: 64px !important;
    }

    .tx-lg-66 {
        font-size: 66px;
    }

    .tx-lg-66-force {
        font-size: 66px !important;
    }

    .tx-lg-68 {
        font-size: 68px;
    }

    .tx-lg-68-force {
        font-size: 68px !important;
    }

    .tx-lg-70 {
        font-size: 70px;
    }

    .tx-lg-70-force {
        font-size: 70px !important;
    }

    .tx-lg-72 {
        font-size: 72px;
    }

    .tx-lg-72-force {
        font-size: 72px !important;
    }

    .tx-lg-74 {
        font-size: 74px;
    }

    .tx-lg-74-force {
        font-size: 74px !important;
    }

    .tx-lg-76 {
        font-size: 76px;
    }

    .tx-lg-76-force {
        font-size: 76px !important;
    }

    .tx-lg-78 {
        font-size: 78px;
    }

    .tx-lg-78-force {
        font-size: 78px !important;
    }

    .tx-lg-80 {
        font-size: 80px;
    }

    .tx-lg-80-force {
        font-size: 80px !important;
    }

    .tx-lg-82 {
        font-size: 82px;
    }

    .tx-lg-82-force {
        font-size: 82px !important;
    }

    .tx-lg-84 {
        font-size: 84px;
    }

    .tx-lg-84-force {
        font-size: 84px !important;
    }

    .tx-lg-86 {
        font-size: 86px;
    }

    .tx-lg-86-force {
        font-size: 86px !important;
    }

    .tx-lg-88 {
        font-size: 88px;
    }

    .tx-lg-88-force {
        font-size: 88px !important;
    }

    .tx-lg-90 {
        font-size: 90px;
    }

    .tx-lg-90-force {
        font-size: 90px !important;
    }

    .tx-lg-92 {
        font-size: 92px;
    }

    .tx-lg-92-force {
        font-size: 92px !important;
    }

    .tx-lg-94 {
        font-size: 94px;
    }

    .tx-lg-94-force {
        font-size: 94px !important;
    }

    .tx-lg-96 {
        font-size: 96px;
    }

    .tx-lg-96-force {
        font-size: 96px !important;
    }

    .tx-lg-98 {
        font-size: 98px;
    }

    .tx-lg-98-force {
        font-size: 98px !important;
    }

    .tx-lg-100 {
        font-size: 100px;
    }

    .tx-lg-100-force {
        font-size: 100px !important;
    }

    .tx-lg-102 {
        font-size: 102px;
    }

    .tx-lg-102-force {
        font-size: 102px !important;
    }

    .tx-lg-104 {
        font-size: 104px;
    }

    .tx-lg-104-force {
        font-size: 104px !important;
    }

    .tx-lg-106 {
        font-size: 106px;
    }

    .tx-lg-106-force {
        font-size: 106px !important;
    }

    .tx-lg-108 {
        font-size: 108px;
    }

    .tx-lg-108-force {
        font-size: 108px !important;
    }

    .tx-lg-110 {
        font-size: 110px;
    }

    .tx-lg-110-force {
        font-size: 110px !important;
    }

    .tx-lg-112 {
        font-size: 112px;
    }

    .tx-lg-112-force {
        font-size: 112px !important;
    }

    .tx-lg-114 {
        font-size: 114px;
    }

    .tx-lg-114-force {
        font-size: 114px !important;
    }

    .tx-lg-116 {
        font-size: 116px;
    }

    .tx-lg-116-force {
        font-size: 116px !important;
    }

    .tx-lg-118 {
        font-size: 118px;
    }

    .tx-lg-118-force {
        font-size: 118px !important;
    }

    .tx-lg-120 {
        font-size: 120px;
    }

    .tx-lg-120-force {
        font-size: 120px !important;
    }

    .tx-lg-122 {
        font-size: 122px;
    }

    .tx-lg-122-force {
        font-size: 122px !important;
    }

    .tx-lg-124 {
        font-size: 124px;
    }

    .tx-lg-124-force {
        font-size: 124px !important;
    }

    .tx-lg-126 {
        font-size: 126px;
    }

    .tx-lg-126-force {
        font-size: 126px !important;
    }

    .tx-lg-128 {
        font-size: 128px;
    }

    .tx-lg-128-force {
        font-size: 128px !important;
    }

    .tx-lg-130 {
        font-size: 130px;
    }

    .tx-lg-130-force {
        font-size: 130px !important;
    }

    .tx-lg-132 {
        font-size: 132px;
    }

    .tx-lg-132-force {
        font-size: 132px !important;
    }

    .tx-lg-134 {
        font-size: 134px;
    }

    .tx-lg-134-force {
        font-size: 134px !important;
    }

    .tx-lg-136 {
        font-size: 136px;
    }

    .tx-lg-136-force {
        font-size: 136px !important;
    }

    .tx-lg-138 {
        font-size: 138px;
    }

    .tx-lg-138-force {
        font-size: 138px !important;
    }

    .tx-lg-140 {
        font-size: 140px;
    }

    .tx-lg-140-force {
        font-size: 140px !important;
    }

    .tx-lg-bold {
        font-weight: 700;
    }

    .tx-lg-semibold {
        font-weight: 600;
    }

    .tx-lg-medium {
        font-weight: 500;
    }

    .tx-lg-normal {
        font-weight: 400;
    }

    .tx-lg-light {
        font-weight: 300;
    }

    .tx-lg-thin {
        font-weight: 200;
    }

    .tx-lg-xthin {
        font-weight: 100;
    }

    .lh-lg-0 {
        line-height: 0;
    }

    .lh-lg-1 {
        line-height: 1.1;
    }

    .lh-lg-2 {
        line-height: 1.2;
    }

    .lh-lg-3 {
        line-height: 1.3;
    }

    .lh-lg-4 {
        line-height: 1.4;
    }

    .lh-lg-5 {
        line-height: 1.5;
    }

    .lh-lg-6 {
        line-height: 1.6;
    }

    .lh-lg-7 {
        line-height: 1.7;
    }

    .lh-lg-8 {
        line-height: 1.8;
    }

    .lh-lg-9 {
        line-height: 1.9;
    }

    .lh-lg-10 {
        line-height: 2.0;
    }

    .lh-lg-11 {
        line-height: 2.1;
    }

    .lh-lg-12 {
        line-height: 2.2;
    }

    .lh-lg-13 {
        line-height: 2.3;
    }

    .lh-lg-14 {
        line-height: 2.4;
    }

    .lh-lg-15 {
        line-height: 2.5;
    }

    .tx-lg-center {
        text-align: center;
    }

    .tx-lg-right {
        text-align: right;
    }

    .tx-lg-left {
        text-align: left;
    }

    .tx-lg-center-force {
        text-align: center !important;
    }

    .tx-lg-right-force {
        text-align: right !important;
    }

    .tx-lg-left-force {
        text-align: left !important;
    }
}

@media (min-width: 1200px) {
    .tx-xl-8 {
        font-size: 8px;
    }

    .tx-xl-8-force {
        font-size: 8px !important;
    }

    .tx-xl-9 {
        font-size: 9px;
    }

    .tx-xl-9-force {
        font-size: 9px !important;
    }

    .tx-xl-10 {
        font-size: 10px;
    }

    .tx-xl-10-force {
        font-size: 10px !important;
    }

    .tx-xl-11 {
        font-size: 11px;
    }

    .tx-xl-11-force {
        font-size: 11px !important;
    }

    .tx-xl-12 {
        font-size: 12px;
    }

    .tx-xl-12-force {
        font-size: 12px !important;
    }

    .tx-xl-13 {
        font-size: 13px;
    }

    .tx-xl-13-force {
        font-size: 13px !important;
    }

    .tx-xl-14 {
        font-size: 14px;
    }

    .tx-xl-14-force {
        font-size: 14px !important;
    }

    .tx-xl-15 {
        font-size: 15px;
    }

    .tx-xl-15-force {
        font-size: 15px !important;
    }

    .tx-xl-16 {
        font-size: 16px;
    }

    .tx-xl-16-force {
        font-size: 16px !important;
    }

    .tx-xl-18 {
        font-size: 18px;
    }

    .tx-xl-18-force {
        font-size: 18px !important;
    }

    .tx-xl-20 {
        font-size: 20px;
    }

    .tx-xl-20-force {
        font-size: 20px !important;
    }

    .tx-xl-22 {
        font-size: 22px;
    }

    .tx-xl-22-force {
        font-size: 22px !important;
    }

    .tx-xl-24 {
        font-size: 24px;
    }

    .tx-xl-24-force {
        font-size: 24px !important;
    }

    .tx-xl-26 {
        font-size: 26px;
    }

    .tx-xl-26-force {
        font-size: 26px !important;
    }

    .tx-xl-28 {
        font-size: 28px;
    }

    .tx-xl-28-force {
        font-size: 28px !important;
    }

    .tx-xl-30 {
        font-size: 30px;
    }

    .tx-xl-30-force {
        font-size: 30px !important;
    }

    .tx-xl-32 {
        font-size: 32px;
    }

    .tx-xl-32-force {
        font-size: 32px !important;
    }

    .tx-xl-34 {
        font-size: 34px;
    }

    .tx-xl-34-force {
        font-size: 34px !important;
    }

    .tx-xl-36 {
        font-size: 36px;
    }

    .tx-xl-36-force {
        font-size: 36px !important;
    }

    .tx-xl-38 {
        font-size: 38px;
    }

    .tx-xl-38-force {
        font-size: 38px !important;
    }

    .tx-xl-40 {
        font-size: 40px;
    }

    .tx-xl-40-force {
        font-size: 40px !important;
    }

    .tx-xl-42 {
        font-size: 42px;
    }

    .tx-xl-42-force {
        font-size: 42px !important;
    }

    .tx-xl-44 {
        font-size: 44px;
    }

    .tx-xl-44-force {
        font-size: 44px !important;
    }

    .tx-xl-46 {
        font-size: 46px;
    }

    .tx-xl-46-force {
        font-size: 46px !important;
    }

    .tx-xl-48 {
        font-size: 48px;
    }

    .tx-xl-48-force {
        font-size: 48px !important;
    }

    .tx-xl-50 {
        font-size: 50px;
    }

    .tx-xl-50-force {
        font-size: 50px !important;
    }

    .tx-xl-52 {
        font-size: 52px;
    }

    .tx-xl-52-force {
        font-size: 52px !important;
    }

    .tx-xl-54 {
        font-size: 54px;
    }

    .tx-xl-54-force {
        font-size: 54px !important;
    }

    .tx-xl-56 {
        font-size: 56px;
    }

    .tx-xl-56-force {
        font-size: 56px !important;
    }

    .tx-xl-58 {
        font-size: 58px;
    }

    .tx-xl-58-force {
        font-size: 58px !important;
    }

    .tx-xl-60 {
        font-size: 60px;
    }

    .tx-xl-60-force {
        font-size: 60px !important;
    }

    .tx-xl-62 {
        font-size: 62px;
    }

    .tx-xl-62-force {
        font-size: 62px !important;
    }

    .tx-xl-64 {
        font-size: 64px;
    }

    .tx-xl-64-force {
        font-size: 64px !important;
    }

    .tx-xl-66 {
        font-size: 66px;
    }

    .tx-xl-66-force {
        font-size: 66px !important;
    }

    .tx-xl-68 {
        font-size: 68px;
    }

    .tx-xl-68-force {
        font-size: 68px !important;
    }

    .tx-xl-70 {
        font-size: 70px;
    }

    .tx-xl-70-force {
        font-size: 70px !important;
    }

    .tx-xl-72 {
        font-size: 72px;
    }

    .tx-xl-72-force {
        font-size: 72px !important;
    }

    .tx-xl-74 {
        font-size: 74px;
    }

    .tx-xl-74-force {
        font-size: 74px !important;
    }

    .tx-xl-76 {
        font-size: 76px;
    }

    .tx-xl-76-force {
        font-size: 76px !important;
    }

    .tx-xl-78 {
        font-size: 78px;
    }

    .tx-xl-78-force {
        font-size: 78px !important;
    }

    .tx-xl-80 {
        font-size: 80px;
    }

    .tx-xl-80-force {
        font-size: 80px !important;
    }

    .tx-xl-82 {
        font-size: 82px;
    }

    .tx-xl-82-force {
        font-size: 82px !important;
    }

    .tx-xl-84 {
        font-size: 84px;
    }

    .tx-xl-84-force {
        font-size: 84px !important;
    }

    .tx-xl-86 {
        font-size: 86px;
    }

    .tx-xl-86-force {
        font-size: 86px !important;
    }

    .tx-xl-88 {
        font-size: 88px;
    }

    .tx-xl-88-force {
        font-size: 88px !important;
    }

    .tx-xl-90 {
        font-size: 90px;
    }

    .tx-xl-90-force {
        font-size: 90px !important;
    }

    .tx-xl-92 {
        font-size: 92px;
    }

    .tx-xl-92-force {
        font-size: 92px !important;
    }

    .tx-xl-94 {
        font-size: 94px;
    }

    .tx-xl-94-force {
        font-size: 94px !important;
    }

    .tx-xl-96 {
        font-size: 96px;
    }

    .tx-xl-96-force {
        font-size: 96px !important;
    }

    .tx-xl-98 {
        font-size: 98px;
    }

    .tx-xl-98-force {
        font-size: 98px !important;
    }

    .tx-xl-100 {
        font-size: 100px;
    }

    .tx-xl-100-force {
        font-size: 100px !important;
    }

    .tx-xl-102 {
        font-size: 102px;
    }

    .tx-xl-102-force {
        font-size: 102px !important;
    }

    .tx-xl-104 {
        font-size: 104px;
    }

    .tx-xl-104-force {
        font-size: 104px !important;
    }

    .tx-xl-106 {
        font-size: 106px;
    }

    .tx-xl-106-force {
        font-size: 106px !important;
    }

    .tx-xl-108 {
        font-size: 108px;
    }

    .tx-xl-108-force {
        font-size: 108px !important;
    }

    .tx-xl-110 {
        font-size: 110px;
    }

    .tx-xl-110-force {
        font-size: 110px !important;
    }

    .tx-xl-112 {
        font-size: 112px;
    }

    .tx-xl-112-force {
        font-size: 112px !important;
    }

    .tx-xl-114 {
        font-size: 114px;
    }

    .tx-xl-114-force {
        font-size: 114px !important;
    }

    .tx-xl-116 {
        font-size: 116px;
    }

    .tx-xl-116-force {
        font-size: 116px !important;
    }

    .tx-xl-118 {
        font-size: 118px;
    }

    .tx-xl-118-force {
        font-size: 118px !important;
    }

    .tx-xl-120 {
        font-size: 120px;
    }

    .tx-xl-120-force {
        font-size: 120px !important;
    }

    .tx-xl-122 {
        font-size: 122px;
    }

    .tx-xl-122-force {
        font-size: 122px !important;
    }

    .tx-xl-124 {
        font-size: 124px;
    }

    .tx-xl-124-force {
        font-size: 124px !important;
    }

    .tx-xl-126 {
        font-size: 126px;
    }

    .tx-xl-126-force {
        font-size: 126px !important;
    }

    .tx-xl-128 {
        font-size: 128px;
    }

    .tx-xl-128-force {
        font-size: 128px !important;
    }

    .tx-xl-130 {
        font-size: 130px;
    }

    .tx-xl-130-force {
        font-size: 130px !important;
    }

    .tx-xl-132 {
        font-size: 132px;
    }

    .tx-xl-132-force {
        font-size: 132px !important;
    }

    .tx-xl-134 {
        font-size: 134px;
    }

    .tx-xl-134-force {
        font-size: 134px !important;
    }

    .tx-xl-136 {
        font-size: 136px;
    }

    .tx-xl-136-force {
        font-size: 136px !important;
    }

    .tx-xl-138 {
        font-size: 138px;
    }

    .tx-xl-138-force {
        font-size: 138px !important;
    }

    .tx-xl-140 {
        font-size: 140px;
    }

    .tx-xl-140-force {
        font-size: 140px !important;
    }

    .tx-xl-bold {
        font-weight: 700;
    }

    .tx-xl-semibold {
        font-weight: 600;
    }

    .tx-xl-medium {
        font-weight: 500;
    }

    .tx-xl-normal {
        font-weight: 400;
    }

    .tx-xl-light {
        font-weight: 300;
    }

    .tx-xl-thin {
        font-weight: 200;
    }

    .tx-xl-xthin {
        font-weight: 100;
    }

    .lh-xl-0 {
        line-height: 0;
    }

    .lh-xl-1 {
        line-height: 1.1;
    }

    .lh-xl-2 {
        line-height: 1.2;
    }

    .lh-xl-3 {
        line-height: 1.3;
    }

    .lh-xl-4 {
        line-height: 1.4;
    }

    .lh-xl-5 {
        line-height: 1.5;
    }

    .lh-xl-6 {
        line-height: 1.6;
    }

    .lh-xl-7 {
        line-height: 1.7;
    }

    .lh-xl-8 {
        line-height: 1.8;
    }

    .lh-xl-9 {
        line-height: 1.9;
    }

    .lh-xl-10 {
        line-height: 2.0;
    }

    .lh-xl-11 {
        line-height: 2.1;
    }

    .lh-xl-12 {
        line-height: 2.2;
    }

    .lh-xl-13 {
        line-height: 2.3;
    }

    .lh-xl-14 {
        line-height: 2.4;
    }

    .lh-xl-15 {
        line-height: 2.5;
    }

    .tx-xl-center {
        text-align: center;
    }

    .tx-xl-right {
        text-align: right;
    }

    .tx-xl-left {
        text-align: left;
    }

    .tx-xl-center-force {
        text-align: center !important;
    }

    .tx-xl-right-force {
        text-align: right !important;
    }

    .tx-xl-left-force {
        text-align: left !important;
    }
}


/* ------------------------------------------ */


/* ############### 7.10 Width ############### */


/* ------------------------------------------ */

.wd-5 {
    width: 5px;
}

.wd-5p {
    width: 5%;
}

.mx-wd-5p {
    max-width: 5%;
}

.mn-wd-5p {
    min-width: 5%;
}

.wd-5-force {
    width: 5px !important;
}

.wd-5p-force {
    width: 5% !important;
}

.mx-wd-5p-force {
    max-width: 5% !important;
}

.mn-wd-5p-force {
    min-width: 5% !important;
}

.wd-10 {
    width: 10px;
}

.wd-10p {
    width: 10%;
}

.mx-wd-10p {
    max-width: 10%;
}

.mn-wd-10p {
    min-width: 10%;
}

.wd-10-force {
    width: 10px !important;
}

.wd-10p-force {
    width: 10% !important;
}

.mx-wd-10p-force {
    max-width: 10% !important;
}

.mn-wd-10p-force {
    min-width: 10% !important;
}

.wd-15 {
    width: 15px;
}

.wd-15p {
    width: 15%;
}

.mx-wd-15p {
    max-width: 15%;
}

.mn-wd-15p {
    min-width: 15%;
}

.wd-15-force {
    width: 15px !important;
}

.wd-15p-force {
    width: 15% !important;
}

.mx-wd-15p-force {
    max-width: 15% !important;
}

.mn-wd-15p-force {
    min-width: 15% !important;
}

.wd-20 {
    width: 20px;
}

.wd-20p {
    width: 20%;
}

.mx-wd-20p {
    max-width: 20%;
}

.mn-wd-20p {
    min-width: 20%;
}

.wd-20-force {
    width: 20px !important;
}

.wd-20p-force {
    width: 20% !important;
}

.mx-wd-20p-force {
    max-width: 20% !important;
}

.mn-wd-20p-force {
    min-width: 20% !important;
}

.wd-25 {
    width: 25px;
}

.wd-25p {
    width: 25%;
}

.mx-wd-25p {
    max-width: 25%;
}

.mn-wd-25p {
    min-width: 25%;
}

.wd-25-force {
    width: 25px !important;
}

.wd-25p-force {
    width: 25% !important;
}

.mx-wd-25p-force {
    max-width: 25% !important;
}

.mn-wd-25p-force {
    min-width: 25% !important;
}

.wd-30 {
    width: 30px;
}

.wd-30p {
    width: 30%;
}

.mx-wd-30p {
    max-width: 30%;
}

.mn-wd-30p {
    min-width: 30%;
}

.wd-30-force {
    width: 30px !important;
}

.wd-30p-force {
    width: 30% !important;
}

.mx-wd-30p-force {
    max-width: 30% !important;
}

.mn-wd-30p-force {
    min-width: 30% !important;
}

.wd-35 {
    width: 35px;
}

.wd-35p {
    width: 35%;
}

.mx-wd-35p {
    max-width: 35%;
}

.mn-wd-35p {
    min-width: 35%;
}

.wd-35-force {
    width: 35px !important;
}

.wd-35p-force {
    width: 35% !important;
}

.mx-wd-35p-force {
    max-width: 35% !important;
}

.mn-wd-35p-force {
    min-width: 35% !important;
}

.wd-40 {
    width: 40px;
}

.wd-40p {
    width: 40%;
}

.mx-wd-40p {
    max-width: 40%;
}

.mn-wd-40p {
    min-width: 40%;
}

.wd-40-force {
    width: 40px !important;
}

.wd-40p-force {
    width: 40% !important;
}

.mx-wd-40p-force {
    max-width: 40% !important;
}

.mn-wd-40p-force {
    min-width: 40% !important;
}

.wd-45 {
    width: 45px;
}

.wd-45p {
    width: 45%;
}

.mx-wd-45p {
    max-width: 45%;
}

.mn-wd-45p {
    min-width: 45%;
}

.wd-45-force {
    width: 45px !important;
}

.wd-45p-force {
    width: 45% !important;
}

.mx-wd-45p-force {
    max-width: 45% !important;
}

.mn-wd-45p-force {
    min-width: 45% !important;
}

.wd-50 {
    width: 50px;
}

.wd-50p {
    width: 50%;
}

.mx-wd-50p {
    max-width: 50%;
}

.mn-wd-50p {
    min-width: 50%;
}

.wd-50-force {
    width: 50px !important;
}

.wd-50p-force {
    width: 50% !important;
}

.mx-wd-50p-force {
    max-width: 50% !important;
}

.mn-wd-50p-force {
    min-width: 50% !important;
}

.wd-55 {
    width: 55px;
}

.wd-55p {
    width: 55%;
}

.mx-wd-55p {
    max-width: 55%;
}

.mn-wd-55p {
    min-width: 55%;
}

.wd-55-force {
    width: 55px !important;
}

.wd-55p-force {
    width: 55% !important;
}

.mx-wd-55p-force {
    max-width: 55% !important;
}

.mn-wd-55p-force {
    min-width: 55% !important;
}

.wd-60 {
    width: 60px;
}

.wd-60p {
    width: 60%;
}

.mx-wd-60p {
    max-width: 60%;
}

.mn-wd-60p {
    min-width: 60%;
}

.wd-60-force {
    width: 60px !important;
}

.wd-60p-force {
    width: 60% !important;
}

.mx-wd-60p-force {
    max-width: 60% !important;
}

.mn-wd-60p-force {
    min-width: 60% !important;
}

.wd-65 {
    width: 65px;
}

.wd-65p {
    width: 65%;
}

.mx-wd-65p {
    max-width: 65%;
}

.mn-wd-65p {
    min-width: 65%;
}

.wd-65-force {
    width: 65px !important;
}

.wd-65p-force {
    width: 65% !important;
}

.mx-wd-65p-force {
    max-width: 65% !important;
}

.mn-wd-65p-force {
    min-width: 65% !important;
}

.wd-70 {
    width: 70px;
}

.wd-70p {
    width: 70%;
}

.mx-wd-70p {
    max-width: 70%;
}

.mn-wd-70p {
    min-width: 70%;
}

.wd-70-force {
    width: 70px !important;
}

.wd-70p-force {
    width: 70% !important;
}

.mx-wd-70p-force {
    max-width: 70% !important;
}

.mn-wd-70p-force {
    min-width: 70% !important;
}

.wd-75 {
    width: 75px;
}

.wd-75p {
    width: 75%;
}

.mx-wd-75p {
    max-width: 75%;
}

.mn-wd-75p {
    min-width: 75%;
}

.wd-75-force {
    width: 75px !important;
}

.wd-75p-force {
    width: 75% !important;
}

.mx-wd-75p-force {
    max-width: 75% !important;
}

.mn-wd-75p-force {
    min-width: 75% !important;
}

.wd-80 {
    width: 80px;
}

.wd-80p {
    width: 80%;
}

.mx-wd-80p {
    max-width: 80%;
}

.mn-wd-80p {
    min-width: 80%;
}

.wd-80-force {
    width: 80px !important;
}

.wd-80p-force {
    width: 80% !important;
}

.mx-wd-80p-force {
    max-width: 80% !important;
}

.mn-wd-80p-force {
    min-width: 80% !important;
}

.wd-85 {
    width: 85px;
}

.wd-85p {
    width: 85%;
}

.mx-wd-85p {
    max-width: 85%;
}

.mn-wd-85p {
    min-width: 85%;
}

.wd-85-force {
    width: 85px !important;
}

.wd-85p-force {
    width: 85% !important;
}

.mx-wd-85p-force {
    max-width: 85% !important;
}

.mn-wd-85p-force {
    min-width: 85% !important;
}

.wd-90 {
    width: 90px;
}

.wd-90p {
    width: 90%;
}

.mx-wd-90p {
    max-width: 90%;
}

.mn-wd-90p {
    min-width: 90%;
}

.wd-90-force {
    width: 90px !important;
}

.wd-90p-force {
    width: 90% !important;
}

.mx-wd-90p-force {
    max-width: 90% !important;
}

.mn-wd-90p-force {
    min-width: 90% !important;
}

.wd-95 {
    width: 95px;
}

.wd-95p {
    width: 95%;
}

.mx-wd-95p {
    max-width: 95%;
}

.mn-wd-95p {
    min-width: 95%;
}

.wd-95-force {
    width: 95px !important;
}

.wd-95p-force {
    width: 95% !important;
}

.mx-wd-95p-force {
    max-width: 95% !important;
}

.mn-wd-95p-force {
    min-width: 95% !important;
}

.wd-100 {
    width: 100px;
}

.wd-100p {
    width: 100%;
}

.mx-wd-100p {
    max-width: 100%;
}

.mn-wd-100p {
    min-width: 100%;
}

.wd-100-force {
    width: 100px !important;
}

.wd-100p-force {
    width: 100% !important;
}

.mx-wd-100p-force {
    max-width: 100% !important;
}

.mn-wd-100p-force {
    min-width: 100% !important;
}

.wd-150 {
    width: 150px;
}

.wd-150-force {
    width: 150px !important;
}

.wd-200 {
    width: 200px;
}

.wd-200-force {
    width: 200px !important;
}

.wd-250 {
    width: 250px;
}

.wd-250-force {
    width: 250px !important;
}

.wd-300 {
    width: 300px;
}

.wd-300-force {
    width: 300px !important;
}

.wd-350 {
    width: 350px;
}

.wd-350-force {
    width: 350px !important;
}

.wd-400 {
    width: 400px;
}

.wd-400-force {
    width: 400px !important;
}

.wd-450 {
    width: 450px;
}

.wd-450-force {
    width: 450px !important;
}

.wd-500 {
    width: 500px;
}

.wd-500-force {
    width: 500px !important;
}

.wd-550 {
    width: 550px;
}

.wd-550-force {
    width: 550px !important;
}

.wd-600 {
    width: 600px;
}

.wd-600-force {
    width: 600px !important;
}

.wd-650 {
    width: 650px;
}

.wd-650-force {
    width: 650px !important;
}

.wd-700 {
    width: 700px;
}

.wd-700-force {
    width: 700px !important;
}

.wd-750 {
    width: 750px;
}

.wd-750-force {
    width: 750px !important;
}

.wd-800 {
    width: 800px;
}

.wd-800-force {
    width: 800px !important;
}

.wd-850 {
    width: 850px;
}

.wd-850-force {
    width: 850px !important;
}

.wd-900 {
    width: 900px;
}

.wd-900-force {
    width: 900px !important;
}

.wd-950 {
    width: 950px;
}

.wd-950-force {
    width: 950px !important;
}

.wd-1000 {
    width: 1000px;
}

.wd-1000-force {
    width: 1000px !important;
}

@media (min-width: 480px) {
    .wd-xs-5 {
        width: 5px;
    }

    .wd-xs-5p {
        width: 5%;
    }

    .mx-wd-xs-5p {
        max-width: 5%;
    }

    .mn-wd-xs-5p {
        min-width: 5%;
    }

    .wd-xs-5-force {
        width: 5px !important;
    }

    .wd-xs-5p-force {
        width: 5% !important;
    }

    .mx-wd-xs-5p-force {
        max-width: 5% !important;
    }

    .mn-wd-xs-5p-force {
        min-width: 5% !important;
    }

    .wd-xs-10 {
        width: 10px;
    }

    .wd-xs-10p {
        width: 10%;
    }

    .mx-wd-xs-10p {
        max-width: 10%;
    }

    .mn-wd-xs-10p {
        min-width: 10%;
    }

    .wd-xs-10-force {
        width: 10px !important;
    }

    .wd-xs-10p-force {
        width: 10% !important;
    }

    .mx-wd-xs-10p-force {
        max-width: 10% !important;
    }

    .mn-wd-xs-10p-force {
        min-width: 10% !important;
    }

    .wd-xs-15 {
        width: 15px;
    }

    .wd-xs-15p {
        width: 15%;
    }

    .mx-wd-xs-15p {
        max-width: 15%;
    }

    .mn-wd-xs-15p {
        min-width: 15%;
    }

    .wd-xs-15-force {
        width: 15px !important;
    }

    .wd-xs-15p-force {
        width: 15% !important;
    }

    .mx-wd-xs-15p-force {
        max-width: 15% !important;
    }

    .mn-wd-xs-15p-force {
        min-width: 15% !important;
    }

    .wd-xs-20 {
        width: 20px;
    }

    .wd-xs-20p {
        width: 20%;
    }

    .mx-wd-xs-20p {
        max-width: 20%;
    }

    .mn-wd-xs-20p {
        min-width: 20%;
    }

    .wd-xs-20-force {
        width: 20px !important;
    }

    .wd-xs-20p-force {
        width: 20% !important;
    }

    .mx-wd-xs-20p-force {
        max-width: 20% !important;
    }

    .mn-wd-xs-20p-force {
        min-width: 20% !important;
    }

    .wd-xs-25 {
        width: 25px;
    }

    .wd-xs-25p {
        width: 25%;
    }

    .mx-wd-xs-25p {
        max-width: 25%;
    }

    .mn-wd-xs-25p {
        min-width: 25%;
    }

    .wd-xs-25-force {
        width: 25px !important;
    }

    .wd-xs-25p-force {
        width: 25% !important;
    }

    .mx-wd-xs-25p-force {
        max-width: 25% !important;
    }

    .mn-wd-xs-25p-force {
        min-width: 25% !important;
    }

    .wd-xs-30 {
        width: 30px;
    }

    .wd-xs-30p {
        width: 30%;
    }

    .mx-wd-xs-30p {
        max-width: 30%;
    }

    .mn-wd-xs-30p {
        min-width: 30%;
    }

    .wd-xs-30-force {
        width: 30px !important;
    }

    .wd-xs-30p-force {
        width: 30% !important;
    }

    .mx-wd-xs-30p-force {
        max-width: 30% !important;
    }

    .mn-wd-xs-30p-force {
        min-width: 30% !important;
    }

    .wd-xs-35 {
        width: 35px;
    }

    .wd-xs-35p {
        width: 35%;
    }

    .mx-wd-xs-35p {
        max-width: 35%;
    }

    .mn-wd-xs-35p {
        min-width: 35%;
    }

    .wd-xs-35-force {
        width: 35px !important;
    }

    .wd-xs-35p-force {
        width: 35% !important;
    }

    .mx-wd-xs-35p-force {
        max-width: 35% !important;
    }

    .mn-wd-xs-35p-force {
        min-width: 35% !important;
    }

    .wd-xs-40 {
        width: 40px;
    }

    .wd-xs-40p {
        width: 40%;
    }

    .mx-wd-xs-40p {
        max-width: 40%;
    }

    .mn-wd-xs-40p {
        min-width: 40%;
    }

    .wd-xs-40-force {
        width: 40px !important;
    }

    .wd-xs-40p-force {
        width: 40% !important;
    }

    .mx-wd-xs-40p-force {
        max-width: 40% !important;
    }

    .mn-wd-xs-40p-force {
        min-width: 40% !important;
    }

    .wd-xs-45 {
        width: 45px;
    }

    .wd-xs-45p {
        width: 45%;
    }

    .mx-wd-xs-45p {
        max-width: 45%;
    }

    .mn-wd-xs-45p {
        min-width: 45%;
    }

    .wd-xs-45-force {
        width: 45px !important;
    }

    .wd-xs-45p-force {
        width: 45% !important;
    }

    .mx-wd-xs-45p-force {
        max-width: 45% !important;
    }

    .mn-wd-xs-45p-force {
        min-width: 45% !important;
    }

    .wd-xs-50 {
        width: 50px;
    }

    .wd-xs-50p {
        width: 50%;
    }

    .mx-wd-xs-50p {
        max-width: 50%;
    }

    .mn-wd-xs-50p {
        min-width: 50%;
    }

    .wd-xs-50-force {
        width: 50px !important;
    }

    .wd-xs-50p-force {
        width: 50% !important;
    }

    .mx-wd-xs-50p-force {
        max-width: 50% !important;
    }

    .mn-wd-xs-50p-force {
        min-width: 50% !important;
    }

    .wd-xs-55 {
        width: 55px;
    }

    .wd-xs-55p {
        width: 55%;
    }

    .mx-wd-xs-55p {
        max-width: 55%;
    }

    .mn-wd-xs-55p {
        min-width: 55%;
    }

    .wd-xs-55-force {
        width: 55px !important;
    }

    .wd-xs-55p-force {
        width: 55% !important;
    }

    .mx-wd-xs-55p-force {
        max-width: 55% !important;
    }

    .mn-wd-xs-55p-force {
        min-width: 55% !important;
    }

    .wd-xs-60 {
        width: 60px;
    }

    .wd-xs-60p {
        width: 60%;
    }

    .mx-wd-xs-60p {
        max-width: 60%;
    }

    .mn-wd-xs-60p {
        min-width: 60%;
    }

    .wd-xs-60-force {
        width: 60px !important;
    }

    .wd-xs-60p-force {
        width: 60% !important;
    }

    .mx-wd-xs-60p-force {
        max-width: 60% !important;
    }

    .mn-wd-xs-60p-force {
        min-width: 60% !important;
    }

    .wd-xs-65 {
        width: 65px;
    }

    .wd-xs-65p {
        width: 65%;
    }

    .mx-wd-xs-65p {
        max-width: 65%;
    }

    .mn-wd-xs-65p {
        min-width: 65%;
    }

    .wd-xs-65-force {
        width: 65px !important;
    }

    .wd-xs-65p-force {
        width: 65% !important;
    }

    .mx-wd-xs-65p-force {
        max-width: 65% !important;
    }

    .mn-wd-xs-65p-force {
        min-width: 65% !important;
    }

    .wd-xs-70 {
        width: 70px;
    }

    .wd-xs-70p {
        width: 70%;
    }

    .mx-wd-xs-70p {
        max-width: 70%;
    }

    .mn-wd-xs-70p {
        min-width: 70%;
    }

    .wd-xs-70-force {
        width: 70px !important;
    }

    .wd-xs-70p-force {
        width: 70% !important;
    }

    .mx-wd-xs-70p-force {
        max-width: 70% !important;
    }

    .mn-wd-xs-70p-force {
        min-width: 70% !important;
    }

    .wd-xs-75 {
        width: 75px;
    }

    .wd-xs-75p {
        width: 75%;
    }

    .mx-wd-xs-75p {
        max-width: 75%;
    }

    .mn-wd-xs-75p {
        min-width: 75%;
    }

    .wd-xs-75-force {
        width: 75px !important;
    }

    .wd-xs-75p-force {
        width: 75% !important;
    }

    .mx-wd-xs-75p-force {
        max-width: 75% !important;
    }

    .mn-wd-xs-75p-force {
        min-width: 75% !important;
    }

    .wd-xs-80 {
        width: 80px;
    }

    .wd-xs-80p {
        width: 80%;
    }

    .mx-wd-xs-80p {
        max-width: 80%;
    }

    .mn-wd-xs-80p {
        min-width: 80%;
    }

    .wd-xs-80-force {
        width: 80px !important;
    }

    .wd-xs-80p-force {
        width: 80% !important;
    }

    .mx-wd-xs-80p-force {
        max-width: 80% !important;
    }

    .mn-wd-xs-80p-force {
        min-width: 80% !important;
    }

    .wd-xs-85 {
        width: 85px;
    }

    .wd-xs-85p {
        width: 85%;
    }

    .mx-wd-xs-85p {
        max-width: 85%;
    }

    .mn-wd-xs-85p {
        min-width: 85%;
    }

    .wd-xs-85-force {
        width: 85px !important;
    }

    .wd-xs-85p-force {
        width: 85% !important;
    }

    .mx-wd-xs-85p-force {
        max-width: 85% !important;
    }

    .mn-wd-xs-85p-force {
        min-width: 85% !important;
    }

    .wd-xs-90 {
        width: 90px;
    }

    .wd-xs-90p {
        width: 90%;
    }

    .mx-wd-xs-90p {
        max-width: 90%;
    }

    .mn-wd-xs-90p {
        min-width: 90%;
    }

    .wd-xs-90-force {
        width: 90px !important;
    }

    .wd-xs-90p-force {
        width: 90% !important;
    }

    .mx-wd-xs-90p-force {
        max-width: 90% !important;
    }

    .mn-wd-xs-90p-force {
        min-width: 90% !important;
    }

    .wd-xs-95 {
        width: 95px;
    }

    .wd-xs-95p {
        width: 95%;
    }

    .mx-wd-xs-95p {
        max-width: 95%;
    }

    .mn-wd-xs-95p {
        min-width: 95%;
    }

    .wd-xs-95-force {
        width: 95px !important;
    }

    .wd-xs-95p-force {
        width: 95% !important;
    }

    .mx-wd-xs-95p-force {
        max-width: 95% !important;
    }

    .mn-wd-xs-95p-force {
        min-width: 95% !important;
    }

    .wd-xs-100 {
        width: 100px;
    }

    .wd-xs-100p {
        width: 100%;
    }

    .mx-wd-xs-100p {
        max-width: 100%;
    }

    .mn-wd-xs-100p {
        min-width: 100%;
    }

    .wd-xs-100-force {
        width: 100px !important;
    }

    .wd-xs-100p-force {
        width: 100% !important;
    }

    .mx-wd-xs-100p-force {
        max-width: 100% !important;
    }

    .mn-wd-xs-100p-force {
        min-width: 100% !important;
    }

    .wd-xs-150 {
        width: 150px;
    }

    .wd-xs-150p {
        width: 150%;
    }

    .mx-wd-xs-150p {
        max-width: 150%;
    }

    .mn-wd-xs-150p {
        min-width: 150%;
    }

    .wd-xs-150-force {
        width: 150px !important;
    }

    .wd-xs-150p-force {
        width: 150% !important;
    }

    .mx-wd-xs-150p-force {
        max-width: 150% !important;
    }

    .mn-wd-xs-150p-force {
        min-width: 150% !important;
    }

    .wd-xs-200 {
        width: 200px;
    }

    .wd-xs-200p {
        width: 200%;
    }

    .mx-wd-xs-200p {
        max-width: 200%;
    }

    .mn-wd-xs-200p {
        min-width: 200%;
    }

    .wd-xs-200-force {
        width: 200px !important;
    }

    .wd-xs-200p-force {
        width: 200% !important;
    }

    .mx-wd-xs-200p-force {
        max-width: 200% !important;
    }

    .mn-wd-xs-200p-force {
        min-width: 200% !important;
    }

    .wd-xs-250 {
        width: 250px;
    }

    .wd-xs-250p {
        width: 250%;
    }

    .mx-wd-xs-250p {
        max-width: 250%;
    }

    .mn-wd-xs-250p {
        min-width: 250%;
    }

    .wd-xs-250-force {
        width: 250px !important;
    }

    .wd-xs-250p-force {
        width: 250% !important;
    }

    .mx-wd-xs-250p-force {
        max-width: 250% !important;
    }

    .mn-wd-xs-250p-force {
        min-width: 250% !important;
    }

    .wd-xs-300 {
        width: 300px;
    }

    .wd-xs-300p {
        width: 300%;
    }

    .mx-wd-xs-300p {
        max-width: 300%;
    }

    .mn-wd-xs-300p {
        min-width: 300%;
    }

    .wd-xs-300-force {
        width: 300px !important;
    }

    .wd-xs-300p-force {
        width: 300% !important;
    }

    .mx-wd-xs-300p-force {
        max-width: 300% !important;
    }

    .mn-wd-xs-300p-force {
        min-width: 300% !important;
    }

    .wd-xs-350 {
        width: 350px;
    }

    .wd-xs-350p {
        width: 350%;
    }

    .mx-wd-xs-350p {
        max-width: 350%;
    }

    .mn-wd-xs-350p {
        min-width: 350%;
    }

    .wd-xs-350-force {
        width: 350px !important;
    }

    .wd-xs-350p-force {
        width: 350% !important;
    }

    .mx-wd-xs-350p-force {
        max-width: 350% !important;
    }

    .mn-wd-xs-350p-force {
        min-width: 350% !important;
    }

    .wd-xs-400 {
        width: 400px;
    }

    .wd-xs-400p {
        width: 400%;
    }

    .mx-wd-xs-400p {
        max-width: 400%;
    }

    .mn-wd-xs-400p {
        min-width: 400%;
    }

    .wd-xs-400-force {
        width: 400px !important;
    }

    .wd-xs-400p-force {
        width: 400% !important;
    }

    .mx-wd-xs-400p-force {
        max-width: 400% !important;
    }

    .mn-wd-xs-400p-force {
        min-width: 400% !important;
    }

    .wd-xs-450 {
        width: 450px;
    }

    .wd-xs-450p {
        width: 450%;
    }

    .mx-wd-xs-450p {
        max-width: 450%;
    }

    .mn-wd-xs-450p {
        min-width: 450%;
    }

    .wd-xs-450-force {
        width: 450px !important;
    }

    .wd-xs-450p-force {
        width: 450% !important;
    }

    .mx-wd-xs-450p-force {
        max-width: 450% !important;
    }

    .mn-wd-xs-450p-force {
        min-width: 450% !important;
    }

    .wd-xs-500 {
        width: 500px;
    }

    .wd-xs-500p {
        width: 500%;
    }

    .mx-wd-xs-500p {
        max-width: 500%;
    }

    .mn-wd-xs-500p {
        min-width: 500%;
    }

    .wd-xs-500-force {
        width: 500px !important;
    }

    .wd-xs-500p-force {
        width: 500% !important;
    }

    .mx-wd-xs-500p-force {
        max-width: 500% !important;
    }

    .mn-wd-xs-500p-force {
        min-width: 500% !important;
    }

    .wd-xs-550 {
        width: 550px;
    }

    .wd-xs-550p {
        width: 550%;
    }

    .mx-wd-xs-550p {
        max-width: 550%;
    }

    .mn-wd-xs-550p {
        min-width: 550%;
    }

    .wd-xs-550-force {
        width: 550px !important;
    }

    .wd-xs-550p-force {
        width: 550% !important;
    }

    .mx-wd-xs-550p-force {
        max-width: 550% !important;
    }

    .mn-wd-xs-550p-force {
        min-width: 550% !important;
    }

    .wd-xs-600 {
        width: 600px;
    }

    .wd-xs-600p {
        width: 600%;
    }

    .mx-wd-xs-600p {
        max-width: 600%;
    }

    .mn-wd-xs-600p {
        min-width: 600%;
    }

    .wd-xs-600-force {
        width: 600px !important;
    }

    .wd-xs-600p-force {
        width: 600% !important;
    }

    .mx-wd-xs-600p-force {
        max-width: 600% !important;
    }

    .mn-wd-xs-600p-force {
        min-width: 600% !important;
    }

    .wd-xs-650 {
        width: 650px;
    }

    .wd-xs-650p {
        width: 650%;
    }

    .mx-wd-xs-650p {
        max-width: 650%;
    }

    .mn-wd-xs-650p {
        min-width: 650%;
    }

    .wd-xs-650-force {
        width: 650px !important;
    }

    .wd-xs-650p-force {
        width: 650% !important;
    }

    .mx-wd-xs-650p-force {
        max-width: 650% !important;
    }

    .mn-wd-xs-650p-force {
        min-width: 650% !important;
    }

    .wd-xs-700 {
        width: 700px;
    }

    .wd-xs-700p {
        width: 700%;
    }

    .mx-wd-xs-700p {
        max-width: 700%;
    }

    .mn-wd-xs-700p {
        min-width: 700%;
    }

    .wd-xs-700-force {
        width: 700px !important;
    }

    .wd-xs-700p-force {
        width: 700% !important;
    }

    .mx-wd-xs-700p-force {
        max-width: 700% !important;
    }

    .mn-wd-xs-700p-force {
        min-width: 700% !important;
    }

    .wd-xs-750 {
        width: 750px;
    }

    .wd-xs-750p {
        width: 750%;
    }

    .mx-wd-xs-750p {
        max-width: 750%;
    }

    .mn-wd-xs-750p {
        min-width: 750%;
    }

    .wd-xs-750-force {
        width: 750px !important;
    }

    .wd-xs-750p-force {
        width: 750% !important;
    }

    .mx-wd-xs-750p-force {
        max-width: 750% !important;
    }

    .mn-wd-xs-750p-force {
        min-width: 750% !important;
    }

    .wd-xs-800 {
        width: 800px;
    }

    .wd-xs-800p {
        width: 800%;
    }

    .mx-wd-xs-800p {
        max-width: 800%;
    }

    .mn-wd-xs-800p {
        min-width: 800%;
    }

    .wd-xs-800-force {
        width: 800px !important;
    }

    .wd-xs-800p-force {
        width: 800% !important;
    }

    .mx-wd-xs-800p-force {
        max-width: 800% !important;
    }

    .mn-wd-xs-800p-force {
        min-width: 800% !important;
    }

    .wd-xs-850 {
        width: 850px;
    }

    .wd-xs-850p {
        width: 850%;
    }

    .mx-wd-xs-850p {
        max-width: 850%;
    }

    .mn-wd-xs-850p {
        min-width: 850%;
    }

    .wd-xs-850-force {
        width: 850px !important;
    }

    .wd-xs-850p-force {
        width: 850% !important;
    }

    .mx-wd-xs-850p-force {
        max-width: 850% !important;
    }

    .mn-wd-xs-850p-force {
        min-width: 850% !important;
    }

    .wd-xs-900 {
        width: 900px;
    }

    .wd-xs-900p {
        width: 900%;
    }

    .mx-wd-xs-900p {
        max-width: 900%;
    }

    .mn-wd-xs-900p {
        min-width: 900%;
    }

    .wd-xs-900-force {
        width: 900px !important;
    }

    .wd-xs-900p-force {
        width: 900% !important;
    }

    .mx-wd-xs-900p-force {
        max-width: 900% !important;
    }

    .mn-wd-xs-900p-force {
        min-width: 900% !important;
    }

    .wd-xs-950 {
        width: 950px;
    }

    .wd-xs-950p {
        width: 950%;
    }

    .mx-wd-xs-950p {
        max-width: 950%;
    }

    .mn-wd-xs-950p {
        min-width: 950%;
    }

    .wd-xs-950-force {
        width: 950px !important;
    }

    .wd-xs-950p-force {
        width: 950% !important;
    }

    .mx-wd-xs-950p-force {
        max-width: 950% !important;
    }

    .mn-wd-xs-950p-force {
        min-width: 950% !important;
    }

    .wd-xs-1000 {
        width: 1000px;
    }

    .wd-xs-1000p {
        width: 1000%;
    }

    .mx-wd-xs-1000p {
        max-width: 1000%;
    }

    .mn-wd-xs-1000p {
        min-width: 1000%;
    }

    .wd-xs-1000-force {
        width: 1000px !important;
    }

    .wd-xs-1000p-force {
        width: 1000% !important;
    }

    .mx-wd-xs-1000p-force {
        max-width: 1000% !important;
    }

    .mn-wd-xs-1000p-force {
        min-width: 1000% !important;
    }

    .wd-xs-auto {
        width: auto;
    }

    .wd-xs-auto-force {
        width: auto !important;
    }
}

@media (min-width: 576px) {
    .wd-sm-5 {
        width: 5px;
    }

    .wd-sm-5p {
        width: 5%;
    }

    .mx-wd-sm-5p {
        max-width: 5%;
    }

    .mn-wd-sm-5p {
        min-width: 5%;
    }

    .wd-sm-5-force {
        width: 5px !important;
    }

    .wd-sm-5p-force {
        width: 5% !important;
    }

    .mx-wd-sm-5p-force {
        max-width: 5% !important;
    }

    .mn-wd-sm-5p-force {
        min-width: 5% !important;
    }

    .wd-sm-10 {
        width: 10px;
    }

    .wd-sm-10p {
        width: 10%;
    }

    .mx-wd-sm-10p {
        max-width: 10%;
    }

    .mn-wd-sm-10p {
        min-width: 10%;
    }

    .wd-sm-10-force {
        width: 10px !important;
    }

    .wd-sm-10p-force {
        width: 10% !important;
    }

    .mx-wd-sm-10p-force {
        max-width: 10% !important;
    }

    .mn-wd-sm-10p-force {
        min-width: 10% !important;
    }

    .wd-sm-15 {
        width: 15px;
    }

    .wd-sm-15p {
        width: 15%;
    }

    .mx-wd-sm-15p {
        max-width: 15%;
    }

    .mn-wd-sm-15p {
        min-width: 15%;
    }

    .wd-sm-15-force {
        width: 15px !important;
    }

    .wd-sm-15p-force {
        width: 15% !important;
    }

    .mx-wd-sm-15p-force {
        max-width: 15% !important;
    }

    .mn-wd-sm-15p-force {
        min-width: 15% !important;
    }

    .wd-sm-20 {
        width: 20px;
    }

    .wd-sm-20p {
        width: 20%;
    }

    .mx-wd-sm-20p {
        max-width: 20%;
    }

    .mn-wd-sm-20p {
        min-width: 20%;
    }

    .wd-sm-20-force {
        width: 20px !important;
    }

    .wd-sm-20p-force {
        width: 20% !important;
    }

    .mx-wd-sm-20p-force {
        max-width: 20% !important;
    }

    .mn-wd-sm-20p-force {
        min-width: 20% !important;
    }

    .wd-sm-25 {
        width: 25px;
    }

    .wd-sm-25p {
        width: 25%;
    }

    .mx-wd-sm-25p {
        max-width: 25%;
    }

    .mn-wd-sm-25p {
        min-width: 25%;
    }

    .wd-sm-25-force {
        width: 25px !important;
    }

    .wd-sm-25p-force {
        width: 25% !important;
    }

    .mx-wd-sm-25p-force {
        max-width: 25% !important;
    }

    .mn-wd-sm-25p-force {
        min-width: 25% !important;
    }

    .wd-sm-30 {
        width: 30px;
    }

    .wd-sm-30p {
        width: 30%;
    }

    .mx-wd-sm-30p {
        max-width: 30%;
    }

    .mn-wd-sm-30p {
        min-width: 30%;
    }

    .wd-sm-30-force {
        width: 30px !important;
    }

    .wd-sm-30p-force {
        width: 30% !important;
    }

    .mx-wd-sm-30p-force {
        max-width: 30% !important;
    }

    .mn-wd-sm-30p-force {
        min-width: 30% !important;
    }

    .wd-sm-35 {
        width: 35px;
    }

    .wd-sm-35p {
        width: 35%;
    }

    .mx-wd-sm-35p {
        max-width: 35%;
    }

    .mn-wd-sm-35p {
        min-width: 35%;
    }

    .wd-sm-35-force {
        width: 35px !important;
    }

    .wd-sm-35p-force {
        width: 35% !important;
    }

    .mx-wd-sm-35p-force {
        max-width: 35% !important;
    }

    .mn-wd-sm-35p-force {
        min-width: 35% !important;
    }

    .wd-sm-40 {
        width: 40px;
    }

    .wd-sm-40p {
        width: 40%;
    }

    .mx-wd-sm-40p {
        max-width: 40%;
    }

    .mn-wd-sm-40p {
        min-width: 40%;
    }

    .wd-sm-40-force {
        width: 40px !important;
    }

    .wd-sm-40p-force {
        width: 40% !important;
    }

    .mx-wd-sm-40p-force {
        max-width: 40% !important;
    }

    .mn-wd-sm-40p-force {
        min-width: 40% !important;
    }

    .wd-sm-45 {
        width: 45px;
    }

    .wd-sm-45p {
        width: 45%;
    }

    .mx-wd-sm-45p {
        max-width: 45%;
    }

    .mn-wd-sm-45p {
        min-width: 45%;
    }

    .wd-sm-45-force {
        width: 45px !important;
    }

    .wd-sm-45p-force {
        width: 45% !important;
    }

    .mx-wd-sm-45p-force {
        max-width: 45% !important;
    }

    .mn-wd-sm-45p-force {
        min-width: 45% !important;
    }

    .wd-sm-50 {
        width: 50px;
    }

    .wd-sm-50p {
        width: 50%;
    }

    .mx-wd-sm-50p {
        max-width: 50%;
    }

    .mn-wd-sm-50p {
        min-width: 50%;
    }

    .wd-sm-50-force {
        width: 50px !important;
    }

    .wd-sm-50p-force {
        width: 50% !important;
    }

    .mx-wd-sm-50p-force {
        max-width: 50% !important;
    }

    .mn-wd-sm-50p-force {
        min-width: 50% !important;
    }

    .wd-sm-55 {
        width: 55px;
    }

    .wd-sm-55p {
        width: 55%;
    }

    .mx-wd-sm-55p {
        max-width: 55%;
    }

    .mn-wd-sm-55p {
        min-width: 55%;
    }

    .wd-sm-55-force {
        width: 55px !important;
    }

    .wd-sm-55p-force {
        width: 55% !important;
    }

    .mx-wd-sm-55p-force {
        max-width: 55% !important;
    }

    .mn-wd-sm-55p-force {
        min-width: 55% !important;
    }

    .wd-sm-60 {
        width: 60px;
    }

    .wd-sm-60p {
        width: 60%;
    }

    .mx-wd-sm-60p {
        max-width: 60%;
    }

    .mn-wd-sm-60p {
        min-width: 60%;
    }

    .wd-sm-60-force {
        width: 60px !important;
    }

    .wd-sm-60p-force {
        width: 60% !important;
    }

    .mx-wd-sm-60p-force {
        max-width: 60% !important;
    }

    .mn-wd-sm-60p-force {
        min-width: 60% !important;
    }

    .wd-sm-65 {
        width: 65px;
    }

    .wd-sm-65p {
        width: 65%;
    }

    .mx-wd-sm-65p {
        max-width: 65%;
    }

    .mn-wd-sm-65p {
        min-width: 65%;
    }

    .wd-sm-65-force {
        width: 65px !important;
    }

    .wd-sm-65p-force {
        width: 65% !important;
    }

    .mx-wd-sm-65p-force {
        max-width: 65% !important;
    }

    .mn-wd-sm-65p-force {
        min-width: 65% !important;
    }

    .wd-sm-70 {
        width: 70px;
    }

    .wd-sm-70p {
        width: 70%;
    }

    .mx-wd-sm-70p {
        max-width: 70%;
    }

    .mn-wd-sm-70p {
        min-width: 70%;
    }

    .wd-sm-70-force {
        width: 70px !important;
    }

    .wd-sm-70p-force {
        width: 70% !important;
    }

    .mx-wd-sm-70p-force {
        max-width: 70% !important;
    }

    .mn-wd-sm-70p-force {
        min-width: 70% !important;
    }

    .wd-sm-75 {
        width: 75px;
    }

    .wd-sm-75p {
        width: 75%;
    }

    .mx-wd-sm-75p {
        max-width: 75%;
    }

    .mn-wd-sm-75p {
        min-width: 75%;
    }

    .wd-sm-75-force {
        width: 75px !important;
    }

    .wd-sm-75p-force {
        width: 75% !important;
    }

    .mx-wd-sm-75p-force {
        max-width: 75% !important;
    }

    .mn-wd-sm-75p-force {
        min-width: 75% !important;
    }

    .wd-sm-80 {
        width: 80px;
    }

    .wd-sm-80p {
        width: 80%;
    }

    .mx-wd-sm-80p {
        max-width: 80%;
    }

    .mn-wd-sm-80p {
        min-width: 80%;
    }

    .wd-sm-80-force {
        width: 80px !important;
    }

    .wd-sm-80p-force {
        width: 80% !important;
    }

    .mx-wd-sm-80p-force {
        max-width: 80% !important;
    }

    .mn-wd-sm-80p-force {
        min-width: 80% !important;
    }

    .wd-sm-85 {
        width: 85px;
    }

    .wd-sm-85p {
        width: 85%;
    }

    .mx-wd-sm-85p {
        max-width: 85%;
    }

    .mn-wd-sm-85p {
        min-width: 85%;
    }

    .wd-sm-85-force {
        width: 85px !important;
    }

    .wd-sm-85p-force {
        width: 85% !important;
    }

    .mx-wd-sm-85p-force {
        max-width: 85% !important;
    }

    .mn-wd-sm-85p-force {
        min-width: 85% !important;
    }

    .wd-sm-90 {
        width: 90px;
    }

    .wd-sm-90p {
        width: 90%;
    }

    .mx-wd-sm-90p {
        max-width: 90%;
    }

    .mn-wd-sm-90p {
        min-width: 90%;
    }

    .wd-sm-90-force {
        width: 90px !important;
    }

    .wd-sm-90p-force {
        width: 90% !important;
    }

    .mx-wd-sm-90p-force {
        max-width: 90% !important;
    }

    .mn-wd-sm-90p-force {
        min-width: 90% !important;
    }

    .wd-sm-95 {
        width: 95px;
    }

    .wd-sm-95p {
        width: 95%;
    }

    .mx-wd-sm-95p {
        max-width: 95%;
    }

    .mn-wd-sm-95p {
        min-width: 95%;
    }

    .wd-sm-95-force {
        width: 95px !important;
    }

    .wd-sm-95p-force {
        width: 95% !important;
    }

    .mx-wd-sm-95p-force {
        max-width: 95% !important;
    }

    .mn-wd-sm-95p-force {
        min-width: 95% !important;
    }

    .wd-sm-100 {
        width: 100px;
    }

    .wd-sm-100p {
        width: 100%;
    }

    .mx-wd-sm-100p {
        max-width: 100%;
    }

    .mn-wd-sm-100p {
        min-width: 100%;
    }

    .wd-sm-100-force {
        width: 100px !important;
    }

    .wd-sm-100p-force {
        width: 100% !important;
    }

    .mx-wd-sm-100p-force {
        max-width: 100% !important;
    }

    .mn-wd-sm-100p-force {
        min-width: 100% !important;
    }

    .wd-sm-150 {
        width: 150px;
    }

    .wd-sm-150p {
        width: 150%;
    }

    .mx-wd-sm-150p {
        max-width: 150%;
    }

    .mn-wd-sm-150p {
        min-width: 150%;
    }

    .wd-sm-150-force {
        width: 150px !important;
    }

    .wd-sm-150p-force {
        width: 150% !important;
    }

    .mx-wd-sm-150p-force {
        max-width: 150% !important;
    }

    .mn-wd-sm-150p-force {
        min-width: 150% !important;
    }

    .wd-sm-200 {
        width: 200px;
    }

    .wd-sm-200p {
        width: 200%;
    }

    .mx-wd-sm-200p {
        max-width: 200%;
    }

    .mn-wd-sm-200p {
        min-width: 200%;
    }

    .wd-sm-200-force {
        width: 200px !important;
    }

    .wd-sm-200p-force {
        width: 200% !important;
    }

    .mx-wd-sm-200p-force {
        max-width: 200% !important;
    }

    .mn-wd-sm-200p-force {
        min-width: 200% !important;
    }

    .wd-sm-250 {
        width: 250px;
    }

    .wd-sm-250p {
        width: 250%;
    }

    .mx-wd-sm-250p {
        max-width: 250%;
    }

    .mn-wd-sm-250p {
        min-width: 250%;
    }

    .wd-sm-250-force {
        width: 250px !important;
    }

    .wd-sm-250p-force {
        width: 250% !important;
    }

    .mx-wd-sm-250p-force {
        max-width: 250% !important;
    }

    .mn-wd-sm-250p-force {
        min-width: 250% !important;
    }

    .wd-sm-300 {
        width: 300px;
    }

    .wd-sm-300p {
        width: 300%;
    }

    .mx-wd-sm-300p {
        max-width: 300%;
    }

    .mn-wd-sm-300p {
        min-width: 300%;
    }

    .wd-sm-300-force {
        width: 300px !important;
    }

    .wd-sm-300p-force {
        width: 300% !important;
    }

    .mx-wd-sm-300p-force {
        max-width: 300% !important;
    }

    .mn-wd-sm-300p-force {
        min-width: 300% !important;
    }

    .wd-sm-350 {
        width: 350px;
    }

    .wd-sm-350p {
        width: 350%;
    }

    .mx-wd-sm-350p {
        max-width: 350%;
    }

    .mn-wd-sm-350p {
        min-width: 350%;
    }

    .wd-sm-350-force {
        width: 350px !important;
    }

    .wd-sm-350p-force {
        width: 350% !important;
    }

    .mx-wd-sm-350p-force {
        max-width: 350% !important;
    }

    .mn-wd-sm-350p-force {
        min-width: 350% !important;
    }

    .wd-sm-400 {
        width: 400px;
    }

    .wd-sm-400p {
        width: 400%;
    }

    .mx-wd-sm-400p {
        max-width: 400%;
    }

    .mn-wd-sm-400p {
        min-width: 400%;
    }

    .wd-sm-400-force {
        width: 400px !important;
    }

    .wd-sm-400p-force {
        width: 400% !important;
    }

    .mx-wd-sm-400p-force {
        max-width: 400% !important;
    }

    .mn-wd-sm-400p-force {
        min-width: 400% !important;
    }

    .wd-sm-450 {
        width: 450px;
    }

    .wd-sm-450p {
        width: 450%;
    }

    .mx-wd-sm-450p {
        max-width: 450%;
    }

    .mn-wd-sm-450p {
        min-width: 450%;
    }

    .wd-sm-450-force {
        width: 450px !important;
    }

    .wd-sm-450p-force {
        width: 450% !important;
    }

    .mx-wd-sm-450p-force {
        max-width: 450% !important;
    }

    .mn-wd-sm-450p-force {
        min-width: 450% !important;
    }

    .wd-sm-500 {
        width: 500px;
    }

    .wd-sm-500p {
        width: 500%;
    }

    .mx-wd-sm-500p {
        max-width: 500%;
    }

    .mn-wd-sm-500p {
        min-width: 500%;
    }

    .wd-sm-500-force {
        width: 500px !important;
    }

    .wd-sm-500p-force {
        width: 500% !important;
    }

    .mx-wd-sm-500p-force {
        max-width: 500% !important;
    }

    .mn-wd-sm-500p-force {
        min-width: 500% !important;
    }

    .wd-sm-550 {
        width: 550px;
    }

    .wd-sm-550p {
        width: 550%;
    }

    .mx-wd-sm-550p {
        max-width: 550%;
    }

    .mn-wd-sm-550p {
        min-width: 550%;
    }

    .wd-sm-550-force {
        width: 550px !important;
    }

    .wd-sm-550p-force {
        width: 550% !important;
    }

    .mx-wd-sm-550p-force {
        max-width: 550% !important;
    }

    .mn-wd-sm-550p-force {
        min-width: 550% !important;
    }

    .wd-sm-600 {
        width: 600px;
    }

    .wd-sm-600p {
        width: 600%;
    }

    .mx-wd-sm-600p {
        max-width: 600%;
    }

    .mn-wd-sm-600p {
        min-width: 600%;
    }

    .wd-sm-600-force {
        width: 600px !important;
    }

    .wd-sm-600p-force {
        width: 600% !important;
    }

    .mx-wd-sm-600p-force {
        max-width: 600% !important;
    }

    .mn-wd-sm-600p-force {
        min-width: 600% !important;
    }

    .wd-sm-650 {
        width: 650px;
    }

    .wd-sm-650p {
        width: 650%;
    }

    .mx-wd-sm-650p {
        max-width: 650%;
    }

    .mn-wd-sm-650p {
        min-width: 650%;
    }

    .wd-sm-650-force {
        width: 650px !important;
    }

    .wd-sm-650p-force {
        width: 650% !important;
    }

    .mx-wd-sm-650p-force {
        max-width: 650% !important;
    }

    .mn-wd-sm-650p-force {
        min-width: 650% !important;
    }

    .wd-sm-700 {
        width: 700px;
    }

    .wd-sm-700p {
        width: 700%;
    }

    .mx-wd-sm-700p {
        max-width: 700%;
    }

    .mn-wd-sm-700p {
        min-width: 700%;
    }

    .wd-sm-700-force {
        width: 700px !important;
    }

    .wd-sm-700p-force {
        width: 700% !important;
    }

    .mx-wd-sm-700p-force {
        max-width: 700% !important;
    }

    .mn-wd-sm-700p-force {
        min-width: 700% !important;
    }

    .wd-sm-750 {
        width: 750px;
    }

    .wd-sm-750p {
        width: 750%;
    }

    .mx-wd-sm-750p {
        max-width: 750%;
    }

    .mn-wd-sm-750p {
        min-width: 750%;
    }

    .wd-sm-750-force {
        width: 750px !important;
    }

    .wd-sm-750p-force {
        width: 750% !important;
    }

    .mx-wd-sm-750p-force {
        max-width: 750% !important;
    }

    .mn-wd-sm-750p-force {
        min-width: 750% !important;
    }

    .wd-sm-800 {
        width: 800px;
    }

    .wd-sm-800p {
        width: 800%;
    }

    .mx-wd-sm-800p {
        max-width: 800%;
    }

    .mn-wd-sm-800p {
        min-width: 800%;
    }

    .wd-sm-800-force {
        width: 800px !important;
    }

    .wd-sm-800p-force {
        width: 800% !important;
    }

    .mx-wd-sm-800p-force {
        max-width: 800% !important;
    }

    .mn-wd-sm-800p-force {
        min-width: 800% !important;
    }

    .wd-sm-850 {
        width: 850px;
    }

    .wd-sm-850p {
        width: 850%;
    }

    .mx-wd-sm-850p {
        max-width: 850%;
    }

    .mn-wd-sm-850p {
        min-width: 850%;
    }

    .wd-sm-850-force {
        width: 850px !important;
    }

    .wd-sm-850p-force {
        width: 850% !important;
    }

    .mx-wd-sm-850p-force {
        max-width: 850% !important;
    }

    .mn-wd-sm-850p-force {
        min-width: 850% !important;
    }

    .wd-sm-900 {
        width: 900px;
    }

    .wd-sm-900p {
        width: 900%;
    }

    .mx-wd-sm-900p {
        max-width: 900%;
    }

    .mn-wd-sm-900p {
        min-width: 900%;
    }

    .wd-sm-900-force {
        width: 900px !important;
    }

    .wd-sm-900p-force {
        width: 900% !important;
    }

    .mx-wd-sm-900p-force {
        max-width: 900% !important;
    }

    .mn-wd-sm-900p-force {
        min-width: 900% !important;
    }

    .wd-sm-950 {
        width: 950px;
    }

    .wd-sm-950p {
        width: 950%;
    }

    .mx-wd-sm-950p {
        max-width: 950%;
    }

    .mn-wd-sm-950p {
        min-width: 950%;
    }

    .wd-sm-950-force {
        width: 950px !important;
    }

    .wd-sm-950p-force {
        width: 950% !important;
    }

    .mx-wd-sm-950p-force {
        max-width: 950% !important;
    }

    .mn-wd-sm-950p-force {
        min-width: 950% !important;
    }

    .wd-sm-1000 {
        width: 1000px;
    }

    .wd-sm-1000p {
        width: 1000%;
    }

    .mx-wd-sm-1000p {
        max-width: 1000%;
    }

    .mn-wd-sm-1000p {
        min-width: 1000%;
    }

    .wd-sm-1000-force {
        width: 1000px !important;
    }

    .wd-sm-1000p-force {
        width: 1000% !important;
    }

    .mx-wd-sm-1000p-force {
        max-width: 1000% !important;
    }

    .mn-wd-sm-1000p-force {
        min-width: 1000% !important;
    }

    .wd-sm-auto {
        width: auto;
    }

    .wd-sm-auto-force {
        width: auto !important;
    }
}

@media (min-width: 768px) {
    .wd-md-5 {
        width: 5px;
    }

    .wd-md-5p {
        width: 5%;
    }

    .mx-wd-md-5p {
        max-width: 5%;
    }

    .mn-wd-md-5p {
        min-width: 5%;
    }

    .wd-md-5-force {
        width: 5px !important;
    }

    .wd-md-5p-force {
        width: 5% !important;
    }

    .mx-wd-md-5p-force {
        max-width: 5% !important;
    }

    .mn-wd-md-5p-force {
        min-width: 5% !important;
    }

    .wd-md-10 {
        width: 10px;
    }

    .wd-md-10p {
        width: 10%;
    }

    .mx-wd-md-10p {
        max-width: 10%;
    }

    .mn-wd-md-10p {
        min-width: 10%;
    }

    .wd-md-10-force {
        width: 10px !important;
    }

    .wd-md-10p-force {
        width: 10% !important;
    }

    .mx-wd-md-10p-force {
        max-width: 10% !important;
    }

    .mn-wd-md-10p-force {
        min-width: 10% !important;
    }

    .wd-md-15 {
        width: 15px;
    }

    .wd-md-15p {
        width: 15%;
    }

    .mx-wd-md-15p {
        max-width: 15%;
    }

    .mn-wd-md-15p {
        min-width: 15%;
    }

    .wd-md-15-force {
        width: 15px !important;
    }

    .wd-md-15p-force {
        width: 15% !important;
    }

    .mx-wd-md-15p-force {
        max-width: 15% !important;
    }

    .mn-wd-md-15p-force {
        min-width: 15% !important;
    }

    .wd-md-20 {
        width: 20px;
    }

    .wd-md-20p {
        width: 20%;
    }

    .mx-wd-md-20p {
        max-width: 20%;
    }

    .mn-wd-md-20p {
        min-width: 20%;
    }

    .wd-md-20-force {
        width: 20px !important;
    }

    .wd-md-20p-force {
        width: 20% !important;
    }

    .mx-wd-md-20p-force {
        max-width: 20% !important;
    }

    .mn-wd-md-20p-force {
        min-width: 20% !important;
    }

    .wd-md-25 {
        width: 25px;
    }

    .wd-md-25p {
        width: 25%;
    }

    .mx-wd-md-25p {
        max-width: 25%;
    }

    .mn-wd-md-25p {
        min-width: 25%;
    }

    .wd-md-25-force {
        width: 25px !important;
    }

    .wd-md-25p-force {
        width: 25% !important;
    }

    .mx-wd-md-25p-force {
        max-width: 25% !important;
    }

    .mn-wd-md-25p-force {
        min-width: 25% !important;
    }

    .wd-md-30 {
        width: 30px;
    }

    .wd-md-30p {
        width: 30%;
    }

    .mx-wd-md-30p {
        max-width: 30%;
    }

    .mn-wd-md-30p {
        min-width: 30%;
    }

    .wd-md-30-force {
        width: 30px !important;
    }

    .wd-md-30p-force {
        width: 30% !important;
    }

    .mx-wd-md-30p-force {
        max-width: 30% !important;
    }

    .mn-wd-md-30p-force {
        min-width: 30% !important;
    }

    .wd-md-35 {
        width: 35px;
    }

    .wd-md-35p {
        width: 35%;
    }

    .mx-wd-md-35p {
        max-width: 35%;
    }

    .mn-wd-md-35p {
        min-width: 35%;
    }

    .wd-md-35-force {
        width: 35px !important;
    }

    .wd-md-35p-force {
        width: 35% !important;
    }

    .mx-wd-md-35p-force {
        max-width: 35% !important;
    }

    .mn-wd-md-35p-force {
        min-width: 35% !important;
    }

    .wd-md-40 {
        width: 40px;
    }

    .wd-md-40p {
        width: 40%;
    }

    .mx-wd-md-40p {
        max-width: 40%;
    }

    .mn-wd-md-40p {
        min-width: 40%;
    }

    .wd-md-40-force {
        width: 40px !important;
    }

    .wd-md-40p-force {
        width: 40% !important;
    }

    .mx-wd-md-40p-force {
        max-width: 40% !important;
    }

    .mn-wd-md-40p-force {
        min-width: 40% !important;
    }

    .wd-md-45 {
        width: 45px;
    }

    .wd-md-45p {
        width: 45%;
    }

    .mx-wd-md-45p {
        max-width: 45%;
    }

    .mn-wd-md-45p {
        min-width: 45%;
    }

    .wd-md-45-force {
        width: 45px !important;
    }

    .wd-md-45p-force {
        width: 45% !important;
    }

    .mx-wd-md-45p-force {
        max-width: 45% !important;
    }

    .mn-wd-md-45p-force {
        min-width: 45% !important;
    }

    .wd-md-50 {
        width: 50px;
    }

    .wd-md-50p {
        width: 50%;
    }

    .mx-wd-md-50p {
        max-width: 50%;
    }

    .mn-wd-md-50p {
        min-width: 50%;
    }

    .wd-md-50-force {
        width: 50px !important;
    }

    .wd-md-50p-force {
        width: 50% !important;
    }

    .mx-wd-md-50p-force {
        max-width: 50% !important;
    }

    .mn-wd-md-50p-force {
        min-width: 50% !important;
    }

    .wd-md-55 {
        width: 55px;
    }

    .wd-md-55p {
        width: 55%;
    }

    .mx-wd-md-55p {
        max-width: 55%;
    }

    .mn-wd-md-55p {
        min-width: 55%;
    }

    .wd-md-55-force {
        width: 55px !important;
    }

    .wd-md-55p-force {
        width: 55% !important;
    }

    .mx-wd-md-55p-force {
        max-width: 55% !important;
    }

    .mn-wd-md-55p-force {
        min-width: 55% !important;
    }

    .wd-md-60 {
        width: 60px;
    }

    .wd-md-60p {
        width: 60%;
    }

    .mx-wd-md-60p {
        max-width: 60%;
    }

    .mn-wd-md-60p {
        min-width: 60%;
    }

    .wd-md-60-force {
        width: 60px !important;
    }

    .wd-md-60p-force {
        width: 60% !important;
    }

    .mx-wd-md-60p-force {
        max-width: 60% !important;
    }

    .mn-wd-md-60p-force {
        min-width: 60% !important;
    }

    .wd-md-65 {
        width: 65px;
    }

    .wd-md-65p {
        width: 65%;
    }

    .mx-wd-md-65p {
        max-width: 65%;
    }

    .mn-wd-md-65p {
        min-width: 65%;
    }

    .wd-md-65-force {
        width: 65px !important;
    }

    .wd-md-65p-force {
        width: 65% !important;
    }

    .mx-wd-md-65p-force {
        max-width: 65% !important;
    }

    .mn-wd-md-65p-force {
        min-width: 65% !important;
    }

    .wd-md-70 {
        width: 70px;
    }

    .wd-md-70p {
        width: 70%;
    }

    .mx-wd-md-70p {
        max-width: 70%;
    }

    .mn-wd-md-70p {
        min-width: 70%;
    }

    .wd-md-70-force {
        width: 70px !important;
    }

    .wd-md-70p-force {
        width: 70% !important;
    }

    .mx-wd-md-70p-force {
        max-width: 70% !important;
    }

    .mn-wd-md-70p-force {
        min-width: 70% !important;
    }

    .wd-md-75 {
        width: 75px;
    }

    .wd-md-75p {
        width: 75%;
    }

    .mx-wd-md-75p {
        max-width: 75%;
    }

    .mn-wd-md-75p {
        min-width: 75%;
    }

    .wd-md-75-force {
        width: 75px !important;
    }

    .wd-md-75p-force {
        width: 75% !important;
    }

    .mx-wd-md-75p-force {
        max-width: 75% !important;
    }

    .mn-wd-md-75p-force {
        min-width: 75% !important;
    }

    .wd-md-80 {
        width: 80px;
    }

    .wd-md-80p {
        width: 80%;
    }

    .mx-wd-md-80p {
        max-width: 80%;
    }

    .mn-wd-md-80p {
        min-width: 80%;
    }

    .wd-md-80-force {
        width: 80px !important;
    }

    .wd-md-80p-force {
        width: 80% !important;
    }

    .mx-wd-md-80p-force {
        max-width: 80% !important;
    }

    .mn-wd-md-80p-force {
        min-width: 80% !important;
    }

    .wd-md-85 {
        width: 85px;
    }

    .wd-md-85p {
        width: 85%;
    }

    .mx-wd-md-85p {
        max-width: 85%;
    }

    .mn-wd-md-85p {
        min-width: 85%;
    }

    .wd-md-85-force {
        width: 85px !important;
    }

    .wd-md-85p-force {
        width: 85% !important;
    }

    .mx-wd-md-85p-force {
        max-width: 85% !important;
    }

    .mn-wd-md-85p-force {
        min-width: 85% !important;
    }

    .wd-md-90 {
        width: 90px;
    }

    .wd-md-90p {
        width: 90%;
    }

    .mx-wd-md-90p {
        max-width: 90%;
    }

    .mn-wd-md-90p {
        min-width: 90%;
    }

    .wd-md-90-force {
        width: 90px !important;
    }

    .wd-md-90p-force {
        width: 90% !important;
    }

    .mx-wd-md-90p-force {
        max-width: 90% !important;
    }

    .mn-wd-md-90p-force {
        min-width: 90% !important;
    }

    .wd-md-95 {
        width: 95px;
    }

    .wd-md-95p {
        width: 95%;
    }

    .mx-wd-md-95p {
        max-width: 95%;
    }

    .mn-wd-md-95p {
        min-width: 95%;
    }

    .wd-md-95-force {
        width: 95px !important;
    }

    .wd-md-95p-force {
        width: 95% !important;
    }

    .mx-wd-md-95p-force {
        max-width: 95% !important;
    }

    .mn-wd-md-95p-force {
        min-width: 95% !important;
    }

    .wd-md-100 {
        width: 100px;
    }

    .wd-md-100p {
        width: 100%;
    }

    .mx-wd-md-100p {
        max-width: 100%;
    }

    .mn-wd-md-100p {
        min-width: 100%;
    }

    .wd-md-100-force {
        width: 100px !important;
    }

    .wd-md-100p-force {
        width: 100% !important;
    }

    .mx-wd-md-100p-force {
        max-width: 100% !important;
    }

    .mn-wd-md-100p-force {
        min-width: 100% !important;
    }

    .wd-md-150 {
        width: 150px;
    }

    .wd-md-150p {
        width: 150%;
    }

    .mx-wd-md-150p {
        max-width: 150%;
    }

    .mn-wd-md-150p {
        min-width: 150%;
    }

    .wd-md-150-force {
        width: 150px !important;
    }

    .wd-md-150p-force {
        width: 150% !important;
    }

    .mx-wd-md-150p-force {
        max-width: 150% !important;
    }

    .mn-wd-md-150p-force {
        min-width: 150% !important;
    }

    .wd-md-200 {
        width: 200px;
    }

    .wd-md-200p {
        width: 200%;
    }

    .mx-wd-md-200p {
        max-width: 200%;
    }

    .mn-wd-md-200p {
        min-width: 200%;
    }

    .wd-md-200-force {
        width: 200px !important;
    }

    .wd-md-200p-force {
        width: 200% !important;
    }

    .mx-wd-md-200p-force {
        max-width: 200% !important;
    }

    .mn-wd-md-200p-force {
        min-width: 200% !important;
    }

    .wd-md-250 {
        width: 250px;
    }

    .wd-md-250p {
        width: 250%;
    }

    .mx-wd-md-250p {
        max-width: 250%;
    }

    .mn-wd-md-250p {
        min-width: 250%;
    }

    .wd-md-250-force {
        width: 250px !important;
    }

    .wd-md-250p-force {
        width: 250% !important;
    }

    .mx-wd-md-250p-force {
        max-width: 250% !important;
    }

    .mn-wd-md-250p-force {
        min-width: 250% !important;
    }

    .wd-md-300 {
        width: 300px;
    }

    .wd-md-300p {
        width: 300%;
    }

    .mx-wd-md-300p {
        max-width: 300%;
    }

    .mn-wd-md-300p {
        min-width: 300%;
    }

    .wd-md-300-force {
        width: 300px !important;
    }

    .wd-md-300p-force {
        width: 300% !important;
    }

    .mx-wd-md-300p-force {
        max-width: 300% !important;
    }

    .mn-wd-md-300p-force {
        min-width: 300% !important;
    }

    .wd-md-350 {
        width: 350px;
    }

    .wd-md-350p {
        width: 350%;
    }

    .mx-wd-md-350p {
        max-width: 350%;
    }

    .mn-wd-md-350p {
        min-width: 350%;
    }

    .wd-md-350-force {
        width: 350px !important;
    }

    .wd-md-350p-force {
        width: 350% !important;
    }

    .mx-wd-md-350p-force {
        max-width: 350% !important;
    }

    .mn-wd-md-350p-force {
        min-width: 350% !important;
    }

    .wd-md-400 {
        width: 400px;
    }

    .wd-md-400p {
        width: 400%;
    }

    .mx-wd-md-400p {
        max-width: 400%;
    }

    .mn-wd-md-400p {
        min-width: 400%;
    }

    .wd-md-400-force {
        width: 400px !important;
    }

    .wd-md-400p-force {
        width: 400% !important;
    }

    .mx-wd-md-400p-force {
        max-width: 400% !important;
    }

    .mn-wd-md-400p-force {
        min-width: 400% !important;
    }

    .wd-md-450 {
        width: 450px;
    }

    .wd-md-450p {
        width: 450%;
    }

    .mx-wd-md-450p {
        max-width: 450%;
    }

    .mn-wd-md-450p {
        min-width: 450%;
    }

    .wd-md-450-force {
        width: 450px !important;
    }

    .wd-md-450p-force {
        width: 450% !important;
    }

    .mx-wd-md-450p-force {
        max-width: 450% !important;
    }

    .mn-wd-md-450p-force {
        min-width: 450% !important;
    }

    .wd-md-500 {
        width: 500px;
    }

    .wd-md-500p {
        width: 500%;
    }

    .mx-wd-md-500p {
        max-width: 500%;
    }

    .mn-wd-md-500p {
        min-width: 500%;
    }

    .wd-md-500-force {
        width: 500px !important;
    }

    .wd-md-500p-force {
        width: 500% !important;
    }

    .mx-wd-md-500p-force {
        max-width: 500% !important;
    }

    .mn-wd-md-500p-force {
        min-width: 500% !important;
    }

    .wd-md-550 {
        width: 550px;
    }

    .wd-md-550p {
        width: 550%;
    }

    .mx-wd-md-550p {
        max-width: 550%;
    }

    .mn-wd-md-550p {
        min-width: 550%;
    }

    .wd-md-550-force {
        width: 550px !important;
    }

    .wd-md-550p-force {
        width: 550% !important;
    }

    .mx-wd-md-550p-force {
        max-width: 550% !important;
    }

    .mn-wd-md-550p-force {
        min-width: 550% !important;
    }

    .wd-md-600 {
        width: 600px;
    }

    .wd-md-600p {
        width: 600%;
    }

    .mx-wd-md-600p {
        max-width: 600%;
    }

    .mn-wd-md-600p {
        min-width: 600%;
    }

    .wd-md-600-force {
        width: 600px !important;
    }

    .wd-md-600p-force {
        width: 600% !important;
    }

    .mx-wd-md-600p-force {
        max-width: 600% !important;
    }

    .mn-wd-md-600p-force {
        min-width: 600% !important;
    }

    .wd-md-650 {
        width: 650px;
    }

    .wd-md-650p {
        width: 650%;
    }

    .mx-wd-md-650p {
        max-width: 650%;
    }

    .mn-wd-md-650p {
        min-width: 650%;
    }

    .wd-md-650-force {
        width: 650px !important;
    }

    .wd-md-650p-force {
        width: 650% !important;
    }

    .mx-wd-md-650p-force {
        max-width: 650% !important;
    }

    .mn-wd-md-650p-force {
        min-width: 650% !important;
    }

    .wd-md-700 {
        width: 700px;
    }

    .wd-md-700p {
        width: 700%;
    }

    .mx-wd-md-700p {
        max-width: 700%;
    }

    .mn-wd-md-700p {
        min-width: 700%;
    }

    .wd-md-700-force {
        width: 700px !important;
    }

    .wd-md-700p-force {
        width: 700% !important;
    }

    .mx-wd-md-700p-force {
        max-width: 700% !important;
    }

    .mn-wd-md-700p-force {
        min-width: 700% !important;
    }

    .wd-md-750 {
        width: 750px;
    }

    .wd-md-750p {
        width: 750%;
    }

    .mx-wd-md-750p {
        max-width: 750%;
    }

    .mn-wd-md-750p {
        min-width: 750%;
    }

    .wd-md-750-force {
        width: 750px !important;
    }

    .wd-md-750p-force {
        width: 750% !important;
    }

    .mx-wd-md-750p-force {
        max-width: 750% !important;
    }

    .mn-wd-md-750p-force {
        min-width: 750% !important;
    }

    .wd-md-800 {
        width: 800px;
    }

    .wd-md-800p {
        width: 800%;
    }

    .mx-wd-md-800p {
        max-width: 800%;
    }

    .mn-wd-md-800p {
        min-width: 800%;
    }

    .wd-md-800-force {
        width: 800px !important;
    }

    .wd-md-800p-force {
        width: 800% !important;
    }

    .mx-wd-md-800p-force {
        max-width: 800% !important;
    }

    .mn-wd-md-800p-force {
        min-width: 800% !important;
    }

    .wd-md-850 {
        width: 850px;
    }

    .wd-md-850p {
        width: 850%;
    }

    .mx-wd-md-850p {
        max-width: 850%;
    }

    .mn-wd-md-850p {
        min-width: 850%;
    }

    .wd-md-850-force {
        width: 850px !important;
    }

    .wd-md-850p-force {
        width: 850% !important;
    }

    .mx-wd-md-850p-force {
        max-width: 850% !important;
    }

    .mn-wd-md-850p-force {
        min-width: 850% !important;
    }

    .wd-md-900 {
        width: 900px;
    }

    .wd-md-900p {
        width: 900%;
    }

    .mx-wd-md-900p {
        max-width: 900%;
    }

    .mn-wd-md-900p {
        min-width: 900%;
    }

    .wd-md-900-force {
        width: 900px !important;
    }

    .wd-md-900p-force {
        width: 900% !important;
    }

    .mx-wd-md-900p-force {
        max-width: 900% !important;
    }

    .mn-wd-md-900p-force {
        min-width: 900% !important;
    }

    .wd-md-950 {
        width: 950px;
    }

    .wd-md-950p {
        width: 950%;
    }

    .mx-wd-md-950p {
        max-width: 950%;
    }

    .mn-wd-md-950p {
        min-width: 950%;
    }

    .wd-md-950-force {
        width: 950px !important;
    }

    .wd-md-950p-force {
        width: 950% !important;
    }

    .mx-wd-md-950p-force {
        max-width: 950% !important;
    }

    .mn-wd-md-950p-force {
        min-width: 950% !important;
    }

    .wd-md-1000 {
        width: 1000px;
    }

    .wd-md-1000p {
        width: 1000%;
    }

    .mx-wd-md-1000p {
        max-width: 1000%;
    }

    .mn-wd-md-1000p {
        min-width: 1000%;
    }

    .wd-md-1000-force {
        width: 1000px !important;
    }

    .wd-md-1000p-force {
        width: 1000% !important;
    }

    .mx-wd-md-1000p-force {
        max-width: 1000% !important;
    }

    .mn-wd-md-1000p-force {
        min-width: 1000% !important;
    }

    .wd-md-auto {
        width: auto;
    }

    .wd-md-auto-force {
        width: auto !important;
    }
}

@media (min-width: 992px) {
    .wd-lg-5 {
        width: 5px;
    }

    .wd-lg-5p {
        width: 5%;
    }

    .mx-wd-lg-5p {
        max-width: 5%;
    }

    .mn-wd-lg-5p {
        min-width: 5%;
    }

    .wd-lg-5-force {
        width: 5px !important;
    }

    .wd-lg-5p-force {
        width: 5% !important;
    }

    .mx-wd-lg-5p-force {
        max-width: 5% !important;
    }

    .mn-wd-lg-5p-force {
        min-width: 5% !important;
    }

    .wd-lg-10 {
        width: 10px;
    }

    .wd-lg-10p {
        width: 10%;
    }

    .mx-wd-lg-10p {
        max-width: 10%;
    }

    .mn-wd-lg-10p {
        min-width: 10%;
    }

    .wd-lg-10-force {
        width: 10px !important;
    }

    .wd-lg-10p-force {
        width: 10% !important;
    }

    .mx-wd-lg-10p-force {
        max-width: 10% !important;
    }

    .mn-wd-lg-10p-force {
        min-width: 10% !important;
    }

    .wd-lg-15 {
        width: 15px;
    }

    .wd-lg-15p {
        width: 15%;
    }

    .mx-wd-lg-15p {
        max-width: 15%;
    }

    .mn-wd-lg-15p {
        min-width: 15%;
    }

    .wd-lg-15-force {
        width: 15px !important;
    }

    .wd-lg-15p-force {
        width: 15% !important;
    }

    .mx-wd-lg-15p-force {
        max-width: 15% !important;
    }

    .mn-wd-lg-15p-force {
        min-width: 15% !important;
    }

    .wd-lg-20 {
        width: 20px;
    }

    .wd-lg-20p {
        width: 20%;
    }

    .mx-wd-lg-20p {
        max-width: 20%;
    }

    .mn-wd-lg-20p {
        min-width: 20%;
    }

    .wd-lg-20-force {
        width: 20px !important;
    }

    .wd-lg-20p-force {
        width: 20% !important;
    }

    .mx-wd-lg-20p-force {
        max-width: 20% !important;
    }

    .mn-wd-lg-20p-force {
        min-width: 20% !important;
    }

    .wd-lg-25 {
        width: 25px;
    }

    .wd-lg-25p {
        width: 25%;
    }

    .mx-wd-lg-25p {
        max-width: 25%;
    }

    .mn-wd-lg-25p {
        min-width: 25%;
    }

    .wd-lg-25-force {
        width: 25px !important;
    }

    .wd-lg-25p-force {
        width: 25% !important;
    }

    .mx-wd-lg-25p-force {
        max-width: 25% !important;
    }

    .mn-wd-lg-25p-force {
        min-width: 25% !important;
    }

    .wd-lg-30 {
        width: 30px;
    }

    .wd-lg-30p {
        width: 30%;
    }

    .mx-wd-lg-30p {
        max-width: 30%;
    }

    .mn-wd-lg-30p {
        min-width: 30%;
    }

    .wd-lg-30-force {
        width: 30px !important;
    }

    .wd-lg-30p-force {
        width: 30% !important;
    }

    .mx-wd-lg-30p-force {
        max-width: 30% !important;
    }

    .mn-wd-lg-30p-force {
        min-width: 30% !important;
    }

    .wd-lg-35 {
        width: 35px;
    }

    .wd-lg-35p {
        width: 35%;
    }

    .mx-wd-lg-35p {
        max-width: 35%;
    }

    .mn-wd-lg-35p {
        min-width: 35%;
    }

    .wd-lg-35-force {
        width: 35px !important;
    }

    .wd-lg-35p-force {
        width: 35% !important;
    }

    .mx-wd-lg-35p-force {
        max-width: 35% !important;
    }

    .mn-wd-lg-35p-force {
        min-width: 35% !important;
    }

    .wd-lg-40 {
        width: 40px;
    }

    .wd-lg-40p {
        width: 40%;
    }

    .mx-wd-lg-40p {
        max-width: 40%;
    }

    .mn-wd-lg-40p {
        min-width: 40%;
    }

    .wd-lg-40-force {
        width: 40px !important;
    }

    .wd-lg-40p-force {
        width: 40% !important;
    }

    .mx-wd-lg-40p-force {
        max-width: 40% !important;
    }

    .mn-wd-lg-40p-force {
        min-width: 40% !important;
    }

    .wd-lg-45 {
        width: 45px;
    }

    .wd-lg-45p {
        width: 45%;
    }

    .mx-wd-lg-45p {
        max-width: 45%;
    }

    .mn-wd-lg-45p {
        min-width: 45%;
    }

    .wd-lg-45-force {
        width: 45px !important;
    }

    .wd-lg-45p-force {
        width: 45% !important;
    }

    .mx-wd-lg-45p-force {
        max-width: 45% !important;
    }

    .mn-wd-lg-45p-force {
        min-width: 45% !important;
    }

    .wd-lg-50 {
        width: 50px;
    }

    .wd-lg-50p {
        width: 50%;
    }

    .mx-wd-lg-50p {
        max-width: 50%;
    }

    .mn-wd-lg-50p {
        min-width: 50%;
    }

    .wd-lg-50-force {
        width: 50px !important;
    }

    .wd-lg-50p-force {
        width: 50% !important;
    }

    .mx-wd-lg-50p-force {
        max-width: 50% !important;
    }

    .mn-wd-lg-50p-force {
        min-width: 50% !important;
    }

    .wd-lg-55 {
        width: 55px;
    }

    .wd-lg-55p {
        width: 55%;
    }

    .mx-wd-lg-55p {
        max-width: 55%;
    }

    .mn-wd-lg-55p {
        min-width: 55%;
    }

    .wd-lg-55-force {
        width: 55px !important;
    }

    .wd-lg-55p-force {
        width: 55% !important;
    }

    .mx-wd-lg-55p-force {
        max-width: 55% !important;
    }

    .mn-wd-lg-55p-force {
        min-width: 55% !important;
    }

    .wd-lg-60 {
        width: 60px;
    }

    .wd-lg-60p {
        width: 60%;
    }

    .mx-wd-lg-60p {
        max-width: 60%;
    }

    .mn-wd-lg-60p {
        min-width: 60%;
    }

    .wd-lg-60-force {
        width: 60px !important;
    }

    .wd-lg-60p-force {
        width: 60% !important;
    }

    .mx-wd-lg-60p-force {
        max-width: 60% !important;
    }

    .mn-wd-lg-60p-force {
        min-width: 60% !important;
    }

    .wd-lg-65 {
        width: 65px;
    }

    .wd-lg-65p {
        width: 65%;
    }

    .mx-wd-lg-65p {
        max-width: 65%;
    }

    .mn-wd-lg-65p {
        min-width: 65%;
    }

    .wd-lg-65-force {
        width: 65px !important;
    }

    .wd-lg-65p-force {
        width: 65% !important;
    }

    .mx-wd-lg-65p-force {
        max-width: 65% !important;
    }

    .mn-wd-lg-65p-force {
        min-width: 65% !important;
    }

    .wd-lg-70 {
        width: 70px;
    }

    .wd-lg-70p {
        width: 70%;
    }

    .mx-wd-lg-70p {
        max-width: 70%;
    }

    .mn-wd-lg-70p {
        min-width: 70%;
    }

    .wd-lg-70-force {
        width: 70px !important;
    }

    .wd-lg-70p-force {
        width: 70% !important;
    }

    .mx-wd-lg-70p-force {
        max-width: 70% !important;
    }

    .mn-wd-lg-70p-force {
        min-width: 70% !important;
    }

    .wd-lg-75 {
        width: 75px;
    }

    .wd-lg-75p {
        width: 75%;
    }

    .mx-wd-lg-75p {
        max-width: 75%;
    }

    .mn-wd-lg-75p {
        min-width: 75%;
    }

    .wd-lg-75-force {
        width: 75px !important;
    }

    .wd-lg-75p-force {
        width: 75% !important;
    }

    .mx-wd-lg-75p-force {
        max-width: 75% !important;
    }

    .mn-wd-lg-75p-force {
        min-width: 75% !important;
    }

    .wd-lg-80 {
        width: 80px;
    }

    .wd-lg-80p {
        width: 80%;
    }

    .mx-wd-lg-80p {
        max-width: 80%;
    }

    .mn-wd-lg-80p {
        min-width: 80%;
    }

    .wd-lg-80-force {
        width: 80px !important;
    }

    .wd-lg-80p-force {
        width: 80% !important;
    }

    .mx-wd-lg-80p-force {
        max-width: 80% !important;
    }

    .mn-wd-lg-80p-force {
        min-width: 80% !important;
    }

    .wd-lg-85 {
        width: 85px;
    }

    .wd-lg-85p {
        width: 85%;
    }

    .mx-wd-lg-85p {
        max-width: 85%;
    }

    .mn-wd-lg-85p {
        min-width: 85%;
    }

    .wd-lg-85-force {
        width: 85px !important;
    }

    .wd-lg-85p-force {
        width: 85% !important;
    }

    .mx-wd-lg-85p-force {
        max-width: 85% !important;
    }

    .mn-wd-lg-85p-force {
        min-width: 85% !important;
    }

    .wd-lg-90 {
        width: 90px;
    }

    .wd-lg-90p {
        width: 90%;
    }

    .mx-wd-lg-90p {
        max-width: 90%;
    }

    .mn-wd-lg-90p {
        min-width: 90%;
    }

    .wd-lg-90-force {
        width: 90px !important;
    }

    .wd-lg-90p-force {
        width: 90% !important;
    }

    .mx-wd-lg-90p-force {
        max-width: 90% !important;
    }

    .mn-wd-lg-90p-force {
        min-width: 90% !important;
    }

    .wd-lg-95 {
        width: 95px;
    }

    .wd-lg-95p {
        width: 95%;
    }

    .mx-wd-lg-95p {
        max-width: 95%;
    }

    .mn-wd-lg-95p {
        min-width: 95%;
    }

    .wd-lg-95-force {
        width: 95px !important;
    }

    .wd-lg-95p-force {
        width: 95% !important;
    }

    .mx-wd-lg-95p-force {
        max-width: 95% !important;
    }

    .mn-wd-lg-95p-force {
        min-width: 95% !important;
    }

    .wd-lg-100 {
        width: 100px;
    }

    .wd-lg-100p {
        width: 100%;
    }

    .mx-wd-lg-100p {
        max-width: 100%;
    }

    .mn-wd-lg-100p {
        min-width: 100%;
    }

    .wd-lg-100-force {
        width: 100px !important;
    }

    .wd-lg-100p-force {
        width: 100% !important;
    }

    .mx-wd-lg-100p-force {
        max-width: 100% !important;
    }

    .mn-wd-lg-100p-force {
        min-width: 100% !important;
    }

    .wd-lg-150 {
        width: 150px;
    }

    .wd-lg-150p {
        width: 150%;
    }

    .mx-wd-lg-150p {
        max-width: 150%;
    }

    .mn-wd-lg-150p {
        min-width: 150%;
    }

    .wd-lg-150-force {
        width: 150px !important;
    }

    .wd-lg-150p-force {
        width: 150% !important;
    }

    .mx-wd-lg-150p-force {
        max-width: 150% !important;
    }

    .mn-wd-lg-150p-force {
        min-width: 150% !important;
    }

    .wd-lg-200 {
        width: 200px;
    }

    .wd-lg-200p {
        width: 200%;
    }

    .mx-wd-lg-200p {
        max-width: 200%;
    }

    .mn-wd-lg-200p {
        min-width: 200%;
    }

    .wd-lg-200-force {
        width: 200px !important;
    }

    .wd-lg-200p-force {
        width: 200% !important;
    }

    .mx-wd-lg-200p-force {
        max-width: 200% !important;
    }

    .mn-wd-lg-200p-force {
        min-width: 200% !important;
    }

    .wd-lg-250 {
        width: 250px;
    }

    .wd-lg-250p {
        width: 250%;
    }

    .mx-wd-lg-250p {
        max-width: 250%;
    }

    .mn-wd-lg-250p {
        min-width: 250%;
    }

    .wd-lg-250-force {
        width: 250px !important;
    }

    .wd-lg-250p-force {
        width: 250% !important;
    }

    .mx-wd-lg-250p-force {
        max-width: 250% !important;
    }

    .mn-wd-lg-250p-force {
        min-width: 250% !important;
    }

    .wd-lg-300 {
        width: 300px;
    }

    .wd-lg-300p {
        width: 300%;
    }

    .mx-wd-lg-300p {
        max-width: 300%;
    }

    .mn-wd-lg-300p {
        min-width: 300%;
    }

    .wd-lg-300-force {
        width: 300px !important;
    }

    .wd-lg-300p-force {
        width: 300% !important;
    }

    .mx-wd-lg-300p-force {
        max-width: 300% !important;
    }

    .mn-wd-lg-300p-force {
        min-width: 300% !important;
    }

    .wd-lg-350 {
        width: 350px;
    }

    .wd-lg-350p {
        width: 350%;
    }

    .mx-wd-lg-350p {
        max-width: 350%;
    }

    .mn-wd-lg-350p {
        min-width: 350%;
    }

    .wd-lg-350-force {
        width: 350px !important;
    }

    .wd-lg-350p-force {
        width: 350% !important;
    }

    .mx-wd-lg-350p-force {
        max-width: 350% !important;
    }

    .mn-wd-lg-350p-force {
        min-width: 350% !important;
    }

    .wd-lg-400 {
        width: 400px;
    }

    .wd-lg-400p {
        width: 400%;
    }

    .mx-wd-lg-400p {
        max-width: 400%;
    }

    .mn-wd-lg-400p {
        min-width: 400%;
    }

    .wd-lg-400-force {
        width: 400px !important;
    }

    .wd-lg-400p-force {
        width: 400% !important;
    }

    .mx-wd-lg-400p-force {
        max-width: 400% !important;
    }

    .mn-wd-lg-400p-force {
        min-width: 400% !important;
    }

    .wd-lg-450 {
        width: 450px;
    }

    .wd-lg-450p {
        width: 450%;
    }

    .mx-wd-lg-450p {
        max-width: 450%;
    }

    .mn-wd-lg-450p {
        min-width: 450%;
    }

    .wd-lg-450-force {
        width: 450px !important;
    }

    .wd-lg-450p-force {
        width: 450% !important;
    }

    .mx-wd-lg-450p-force {
        max-width: 450% !important;
    }

    .mn-wd-lg-450p-force {
        min-width: 450% !important;
    }

    .wd-lg-500 {
        width: 500px;
    }

    .wd-lg-500p {
        width: 500%;
    }

    .mx-wd-lg-500p {
        max-width: 500%;
    }

    .mn-wd-lg-500p {
        min-width: 500%;
    }

    .wd-lg-500-force {
        width: 500px !important;
    }

    .wd-lg-500p-force {
        width: 500% !important;
    }

    .mx-wd-lg-500p-force {
        max-width: 500% !important;
    }

    .mn-wd-lg-500p-force {
        min-width: 500% !important;
    }

    .wd-lg-550 {
        width: 550px;
    }

    .wd-lg-550p {
        width: 550%;
    }

    .mx-wd-lg-550p {
        max-width: 550%;
    }

    .mn-wd-lg-550p {
        min-width: 550%;
    }

    .wd-lg-550-force {
        width: 550px !important;
    }

    .wd-lg-550p-force {
        width: 550% !important;
    }

    .mx-wd-lg-550p-force {
        max-width: 550% !important;
    }

    .mn-wd-lg-550p-force {
        min-width: 550% !important;
    }

    .wd-lg-600 {
        width: 600px;
    }

    .wd-lg-600p {
        width: 600%;
    }

    .mx-wd-lg-600p {
        max-width: 600%;
    }

    .mn-wd-lg-600p {
        min-width: 600%;
    }

    .wd-lg-600-force {
        width: 600px !important;
    }

    .wd-lg-600p-force {
        width: 600% !important;
    }

    .mx-wd-lg-600p-force {
        max-width: 600% !important;
    }

    .mn-wd-lg-600p-force {
        min-width: 600% !important;
    }

    .wd-lg-650 {
        width: 650px;
    }

    .wd-lg-650p {
        width: 650%;
    }

    .mx-wd-lg-650p {
        max-width: 650%;
    }

    .mn-wd-lg-650p {
        min-width: 650%;
    }

    .wd-lg-650-force {
        width: 650px !important;
    }

    .wd-lg-650p-force {
        width: 650% !important;
    }

    .mx-wd-lg-650p-force {
        max-width: 650% !important;
    }

    .mn-wd-lg-650p-force {
        min-width: 650% !important;
    }

    .wd-lg-700 {
        width: 700px;
    }

    .wd-lg-700p {
        width: 700%;
    }

    .mx-wd-lg-700p {
        max-width: 700%;
    }

    .mn-wd-lg-700p {
        min-width: 700%;
    }

    .wd-lg-700-force {
        width: 700px !important;
    }

    .wd-lg-700p-force {
        width: 700% !important;
    }

    .mx-wd-lg-700p-force {
        max-width: 700% !important;
    }

    .mn-wd-lg-700p-force {
        min-width: 700% !important;
    }

    .wd-lg-750 {
        width: 750px;
    }

    .wd-lg-750p {
        width: 750%;
    }

    .mx-wd-lg-750p {
        max-width: 750%;
    }

    .mn-wd-lg-750p {
        min-width: 750%;
    }

    .wd-lg-750-force {
        width: 750px !important;
    }

    .wd-lg-750p-force {
        width: 750% !important;
    }

    .mx-wd-lg-750p-force {
        max-width: 750% !important;
    }

    .mn-wd-lg-750p-force {
        min-width: 750% !important;
    }

    .wd-lg-800 {
        width: 800px;
    }

    .wd-lg-800p {
        width: 800%;
    }

    .mx-wd-lg-800p {
        max-width: 800%;
    }

    .mn-wd-lg-800p {
        min-width: 800%;
    }

    .wd-lg-800-force {
        width: 800px !important;
    }

    .wd-lg-800p-force {
        width: 800% !important;
    }

    .mx-wd-lg-800p-force {
        max-width: 800% !important;
    }

    .mn-wd-lg-800p-force {
        min-width: 800% !important;
    }

    .wd-lg-850 {
        width: 850px;
    }

    .wd-lg-850p {
        width: 850%;
    }

    .mx-wd-lg-850p {
        max-width: 850%;
    }

    .mn-wd-lg-850p {
        min-width: 850%;
    }

    .wd-lg-850-force {
        width: 850px !important;
    }

    .wd-lg-850p-force {
        width: 850% !important;
    }

    .mx-wd-lg-850p-force {
        max-width: 850% !important;
    }

    .mn-wd-lg-850p-force {
        min-width: 850% !important;
    }

    .wd-lg-900 {
        width: 900px;
    }

    .wd-lg-900p {
        width: 900%;
    }

    .mx-wd-lg-900p {
        max-width: 900%;
    }

    .mn-wd-lg-900p {
        min-width: 900%;
    }

    .wd-lg-900-force {
        width: 900px !important;
    }

    .wd-lg-900p-force {
        width: 900% !important;
    }

    .mx-wd-lg-900p-force {
        max-width: 900% !important;
    }

    .mn-wd-lg-900p-force {
        min-width: 900% !important;
    }

    .wd-lg-950 {
        width: 950px;
    }

    .wd-lg-950p {
        width: 950%;
    }

    .mx-wd-lg-950p {
        max-width: 950%;
    }

    .mn-wd-lg-950p {
        min-width: 950%;
    }

    .wd-lg-950-force {
        width: 950px !important;
    }

    .wd-lg-950p-force {
        width: 950% !important;
    }

    .mx-wd-lg-950p-force {
        max-width: 950% !important;
    }

    .mn-wd-lg-950p-force {
        min-width: 950% !important;
    }

    .wd-lg-1000 {
        width: 1000px;
    }

    .wd-lg-1000p {
        width: 1000%;
    }

    .mx-wd-lg-1000p {
        max-width: 1000%;
    }

    .mn-wd-lg-1000p {
        min-width: 1000%;
    }

    .wd-lg-1000-force {
        width: 1000px !important;
    }

    .wd-lg-1000p-force {
        width: 1000% !important;
    }

    .mx-wd-lg-1000p-force {
        max-width: 1000% !important;
    }

    .mn-wd-lg-1000p-force {
        min-width: 1000% !important;
    }

    .wd-lg-auto {
        width: auto;
    }

    .wd-lg-auto-force {
        width: auto !important;
    }
}

@media (min-width: 1200px) {
    .wd-xl-5 {
        width: 5px;
    }

    .wd-xl-5p {
        width: 5%;
    }

    .mx-wd-xl-5p {
        max-width: 5%;
    }

    .mn-wd-xl-5p {
        min-width: 5%;
    }

    .wd-xl-5-force {
        width: 5px !important;
    }

    .wd-xl-5p-force {
        width: 5% !important;
    }

    .mx-wd-xl-5p-force {
        max-width: 5% !important;
    }

    .mn-wd-xl-5p-force {
        min-width: 5% !important;
    }

    .wd-xl-10 {
        width: 10px;
    }

    .wd-xl-10p {
        width: 10%;
    }

    .mx-wd-xl-10p {
        max-width: 10%;
    }

    .mn-wd-xl-10p {
        min-width: 10%;
    }

    .wd-xl-10-force {
        width: 10px !important;
    }

    .wd-xl-10p-force {
        width: 10% !important;
    }

    .mx-wd-xl-10p-force {
        max-width: 10% !important;
    }

    .mn-wd-xl-10p-force {
        min-width: 10% !important;
    }

    .wd-xl-15 {
        width: 15px;
    }

    .wd-xl-15p {
        width: 15%;
    }

    .mx-wd-xl-15p {
        max-width: 15%;
    }

    .mn-wd-xl-15p {
        min-width: 15%;
    }

    .wd-xl-15-force {
        width: 15px !important;
    }

    .wd-xl-15p-force {
        width: 15% !important;
    }

    .mx-wd-xl-15p-force {
        max-width: 15% !important;
    }

    .mn-wd-xl-15p-force {
        min-width: 15% !important;
    }

    .wd-xl-20 {
        width: 20px;
    }

    .wd-xl-20p {
        width: 20%;
    }

    .mx-wd-xl-20p {
        max-width: 20%;
    }

    .mn-wd-xl-20p {
        min-width: 20%;
    }

    .wd-xl-20-force {
        width: 20px !important;
    }

    .wd-xl-20p-force {
        width: 20% !important;
    }

    .mx-wd-xl-20p-force {
        max-width: 20% !important;
    }

    .mn-wd-xl-20p-force {
        min-width: 20% !important;
    }

    .wd-xl-25 {
        width: 25px;
    }

    .wd-xl-25p {
        width: 25%;
    }

    .mx-wd-xl-25p {
        max-width: 25%;
    }

    .mn-wd-xl-25p {
        min-width: 25%;
    }

    .wd-xl-25-force {
        width: 25px !important;
    }

    .wd-xl-25p-force {
        width: 25% !important;
    }

    .mx-wd-xl-25p-force {
        max-width: 25% !important;
    }

    .mn-wd-xl-25p-force {
        min-width: 25% !important;
    }

    .wd-xl-30 {
        width: 30px;
    }

    .wd-xl-30p {
        width: 30%;
    }

    .mx-wd-xl-30p {
        max-width: 30%;
    }

    .mn-wd-xl-30p {
        min-width: 30%;
    }

    .wd-xl-30-force {
        width: 30px !important;
    }

    .wd-xl-30p-force {
        width: 30% !important;
    }

    .mx-wd-xl-30p-force {
        max-width: 30% !important;
    }

    .mn-wd-xl-30p-force {
        min-width: 30% !important;
    }

    .wd-xl-35 {
        width: 35px;
    }

    .wd-xl-35p {
        width: 35%;
    }

    .mx-wd-xl-35p {
        max-width: 35%;
    }

    .mn-wd-xl-35p {
        min-width: 35%;
    }

    .wd-xl-35-force {
        width: 35px !important;
    }

    .wd-xl-35p-force {
        width: 35% !important;
    }

    .mx-wd-xl-35p-force {
        max-width: 35% !important;
    }

    .mn-wd-xl-35p-force {
        min-width: 35% !important;
    }

    .wd-xl-40 {
        width: 40px;
    }

    .wd-xl-40p {
        width: 40%;
    }

    .mx-wd-xl-40p {
        max-width: 40%;
    }

    .mn-wd-xl-40p {
        min-width: 40%;
    }

    .wd-xl-40-force {
        width: 40px !important;
    }

    .wd-xl-40p-force {
        width: 40% !important;
    }

    .mx-wd-xl-40p-force {
        max-width: 40% !important;
    }

    .mn-wd-xl-40p-force {
        min-width: 40% !important;
    }

    .wd-xl-45 {
        width: 45px;
    }

    .wd-xl-45p {
        width: 45%;
    }

    .mx-wd-xl-45p {
        max-width: 45%;
    }

    .mn-wd-xl-45p {
        min-width: 45%;
    }

    .wd-xl-45-force {
        width: 45px !important;
    }

    .wd-xl-45p-force {
        width: 45% !important;
    }

    .mx-wd-xl-45p-force {
        max-width: 45% !important;
    }

    .mn-wd-xl-45p-force {
        min-width: 45% !important;
    }

    .wd-xl-50 {
        width: 50px;
    }

    .wd-xl-50p {
        width: 50%;
    }

    .mx-wd-xl-50p {
        max-width: 50%;
    }

    .mn-wd-xl-50p {
        min-width: 50%;
    }

    .wd-xl-50-force {
        width: 50px !important;
    }

    .wd-xl-50p-force {
        width: 50% !important;
    }

    .mx-wd-xl-50p-force {
        max-width: 50% !important;
    }

    .mn-wd-xl-50p-force {
        min-width: 50% !important;
    }

    .wd-xl-55 {
        width: 55px;
    }

    .wd-xl-55p {
        width: 55%;
    }

    .mx-wd-xl-55p {
        max-width: 55%;
    }

    .mn-wd-xl-55p {
        min-width: 55%;
    }

    .wd-xl-55-force {
        width: 55px !important;
    }

    .wd-xl-55p-force {
        width: 55% !important;
    }

    .mx-wd-xl-55p-force {
        max-width: 55% !important;
    }

    .mn-wd-xl-55p-force {
        min-width: 55% !important;
    }

    .wd-xl-60 {
        width: 60px;
    }

    .wd-xl-60p {
        width: 60%;
    }

    .mx-wd-xl-60p {
        max-width: 60%;
    }

    .mn-wd-xl-60p {
        min-width: 60%;
    }

    .wd-xl-60-force {
        width: 60px !important;
    }

    .wd-xl-60p-force {
        width: 60% !important;
    }

    .mx-wd-xl-60p-force {
        max-width: 60% !important;
    }

    .mn-wd-xl-60p-force {
        min-width: 60% !important;
    }

    .wd-xl-65 {
        width: 65px;
    }

    .wd-xl-65p {
        width: 65%;
    }

    .mx-wd-xl-65p {
        max-width: 65%;
    }

    .mn-wd-xl-65p {
        min-width: 65%;
    }

    .wd-xl-65-force {
        width: 65px !important;
    }

    .wd-xl-65p-force {
        width: 65% !important;
    }

    .mx-wd-xl-65p-force {
        max-width: 65% !important;
    }

    .mn-wd-xl-65p-force {
        min-width: 65% !important;
    }

    .wd-xl-70 {
        width: 70px;
    }

    .wd-xl-70p {
        width: 70%;
    }

    .mx-wd-xl-70p {
        max-width: 70%;
    }

    .mn-wd-xl-70p {
        min-width: 70%;
    }

    .wd-xl-70-force {
        width: 70px !important;
    }

    .wd-xl-70p-force {
        width: 70% !important;
    }

    .mx-wd-xl-70p-force {
        max-width: 70% !important;
    }

    .mn-wd-xl-70p-force {
        min-width: 70% !important;
    }

    .wd-xl-75 {
        width: 75px;
    }

    .wd-xl-75p {
        width: 75%;
    }

    .mx-wd-xl-75p {
        max-width: 75%;
    }

    .mn-wd-xl-75p {
        min-width: 75%;
    }

    .wd-xl-75-force {
        width: 75px !important;
    }

    .wd-xl-75p-force {
        width: 75% !important;
    }

    .mx-wd-xl-75p-force {
        max-width: 75% !important;
    }

    .mn-wd-xl-75p-force {
        min-width: 75% !important;
    }

    .wd-xl-80 {
        width: 80px;
    }

    .wd-xl-80p {
        width: 80%;
    }

    .mx-wd-xl-80p {
        max-width: 80%;
    }

    .mn-wd-xl-80p {
        min-width: 80%;
    }

    .wd-xl-80-force {
        width: 80px !important;
    }

    .wd-xl-80p-force {
        width: 80% !important;
    }

    .mx-wd-xl-80p-force {
        max-width: 80% !important;
    }

    .mn-wd-xl-80p-force {
        min-width: 80% !important;
    }

    .wd-xl-85 {
        width: 85px;
    }

    .wd-xl-85p {
        width: 85%;
    }

    .mx-wd-xl-85p {
        max-width: 85%;
    }

    .mn-wd-xl-85p {
        min-width: 85%;
    }

    .wd-xl-85-force {
        width: 85px !important;
    }

    .wd-xl-85p-force {
        width: 85% !important;
    }

    .mx-wd-xl-85p-force {
        max-width: 85% !important;
    }

    .mn-wd-xl-85p-force {
        min-width: 85% !important;
    }

    .wd-xl-90 {
        width: 90px;
    }

    .wd-xl-90p {
        width: 90%;
    }

    .mx-wd-xl-90p {
        max-width: 90%;
    }

    .mn-wd-xl-90p {
        min-width: 90%;
    }

    .wd-xl-90-force {
        width: 90px !important;
    }

    .wd-xl-90p-force {
        width: 90% !important;
    }

    .mx-wd-xl-90p-force {
        max-width: 90% !important;
    }

    .mn-wd-xl-90p-force {
        min-width: 90% !important;
    }

    .wd-xl-95 {
        width: 95px;
    }

    .wd-xl-95p {
        width: 95%;
    }

    .mx-wd-xl-95p {
        max-width: 95%;
    }

    .mn-wd-xl-95p {
        min-width: 95%;
    }

    .wd-xl-95-force {
        width: 95px !important;
    }

    .wd-xl-95p-force {
        width: 95% !important;
    }

    .mx-wd-xl-95p-force {
        max-width: 95% !important;
    }

    .mn-wd-xl-95p-force {
        min-width: 95% !important;
    }

    .wd-xl-100 {
        width: 100px;
    }

    .wd-xl-100p {
        width: 100%;
    }

    .mx-wd-xl-100p {
        max-width: 100%;
    }

    .mn-wd-xl-100p {
        min-width: 100%;
    }

    .wd-xl-100-force {
        width: 100px !important;
    }

    .wd-xl-100p-force {
        width: 100% !important;
    }

    .mx-wd-xl-100p-force {
        max-width: 100% !important;
    }

    .mn-wd-xl-100p-force {
        min-width: 100% !important;
    }

    .wd-xl-150 {
        width: 150px;
    }

    .wd-xl-150p {
        width: 150%;
    }

    .mx-wd-xl-150p {
        max-width: 150%;
    }

    .mn-wd-xl-150p {
        min-width: 150%;
    }

    .wd-xl-150-force {
        width: 150px !important;
    }

    .wd-xl-150p-force {
        width: 150% !important;
    }

    .mx-wd-xl-150p-force {
        max-width: 150% !important;
    }

    .mn-wd-xl-150p-force {
        min-width: 150% !important;
    }

    .wd-xl-200 {
        width: 200px;
    }

    .wd-xl-200p {
        width: 200%;
    }

    .mx-wd-xl-200p {
        max-width: 200%;
    }

    .mn-wd-xl-200p {
        min-width: 200%;
    }

    .wd-xl-200-force {
        width: 200px !important;
    }

    .wd-xl-200p-force {
        width: 200% !important;
    }

    .mx-wd-xl-200p-force {
        max-width: 200% !important;
    }

    .mn-wd-xl-200p-force {
        min-width: 200% !important;
    }

    .wd-xl-250 {
        width: 250px;
    }

    .wd-xl-250p {
        width: 250%;
    }

    .mx-wd-xl-250p {
        max-width: 250%;
    }

    .mn-wd-xl-250p {
        min-width: 250%;
    }

    .wd-xl-250-force {
        width: 250px !important;
    }

    .wd-xl-250p-force {
        width: 250% !important;
    }

    .mx-wd-xl-250p-force {
        max-width: 250% !important;
    }

    .mn-wd-xl-250p-force {
        min-width: 250% !important;
    }

    .wd-xl-300 {
        width: 300px;
    }

    .wd-xl-300p {
        width: 300%;
    }

    .mx-wd-xl-300p {
        max-width: 300%;
    }

    .mn-wd-xl-300p {
        min-width: 300%;
    }

    .wd-xl-300-force {
        width: 300px !important;
    }

    .wd-xl-300p-force {
        width: 300% !important;
    }

    .mx-wd-xl-300p-force {
        max-width: 300% !important;
    }

    .mn-wd-xl-300p-force {
        min-width: 300% !important;
    }

    .wd-xl-350 {
        width: 350px;
    }

    .wd-xl-350p {
        width: 350%;
    }

    .mx-wd-xl-350p {
        max-width: 350%;
    }

    .mn-wd-xl-350p {
        min-width: 350%;
    }

    .wd-xl-350-force {
        width: 350px !important;
    }

    .wd-xl-350p-force {
        width: 350% !important;
    }

    .mx-wd-xl-350p-force {
        max-width: 350% !important;
    }

    .mn-wd-xl-350p-force {
        min-width: 350% !important;
    }

    .wd-xl-400 {
        width: 400px;
    }

    .wd-xl-400p {
        width: 400%;
    }

    .mx-wd-xl-400p {
        max-width: 400%;
    }

    .mn-wd-xl-400p {
        min-width: 400%;
    }

    .wd-xl-400-force {
        width: 400px !important;
    }

    .wd-xl-400p-force {
        width: 400% !important;
    }

    .mx-wd-xl-400p-force {
        max-width: 400% !important;
    }

    .mn-wd-xl-400p-force {
        min-width: 400% !important;
    }

    .wd-xl-450 {
        width: 450px;
    }

    .wd-xl-450p {
        width: 450%;
    }

    .mx-wd-xl-450p {
        max-width: 450%;
    }

    .mn-wd-xl-450p {
        min-width: 450%;
    }

    .wd-xl-450-force {
        width: 450px !important;
    }

    .wd-xl-450p-force {
        width: 450% !important;
    }

    .mx-wd-xl-450p-force {
        max-width: 450% !important;
    }

    .mn-wd-xl-450p-force {
        min-width: 450% !important;
    }

    .wd-xl-500 {
        width: 500px;
    }

    .wd-xl-500p {
        width: 500%;
    }

    .mx-wd-xl-500p {
        max-width: 500%;
    }

    .mn-wd-xl-500p {
        min-width: 500%;
    }

    .wd-xl-500-force {
        width: 500px !important;
    }

    .wd-xl-500p-force {
        width: 500% !important;
    }

    .mx-wd-xl-500p-force {
        max-width: 500% !important;
    }

    .mn-wd-xl-500p-force {
        min-width: 500% !important;
    }

    .wd-xl-550 {
        width: 550px;
    }

    .wd-xl-550p {
        width: 550%;
    }

    .mx-wd-xl-550p {
        max-width: 550%;
    }

    .mn-wd-xl-550p {
        min-width: 550%;
    }

    .wd-xl-550-force {
        width: 550px !important;
    }

    .wd-xl-550p-force {
        width: 550% !important;
    }

    .mx-wd-xl-550p-force {
        max-width: 550% !important;
    }

    .mn-wd-xl-550p-force {
        min-width: 550% !important;
    }

    .wd-xl-600 {
        width: 600px;
    }

    .wd-xl-600p {
        width: 600%;
    }

    .mx-wd-xl-600p {
        max-width: 600%;
    }

    .mn-wd-xl-600p {
        min-width: 600%;
    }

    .wd-xl-600-force {
        width: 600px !important;
    }

    .wd-xl-600p-force {
        width: 600% !important;
    }

    .mx-wd-xl-600p-force {
        max-width: 600% !important;
    }

    .mn-wd-xl-600p-force {
        min-width: 600% !important;
    }

    .wd-xl-650 {
        width: 650px;
    }

    .wd-xl-650p {
        width: 650%;
    }

    .mx-wd-xl-650p {
        max-width: 650%;
    }

    .mn-wd-xl-650p {
        min-width: 650%;
    }

    .wd-xl-650-force {
        width: 650px !important;
    }

    .wd-xl-650p-force {
        width: 650% !important;
    }

    .mx-wd-xl-650p-force {
        max-width: 650% !important;
    }

    .mn-wd-xl-650p-force {
        min-width: 650% !important;
    }

    .wd-xl-700 {
        width: 700px;
    }

    .wd-xl-700p {
        width: 700%;
    }

    .mx-wd-xl-700p {
        max-width: 700%;
    }

    .mn-wd-xl-700p {
        min-width: 700%;
    }

    .wd-xl-700-force {
        width: 700px !important;
    }

    .wd-xl-700p-force {
        width: 700% !important;
    }

    .mx-wd-xl-700p-force {
        max-width: 700% !important;
    }

    .mn-wd-xl-700p-force {
        min-width: 700% !important;
    }

    .wd-xl-750 {
        width: 750px;
    }

    .wd-xl-750p {
        width: 750%;
    }

    .mx-wd-xl-750p {
        max-width: 750%;
    }

    .mn-wd-xl-750p {
        min-width: 750%;
    }

    .wd-xl-750-force {
        width: 750px !important;
    }

    .wd-xl-750p-force {
        width: 750% !important;
    }

    .mx-wd-xl-750p-force {
        max-width: 750% !important;
    }

    .mn-wd-xl-750p-force {
        min-width: 750% !important;
    }

    .wd-xl-800 {
        width: 800px;
    }

    .wd-xl-800p {
        width: 800%;
    }

    .mx-wd-xl-800p {
        max-width: 800%;
    }

    .mn-wd-xl-800p {
        min-width: 800%;
    }

    .wd-xl-800-force {
        width: 800px !important;
    }

    .wd-xl-800p-force {
        width: 800% !important;
    }

    .mx-wd-xl-800p-force {
        max-width: 800% !important;
    }

    .mn-wd-xl-800p-force {
        min-width: 800% !important;
    }

    .wd-xl-850 {
        width: 850px;
    }

    .wd-xl-850p {
        width: 850%;
    }

    .mx-wd-xl-850p {
        max-width: 850%;
    }

    .mn-wd-xl-850p {
        min-width: 850%;
    }

    .wd-xl-850-force {
        width: 850px !important;
    }

    .wd-xl-850p-force {
        width: 850% !important;
    }

    .mx-wd-xl-850p-force {
        max-width: 850% !important;
    }

    .mn-wd-xl-850p-force {
        min-width: 850% !important;
    }

    .wd-xl-900 {
        width: 900px;
    }

    .wd-xl-900p {
        width: 900%;
    }

    .mx-wd-xl-900p {
        max-width: 900%;
    }

    .mn-wd-xl-900p {
        min-width: 900%;
    }

    .wd-xl-900-force {
        width: 900px !important;
    }

    .wd-xl-900p-force {
        width: 900% !important;
    }

    .mx-wd-xl-900p-force {
        max-width: 900% !important;
    }

    .mn-wd-xl-900p-force {
        min-width: 900% !important;
    }

    .wd-xl-950 {
        width: 950px;
    }

    .wd-xl-950p {
        width: 950%;
    }

    .mx-wd-xl-950p {
        max-width: 950%;
    }

    .mn-wd-xl-950p {
        min-width: 950%;
    }

    .wd-xl-950-force {
        width: 950px !important;
    }

    .wd-xl-950p-force {
        width: 950% !important;
    }

    .mx-wd-xl-950p-force {
        max-width: 950% !important;
    }

    .mn-wd-xl-950p-force {
        min-width: 950% !important;
    }

    .wd-xl-1000 {
        width: 1000px;
    }

    .wd-xl-1000p {
        width: 1000%;
    }

    .mx-wd-xl-1000p {
        max-width: 1000%;
    }

    .mn-wd-xl-1000p {
        min-width: 1000%;
    }

    .wd-xl-1000-force {
        width: 1000px !important;
    }

    .wd-xl-1000p-force {
        width: 1000% !important;
    }

    .mx-wd-xl-1000p-force {
        max-width: 1000% !important;
    }

    .mn-wd-xl-1000p-force {
        min-width: 1000% !important;
    }

    .wd-xl-auto {
        width: auto;
    }

    .wd-xl-auto {
        width: auto !important;
    }
}

.wd-100v {
    width: 100vw;
}

.wd-1 {
    width: 1px;
}

.wd-2 {
    width: 2px;
}

.wd-3 {
    width: 3px;
}

.wd-4 {
    width: 4px;
}

.wd-6 {
    width: 6px;
}

.wd-7 {
    width: 7px;
}

.wd-8 {
    width: 8px;
}

.wd-9 {
    width: 9px;
}

.wd-16 {
    width: 16px;
}

.wd-32 {
    width: 32px;
}

.wd-36 {
    width: 36px;
}

.wd-48 {
    width: 48px;
}

.wd-64 {
    width: 64px;
}

.wd-72 {
    width: 72px;
}

.wd-170 {
    width: 170px;
}

.wd-180 {
    width: 180px;
}

.wd-190 {
    width: 190px;
}

.wd-220 {
    width: 220px;
}

.wd-230 {
    width: 230px;
}

.mx-wd-1350 {
    max-width: 1350px;
}


/* 30.9.21 */

.bd_steps {
    counter-reset: section;
}

.bd_steps a::before {
    counter-increment: section;
    content: counter(section);
    border: 2px solid white;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    text-align: center;
    line-height: 30px;
    margin-right: 10px;
}

.bd_steps .nav-link {
    font-weight: 500;
    font-size: 14px;
    display: flex;
    background-color: #e9ecef;
    padding: 12px 15px;
    border-radius: 3px;
    align-items: center;
}

.bd_steps .nav-link:hover {
    background: #17A2B8;
    color: #fff;
}

.bd_steps .nav-link+.nav-link {
    margin-top: 10px;
}

.bd_stepscon {
    background-color: #e9ecef;
    min-height: 150px;
    padding: 25px;
    border-radius: 3px;
}

.bd_stepscon h3 {
    font-size: 18px;
    color: #212529;
    font-weight: 700;
    margin-bottom: 5px;
}

.bd_stepscon .add_establishment {
    position: absolute;
    top: 0;
    right: 0;
}

.cancel-modal {
    position: relative !important;
}

.bd_stepscon section {
    position: relative;
}

.bd_actions {
    margin-top: 10px;
}

.bd_actions ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
}

.bd_actions ul li a {
    display: block;
    background-color: #17A2B8;
    padding: 9px 25px;
    line-height: 1.573;
    color: #fff;
    border-radius: 3px;
}

.bd_actions ul li.disabled a {
    background-color: #adb5bd;
}

.bg-danger-animated {
    background: linear-gradient(45deg, #a5434e, #c14c58);
    color: white !important;
}


/* 12-1-2023 */

.template-details .nav {
    padding: 20px 0 !important;
}

.template-details .nav li a {
    font-size: 13px;
    font-weight: 500 !important;
    color: #1D232A;
}

.modal.left .modal-dialog,
.modal.right .modal-dialog {
    position: fixed;
    margin: auto;
    width: 70%;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
    height: 100%;
    overflow-y: auto;
}

.modal.left .modal-body,
.modal.right .modal-body {
    padding: 15px 15px 80px;
}


/*Right*/
.modal.right.fade .modal-dialog {
    right: -320px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.show .modal-dialog {
    right: 0;
}

/* ----- MODAL STYLE ----- */
.modal-content {
    border-radius: 0;
    border: none;
}

.modal-header {
    border-bottom-color: #EEEEEE;
    background-color: #FAFAFA;
}

.template-details .table th,
.template-details .table td {
    letter-spacing: 0.6px;
    font-size: 13px;
}

#empPackageDetailsModal .modal-content {
    padding-top: 60px;
    position: relative;
}

#empPackageDetailsModal .modal-header {
    position: fixed;
    width: 100%;
    z-index: 9;
    top: 0;
}

#empPackageDetailsModal .template-details {
    padding-left: 35%;
    padding-top: 0;
    padding-bottom: 0;

}

#empPackageDetailsModal .template-details .template-tables {
    max-height: 80vh;
    overflow: auto;
    height: 100vh;
}

#empPackageDetailsModal .template-details .col-md-4.border-right {
    position: fixed;
    left: 0;
    height: 100vh;
}

#empPackageDetailsModal .template-details .col-md-8 {
    flex: 100%;
    max-width: 100%;
}

.blink {
    animation: blinker 1s linear infinite;
    font-weight: 600;    
}
.tds_accordian{
    background: #17A2B8;
    color: #fff;
    cursor: pointer;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}